import { Component, OnInit, Input, ViewChild, Output, EventEmitter, AfterViewInit } from '@angular/core';
import { FullCalendarComponent } from '@fullcalendar/angular';
import { FormControl } from '@angular/forms';
import { OptionsInput, EventInput } from '@fullcalendar/core';
import {CdkDragDrop} from '@angular/cdk/drag-drop';
import { ApointmentEditComponent } from '../apointment/apointment-edit/apointment-edit.component';
import { Patient } from 'src/app/model/organisation/patient';
import { MedicalAgent } from 'src/app/model/organisation/medical-agent';
import { BusinessUnit } from 'src/app/model/organisation/business-unit';
import { Person } from 'src/app/model/organisation/person';
import { TranslateService } from '@ngx-translate/core';
import { DayOfWeek } from 'src/app/model/organisation/diary';

@Component({
  selector: 'app-nkap-full-calendar',
  templateUrl: './nkap-full-calendar.component.html',
  styleUrls: ['./nkap-full-calendar.component.css']
})
export class NkapFullCalendarComponent implements OnInit {

  /**
   * The #Calendar component in the template
   */
  @ViewChild('calendar',null)
  calendarComponent: FullCalendarComponent;
  
  @Input() title: string;
  @Input() type: string;


  /**
   * Determines whether the calendar is visible
   */
  @Input() calendarVisible: boolean;
  /**
   * Definition of plugin used in the calendar
   */
  @Input() calendarPlugins: any;
  /**
   * Determines whether weekend days are enabled
   */
  @Input() calendarWeekends :boolean;

  /**
   * Contains all the text of the buttons used in the calendar
   */
  @Input() customButtons: any;
  @Input() header: any;
  @Input() footer: any;
  @Input() allDaySlot: any;
  @Input() selectMirror:any;
  @Input() slotDuration:any;
  @Input() firstDay: string;
  @Input() minTime: string;
  @Input() maxTime: string;
  @Input() hiddenDays: any;
  @Input() droppable: boolean;
  @Input() eventColor: string;

  /**
   * Determines whether an event is editable 
   */
  @Input() editable: boolean;
  /**
   * Determines if he is allowed to select periods of time that are occupied by events.
   */
  @Input() selectOverlap: boolean
  /**
   * Determines if events being dragged and resized are allowed to overlap each other.
   */
  @Input() eventOverlap: boolean
  /**
   * Determines whether events can overlap visually.
   */
  @Input() slotEventOverlap: boolean
  /**
   * Determines whether the calendar zones are selectable
   */
  @Input() selectable: boolean

  @Input() selectedStartDay: number;
  @Input() selectedEndDay: number;

  @Output() eventClick: EventEmitter<any>= new EventEmitter();
  @Output() eventResize: EventEmitter<any>= new EventEmitter();
  @Output() eventRender: EventEmitter<any>= new EventEmitter();
  @Output() eventMouseEnter: EventEmitter<any>= new EventEmitter();
  @Output() eventMouseLeave: EventEmitter<any>= new EventEmitter();
  @Output() eventDrop: EventEmitter<any>= new EventEmitter();

  @Output() selectedStartDayChange: EventEmitter<any>= new EventEmitter();
  @Output() selectedEndDayChange: EventEmitter<any>= new EventEmitter();
  
  /**
   * Parameter for the calendar language
   */
  options: OptionsInput ;

  calendarEvents: EventInput[] = new Array();
  
  patientList: Patient[];
  doctorList: MedicalAgent[];
  serviceList: BusinessUnit[];
  
  doctorControl = new FormControl();
  patientControl = new FormControl();
  serviceControl = new FormControl();

  startControl = new FormControl();
  endControl = new FormControl();
  minTimeControl = new FormControl();
  maxTimeControl = new FormControl();
  slotDurationControl = new FormControl();

  DayOfWeek = [
    {value: DayOfWeek.SUNDAY, text: 'doctor.planing.day.enum.sunday'},
    {value: DayOfWeek.MONDAY, text: 'doctor.planing.day.enum.monday'},
    {value: DayOfWeek.TUESDAY, text: 'doctor.planing.day.enum.tuesday'},
    {value: DayOfWeek.WEDNESDAY, text: 'doctor.planing.day.enum.wednesday'},
    {value: DayOfWeek.THURSDAY, text: 'doctor.planing.day.enum.thursday'},
    {value: DayOfWeek.FRIDAY, text: 'doctor.planing.day.enum.friday'},
    {value: DayOfWeek.SATURDAY, text: 'doctor.planing.day.enum.saturday'},
  ];

  buttonText: any;
  nowDate = new Date();

  constructor( protected translateService: TranslateService,) { 

  }

  ngOnInit() {
    /** 
     * Default translation at FullCalendar interface 
     **/
    this.onTranslateCalendar();
    
    /**
     * FullCalendar translation when changing the language 
     **/
    this.translateService.onLangChange.subscribe(result=>{
        this.onTranslateCalendar();
    });
  }

  /**
   * FullCalendar translation 
   **/
  onTranslateCalendar(){
    this.options= {
      locale: `${this.translateService.currentLang}`
    };

    this.buttonText={
      today: this.translateService.instant('nkap-full-calendar.buttonText.today'),
      dayGridMonth: this.translateService.instant('nkap-full-calendar.buttonText.dayGridMonth'),
      timeGridWeek: this.translateService.instant('nkap-full-calendar.buttonText.timeGridWeek'),
      timeGridDay: this.translateService.instant('nkap-full-calendar.buttonText.timeGridDay')
    }
  }


  displayPersonFn(agent?: Person): string  {
    return agent ? `${agent.fullname}` : '';
  }

  displayService(service?: BusinessUnit): string {
    return service ? `${service.name}` : '';
  }

  eventClick2(event){
    this.eventClick.emit(event);
  }

  eventResize2(event){
    this.eventResize.emit(event);
  }

  eventRender2(event){
    this.eventRender.emit(event);
  }

  eventMouseEnter2(event){
    this.eventMouseEnter.emit(event);
  }

  eventMouseLeave2(event){
    this.eventMouseLeave.emit(event);
  }

  eventDrop2(event){
    this.eventDrop.emit(event);
  }

  selectedStartDayChange2(event){
    this.selectedStartDayChange.emit(event);
  }

  selectedEndDayChange2(event){
    this.selectedEndDayChange.emit(event);
  }
}
