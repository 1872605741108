import { TimeDuration, PrescriptionItem } from 'src/app/model/prescriptions/prescription';
import { TranslateService } from '@ngx-translate/core';
import { Component, OnInit, ViewChild, AfterViewInit } from '@angular/core';
import { MedicalPrescription, MedicalPrescriptionItem } from '../../../../model/prescriptions/prescription';
import { IFormActionBarButton, FormAction, ButtonsBarComponent } from 'src/app/components/buttons-bar/buttons-bar.component';
import { NkapHttpService } from 'src/app/services/nkap-http.service';
import { ActivatedRoute, Router } from '@angular/router';
import { NkapNotificationService, NotificationType } from 'src/app/services/nkap-notification.service';
import { FormBuilder } from '@angular/forms';
import { MedicalAgent } from 'src/app/model/organisation/medical-agent';
import { MedicalFolder } from 'src/app/model/admission/medical-folder';
import { Funtions } from 'src/app/model/security/funtionalities';
import { MedicalPrescriptionEditFormComponent } from './../medical-prescription-edit-form/medical-prescription-edit-form.component';
import { UserAppService } from 'src/app/services/user-app.service';
import { FileDownloaderComponent } from 'src/app/components/file-downloader/file-downloader.component';
import { SearchCriteria } from 'src/app/model/data-page';

@Component({
  selector: 'app-medical-prescription-edit',
  templateUrl: './medical-prescription-edit.component.html',
  styleUrls: ['./medical-prescription-edit.component.css']
})
export class MedicalPrescriptionEditComponent implements OnInit, AfterViewInit {
  
  currentObject: MedicalPrescription;
  validityErrors: string[];// list of errors in the Prestation  

  protected buttons: IFormActionBarButton[];

  @ViewChild(MedicalPrescriptionEditFormComponent)
  editForm: MedicalPrescriptionEditFormComponent;
  mustPatientBeSelected: boolean = true;

  @ViewChild('buttonsBar')
  protected buttonsBarCmp: ButtonsBarComponent;
  
  /**
   * A reference on download component
   */
  @ViewChild("downloader")
  downloaderComponent: FileDownloaderComponent;
  openFileInTab: boolean = true;
  
  protected formAction: string = "";
  protected prescriptionOldCode: string = null;

  constructor(private translateService: TranslateService,private httpService: NkapHttpService<any>, 
              private router: Router, private route: ActivatedRoute, private userService: UserAppService, 
              private msgService: NkapNotificationService) {
      this.currentObject = new MedicalPrescription();
      this.currentObject.date = new Date(Date.now());
      this.currentObject.medicalAgent = new MedicalAgent();
      this.currentObject.medicalFolder = new MedicalFolder();
      this.currentObject.prescriptionItems = [new MedicalPrescriptionItem()];
      this.buttons = [
        {id: "save", value: FormAction.CREATE, icon: {type : 'save'},
          text: 'btn.save.label', disabled: true, functionalities: [Funtions.medicalPrescription_btn_new_fct,Funtions.medicalPrescription_btn_edit_fct]},
        {id: "save_print", value: FormAction.CREATE, icon: {type : 'save'},
          text: 'btn.save.and.print.label', disabled: true, functionalities: [Funtions.medicalPrescription_btn_new_fct,Funtions.medicalPrescription_btn_edit_fct,Funtions.medicalPrescription_btn_print_fct]},
        {id: "list", value: FormAction.VIEW, icon: {type : 'list'},
          text: 'btn.list.label', disabled: false, functionalities: [Funtions.medicalPrescription_btn_list_fct]}
      ];
  }

  ngAfterViewInit(): void {
    this.editForm.statusChanged.subscribe(status => {
        this.updateButtonsStatus(status);
    });
  }

  ngOnInit() {
    this.initForm(this.currentObject);
    // Get the MedicalAgent connected
    let userId = this.userService.getUser().number;
    if(userId != null) {
      this.httpService.search({userId: userId },`organisation/medical-agent`).subscribe(results => {
        if(results.datas && results.datas.length > 0){
          if(this.currentObject){
            this.currentObject.medicalAgent = results.datas[0];
          }
          this.editForm.setAgent(results.datas[0]);
        }
      }, (errors) => {
        this.msgService.log('medical-prescription.edit.get.medicalAgent.data.error', NotificationType.ERROR);
      });
    }
    //Get routed data
    this.route.paramMap.subscribe(params => {
      let data: {id?: string | number, medicalFolderId?: string | number, 
        selectPatient?: boolean,
        action?: string} = (params as any).params;
        //console.log("params ", data);
      if(data.action && (data.action == MedicalPrescription.REVISE_ACTION  || data.action == MedicalPrescription.RENEW_ACTION )){// MedicalPrescription.REVISE_ACTION or MedicalPrescription.RENEW_ACTION
        this.formAction = data.action;
      }
      
      if (data.id != null) {
        this.httpService.findById(data.id, 'prescription/medical-prescription', true).subscribe( (prescription) => {
          if(this.formAction == MedicalPrescription.RENEW_ACTION){
            this.mustPatientBeSelected = false; // canot change the patient anymore
            this.prescriptionOldCode = prescription.prescriptionCode;
            let expirationDate = null;
            if(prescription.validityDuration != null) {
              expirationDate = new TimeDuration(prescription.validityDuration.duration, prescription.validityDuration.unit).getAchievementDate(new Date(Date.now()));
            }
            //console.log(" prescription ", prescription.validityDuration, expirationDate);
            let newMedicalPrescription: MedicalPrescription = Object.assign( prescription, {
              prescriptionCode: null, date: new Date(Date.now()), 
              expirationDate: expirationDate,
              stillValid : true, // the prescription is valid
              medicalAgent: null // the doctor must be the one connected, not the one who made the old prescription
            });
            let newItems = [];
            if(newMedicalPrescription.prescriptionItems) {
              newMedicalPrescription.prescriptionItems.forEach( (item)=> {
                newItems.push(Object.assign(new MedicalPrescriptionItem(), item, {id: null, creationDate: null, modificationDate: null}))
              });              
            }
            newMedicalPrescription.prescriptionItems = newItems;
            this.currentObject = newMedicalPrescription;
          } else {
            this.currentObject = prescription;
          }
        }, (errors) => {
          this.msgService.log('medical-prescription.edit.get.data.error', NotificationType.ERROR);
        });
      }
      if (data.medicalFolderId != null) {
        this.mustPatientBeSelected = false;
        this.httpService.findById(data.medicalFolderId , 'admission/medical-folder').subscribe ( (result) => {
          this.currentObject.medicalFolder = result;
          this.editForm.setMedicalFolder(result,data.selectPatient != null && (data.selectPatient + "").trim()== "true" ? true : false);
        }, (errors) => {
          this.msgService.log('medical-prescription.edit.get.medicalFolder.data.error', NotificationType.ERROR);
        });
      } else {
        this.mustPatientBeSelected = true;
      }
      
    });    
    this.updateButtonsStatus(this.editForm.getFormStatus());
  }

  renderPrescriptionCode() {
    return this.prescriptionOldCode != null ? 
    " N°" + this.prescriptionOldCode +"" : 
    (this.currentObject && this.currentObject.prescriptionCode? (" N°" + this.currentObject.prescriptionCode ) : "");
  }

  public initForm(prescription: MedicalPrescription): void {
    if(this.editForm) {
      this.editForm.initForm(prescription);
      this.updateButtonsStatus(this.editForm.getFormStatus());
    }    
  }

  isValid(): boolean {
    return this.editForm.isValid();
  }

  getCurrentErrors() {
    return this.editForm.getCurrentErrors();
  }

  getData(): MedicalPrescription {
    this.currentObject = this.editForm.getData();
    return this.currentObject;
  }

  updateButtonsStatus(status: boolean): void {
    if(this.buttonsBarCmp){
      let newStatusList = [
        {id: 'save', disabled:!status},
        {id: 'save_print', disabled:!status}
      ];
      //console.log("updateButtonsStatus ",newStatusList);
      this.buttonsBarCmp.setButtonsStatus(newStatusList);
    }
  }

  btnActionClick(event) {
    let btnNumber = 0;
    if ( event && event.id === this.buttons[btnNumber++].id) {
      this.editForm.afterSaveEvent.subscribe( (result: {result:NotificationType, value: MedicalPrescription}) => {
        if(result.result == NotificationType.SUCCESS){// if save goes well, we close the dialog
          this.router.navigate(['./medical-prescription/list', {search: true}]);
        }
      });
      this.editForm.save();
    } else if ( event && event.id === this.buttons[btnNumber++].id) {
      this.editForm.afterSaveEvent.subscribe( (result: {result:NotificationType, value: MedicalPrescription}) => {
        if(result.result == NotificationType.SUCCESS){// if save goes well, we close the dialog
          console.log(result.value);
          let prescriptionId = result.value.id;
          this.print(prescriptionId);
          this.router.navigate(['./medical-prescription/list', {search: true}]);
        }
      });
      this.editForm.save();      
    } else if ( event && event.id === this.buttons[btnNumber++].id) {
      this.router.navigate(['./medical-prescription/list', {search: true}]);
    }
  }

  print(id) {
    let criteria = new SearchCriteria();
    criteria.lang= this.translateService.currentLang;
    criteria.id = id;
    this.downloaderComponent.criteria=criteria;
    this.downloaderComponent.link = 'prescription/medical-prescription/print';
    this.downloaderComponent.downloadFile({}, false);
    this.downloaderComponent.event.subscribe( (whellDone) => {
      if (whellDone == false) {
        this.msgService.log("form.print.error", NotificationType.ERROR);
      }
    });
  }
}
