import { Component, OnInit, ViewChild, ChangeDetectorRef } from '@angular/core';
import { ButtonsBarComponent, IFormActionBarButton, FormAction } from 'src/app/components/buttons-bar/buttons-bar.component';
import { Subscription, Subject } from 'rxjs';
import { AntecedentType } from 'src/app/model/admission/medical-folder';
import { MatTableDataSource, MatPaginator, MatSort } from '@angular/material';
import { TranslatePipe, TranslateService } from '@ngx-translate/core';
import { SearchCriteria } from 'src/app/model/data-page';
import { NkapHttpService } from 'src/app/services/nkap-http.service';
import { ActivatedRoute, Router } from '@angular/router';
import { UserAppService } from 'src/app/services/user-app.service';
import { NkapNotificationService, NotificationType } from 'src/app/services/nkap-notification.service';
import { NkapListComponent } from 'src/app/components/base/nkap-list-component';
import { Funtions } from 'src/app/model/security/funtionalities';

@Component({
  selector: 'app-antecedent-type-list',
  templateUrl: './antecedent-type-list.component.html',
  styleUrls: ['./antecedent-type-list.component.css']
})
export class AntecedentTypeListComponent extends NkapListComponent<AntecedentType> {
  @ViewChild("btnBar")
  btnBarCmp: ButtonsBarComponent;
  
  dataAntecedentTypeSubscription: Subscription;
  dataAntecedentTypeSubject: Subject<AntecedentType[]> = new Subject<AntecedentType[]>();
  displayedColumns: string[] = ['name','action'];
  tableDataSource: MatTableDataSource<AntecedentType> = new MatTableDataSource<AntecedentType>([]);
  private dataAntecedentType: AntecedentType[];
  categoryList: {value: string , text: string}[];
 
  selectedAntecedent:any;

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;  

  translate: TranslatePipe;

  criteria: any | SearchCriteria;

  constructor(protected _ref: ChangeDetectorRef, protected httpService: NkapHttpService<AntecedentType>, 
    protected router: Router, protected route: ActivatedRoute, protected userService: UserAppService,
    protected msgService: NkapNotificationService, protected translateService: TranslateService) {
   super(_ref, httpService, router, route, userService, msgService, translateService);
      this.searchBaseUrl = "admission/antecedent-type";
    this.buttons = [
      {id: "add", value: FormAction.CREATE, icon: {type : 'add_circle_outline', color: '#0099cc'},
        text: 'antecedent.type.btn.new.label', disabled: false, functionalities:[Funtions.antecedent_type_btn_new_fct]},
      {id: "edit", value: FormAction.UPDATE, icon: {type : 'edit',color:'#ff9933'}, 
        text: 'antecedent.type.btn.edit.label', disabled: false, functionalities: [Funtions.antecedent_type_btn_edit_fct]},
      {id: "delete", value: FormAction.DELETE, icon: {type :'delete_forever',color:'#ff9933'},
        text: 'antecedent.type.btn.delete.label', disabled: false, functionalities: [Funtions.antecedent_type_btn_delete_fct]}
    ];

   }

  ngOnInit() {
    super.ngOnInit();
    this.dataAntecedentTypeSubscription = this.dataAntecedentTypeSubject.subscribe((data) => {
      this.tableDataSource = new MatTableDataSource<AntecedentType>(data);
      this.tableDataSource.paginator = this.paginator;
      this.tableDataSource.sort = this.sort;
    });
   // this.getAntecedent();
   
  }


  applyFilter(filterValue: string) {
    filterValue = filterValue.trim(); // Remove whitespace
    filterValue = filterValue.toLowerCase(); // Datasource defaults to lowercase matches
    this.tableDataSource.filter = filterValue;
  }

  emitData(){
    this.dataAntecedentTypeSubject.next(this.dataAntecedentType);
  }

  // getAntecedent(value = '') {
  //   this.httpService.search( {value: value}, 'admission/antecedent').subscribe(result => {
  //     this.dataAntecedent = result? result.datas : [];
  //     console.log(result);
  //     this.emitData();
  //   });
  // }

  btnActionClick(event) {
    if(event && event.id === this.buttons[0].id){
      this.router.navigate(["antecedent-type/edit"]);
    } else if(event && event.id === this.buttons[1].id){
      if( !this.selection.selected || this.selection.selected.length != 1){
        this.msgService.log("antecedent.type.list.select.to.modify.error", NotificationType.ERROR);
      }else{
        this.router.navigate(["antecedent-type/edit", {id: this.selection.selected[0].id}]);
      }
    } else if(event && event.id === this.buttons[2].id){
      if( !this.selection.selected || this.selection.selected.length < 1){
        this.msgService.log("antecedent.type.list.select.to.modify.error", NotificationType.ERROR);
      }else{
        this.deleteSelected();
      }
    }
  }

  deleteSelected(){
    this.msgService.confirm(this.translate.transform("antecedent.type.delete.confirm.text")).subscribe( (result) => {
      if(result === true){
          let antecedentSelected = this.selection.selected[0];
          this.httpService.deleteById(antecedentSelected.id, this.searchBaseUrl).subscribe( (val) => {
            this.msgService.log("antecedent.type.delete.succed", NotificationType.SUCCESS);
            // this.router.navigate(['antecedent/list', {search: true}]);
            this.searchClicked({});
          }, (error) => {
            this.msgService.log("antecedent.type.delete.fail", NotificationType.ERROR);
          });
        }
      });
  }

  protected viewElement(element) {
    this.router.navigate(['antecedent-type/show', {id: element.id}]);
  }

  listSelectionChanged(){
    super.listSelectionChanged();
    if(this.btnBarCmp){
      const btnStatus = [
        {id: "add", disabled: false, functionalities: this.buttons[0].functionalities},
        {id: "edit", disabled: !(this.selection && this.selection.selected && this.selection.selected.length > 0),functionalities: this.buttons[1].functionalities},
        {id: "delete", disabled: !(this.selection && this.selection.selected && this.selection.selected.length > 0),functionalities: this.buttons[2].functionalities},
      ];    
      this.btnBarCmp.setButtonsStatus(btnStatus);
    }
  }

  displayTypeFn(type?: AntecedentType): string {
    return type ? `${type.name}` : '';
  }

  ngOnDestroy(){
    this.dataAntecedentTypeSubscription.unsubscribe();
  }

}
