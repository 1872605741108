import { Component, ViewChild, ChangeDetectorRef } from '@angular/core';
import { FormGroup, AbstractControl, FormBuilder, Validators } from '@angular/forms';
import { MatAutocompleteSelectedEvent } from '@angular/material';
import { FormAction, ButtonsBarComponent } from 'src/app/components/buttons-bar/buttons-bar.component';
import { TranslateService } from '@ngx-translate/core';
import { NkapHttpService } from 'src/app/services/nkap-http.service';
import { Router, ActivatedRoute } from '@angular/router';
import { NkapNotificationService, NotificationType } from 'src/app/services/nkap-notification.service';
import { Person, GenderType } from 'src/app/model/organisation/person';
import { MedicalAgent, MedicalAgentType } from 'src/app/model/organisation/medical-agent';
import { Country } from 'src/app/model/location/country';
import { Party } from 'src/app/model/organisation/party';
import { NkapListComponent } from 'src/app/components/base/nkap-list-component';
import { UserAppService } from 'src/app/services/user-app.service';
import { Funtions } from 'src/app/model/security/funtionalities';
import { NkapValidators } from 'src/app/components/base/nkap-validators';
import { ENTER, COMMA } from '@angular/cdk/keycodes';

@Component({
  selector: 'app-doctor-list',
  templateUrl: './doctor-list.component.html',
  styleUrls: ['./doctor-list.component.css']
})
export class DoctorListComponent extends NkapListComponent<MedicalAgent>{

  protected FEMALE = GenderType[GenderType.FEMALE];
  
  personIdentificationForm: FormGroup;
  nationalityControl: AbstractControl;
  nationalities: Country[];

  displayer: Person = new Person();
  
  /*select sexe */
  protected sexes: {value?: any, text?: any}[];

  /** Medical agent type */
  protected typeList: {value?: any, text?: any}[];
  protected typeControl: AbstractControl;
  protected typesSelected: any[];
  
  @ViewChild("btnBar")
  btnBarCmp: ButtonsBarComponent;

  separatorKeysCodes: number[] = [ENTER, COMMA];
  
  constructor(private fb: FormBuilder, 
    protected _ref: ChangeDetectorRef, protected httpService: NkapHttpService<MedicalAgent>, 
    protected router: Router, protected route: ActivatedRoute, protected userService: UserAppService,
    protected msgService: NkapNotificationService, protected translateService: TranslateService) {
   super(_ref, httpService, router, route, userService, msgService, translateService);
      this.searchBaseUrl = "organisation/medical-agent";

      this.personIdentificationForm = this.fb.group({
        gender: [null],
        nationality: [null],
        number: [null, [Validators.maxLength(50)]],
        type: ['',[NkapValidators.dataWithIdSelected]]
      });
      this.nationalityControl = this.personIdentificationForm.get('nationality');  
      this.typeControl = this.personIdentificationForm.get('type');  

      this.typesSelected = [];

      this.buttons = [
        {id: "add", value: FormAction.CREATE, icon: {type : 'add_circle_outline', color: '#0099cc'},
          text: 'doctor.btn.new.label', disabled: false, functionalities: [Funtions.doctor_btn_new_fct]},
        {id: "edit", value: FormAction.UPDATE, icon: {type : 'edit',color:'#ff9933'}, 
          text: 'doctor.btn.edit.label', disabled: false, functionalities: [Funtions.doctor_btn_edit_fct]},
        {id: "delete", value: FormAction.DELETE, icon: {type :'delete_forever',color:'#ff9933'},
          text: 'doctor.btn.delete.label', disabled: false, functionalities: [Funtions.doctor_btn_delete_fct]}
      ];
      this.criteria.number = "";
  }

  ngOnInit() {
    super.ngOnInit();    
    this.sexes = [
      {value: null, text: ''},
      {value: GenderType[GenderType.FEMALE], text: 'GenderType.' + GenderType[GenderType.FEMALE] + '.label'},
      {value: GenderType[GenderType.MALE], text: 'GenderType.' + GenderType[GenderType.MALE] + '.label'}
    ];
    this.typeList = [
      {value: null, text: ''},
      {value: MedicalAgentType[MedicalAgentType.GENERALIST_DOCTOR], text: 'MedicalAgentType.' + MedicalAgentType[MedicalAgentType.GENERALIST_DOCTOR] + '.label'},
      {value: MedicalAgentType[MedicalAgentType.SPECIALIST_DOCTOR], text: 'MedicalAgentType.' + MedicalAgentType[MedicalAgentType.SPECIALIST_DOCTOR] + '.label'},
      {value: MedicalAgentType[MedicalAgentType.NURSE], text: 'MedicalAgentType.' + MedicalAgentType[MedicalAgentType.NURSE] + '.label'},
      {value: MedicalAgentType[MedicalAgentType.LABORATORY_TECHNICIAN], text: 'MedicalAgentType.' + MedicalAgentType[MedicalAgentType.LABORATORY_TECHNICIAN] + '.label'},
      {value: MedicalAgentType[MedicalAgentType.OTHERS], text: 'MedicalAgentType.' + MedicalAgentType[MedicalAgentType.OTHERS] + '.label'}
    ];

    this.nationalityControl.valueChanges.subscribe( (textInput) => {
      if (!textInput || typeof textInput === 'string') {
        this.criteria.nationalityId = null;
        this.getCountry(textInput);
      } else {
        this.criteria.nationalityId = textInput.id;
      }
    });
  }

  public getCountry(value) {
    this.httpService.search({ criteria: value }, 'location/country').subscribe((result) => {
      this.nationalities = result ? result.datas : [];
    });
  }

  protected search(criteria: any) {
    criteria.types = this.typesSelected;
    criteria.gender = this.personIdentificationForm.get('gender').value;
    criteria.number = this.personIdentificationForm.get("number").value;
    criteria.userId = this.personIdentificationForm.get("number").value;
    super.search(criteria);
  }

  
  btnActionClick(event) {
    if(event && event.id === this.buttons[0].id ){
      this.router.navigate(["doctor/edit"]);
    } else if(event && event.id === this.buttons[1].id){
      if( !this.selection.selected || this.selection.selected.length != 1){
        this.msgService.log("doctor.list.select.to.modify.error", NotificationType.ERROR);
      }else{
        this.router.navigate(["doctor/edit", {id: this.selection.selected[0].id}]);
      }
    } else if(event && event.id === this.buttons[2].id){
      if( !this.selection.selected || this.selection.selected.length < 1){
        this.msgService.log("doctor.list.select.to.delete.error", NotificationType.ERROR);
      }else{
        this.deleteSelected();
      }
    }
  }

  displayTypeFn(elmt:any){
    if(elmt != null ) {
      return `MedicalAgentType.${elmt}.label`;
    }
  }

  protected viewElement(element) {
    this.router.navigate(['doctor/show', {id: element.id}]);
  }

  deleteSelected(){
    this.msgService.confirm(this.translate.transform("doctor.delete.confirm.text")).subscribe( (result) => {
      if(result === true){
        let doctorSelected = this.selection.selected[0];
        this.httpService.deleteById(doctorSelected.id, 'organisation/medical-agent').subscribe( (val) => {
          this.msgService.log("doctor.delete.succed", NotificationType.SUCCESS);
          this.selection.clear();
          this.searchClicked({});
        }, (error) => {
          this.msgService.log("doctor.delete.fail", NotificationType.ERROR);
        });
      }
    });
  }

  protected getPersonAge(person: Person){
    return person ? Party.getAge(person.birthdate) : null;
  }

  protected setCriteriaDataInView(cachedNavigationData: any) {
    super.setCriteriaDataInView(cachedNavigationData);
    if(cachedNavigationData == null) { return ;}
    if( cachedNavigationData.criteria != null) {
      this.personIdentificationForm.get('type').setValue( cachedNavigationData.criteria.status);
      this.personIdentificationForm.get('gender').setValue( cachedNavigationData.criteria.gender);
    }
    if( cachedNavigationData.nationality != null) {
      this.nationalityControl.setValue(cachedNavigationData.nationality);
    }
  }

  protected getNavigationDataToStore(): any {
    let data = super.getNavigationDataToStore();
    return Object.assign( data != null ? data : {}, {
      nationality: this.nationalityControl.value,
    });
  }

  listSelectionChanged(){
    super.listSelectionChanged();
    if(this.btnBarCmp){
      const btnStatus = [
        {id: "add", disabled: false, functionalities: this.buttons[0].functionalities},
        {id: "edit", disabled: !(this.selection && this.selection.selected && this.selection.selected.length > 0), functionalities: this.buttons[1].functionalities},
        {id: "delete", disabled: !(this.selection && this.selection.selected && this.selection.selected.length > 0), functionalities: this.buttons[2].functionalities},
      ];    
      this.btnBarCmp.setButtonsStatus(btnStatus);
    }
  }

  removeInType(type) {
    if (type) {
      const index = this.typesSelected.findIndex( (elmt) => elmt == type);
      if (index != -1) {
        this.typesSelected.splice(index, 1);
      }
    }
  }
  
  selectedType(event: MatAutocompleteSelectedEvent) {
    let selected = event.option.value;
    if(selected != null && this.typesSelected != null && this.typesSelected.findIndex( (elm)=> elm == selected ) == -1) {
      this.typesSelected = this.typesSelected.concat([selected]);
    }
    //this.consInput.nativeElement.value = '';
    this.typeControl.setValue(null);
    console.log( "selectedConsultation", event);
  }
}
