import { Component, OnInit, Input, Inject, AfterViewInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators, FormControl } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
// tslint:disable-next-line:max-line-length
import { PrestationCovered, PrestationFamilyCovered, PrestationCategoryCovered, PrestationCategory, CareCoverage } from 'src/app/model/admission/care-coverage-doc';
import { NkapHttpService } from 'src/app/services/nkap-http.service';
import { IFormActionBarButton, FormAction } from '../../buttons-bar/buttons-bar.component';
import { TranslateService } from '@ngx-translate/core';
import { NkapValidators } from '../../base/nkap-validators';
let self;
import { Funtions } from 'src/app/model/security/funtionalities';
import { IModalComponent } from '../../base/modal/modal.component';

@Component({
  selector: 'app-couverture-sante-modal',
  templateUrl: './couverture-sante-modal.component.html',
  styleUrls: ['./couverture-sante-modal.component.css']
})
export class CouvertureSanteModalComponent extends IModalComponent implements OnInit, AfterViewInit {

  couvertureSanteModalForm: FormGroup;
  placeHolderValue: string;
  parameters: any = {};
  url: string;
  datas: any[] = [];
  autoCompleteValue: any;

  protected buttons: IFormActionBarButton[];

  protected categories: any[];

  protected modalTitle: string;

  protected data: {modalTitle?: string, careCoverage: PrestationCategoryCovered & PrestationFamilyCovered & PrestationCovered};

  constructor(private formBuilder: FormBuilder,
              public dialogRef: MatDialogRef<CouvertureSanteModalComponent>,
              private nkapService: NkapHttpService<any>,
              private translateService: TranslateService,
              @Inject(MAT_DIALOG_DATA) public modalData: any ) {
    super();
    self = this;
    this.data = modalData.data;
    this.parameters = {
      prestationUrl: 'prestation',
      prestationFamilyUrl: 'prestation/prestation-family',
      prestationGlobalUrl: 'third-party-payer/prestation-category-covered',
    };
    
    this.categories = [
      {
        value: PrestationCategory[PrestationCategory.ALL],
        text: 'couverture-sante.modal.prestation.categorie.all',
        classe: 'PrestationCategory'
      },            
      {
        value: PrestationCategory[PrestationCategory.AMBULATORY],
        text: 'couverture-sante.modal.prestation.categorie.ambulatory',
        classe: 'PrestationCategory'
      },
      {
        value: PrestationCategory[PrestationCategory.STATIONARY],
        text: 'couverture-sante.modal.prestation.categorie.stationnaire',
        classe: 'PrestationCategory'
      },
    ];
  }

  ngAfterViewInit() {
    
  }

  ngOnInit() {
       
    this.initForm();
    this.buttons = [
      {id: 'save', value: FormAction.CREATE, disabled: this.couvertureSanteModalForm.invalid , icon: {type : 'save'},
        text: 'btn.save.label', functionalities: [
        Funtions.consultation,Funtions.reception_btn_edit_fct,Funtions.exams,Funtions.careCoverageDoc_btn_new_fct,Funtions.careCoverageDoc_btn_edit_fct]},
      {id: 'cancel', value: FormAction.CANCEL, icon: {type : 'cancel'},
        text: 'couverture-sante.document.modal.btn.cancel', functionalities: ['couverture-sante.document.modal.btn.cancel']},
    ];
    this.couvertureSanteModalForm.statusChanges.subscribe(value => {
      if (this.couvertureSanteModalForm.invalid === false) {
        this.buttons[0].disabled = false;
      } else {
        this.buttons[0].disabled = true;
      }
    });

    this.couvertureSanteModalForm.get('name').valueChanges.subscribe(value => {  
      //console.log("couvertureSanteModalForm ", value);   
      if (!value || typeof value === 'string') {
        this.autoCompleteValue = {};
        this.getDatas(value);
      } else {
        this.autoCompleteValue = value;
      }
    });
  }

  initForm() {
    console.log("initForm" , this.data);
    switch (this.data.careCoverage.classe) {
      case 'PrestationCovered' :
        this.datas = [];
        this.placeHolderValue = 'couverture-sante.tiers-payant-type.modal.prestation';
        this.url = `${this.parameters.prestationUrl}`;
        this.autoCompleteValue = this.data.careCoverage.prestation;
        
         break;
      case 'PrestationFamilyCovered' :
        this.datas = [];
        this.placeHolderValue = 'couverture-sante.tiers-payant-type.modal.famille-de-prestation';
        this.url = `${this.parameters.prestationFamilyUrl}`;
        this.autoCompleteValue = this.data.careCoverage.family;
        break;
      case 'PrestationCategoryCovered' :
        this.datas = [];// this.categories;
        this.placeHolderValue = 'couverture-sante.tiers-payant-type.modal.prestation-categorie';
        this.url = `${this.parameters.prestationGlobalUrl}`;
        this.autoCompleteValue = this.categories.find( (elm)=> elm.value === this.data.careCoverage.category);
       
        break;
    }
    this.couvertureSanteModalForm = this.formBuilder.group({
      name: [this.autoCompleteValue, [Validators.required , Validators.minLength(1) , NkapValidators.notEmpty]],
      maxAmount: [this.data.careCoverage.maxAmount, [Validators.pattern(/^[1-9][0-9]+$/)]],
      percent: [this.data.careCoverage.percent, [Validators.required,  Validators.pattern(/^[1-9]$|^[1-9][0-9]$|^100$/)]]
    });
  }

  onNoClick() {
    this.dialogRef.close();
  }

  onSubmitForm() {
    this.dialogRef.close(this.getData());
  }

  getDatas(criteria?: string) {
    if(this.data.careCoverage.classe !== 'PrestationCategoryCovered') {            
      this.nkapService.search({value: criteria}, this.url).subscribe(result => {
        this.datas = result.datas || [];
      });
    } else {
      this.datas = this.categories.filter( (elmt) => {
        return criteria ? this.displayOption(elmt).toUpperCase().indexOf(criteria.toUpperCase()) != -1 : true;
      });
    }
  }

  btnActionClick(event) {
    if ( event && event.id === this.buttons[0].id) {
      if(this.couvertureSanteModalForm.valid) {
        this.onSubmitForm();
      }
    } else if ( event && event.id === this.buttons[1].id) {
      this.onNoClick();
    }
  }

  displayOption(val){
    let response = "";
    if(val){
      response = (val.classe == 'PrestationCategory') ? self.translateService.instant(val.text) : val.name;
    }
    return response;
  }

  public getData() {
    const formValue = this.couvertureSanteModalForm.value;
    let newItem: any = Object.assign({}, this.data.careCoverage);
    newItem.maxAmount = formValue.maxAmount;
    newItem.percent = parseInt(formValue.percent);

    if(newItem.classe === 'PrestationCategoryCovered'){
      newItem.category = this.autoCompleteValue.value;
    }
    if(newItem.classe ==='PrestationFamilyCovered'){
      newItem.family = this.autoCompleteValue;
    }
    if(newItem.classe === 'PrestationCovered'){
      newItem.prestation =  this.autoCompleteValue;
    }
    return newItem;
  }
}

