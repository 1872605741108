import { Component, OnInit, ChangeDetectorRef, ViewChild } from '@angular/core';
import { Apointment, ApointmentStatus } from 'src/app/model/admission/apointment';
import { NkapListComponent } from '../../base/nkap-list-component';
import { Reception, PrestationsToPerformed } from 'src/app/model/admission/reception';
import { NkapHttpService } from 'src/app/services/nkap-http.service';
import { ActivatedRoute, Router } from '@angular/router';
import { UserAppService } from 'src/app/services/user-app.service';
import { NkapNotificationService, NotificationType } from 'src/app/services/nkap-notification.service';
import { TranslateService } from '@ngx-translate/core';
import { FormAction } from '../../buttons-bar/buttons-bar.component';
import { MedicalAgent } from 'src/app/model/organisation/medical-agent';
import { FormControl } from '@angular/forms';
import { MatInput } from '@angular/material';
import { BusinessUnit } from 'src/app/model/organisation/business-unit';
import * as moment from 'moment';
import { Person } from 'src/app/model/organisation/person';
import { FileDownloaderComponent } from '../../file-downloader/file-downloader.component';
import { Funtions } from 'src/app/model/security/funtionalities';
@Component({
  selector: 'app-chronological-list',
  templateUrl: './chronological-list.component.html',
  styleUrls: ['./chronological-list.component.css']
})
export class ChronologicalListComponent extends NkapListComponent<Reception>  {

  medicalAgentSelected: MedicalAgent;
  medicalAgentControl = new FormControl();
  medicalAgentList: MedicalAgent[];
  @ViewChild('medicalAgentInput')
  medicalAgentInputCmp: MatInput;

  serviceSelected: BusinessUnit;
  serviceControl = new FormControl();
  serviceList: BusinessUnit[];
  @ViewChild('serviceInput')
  serviceInputCmp: MatInput;

  tableColumn = [];

   /**
   * A reference on download component
   */
  @ViewChild("downloader")
  downloaderComponent: FileDownloaderComponent;
  openFileInTab = true;
  
  constructor(protected _ref: ChangeDetectorRef, protected httpService: NkapHttpService<any>, 
    protected router: Router, protected route: ActivatedRoute, protected userService: UserAppService,
    protected msgService: NkapNotificationService, protected translateService: TranslateService) {
   super(_ref, httpService, router, route, userService, msgService, translateService);
      this.searchBaseUrl = "admission/reception";
      if(this.criteria.period == null) {
        this.criteria.period = {};
      }
      this.criteria.apointment = {period: {}};
   this.buttons = [
    {id: "search", value: FormAction.PRINT, icon: {type : 'search'}, toolTip: "app.search.label", cssClass:"mat-raised-button",
    disabled: false, functionalities: [Funtions.chronologicalList_btn_print_fct, Funtions.chronologicalList_btn_list_fct]},
      {id: "print", value: FormAction.PRINT, icon: {type : 'print'}, toolTip: "chronological-list.print.label",cssClass:"mat-raised-button",
         disabled: false, functionalities: [Funtions.chronologicalList_btn_print_fct]},
      {id: 'view', value: FormAction.VIEW, icon: {type : 'assignment', color: '#ff9933'}, 
      text: 'consultation-sheet.btn.view.label', disabled: false, functionalities: [Funtions.reception_btn_close_fct]},
     
      /*{id: "close", value: FormAction.UPDATE, icon: {type : 'close'}, 
         disabled: false, functionalities: [Funtions.chronologicalList_btn_close_fct]},*/
      ];   
      this.tableColumn = ['checked','date','patient','description','personToMeet','unit','status'/*,'action'*/];  
  }

  ngOnInit() {
    
    this.criteria.apointment.period.start = moment().utc().startOf('day');
    const currentDate = moment.utc(new Date()).startOf('day').toDate();
    this.criteria.apointment.period.start = currentDate;
    console.log(" this.criteria.apointment.period.start ", this.criteria.apointment.period.start);
    this.route.paramMap.subscribe(params => {
      let data: any = (params as any).params;
      if (data.search) {
        this.searchClicked({});
      }
    });    

    this.medicalAgentControl = new FormControl();
    this.medicalAgentControl.valueChanges.subscribe( (inputValue) => {
      if (typeof inputValue === 'string' ) {
        this.medicalAgentSelected = null;
        this.httpService.search({value: inputValue}, 'organisation/medical-agent').subscribe ( (result) => {
           this.medicalAgentList = result ? result.datas : [];
        });
       } else {
         this.medicalAgentSelected = inputValue;
       }
      this.criteria.medicalAgentId = this.medicalAgentSelected? this.medicalAgentSelected.id : undefined;
     // this.rebuildTableColumns();
     /* if(this.medicalAgentSelected != null) {
        this.searchClicked({});
      }*/
    });

    this.serviceControl = new FormControl();
    this.serviceControl.valueChanges.subscribe( (inputValue) => {
      if (typeof inputValue === 'string' ) {
        this.serviceSelected = null;
        this.httpService.search({value: inputValue}, 'organisation/business-unit').subscribe ( (result) => {
           this.serviceList = result ? result.datas : [];
         });
       } else {
         this.serviceSelected = inputValue;
         this.searchClicked({});
       }
       this.criteria.apointment.unitId = this.serviceSelected? this.serviceSelected.id : undefined;
     // this.rebuildTableColumns();
     /* if(this.serviceSelected != null) {
        this.searchClicked({});
      }*/
    });
  }

  rebuildTableColumns() {
    this.tableColumn = ['checked','date','patient','description'];
    if(this.criteria.medicalAgentId == null){
      this.tableColumn.push('personToMeet');
    }
    if(this.criteria.apointment.unitId == null){
      this.tableColumn.push('unit');
    }
    this.tableColumn.push('status');
   // this.tableColumn.push('action');
  }

  displayPersonFn(agent?: Person): string  {
    return agent ? `${agent.fullname}` : '';
  }

  displayServiceFn(service?: BusinessUnit): string {
    return service ? `${service.name}` : '';
  }

  displayDirectionFn(prestToPerformed: PrestationsToPerformed) {
    let str = "";
    if(prestToPerformed != null){
      str += prestToPerformed.prestation ? `${prestToPerformed.prestation.name} ` : '';
      /*str += prestToPerformed.agent ? `(${this.displayPersonFn(prestToPerformed.agent)} ` : '';
      str += !prestToPerformed.agent && prestToPerformed.unit ?
       `${prestToPerformed.agent ? '':'('} ${this.displayServiceFn(prestToPerformed.unit)} ` : '';
      str += prestToPerformed.unit || prestToPerformed.agent ? ')' : '';*/
    }
    return str;
  }
 
  formatDate(date){
    try {
        return "[" + moment(new Date(date)).utc().format("HH:mm") + "] " + moment(new Date(date)).format("LL");
    } catch (e) {
      console.error("Parse date in reception-list fail " , e);
    }
  }

  protected startDateSelected(event) {
    if(!this.criteria.apointment.period){
      this.criteria.period = {};
    }
    this.criteria.apointment.period.start = event.value;
    //this.searchClicked({});
  }

  btnActionClick(event) {
    if(event && event.id === this.buttons[1].id){
      // print report
     this.printClicked(event);
    } else if(event && event.id === this.buttons[0].id){//
      this.searchClicked(event)
    } /*else if(event && event.id === this.buttons[2].id){// close all apointment
      if( !this.selection.selected || this.selection.selected.length < 1){
        this.msgService.log('reception.list.select.to.cancel.error', NotificationType.ERROR);
      }else{
        // TODO cancel reception(s)
      }
    }*/
    else if(event && event.id === this.buttons[2].id){// close all apointment
      if( !this.selection.selected || this.selection.selected.length != 1){
        this.msgService.log('reception.list.select.to.modify.error', NotificationType.ERROR);
      }else{
        this.router.navigate(['reception/consultation-sheet', {id: this.selection.selected[0].id}]);
      }
    }
  }

  protected viewElement(element) {
    this.router.navigate(['apointment/show', {id: element.id}]);
  }

  formatApointmentStatus(status) {
    return status ? "ApointmentStatus." + status + ".label" : "ApointmentStatus." + ApointmentStatus[ApointmentStatus.PENDING] + ".label";
  }

  protected search(criteria: any) {
    this.criteria.chronologicaly = true;
    this.criteria.apointment.status = ApointmentStatus[ApointmentStatus.CONFIRM];
    this.rebuildTableColumns();
    super.search(criteria);
  }

  /**
   * Function triggering the printing of the list of receptions
   */
  protected printClicked(event) {
    this.criteria.lang= this.translateService.currentLang;
    this.downloaderComponent.link = 'admission/reception-list/print';
    this.downloaderComponent.criteria=this.criteria;
    this.downloaderComponent.downloadFile({});
    this.downloaderComponent.event.subscribe( (whellDone) => {
        if (whellDone == false) {
          this.msgService.log("form.print.error", NotificationType.ERROR);
        }
    });
  }


  formatHour(startTime){
    try{
     return moment(new Date((new Date().getFullYear())+"-01-01T" + `${startTime}`)).format("h:mm");
     }catch(e){
       console.error("Parse date in reception-list fail " , e);
     }
  }
}
