import { Component, OnInit, ChangeDetectorRef, ViewChild } from '@angular/core';
import { Reception, ReceptionStatus, PrestationsToPerformed } from 'src/app/model/admission/reception';
import { IFormActionBarButton, FormAction, ButtonsBarComponent } from '../../buttons-bar/buttons-bar.component';
import { TranslatePipe, TranslateService } from '@ngx-translate/core';
import { NkapHttpService } from 'src/app/services/nkap-http.service';
import { Router, ActivatedRoute } from '@angular/router';
import { NkapNotificationService, NotificationType } from 'src/app/services/nkap-notification.service';
import { Person } from 'src/app/model/organisation/person';
import { BusinessUnit } from 'src/app/model/organisation/business-unit';
import { CareCoverageDoc } from 'src/app/model/admission/care-coverage-doc';
import { Funtions } from 'src/app/model/security/funtionalities';

@Component({
  selector: 'app-reception-show',
  templateUrl: './reception-show.component.html',
  styleUrls: ['./reception-show.component.css']
})
export class ReceptionShowComponent implements OnInit {

  protected currentObject: Reception;
  protected buttons: IFormActionBarButton[];
  protected careCoverageDocApply: CareCoverageDoc;

  translate: TranslatePipe;
  id: String | number;

  @ViewChild("btns")
  protected btnsCmp: ButtonsBarComponent;

  
  constructor(private _ref: ChangeDetectorRef, protected translateService: TranslateService,
              private httpService: NkapHttpService<Reception>, 
              private router: Router, private route: ActivatedRoute,
              private msgService: NkapNotificationService) {
      this.translate = new TranslatePipe(translateService, _ref);
      this.buttons = [
        {id: 'edit', value: FormAction.UPDATE, icon: {type : 'edit', color: '#ff9933'}, 
          text: 'reception.btn.edit.label', disabled: true, functionalities: [Funtions.reception_btn_edit_fct]},
       /* {id: 'delete', value: FormAction.DELETE, icon: {type : 'delete_forever', color: '#ff9933'},
           text: 'reception.btn.delete.label', disabled: false},*/
        {id: 'list', value: FormAction.VIEW, icon: {type : 'list'}, text: 'btn.list.label', disabled: false, functionalities: [Funtions.reception_btn_list_fct]}
      ];

  }

  ngOnInit() {
    this.route.paramMap.subscribe(params => {
      let data: {id?: string | number} = (params as any).params;
      this.id = data.id
      if (data.id != null) {
        this.httpService.findById(data.id, 'admission/reception/with-care-coverage', true).subscribe( (result) => {
          this.currentObject = result;
          if(this.currentObject.prestationsToPerformed && this.currentObject.prestationsToPerformed.length > 0) {
            let found = this.currentObject.prestationsToPerformed.find( (elmt)=> elmt.careCoverageToApply != null && elmt.careCoverageDoc != null);
            this.careCoverageDocApply = found? found.careCoverageDoc : null;
          }
        }, (errors) => {
          this.msgService.log('reception.edit.get.data.error', NotificationType.ERROR);
        });
      }
    });
  }

  btnActionClick(event) {
    if (event && event.id === this.buttons[0].id &&(this.currentObject.status == null || 
      this.currentObject.status == ReceptionStatus[ReceptionStatus.PENDING])) {
      this.router.navigate(['reception/home', {id: this.currentObject.id}]);
    }/* else if (event && event.id === this.buttons[1].id) {
        this.delete();
    } */else if (event && event.id === this.buttons[1].id) {
      this.router.navigate(['reception/list', {search : true}]);
    } 
  }

  listSelectionChanged(){
    if(this.btnsCmp){
      const newStatus = [
        {id: 'edit', disabled: this.currentObject != null && this.currentObject.status == ReceptionStatus[ReceptionStatus.PENDING]
                     ? false : true
        }
      ];
      this.btnsCmp.setButtonsStatus(newStatus);
    }
  }

  folderNavigate() {
    if(this.currentObject && this.currentObject.patientFolder) {
      this.router.navigate(['medical-folder/show', {id:this.currentObject.patientFolder.id}]);
    }
  }

  displayPersonFn(agent?: Person): string  {
    return agent ? `${agent.fullname}` : '';
  }

  displayServiceFn(service?: BusinessUnit): string {
    return service ? `${service.name}` : '';
  }

  displayDirectionFn(prestToPerformed: PrestationsToPerformed) {
    let str = "";
    if(prestToPerformed != null){
      str += prestToPerformed.prestation ? `${prestToPerformed.prestation.name} ` : '';
      str += prestToPerformed.agent ? `(${this.displayPersonFn(prestToPerformed.agent)} ` : '';
      str += !prestToPerformed.agent && prestToPerformed.unit ?
       `${prestToPerformed.agent ? '':'('} ${this.displayServiceFn(prestToPerformed.unit)} ` : '';
      str += prestToPerformed.unit || prestToPerformed.agent ? ')' : '';
    }
    return str;
  }

  getPrestationsToPerformedInsurer(prestToPerformed: PrestationsToPerformed) {
    let str = "";
    str += prestToPerformed && prestToPerformed.careCoverageDoc && prestToPerformed.careCoverageDoc.documentIssuer && prestToPerformed.careCoverageDoc.documentIssuer.party ?
       `${prestToPerformed.careCoverageDoc.documentIssuer.party.fullname} ` : '';
    return str;
  }

  getPrestationsToPerformedPercent(prestToPerformed: PrestationsToPerformed) {
    let str = "";
    str += prestToPerformed && prestToPerformed.careCoverageToApply ?
       `${prestToPerformed.careCoverageToApply.percent} ` : '';
    return str;
  }

  getPrestationsToPerformedMaxAmount(prestToPerformed: PrestationsToPerformed) {
    let str = "";
    str += prestToPerformed && prestToPerformed.careCoverageToApply && prestToPerformed.careCoverageToApply.maxAmount != null?
       `${prestToPerformed.careCoverageToApply.maxAmount} ` : this.translate.transform("care-coverage-doc.max.amount.null.label");
    return str;
  }

  

  contactPersonExist(){
    let exist = false;
    if (this.currentObject.patientFolder && this.currentObject.patientFolder.contactPersons 
      && this.currentObject.patientFolder.contactPersons.length > 0){
        this.currentObject.patientFolder.contactPersons.forEach( (item)=>{
          if(item.person && item.person.fullname && item.person.fullname!= 'null'){
            exist = true;
          }
        });
    }
    return exist;
  }

  attendingDoctorExist(){
    if(this.currentObject.patientFolder 
      && this.currentObject.patientFolder.attendingDoctor
      && this.currentObject.patientFolder.attendingDoctor.fullname != null 
      && this.currentObject.patientFolder.attendingDoctor.fullname != 'null' ){
        return true;
    }
    return false;
  }

  delete(){
    if (confirm(this.translate.transform('reception.delete.confirm.text'))) {
      this.httpService.deleteById(this.currentObject.id, 'admission/reception', true).subscribe( (val) => {
        this.msgService.log('prestation.delete.succed', NotificationType.SUCCESS);
        this.router.navigate(['reception/list', {search : true}]);
      }, (error) => {
        this.msgService.log('reception.delete.fail', NotificationType.ERROR);
      });
    }
  }
}
