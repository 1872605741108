import { Component, OnInit, Input, EventEmitter, ChangeDetectorRef, Output, ViewChild, OnChanges, SimpleChanges } from '@angular/core';
import { CareCoverageDoc } from 'src/app/model/admission/care-coverage-doc';
import { IFormActionBarButton, FormAction } from '../../buttons-bar/buttons-bar.component';
import { NkapHttpService } from 'src/app/services/nkap-http.service';
import { Router, ActivatedRoute } from '@angular/router';
import { NkapNotificationService, NotificationType } from 'src/app/services/nkap-notification.service';
import { TranslateService } from '@ngx-translate/core';
import { CouvertureSanteComponent } from '../../couverture-sante/couverture-sante.component';
import { Reception } from 'src/app/model/admission/reception';
import { Funtions } from 'src/app/model/security/funtionalities';


@Component({
  selector: 'app-reception-care-coverage-doc-edit',
  templateUrl: './reception-care-coverage-doc-edit.component.html',
  styleUrls: ['./reception-care-coverage-doc-edit.component.css']
})
export class ReceptionCareCoverageDocEditComponent implements OnInit , OnChanges {

  protected reception: Reception;
  protected patientFolderId: any;
  protected docNumber: any;
  protected docIssuerId: any;
  protected dataToSave: CareCoverageDoc;

  /**
   * Url parameters
   */
  protected params: any;

  /**
   * Form title parameters
   */
  protected parameters: any;

  protected buttons: IFormActionBarButton[];
  
  @ViewChild('couverture')
  couvertureCmp: CouvertureSanteComponent;

  constructor(protected _ref: ChangeDetectorRef, protected httpService: NkapHttpService<any>, 
    protected router: Router, protected route: ActivatedRoute,
    protected msgService: NkapNotificationService, protected translateService: TranslateService) {
        this.buttons = [
          {id: "add", value: FormAction.CREATE, icon: {type : 'save', color: '#0099cc'},
            text: 'btn.save.label', disabled: false, functionalities: [Funtions.careCoverageDoc_btn_new_fct, Funtions.careCoverageDoc_btn_edit_fct]},
          {id: "list", value: FormAction.VIEW, icon: {type : 'list',color:'#ff9933'}, 
            text: 'btn.list.label', disabled: false, functionalities: [Funtions.careCoverageDoc_btn_list_fct, Funtions.careCoverageDoc_btn_new_fct, Funtions.careCoverageDoc_btn_edit_fct]}
        ];
 }

  ngOnInit() {
    // get parameter set in route
    this.route.paramMap.subscribe(params => {
      this.params = (params as any).params;
      if (this.params.receptionId != null) {
        this.httpService.findById(this.params.receptionId, 'admission/reception', true).subscribe( (reception)=>{
          this.reception = reception;
          if(this.reception ){
            if(this.reception.prestationsToPerformed && this.reception.prestationsToPerformed.length > 0) {
              const careCoverage = this.reception.prestationsToPerformed[0].careCoverageDoc;
              this.docNumber = careCoverage? careCoverage.number : null;
            }
            this.patientFolderId = this.reception.patientFolder? this.reception.patientFolder.id : null; 
            this.parameters = {
              number : this.reception.id,
              patient: `${this.reception.patientFolder.patient.fullname} (${this.reception.patientFolder.number})`
            };
            this.couvertureCmp.ngOnInit();
            this.couvertureCmp.searchNumber({});
          }
       });
      } else{ // if no reception id set, go back to the list
        this.router.navigate(['reception/list', {search:true}]);    
      }
    }); 
    
  }

  ngOnChanges(changes: SimpleChanges): void {

  }

  btnActionClick(event) {
    if(event && event.id === this.buttons[0].id ){
      // save care coverage in reception
      this.save();
    } else if(event && event.id === this.buttons[1].id){
      this.router.navigate(['reception/list', {search:true}]);
    }
  }

  isValid(): boolean {
    let isValid = true;
    this.dataToSave = this.couvertureCmp.getData();
    if (this.dataToSave){
      this.dataToSave.patientFolderId = this.reception && this.reception.patientFolder? this.reception.patientFolder.id : null;
    }/*else{
      isValid = false;
    } */
    if (this.dataToSave && this.dataToSave.patientFolderId == null){
      isValid = false;
    }
    if (this.couvertureCmp.isValid() === false){
      isValid = false;
    }
    return isValid;
  }

  save(){
    if (this.isValid() === true && this.params.receptionId){  
      if(this.dataToSave)  {  
        this.httpService.post(this.dataToSave, `admission/reception/set-care-coverage/by-reception-id/${this.params.receptionId}`, true).subscribe( (result) => {
          this.msgService.log("care-coverage-doc.edit.save.succed", NotificationType.SUCCESS);
          this.router.navigate(['reception/list', {search:true}]);    
        }, (error) => {
          if (typeof error == 'string') {
            this.msgService.log(error, NotificationType.ERROR);
          } else {
            this.msgService.log("care-coverage-doc.edit.save.fail", NotificationType.ERROR);
          }
        });
      } else {
        this.httpService.post(null, `admission/reception/remove-care-coverage/by-reception-id/${this.params.receptionId}`, true).subscribe( (result) => {
          this.msgService.log("reception.care-coverage-doc.remove.save.succed", NotificationType.SUCCESS);
          this.router.navigate(['reception/list', {search:true}]);    
        }, (error) => {
          if (typeof error == 'string') {
            this.msgService.log(error, NotificationType.ERROR);
          } else {
            this.msgService.log("reception.care-coverage-doc.remove.save.fail", NotificationType.ERROR);
          }
        });
      }
    }else{
      this.msgService.log("care-coverage-doc.edit.valid.fail", NotificationType.ERROR);
    }
  }
}
