import { Component, ViewChild, EventEmitter, OnInit, AfterViewInit, ChangeDetectorRef, ElementRef, Renderer2 } from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import { DomSanitizer } from '@angular/platform-browser';
import { MatIconRegistry, MatSidenav, MatProgressSpinner, DateAdapter, MatPaginatorIntl } from '@angular/material';
import { UserAppService } from './services/user-app.service';
import { GlobalVariables } from './services/global-variables';
import { Router, NavigationEnd } from '@angular/router';
import { NkapHttpService } from './services/nkap-http.service';
import { AppProgressBarComponent } from './services/nkap-notification.service';
import { OverlayRef, FullscreenOverlayContainer, Overlay } from '@angular/cdk/overlay';
import { ComponentPortal } from '@angular/cdk/portal';
import { registerLocaleData } from '@angular/common';
import 'moment/locale/fr';   
import * as moment from 'moment';    
import { NkapKeycloakService } from './services/nkap-keycloak.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit, AfterViewInit{

  @ViewChild(MatSidenav)
  sideNav: MatSidenav;
  longProcess: boolean = false;
  userLogged: boolean = false;

  paginatorLabels: {itemsPerPageLabel?: string, nextPageLabel?: string, rangeLabel?: string,
  previousPageLabel?: string, firstPageLabel?: string, lastPageLabel?: string} = {};
  ruleSubscription = new Subscription();

  progressBarMode = 'indeterminate';
  overlayRef:OverlayRef;

  constructor(private cd: ChangeDetectorRef,private translate: TranslateService, private router: Router,
              private userService: UserAppService, private httpService: NkapHttpService<any>,
              private mdIconRegistry: MatIconRegistry, sanitizer: DomSanitizer,
              private el: ElementRef , private renderer: Renderer2,
              private overlay: Overlay, private nkapKeycloakService: NkapKeycloakService
    ) {
      
    httpService.showSpiner.subscribe( (show) => {
      if(this.longProcess != show){
        this.longProcess = show;
        this.cd.detectChanges();
	      // console.log('longProcess ', this.longProcess);
        if(show == true){
          this.overlayRef = overlay.create({
            hasBackdrop: true,
            backdropClass: 'dark-backdrop',
            panelClass: 'tm-file-preview-dialog-panel'
          });
          this.overlayRef.attach(new ComponentPortal(AppProgressBarComponent));          
        } else {
          this.overlayRef.dispose();
        }
      }
    }); 

      this.userLogged = userService.isUserLoggedIn();
      this.userService.userLoggedChanged.subscribe( (event) => {
        this.userLogged = event.logged;
        this.cd.detectChanges();
        if(!this.userLogged) {
          this.sideNav.close();
        }
      });
      const browserLang = this.translate.getBrowserLang();
      const langIndex = GlobalVariables.AVAILABLE_LANG.findIndex( (lang) => lang === browserLang);
      let currentLang = GlobalVariables.AVAILABLE_LANG[langIndex != -1 ? langIndex : 0];
      this.translate.setDefaultLang(currentLang);
      this.translate.use(currentLang);
      GlobalVariables.AVAILABLE_LANG.forEach( (lang) => {
        registerLocaleData(GlobalVariables.AVAILABLE_LOCALE[lang]);
       // console.log(' lang ', lang, GlobalVariables.AVAILABLE_LOCALE[lang]  );
        registerLocaleData(GlobalVariables.AVAILABLE_LOCALE[lang]);
      });
         
      this.translate.onLangChange.subscribe( (lang)=>{
         moment.locale(lang.lang);
      });
  }

  ngOnInit(){
    this.ruleSubscription = this.nkapKeycloakService.rulesSubject.subscribe(rules => {
      this.router.navigate(GlobalVariables.AFTER_LOGIN_ROUTE);
    });
  }

  ngAfterViewInit(){
    this.setContentMaxHeight();
  }

  // To set the maxHeight of the modal content and make it scrollable
  protected setContentMaxHeight() {
     
    const appContent = (<HTMLElement>this.el.nativeElement).querySelector('.app-content');
      //console.log({container:container});
      let containerHeight = screen.height;// (container as any).offsetHeight;
      let minHeight = (containerHeight - 220) + "px";
     // console.log(" containerHeight ", containerHeight,"minHeight", minHeight);
      this.renderer.setStyle(appContent,"min-height",minHeight);
  }
}

export class NkapMatPaginatorIntl extends MatPaginatorIntl {
  translate: TranslateService;
  
  public rangeLabel: string;

  constructor() {
    super();
  }

  injectTranslateService(translate: TranslateService) {
    this.translate = translate;

    this.translate.onLangChange.subscribe((lang) => {
      this.translateLabels();
    });

    this.translateLabels();
    
  }

  private translateLabels(){
    this.itemsPerPageLabel = this.translate.instant('paginator.item.per.page.label');
    this.nextPageLabel = this.translate.instant('paginator.next.page.label');
    this.previousPageLabel = this.translate.instant('paginator.previous.label');
    this.firstPageLabel = this.translate.instant('paginator.first.page.label');
    this.lastPageLabel = this.translate.instant('paginator.last.page.label');
    this.rangeLabel = this.translate.instant('paginator.range.label');
    this.changes.next();
    //console.log("translateLabels ", this);
  }

  getRangeLabel = (page, pageSize, length) => {
    length = Math.max(length, 0);
    const startIndex = page * pageSize;
    const endIndex = startIndex < length ?
      Math.min(startIndex + pageSize, length) :
      startIndex + pageSize;
    return startIndex+'-' +endIndex +' '+ (this.rangeLabel? this.rangeLabel : '/') +' '+ length;
  };
}

export class DynamicLocaleId extends String {
  public static locale: string;

  constructor() {
    super();
  }
  toString() {
    return DynamicLocaleId.locale ? DynamicLocaleId.locale : 'fr';
  }
}