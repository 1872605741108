import { Persistent, ClassNameMap } from '../persistent';
import { Doctor } from './doctor';
import { Time } from '@angular/common';

export class Diary extends Persistent {
    public doctor?: Doctor;
    public periods?: Periode[];
    constructor() {
        super();
        this.classe = ClassNameMap.Diary;
    }
}

export class Periode extends Persistent {
    public validityStartDate?: Date;
    public validityEndDate?: Date;
    public isProgramable?: boolean;
    public timeSlot?: TimeSlot;
    public day?: DayOfWeek;
    public activity?: Activity;
    public action?: string;
    public symbol?: any;
    public type?: any;
    public days?: any;
    public specifiqStart?: any;
    public specifiqEnd?: any;
    public businessHours?: number[];
    constructor() {
        super();
        this.classe = ClassNameMap.Periode;
    }
}
export class TimeSlot {
    public startTime?: Time | string;
    public endTime?: Time | string;
}

export enum DayOfWeek {
    SUNDAY,
    MONDAY,
    TUESDAY,
    WEDNESDAY,
    THURSDAY,
    FRIDAY,
    SATURDAY
}

export class Activity extends Persistent {
    public name?: string;
    constructor(name?: string) {
        super();
        this.name = name;
        this.classe = ClassNameMap.Activity;
    }
}
