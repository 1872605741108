// locale for date pipe
//import localeFr from 'moment/locale/fr';
import { registerLocaleData } from '@angular/common';
import localeFr from '@angular/common/locales/fr';
import localeEn from '@angular/common/locales/en';
registerLocaleData(localeFr);
registerLocaleData(localeEn);
export class GlobalVariables {
    // context path of the application
    public static CONTEXT_PATH = '/nkap-lokta-admission';
    // name of the application. It's used to identified cashed data of the module
    public static APP_NAME = 'nkap-lokta-admission';
    // Number of elements to retrieve per page when requesting page data from server
    public static MAX_ITEM_PER_PAGE = 100;
    // Pagination options
    public static PAGINATOR_SIZE_OPTIONS = [5, 10, 15];
    // default selected option in pagination
    public static PAGINATOR_DEFAULT_SIZE = 5;
    // Availables languages in the application
    public static AVAILABLE_LANG: string[] = ['fr', 'en'];
    public static AVAILABLE_LOCALE: any = {'fr': localeFr, 'en': localeEn};
    // Where to go when the user logout from the application
    public static AFTER_LOGOUT_ROUTE = ['login'];
    // Where to go when the user log into the application
    public static AFTER_LOGIN_ROUTE = ['dashboard'];
    // Get Authorities
    public static KEYCLOAK_RULES_ATTRIBUTE = 'authority';
    public static KEYCLOAK_GROUP_ATTRIBUTE = 'group';
}