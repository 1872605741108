import { Component, OnInit, Input, OnChanges, SimpleChanges } from '@angular/core';
import { Company, Party } from 'src/app/model/organisation/party';
import { Person, GenderType } from 'src/app/model/organisation/person';
import { ClassNameMap } from 'src/app/model/persistent';
import * as moment from 'moment';
import { UserAppService } from 'src/app/services/user-app.service';
import { TranslateService } from '@ngx-translate/core';
import { GlobalVariables } from 'src/app/services/global-variables';
@Component({
  selector: 'app-party-show',
  templateUrl: './party-show.component.html',
  styleUrls: ['./party-show.component.css']
})
export class PartyShowComponent implements OnInit, OnChanges {
  
  @Input()
  protected showAge: boolean;
  @Input('party')
  protected currentObject: Party | Person | Company;

  @Input()
  protected colClass;

  protected personClass: string;
  protected companyClass: string;
  protected medicalAgentClass: string;

  protected FEMALE = GenderType[GenderType.FEMALE];
  

  protected locale: string;

  constructor(private userService: UserAppService, private translateService: TranslateService) {
    this.personClass = ClassNameMap.Person;
    this.companyClass = ClassNameMap.Company;
    this.medicalAgentClass = ClassNameMap.MedicalAgent;
    this.locale = this.translateService.currentLang;
    translateService.onLangChange.subscribe( (lang) => {
      this.locale = lang.lang;
    });
  }

  ngOnInit() {
  }

  ngOnChanges(changes: SimpleChanges): void {
    
  }
  
  renderDate(date: string) {
    //console.log(" renderDate ", this.locale);
    moment.locale(this.locale);
    return moment(new Date(date)).format("LL");
  }

  getAge(party: Party) {
    return Party.getAge(party.birthdate);
  }
}
