import { Component, OnInit, ChangeDetectorRef, Input, OnChanges, SimpleChanges, ViewChild } from '@angular/core';
import { FormControl, Validators, FormGroupDirective, NgForm } from '@angular/forms';
import { Observable } from 'rxjs';
import { NkapHttpService } from './../../services/nkap-http.service';
import { Address } from './../../model/location/address';
import { City } from './../../model/location/city';
import { Country } from './../../model/location/country';
import { Person } from 'src/app/model/organisation/person';
import { TranslatePipe, TranslateService } from '@ngx-translate/core';
import { ClassNameMap } from 'src/app/model/persistent';
import { MatInput } from '@angular/material';
import { map } from 'rxjs/operators';

let self;
@Component({
  selector: 'app-adresse',
  templateUrl: './adresse.component.html',
  styleUrls: ['./adresse.component.css']
})
export class AdresseComponent implements OnInit, OnChanges {
  
  person: Person = new Person();

  @Input() patientAddressInput: Person;

  public translator: TranslatePipe;

  selectedBP = true;

  selected = '';

  filteredlistCountries: Observable<Country[]>;

  filteredlistCities: Observable<City[]>;

  countrySelected: Country;

  citySelect: City;

  typeAddress = [];

  // indicator to check if presence of one contact at least is provided
  checkContact: boolean = false;

  myControlCountry: FormControl = new FormControl('', [this.countryValidator]);
  @ViewChild('countryName')
  countryInputCmp: MatInput;

  myControlCity:FormControl= new FormControl('', [this.cityValidator]);
  @ViewChild('cityName')
  cityInputCmp: MatInput;

  myControlPostalCode = new FormControl();

  emailFormControl:FormControl = new FormControl('', [
    Validators.pattern(/[+a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,3}$/), this.contactValidator
  ]);

  phoneFormControl:FormControl = new FormControl('', [
    Validators.pattern(/[+0-9]{9}/), this.contactValidator
  ]);  

  officephoneFormControl:FormControl = new FormControl('', [
    Validators.pattern(/[+0-9]{9}/), this.contactValidator
  ]);  

  fixedphoneFormControl:FormControl = new FormControl('', [
    Validators.pattern(/[+0-9]{9}/), this.contactValidator
  ]);

  constructor(private nkapService: NkapHttpService<any>, public _ref: ChangeDetectorRef, private translate: TranslateService) {
    self = this;

    this.translator = new TranslatePipe(translate, _ref);

    this.person = new Person();
    //console.log(this.person);
    this.buildTypeAddress();
    this.translate.onLangChange.subscribe(
      x => {
        this.buildTypeAddress();
      },
      e => console.log(' error %s', e)
    );
    this.setAddressInView(this.person);
    
  }

  ngOnInit() {
  
    this.myControlCountry.valueChanges.subscribe((value) => {
      if (!value || typeof value === 'string') {
          this.countrySelected = null;
          this.getCountry(value);
      } else {
        if (value.name && value.name.trim() != "") {
          this.countrySelected = value;
          if (this.citySelect) {
            this.citySelect.country = value;
            this.citySelect.toAddOrUpdate = true;
          }
        }
      }
      this.myControlCity.updateValueAndValidity({ emitEvent: false });
      this.myControlCountry.updateValueAndValidity({ emitEvent: false });
      this.myControlCity.markAsTouched({onlySelf: false}); // changer la valeur du touch
      this.myControlCountry.markAsTouched({onlySelf: false}); // changer la valeur du touch
    });

    this.myControlCity.valueChanges.subscribe((value) => {
      if (!value || typeof value === 'string') {
        this.citySelect = null;
        this.getCity(value);
      } else {
        if (value.name && value.name.trim() != "") {
          this.citySelect = value;
          this.myControlPostalCode.setValue(this.citySelect.postCode);
          if (value.country && value.country.name && value.country.name.trim() != ""){
            this.myControlCountry.setValue(value.country);
          }
        }       
      }
      this.myControlCity.updateValueAndValidity({ emitEvent: false });
      this.myControlCountry.updateValueAndValidity({ emitEvent: false });
      this.myControlCity.markAsTouched({onlySelf: false}); // changer la valeur du touch
      this.myControlCountry.markAsTouched({onlySelf: false}); // changer la valeur du touch
      this.person.address.locality = this.citySelect;
      if(this.person.address.locality){
        this.person.address.locality.toAddOrUpdate = true;
      }
    });

    this.myControlPostalCode.valueChanges.subscribe((value) => {
         this.myControlCity.updateValueAndValidity({ emitEvent: false });
         this.myControlCountry.updateValueAndValidity({ emitEvent: false });
         this.myControlCity.markAsTouched({onlySelf: false}); // changer la valeur du touch
         this.myControlCountry.markAsTouched({onlySelf: false}); // changer la valeur du touch
    });

    this.phoneFormControl.valueChanges.subscribe((value) => {
        this.fixedphoneFormControl.markAsTouched({onlySelf: false}); // changer la valeur du touch
        this.officephoneFormControl.markAsTouched({onlySelf: false}); // changer la valeur du touch
        this.emailFormControl.markAsTouched({onlySelf: false}); // changer la valeur du touch

        this.fixedphoneFormControl.updateValueAndValidity({ emitEvent: false }); // Vérifie la validité du composant
        this.officephoneFormControl.updateValueAndValidity({ emitEvent: false }); // Vérifie la validité du composant
        this.emailFormControl.updateValueAndValidity({ emitEvent: false }); // Vérifie la validité du composant
    });

    this.fixedphoneFormControl.valueChanges.subscribe((value) => {
        this.phoneFormControl.markAsTouched({onlySelf: false}); // changer la valeur du touch
        this.officephoneFormControl.markAsTouched({onlySelf: false}); // changer la valeur du touch
        this.emailFormControl.markAsTouched({onlySelf: false}); // changer la valeur du touch

        this.phoneFormControl.updateValueAndValidity({ emitEvent: false }); // Vérifie la validité du composant
        this.officephoneFormControl.updateValueAndValidity({ emitEvent: false }); // Vérifie la validité du composant
        this.emailFormControl.updateValueAndValidity({ emitEvent: false }); // Vérifie la validité du composant
    });

    this.officephoneFormControl.valueChanges.subscribe((value) => {
        this.phoneFormControl.markAsTouched({onlySelf: false}); // changer la valeur du touch
        this.fixedphoneFormControl.markAsTouched({onlySelf: false}); // changer la valeur du touch
        this.emailFormControl.markAsTouched({onlySelf: false}); // changer la valeur du touch

        this.phoneFormControl.updateValueAndValidity({ emitEvent: false }); // Vérifie la validité du composant
        this.fixedphoneFormControl.updateValueAndValidity({ emitEvent: false }); // Vérifie la validité du composant
        this.emailFormControl.updateValueAndValidity({ emitEvent: false }); // Vérifie la validité du composant
    });

    this.emailFormControl.valueChanges.subscribe((value) => {
        this.phoneFormControl.markAsTouched({onlySelf: false}); // changer la valeur du touch
        this.fixedphoneFormControl.markAsTouched({onlySelf: false}); // changer la valeur du touch
        this.officephoneFormControl.markAsTouched({onlySelf: false}); // changer la valeur du touch

        this.phoneFormControl.updateValueAndValidity({ emitEvent: false }); // Vérifie la validité du composant
        this.fixedphoneFormControl.updateValueAndValidity({ emitEvent: false }); // Vérifie la validité du composant
        this.officephoneFormControl.updateValueAndValidity({ emitEvent: false }); // Vérifie la validité du composant
    });

  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['patientAddressInput']) {
      this.setAddressInView(this.patientAddressInput);
    }
  }

  protected setAddressInView(addressContact: Person){
    this.citySelect = null;
    this.countrySelected = null;
    if (addressContact == null) {
      addressContact = new Person();
    }
    if (addressContact.address == null) {
      addressContact.address = new Address();
    }
    if (addressContact.address.locality == null) {
      addressContact.address.locality = new City();
    } else if (addressContact.address.locality.name && addressContact.address.locality.name.trim()!=""){
      this.citySelect = addressContact.address.locality;
      this.countrySelected = this.citySelect.country;
    }
    if (addressContact.address.locality.country == null) {
      addressContact.address.locality.country = new Country();
    }
    this.myControlCity.patchValue(this.citySelect);
    this.myControlCountry.patchValue(this.countrySelected);
    this.myControlPostalCode.patchValue(this.citySelect ? this.citySelect.postCode : '');
    this.person = addressContact;
    if (this.person.address.number != null && this.person.address.number.trim() != "") {
      this.selected = this.typeAddress[1].value;
      this.selectedBP = false;
    } else {
      this.selected = this.typeAddress[0].value;
      this.selectedBP = true;
    }
  }

  public cityValidator(cityControl: FormControl) {
    if(self){
      if (!self.citySelect) {
        console.log( " self.myControlPostalCode.value ", self.myControlPostalCode.value , self.citySelect , self.countrySelected);
        if (self.myControlPostalCode && self.myControlPostalCode.value != null && self.myControlPostalCode.value.trim() != "") {
            return {
              'cityValidator': false
            };
        }
        if (self.countrySelected != null) {
            return {
              'cityValidator': false
            };
        }
      }
    }
    return null;
  }

  public countryValidator(countryControl: FormControl) {
    if(self){
      if (!self.countrySelected) {
        if (self.myControlPostalCode && self.myControlPostalCode.value != null && self.myControlPostalCode.value.trim() != "") {
            return {
              'countryValidator': false
            };
        }
        if (self.citySelect != null) {
            return {
              'countryValidator': false
            };
        }
      }
    }
    return null;
  }

  public contactValidator(valueControl: FormControl) {
    if (self && self.checkContact === true 
      && self.emailFormControl && self.phoneFormControl && self.fixedphoneFormControl && self.officephoneFormControl) {
        if ( (self.emailFormControl.value == null || (self.emailFormControl.value != null && self.emailFormControl.value.trim() === '') )
          && (self.phoneFormControl.value == null || (self.phoneFormControl.value != null && self.phoneFormControl.value.trim() === '') )
          && (self.fixedphoneFormControl.value == null || (self.fixedphoneFormControl.value != null && self.fixedphoneFormControl.value.trim() === '') )
          && (self.officephoneFormControl.value == null || (self.officephoneFormControl.value != null && self.officephoneFormControl.value.trim() === '') )) {
            return {
              'contactValidator': false
            };
        }
    }
    return null;
  }

  public buildTypeAddress() {
    this.typeAddress = [];
    ['postal', 'street'].forEach((state) => {
      this.typeAddress.push({value: state, text: state + '.address.label'});
    });
  }

  public getCountry(value) {
    this.nkapService.search({ criteria: value }, 'location/country').subscribe((result) => {
      this.filteredlistCountries = new Observable((observer) => {
        observer.next(result ? result.datas : [])
      });
    });
  }

  public getCity(value) {
    this.nkapService.search({ criteria: value, countryId: this.countrySelected ? this.countrySelected.id : undefined }, 'location/city').subscribe((result) => {
      this.filteredlistCities = new Observable((observer) => {
        observer.next(result ? result.datas : [])
      });
    });
  }

  changeAddress() {
    this.selectedBP = !this.selectedBP;
    if (this.selectedBP) {
      this.person.address.number = '';
    }
  }


  displayCountry(country?: Country): string | undefined {
    return country ? country.name : undefined;
  }

  displayCity(city?: City): string | undefined {
    return city ? city.name : undefined;
  }

  getErrorMessage() {
    var textError1 = '';
    var textError2 = '';

    if (this.emailFormControl.hasError('required')) {
      textError1 = this.translator.transform('email.enter.error');
    }

    if (this.emailFormControl.hasError('pattern')) {
      textError2 = this.translator.transform('format.email.error');
    }
    return textError1 + ' ' + textError2;
  }


  getPhoneErrorMessage(phone) {
    var textError1 = '';
    var textError2 = '';
    if (phone.hasError('required')) {
      textError1 = this.translator.transform('phone.enter.error');
    }

    if (phone.hasError('pattern')) {
      textError2 = this.translator.transform('format.phone.error');
    }

    return textError1 + ' ' + textError2;
  }

  getErrorCountry() {

    return this.translator.transform('text.error.country.label');

  }

  getErrorCity() {

    return this.translator.transform('text.error.city.label');

  }


  isContactValid() {
    this.checkContact = true;
    this.phoneFormControl.markAsTouched({onlySelf: false});
    this.officephoneFormControl.markAsTouched({onlySelf: false});
    this.fixedphoneFormControl.markAsTouched({onlySelf: false});
    this.emailFormControl.markAsTouched({onlySelf: false});

    this.phoneFormControl.updateValueAndValidity({ emitEvent: false }); // Vérifie la validité du composant
    this.fixedphoneFormControl.updateValueAndValidity({ emitEvent: false }); // Vérifie la validité du composant
    this.officephoneFormControl.updateValueAndValidity({ emitEvent: false }); // Vérifie la validité du composant
    this.emailFormControl.updateValueAndValidity({ emitEvent: false }); // Vérifie la validité du composant

    if (!this.person.fixedPhone && !this.person.mobilePhone
      && !this.person.officePhone && !this.person.address.street
      && !this.person.emailAddress) {
      return false;
    }

    if (this.phoneFormControl.invalid
      || this.officephoneFormControl.invalid
      || this.fixedphoneFormControl.invalid
      || this.emailFormControl.invalid) {
      return false;
    }
    return true;
  }

  isLocalityValid(){
    if (this.person.address && this.person.address.locality && this.person.address.locality.postCode) {
      if (!this.person.address.locality.country.name
        || !this.person.address.locality.name) {
        return false;
      }
    }
    if(this.myControlPostalCode.invalid){
      return false;
    }
    if(this.myControlCity.invalid){
      return false;
    }
    if(this.myControlCountry.invalid){
      return false;
    }
    return true;
  }

  isValid() {    
    if(this.isContactValid() === false){
      return false;
    }
    if(this.isLocalityValid() === false){
      return false;
    }
    return true;

  }

  addNewCity(event) {
    if (this.citySelect == null) {
      this.citySelect = new City();
      this.citySelect.name = this.myControlCity.value;
      this.citySelect.postCode = this.myControlPostalCode.value;
      this.citySelect.country = this.countrySelected ? this.countrySelected : this.citySelect.country;
      this.person.address.locality = this.citySelect;
      this.myControlCity.setValue(this.citySelect);
    }
  }

  addNewCountry(event) {
    if (this.countrySelected == null) {
      this.countrySelected = new Country();
      this.countrySelected.name = this.myControlCountry.value;
      this.myControlCountry.setValue(this.countrySelected);
    }
  }

  getData() {
    if(this.person && this.person.address) {
      this.person.address.locality = this.citySelect;
    }
    let data = {
      id : this.person.id,
      emailAddress: this.person.emailAddress,
      mobilePhone: this.person.mobilePhone,
      fixedPhone: this.person.fixedPhone,
      officePhone: this.person.officePhone,
      address: this.person.address ? {
        toAddOrUpdate: true,
        classe: ClassNameMap.Address,
        id: this.person.address.id,
        number: this.person.address.number,
        street: this.person.address.street,
        latitude: this.person.address.latitude,
        longitude: this.person.address.longitude,
        locality: this.person.address.locality ? {
             toAddOrUpdate: this.person.address.locality.toAddOrUpdate,
             classe: ClassNameMap.City,
             id: this.person.address.locality.id,
             postCode: this.myControlPostalCode.value && this.myControlPostalCode.value.trim() != "" ? this.myControlPostalCode.value : undefined,
             name: this.person.address.locality.name,
             country: this.person.address.locality.country ? {
                 toAddOrUpdate: this.person.address.locality.country.toAddOrUpdate,
                 classe: ClassNameMap.Country,
                 id: this.person.address.locality.country.id,
                 isoCode: this.person.address.locality.country.isoCode,
                 name: this.person.address.locality.country.name
             } : undefined
        } : undefined
     } : undefined
    };
    if(data.address && data.address.latitude == null && data.address.longitude == null 
      && data.address.locality == null && ( data.address.number == null || data.address.number == "")
      && ( data.address.street == null || data.address.street == "") ){
        data.address = undefined;
    }
    return data;
  }
 
}
