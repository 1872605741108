import { Component, OnInit, ChangeDetectorRef, Inject, Optional } from '@angular/core';
import { NkapHttpService } from 'src/app/services/nkap-http.service';
import { ActivatedRoute, Router } from '@angular/router';
import { Antecedent, AntecedentCategory, AntecedentType } from 'src/app/model/admission/medical-folder';
import { NkapNotificationService, NotificationType } from 'src/app/services/nkap-notification.service';
import { FormControl, Validators } from '@angular/forms';
import { IFormActionBarButton, FormAction } from '../../buttons-bar/buttons-bar.component';
import { Funtions } from 'src/app/model/security/funtionalities';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog, MatTableDataSource } from '@angular/material';
import { TranslateService, TranslatePipe } from '@ngx-translate/core';
import { AntecedentTypeEditComponent } from '../antecedent-type/antecedent-type-edit/antecedent-type-edit.component';
import { NkapValidators } from '../../base/nkap-validators';
import { IModalComponent } from '../../base/modal/modal.component';

@Component({
  selector: 'app-antecedent-edit',
  templateUrl: './antecedent-edit.component.html',
  styleUrls: ['./antecedent-edit.component.css']
})
export class AntecedentEditComponent extends IModalComponent implements OnInit {
  protected antecedent:Antecedent;
  // nameControl: FormControl;
  descriptionControl: FormControl;
  typeControl: FormControl;
  categoryControl=new FormControl();
  currentType:AntecedentType=new AntecedentType();
  typeList:AntecedentType[];
  functionnalitiesAntType:string[];
  currentCategory:any;
  categoryValue:any;
  // categoryList:AntecedentCategory[];

  dataSource: MatTableDataSource<Antecedent> = new MatTableDataSource<Antecedent>([]);

  protected buttons: IFormActionBarButton[];

  categoryList: {value: string , text: string}[];

  protected data: {dialog: boolean, antecedent:Antecedent};

  constructor(private route: ActivatedRoute, private httpService: NkapHttpService<Antecedent>,
     private msgService: NkapNotificationService ,private router: Router,public dialog: MatDialog,
     @Optional() @Inject(MAT_DIALOG_DATA) public modalData: any,
     @Optional() public dialogRef: MatDialogRef<AntecedentTypeEditComponent>,
     @Optional() public dialogRef2: MatDialogRef<AntecedentEditComponent>) { 
       super();
      this.data = modalData.data;
      this.antecedent = new Antecedent();

      this.buttons = [
        {id: "save", value: FormAction.CREATE, icon: {type : 'save'},
          text: 'btn.save.label', disabled: false, functionalities: [Funtions.antecedent_btn_new_fct, Funtions.antecedent_btn_edit_fct]},
        // {id: "list", value: FormAction.VIEW, icon: {type : 'list'},
        //   text: 'btn.list.label', disabled: data ? true:false, functionalities: [Funtions.antecedent_btn_list_fct]},
          {id: "exit", value: FormAction.CLOSE, icon: {type : 'cancel'},
          text: 'confirm-dialog.cancel.btn.label', disabled: this.data ? false:true, functionalities: [Funtions.antecedent_btn_new_fct, Funtions.antecedent_btn_edit_fct]},
      ];

      this.categoryList = [
        {value: AntecedentCategory[AntecedentCategory.PERSONAL], text:"antecedent.category." + AntecedentCategory[AntecedentCategory.PERSONAL] + ".label"},
        {value: AntecedentCategory[AntecedentCategory.FAMILY], text:"antecedent.category." + AntecedentCategory[AntecedentCategory.FAMILY] + ".label"},
      ];

      this.descriptionControl = new FormControl('', [Validators.required, Validators.minLength(1)]);
      this.descriptionControl.updateValueAndValidity();

      this.typeControl = new FormControl('', [Validators.required, NkapValidators.dataWithIdSelected]);
      this.typeControl.updateValueAndValidity();


      if(this.data && this.data.dialog===true){
        // this.ngOnInit();  
        if(this.data.antecedent)
        this.setObjectInView(this.data.antecedent);
        
      }

      this.functionnalitiesAntType=[Funtions.antecedent_type_btn_new_fct];
  }

  ngOnInit() {
    console.log(this.data)
    
    // this.categoryControl = new FormControl('', [Validators.required, Validators.arguments(AntecedentCategory)]);
    // this.categoryControl.updateValueAndValidity();

    this.route.paramMap.subscribe(params => {
      let data: {id?: string} = (params as any).params;
      if (data.id != null) {
        this.httpService.findById(data.id, 'admission/antecedent', true).subscribe( (antecedent)=>{
          console.log(antecedent)
          this.setObjectInView(antecedent);
        }, (errors) => {
          this.msgService.log("antecedent.edit.get.data.error", NotificationType.ERROR);
        });
      }
    });

    this.typeControl.valueChanges.subscribe(value => {
      if (!value || typeof value === 'string') {
        this.getType(value);
      }
      if (value && value.id !== undefined) {
          this.currentType = value;
      }
    });

    this.getType('');
  }


  btnActionClick(event) {
    if(event && event.id === this.buttons[0].id){
      this.addAntecedent();
    } else if(event && event.id === this.buttons[1].id){
      this.cancel();
    }
}

  // save() {
  //   console.log(this.antecedent)
  //   if (this.isValid() === true){
  //     this.httpService.save(this.antecedent, 'admission/antecedent', true).subscribe( (result) => {
  //       this.msgService.log("antecedent.edit.save.succed", NotificationType.SUCCESS);

  //       if(this.data && this.data.dialog===true)
  //       this.dialogRef.close(result);
  //       else
  //       this.router.navigate(['antecedent/list', {search: true}]);

  //     }, (error) => {
  //       console.log(error)
  //       if (error && Array.isArray(error)) {
  //         error.forEach( (err) => {
  //           this.msgService.log(err, NotificationType.ERROR);
  //         });
  //       } else if(typeof error === 'string') {
  //         this.msgService.log(error, NotificationType.ERROR);
  //       }else {
  //         this.msgService.log("antecedent.edit.save.fail", NotificationType.ERROR);
  //       }
  //     });
  //   } else {
  //     this.msgService.log("antecedent.edit.antecedent.data.invalid", NotificationType.ERROR);
  //   }
  // }

  addAntecedent(){
    if (this.isValid() === true){
      this.dialogRef.close(this.antecedent);
    }else {
      this.msgService.log("antecedent.edit.antecedent.data.invalid", NotificationType.ERROR);
    }
  }

  isValid(){
    console.log(this.currentCategory);
    console.log(this.currentType);
    let isValid = true;
    this.getObjectFromView();
    this.descriptionControl.markAsTouched();
    this.typeControl.markAsTouched();
    if(this.descriptionControl.invalid || !this.currentCategory || this.typeControl.invalid){
      isValid = false;
    }
    /* if(this.descriptionControl.invalid){
      isValid = false;
    } */
    console.log( "isValid" , isValid);
    return isValid;
  }

  getObjectFromView(){
    // this.antecedent.name = this.nameControl.value;
    this.antecedent.description = this.descriptionControl.value;
    this.antecedent.type=this.currentType;
    if(this.currentCategory && this.currentCategory.value)
    this.antecedent.category=this.currentCategory.value;
    else
    this.antecedent.category=this.currentCategory;

  }

  setObjectInView(antecedent: Antecedent){
    if (antecedent) {
      console.log(antecedent);
      this.antecedent = antecedent;

      this.descriptionControl.setValue(antecedent.description);
      this.descriptionControl.updateValueAndValidity();

      this.typeControl.setValue(antecedent.type);
      this.typeControl.updateValueAndValidity();

      this.currentCategory=antecedent.category;
      this.currentType=antecedent.type;
      console.log(this.currentCategory);
      console.log( this.currentType);

      if(antecedent.category.toString()==AntecedentCategory[AntecedentCategory.PERSONAL])
      this.categoryValue= this.categoryList[0];
      if(antecedent.category.toString()==AntecedentCategory[AntecedentCategory.FAMILY])
      this.categoryValue= this.categoryList[1];
    }
  }

  categorySelected(category:AntecedentCategory){
    console.log(category);
    this.currentCategory=category;
  }

  // createType(){
  //   // this.router.navigate(['antecedent/type', {search: true}]);
  //   const dialogRef= this.dialog.open(AntecedentTypeEditComponent, {
  //     height: '1000px',
  //     width: '1000px',
  //     disableClose: true,
  //     data: {dialog: true}
  // });

  //   dialogRef.afterClosed().subscribe(result => {
  //     this.getType('');
  //   });
  // }

  getType(value) {
    this.httpService.search({value: value},'admission/antecedent-type').subscribe ( (result) => {
      this.typeList = result ? result.datas : [];
    });
  }

  displayTypeFn(type?: AntecedentType): string {
    return type ? `${type.name}` : '';
  }

  cancel(): void {
    this.dialogRef2.close();
  }

  public getData() {
    return this.antecedent;
  }
}