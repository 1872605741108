import { Component, OnInit, ChangeDetectorRef, Input, AfterViewInit, SimpleChanges, OnChanges } from '@angular/core';
import { TranslateService, TranslatePipe } from '@ngx-translate/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NkapHttpService } from 'src/app/services/nkap-http.service';
import { NkapNotificationService } from 'src/app/services/nkap-notification.service';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { PhysicalExam } from 'src/app/model/consultation/physical.exam';
import { Subscription, Subject } from 'rxjs';


@Component({
  selector: 'app-physical-exam',
  templateUrl: './physical-exam.component.html',
  styleUrls: ['./physical-exam.component.css']
})
export class PhysicalExamComponent implements OnInit , OnChanges{

  firstFormGroup: FormGroup;
  weigthControl:FormControl;
  // taControl:FormControl;
  // tControl:FormControl;
  // glycemieControl:FormControl;
  physicalExamSubscription: Subscription;
  physicalExamSubject: Subject<PhysicalExam> = new Subject<PhysicalExam>();

  translate: TranslatePipe;

  physicalExam:PhysicalExam;
  edit=true;

  @Input() physicalExamInput:PhysicalExam;
  
  constructor(private _ref:ChangeDetectorRef, private translateService:TranslateService, private route:ActivatedRoute,
    private httpService:NkapHttpService<any>,private msgService: NkapNotificationService,private router: Router) { 
      this.physicalExam=new PhysicalExam();
      this.translate = new TranslatePipe(translateService,_ref);
    }

  ngOnInit() {
    this.physicalExamSubscription = this.physicalExamSubject.subscribe((data) => {
      // this.physicalExam=this.physicalExamInput;
      this.physicalExam=data;
      this.edit=this.physicalExam.edit;
      // this.setObjectInView();
      console.log(this.physicalExam);
    });

  
    // this.egControl = new FormControl(this.physicalExamInput.EG, [Validators.required, Validators.minLength(1)]);
    // this.egControl.updateValueAndValidity();
    // this.taControl = new FormControl(this.physicalExamInput.TA, [Validators.required, Validators.minLength(1)]);
    // this.taControl.updateValueAndValidity();

    // this.firstFormGroup = new FormGroup({
    //   EGControl: this.egControl,
    //   TAControl: this.taControl,
    // });
    
  }
  ngOnChanges(changes: SimpleChanges):void{
    if (changes['physicalExamInput']) {
      this.emitPhysicalExam();
      console.log("onChange")
      console.log(this.physicalExam)
      // this.getFrcv();
    }
  }

  isValid(){
    // if(this.firstFormGroup.valid){
    //   return true;
    // }else{
    //   return false;
    // }
  }

  setObjectInView(){
    console.log(this.physicalExam);
  }

  emitPhysicalExam() {
    this.physicalExamSubject.next(this.physicalExamInput);
  }

  getObjectFromView(){
    console.log(this.physicalExam)
    return this.physicalExam;
  }
}
