import { Component, OnInit, ViewChild, ChangeDetectorRef } from '@angular/core';
import { NotificationType, NkapNotificationService } from 'src/app/services/nkap-notification.service';
import { FormControl } from '@angular/forms';
import { MatTableDataSource, MatTable, MatInput } from '@angular/material';
import { PrestationFamily } from 'src/app/model/prestation/prestation';
import { SelectionModel } from '@angular/cdk/collections';
import { SearchCriteria } from 'src/app/model/data-page';
import { IFormActionBarButton, FormAction, ButtonsBarComponent } from 'src/app/components/buttons-bar/buttons-bar.component';
import { TranslatePipe, TranslateService } from '@ngx-translate/core';
import { NkapHttpService } from 'src/app/services/nkap-http.service';
import { Router, ActivatedRoute } from '@angular/router';
import { GlobalVariables } from 'src/app/services/global-variables';
import { NkapListComponent } from 'src/app/components/base/nkap-list-component';
import { UserAppService } from 'src/app/services/user-app.service';
import { Funtions } from 'src/app/model/security/funtionalities';

@Component({
  selector: 'app-prestation-family-list',
  templateUrl: './prestation-family-list.component.html',
  styleUrls: ['./prestation-family-list.component.css']
})
export class PrestationFamilyListComponent extends NkapListComponent<PrestationFamily>  {

  @ViewChild("btnBar")
  btnBarCmp: ButtonsBarComponent;
  
  familySelected: PrestationFamily;
  familyControl: FormControl = new FormControl();
  familyList: PrestationFamily[];

  translate: TranslatePipe;

  constructor(protected _ref: ChangeDetectorRef, protected httpService: NkapHttpService<PrestationFamily>, 
        protected router: Router, protected route: ActivatedRoute, protected userService: UserAppService,
        protected msgService: NkapNotificationService, protected translateService: TranslateService) {
       super(_ref, httpService, router, route, userService, msgService, translateService);
          this.searchBaseUrl = "prestation/prestation-family";
       this.buttons = [
        {id: "add", value: FormAction.CREATE, icon: {type : 'add_circle_outline', color: '#0099cc'},
          text: 'prestation-family.btn.new.label', disabled: false, functionalities: [Funtions.prestationFamily_btn_new_fct]},
        {id: "edit", value: FormAction.UPDATE, icon: {type : 'edit',color:'#ff9933'}, 
          text: 'prestation-family.btn.edit.label', disabled: false, functionalities: [Funtions.prestationFamily_btn_edit_fct]},
        {id: "delete", value: FormAction.DELETE, icon: {type :'delete_forever',color:'#ff9933'},
          text: 'prestation-family.btn.delete.label', disabled: false, functionalities: [Funtions.prestationFamily_btn_delete_fct]}
      ];
  }

  ngOnInit() {
    super.ngOnInit();
    this.familyControl.valueChanges.subscribe( (inputValue) => {
      if (typeof inputValue === 'string' ) {
        this.familySelected = null;
        this.httpService.search({value: inputValue}, 'prestation/prestation-family').subscribe ( (result) => {
           this.familyList = result ? result.datas : [];
        });
       } else {
         this.familySelected = inputValue;
       }
      this.criteria.parentId = this.familySelected? this.familySelected.id : undefined;
    });
  }

  displayFamilyFn(family?: PrestationFamily): string  {
    return family ? `${family.code} ${family.name ? family.name : ''}` : '';
  }
 
  btnActionClick(event) {
    if(event && event.id === this.buttons[0].id){
      this.router.navigate(["prestation-family/edit"]);
    } else if(event && event.id === this.buttons[1].id){
      if( !this.selection.selected || this.selection.selected.length != 1){
        this.msgService.log("prestation-family.list.select.to.modify.error", NotificationType.ERROR);
      }else{
        this.router.navigate(["prestation-family/edit", {id: this.selection.selected[0].id}]);
      }
    } else if(event && event.id === this.buttons[2].id){
      if( !this.selection.selected || this.selection.selected.length < 1){
        this.msgService.log("prestation-family.list.select.to.delete.error", NotificationType.ERROR);
      }else{
        this.deleteSelected();
      }
    }
  }

  protected startDateSelected(event) {
    if(!this.criteria.period){
      this.criteria.period = {};
    }
    this.criteria.period.start = event.value;
  }

  protected endDateSelected(event) {
    if(!this.criteria.period){
      this.criteria.period = {};
    }
    this.criteria.period.end = event.value;
  }

  protected viewElement(element) {
    this.router.navigate(['prestation-family/show', {id: element.id}]);
  }

  deleteSelected(){
    this.msgService.confirm(this.translate.transform("prestation-family.delete.confirm.text")).subscribe( (result) => {
      if(result === true){
        let prestationSelected = this.selection.selected[0];
        this.httpService.deleteById(prestationSelected.id, 'prestation/prestation-family').subscribe( (val) => {
          this.msgService.log("prestation-family.delete.succed", NotificationType.SUCCESS);
          this.selection.clear();
          this.searchClicked({});
        }, (error) => {
          this.msgService.log("prestation-family.delete.fail", NotificationType.ERROR);
        });
      }
    });
  }

  protected setCriteriaDataInView(cachedNavigationData: any) {
    super.setCriteriaDataInView(cachedNavigationData);
    if(cachedNavigationData != null && cachedNavigationData.family != null) {
      this.familyControl.setValue(cachedNavigationData.family);
    }
  }

  protected getNavigationDataToStore(): any {
    let data = super.getNavigationDataToStore();
    return Object.assign( data != null ? data : {}, { family: this.familyControl.value});
  }

  listSelectionChanged(){
    super.listSelectionChanged();
    if(this.btnBarCmp){
      const btnStatus = [
        {id: "add", disabled: false, functionalities: this.buttons[0].functionalities},
        {id: "edit", disabled: !(this.selection && this.selection.selected && this.selection.selected.length > 0), functionalities: this.buttons[1].functionalities},
        {id: "delete", disabled: !(this.selection && this.selection.selected && this.selection.selected.length > 0), functionalities: this.buttons[2].functionalities},
      ];    
      this.btnBarCmp.setButtonsStatus(btnStatus);
    }
  }
}
