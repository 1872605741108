import { Component, OnInit, ChangeDetectorRef, ViewChild, ElementRef } from '@angular/core';
import { NkapListComponent } from '../../base/nkap-list-component';
import { SaleReportData, IPartnerData } from 'src/app/model/reporting/sale-report-data';
import { NkapHttpService } from 'src/app/services/nkap-http.service';
import { Router, ActivatedRoute } from '@angular/router';
import { UserAppService } from 'src/app/services/user-app.service';
import { TranslateService } from '@ngx-translate/core';
import { NkapNotificationService, NotificationType } from 'src/app/services/nkap-notification.service';
import { SaleReportCriteriaSearch } from 'src/app/model/reporting/sale-report-criteria-search';
import { FileDownloaderComponent } from '../../file-downloader/file-downloader.component';
import { FormControl } from '@angular/forms';
import { MatInput, MatAutocompleteSelectedEvent } from '@angular/material';
import { Prestation } from 'src/app/model/prestation/prestation';
import { FormAction } from '../../buttons-bar/buttons-bar.component';
import { Funtions } from 'src/app/model/security/funtionalities';
import { ENTER, COMMA } from '@angular/cdk/keycodes';
import * as moment from 'moment';
import { DateUtils } from 'src/app/services/utils';

@Component({
  selector: 'app-costs-regulations-statement',
  templateUrl: './costs-regulations-statement.component.html',
  styleUrls: ['./costs-regulations-statement.component.css']
})
export class CostsRegulationsStatementComponent extends NkapListComponent<any> {

  agentSelected: IPartnerData[] = [];
  agentControl = new FormControl();
  agentList: IPartnerData[];
  @ViewChild('agentInput')
  agentInputCmp: ElementRef<HTMLInputElement>;

  prestationSelected: Prestation[] = [];
  prestationControl = new FormControl();
  prestationList: Prestation[];
  @ViewChild('prestationInput')
  prestationInputCmp: ElementRef<HTMLInputElement>;

  tableColumn: string[] = [];
  separatorKeysCodes: number[] = [ENTER, COMMA];

  comparotorControl = new FormControl();
  comparators: string[] = [];
   /**
   * A reference on download component
   */
  @ViewChild("downloader")
  downloaderComponent: FileDownloaderComponent;
  openFileInTab = true;

  constructor(protected _ref: ChangeDetectorRef, protected httpService: NkapHttpService<any>, 
    protected router: Router, protected route: ActivatedRoute, protected userService: UserAppService,
    protected msgService: NkapNotificationService, protected translateService: TranslateService) {
    super(_ref, httpService, router, route, userService, msgService, translateService);
      this.searchBaseUrl = "reporting/costs-regulations-statement";

      this.criteria = new SaleReportCriteriaSearch();
      this.criteria.period = {start: DateUtils.getMonday(Date.now()), end:Date.now()};
      this.criteria.pageSize = this.paginatorParams.pageSize;
      this.tableColumn = ["saleNumber","billNumber","date","prestation","agent","amount"];
      this.buttons = [
        {id: "search", value: FormAction.VIEW, icon: {type : 'search'}, toolTip: "app.search.label", cssClass:"mat-raised-button",
            disabled: false, functionalities: [Funtions.costRegulationStatement_btn_view_fct]},
        {id: "print", value: FormAction.PRINT, icon: {type : 'print'}, toolTip: "app.print.label",cssClass:"mat-raised-button",
            disabled: false, functionalities: [Funtions.costRegulationStatement_btn_print_fct]},
      ]; 
      this.comparators = ["","=","<",">","<=",">=","<>"];
  }

  ngOnInit() {
    
    this.route.paramMap.subscribe(params => {
      let data: any = (params as any).params;
      if (data.search) {
        this.searchClicked({});
      }
    });    

    this.agentControl = new FormControl();
    this.agentControl.valueChanges.subscribe( (inputValue) => {
      if (typeof inputValue === 'string' ) {
        this.httpService.search({value: inputValue}, 'organisation/cashier').subscribe ( (result) => {
           this.agentList = result ? result.datas : [];
        });
       }
    },(error)=>{
      if(typeof error === 'string' && error.trim().startsWith("error.bubinga.")) {
        this.msgService.log("costs-regulations-statement.comunication.bubinga.impossible.error",NotificationType.ERROR);
      }
    });

    this.prestationControl = new FormControl();
    this.prestationControl.valueChanges.subscribe( (inputValue) => {
      if (typeof inputValue === 'string' ) {
        this.httpService.search({value: inputValue}, 'prestation').subscribe ( (result) => {
           this.prestationList = result ? result.datas : [];
         });
       }
    });
  }

  removeInPrestationSelected(prestation) {
    if (prestation) {
      const index = this.prestationSelected.findIndex( (elmt) => elmt.code == prestation.code);
      if (index != -1) {
        this.prestationSelected.splice(index, 1);
      }
    }
  }

  selectedPrestation(event: MatAutocompleteSelectedEvent) {
    let selected = event.option.value;
    if(selected != null && this.prestationSelected != null && this.prestationSelected.findIndex( (elm)=> elm.code == selected.code ) == -1) {
      this.prestationSelected = this.prestationSelected.concat([selected]);
    }
    this.prestationInputCmp.nativeElement.value = '';
    this.prestationControl.setValue(null);
    //console.log( "selectedPrestation", event);
  }

  removeInAgentSelected(agent) {
    if (agent) {
      const index = this.agentSelected.findIndex( (elmt) => elmt.code == agent.code);
      if (index != -1) {
        this.agentSelected.splice(index, 1);
      }
    }
  }

  selectedAgent(event: MatAutocompleteSelectedEvent) {
    let selected = event.option.value;
    if(selected != null && this.agentSelected != null && this.agentSelected.findIndex( (elm)=> elm.code == selected.code ) == -1) {
      this.agentSelected = this.agentSelected.concat([selected]);
    }
    this.agentInputCmp.nativeElement.value = '';
    this.agentControl.setValue(null);
    //console.log( "selectedAgent", event);
  }

  comparatorChanged(event) {
    if(!this.criteria.period){
      this.criteria.period = {};
    }
    this.criteria.period.comparator = event? event.value: this.criteria.period.comparator; 
  }

  protected startDateSelected(event) {
    if(event && event.value) {
      this.criteria.period.start = null;
      this.criteria.period.start = event.value? event.value.utc().startOf('day').toDate() : this.criteria.period.start;
    } else {
      this.msgService.log("reporting.period.start.is.required.text",NotificationType.INFO);
      this.criteria.period.start = DateUtils.getMonday(Date.now());
    }  
  }

  protected endDateSelected(event) {
    this.criteria.period.end = null;
    this.criteria.period.end = event.value? event.value.utc().startOf('day').toDate() : this.criteria.period.end;
  }

  btnActionClick(event) {
    if(event && event.id === this.buttons[1].id){
      // print report
     this.printClicked(event);
    } else if(event && event.id === this.buttons[0].id){
      this.searchClicked(event)
    }
  }

  public searchClicked(event) {    
    this.criteria.allPages = undefined;
    this.criteria.productCodes = Array.from(this.prestationSelected,(prestation)=> prestation.code);
    this.criteria.cashierNumbers = Array.from(this.agentSelected,(agent)=> agent.code);
    //console.log("searchClicked",this.criteria);
    this.search(this.criteria);
  }

  protected handleSearchError(error) {
    if(typeof error === 'string' && error.trim().startsWith("error.bubinga.")) {
        this.msgService.log("costs-regulations-statement.comunication.bubinga.impossible.error", NotificationType.ERROR);
    }
  }
  /**
   * Function triggering the printing of the list of receptions
   */
  protected printClicked(event) {
    this.criteria.lang= this.translateService.currentLang;
    this.downloaderComponent.link = 'reporting/costs-regulations-statement/print';
    this.criteria.productCodes = Array.from(this.prestationSelected,(prestation)=> prestation.code);
    this.criteria.cashierNumbers = Array.from(this.agentSelected,(agent)=> agent.code);
    this.downloaderComponent.criteria=this.criteria;
    this.downloaderComponent.downloadFile({});
    this.downloaderComponent.event.subscribe( (whellDone) => {
        if (whellDone == false) {
          this.msgService.log("form.print.error", NotificationType.ERROR);
        }
    });
  }

  protected printAllClicked(event) {
    this.criteria.allPages = true;
    this.printClicked(null);
  }

  protected printSingleClicked(event) {
    this.criteria.allPages = false;
    this.printClicked(null);
  }

  formatDate(date){
    try {
      //console.log("formatDate", date ,moment(date).format("LL"));
        return "[" + moment(new Date(date)).utc().format("HH:mm") + "] " + moment(new Date(date)).format("LL");
    } catch (e) {
      console.error("Parse date in reception-list fail " , e);
    }
  }

  formatHour(startTime){
    try{
     return moment(new Date((new Date().getFullYear())+"-01-01T" + `${startTime}`)).format("h:mm");
     }catch(e){
       console.error("Parse date in reception-list fail " , e);
     }
  }

  displayPersonFn(agent?: any): string  {
    return agent ? `${agent.name}` : '';
  }

  displayPrestationFn(prestation?: any): string {
    if(prestation.productCode){
      return `${prestation.productCode} - ${prestation.productName}`
    } else{
      return `${prestation.code} - ${prestation.name}`
    }
  }

}
