import { ExamAssessment, Exam } from './../../../model/exam/exam';
import { Component, OnInit, SimpleChanges, ViewChild } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { MedicalAgent } from 'src/app/model/organisation/medical-agent';
import { Funtions } from 'src/app/model/security/funtionalities';
import { User } from 'src/app/model/security/user';
import { NkapHttpService } from 'src/app/services/nkap-http.service';
import { NkapNotificationService, NotificationType } from 'src/app/services/nkap-notification.service';
import { UserAppService } from 'src/app/services/user-app.service';
import { NkapValidators } from '../../base/nkap-validators';
import { IFormActionBarButton, ButtonsBarComponent, FormAction } from '../../buttons-bar/buttons-bar.component';

@Component({
  selector: 'app-exam-assessment-edit',
  templateUrl: './exam-assessment-edit.component.html',
  styleUrls: ['./exam-assessment-edit.component.css']
})
export class ExamAssessmentEditComponent implements OnInit {

  buttons: IFormActionBarButton[] = [];
  form: FormGroup;
  currentObject: ExamAssessment;

  agentConnected: MedicalAgent;
  medicalAgentList: MedicalAgent[];

  currentExam: Exam;

  userConnected: User;
  
  @ViewChild("btnBar")
  btnBarCmp: ButtonsBarComponent;
  resultRejected: boolean;

  constructor(private formBuilder: FormBuilder,private userService: UserAppService,
    private msgService: NkapNotificationService,
    protected router: Router, protected route: ActivatedRoute, private httpService: NkapHttpService<any>) {
    this.currentObject = new ExamAssessment();
    this.buttons = [
      {id: "validate", value: FormAction.CREATE, icon: {type : 'thumb_up'},
        text: 'btn.validate.result.label', disabled: false, functionalities: [Funtions.exam_btn_saveAssessment_fct]},
      {id: "reject", value: FormAction.CREATE, icon: {type : 'thumb_down'},
        text: 'btn.reject.result.label', disabled: false, functionalities: [Funtions.exam_btn_saveAssessment_fct]},
      {id: "list", value: FormAction.VIEW, icon: {type : 'list'},
        text: 'btn.list.label', disabled: false, functionalities: [Funtions.exam_btn_list_fct]}
    ];
  }

  public initForm(result: ExamAssessment): void {
    this.resultRejected = null;
    this.currentObject = result;
    console.log("initForm ", this.currentObject);
    this.form = this.formBuilder.group({
      date: [this.currentObject && this.currentObject.date? this.currentObject.date : new Date(Date.now()), [Validators.required, NkapValidators.notAfterCurrentDay]],
      doctor: [this.currentObject && this.currentObject.doctor? this.currentObject.doctor : this.agentConnected, [Validators.required, NkapValidators.dataWithIdSelected]],
      comments: [this.currentObject? this.currentObject.comments : '', [Validators.required, NkapValidators.notEmpty]],
    });

    this.form.get('doctor').valueChanges.subscribe( (inputValue) => {
      if (typeof inputValue === 'string' ) {
        let url = 'organisation/medical-agent';        
        this.httpService.search({value: inputValue}, url).subscribe ( (result) => {
          this.medicalAgentList = result ? result.datas : [];
        });          
      }
    });
    this.form.get('comments').markAsTouched();
    this.form.get('comments').updateValueAndValidity({onlySelf: true});
    this.form.get('doctor').markAsTouched();
    this.form.get('doctor').updateValueAndValidity({onlySelf: true});
    this.form.statusChanges.subscribe( ()=> {
      this.btnStatusChange();
    });
  }

  btnStatusChange() {
    if(this.btnBarCmp){
      const btnStatus = [
        {id: "validate", disabled: !this.isValid()},
        {id: "reject", disabled: !this.isValid()},
        {id: "list", disabled: false },
      ];  
      console.log(" btnStatusChange ", btnStatus);  
      this.btnBarCmp.setButtonsStatus(btnStatus);
    }
  }

  ngOnInit() { 
    
    this.route.paramMap.subscribe(params => {
      //console.log(" ngOnInit params", params);
      let data: {examId?: string} = (params as any).params;
      console.log(" ngOnInit ", data);
      if(data && data.examId != null) {
        this.httpService.findById(data.examId, "exam/with-details").subscribe ( (result) => {
          this.currentExam = result;
        });  
      }
      this.initForm(this.currentObject);  
    });
     
  }

  ngAfterViewInit(): void {
    // Get the MedicalAgent connected
    this.userConnected = this.userService.getUser();
    let userId = this.userConnected.number;
    if(userId != null) {
      this.httpService.search({userId: userId ? userId : undefined},`organisation/medical-agent`).subscribe(results => {
        if(results.datas && results.datas.length > 0){
          this.agentConnected = results.datas[0];
          if(this.form.get('doctor').invalid) {
            this.form.get('doctor').setValue(this.agentConnected);
          }
        }
      });
    }
  }
  
  ngOnChanges(changes: SimpleChanges): void {
    //console.log("changes ", changes);
    if(changes["currentObject"]){
      console.log("changes on currentObject ", changes["currentObject"], this.currentObject);
      this.initForm(this.currentObject);
      this.form.updateValueAndValidity({onlySelf: true, emitEvent: true});
    }
  }
 
  public isValid(): boolean {
    //this.form.updateValueAndValidity({onlySelf:true});
    let isValid = this.form.valid ? true : false;
    console.log("isValid", isValid, this.form);
    if(!this.currentExam || this.currentExam.id ==null){
      isValid == false;
    }
    if(!this.userConnected || this.userConnected.id ==null){
      isValid == false;
    }
    return isValid;
  }

  public getData(): ExamAssessment {
    if(!this.currentObject){
      this.currentObject = new ExamAssessment();
    }
    this.currentObject = Object.assign( this.currentObject, this.form.value);   
    this.currentObject.user = this.userConnected;
    this.currentObject.exam = null;    
    return this.currentObject;
  }

  public btnActionClick(event) {
    let i = 0; 
    if(event && event.id === this.buttons[i++].id){
      this.resultRejected = null;
      this.save();
    } else if(event && event.id === this.buttons[i++].id){
      this.resultRejected = true;
      this.save();
    } else if(event && event.id === this.buttons[i++].id){
      this.router.navigate(["./exam/list", {examId: this.currentExam? this.currentExam.id : null, search: true}]);
    }
  }

  save() {
    if(this.isValid()) {
      let toSave = this.getData();
      toSave.resultRejected = this.resultRejected ;
      this.httpService.post(toSave, `exam/assessment/save/${this.currentExam.id}`, true).subscribe( (result) => {
        this.msgService.log("exam-assessment.edit.save.succed", NotificationType.SUCCESS);
        this.router.navigate(["./exam/list", {examId: this.currentExam? this.currentExam.id : null, search: true}]);
      }, (error) => {
          this.msgService.log(error, NotificationType.ERROR);
      });
    }
  }

  displayMedicalAgent(agent: MedicalAgent) {
    return agent? agent.fullname : '';
  }

}
