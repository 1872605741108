import { Component, OnInit } from '@angular/core';
import { BusinessUnit } from 'src/app/model/organisation/business-unit';
import { FormControl, Validators } from '@angular/forms';
import { NkapHttpService } from 'src/app/services/nkap-http.service';
import { Router, ActivatedRoute } from '@angular/router';
import { NkapNotificationService, NotificationType } from 'src/app/services/nkap-notification.service';
import { IFormActionBarButton, FormAction } from '../../buttons-bar/buttons-bar.component';
import { NkapValidators } from '../../base/nkap-validators';
import { Funtions } from 'src/app/model/security/funtionalities';

@Component({
  selector: 'app-business-unit-edit',
  templateUrl: './business-unit-edit.component.html',
  styleUrls: ['./business-unit-edit.component.css']
})
export class BusinessUnitEditComponent implements OnInit {

  protected currentObject: BusinessUnit;

  unitControl: FormControl = new FormControl('', [Validators.required]);
  unitList: BusinessUnit[];

  nameControl: FormControl;

  protected buttons: IFormActionBarButton[];

  constructor(private httpService: NkapHttpService<BusinessUnit>, private router: Router,
              private route: ActivatedRoute, private msgService: NkapNotificationService) {
    this.currentObject = new BusinessUnit();
    this.buttons = [
      {id: "save", value: FormAction.CREATE, icon: {type : 'save'},
        text: 'btn.save.label', disabled: false, functionalities: [Funtions.businessUnit_btn_new_fct,Funtions.businessUnit_btn_edit_fct]},
      {id: "list", value: FormAction.VIEW, icon: {type : 'list'},
        text: 'btn.list.label', disabled: false, functionalities: [Funtions.businessUunit_btn_list_fct]}
    ];
  }

  ngOnInit() {
    this.nameControl = new FormControl('', [Validators.required, Validators.minLength(1)]);
    this.nameControl.updateValueAndValidity();
    this.unitControl = new FormControl('', [Validators.required, NkapValidators.dataWithIdSelected]);
    this.route.paramMap.subscribe(params => {
      let data: {id?: string} = (params as any).params;
      if (data.id != null) {
        this.httpService.findById(data.id, 'organisation/business-unit', true).subscribe( (object)=>{
          this.setObjectInView(object);
        }, (errors) => {
          this.msgService.log("form.edit.get.data.error", NotificationType.ERROR);
        });
      }
    });
    
    this.unitControl.valueChanges.subscribe( (inputValue) => {
      if (typeof inputValue === 'string' ) {
        this.currentObject.parent = null;
        this.httpService.search({value: inputValue}, 'organisation/business-unit').subscribe ( (result) => {
           this.unitList = result ? result.datas : [];
        });
      } else {
        this.currentObject.parent = inputValue;
      }
    });
   
  }

  displayUnitFn(unit?: BusinessUnit): string  {
    return unit ? `${unit.name}` : '';
  }

  btnActionClick(event) {
    if(event && event.id === this.buttons[0].id){
      this.save();
    } else if(event && event.id === this.buttons[1].id){
      this.router.navigate(["business-unit/list", {search: true}]);
    }
  }

  isValid(){
    let isValid = true;
    this.getObjectFromView();  
    this.unitControl.markAsTouched();
    this.nameControl.markAsTouched();
    if(!this.currentObject.root && this.unitControl.invalid){
      isValid = false;
    }
    if(this.nameControl.invalid){
      isValid = false;
    }
    console.log( "isValid" , isValid);
    return isValid;
  }

  getObjectFromView() {
    this.currentObject.name = this.nameControl.value;
  }

  setObjectInView(object: BusinessUnit){
    if (object) {
      this.currentObject = object;
      this.nameControl = new FormControl(object.name, [Validators.required, Validators.minLength(1)]);
      this.nameControl.updateValueAndValidity();
      this.unitControl.patchValue(object.parent ? object.parent : null);
    }
  }

  save() {
    if (this.isValid() === true){
      this.httpService.save(this.currentObject, 'organisation/business-unit', true).subscribe( (result) => {
        this.msgService.log("business-unit.edit.save.succed", NotificationType.SUCCESS);
        this.router.navigate(['business-unit/list', {search: true}]);
      }, (error) => {
        if (error && Array.isArray(error)) {
          error.forEach( (err) => {
            this.msgService.log(err, NotificationType.ERROR);
          });
        } else {
          this.msgService.log(error, NotificationType.ERROR);
        }
      });
    } 
  }


}
