import { Injectable, EventEmitter, OnInit } from '@angular/core';
import { Persistent } from '../model/persistent';
import { HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import { NkapNotificationService, NotificationType } from './nkap-notification.service';
import { DataPage, SearchCriteria } from '../model/data-page';
import { Observable, of, Subscribable, Subscription } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';
import { GlobalVariables } from './global-variables';
import { Base64, ServiceUtils } from './utils';
import { NkapKeycloakService } from './nkap-keycloak.service';
import { AccessToken } from '../model/security/access-token';
import { UserAppService } from './user-app.service';

@Injectable({
  providedIn: 'root'
})
export class NkapHttpService<T extends Persistent> {
  tokenSubscription: Subscription;
  usertoken: AccessToken;
  public showSpiner: EventEmitter<boolean>;

  constructor(private userService: UserAppService ,private nkapKeycloakService: NkapKeycloakService,private http: HttpClient, private notificationService: NkapNotificationService) {
    this.showSpiner = notificationService.showSpiner;

    this.nkapKeycloakService.tokenSubject.subscribe(token => {
      this.usertoken = token;
    });

    this.nkapKeycloakService.showSpiner.subscribe(status => {
      this.showSpiner.emit(status);
    });
  }

  /**
   * Get the user actualy connected
   */
    getUserToken() {
      const access = this.usertoken ? this.usertoken.access_token : null;
      //return userToken || Base64.encode( ":" );
      return access;
  }

  protected getHttpOptions(){
    let userToken: string = this.getUserToken();
    return {
        headers: new HttpHeaders(
          {
              'Content-Type': 'application/json',
              'dataType': 'json',
              'Authorization': 'Bearer ' + userToken
          })   
    };
  }

  public save(entity: T, baseURI: string = '', progressSpinner?: boolean,
              unAuth: boolean = false): Observable<T> {
     const savePath: string = 'save';
     const baseMessage = `${baseURI.replace(new RegExp('/', 'g'), '.')}.${savePath.replace(new RegExp('/', 'g'), '.')}`;
     const failMessage = `${baseMessage}.fail.error`;
     const succedMessage = `${baseMessage}.succed.message`;
	if(progressSpinner== true) { this.showSpiner.emit(true);}
     return this.http.post<T>(`${GlobalVariables.CONTEXT_PATH}/${baseURI}/${savePath}`,
                               entity, unAuth === true ? undefined : this.getHttpOptions()).pipe(
	      tap(_ => {if (progressSpinner== true) { this.showSpiner.emit(false); }}),
        catchError( err => {
          if (progressSpinner== true) { this.showSpiner.emit(false); }
          return ServiceUtils.handleError(err);
        })

     );
  }

  public findById(id: number | string, baseURI: string = '', 
                   progressSpinner?: boolean, unAuth: boolean = false): Observable<T> {
    const findByIdPath: string = 'find-by-id';
    const baseMessage = `${baseURI.replace(new RegExp('/', 'g'), '.')}.${findByIdPath.replace(new RegExp('/', 'g'), '.')}`;
    const failMessage = `${baseMessage}.fail.error`;
    const succedMessage = `${baseMessage}.succed.message`;
    if(progressSpinner== true) { this.showSpiner.emit(true);}
    return this.http.get<T>(`${GlobalVariables.CONTEXT_PATH}/${baseURI}/${findByIdPath}/${id}`,
    unAuth ? undefined : this.getHttpOptions()).pipe(
      tap(_ => {if(progressSpinner== true) { this.showSpiner.emit(false);}}),
      catchError( err => {
        if (progressSpinner== true) { this.showSpiner.emit(false); }
        return ServiceUtils.handleError(err);
      })
    );
  }

  public deleteById(id: number | string, baseURI: string = '', 
                     progressSpinner?: boolean, unAuth: boolean = false): Observable<boolean> {
    const deleteByIdPath: string = 'delete-by-id';
    const baseMessage = `${baseURI.replace(new RegExp('/', 'g'), '.')}.${deleteByIdPath.replace(new RegExp('/', 'g'), '.')}`;
    const failMessage = `${baseMessage}.fail.error`;
    const succedMessage = `${baseMessage}.succed.message`;
	if(progressSpinner== true) { this.showSpiner.emit(true);}
    return this.http.delete<any>(`${GlobalVariables.CONTEXT_PATH}/${baseURI}/${deleteByIdPath}/${id}`,
      unAuth === true ? undefined : this.getHttpOptions()).pipe(
	    tap(_ => {if(progressSpinner== true) { this.showSpiner.emit(false);}}),
      catchError( err => {
        if (progressSpinner== true) { this.showSpiner.emit(false); }
        return ServiceUtils.handleError(err);
      })
    );
  }

  public list(baseURI: string = '', 
              progressSpinner?: boolean, unAuth: boolean = false): Observable<T[]> {
      const listPath: string = 'list';
      const baseMessage = `${baseURI.replace(new RegExp('/', 'g'), '.')}.${listPath.replace(new RegExp('/', 'g'), '.')}`;
      const failMessage = `${baseMessage}.fail.error`;
      const succedMessage = `${baseMessage}.succed.message`;
	    if(progressSpinner== true) { this.showSpiner.emit(true);}
      return this.http.get<any>(`${GlobalVariables.CONTEXT_PATH}/${baseURI}/${listPath}`,
      unAuth === true ? undefined : this.getHttpOptions()).pipe(
	    tap(_ => {if(progressSpinner== true) { this.showSpiner.emit(false);}}),	
      catchError( err => {
        if (progressSpinner== true) { this.showSpiner.emit(false); }
        return ServiceUtils.handleError(err);
      })
    );
  }

  public search(criteria: SearchCriteria | any, baseURI: string = '', 
                 progressSpinner?: boolean, unAuth: boolean = false): Observable<DataPage<T>> {
      const searchPath: string = 'search';
      const baseMessage = `${baseURI.replace(new RegExp('/', 'g'), '.')}.${searchPath.replace(new RegExp('/', 'g'), '.')}`;
      const failMessage = `${baseMessage}.fail.error`;
      const succedMessage = `${baseMessage}.succed.message`;
      if(progressSpinner== true) { this.showSpiner.emit(true);}
      return this.http.post<any>(`${GlobalVariables.CONTEXT_PATH}/${baseURI}/${searchPath}`,
                               criteria, unAuth === true ? undefined : this.getHttpOptions()).pipe(
	      tap(_ => {if(progressSpinner== true) { this.showSpiner.emit(false);}}),
        catchError( err => {
          if (progressSpinner== true) { this.showSpiner.emit(false); }
          return ServiceUtils.handleError(err);
        })
     );
  }

  public post(data: any, url: string , 
                 progressSpinner?: boolean, unAuth: boolean = false): Observable<any> {
      const baseMessage = `${url.replace(new RegExp('/', 'g'), '.')}`;
      const failMessage = `${baseMessage}.fail.error`;
      const succedMessage = `${baseMessage}.succed.message`;
	    if(progressSpinner== true) { this.showSpiner.emit(true);}
      return this.http.post<any>(`${GlobalVariables.CONTEXT_PATH}/${url}`,
                               data, unAuth === true ? undefined : this.getHttpOptions()).pipe(
	    tap(_ => {if(progressSpinner== true) { this.showSpiner.emit(false);}}),
      catchError( err => {
        if (progressSpinner== true) { this.showSpiner.emit(false); }
        return ServiceUtils.handleError(err);
      })
     );
  }

  public get(url: string , progressSpinner?: boolean, unAuth: boolean = false): Observable<any> {
      const baseMessage = `${url.replace(new RegExp('/', 'g'), '.')}`;
      const failMessage = `${baseMessage}.fail.error`;
      const succedMessage = `${baseMessage}.succed.message`;
    	if(progressSpinner== true) { this.showSpiner.emit(true);}
      return this.http.get<any>(`${GlobalVariables.CONTEXT_PATH}/${url}`,
                                unAuth === true ? undefined : this.getHttpOptions()).pipe(
      tap(_ => {if(progressSpinner== true) { this.showSpiner.emit(false);}}),
      catchError( err => {
        if (progressSpinner== true) { this.showSpiner.emit(false); }
        return ServiceUtils.handleError(err);
      })
     );
  }
}


