import { Persistent, ClassNameMap } from '../persistent';
import { Patient } from '../organisation/patient';
import { Person } from '../organisation/person';
import { BusinessUnit } from '../organisation/business-unit';
import { TimeSlot } from '../organisation/diary';

export class Apointment extends Persistent {
    public patient?: Patient;
    public date?: Date;
    public hour?: string;
    public personToMeet?: Person;
    public personEncountered?: Person;
    public unit?: BusinessUnit;
    public place?: string;
    public status?: string | ApointmentStatus;
    public description?: string;
    public timeSlot?: TimeSlot;
    constructor(patient?: Patient,date?: Date,personToMeet?: Person,unit?: BusinessUnit) {
        super();
        this.classe = ClassNameMap.Apointment;
        this.patient = patient;
        this.date = date;
        this.personToMeet = personToMeet;
        this.unit = unit;
    }
}

export enum ApointmentStatus{
    PENDING,
    CONFIRM,
    ACHIVED,
    CANCEL,
    POST_PONNED,
}