import { Component, OnInit, ViewChild } from '@angular/core';
import { AbstractControl, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { FormAction, IFormActionBarButton } from 'src/app/components/buttons-bar/buttons-bar.component';
import { PartyComponent } from 'src/app/components/party/party.component';
import { MedicalInstitutionPartner, MedicalInstitutionType } from 'src/app/model/organisation/medical-institution-partner';
import { Party, PartyType } from 'src/app/model/organisation/party';
import { ClassNameMap } from 'src/app/model/persistent';
import { Funtions } from 'src/app/model/security/funtionalities';
import { NkapHttpService } from 'src/app/services/nkap-http.service';
import { NkapNotificationService, NotificationType } from 'src/app/services/nkap-notification.service';

@Component({
  selector: 'app-medical-institution-partner-edit',
  templateUrl: './medical-institution-partner-edit.component.html',
  styleUrls: ['./medical-institution-partner-edit.component.css']
})
export class MedicalInstitutionPartnerEditComponent implements OnInit {
  
  currentObject: MedicalInstitutionPartner;

  @ViewChild('partyC')
  protected partyCmp: PartyComponent;

  protected buttons: IFormActionBarButton[];

  protected party: Party;
  partyType = PartyType.COMPANY;

  //protected type: string;
  medicalInstitutionForm: FormGroup;
  protected types: any[];
  
  constructor(private fb: FormBuilder, private httpService: NkapHttpService<MedicalInstitutionPartner>, private router: Router,
    private route: ActivatedRoute, private msgService: NkapNotificationService) {
    this.currentObject = new MedicalInstitutionPartner();
    this.party = this.currentObject.party;
    this.buttons = [
      {id: "save", value: FormAction.CREATE, icon: {type : 'save'},
      text: 'btn.save.label', disabled: false, functionalities: [
        Funtions.medicalInstitutionPartner_btn_new_fct,Funtions.medicalInstitutionPartner_btn_edit_fct]},
      {id: "list", value: FormAction.VIEW, icon: {type : 'list'},
      text: 'btn.list.label', disabled: false, functionalities: [Funtions.medicalInstitutionPartner_btn_delete_fct]}
    ];
    this.medicalInstitutionForm = this.fb.group({
      type: [this.currentObject.type,  [Validators.required]]
    });
  }

  ngOnInit() {
    this.types = [
      {value: MedicalInstitutionType[MedicalInstitutionType.LABORATORY], text: 'MedicalInstitutionType.' + MedicalInstitutionType[MedicalInstitutionType.LABORATORY] + '.label'},
      {value: MedicalInstitutionType[MedicalInstitutionType.HOSPITAL], text: 'MedicalInstitutionType.' + MedicalInstitutionType[MedicalInstitutionType.HOSPITAL] + '.label'},
    ];
    this.setObjectInView(this.currentObject);
    this.route.paramMap.subscribe(params => {
      let data: {id?: string, type?: string} = (params as any).params;
      if (data.id != null) {
        this.httpService.findById(data.id, 'organisation/medical-institution', true).subscribe( (object)=>{
          this.setObjectInView(object);
        }, (errors) => {
          this.msgService.log("medical-institution-partner.edit.get.data.error", NotificationType.ERROR);
        });
      }
    });
  }

  btnActionClick(event) {
    if(event && event.id === this.buttons[0].id){
      this.save();
    } else if(event && event.id === this.buttons[1].id){
      this.router.navigate(["medical-institution-partner/list", {search: true}]);
    }
  }

  getObjectFromView(){
    if(this.partyCmp){
      this.currentObject.party = this.partyCmp.getData();
      this.currentObject.party.toAddOrUpdate = true;
      this.currentObject.party.classe = ClassNameMap.Company ;
    }
    this.currentObject.type = this.medicalInstitutionForm.get('type').value;;
  }

  setObjectInView(partner: MedicalInstitutionPartner) {
    if (partner) {
      this.currentObject = partner;
      this.party = this.currentObject.party;
      this.medicalInstitutionForm.get('type').patchValue(this.currentObject.type); 
    }
  }

  isValid(){
    let isValid = true;
    isValid = this.medicalInstitutionForm.valid ? true : false;
    if(this.partyCmp){
      isValid = this.partyCmp.isValid();
    }
    if(this.partyCmp.addressCmp && this.partyCmp.addressCmp.isValid() == false) {
      isValid = false;
    }
    return isValid;
  }

  save() {
    this.getObjectFromView();
    if (this.isValid() === true) {
      this.httpService.save(this.currentObject, 'organisation/medical-institution', true).subscribe( (result) => {
        this.msgService.log("medical-institution-partner.edit.save.succed", NotificationType.SUCCESS);
        this.router.navigate(['medical-institution-partner/list', {search: true}]);
      }, (error) => {
        if (error && Array.isArray(error)) {
          error.forEach( (err) => {
            this.msgService.log(err, NotificationType.ERROR);
          });
        } else {
          this.msgService.log("medical-institution-partner.edit.save.fail", NotificationType.ERROR);
        }
      });
    } 
  }
}
