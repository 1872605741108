import { Component, OnInit, AfterViewInit, Input, OnChanges, SimpleChanges, Output, EventEmitter } from '@angular/core';
import { PatientDiagnosis } from 'src/app/model/consultation/patient-diagnosis';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { NkapValidators } from '../../base/nkap-validators';
import { IFormActionBarButton, FormAction } from '../../buttons-bar/buttons-bar.component';
import { MedicalAgent } from 'src/app/model/organisation/medical-agent';
import { UserAppService } from 'src/app/services/user-app.service';
import { NkapHttpService } from 'src/app/services/nkap-http.service';

@Component({
  selector: 'app-patient-diagnosis',
  templateUrl: './patient-diagnosis.component.html',
  styleUrls: ['./patient-diagnosis.component.css']
})
export class PatientDiagnosisComponent implements OnInit , AfterViewInit, OnChanges {
   
  @Input('diagnosis')
  currentObject: PatientDiagnosis;

  @Input('functionality')
  functionality: string = 'patient-diagnosis.edit';

  @Input('buttons')
  buttons: IFormActionBarButton[] = [];

  @Input('editable')
  editable: boolean = true;

  /**
   * Event to emit the btn click
   */
  @Output("event")
  btnEvent: EventEmitter<IFormActionBarButton> = new EventEmitter<IFormActionBarButton>();

  form: FormGroup;
  
  /**
   * The doctor connected
   */
  protected doctorConnected: MedicalAgent;
  protected setDiagnosisAgent: boolean;

  constructor(private formBuilder: FormBuilder,private userService: UserAppService,private httpService:NkapHttpService<any>) {
    this.currentObject = new PatientDiagnosis();
  }

  public initPatientDiagnosisForm(diagnosis: PatientDiagnosis): void {
    //this.currentObject = Object.assign(new PatientDiagnosis(), diagnosis);
    this.currentObject = diagnosis;
    console.log("initPatientDiagnosisForm ", this.currentObject);
    this.form = this.formBuilder.group({
      diagnosisText: [this.currentObject? this.currentObject.diagnosisText : '', [Validators.required, NkapValidators.notEmpty]],
    });
    this.form.get('diagnosisText').valueChanges.subscribe( ()=> { // if the diagnosis changed, the new user must be the one who made it.
      this.setDiagnosisAgent = true;
    });
  }

  ngOnInit() { 
    console.log(" ngOnInit ");
    this.initPatientDiagnosisForm(this.currentObject);   
  }

  ngAfterViewInit(): void {
    // Get the MedicalAgent connected
    let userId = this.userService.getUser().number;
    if(userId != null) {
      this.httpService.search({userId: userId ? userId : undefined},`organisation/medical-agent`).subscribe(results => {
        if(results.datas && results.datas.length > 0){
          this.doctorConnected = results.datas[0];
        }
      });
    }
  }
  
  ngOnChanges(changes: SimpleChanges): void {
    //console.log("changes ", changes);
    if(changes["currentObject"]){
      console.log("changes on currentObject ", changes["currentObject"], this.currentObject);
      this.initPatientDiagnosisForm(this.currentObject);
      this.form.updateValueAndValidity({onlySelf: true, emitEvent: true});
    }
  }
 
  public isValid(): boolean {
    //this.form.updateValueAndValidity({onlySelf:true});
    let isValid = this.form.valid ? true : false;
    console.log("isValid", isValid, this.form);
    return isValid;
  }

  public getData(): PatientDiagnosis {
    if(!this.currentObject){
      this.currentObject = new PatientDiagnosis();
    }
    this.currentObject.diagnosisText = this.form.get('diagnosisText').value;
    if(this.setDiagnosisAgent == true) {
      this.currentObject.doctor = this.doctorConnected;
    }
    this.currentObject.toAddOrUpdate = false;
    this.currentObject.toDelete = false;
    /*if(this.currentObject.id != null && (this.currentObject.diagnosisText == null || this.currentObject.diagnosisText.trim() == "")) {
      this.currentObject.toDelete = true;
      return this.currentObject;
    } else */
    if( this.currentObject.diagnosisText && this.currentObject.diagnosisText.trim() != "") {
      this.currentObject.toAddOrUpdate = true;
      return this.currentObject;
    } else { return null; }
  }

  public btnActionClick(event) {
    if(event) {
      this.btnEvent.emit(event);
    }
  }
}
