import { Component, OnInit, Input, OnChanges, SimpleChanges, ViewChild, ElementRef } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { Observable } from 'rxjs';
import { Patient, Employer } from 'src/app/model/organisation/patient';
import { NkapHttpService } from 'src/app/services/nkap-http.service';
import { Country } from 'src/app/model/location/country';
import { Party } from 'src/app/model/organisation/party';
import {DateAdapter} from '@angular/material/core';
import { TranslateService, LangChangeEvent } from '@ngx-translate/core';
import { GenderType, Profession, Religion } from 'src/app/model/organisation/person';
import { Nameable } from 'src/app/model/persistent';
import { HotKeys, FocusMatInputKeyDownAction } from 'src/app/services/keyboard/hotkeys';
import { MatInput } from '@angular/material';

@Component({
  selector: 'app-patient-identification',
  templateUrl: './patient-identification.component.html',
  styleUrls: ['./patient-identification.component.css']
})
export class PatientIdentificationComponent implements OnInit, OnChanges {
    
  /*Initialization of objects*/
  patientIdentification: Patient;
  myContolNationalitie = new FormControl('', [Validators.required,Validators.maxLength(255)]);  
  optionsNationalite: Country[];

  protected professionCtrl: FormControl = new FormControl('', [Validators.maxLength(255)]);
  protected professionList: Profession[];

  protected religionCtrl: FormControl = new FormControl('', [Validators.maxLength(255)]);
  protected religionList: Religion[];

  age: number;
  /*receive data from the server*/
  @Input() patientInput: Patient;
  /*Select the date that is less than or equal to today*/
  maxDate = new Date(Date.now());

  @ViewChild("nameMatInput")
  protected nameMatInputCmp: MatInput;

  @ViewChild("birthDateMatInput")
  protected birthDateMatInputCmp: MatInput;
  
  @ViewChild("countryMatInput")
  protected countryMatInputCmp: MatInput;

  constructor(private hostElement: ElementRef, private nkapService: NkapHttpService<any>, private _adapter: DateAdapter<Date>,
    private hotKeys: HotKeys,
    private langpicker: TranslateService){
    /*Initializing the parameters*/
    this.patientIdentification = new Patient();
    //console.log(this.patientIdentification);
  }

  ngOnInit() {
     this.hotKeys.init([
        {keys:'meta.n', action: new FocusMatInputKeyDownAction(this.nameMatInputCmp)},
        {keys:'meta.d', action: new FocusMatInputKeyDownAction(this.birthDateMatInputCmp)},
        {keys:'meta.s', action: new FocusMatInputKeyDownAction(this.nameMatInputCmp)},
        {keys:'meta.p', action: new FocusMatInputKeyDownAction(this.countryMatInputCmp)},
    ]);
    this.hotKeys.addShortcut({keys:'meta.b'}).subscribe( (event)=>{
      console.log("hotKeys ", event);
    });
    /*select countrie*/
    this.myContolNationalitie.valueChanges.subscribe ((value) =>{
      if (!value || typeof value === 'string') {
        this.patientIdentification.nationality = null;
        this.getCountry(value);
      } else {
        this.patientIdentification.nationality = value;
        this.patientIdentification.nationality.toAddOrUpdate = true;
      }
    });
    
    this.getCountry(null);

    //init autocomplete for religion
    this.religionCtrl.valueChanges.subscribe( (value)=>{
      if (!value || typeof value === 'string') {
        this.patientIdentification.religion = null;
        this.nkapService.search({ criteria: value }, 'organisation/religion').subscribe((result) => {
          this.religionList = result ? result.datas : [];
        }, (error) => {
          this.religionList = [];
        });
      } else {
        this.patientIdentification.religion = value;
        this.patientIdentification.religion.toAddOrUpdate = true;
      }
    });

    //init autocomplete for profession
    this.professionCtrl.valueChanges.subscribe( (value) => {
      if (!value || typeof value === 'string') {
        this.patientIdentification.profession = null;
        this.nkapService.search({ criteria: value }, 'organisation/profession').subscribe((result) => {
          this.professionList = result ? result.datas : [];
        }, (error) => {
          this.professionList = [];
        });
      } else {
        this.patientIdentification.profession = value;
        this.patientIdentification.profession.toAddOrUpdate = true;
      }
    });
    /*translate datepicker*/
    this.langpicker.onLangChange.subscribe((event: LangChangeEvent) => {
      //this._adapter.setLocale(event.lang);
    });    
  }

  ngOnChanges(changes: SimpleChanges): void {
    if(changes['patientInput']){
      /*If patientInput is different from null and it's definite, 
      then patientIdentification gets patientInput*/
      this.setObjectInView(this.patientInput);
    }
  }

  protected setObjectInView(patientInput: Patient) {
    if (patientInput == null) {
      patientInput = new Patient();
    }
    if (patientInput.employer == null) {
      patientInput.employer = new Employer();
    }
    if (patientInput.employer.party == null) {
      patientInput.employer.party = new Party();
    }
    this.patientIdentification = patientInput;
    this.myContolNationalitie.patchValue(this.patientIdentification.nationality);
    this.religionCtrl.patchValue(this.patientIdentification.religion);
    this.professionCtrl.patchValue(this.patientIdentification.profession);
    this.ageCalcul();
  }

  /*Send a request to the server and return the result*/
  public getCountry(value) {
    this.nkapService.search({ criteria: value }, 'location/country').subscribe((result) => {
      this.optionsNationalite = result ? result.datas : [];
    });
  }

  /*Test if the objects are all different from zero*/
  isValid(){
    this.getData();
    let isValid = true;
    // console.log("isValid ", this.myContolNationalitie);
    this.myContolNationalitie.markAsTouched();
    this.professionCtrl.markAsTouched();
    this.religionCtrl.markAsTouched();
    if(!(this.patientIdentification.name != null && 
      this.patientIdentification.name.trim() != "" &&
      this.patientIdentification.name.toString().trim() != null && 
      this.patientIdentification.birthdate != null && 
      this.patientIdentification.gender != null &&
      (this.patientIdentification.nationality != null && 
        this.patientIdentification.nationality.name != null))){
          isValid = false;
    }
    return isValid;
  }

  /*Test if all objects are recovered*/
  getData(){
    //console.log("patient", JSON.stringify(this.patientIdentification));
    if(this.patientIdentification && this.patientIdentification.employer){
      if(this.patientIdentification.employer.party && !this.patientIdentification.employer.party.name){
        this.patientIdentification.employer = null;
      }
    } 
    const nationality = this.myContolNationalitie.value
    if(this.patientIdentification.nationality == null && nationality != null && nationality.trim() !=""){
      this.patientIdentification.nationality = new Country();
      this.patientIdentification.nationality.name = nationality;
    } 
    const religion = this.religionCtrl.value;
    if(this.patientIdentification.religion == null && religion != null && religion.trim() !=""){
      this.patientIdentification.religion = new Religion(religion);
    }
    const profession = this.professionCtrl.value;
    if(this.patientIdentification.profession == null && profession != null && profession.trim() !=""){
      this.patientIdentification.profession = new Profession(profession);
    }
    return this.patientIdentification;
  }

  /*select sexe */
  sexes: any[] = [
    {value: GenderType[GenderType.FEMALE], text:'translate.Feminin'},
    {value: GenderType[GenderType.MALE], text: 'translate.Masculin'}
  ];

  /*Function that automatically calculates the age of the patient.*/
  ageCalcul() {
    if(this.patientIdentification.birthdate != null){
      this.age = new Date(Date.now()).getFullYear() - new Date(this.patientIdentification.birthdate).getFullYear();
      console.log(this.patientIdentification.birthdate);
    }
  }

  displayNameable(nameable: Nameable): string {
    return nameable ? nameable.name : "";
  }
}
