import { Drug } from './../../../../model/prescriptions/prescription';
import { NkapValidators } from 'src/app/components/base/nkap-validators';
import { PrestationEditModalComponent } from './../../../prestation/prestation-edit-modal/prestation-edit-modal.component';
import { ModalComponentService } from './../../../base/modal/modal.component';
import { Party } from 'src/app/model/organisation/party';
import { Component, OnInit, ViewChild, Input, OnChanges, SimpleChanges, EventEmitter, Output } from '@angular/core';
import { MedicalPrescription, MedicalPrescriptionItem, TimeDuration, TimeUnit, Prescription } from 'src/app/model/prescriptions/prescription';
import { MatTable, MatTableDataSource, MatDialogRef } from '@angular/material';
import { IFormActionBarButton } from '../../../buttons-bar/buttons-bar.component';
import { FormGroup, FormBuilder, Validators, FormArray, AbstractControl } from '@angular/forms';
import { Prestation, PrestationGroup } from 'src/app/model/prestation/prestation';
import { MedicalAgent } from 'src/app/model/organisation/medical-agent';
import { MedicalFolder } from 'src/app/model/admission/medical-folder';
import { NkapHttpService } from 'src/app/services/nkap-http.service';
import { ActivatedRoute, Router } from '@angular/router';
import { NkapNotificationService, NotificationType } from 'src/app/services/nkap-notification.service';
import * as moment from 'moment';
import { BehaviorSubject } from 'rxjs';

@Component({
  selector: 'app-medical-prescription-edit-form',
  templateUrl: './medical-prescription-edit-form.component.html',
  styleUrls: ['./medical-prescription-edit-form.component.css']
})
export class MedicalPrescriptionEditFormComponent implements  OnChanges{

  @Input('prescription')
  currentObject: MedicalPrescription;

  validityErrors: string[];// list of errors in the Prestation  

  @ViewChild(MatTable)
  tableCmp: MatTable<any>;
  prescriptionItemsDataSource = new BehaviorSubject<AbstractControl[]>([]);
  prescriptionItemsRows: FormArray;
  protected prescriptionItemsToDelete: MedicalPrescriptionItem[] = [];
  invalidPrescriptionItemsError = Prescription.invalidPrescriptionItemsError;
  
  protected buttons: IFormActionBarButton[];

  form: FormGroup;

  prescriptionCodeEditable: boolean = false;

  displayers: any = {displayPrestation: Prestation.displayPrestation};

  //the agent connected (need ID, number and fullName)
  @Input()
  agent: MedicalAgent;

  // the the medical folder selected (need ID, Number, and Patient fullname)
  @Input()
  medicalFolder: MedicalFolder;
  @Input('select-patient')
  selectPatient: boolean = false;

  validityDuration: TimeDuration;

  expirationDate: Date;
  
  // medical prestations in autocomplete selection 
  medicalPrestations: any[];

  afterSaveEvent: EventEmitter<{result:NotificationType, value: Prescription}> = new EventEmitter<{result:NotificationType, value: Prescription}>();
  
  statusChanged: EventEmitter<any> = new EventEmitter<any>();
  
  dialogRef: MatDialogRef<any>;
  displayNewMedicalPrestationDialog: boolean = true;

  constructor(private formBuilder: FormBuilder,
    private httpService: NkapHttpService<any>, 
    private router: Router, private route: ActivatedRoute,
     private msgService: NkapNotificationService,
     private modalService: ModalComponentService) {
      this.setAgent(null);   
      this.currentObject = new MedicalPrescription();
      this.currentObject.date = new Date(Date.now());
      this.currentObject.medicalAgent = new MedicalAgent();
      this.currentObject.medicalFolder = new MedicalFolder();
      this.currentObject.prescriptionItems = [new MedicalPrescriptionItem()];
      this.initForm(this.currentObject);
   }

  ngOnChanges(changes: SimpleChanges): void {
    //console.log("ngOnChanges ", changes);
    if(changes['currentObject']) {
      this.initForm(changes['currentObject'].currentValue);
      this.form.updateValueAndValidity({onlySelf: true, emitEvent: true});
    } else if(changes['agent']) {
      this.form.updateValueAndValidity({onlySelf: true, emitEvent: true});
    } else if(changes['medicalFolder']) {
      this.form.updateValueAndValidity({onlySelf: true, emitEvent: true});
    }
  }

  public initForm(prescription: MedicalPrescription): void {
    this.currentObject = Object.assign(new MedicalPrescription(), prescription);
    //console.log("initForm ", this.currentObject);
    this.prescriptionItemsRows = this.formBuilder.array([]);
    this.form = this.formBuilder.group({
      date: [this.currentObject? this.currentObject.date : '', [Validators.required, NkapValidators.notAfterCurrentDay]],
      expirationDate: [this.currentObject? this.currentObject.expirationDate : null, []],
      recommendations: [this.currentObject? this.currentObject.recommendations : '', []],
      prescriptionItems: this.prescriptionItemsRows
    });
    this.validityDuration = this.currentObject.validityDuration != null ? Object.assign(new TimeDuration(),this.currentObject.validityDuration) : null;
    this.expirationDate = this.currentObject.expirationDate;
    let items = [];
    if(this.currentObject && this.currentObject.prescriptionItems) {
      this.currentObject.prescriptionItems.forEach( (data)=> {
        let item = Object.assign(new MedicalPrescriptionItem(),data);
        items.push(item);
        this.addNewLine(item);
      });
      this.currentObject.prescriptionItems = items;
    } else {
      this.currentObject.prescriptionItems = [new MedicalPrescriptionItem()];
      this.addNewLine(this.currentObject.prescriptionItems[0]);      
    }
    
    this.setMedicalFolder(this.currentObject ? this.currentObject.medicalFolder : null );
    this.setAgent(this.currentObject ? this.currentObject.medicalAgent : null);
    this.checkIfShouldAddNewLine(false);
    this.form.statusChanges.subscribe( (status)=>{
      this.statusChanged.emit(this.getFormStatus());
    });
    this.statusChanged.emit(this.getFormStatus());
  }

  isValid(): boolean {
    let currentObject: MedicalPrescription = this.getData();
    let isValid = currentObject? currentObject.isValid() : false;
    this.validityErrors =  isValid === false ? currentObject.validityErrors : null;  
    return isValid;
  }

  getCurrentErrors() {
    return this.validityErrors;
  }

  getData(): MedicalPrescription {
    this.currentObject = Object.assign(new MedicalPrescription(),this.currentObject, this.form.value);
    //console.log("getData",this.currentObject );
    this.currentObject.medicalAgent = this.agent && this.agent.id != null ? this.agent : null;
    this.currentObject.medicalFolder = this.medicalFolder && this.medicalFolder.id != null ? this.medicalFolder: null;
    this.currentObject.validityDuration = this.validityDuration;
    this.currentObject.expirationDate = this.expirationDate;
    // Get items as MedicalPrescriptionItem object
    this.currentObject.prescriptionItems = [];
    if(this.prescriptionItemsRows && this.prescriptionItemsRows.length > 1) {
      for(let i = 0; i < this.prescriptionItemsRows.length - 1 ; i++) { // we remove last line
        this.currentObject.prescriptionItems.push(
          Object.assign(new MedicalPrescriptionItem(), this.prescriptionItemsRows.at(i).value)
        );
      }
    } 
    if (this.prescriptionItemsToDelete.length > 0) {
      this.currentObject.prescriptionItems = this.currentObject.prescriptionItems.concat(this.prescriptionItemsToDelete);
    }
    //console.log("getData",this.currentObject );
    //console.log("getData", this.currentObject);
    return this.currentObject;
  }

  setQuantity(element,event) {
    if(event && event.target && Number(event.target.value) <= 0) {
      //console.log("setQuantity " , Number(event.target.value));
      element.quantity = 1;
    }
    if(element) {
      element.toAddOrUpdate = true;
    } 
  }

  filterPrestationOptions(event) {
    console.log(event);
    if(event && event.target){
      const value = event.target.value;
      this.httpService.search({value: value},"prescription/drug").subscribe( (results)=>{
        //console.log("filterPrestationOptions", results);
        this.medicalPrestations = results? results.datas : [];
      });
    }
  }

  deleteElement(element,index){
    //console.log("deleteElement ", element, index);
    this.prescriptionItemsRows.removeAt(index);
    if(element.id != null) {
      element.toDelete = true;
      this.prescriptionItemsToDelete.push(element);
    }
    //console.log("prescriptionItemsToDelete ", this.prescriptionItemsToDelete);
    this.prescriptionItemsDataSource.next(this.prescriptionItemsRows.controls);
    this.checkIfShouldAddNewLine(true);
  }

  addNewLine(element: MedicalPrescriptionItem) {
    let lineGroup =this.formBuilder.group({
      drug: [element? element.drug : null, [Validators.required, NkapValidators.notEmpty]],
      conditioning: [element? element.conditioning: ''],
      quantity: [element? element.quantity :1,[NkapValidators.validNumberUpperThanZero]],
      protocol: [element? element.protocol : '', [Validators.required, NkapValidators.notEmpty]]
    });
    lineGroup.get('drug').markAsTouched();
    if(this.prescriptionItemsRows.length==0){
      lineGroup.get('protocol').markAsTouched();  
      lineGroup.get('quantity').markAsTouched();  
    }
    lineGroup.get('quantity').valueChanges.subscribe( (qty)=> {
      if(lineGroup.get('quantity').value <= 0){
        lineGroup.get('quantity').setValue(1);
      }
    }); 
    
    // To search drugs from server
    lineGroup.get('drug').valueChanges.subscribe( (inputValue)=> {
      console.log("drug valueChanges ",inputValue, (typeof inputValue === 'string' ));
      if (typeof inputValue === 'string' ) {
        this.httpService.search({value: inputValue},"prescription/drug").subscribe( (results)=>{
          this.medicalPrestations = results? results.datas : [];
        });
      }
    }); 
    // To create the drug corresponding to the text the user set in input, when he didn't choose an option
    lineGroup.get('drug').statusChanges.subscribe( (status)=> {
      let inputValue = lineGroup.get('drug').value ;
      console.log("drug statusChanges ",inputValue, (typeof inputValue === 'string' ));
      if (inputValue != null && typeof inputValue === 'string' && lineGroup.get('drug').valid == true) {
        lineGroup.get('drug').setValue(new Drug(inputValue));
      } else if(inputValue != null && lineGroup.get('drug').valid == false){
        lineGroup.get('drug').setValue(null);
      }
      lineGroup.get('protocol').markAsTouched();  
      lineGroup.get('quantity').markAsTouched();  
    }); 
    
    lineGroup.statusChanges.subscribe( (status)=> {
      //console.log("lineGroup.statusChanges",status);
      let inputValue = lineGroup.get('drug').value ;
      if (inputValue != null && typeof inputValue !== 'string') {
          this.checkIfShouldAddNewLine(true);
      }
    });
    this.prescriptionItemsRows.push(lineGroup);
    this.prescriptionItemsDataSource.next(this.prescriptionItemsRows.controls);
  }

  displayPrestation(drug: Drug) {
    return drug? drug.name : "";
  }
  
  displayDate(date) {
    try {
        return moment(new Date(date)).format("LL");
    } catch (e) {
      console.error("Parse date in reception-list fail " , e);
    }
  }

  btnActionClick(event) {
    if(event && event.id === this.buttons[0].id){
      this.save();
    } else if(event && event.id === this.buttons[1].id){
      this.router.navigate(["medical-prescription/list", {search: true}]);
    }
  }
  
  dateSelected(event) {

  }

  durationChanged(event:TimeDuration) {
    console.log("durationChanged , calculate new expriration date ", event);
    if(event != null && event.isValid() == true){
      this.validityDuration = event;
      this.expirationDate = event? event.getAchievementDate(this.form.get('date').value) : null;
      //console.log("durationChanged , calculate new expriration date ", this.expirationDate);
    } else {
      this.validityDuration = null;
      this.expirationDate = null;
    }
  }


  save() {
    if(this.isValid() === true) {
      let dataToSave = this.getData() ;
      this.httpService.save(dataToSave, "prescription/medical-prescription",true).subscribe( (result)=>{
        this.msgService.log("medical-prescription.form.edit.save.successfully.message", NotificationType.SUCCESS);
        this.afterSaveEvent.emit({result:NotificationType.SUCCESS, value: result});
      }, (error)=>{
        //console.log("error ", error);
        this.msgService.log(error, NotificationType.ERROR);
        this.afterSaveEvent.emit({result:NotificationType.ERROR, value: dataToSave});
      });
    } else {
      this.msgService.log(this.getCurrentErrors(), NotificationType.ERROR);
    }
  }

  checkIfShouldAddNewLine(emitStatusChanged: boolean) {
    if(this.prescriptionItemsRows == null || this.prescriptionItemsRows.length < 1) {
      //console.log("checkIfShouldAddNewLine empty table");
      this.addNewLine(new MedicalPrescriptionItem());
      if( emitStatusChanged == true) {this.statusChanged.emit(this.getFormStatus());}
    } else {
      const lastLine = this.prescriptionItemsRows.at(this.prescriptionItemsRows.length - 1);
      //console.log("checkIfShouldAddNewLine ",lastLine, lastLine.valid);
      if(lastLine.valid==true) {
          this.addNewLine(new MedicalPrescriptionItem());
          if( emitStatusChanged == true) {this.statusChanged.emit(this.getFormStatus());}
      }
    }
  }

  setAgent(agent: MedicalAgent): void {
    this.agent = agent != null? agent : this.agent;
    this.form? this.form.updateValueAndValidity({onlySelf: true, emitEvent: true}) : null;
  }

  setMedicalFolder(folder: MedicalFolder, selectPatient?: boolean): void {
    //console.log("setMedicalFolder ", folder,selectPatient );
    this.medicalFolder = folder;
    this.form? this.form.updateValueAndValidity({onlySelf: true, emitEvent: true}) : null;
    this.selectPatient = selectPatient != null && selectPatient != undefined ? selectPatient : this.selectPatient;
    if(this.medicalFolder == null) {
      this.selectPatient = true;
    }
    //console.log("selectPatient ", this.selectPatient);
  }

  getPatientSelected(event) {
    this.medicalFolder = event;
    this.statusChanged.emit(this.getFormStatus());
  }

  getFormStatus():boolean {
    let formIsValid: boolean = this.itemsValid() && 
    (this.form.get('date').valid == true) &&
    ( this.validityDuration != null ? this.validityDuration.isValid() : true) &&
    this.medicalFolder != null && this.medicalFolder.id != null && 
    this.agent != null && this.agent.id != null;
    console.log("getFormStatus", {formIsValid, duration : this.validityDuration});
    return formIsValid;
  }

  itemsValid(): boolean{
    let itemsValid = true;
    if(this.prescriptionItemsRows && this.prescriptionItemsRows.length > 1){
      for(let i = 0; i < this.prescriptionItemsRows.length - 1 ; i++){// we do not check the last line
        if(this.prescriptionItemsRows.at(i).valid == false){
          itemsValid = false;
          break;
        }
      }
    } else {
      itemsValid = false;
    }
    return itemsValid;
  }
  
  checkDuration(event) {
    if(this.form && event && event.target && event.target.value.trim()!="" && Number(event.target.value) <= 0) {
      this.form.get("validityDuration.duration").patchValue(1);
    }
  }


}
