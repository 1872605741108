
import {Persistent, ClassNameMap} from "../persistent";
import { BusinessUnit } from '../organisation/business-unit';

export class User extends Persistent {

    public login?: string;

    public lang?: string;

    public hashPassword?: string;

    public password?: string;

    public loggedInService?: BusinessUnit;

    public userName?: string;

    public userMail?: string;

    public userPhoneNumber?: string;

    public number?: string;

    constructor(){
        super();
        this.classe = ClassNameMap.User;
    }
}