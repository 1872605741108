import { Party } from '../organisation/party';
import { Document } from '../admission/document';
import { User } from '../security/user';
import { Persistent, ClassNameMap } from '../persistent';
import { Prestation, PrestationFamily } from '../prestation/prestation';
import { Period } from './Period';
import { MedicalFolder } from './medical-folder';

export class CareCoverageDoc extends Persistent {
    public patientFolder?: MedicalFolder;
    public patientFolderId?: string | number;
    public isValid?: boolean;
    public receptionDate?: Date;
    public oldVersion?: CareCoverageDoc;
    public prestations?: PrestationCovered[];
    public prestationTypes?: PrestationFamilyCovered[];
    public prestationCategories?: PrestationCategoryCovered[];
    public printVersion?: CoverageDocument[];
    public validityChecked?: CareCoverageDocValidation[];
    public documentIssuer?: ThirdPartyPayer;
    public validityPeriod?: Period;
    public type?: CareCoverageDocType;
    public endOfLifeReason?: EndOfLifeReason;
    public number?: string;
    public file?: any;
    constructor() {
        super();
        this.classe = ClassNameMap.CareCoverageDoc;
    }
}

export class ThirdPartyPayer extends Persistent{
    public isStillValid?: boolean;
    public notValidReason?: string;
    public party?: Party;
    public documentIssuer?: CareCoverageDoc[];
    public type?: ThirdPartyPayerType | string;

    constructor(){
        super();
        this.party = new Party();
        this.classe = ClassNameMap.ThirdPartyPayer;
    }
}

export class CareCoverage extends Persistent {
    public maxAmount?: any;
    public percent?: any;
    public careCoverageDoc?: CareCoverageDoc;
    constructor() {
        super();
        this.classe = ClassNameMap.CareCoverage;
    }
}

export class PrestationCategoryCovered extends CareCoverage {
    public category?: PrestationCategory;
    constructor() {
        super();
        this.classe = ClassNameMap.PrestationCategoryCovered;
    }
}

export class PrestationFamilyCovered extends CareCoverage {
    public family?: PrestationFamily;
    constructor() {
        super();
        this.classe = ClassNameMap.PrestationTypeCovered;
    }
}
export class PrestationCovered extends CareCoverage {
    public prestation?: Prestation;
    constructor() {
        super();
        this.classe = ClassNameMap.PrestationCovered;
    }
}

export class CareCoverageDocValidation extends Persistent {
    public comment?: string;
    public person?: User;
    public validationDate?: Date;
    public careCoverageDoc?: CareCoverageDoc;
}

export class EndOfLifeReason extends Persistent {
    public date?: Date;
    public reason?: string;
    public user?: User;
    public careCoverageDoc?: CareCoverageDoc;
}

export class CoverageDocument extends Document {
    public careCoverageDoc?: CareCoverageDoc;
}

export enum CareCoverageDocType {
    INSURANCE_CARD,
    CARE_VOUCHER
}

export enum ThirdPartyPayerType {
    INSURANCE,
    EMPLOYER,
    PERSONNE
}

export enum PrestationCategory {
  ALL,
  AMBULATORY,
  STATIONARY
}


