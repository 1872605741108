import { Component, OnInit, Input, SimpleChanges, OnChanges } from '@angular/core';
import { FormGroup, FormBuilder, Validators, AbstractControl, FormControl } from '@angular/forms';
import { Person, GenderType, Profession, Religion } from 'src/app/model/organisation/person';
import { Country } from 'src/app/model/location/country';
import { NkapValidators } from '../../base/nkap-validators';
import { Observable } from 'rxjs';
import { NkapHttpService } from 'src/app/services/nkap-http.service';
import { Party } from 'src/app/model/organisation/party';
import { Nameable } from 'src/app/model/persistent';

@Component({
  selector: 'app-person-identification',
  templateUrl: './person-identification.component.html',
  styleUrls: ['./person-identification.component.css']
})
export class PersonIdentificationComponent implements OnInit, OnChanges {

  
  @Input()
  person: Person;

  @Input('remove')
  fieldsToRemove: string[];

  personIdentificationForm: FormGroup;
  nationalityControl: AbstractControl;

  displayer: Person = new Person();

  nationalities: Observable<any[]>;
  nationalitySelected: Country;

  /** Person age */
  age: number;
  /*Select the date that is less than or equal to today*/
  maxDate = new Date(Date.now());

  /*select sexe */
  sexes: any[];

  protected professionCtrl: AbstractControl ;
  protected professionList: Profession[];
  protected professionSelected: Profession;

  protected religionCtrl: AbstractControl;
  protected religionList: Religion[];
  protected religionSelected: Religion;


  constructor(private fb: FormBuilder, private nkapService: NkapHttpService<any>) { 
    this.personIdentificationForm = this.fb.group({
      name: ['',  [Validators.required, NkapValidators.notEmpty, Validators.maxLength(255)]],
      surname: [''],
      birthdate: ['', [NkapValidators.notAfterCurrentDay]],
      age: [{value: '', disabled: true}],
      gender: [null, [Validators.required]],
      nationality: [null],
      profession: [null, [Validators.maxLength(255)]],
      religion: [null, [Validators.maxLength(255)]]
    });
    this.nationalityControl = this.personIdentificationForm.get('nationality');
    this.professionCtrl = this.personIdentificationForm.get('profession');
    this.religionCtrl = this.personIdentificationForm.get('religion');
    this.person = new Person();
    this.personIdentificationForm.patchValue(this.person);
    this.nationalityControl.patchValue(this.person ? this.person.nationality : '');
    this.religionCtrl.patchValue(this.person ? this.person.religion : '');
    this.professionCtrl.patchValue(this.person ? this.person.profession : '');
    
  }

  ngOnInit() {
    this.sexes = [
      {value: GenderType[GenderType.FEMALE], text: 'GenderType.' + GenderType[GenderType.FEMALE] + '.label'},
      {value: GenderType[GenderType.MALE], text: 'GenderType.' + GenderType[GenderType.MALE] + '.label'}
    ];
    
    this.nationalityControl.valueChanges.subscribe( (textInput) => {
      if (!textInput || typeof textInput === 'string') {
        this.nationalitySelected = null;
        this.getCountry(textInput);
      } else {
        this.nationalitySelected = textInput;
      }
    });

    //init autocomplete for religion
    this.religionCtrl.valueChanges.subscribe( (value)=>{
      if (!value || typeof value === 'string') {
        this.religionSelected = null;
        this.nkapService.search({ criteria: value }, 'organisation/religion').subscribe((result) => {
          this.religionList = result ? result.datas : [];
        }, (error) => {
          this.religionList = [];
        });
      } else {
        this.religionSelected = value;
      }
    });

    //init autocomplete for profession
    this.professionCtrl.valueChanges.subscribe( (value) => {
      if (!value || typeof value === 'string') {
        this.professionSelected = null;
        this.nkapService.search({ criteria: value }, 'organisation/profession').subscribe((result) => {
          this.professionList = result ? result.datas : [];
        }, (error) => {
          this.professionList = [];
        });
      } else {
        this.professionSelected = value;
      }
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.person) {
      this.personIdentificationForm.patchValue(this.person);
      this.nationalityControl.patchValue(this.person? this.person.nationality : '');
      this.religionCtrl.patchValue(this.person ? this.person.religion : '');
      this.professionCtrl.patchValue(this.person ? this.person.profession : '');
    
      this.ageCalcul();
    }
    if (changes.fieldsToRemove) {
      console.log("fieldsToRemove", this.fieldsToRemove , this.fieldsToRemove? this.fieldsToRemove.indexOf('profession') : 0);
    }
  }

  
  /*Send a request to the server and return the result*/
  public getCountry(value) {
    this.nkapService.search({ criteria: value }, 'location/country').subscribe((result) => {
      this.nationalities = new Observable((observer) => {
        observer.next(result ? result.datas : []);
        observer.complete();
      });
    });
  }

  /*Function that automatically calculates the age of the patient.*/
  ageCalcul() {
    if (this.personIdentificationForm.get('birthdate') != null && this.personIdentificationForm.get('birthdate').value != null) {
      let age = Party.getAge(this.personIdentificationForm.get('birthdate').value);
      this.personIdentificationForm.get('age').setValue( age);
      //console.log("ageCalcul ", this.personIdentificationForm.get('birthdate').value);
    }
  }
  
  addNewCountry(event){
    if (this.nationalitySelected == null) {
      this.nationalitySelected = new Country();
      this.nationalitySelected.name = this.nationalityControl.value;
      this.nationalityControl.setValue(this.nationalitySelected);
    }
  }

  addNewProfession(event) {
    if (this.professionSelected == null && this.professionCtrl.value && this.professionCtrl.value.trim() != "") {
      this.professionSelected = new Profession(this.professionCtrl.value);
      this.professionCtrl.setValue(this.professionSelected);
    }
  }

  addNewReligion(event) {
    if (this.religionSelected == null && this.religionCtrl.value && this.religionCtrl.value.trim() != "") {
      this.religionSelected = new Religion(this.religionCtrl.value);
      this.religionCtrl.setValue(this.religionSelected);
    }
  }

  public isValid(): boolean {
      this.personIdentificationForm.get('name').markAsTouched({onlySelf: false});
      this.personIdentificationForm.get('birthdate').markAsTouched({onlySelf: false});
      this.personIdentificationForm.get('gender').markAsTouched({onlySelf: false});
      this.personIdentificationForm.get('profession').markAsTouched({onlySelf: false});
      this.personIdentificationForm.get('religion').markAsTouched({onlySelf: false});
      return this.personIdentificationForm.invalid === true ? false : true;
  }

  public getData(): Person {
    let person: Person = this.personIdentificationForm.value;
    person.toAddOrUpdate = true;
    person.id = this.person.id;
    if(person.nationality && (person.nationality.name == null || person.nationality.name.trim() == "")){
      person.nationality = undefined;
    }
    if(person.religion && (person.religion.name == null || person.religion.name.trim() =="")){
      person.religion = undefined;
    }
    if(person.profession && (person.profession.name == null || person.profession.name.trim() =="")){
      person.profession = undefined;
    }
    return person;
  }

  displayNameable(nameable: Nameable): string {
    return nameable ? nameable.name : "";
  }
}
