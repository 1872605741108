import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { ChangeDetectorRef, Component, OnInit, ViewChild } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, FormControl } from '@angular/forms';
import { MatAutocompleteSelectedEvent } from '@angular/material';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { NkapListComponent } from 'src/app/components/base/nkap-list-component';
import { ButtonsBarComponent, FormAction } from 'src/app/components/buttons-bar/buttons-bar.component';
import { MedicalInstitutionPartner, MedicalInstitutionType } from 'src/app/model/organisation/medical-institution-partner';
import { Funtions } from 'src/app/model/security/funtionalities';
import { NkapHttpService } from 'src/app/services/nkap-http.service';
import { NkapNotificationService, NotificationType } from 'src/app/services/nkap-notification.service';
import { UserAppService } from 'src/app/services/user-app.service';

@Component({
  selector: 'app-medical-institution-partner-list',
  templateUrl: './medical-institution-partner-list.component.html',
  styleUrls: ['./medical-institution-partner-list.component.css']
})
export class MedicalInstitutionPartnerListComponent extends NkapListComponent<MedicalInstitutionPartner>{

  searchForm: FormGroup;
  
  /** Medical agent type */
  protected typeList: {value?: any, text?: any}[];
  protected typeControl: AbstractControl = new FormControl();
  protected typesSelected: any[];
  
  @ViewChild("btnBar")
  btnBarCmp: ButtonsBarComponent;

  separatorKeysCodes: number[] = [ENTER, COMMA];
  
  constructor(private fb: FormBuilder, 
    protected _ref: ChangeDetectorRef, protected httpService: NkapHttpService<MedicalInstitutionPartner>, 
    protected router: Router, protected route: ActivatedRoute, protected userService: UserAppService,
    protected msgService: NkapNotificationService, protected translateService: TranslateService) {
   super(_ref, httpService, router, route, userService, msgService, translateService);
      this.searchBaseUrl = "organisation/medical-institution";

      this.typesSelected = [];

      this.buttons = [
        {id: "add", value: FormAction.CREATE, icon: {type : 'add_circle_outline', color: '#0099cc'},
          text: 'btn.new.label', disabled: false, functionalities: [Funtions.medicalInstitutionPartner_btn_new_fct]},
        {id: "edit", value: FormAction.UPDATE, icon: {type : 'edit',color:'#ff9933'}, 
          text: 'medical-institution-partner.btn.edit.label', disabled: false, functionalities: [Funtions.medicalInstitutionPartner_btn_edit_fct]},
        {id: "delete", value: FormAction.DELETE, icon: {type :'delete_forever',color:'#ff9933'},
          text: 'medical-institution-partner.btn.delete.label', disabled: false, functionalities: [Funtions.medicalInstitutionPartner_btn_delete_fct]}
      ];
      this.criteria.number = "";
  }

  ngOnInit() {
    super.ngOnInit();    
    
    this.typeList = [
      {value: null, text: ''},
      {value: MedicalInstitutionType[MedicalInstitutionType.LABORATORY], text: 'MedicalInstitutionType.' + MedicalInstitutionType[MedicalInstitutionType.LABORATORY] + '.label'},
      {value: MedicalInstitutionType[MedicalInstitutionType.HOSPITAL], text: 'MedicalInstitutionType.' + MedicalInstitutionType[MedicalInstitutionType.HOSPITAL] + '.label'},
    ];
  }

  protected search(criteria: any) {
    criteria.types = this.typesSelected;
    super.search(criteria);
  }

  
  btnActionClick(event) {
    if(event && event.id === this.buttons[0].id ){
      this.router.navigate(["medical-institution-partner/edit"]);
    } else if(event && event.id === this.buttons[1].id){
      if( !this.selection.selected || this.selection.selected.length != 1){
        this.msgService.log("medical-institution-partner.list.no.selected.item.error", NotificationType.ERROR);
      }else{
        this.router.navigate(["medical-institution-partner/edit", {id: this.selection.selected[0].id}]);
      }
    } else if(event && event.id === this.buttons[2].id){
      if( !this.selection.selected || this.selection.selected.length < 1){
        this.msgService.log("medical-institution-partner.list.no.selected.item.error", NotificationType.ERROR);
      }else{
        this.deleteSelected();
      }
    }
  }

  displayTypeFn(elmt:any){
    if(elmt != null ) {
      return `MedicalInstitutionType.${elmt}.label`;
    }
  }

  protected viewElement(element) {
    this.router.navigate(['medical-institution-partner/show', {id: element.id}]);
  }

  deleteSelected(){
    this.msgService.confirm(this.translate.transform("medical-institution-partner.delete.confirm.text")).subscribe( (result) => {
      if(result === true){
        let doctorSelected = this.selection.selected[0];
        this.httpService.deleteById(doctorSelected.id, 'organisation/medical-institution').subscribe( (val) => {
          this.msgService.log("medical-institution-partner.delete.succed", NotificationType.SUCCESS);
          this.selection.clear();
          this.searchClicked({});
        }, (error) => {
          this.msgService.log("medical-institution-partner.delete.fail", NotificationType.ERROR);
        });
      }
    });
  }

  protected setCriteriaDataInView(cachedNavigationData: any) {
    super.setCriteriaDataInView(cachedNavigationData);
    if(cachedNavigationData == null) { return ;}
    if( cachedNavigationData.criteria != null) {
      this.typesSelected = cachedNavigationData.criteria.types;
    }
  }

  protected getNavigationDataToStore(): any {
    let data = super.getNavigationDataToStore();
    return Object.assign( data != null ? data : {}, {
      types: this.typesSelected,
    });
  }

  listSelectionChanged(){
    super.listSelectionChanged();
    if(this.btnBarCmp){
      const btnStatus = [
        {id: "add", disabled: false, functionalities: this.buttons[0].functionalities},
        {id: "edit", disabled: !(this.selection && this.selection.selected && this.selection.selected.length > 0)},
        {id: "delete", disabled: !(this.selection && this.selection.selected && this.selection.selected.length > 0)},
      ];    
      this.btnBarCmp.setButtonsStatus(btnStatus);
    }
  }

  removeInType(type) {
    if (type) {
      const index = this.typesSelected.findIndex( (elmt) => elmt == type);
      if (index != -1) {
        this.typesSelected.splice(index, 1);
      }
    }
  }
  
  selectedType(event: MatAutocompleteSelectedEvent) {
    let selected = event.option.value;
    if(selected != null && this.typesSelected != null && this.typesSelected.findIndex( (elm)=> elm == selected ) == -1) {
      this.typesSelected = this.typesSelected.concat([selected]);
    }
    //this.consInput.nativeElement.value = '';
    this.typeControl.setValue(null);
    console.log( "selectedType", event);
  }

}
