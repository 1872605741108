import { FRCVTypes } from './../../../model/admission/medical-folder';
import { Component, OnInit, ChangeDetectorRef, ViewChild } from '@angular/core';
import { MedicalFolder, Antecedent, AntecedentCategory } from 'src/app/model/admission/medical-folder';
import { IFormActionBarButton, FormAction, ButtonsBarComponent } from '../../buttons-bar/buttons-bar.component';
import { TranslatePipe, TranslateService } from '@ngx-translate/core';
import { NkapHttpService } from 'src/app/services/nkap-http.service';
import { Router, ActivatedRoute } from '@angular/router';
import { NkapNotificationService, NotificationType } from 'src/app/services/nkap-notification.service';
import { Person } from 'src/app/model/organisation/person';
import { BusinessUnit } from 'src/app/model/organisation/business-unit';
import { PrestationsToPerformed } from 'src/app/model/admission/reception';
import { Funtions } from 'src/app/model/security/funtionalities';
import { PatientHistoryComponent } from './patient-history/patient-history.component';
import { NkapListComponent } from '../../base/nkap-list-component';
import { UserAppService } from 'src/app/services/user-app.service';

@Component({
  selector: 'app-medical-folder-show',
  templateUrl: './medical-folder-show.component.html',
  styleUrls: ['./medical-folder-show.component.css']
})
export class MedicalFolderShowComponent implements OnInit {

  protected currentObject: MedicalFolder;
  protected buttons: IFormActionBarButton[];

  functionnalitiesHistory:string[];
  categoryList: {value: string , text: string}[];
  translate: TranslatePipe;

  loadHistory:Boolean=false;
  tabDisplay:Boolean=false;

  @ViewChild('patientHistoryIdentity')
  patientHistoryCmp:PatientHistoryComponent;
  patientId: number;

  antecedents: Antecedent[];
  frcvTypes: FRCVTypes[];
  loadAntecedentsProcessLaunch: boolean;
  loadFrcvsProcessLaunch: boolean;

  constructor(protected _ref: ChangeDetectorRef, protected translateService: TranslateService,
              protected httpService: NkapHttpService<MedicalFolder>, protected userService: UserAppService,
              protected router: Router, protected route: ActivatedRoute,
              protected msgService: NkapNotificationService) {

      this.translate = new TranslatePipe(translateService, _ref);
      this.buttons = [
        {id: 'edit', value: FormAction.UPDATE, icon: {type : 'edit', color: '#ff9933'}, 
          text: 'medical-folder.btn.edit.label', disabled: false, functionalities: [Funtions.patient_btn_edit_fct]},
        {id: 'list', value: FormAction.VIEW, icon: {type : 'list'}, 
          text: 'btn.list.label', disabled: false, functionalities: ['app.menu.patient.folder.title.label',Funtions.patient_btn_edit_fct,Funtions.patient_form_btn_print_fct,Funtions.consultationSheet_btn_view_fct,Funtions.patient_history_tab_label_fct]}
      ];

      this.categoryList = [
        {value: AntecedentCategory[AntecedentCategory.PERSONAL], text:"antecedent.category." + AntecedentCategory[AntecedentCategory.PERSONAL] + ".label"},
        {value: AntecedentCategory[AntecedentCategory.FAMILY], text:"antecedent.category." + AntecedentCategory[AntecedentCategory.FAMILY] + ".label"},
      ];

      this.functionnalitiesHistory=[Funtions.patient_history_tab_label_fct,Funtions.consultationSheet_btn_view_fct];
  }

  ngOnInit() {
    this.route.paramMap.subscribe(params => {
      let data: {id?: string | number} = (params as any).params;
      if (data.id != null) {
        this.httpService.findById(data.id, 'admission/medical-folder', true).subscribe( (result) => {
          this.currentObject = result;
          this.patientId = this.currentObject ? this.currentObject.patient.id : null;
        }, (errors) => {
          this.msgService.log('edit.get.data.error', NotificationType.ERROR);
        });
        // We get complete list of antecedents
        this.loadAntecedentsProcessLaunch = true;
        this.httpService.get(`admission/antecedent/find-by-medical-folder-id/${data.id}`).subscribe( (result) => {
          this.antecedents = result;
          this.loadAntecedentsProcessLaunch = false;
        });
        // We get complete list of frcvs
        this.loadFrcvsProcessLaunch = true;
        this.httpService.get(`admission/frcv/find-by-medical-folder-id/${data.id}`).subscribe( (result) => {
          this.frcvTypes = result;
          this.loadFrcvsProcessLaunch = false;
        });
      }
    });
  }

  btnActionClick(event) {
    if (event && event.id === this.buttons[0].id ) {
      this.router.navigate(['reception/admission', {id: this.currentObject.id}]);
    }else if (event && event.id === this.buttons[1].id) {
      this.router.navigate(['medical-folder/list', {search : true}]);
    } 
  }

  secure(event){
    this.tabDisplay=!event;
    // console.log(this.tabDisplay);
  }

  displayPersonFn(agent?: Person): string  {
    return agent ? `${agent.fullname}` : '';
  }

  displayServiceFn(service?: BusinessUnit): string {
    return service ? `${service.name}` : '';
  }


  contactPersonExist(){
    let exist = false;
    if (this.currentObject && this.currentObject.contactPersons 
      && this.currentObject.contactPersons.length > 0){
        this.currentObject.contactPersons.forEach( (item)=>{
          if(item.person && item.person.fullname && item.person.fullname!= 'null'){
            exist = true;
          }
        });
    }
    return exist;
  }

  attendingDoctorExist(){
    if(this.currentObject 
      && this.currentObject.attendingDoctor
      && this.currentObject.attendingDoctor.fullname != null 
      && this.currentObject.attendingDoctor.fullname != 'null' ){
        return true;
    }
    return false;
  }

  displayAntecedentFn(antecedent:Antecedent){
    return antecedent != null ? `[ ${antecedent.type.name} ]  :   ${antecedent.description}` : "";
  }

  displayCategoryFn(antecedent:Antecedent){
    if(antecedent != null && antecedent.category.toString()==AntecedentCategory[AntecedentCategory.PERSONAL])
    return this.categoryList[0].text;
    if(antecedent != null && antecedent.category.toString()==AntecedentCategory[AntecedentCategory.FAMILY])
    return this.categoryList[1].text;
  }

  delete(){
    if (confirm(this.translate.transform('medical-folder.delete.confirm.text'))) {
      this.httpService.deleteById(this.currentObject.id, 'admission/medical-folder', true).subscribe( (val) => {
        this.msgService.log('medical-folder.delete.succed', NotificationType.SUCCESS);
        this.router.navigate(['medical-folder/list', {search : true}]);
      }, (error) => {
        this.msgService.log('medical-folder.delete.fail', NotificationType.ERROR);
      });
    }
  }

  changeTab(event){
    console.log(event.index);
    if(event.index==1)
    this.loadHistory=true;
  }
}
