import { User } from 'src/app/model/security/user';
import { Prestation } from 'src/app/model/prestation/prestation';
import { MedicalFolder } from '../admission/medical-folder';
import { ClassNameMap, Persistent, PersistentDTO } from '../persistent';
import { Apointment } from '../admission/apointment';
import { MedicalAgent } from '../organisation/medical-agent';
import { Party } from '../organisation/party';
import { Person } from '../organisation/person';
import { FlatMedicalFolderDTO } from 'src/app/model/exam/exam';
import { TimeSlot } from 'src/app/model/organisation/diary';


export class Care extends Persistent {
    public carePrestation?: Prestation;
    public patientFolder?: MedicalFolder;
    public status?: CareStatus | string;
    public currentStep?: CareWorkflowStep;
	public receptionId?: string; 
	
    constructor(carePrestation?: Prestation,patientFolder?: MedicalFolder,status?: CareStatus,currentStep?: CareWorkflowStep) {
        super();
        this.classe = ClassNameMap.Care;
        this.carePrestation = carePrestation;
        this.patientFolder = patientFolder;
        this.status = status;
        this.currentStep = currentStep;
    }
}

export class CareWorkflowStep extends Persistent {
	public user?: User;	
	public date?: Date;
    public care?: Care;
    constructor(care?: Care) {
        super();
        this.classe = ClassNameMap.CareWorkflowStep;
        this.care = care;
    }
}

export enum CareStatus {
	REQUESTED,
	PLANNED,
	EXECUTED
}

export class CareApointment extends CareWorkflowStep {
    public apointment?: Apointment;
    public careProtocol?: string;
    constructor(care?: Care,apointment?: Apointment) {
        super(care);
        this.classe = ClassNameMap.CareApointment;
        this.apointment = apointment;
    }
}

export class CareRequest extends CareWorkflowStep {

	public prescriber?: Person;
	
    public prescriptionCode?: string;
    
    constructor(care?: Care,prescriber?: Person) {
        super(care);
        this.classe = ClassNameMap.CareRequest;
        this.prescriber = prescriber;
    }
}

export class CareExecution extends CareWorkflowStep {

	public agent?: MedicalAgent;
    
    public comment?: string;
    
    constructor(care?: Care,agent?: MedicalAgent) {
        super(care);
        this.classe = ClassNameMap.CareExecution;
        this.agent = agent;
        this.date = new Date(Date.now());
    }
}

export class CareDTO extends PersistentDTO{    
    
    public carePrestation?: {code?: string, name?: string};
	
    public patientFolder?: FlatMedicalFolderDTO;

    public status?: CareStatus | string;
	
	public currentStep?: CareWorkflowStepDTO;
	
	public request?: CareRequestDTO;
	
	public execution?: CareExecutionDTO;
}

export class CareWorkflowStepDTO extends PersistentDTO{
    public careId?: string;
	public userLogin?: string;
	public date?: string | any;
}

export class CareRequestDTO extends CareWorkflowStepDTO{
    public prescriberFullname?: string;
	public prescriptionCode?: string;
}

export class CareExecutionDTO extends CareWorkflowStepDTO{
    public agentFullname?: string;
	public comment?: string;
}

export class CareApointmentDTO extends CareWorkflowStepDTO {
	public apointmentId?: string;
	public patientId?: string;
	public apointmentDate?: string| any;
	public timeSlot?: TimeSlot;
	public personToMeetFullname?: string;
	public personEncounteredFullname?: string;
	public unitName?: string;
	public place?: string;
	public apointmentStatus?: string;
    public description?: string;
    public careProtocol?: string;
}
