import { Component, OnInit, ViewChild, ChangeDetectorRef, AfterViewInit } from '@angular/core';
import { MedicalPrescription, MedicalPrescriptionItem, Prescription } from 'src/app/model/prescriptions/prescription';
import { MatTable, MatTableDataSource } from '@angular/material';
import { IFormActionBarButton, FormAction, ButtonsBarComponent } from 'src/app/components/buttons-bar/buttons-bar.component';
import { NkapHttpService } from 'src/app/services/nkap-http.service';
import { NkapNotificationService, NotificationType } from 'src/app/services/nkap-notification.service';
import { Router, ActivatedRoute } from '@angular/router';
import { TranslatePipe, TranslateService } from '@ngx-translate/core';
import { Funtions } from 'src/app/model/security/funtionalities';
import * as moment from 'moment';
import { Prestation } from 'src/app/model/prestation/prestation';
import { SearchCriteria } from 'src/app/model/data-page';
import { FileDownloaderComponent } from 'src/app/components/file-downloader/file-downloader.component';

@Component({
  selector: 'app-medical-prescription-show',
  templateUrl: './medical-prescription-show.component.html',
  styleUrls: ['./medical-prescription-show.component.css']
})
export class MedicalPrescriptionShowComponent implements OnInit , AfterViewInit{

  currentObject: MedicalPrescription;

  prescriptionItemsDataSource: MatTableDataSource<MedicalPrescriptionItem> = new MatTableDataSource([]);
  
  protected buttons: IFormActionBarButton[];

  translate: TranslatePipe;
  
  displayers: any = {displayPrestation: Prestation.displayPrestation};

  @ViewChild("btnBar")
  btnBarCmp: ButtonsBarComponent;

  /**
   * A reference on download component
   */
  @ViewChild("downloader")
  downloaderComponent: FileDownloaderComponent;
  openFileInTab: boolean = true;
  
  constructor(private _ref: ChangeDetectorRef, protected translateService: TranslateService,
    private httpService: NkapHttpService<Prescription>, 
    private router: Router, private route: ActivatedRoute,
    private msgService: NkapNotificationService) {
        this.translate = new TranslatePipe(translateService, _ref);
        this.buttons = [
        /*{id: 'edit', value: FormAction.UPDATE, icon: {type : 'edit', color: '#ff9933'}, 
        text: 'btn.edit.label', disabled: false, functionalities: [Funtions.medicalPrescription_btn_edit_fct]},
        {id: 'delete', value: FormAction.DELETE, icon: {type : 'delete_forever', color: '#ff9933'},
        text: 'btn.delete.label', disabled: false, functionalities: [Funtions.medicalPrescription_btn_delete_fct]},
        */
       {id: "print", value: FormAction.CREATE, icon: {type : 'print', color: '#ff9933'},
          text: 'btn.print.label', disabled: false, functionalities: [Funtions.medicalPrescription_btn_print_fct]},
       {id: "close", value: FormAction.CREATE, icon: {type : 'delete', color: '#ff9933'},
          text: 'medical-prescription.btn.close.label', disabled: false, functionalities: [Funtions.medicalPrescription_btn_close_fct]},
       {id: 'list', value: FormAction.VIEW, icon: {type : 'list'}, text: 'btn.list.label', disabled: false, functionalities: [Funtions.medicalPrescription_btn_list_fct]}
        ];
       
  }

  ngAfterViewInit(): void {
    this.setButtonsStatus();
  }

  ngOnInit() {
    this.route.paramMap.subscribe(params => {
      let data: {id?: string | number} = (params as any).params;
      if (data.id != null) {
        this.httpService.findById(data.id, 'prescription/medical-prescription', true).subscribe( (prescription) => {
          this.currentObject = prescription;
          this.prescriptionItemsDataSource.data = this.currentObject.prescriptionItems ? this.currentObject.prescriptionItems : [];
          this.setButtonsStatus();
        }, (errors) => {
          this.msgService.log('medical-prescription.edit.get.data.error', NotificationType.ERROR);
        });
      }
    });
  }

  btnActionClick(event) {
    let i = 0;
    if ( event && event.id === this.buttons[i++].id) {
      if(this.currentObject) {
        this.print(this.currentObject.id);
      }
    } else if ( event && event.id === this.buttons[i++].id) {
      this.closeCurrentPrescription();
    } else if ( event && event.id === this.buttons[i++].id) {
      this.router.navigate(['./medical-prescription/list', {search: true}]);
    } 
  }

  setButtonsStatus(){
    if(this.btnBarCmp){
      const btnStatus = [
        {id: "print", disabled: (!this.currentObject || this.currentObject.stillValid == false)},
        {id: "close", disabled: (!this.currentObject || this.currentObject.stillValid == false)},
      ];    
      console.log({btnStatus});
      this.btnBarCmp.setButtonsStatus(btnStatus);
    }
  }

  displayDate(date) {
    try {
        return moment(new Date(date)).format("LL");
    } catch (e) {
      console.error("Parse date in reception-list fail " , e);
    }
  }

  displayStatus(element: MedicalPrescription) {
    let status = "";
    if(element.stillValid === false) {
      status = "medical-prescription.status.invalid.label";
    } else if(element.expirationDate != null && moment(Date.now()).isAfter(element.expirationDate) == true) {
      status = "medical-prescription.status.expired.label";
    } else {
      status = "medical-prescription.status.applicable.label";
    }
    return status
  }


  print(id) {
    let criteria = new SearchCriteria();
    criteria.lang= this.translateService.currentLang;
    criteria.id = id;
    this.downloaderComponent.criteria = criteria;
    this.downloaderComponent.link = 'prescription/medical-prescription/print';
    this.downloaderComponent.downloadFile({}, true);
    this.downloaderComponent.event.subscribe( (whellDone) => {
      if (whellDone == false) {
        this.msgService.log("form.print.error", NotificationType.ERROR);
      }
    });
  }

  closeCurrentPrescription(){
    this.msgService.confirm(this.translate.transform("medical-prescription.close.confirm.text")).subscribe( (result) => {
      if(result === true){
          this.httpService.post({id:this.currentObject.id}, 'prescription/medical-prescription/close').subscribe( (val) => {
            this.msgService.log("medical-prescription.close.succed", NotificationType.SUCCESS);
            this.router.navigate(['./medical-prescription/list', {search: true}]);
          }, (error) => {
            this.msgService.log("medical-prescription.close.fail", NotificationType.ERROR);
          });
        }
      });
  }
}
