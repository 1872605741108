import { OnInit, OnChanges, AfterViewInit, SimpleChanges, ViewChild, ChangeDetectorRef, OnDestroy, EventEmitter } from '@angular/core';
import { Persistent } from 'src/app/model/persistent';
import { FormControl } from '@angular/forms';
import { MatTableDataSource, MatTable } from '@angular/material';
import { SelectionModel } from '@angular/cdk/collections';
import { SearchCriteria } from 'src/app/model/data-page';
import { IFormActionBarButton, FormAction } from '../buttons-bar/buttons-bar.component';
import { TranslatePipe, TranslateService } from '@ngx-translate/core';
import { NkapHttpService } from 'src/app/services/nkap-http.service';
import { ActivatedRoute, Router } from '@angular/router';
import { NkapNotificationService } from 'src/app/services/nkap-notification.service';
import { GlobalVariables } from 'src/app/services/global-variables';
import { UserAppService } from 'src/app/services/user-app.service';
/**
 * Base component of grid components
 */
export class NkapListComponent<P extends Persistent> implements OnInit, OnChanges, AfterViewInit, OnDestroy {
    
  /**
   * Control for text criteria
   */
  searchFormControl: FormControl = new FormControl();

  /**
   * Table data source
   */
  tableDataSource: MatTableDataSource<P> = new MatTableDataSource([]);

  // view on mat-table 
  @ViewChild(MatTable)
  tableCmp: MatTable<any>;
  // selection tool
  selection: SelectionModel<P> = new SelectionModel<P>(false, []);

  /**
   * Criteria object send to the server to filter data
   */
  criteria: any | SearchCriteria;

  /**
   * Parameters send via url
   */
  params: any;

  /**
   * Paginator parameters
   */
  paginatorParams: {length?: number, pageSize?: number, pageSizeOptions?: number[], page?: number };

  /**
   * Indicator to kow if the zone of 'more criteria' must be hide or show
   */
  moreCriteriaShow: boolean = false;

  // tanslation pipe. Used to translate text out of the template
  translate: TranslatePipe;

  /**
   * The base url to use when calling http.search to perform filtering
   */
  searchBaseUrl: string;

  //List of buttons to render in buttons-bar
  buttons: IFormActionBarButton[];

  /**
   * Emit when the selection change: When an element is selected or when all element are unselected
   */
  elmtSelected: EventEmitter<any> = new EventEmitter();

  /**
   * Contain the current lang choosed by the user
   */
  lang = GlobalVariables.AVAILABLE_LANG ? GlobalVariables.AVAILABLE_LANG[0] : null;

  constructor(protected _ref: ChangeDetectorRef, protected httpService: NkapHttpService<P>, 
    protected router: Router, protected route: ActivatedRoute,protected userService: UserAppService,
    protected msgService: NkapNotificationService, protected translateService: TranslateService) {
       this.translate = new TranslatePipe(translateService, _ref);
       this.criteria = new SearchCriteria();
       this.paginatorParams = {
        pageSize: GlobalVariables.PAGINATOR_DEFAULT_SIZE,
        pageSizeOptions: GlobalVariables.PAGINATOR_SIZE_OPTIONS,
        page: 0
      };
      this.criteria.pageSize = GlobalVariables.PAGINATOR_DEFAULT_SIZE;
      this.lang = this.translateService.currentLang;
      this.translateService.onLangChange.subscribe( (lang) => {
        this.lang = lang.lang;
      });
  }

  ngOnInit() {
    // initialisation of search control
    this.searchFormControl = new FormControl('');
    this.searchFormControl.updateValueAndValidity();
    // initialise cache data management
    this.initCacheDataManagement();
    // get parameter set in route
    this.route.paramMap.subscribe(params => {
      this.params = (params as any).params;
      console.log("params ", this.params);
      if (this.params.search) {
        this.searchClicked({});
      }
    }); 
  }

  /**
   * Initialisation of cache data management
   */
  initCacheDataManagement() {
    // retrieving cache data
    const cachedNavigationData = this.userService.getNavigationData(this.constructor.name);
    if(cachedNavigationData != null && cachedNavigationData.criteria != null) {
      this.criteria = Object.assign(this.criteria != null ? this.criteria : {}, cachedNavigationData.criteria);
    }
    // set cache data in criteria to perform filter with them
    this.setCriteriaDataInView(cachedNavigationData);        
  }
  
ngOnChanges(changes: SimpleChanges): void {
    
}

ngAfterViewInit(): void {
  this.listSelectionChanged();
}

/**
 * Call when the user change the page he wan to see
 * @param event
 */
  pageChanged(event) {
    this.criteria.value = this.searchFormControl.value;
    this.criteria.pageSize = event.pageSize;
    this.criteria.page = event.pageIndex >= 0 ? event.pageIndex : 0;
    this.search(this.criteria);
    this.selection.clear();
    this.elmtSelected.emit(null);
    this.listSelectionChanged();
  }

  /**
   * Method that perform filtering on server
   * @param criteria 
   */
  protected search(criteria: any) {
    this.criteria.allPages=null; 
    console.log(criteria)
    this.httpService.search(criteria, this.searchBaseUrl, true).subscribe( (result) => {
      this.tableDataSource.data = result ? result.datas : [];
      console.log(result)
      console.log(result.datas)
      this.paginatorParams = {
        pageSize: GlobalVariables.PAGINATOR_DEFAULT_SIZE,
        pageSizeOptions: GlobalVariables.PAGINATOR_SIZE_OPTIONS,
        length: result.totalItemCount
      };
      this.tableCmp.renderRows();
      this.listSelectionChanged();
    }, (error)=>{// search fail
      this.handleSearchError(error);
    });
  }

  /**
   * Render error when search fail
   * @param error 
   */
  protected handleSearchError(error) {
    this.msgService.log("list.search.fail.error");
  }
  /**
   * Call to filter the grid when the search btn is cliecked
   * @param event 
   */
  searchClicked(event) {
    this.criteria.value =  this.searchFormControl && this.searchFormControl.value ? this.searchFormControl.value : '';
    this.criteria.page = 0;
    this.elmtSelected.emit(null);
    this.search(this.criteria);
  }

  /** Whether the number of selected elements matches the total number of rows. */
  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.tableDataSource.data.length;
    return numSelected === numRows;
  }

  /** Selects all rows if they are not all selected; otherwise clear selection. */
  masterToggle() {
    this.isAllSelected() ?
        this.selection.clear() :
        this.tableDataSource.data.forEach(row => this.selection.select(row));

    this.elmtSelected.emit(this.selection.selected);
    this.listSelectionChanged();
  }

  /**
   * Checbox status changed
   * @param element 
   */
  checkBoxChange(element) {
    this.selection.toggle(element);
    this.elmtSelected.emit(this.selection.selected);
    this.listSelectionChanged();
  }

  /**
   * Merge cached data to default criteria data
   * @param cachedNavigationData 
   */
  protected setCriteriaDataInView(cachedNavigationData: any) {
    if(cachedNavigationData == null) { return ;}
    console.log("setCriteriaDataInView ", cachedNavigationData);
    if(cachedNavigationData.criteria != null) {
      this.searchFormControl.setValue( cachedNavigationData.criteria.value);
    }
    if(cachedNavigationData.moreCriteriaShow != null) {
      this.moreCriteriaShow = cachedNavigationData.moreCriteriaShow;
    }
  }
  /**
   * Befor destroying the view, we store data
   */
  ngOnDestroy() {
    this.userService.updateNavigationData( this.constructor.name, this.getNavigationDataToStore());
  }

  /**
   * return criteria and status of more-criteria zone
   */
  protected getNavigationDataToStore() : any {
    return Object.assign({}, {criteria : this.criteria, moreCriteriaShow: this.moreCriteriaShow});
  }

  listSelectionChanged() {

  }
}