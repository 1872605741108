import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { FormAction, IFormActionBarButton } from '../../buttons-bar/buttons-bar.component';
import { ActivatedRoute, Router } from '@angular/router';
import { NkapNotificationService, NotificationType } from 'src/app/services/nkap-notification.service';
import { NkapHttpService } from 'src/app/services/nkap-http.service';
import { FormControl } from '@angular/forms';
import { PrestationFamily, PrestationParameters, Prestation } from 'src/app/model/prestation/prestation';
import { TaggedValue } from 'src/app/model/persistent';
import { SearchCriteria } from 'src/app/model/data-page';
import {COMMA, ENTER} from '@angular/cdk/keycodes';
import { Observable } from 'rxjs';
import { MatAutocompleteSelectedEvent } from '@angular/material';
import { NkapValidators } from '../../base/nkap-validators';
import { Funtions } from 'src/app/model/security/funtionalities';

@Component({
  selector: 'app-prestation-params',
  templateUrl: './prestation-params.component.html',
  styleUrls: ['./prestation-params.component.css']
})
export class PrestationParamsComponent implements OnInit {

  consFamilyControl: FormControl = new FormControl('',[NkapValidators.dataWithIdSelected]);
  consFamilyList: PrestationFamily[];

  examsFamilyControl: FormControl = new FormControl('',[NkapValidators.dataWithIdSelected]);
  examsFamilyList: PrestationFamily[];

  drugsFamilyControl: FormControl = new FormControl('',[NkapValidators.dataWithIdSelected]);
  drugsFamilyList: PrestationFamily[];

  careFamilyControl: FormControl = new FormControl('',[NkapValidators.dataWithIdSelected]);
  careFamilyList: PrestationFamily[];

  protected buttons: IFormActionBarButton[];

  protected prestationParameters: PrestationParameters;

  protected consPrestations: Prestation[] = [];
  consControl = new FormControl();
  consList: Observable<string[]>;
  @ViewChild('consInput')
  consInput: ElementRef<HTMLInputElement>;


  protected examPrestations: Prestation[] = [];
  examsControl = new FormControl();
  examsList: Observable<string[]>;
  @ViewChild('examsInput')
  examsInput: ElementRef<HTMLInputElement>;

  separatorKeysCodes: number[] = [ENTER, COMMA];

  constructor(private httpService: NkapHttpService<any>, private router: Router,
              private route: ActivatedRoute, private msgService: NkapNotificationService) {
    this.prestationParameters = new PrestationParameters();
    this.buttons = [
      {id: "save", value: FormAction.CREATE, icon: {type : 'save'},
        text: 'btn.save.label', disabled: false, functionalities: ['prestation.btn.params.label']},
      {id: "list", value: FormAction.CANCEL, icon: {type : 'list'},
        text: 'btn.list.label', disabled: false, functionalities: [Funtions.prestation_btn_list_fct]}
    ];
  }

  ngOnInit() {
    this.httpService.get('prestation/parameters', true).subscribe( (result) => {
      this.prestationParameters = result;
      this.setObjectInView(this.prestationParameters);
    }, (error) => {
      this.msgService.log("prestation.parameters.get.fail", NotificationType.ERROR);
    });
    this.consFamilyControl.valueChanges.subscribe( (elmt) => {
      if (typeof elmt === "string" ) {
        this.prestationParameters.mainConsultationFamily = null;
        let criteria = new SearchCriteria();
        criteria.value = elmt;
        this.httpService.search(criteria, "prestation/prestation-family").subscribe( (result) => {
          this.consFamilyList = result ? result.datas : [];
        });
      } else {
        this.prestationParameters.mainConsultationFamily = elmt;
      }
    });

    this.examsFamilyControl.valueChanges.subscribe( (elmt) => {
      if (typeof elmt === "string" ) {
        this.prestationParameters.mainExamsFamily = null;
        let criteria = new SearchCriteria();
        criteria.value = elmt;
        this.httpService.search(criteria, "prestation/prestation-family").subscribe( (result) => {
          this.examsFamilyList = result ? result.datas : [];
        });
      } else {
        this.prestationParameters.mainExamsFamily = elmt;
      }
    });

    this.drugsFamilyControl.valueChanges.subscribe( (elmt) => {
      if (typeof elmt === "string" ) {
        this.prestationParameters.mainDrugsFamily = null;
        let criteria = new SearchCriteria();
        criteria.value = elmt;
        this.httpService.search(criteria, "prestation/prestation-family").subscribe( (result) => {
          this.drugsFamilyList = result ? result.datas : [];
        });
      } else {
        this.prestationParameters.mainDrugsFamily = elmt;
      }
    });

    this.careFamilyControl.valueChanges.subscribe( (elmt) => {
      if (typeof elmt === "string" ) {
        this.prestationParameters.mainCareFamily = null;
        let criteria = new SearchCriteria();
        criteria.value = elmt;
        this.httpService.search(criteria, "prestation/prestation-family").subscribe( (result) => {
          this.careFamilyList = result ? result.datas : [];
        });
      } else {
        this.prestationParameters.mainCareFamily = elmt;
      }
    });

    this.consControl.valueChanges.subscribe( (elmt) => {
      if(typeof elmt == "string"){
        let criteria = new SearchCriteria();
        criteria.value = elmt;
        this.httpService.search(criteria, "prestation").subscribe( (result)=>{
          this.consList = new Observable( (observer) => {
            observer.next(result ? result.datas : []);
          });
        });
      }
    });

    this.examsControl.valueChanges.subscribe( (elmt) => {
      if(typeof elmt == "string"){
        let criteria = new SearchCriteria();
        criteria.value = elmt;
        this.httpService.search(criteria, "prestation").subscribe( (result)=>{
          this.examsList = new Observable( (observer) => {
            observer.next(result ? result.datas : []);
          });
        });
      }
    });
  }


  btnActionClick(event) {
    if (event && event.id === this.buttons[0].id){
      this.save();
    } else if (event && event.id === this.buttons[1].id){
      this.router.navigate(["prestation/list", {search: true}]);
    }
  }

  displayPrestationFamilyFn(family) {
    return family ? `${family.code} ${family.name ? family.name : ''}` : '';
  }

  displayPrestationFn(prestation) {
    return prestation ? `${prestation.code} ${prestation.name ? prestation.name : ''}` : '';
  }

  isValid() {
    let isValid = true;
    this.getObjectInView();
    this.consFamilyControl.markAsTouched();
    this.examsFamilyControl.markAsTouched();
    this.drugsFamilyControl.markAsTouched();
    this.careFamilyControl.markAsTouched();
    if(this.consFamilyControl.invalid){
      isValid = false;
    }
    if(this.examsFamilyControl.invalid){
      isValid = false;
    }
    if(this.drugsFamilyControl.invalid){
      isValid = false;
    }
    if(this.careFamilyControl.invalid){
      isValid = false;
    }
    console.log( "isValid" , isValid);
    return isValid;
  }

  getObjectInView(){
    this.prestationParameters.mostAskConsultations = this.consPrestations;
    this.prestationParameters.mostAskExams = this.examPrestations;
  }

  setObjectInView(parameters: PrestationParameters) {
    if (parameters) {
      this.prestationParameters = parameters;
      this.consFamilyControl.patchValue(parameters.mainConsultationFamily);
      this.examsFamilyControl.patchValue(parameters.mainExamsFamily);
      this.drugsFamilyControl.patchValue(parameters.mainDrugsFamily);
      this.careFamilyControl.patchValue(parameters.mainCareFamily);
      this.consPrestations = parameters.mostAskConsultations;
      this.examPrestations = parameters.mostAskExams;
    } else {
      this.prestationParameters = new PrestationParameters();
    }
  }

  save() {
    if (this.isValid() === true) {
      this.httpService.save(this.prestationParameters, 'prestation/parameters', true).subscribe( (result) => {
        this.msgService.log("prestation.parameters.save.succed", NotificationType.SUCCESS);
        this.router.navigate(['prestation/list', {search: true}]);
      }, (error) => {
        if (error && Array.isArray(error)) {
          error.forEach( (err) => {
            this.msgService.log(err, NotificationType.ERROR);
          });
        } else {
          this.msgService.log("prestation.parameters.save.fail", NotificationType.ERROR);
        }
      });
    }
  }

  removeInConsultation(prestation) {
    if (prestation) {
      const index = this.consPrestations.findIndex( (elmt) => elmt.id == prestation.id);
      if (index != -1) {
        this.consPrestations.splice(index, 1);
      }
    }
  }

  addConsultation(event) {

  }

  selectedConsultation(event: MatAutocompleteSelectedEvent) {
    let selected = event.option.value;
    if(selected != null && this.consPrestations != null && this.consPrestations.findIndex( (elm)=> elm.id == selected.id ) == -1) {
      this.consPrestations = this.consPrestations.concat([selected]);
    }
    this.consInput.nativeElement.value = '';
    this.consControl.setValue(null);
    console.log( "selectedConsultation", event);
  }

  removeInExams(prestation) {
    if (prestation) {
      const index = this.examPrestations.findIndex( (elmt) => elmt.id == prestation.id);
      if (index != -1) {
        this.examPrestations.splice(index, 1);
      }
    }
  }

  selectedExams(event: MatAutocompleteSelectedEvent) {
    let selected = event.option.value;
    if(selected != null && this.examPrestations != null && this.examPrestations.findIndex( (elm)=> elm.id == selected.id ) == -1) {
      this.examPrestations = this.examPrestations.concat([selected]);
    }
    //this.examPrestations = this.examPrestations.concat([event.option.value]);
    this.examsInput.nativeElement.value = '';
    this.examsControl.setValue(null);
    console.log( "selectedExams", event);
  }
}
