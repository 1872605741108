import { Prescription } from './../prescriptions/prescription';
import { Antecedent, FRCVTypes, MedicalFolder, SystemInvestigation } from '../admission/medical-folder';
import { PhysicalExam } from './physical.exam';
import { MedicalAgent } from '../organisation/medical-agent';
import { Persistent, ClassNameMap } from '../persistent';
import { Reception } from '../admission/reception';
import { PatientDiagnosis } from './patient-diagnosis';

export class ConsultationSheet extends Persistent{
    public doctor?: MedicalAgent;
    public reception?: Reception;
    public reason?:string;
    public diseaseHistory?: string;
    public antecedents?:Antecedent[];
    public frcvTypes?:FRCVTypes[];
    public physicalExam?: PhysicalExam;
    public systemInvestigations?: SystemInvestigation[];
    public observation?: string;
    public diagnosis?: PatientDiagnosis;
    public prescriptions?: Prescription[];
  
    constructor() {
      super();
      this.classe = ClassNameMap.ConsultationSheet;
    }
  }