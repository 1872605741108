import { Component, OnInit, ViewChild, AfterViewInit, Input, OnChanges, SimpleChanges } from '@angular/core';
import { SingleContactPersonComponent } from '../single-contact-person/single-contact-person.component';
import { ContactPerson } from '../../model/organisation/contact-person';

@Component({
  selector: 'app-contact-person',
  templateUrl: './contact-person.component.html',
  styleUrls: ['./contact-person.component.css']
})
export class ContactPersonComponent implements OnInit, OnChanges {

  @Input() contactPersonInput: ContactPerson[];

  contactPerson1: ContactPerson = new ContactPerson();
  contactPerson2: ContactPerson = new ContactPerson();
  contactPerson3: ContactPerson = new ContactPerson();

  @ViewChild("contact1")
  contact1cmp: SingleContactPersonComponent;

  @ViewChild("contact2")
  contact2cmp: SingleContactPersonComponent;

  @ViewChild("contact3")
  contact3cmp: SingleContactPersonComponent;


  constructor() { }

  ngOnInit() {
    if (this.contactPersonInput) {
      this.contactPersonInput.forEach(contact => {
        if(contact.company){
          this.contactPerson3 = contact;
        }else if(this.contactPerson1.person && this.contactPerson1.person.name != null){
          this.contactPerson2 = contact;
        }else{
          this.contactPerson1 = contact;
        }
      });
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['contactPersonInput']) {
      this.ngOnInit();
    }
  }

  isValid() {
      this.contactPersonInput = [];
      if (this.contact1cmp.isValid() && this.contact2cmp.isValid() && this.contact3cmp.isValid()) {
        if (this.contact1cmp.isValid()) {
          if(this.contact1cmp.getData() != null){
            this.contactPersonInput.push(this.contact1cmp.getData());
          }  
        }
        if (this.contact2cmp.isValid()) {
          if(this.contact2cmp.getData() != null){
            this.contactPersonInput.push(this.contact2cmp.getData());
          }
        }
        if (this.contact3cmp.isValid()) {
          if(this.contact3cmp.getData() != null){
            this.contactPersonInput.push(this.contact3cmp.getData());
          }
        }
        return true;
      } else {
        return false;
      }
  }

  getData() {
    this.isValid();
    return this.contactPersonInput;
  }
}

