import { Component, OnInit, ViewChild, AfterViewInit, OnDestroy } from '@angular/core';
import { BusinessUnit } from 'src/app/model/organisation/business-unit';
import { UserAppService } from 'src/app/services/user-app.service';
import { MatInput } from '@angular/material';
import { FormControl, Validators } from '@angular/forms';
import { User } from 'src/app/model/security/user';
import { Router, ActivatedRoute } from '@angular/router';
import { NkapValidators } from '../base/nkap-validators';
import { NkapNotificationService, NotificationType } from 'src/app/services/nkap-notification.service';
import { GlobalVariables } from 'src/app/services/global-variables';
import { NkapKeycloakService } from 'src/app/services/nkap-keycloak.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit , AfterViewInit, OnDestroy{

  protected user: User;

  hidePassword = true;

  loginControl: FormControl;
  passwordControl: FormControl;

  serviceSelected: BusinessUnit;
  serviceControl :FormControl ;
  serviceList: BusinessUnit[];
  @ViewChild('serviceInput')
  serviceInputCmp: MatInput;

  formValidity: boolean = false;
  errors: string[];
  urlAccount: string;
  reload: boolean;

  deconnectSubscription: Subscription = new Subscription();

  constructor(private nkapKeycloakService: NkapKeycloakService,private router: Router,private appService: UserAppService, private msgService: NkapNotificationService) {
                this.loginControl = new FormControl('',[Validators.required, NkapValidators.notEmpty]);
                this.passwordControl = new FormControl('',[Validators.required]);
                this.serviceControl = new FormControl('',[Validators.required]);

                this.reload = true;
  }

  ngOnDestroy() {
    this.deconnectSubscription.unsubscribe();
  }

  ngOnInit() {
    this.loginControl.valueChanges.subscribe( (val) => {
      this.formValidity = this.isValid();
    });

    this.passwordControl.valueChanges.subscribe( (val) => {
      this.formValidity = this.isValid();
    });

    this.serviceControl.valueChanges.subscribe( (inputValue) => {
      if (typeof inputValue === 'string' ) {
        this.serviceSelected = null;
        this.appService.searchService({value: inputValue}).subscribe ( (result) => {
           this.serviceList = result ? result.datas : [];
        });
       } else {
         this.serviceSelected = inputValue;
         this.user.loggedInService = inputValue;
         this.formValidity = this.isValid();
       }
    });

    this.nkapKeycloakService.deconnectSubject.subscribe(() => {
      document.location.reload();
    });
  }

  ngAfterViewInit() {
    this.user = new User();
  }

  displayServiceFn(service?: BusinessUnit): string {
    return service ? `${service.name}` : '';
  }

  /**
   *  Is user parameters valid?
   */
  public isValid() {
    let isValid = true;
    this.errors = null;
   
    if (this.loginControl.invalid) {
      isValid = false;
    }
    if (this.passwordControl.invalid){
      isValid = false;
    }
    if (this.serviceControl.invalid){
      isValid = false;
    }
    return isValid;
  }

  UpdateAccount(event) {
    
  }

  loggedInUser(event) {
    this.loginControl.markAsTouched();
    this.passwordControl.markAsTouched();
    this.serviceControl.markAsTouched();
    this.user.login = this.loginControl.value;
    this.user.password = this.passwordControl.value;
    this.user.loggedInService = this.serviceSelected;
    if(this.isValid()) {
      this.msgService.showSpiner.emit(true);
      this.appService.userConnect(this.user).subscribe(
        (result) => {
          NkapKeycloakService.accessToken = result;
          this.nkapKeycloakService.emitToken();
          this.msgService.showSpiner.emit(false);
          //this.router.navigate(GlobalVariables.AFTER_LOGIN_ROUTE)
        }, (error) => {
          console.log({loginError : error});
          this.msgService.showSpiner.emit(false);
          let errorList: string[] = error.split(" ");
          if (errorList.length !== 1) {
            this.urlAccount = errorList[1];
          }
          this.errors = [errorList[0]];
          this.msgService.log(this.errors[0], NotificationType.ERROR);
        });
    }
  }
}
