import { Component, OnInit, OnChanges, SimpleChanges } from '@angular/core';
import { UserAppService } from 'src/app/services/user-app.service';
import { NkapKeycloakService } from 'src/app/services/nkap-keycloak.service';
import { Subscription } from 'rxjs';
import { Funtions } from 'src/app/model/security/funtionalities';
import { Impersonate } from 'src/app/model/security/impersonisation';
import { HttpClient } from '@angular/common/http';
import { getTreeControlFunctionsMissingError } from '@angular/cdk/tree';

@Component({
  selector: 'app-main-menu',
  templateUrl: './main-menu.component.html',
  styleUrls: ['./main-menu.component.css']
})
export class MainMenuComponent implements OnInit, OnChanges {
  
  protected menu: MenuItem[] = [];
  public currentUserMenu: MenuItem[];
  rulesSubscription: Subscription;

  constructor(private userService: UserAppService, private nkapKeycloakService: NkapKeycloakService) {
    this.userService.userLoggedChanged.subscribe( (data) => {
      this.initMenu();
    });

    this.nkapKeycloakService.rulesSubject.subscribe((rules) => {
      this.initMenu();
    });

    this.nkapKeycloakService.impersonateSubject.subscribe((result: Impersonate) => {
      console.log(result);
      this.nkapKeycloakService.impersonation2(result.token, result.url);
    });
  }

  ngOnInit() {
    this.initMenu();
  }

  ngOnChanges(changes: SimpleChanges): void {
    
  }

  protected initMenu() {
    //let currentUser = this.userService.getUser();
    let userAvatarUrl = 'assets/images/user-male.png';
    let userName = this.userService.getUserAppLabel();
    if (userName === '') {
      userName = 'app.menu.user.profile.label';
    }
    this.menu = [
      {
        menuTitle: userName,
        menuAvatarUrl: userAvatarUrl,
        children: [
          {
            cssLocator:"user-parameters",
            menuTitle: 'app.menu.user.parameters.label',
            routeData: ['/user-parameters/show']
          }
        ]
      },{
        cssLocator:"medical-folder",
        menuTitle: "app.menu.patient.title.label",
        menuAvatarUrl:  "assets/images/patient.png",
        funtionalities: [
          Funtions.patient_btn_new_fct, Funtions.patient_btn_list_fct,Funtions.patient_btn_edit_fct,Funtions.patient_btn_delete_fct,Funtions.patient_form_btn_print_fct,
          Funtions.careCoverageDoc_btn_list_fct,Funtions.careCoverageDoc_btn_edit_fct,Funtions.careCoverageDoc_btn_new_fct,Funtions.consultationSheet_btn_view_fct,Funtions.patient_history_tab_label_fct
          ],
        children: [
          {
            cssLocator:"medical-folder_new-patient",
            menuTitle: "app.menu.new.patient.title.label",
            routeData: ['reception/admission'],
            funtionalities: [Funtions.patient_btn_new_fct],
          },
          {
            cssLocator:"medical-folder_list",
            menuTitle: "app.menu.patient.folder.title.label",
            routeData: ['medical-folder/list'],
            funtionalities: [
              Funtions.patient_btn_new_fct, Funtions.patient_btn_edit_fct, Funtions.patient_btn_delete_fct,Funtions.patient_btn_list_fct,Funtions.patient_form_btn_print_fct,
              Funtions.careCoverageDoc_btn_list_fct,Funtions.careCoverageDoc_btn_edit_fct,Funtions.careCoverageDoc_btn_new_fct,Funtions.consultationSheet_btn_view_fct,Funtions.patient_history_tab_label_fct
            ],
          }
        ]
      },{
        cssLocator:"medical-agent",
        menuTitle: "app.menu.medical-agent.title.label",
        menuAvatarUrl:  "assets/images/doctor.png",
        funtionalities: [ 
        Funtions.doctor_btn_list_fct, Funtions.doctor_btn_new_fct, Funtions.doctor_btn_edit_fct, Funtions.doctor_btn_delete_fct,
        Funtions.doctorPlaning_btn_new_fct,Funtions.doctorPlaning_btn_list_fct, Funtions.doctorPlaning_btn_him_fct
      ],
        children: [
          {
            cssLocator:"medical-agent_doctor",
            menuTitle: "app.menu.doctor.title.label",
            routeData: ['doctor/list'],
            funtionalities: [Funtions.doctor_btn_list_fct, Funtions.doctor_btn_new_fct,Funtions.doctor_btn_edit_fct, Funtions.doctor_btn_delete_fct],
          },
          {
            cssLocator:"medical-agent_planning",
            menuTitle: "app.menu.medical-agent.planning.title.label",
            routeData: ['doctor/planing'],
            funtionalities: [Funtions.doctorPlaning_btn_list_fct, Funtions.doctorPlaning_btn_new_fct, Funtions.doctorPlaning_btn_him_fct]
          }
        ]
      }, {
        cssLocator:"partners",
        menuTitle: "app.menu.partner.title.label",
        menuAvatarUrl:  "assets/images/third-party-payer.jpg",
        funtionalities: [
            Funtions.thirdPartyPayer_insurance_btn_new_fct, Funtions.thirdPartyPayer_employer_btn_new_fct,
            Funtions.thirdPartyPayer_person_btn_new_fct,Funtions.thirdPartyPayer_btn_list_fct,
            Funtions.thirdPartyPayer_btn_edit_fct,Funtions.thirdPartyPayer_btn_delete_fct,
            Funtions.medicalInstitutionPartner_btn_new_fct,Funtions.medicalInstitutionPartner_btn_list_fct,
            Funtions.medicalInstitutionPartner_btn_edit_fct,Funtions.medicalInstitutionPartner_btn_delete_fct,
            Funtions.medicalInstitutionPartner_btn_show_fct
          ],
          children: [{
            cssLocator:"third-party-payer",
            menuTitle: "app.menu.third-party-payer.title.label",
            funtionalities: [
              Funtions.thirdPartyPayer_insurance_btn_new_fct, Funtions.thirdPartyPayer_employer_btn_new_fct,
              Funtions.thirdPartyPayer_person_btn_new_fct,Funtions.thirdPartyPayer_btn_list_fct,
              Funtions.thirdPartyPayer_btn_edit_fct,Funtions.thirdPartyPayer_btn_delete_fct
            ],
            routeData: ['third-party-payer/list']
            },{
            cssLocator:"medical-institution-partner",
            menuTitle: "app.menu.medical-institution-partner.title.label",
            funtionalities: [
              Funtions.medicalInstitutionPartner_btn_new_fct,Funtions.medicalInstitutionPartner_btn_list_fct,
              Funtions.medicalInstitutionPartner_btn_edit_fct,Funtions.medicalInstitutionPartner_btn_delete_fct,
              Funtions.medicalInstitutionPartner_btn_show_fct
            ],
            routeData: ['medical-institution-partner/list']
          }]
      },{
        cssLocator:"prescription",
        menuTitle: "app.menu.prescription.title.label",
        menuAvatarUrl:  "assets/images/prescription.jpg",
        funtionalities: [ 
        Funtions.carePrescription_btn_delete_fct, Funtions.carePrescription_btn_edit_fct, 
        Funtions.carePrescription_btn_list_fct, Funtions.carePrescription_btn_new_fct, Funtions.carePrescription_btn_print_fct,
        Funtions.examPrescription_btn_delete_fct, Funtions.examPrescription_btn_edit_fct, 
        Funtions.examPrescription_btn_list_fct, Funtions.examPrescription_btn_new_fct, Funtions.examPrescription_btn_print_fct,
        Funtions.medicalPrescription_btn_delete_fct, Funtions.medicalPrescription_btn_edit_fct, 
        Funtions.medicalPrescription_btn_list_fct, Funtions.medicalPrescription_btn_new_fct, Funtions.medicalPrescription_btn_print_fct
      ],
        children: [
          {
            cssLocator:"prescription_care",
            menuTitle: "app.menu.care.prescription.title.label",
            routeData: ['care-prescription/list'],
            funtionalities: [Funtions.carePrescription_btn_edit_fct, Funtions.carePrescription_btn_delete_fct,
              Funtions.carePrescription_btn_new_fct, Funtions.carePrescription_btn_print_fct, Funtions.carePrescription_btn_close_fct],
          },
          {
            cssLocator:"prescription_exam",
            menuTitle: "app.menu.exam.prescription.title.label",
            routeData: ['exam-prescription/list'],
            funtionalities: [Funtions.examPrescription_btn_delete_fct, Funtions.examPrescription_btn_edit_fct, 
              Funtions.examPrescription_btn_list_fct, Funtions.examPrescription_btn_new_fct, Funtions.examPrescription_btn_print_fct,, Funtions.examPrescription_btn_close_fct],
          },
          {
            cssLocator:"prescription_medical",
            menuTitle: "app.menu.medical.prescription.title.label",
            routeData: ['medical-prescription/list'],
            funtionalities: [Funtions.medicalPrescription_btn_delete_fct, Funtions.medicalPrescription_btn_edit_fct, 
              Funtions.medicalPrescription_btn_list_fct, Funtions.medicalPrescription_btn_new_fct, Funtions.medicalPrescription_btn_print_fct,
              Funtions.medicalPrescription_btn_print_fct, Funtions.medicalPrescription_btn_close_fct],
          }
        ]
      },{
        cssLocator:"exams_in_process",
        menuTitle: "app.menu.exams_in_process.title.label",
        routeData: ['exam/list'],
        menuAvatarUrl:  "assets/images/exam_in_process.jpg",
        funtionalities: [Funtions.exam_btn_plan_fct, Funtions.exam_btn_print_fct, Funtions.exam_btn_result_fct],
      },{
        cssLocator:"treatments_process",
        menuTitle: "app.menu.treatments_process.title.label",
        routeData: ['care/list'],
        menuAvatarUrl:  "assets/images/treatments_process.jpg",
        funtionalities: [Funtions.care_btn_plan_fct, Funtions.care_btn_print_fct, Funtions.care_btn_result_fct],
      },
      {
        cssLocator:"reporting",
        menuTitle: "app.menu.reporting.title.label",
        menuAvatarUrl:  "assets/images/reports.jpg",
        funtionalities: [Funtions.costRegulationStatement_btn_print_fct, Funtions.costRegulationStatement_btn_view_fct],
        children: [
          {
            cssLocator:"reporting_cost-regulation-statement",
            menuTitle: "app.menu.reporting.cost-regulation-statement.title.label",
            routeData: ['reporting/costs-regulations-statement'],
            funtionalities: [Funtions.costRegulationStatement_btn_print_fct, Funtions.costRegulationStatement_btn_view_fct],
          },
          {
            cssLocator:"reporting_counter-activities",
            menuTitle: "app.menu.reporting.counter-activities.title.label",
            routeData: ['reporting/counter-activities'],
            funtionalities: [Funtions.counterActivities_btn_print_fct, Funtions.counterActivities_btn_view_fct],
          },
          {
            cssLocator:"reporting_billing-of-services",
            menuTitle: "app.menu.reporting.billing-of-services.title.label",
            routeData: ['reporting/billing-of-services'],
            funtionalities: [Funtions.billingOfServices_btn_print_fct, Funtions.billingOfServices_btn_view_fct],
          }
        ]
      }, /*, {
        menuTitle: "reception.home.consultation.title.label",
        menuAvatarUrl:  "assets/images/consultation.png",
        routeData: ['reception/home', {type: ReceptionType.CONSULTATION}],
      }, {
        menuTitle: "reception.home.exams.title.label",
        menuAvatarUrl: "assets/images/exams.png",
        routeData: ['reception/home', {type: ReceptionType.EXAMS}],
      }*/ {
        cssLocator:"parameters",
        menuTitle: 'app.menu.parameters.profile.label',
        menuAvatarUrl: 'assets/images/parameters.png',
        funtionalities: [
        Funtions.businessUunit_btn_list_fct,Funtions.businessUnit_btn_new_fct,Funtions.businessUnit_btn_edit_fct,Funtions.businessUnit_btn_delete_fct,
        Funtions.prestationFamily_btn_list_fct, Funtions.prestationFamily_btn_new_fct, Funtions.prestationFamily_btn_edit_fct,Funtions.prestationFamily_btn_delete_fct,  
        Funtions.prestation_btn_list_fct, Funtions.prestation_btn_new_fct, Funtions.prestation_btn_edit_fct, Funtions.prestation_btn_delete_fct,Funtions.prestation_btn_params_fct,
        Funtions.antecedent_btn_list_fct, Funtions.antecedent_btn_new_fct, Funtions.antecedent_btn_edit_fct, Funtions.antecedent_btn_delete_fct,
        Funtions.frcv_btn_list_fct, Funtions.frcv_btn_new_fct, Funtions.frcv_btn_edit_fct, Funtions.frcv_btn_delete_fct,
        Funtions.systemInvestigationType_btn_list_fct, Funtions.systemInvestigationType_btn_new_fct, Funtions.systemInvestigationType_btn_edit_fct, Funtions.systemInvestigationType_btn_delete_fct,
      ],
        children: [
          {
            cssLocator:"parameters_business-unit",
            menuTitle: 'app.menu.parameters.business-unit.label',
            routeData: ['/business-unit/list'],
            funtionalities: [Funtions.businessUunit_btn_list_fct,Funtions.businessUnit_btn_new_fct,Funtions.businessUnit_btn_edit_fct,Funtions.businessUnit_btn_delete_fct],
          },
          {
            cssLocator:"parameters_prestation-family",
            menuTitle: 'app.menu.parameters.prestation.family.label',
            routeData: ['/prestation-family/list'],
            funtionalities: [Funtions.prestationFamily_btn_list_fct, Funtions.prestationFamily_btn_new_fct, Funtions.prestationFamily_btn_edit_fct,Funtions.prestationFamily_btn_delete_fct,  ],
          },
          {
            cssLocator:"parameters_prestation",
            menuTitle: 'app.menu.parameters.prestation.label',
            routeData: ['/prestation/list'],
            funtionalities: [Funtions.prestation_btn_list_fct, Funtions.prestation_btn_new_fct, Funtions.prestation_btn_edit_fct, Funtions.prestation_btn_delete_fct,Funtions.prestation_btn_params_fct],
          },
          {
            cssLocator:"parameters_antecedent-type",
            menuTitle: 'app.menu.parameters.antecedent.type.label',
            routeData: ['/antecedent-type/list'],
            funtionalities: [Funtions.antecedent_type_btn_list_fct, Funtions.antecedent_type_btn_new_fct, Funtions.antecedent_type_btn_edit_fct, Funtions.antecedent_type_btn_delete_fct,],
          },
          {
            cssLocator:"parameters_frcv",
            menuTitle: 'app.menu.parameters.frcv.label',
            routeData: ['/frcv/list'],
            funtionalities: [Funtions.frcv_btn_list_fct, Funtions.frcv_btn_new_fct, Funtions.frcv_btn_edit_fct, Funtions.frcv_btn_delete_fct,],
          },
          { cssLocator:"parameters_system-investigation-type",
            menuTitle: 'app.menu.parameters.system-investigation-type.label',
            routeData: ['/system-investigation-type/list'],
            funtionalities: [Funtions.systemInvestigationType_btn_list_fct, Funtions.systemInvestigationType_btn_new_fct, Funtions.systemInvestigationType_btn_edit_fct, Funtions.systemInvestigationType_btn_delete_fct,],
          }
        ]
      }
    ];
    
    this.currentUserMenu = [this.menu[0]];
    this.menu = this.menu.filter(i => {
      return this.allowAccess(i) !== null;
    });

    this.currentUserMenu = this.currentUserMenu.concat(this.menu);
  }

  private allowAccess(menuItem: MenuItem) {
    if (!menuItem.funtionalities) return null;
    let funtionalities = menuItem.funtionalities.filter(f => {
      return this.userService.hasAccess(f)
    });
    if (funtionalities.length === 0) return null;
    if (menuItem.children) {
      menuItem.children = menuItem.children.filter(i => {
        return this.allowAccess(i) !== null;
      });
    }
    return menuItem;
  }
}

export interface MenuItem {
  cssLocator?: string;
  menuTitle?: string;
  menuAvatarUrl?: string;
  menuDescription?: string;
  children?: MenuItem[];
  menuAction?: any;
  routeData?: any;
  funtionalities?: string[];
}