import { Component, OnInit, Input, AfterViewInit, Output, EventEmitter, ViewChild, ViewContainerRef, ComponentFactoryResolver, ComponentRef, ElementRef, TemplateRef, OnChanges, SimpleChanges } from '@angular/core';
import { UserAppService } from 'src/app/services/user-app.service';
import { NkapKeycloakService } from 'src/app/services/nkap-keycloak.service';

import { Directive } from '@angular/core';

@Component({
  selector: 'app-secure-template',
  template: 
  `<ng-container #container *ngIf="hasAccess">
   <ng-content></ng-content>
  </ng-container>`
  ,
  styles: []
})
export class SecureTemplateComponent implements OnInit, AfterViewInit, OnChanges {

  @ViewChild("container", {read: ViewContainerRef})
  container: ViewContainerRef;

  @Input() functionalities: string[];

  @Input() routingUrl: string[];

  @Input() serverUrl: string[];

  @Output() secure: EventEmitter<boolean> = new EventEmitter();

  hasAccess: boolean;
  constructor(private userService: UserAppService, private nkapKeycloakService: NkapKeycloakService) {
    this.hasAccess = false;
  }

  ngOnChanges(changes: SimpleChanges): void {
    if(changes["functionalities"]) {
      this.hasAccessByFunctionnalities();
    }
  }

  ngOnInit() {
    this.nkapKeycloakService.rulesSubject.subscribe(roles => {
      this.hasAccessByFunctionnalities();
    });
    this.hasAccessByFunctionnalities();
  }

  hasAccessByFunctionnalities() {
   // console.log("hasAccessByFunctionnalities 1 ",this.functionalities);
    if (!this.functionalities || this.functionalities.length === 0) return;
    let functionalitiesAccessibles = this.functionalities.filter((f: string) => this.userService.hasAccess(f) === true);
   // console.log("hasAccessByFunctionnalities 2 ", functionalitiesAccessibles);
    if (functionalitiesAccessibles.length !== 0) {
        this.hasAccess = true;
    }
    this.secure.emit(this.hasAccess);
  }

  hasAccessByRouting() {
    
  }
  ngAfterViewInit() {
    // this.hasAccessByFunctionnalities();
  }

  hasPrivilage(): boolean{
    return true
  }

}

/**
 * Show when user has access to list of functionalities
 */
@Directive({ selector: '[IfHasFuncts]'})
export class IfHasFunctsDirective {

  constructor(private userService: UserAppService,
    private templateRef: TemplateRef<any>,
    private viewContainer: ViewContainerRef) { }

    @Input() set IfHasFuncts(functs: string[]) {
    if (this.hasAccessByFunctionnalities(functs)) {
      this.viewContainer.createEmbeddedView(this.templateRef);
    } else {
      this.viewContainer.clear();
    }
  }

  hasAccessByFunctionnalities(functionalities): boolean {
    console.log("hasAccessByFunctionnalities 1 ",functionalities);
    if (!functionalities || functionalities.length === 0) return false;
    let functionalitiesAccessibles = functionalities.filter((f: string) => this.userService.hasAccess(f) === true);
    console.log("hasAccessByFunctionnalities 2 ", functionalitiesAccessibles);
    if (functionalitiesAccessibles.length !== 0) {
       return true;
    }
    return false;
  }
}