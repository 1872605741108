import {Component, Input, OnInit, ViewChild, OnChanges, SimpleChanges, ChangeDetectorRef, EventEmitter} from '@angular/core';
import {from, Subscription, Subject} from 'rxjs/index';
import {MatSelectionList, MatTableDataSource, MatDialog} from '@angular/material';
import {BreakpointObserver, Breakpoints, BreakpointState} from '@angular/cdk/layout';
import {filter, map} from 'rxjs/internal/operators';
import {FormControl} from '@angular/forms';
import { NkapHttpService } from 'src/app/services/nkap-http.service';
import { Antecedent, FRCVTypes, AntecedentCategory } from 'src/app/model/admission/medical-folder';
import { ClassNameMap } from 'src/app/model/persistent';
import { AntecedentEditComponent } from '../antecedent/antecedent-edit/antecedent-edit.component';
import { Funtions } from 'src/app/model/security/funtionalities';
import { TranslatePipe, TranslateService } from '@ngx-translate/core';
import { NkapNotificationService, NotificationType } from 'src/app/services/nkap-notification.service';
import { ModalComponentService } from '../base/modal/modal.component';

@Component({
  selector: 'app-antecedents-frcv',
  templateUrl: './antecedents-frcv.component.html',
  styleUrls: ['./antecedents-frcv.component.css']
})
export class AntecedentsFrcvComponent implements OnInit, OnChanges {
  userDataAntecedentSubscription: Subscription;
  userDataAntecedentSubject: Subject<Antecedent[]> = new Subject<Antecedent[]>();
  sheetDataEmitter:EventEmitter<boolean>=new EventEmitter<boolean>();
  sheetData=false;
  dataAntecedents: any[];
  dataFRCV: FRCVTypes[];
  antToDelete: Antecedent[] = [];
  functionnalitiesAntEdit:string[];
  categoryList: {value: string , text: string}[];
  /**
   * Use to get FRCV and Antecedent of patient
   */
  @Input() userdataFRCV: FRCVTypes[] = [];
  @Input() userDataAntecedent: Antecedent[] = [];

  @ViewChild(MatSelectionList, null) matSelectionList: MatSelectionList;

  displayedColumns: string[] = ['position', 'category', 'type', 'description','edit', 'delete'];
  dataSource: MatTableDataSource<Antecedent>;
  // myControl = new FormControl();
  colsNumber = 2;

  translate: TranslatePipe;

  constructor(private _ref: ChangeDetectorRef, private nkapService: NkapHttpService<any>,private breakpointObserver: BreakpointObserver,
              public modalService: ModalComponentService,
              public dialog: MatDialog,private msgService: NkapNotificationService, protected translateService: TranslateService) {
      this.translate = new TranslatePipe(translateService, _ref);

    this.categoryList = [
      {value: AntecedentCategory[AntecedentCategory.PERSONAL], text:"antecedent.category." + AntecedentCategory[AntecedentCategory.PERSONAL] + ".label"},
      {value: AntecedentCategory[AntecedentCategory.FAMILY], text:"antecedent.category." + AntecedentCategory[AntecedentCategory.FAMILY] + ".label"},
    ];

    this.functionnalitiesAntEdit=[Funtions.antecedent_btn_edit_fct];

    this.sheetDataEmitter.subscribe((data)=>{
      if(data)
      this.sheetData=data;

      console.log(this.sheetData);
    });
  }

  ngOnInit() {
    
    this.userDataAntecedentSubscription = this.userDataAntecedentSubject.subscribe((data) => {
      if(data && data.length){
        for(let i=0;i<data.length;i++){
          data[i].index=i+1;
        }
      }
      console.log(data);
      this.dataSource = new MatTableDataSource<Antecedent>(data);
    });
    
    // this.myControl.valueChanges.subscribe(value => {
    //   if (!value || typeof value === 'string') {
    //     this.getAntecedent(value);
    //   } else {
    //     this.addAndetecedent(value);
    //   }
    // });
    this.breakpointObserver.observe([Breakpoints.Handset]).subscribe((state: BreakpointState) => {
      if (state.matches) {
        this.colsNumber = 4;
      } else {
        this.colsNumber = 6;
      }
    });

    // this.getAntecedent();
    this.emitUserAntecedent();
    this.getFrcv();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['userdataFRCV'] || changes['userDataAntecedent']) {
      this.emitUserAntecedent();
      this.getFrcv();
    }
  }

  // getAntecedent(value?) {
  //   this.nkapService.search( {value: value ? value : ''}, 'admission/antecedent').subscribe(result => {
  //     this.dataAntecedents = result != null ? result.datas : [];
  //   });
  // }

  getFrcv(value?) {
    this.nkapService.search( {value: value ? value : ''}, 'admission/frcv').subscribe(result => {
      this.dataFRCV = result != null ? result.datas : [];
      if (!this.userdataFRCV) {
        this.userdataFRCV = [];
      }
      this.dataFRCV.forEach(val => {
        if (this.userdataFRCV.find(v => v.id === val.id) === undefined) {
          val.check = false;
        } else {
          val.check = true;
        }
      });
    });
  }

  getAntecedentById(id: number): Antecedent {
    let val: Antecedent;
    this.nkapService.findById( id, `admission/antecedent/find-by-id/`).subscribe(result => {
      val = result;
    });
    return val;
  }

  addAndetecedent(option: Antecedent) {
    if (!this.userDataAntecedent) {
      this.userDataAntecedent = [];
    }

    this.userDataAntecedent = this.userDataAntecedent.filter(val => val.index !== option.index);
    this.userDataAntecedent.push(option);
    this.emitUserAntecedent();

    // if (this.userDataAntecedent.find(val => val.id === option.id) === undefined) {
    //   this.userDataAntecedent.push(option);
    // }
    // this.emitUserAntecedent();

  }

  remouve(antecedent: Antecedent) {
    console.log(antecedent);
    if(antecedent.id && !this.sheetData)
      this.delete(antecedent);
    else if(!antecedent.id || antecedent.folderId){
      this.userDataAntecedent = this.userDataAntecedent.filter(val => val.index !== antecedent.index);
      this.emitUserAntecedent();
      }else if(antecedent.id && this.sheetData && !antecedent.folderId){
        antecedent.toDelete = true;
        this.antToDelete.push(antecedent);
        
        this.userDataAntecedent = this.userDataAntecedent.filter(val => val.index !== antecedent.index);
        this.emitUserAntecedent();
      }
  }

  edit(antecedent:Antecedent){
    /*const dialogRef= this.dialog.open(AntecedentEditComponent, {
      height: '470px',
      width: '950px',
      disableClose: true,
      data: {dialog: true, antecedent: antecedent}
  });*/
   const dialogRef = this.modalService.openModal(AntecedentEditComponent,
    "antecedent.edit.form.title",undefined,{
    width: '700px',
    height:'420px',
    maxHeight:'420px',
    disableClose: true,
    position:{left:"10%", right:"10%"},
    data: {dialog: true, antecedent: antecedent}
  });
    dialogRef.afterClosed().subscribe(result => {
      console.log(result)
      if(result)
        this.addAndetecedent(result);
        // this.getAntecedent();
    });
  }

  getDataAntecedent(): Antecedent[] {
    if(this.userDataAntecedent && this.antToDelete)
    return this.userDataAntecedent.concat(this.antToDelete);
  }

  getDataFRCV() {
    const data = [];
    if(this.dataFRCV) {
      from(this.dataFRCV).pipe(
        filter((val: FRCVTypes) => val.check === true),
        map((val: FRCVTypes) => val)
      ).subscribe((val => {
        data.push(val);
      }));
    }
    return data;
  }

  getData(): {dataAntecedent: Antecedent[], dataFRCV: FRCVTypes[]} {
    return {dataAntecedent: this.getDataAntecedent(), dataFRCV: this.getDataFRCV()};
  }

  emitUserAntecedent() {
    this.userDataAntecedentSubject.next(this.userDataAntecedent);
  }

  displayCategoryFn(antecedent:Antecedent){
    if(antecedent.category.toString()==AntecedentCategory[AntecedentCategory.PERSONAL])
    return this.categoryList[0].text;
    if(antecedent.category.toString()==AntecedentCategory[AntecedentCategory.FAMILY])
    return this.categoryList[1].text;
  }

  displayTypeFn(antecedent?: Antecedent): string {
    return antecedent.type ? `${antecedent.type.name}` : '';
  }

  delete(antecedent: Antecedent){
    this.msgService.confirm(this.translate.transform("antecedent.delete.confirm.text")).subscribe( (result) => {
      if(result === true){
        this.nkapService.deleteById(antecedent.id, 'admission/antecedent', true).subscribe( (val) => {
          this.msgService.log('antecedent.delete.succed', NotificationType.SUCCESS);
          this.userDataAntecedent = this.userDataAntecedent.filter(val => val.index !== antecedent.index);
          this.emitUserAntecedent();
        },(error) => {
          if (error && Array.isArray(error)) {
            error.forEach( (err) => {
              this.msgService.log(err, NotificationType.ERROR);
            });
          } else if(typeof error === 'string') {
            let antError=`antecedent.${error}`;
            this.msgService.log(antError, NotificationType.ERROR);
          } else {
            this.msgService.log(error ? error : "antecedent.delete.fail", NotificationType.ERROR);
          }
        });
      }
    });
  }
}
