import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-main-unit',
  templateUrl: './main-unit.component.html',
  styleUrls: ['./main-unit.component.css']
})
export class MainUnitComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
