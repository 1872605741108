import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { NkapNotificationService, NotificationType } from 'src/app/services/nkap-notification.service';
import { TranslateService } from '@ngx-translate/core';
import { Router } from '@angular/router';
import { DateAdapter } from '@angular/material';
import { UserAppService } from 'src/app/services/user-app.service';
import { GlobalVariables } from 'src/app/services/global-variables';
import { Reception, ReceptionType } from 'src/app/model/admission/reception';
import { RouteGuardGuard } from 'src/app/services/route-guard.guard';
import { NkapKeycloakService } from 'src/app/services/nkap-keycloak.service';
import { Subject } from 'rxjs';
import { Funtions } from 'src/app/model/security/funtionalities';

 
@Component({
  selector: 'app-main-toolbar',
  templateUrl: './main-toolbar.component.html',
  styleUrls: ['./main-toolbar.component.css']
})
export class MainToolbarComponent implements OnInit {

  protected currentLang: Language;
  protected availableLanguages: Language[];
  protected quickMenuButtons: any[];
  protected menuToolbar: any[] = [];
  protected routerLinkConsultation ;
  protected routerLinkExams ;

  @Output()
  protected mainMenuBtnClick: EventEmitter<any> = new EventEmitter<any>();

  @Output()
  protected closeMainMenu: EventEmitter<any> = new EventEmitter<any>();

  protected previousUrl: string;

  constructor(private router: Router, private translate: TranslateService, 
    private msgService: NkapNotificationService,
    private routeGuard: RouteGuardGuard,
    private _dateAdapter: DateAdapter<any>,
              private userService: UserAppService, private nkapKeycloakService:NkapKeycloakService) {
      this.userService.userLoggedChanged.subscribe( (data) => {
        this.initQuickMenu();
        this.initLanguages();
      });

      this.nkapKeycloakService.rulesSubject.subscribe((rules) => {
        this.initQuickMenu();
      });

      this.initQuickMenu();
      this.initLanguages();
  }

  ngOnInit() {
    this.routeGuard.routeChanged.subscribe( (previousUrl) => {
      this.previousUrl = previousUrl;
      //console.log("routeGuard.routeChanged ", previousUrl);
    });
  }

  protected initQuickMenu() {
    this.menuToolbar = [
      {
        routerLink: ['reception/home', {type: ReceptionType[ReceptionType.CONSULTATION]}],
        label: "reception.home.consultation.title.label",
        description: "reception.home.consultation.textContent.label",
        avatarUrl: "assets/images/consultation.png",
        name: "main-toolbar_consultation_btn",
        functionalities: [Funtions.consultation],
        receptionReset: true
      },
      {
        routerLink: ['reception/home', {type: ReceptionType[ReceptionType.EXAMS]}],
        label: "reception.home.exams.title.label",
        description: "reception.home.exams.textContent.label",
        avatarUrl: "assets/images/exams.png",
        name: "main-toolbar_exams_btn",
        functionalities: [Funtions.exams],
        receptionReset: true
      },
      {
        routerLink: ['reception/home', {type: ReceptionType[ReceptionType.CARE]}],
        label: "reception.home.treatments.title.label",
        description: "reception.home.treatments.textContent.label",
        avatarUrl: "assets/images/prescription.jpg",
        name: "main-toolbar_treatments_btn",
        functionalities: [Funtions.treatments],
        receptionReset: true
      },
      {
        routerLink: ['apointment/list'],
        label: "reception.home.apointment.title.label",
        description:"reception.home.apointment.textContent.label",
        name: "main-toolbar_apointmentList_btn",
        functionalities: [Funtions.apointment_btn_list_fct,
        Funtions.apointment_btn_edit_fct,Funtions.apointment_btn_delete_fct, Funtions.apointment_btn_new_fct,Funtions.apointment_btn_delete_fct],
        avatarUrl: "assets/images/apointment.png"
      },
      {
        routerLink: ['reception/list',{search:true}],
        label: "reception.list.title.label",
        name: "main-toolbar_receptionList_btn",
        functionalities: [Funtions.reception_btn_list_fct,Funtions.reception_btn_edit_fct, Funtions.patient_form_btn_print_fct, Funtions.careCoverageDoc_btn_new_fct],
       /* cssClass: "bg-info",*/
        description:"reception.list.textContent.label"
      },
      {
        routerLink: ['apointment/chronological-list'],
        label: "reception.chronological-list.title.label",
        name: "main-toolbar_apointmentChronologicalList_btn",
        functionalities: [Funtions.chronologicalList_btn_list_fct,Funtions.chronologicalList_btn_print_fct,Funtions.chronologicalList_btn_close_fct],
        /* cssClass: "bg-info",*/
        description:"reception.chronological-list.textContent.label"
      }
    ];

    this.quickMenuButtons = [];
    this.menuToolbar.forEach(menuItem => {
      if (menuItem.functionalities) {
        menuItem.functionalities = menuItem.functionalities.filter((f: string) => this.userService.hasAccess(f) === true);
        if (menuItem.functionalities.length !== 0) {
          this.quickMenuButtons.push(menuItem);
        }
      }
    });
  }

  secureCmp(event) {
    if (event) return;
  }

  protected quickBtnClicked(btn) {
    this.closeMainMenu.emit({});
    this.router.navigate(btn.routerLink);
  }

  protected initLanguages() {
    
    this.translate.addLangs(GlobalVariables.AVAILABLE_LANG);
    let availableLanguages = [];
    GlobalVariables.AVAILABLE_LANG.forEach( (lang) => {
      availableLanguages.push({value: lang, labelCode: 'nkap.lang.' + lang + '.label'});
    });
    this.availableLanguages = availableLanguages;
    const browserLang = this.translate.getBrowserLang();
    const langIndex = this.availableLanguages.findIndex( (lang) => lang.value === browserLang);
    this.currentLang = this.availableLanguages[langIndex != -1 ? langIndex : 0];
    this.translate.setDefaultLang(this.currentLang.value);
    this.translate.use(this.currentLang.value);
    this._dateAdapter.setLocale(this.currentLang.value);
  }

  protected langChoosed(lang) {
    this.currentLang = lang;
    this.translate.use(this.currentLang.value);
    this._dateAdapter.setLocale(this.currentLang.value);
    this.userService.userLangChanged.emit(lang);
  }

  protected logOutUser() {
    this.userService.userDisconnect().subscribe( (result)=>{
      this.router.navigate(GlobalVariables.AFTER_LOGOUT_ROUTE);
      this.nkapKeycloakService.emitDeconnect();
    }, (error) => {
      this.userService.forceDisconnect();
      this.msgService.log(error, NotificationType.ERROR);
      this.router.navigate(GlobalVariables.AFTER_LOGOUT_ROUTE);
    });
  }

  protected seeUserMessages() {

  }

  protected showReceptionHomePage() {
    
  }
}
export interface Language {
  value: string;
  labelCode: string;
}
