import { Component, OnInit, ChangeDetectorRef, Inject, AfterContentChecked } from '@angular/core';
import { TranslatePipe, TranslateService } from '@ngx-translate/core';
import { Apointment } from 'src/app/model/admission/apointment';
import { SelectionModel } from '@angular/cdk/collections';
import { IFormActionBarButton, FormAction } from 'src/app/components/buttons-bar/buttons-bar.component';
import { FormControl } from '@angular/forms';
import { DataService } from '../data-service';
import { NkapHttpService } from 'src/app/services/nkap-http.service';
import { NkapNotificationService, NotificationType } from 'src/app/services/nkap-notification.service';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { Funtions } from 'src/app/model/security/funtionalities';
import { IModalComponent } from 'src/app/components/base/modal/modal.component';

@Component({
  selector: 'app-apointment-edit-modal-edit',
  templateUrl: './apointment-edit-modal-edit.component.html',
  styleUrls: ['./apointment-edit-modal-edit.component.css']
})
export class ApointmentEditModalEditComponent extends IModalComponent implements OnInit, AfterContentChecked {

  translate: TranslatePipe;
  selection: SelectionModel<Apointment> = new SelectionModel<Apointment>(false, []);
  protected buttons: IFormActionBarButton[];
  descriptionControl = new FormControl();
  careProtocolControl = new FormControl();

  IsCareApointment: boolean = false;

  protected data:  {title?: string, message: string,
     event:string, idSelectedApointment:any,
     examId: string, careProtocol:string, careMessage: string, careId: string};

  constructor(public dataService: DataService,private _ref: ChangeDetectorRef, private httpService: NkapHttpService<Apointment>,
    public dialogRef: MatDialogRef<ApointmentEditModalEditComponent>,private msgService: NkapNotificationService, protected translateService: TranslateService,
    @Inject(MAT_DIALOG_DATA) public modalData: any) {
      super();
      this.data = modalData.data;
      //console.log("data--->", this.data);
      this.translate = new TranslatePipe(translateService, _ref);

      this.buttons = [
        {id: "delete", value: FormAction.DELETE, icon: {type :'delete_forever',color:'#ff9933'},
          text: 'apointment.btn.delete.label', disabled: false, functionalities: [Funtions.apointment_btn_delete_fct,Funtions.consultation,Funtions.exams,Funtions.reception_btn_edit_fct]},
        {id: "edit", value: FormAction.UPDATE, icon: {type : 'save',color:'#ff9933'}, 
          text: 'btn.save.label', disabled: false, functionalities: [Funtions.apointment_btn_new_fct,Funtions.consultation,Funtions.reception_btn_edit_fct, Funtions.apointment_btn_edit_fct, Funtions.apointment_btn_new_fct,Funtions.exams]},
        {id: "cancel", value: FormAction.CANCEL, icon: {type :'cancel',color:'#ff9933'},
           text: 'apointment.btn.cancel', disabled: false, functionalities: ['apointment.btn.cancel.label']},
      ];
    }

    ngOnInit() {
      
    }

    ngAfterContentChecked() {
      this.IsCareApointment = this.data.careProtocol ? true : false;
      this._ref.detectChanges(); 
    }

    cancel(): void {
      this.dataService.dataAnnuler=true;
      this.dialogRef.close();
    }


    onDelete(){
      this.msgService.confirm(this.translate.transform("apointment.delete.confirm.text")).subscribe( (result) => {
        if(result === true){
          console.log(this.data.idSelectedApointment);
            if(this.data.examId != null && this.data.careId != null) {

            } else {
              this.httpService.deleteById(this.data.idSelectedApointment, 'admission/apointment').subscribe( (val) => {
                this.msgService.log("apointment.delete.succed", NotificationType.SUCCESS);
                console.log("delete")
                this.dataService.dataDelete=true;
                this.dialogRef.close();
              }, (error) => {
                this.msgService.log("apointment.delete.fail", NotificationType.ERROR);
              });
            }
          }
        });

      }


    
      btnActionClick(event) {
        if(event && event.id === this.buttons[0].id){
          this.onDelete();
        } else if(event && event.id === this.buttons[1].id){
          this.dialogRef.close(this.data ? this.data : null);
        } else if(event && event.id === this.buttons[2].id){
          this.cancel();
        }
      
      }

      public getData() {
        return this.data;
      }
}
