import { Component, OnInit, Input, OnChanges , SimpleChanges, ViewChild} from '@angular/core';
import { Party, PartyType } from 'src/app/model/organisation/party';
import { CompanyIdentificationComponent } from './company-identification/company-identification.component';
import { PatientIdentificationComponent } from '../patient-identification/patient-identification.component';
import { AdresseComponent } from '../adresse/adresse.component';
import { Address } from 'src/app/model/location/address';

@Component({
  selector: 'app-party',
  templateUrl: './party.component.html',
  styleUrls: ['./party.component.css']
})
export class PartyComponent implements OnInit, OnChanges {
  
  @Input('remove')
  fieldsToRemove: string[];
  
  @Input('type')
  protected partyType: string = PartyType.COMPANY;

  @Input('party')
  protected party: Party;
  protected partyInput: Party;

  @ViewChild('companyIdentification')
  companyIdentificationCmp: CompanyIdentificationComponent;

  @ViewChild('personIdentification')
  personIdentificationCmp: PatientIdentificationComponent;

  @ViewChild('address')
  addressCmp: AdresseComponent;

  PERSON: string = PartyType.PERSON;
  COMPANY: string = PartyType.COMPANY;

  constructor() {
    this.party = new Party();
  }

  ngOnInit() {
  }

  ngOnChanges(changes: SimpleChanges): void {
    if(changes['party']){
      this.partyInput = Object.assign({}, this.party);
    }
    if(changes['partyType']){
      console.log("partyType ", this.partyType, this.PERSON);
    }
  }

  public getData(): Party {
    if(this.partyType == PartyType.PERSON && this.personIdentificationCmp ){
        this.party = this.personIdentificationCmp.getData();
    }
    if(this.partyType == PartyType.COMPANY && this.companyIdentificationCmp){
        this.party = this.companyIdentificationCmp.getData();
    }
    let addressAndContact = this.addressCmp.getData();
    this.party = Object.assign(this.party, addressAndContact);
    this.party.toAddOrUpdate = true;
    return this.party;
  }

  public isValid(): boolean {
    let isValid = true;
    if(this.partyType == PartyType.PERSON && this.personIdentificationCmp 
      && this.personIdentificationCmp.isValid() == false){
        isValid = false;
    }
    if(this.partyType == PartyType.COMPANY && this.companyIdentificationCmp 
      && this.companyIdentificationCmp.isValid() == false){
        isValid = false;
    }
    return isValid;
  }

}

