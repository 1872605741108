import { Component, OnInit, Input, ChangeDetectorRef } from '@angular/core';
import { Apointment, ApointmentStatus } from 'src/app/model/admission/apointment';
import { IFormActionBarButton, FormAction } from '../../buttons-bar/buttons-bar.component';
import { TranslatePipe, TranslateService } from '@ngx-translate/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NkapNotificationService, NotificationType } from 'src/app/services/nkap-notification.service';
import { NkapHttpService } from 'src/app/services/nkap-http.service';
import { Person } from 'src/app/model/organisation/person';
import { BusinessUnit } from 'src/app/model/organisation/business-unit';
import * as moment from 'moment';
import { Funtions } from 'src/app/model/security/funtionalities';

@Component({
  selector: 'app-apointment-show',
  templateUrl: './apointment-show.component.html',
  styleUrls: ['./apointment-show.component.css']
})
export class ApointmentShowComponent implements OnInit {

  @Input() apointment: Apointment;

  protected buttons: IFormActionBarButton[];
  translate: TranslatePipe;
  protected lang = 'fr';
  
  constructor(private _ref: ChangeDetectorRef,private route: ActivatedRoute,
    private msgService: NkapNotificationService,private httpService: NkapHttpService<Apointment>,
    private router: Router, protected translateService: TranslateService) {

      this.translate = new TranslatePipe(translateService, _ref);

      this.buttons = [
        {id: 'edit', value: FormAction.UPDATE, icon: {type : 'edit', color: '#ff9933'}, 
          text: 'apointment.btn.edit.label', disabled: false, functionalities: [Funtions.apointment_btn_edit_fct]},
        {id: 'delete', value: FormAction.DELETE, icon: {type : 'delete_forever', color: '#ff9933'},
          text: 'apointment.btn.delete.label', disabled: false, functionalities: [Funtions.apointment_btn_delete_fct]},
        {id: 'list', value: FormAction.VIEW, icon: {type : 'list'}, text: 'btn.list.label', disabled: false, functionalities: [Funtions.apointment_btn_list_fct]}
      ];
      
    }

  ngOnInit() {
    this.route.paramMap.subscribe(params => {
      let data: {id?: string | number} = (params as any).params;
      if (data.id != null) {
        this.httpService.findById(data.id, 'admission/apointment').subscribe( (apointment) => {
          this.apointment = apointment;
        }, (errors) => {
          this.msgService.log('antecedent.edit.get.data.error', NotificationType.ERROR);
        });
      }
    });
  }


  btnActionClick(event) {
    if (event && event.id === this.buttons[0].id) {
      this.router.navigate(['apointment/edit', {id: this.apointment.id}]);
    } else if (event && event.id === this.buttons[1].id) {
        this.delete();
    } else if (event && event.id === this.buttons[2].id) {
      this.router.navigate(['apointment/list', {search : true}]);
    } else if (event && event.id === this.buttons[1].id) {
      
    }
  }

  
  delete(){
    this.msgService.confirm(this.translate.transform("apointment.delete.confirm.text")).subscribe( (result) => {
      if(result === true){
        this.httpService.deleteById(this.apointment.id, 'admission/apointment').subscribe( (val) => {
          this.msgService.log('apointment.delete.succed', NotificationType.SUCCESS);
          this.router.navigate(['apointment/list', {search : true}]);
        }, (error) => {
            this.msgService.log("apointment.delete.fail", NotificationType.ERROR);
          });
        }
      });

    }

  displayPersonFn(agent?: Person): string  {
    return agent ? `${agent.fullname}` : '';
  }

  displayServiceFn(service?: BusinessUnit): string {
    return service ? `${service.name}` : '';
  }

  formatApointmentStatus(status) {
    return status ? "ApointmentStatus." + status + ".label" : "ApointmentStatus." + ApointmentStatus[ApointmentStatus.PENDING] + ".label";
  }

  formatDate(date){
    try{
      return moment(new Date(date)).format("LL");
    }catch(e){
      console.error("Parse date in reception-list fail " , e);
    }
  }


  formatHour(apointment){
    try{
      if(apointment.timeSlot)
      return moment(new Date((new Date().getFullYear())+"-01-01T" + `${apointment.timeSlot.startTime}`)).format("h:mm");
      }catch(e){
        console.error("Parse date in reception-list fail " , e);
      }
  }
 


}
