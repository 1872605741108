import { EventEmitter, Injectable, OnInit } from '@angular/core';
import { Subject } from 'rxjs';
import {HttpHeaders, HttpClient} from '@angular/common/http';
import { catchError, tap } from 'rxjs/operators';
import { ServiceUtils } from './utils';
import { AccessToken } from '../model/security/access-token';
import { GlobalVariables } from './global-variables';
import { SearchCriteria } from '../model/data-page';
import { Impersonate } from '../model/security/impersonisation';
import * as $ from 'jquery';
import { NkapHttpService } from './nkap-http.service';

@Injectable({
  providedIn: 'root'
})
export class NkapKeycloakService {

  public showSpiner: EventEmitter<boolean> = new EventEmitter<boolean>();
  constructor(private http: HttpClient) {
    this.tokenSubject.subscribe(token => {
      if (token) {
        this.getRules();
      }
    });
  }
  
  // Event emitter when a token change
  tokenSubject: Subject<AccessToken> = new Subject<AccessToken>();
  static accessToken: AccessToken;
  
  // Event emitter when a rule of user change
  rulesSubject: Subject<Map<string, string[]> > = new Subject<Map<string, string[]> >();
  static goodRules : Map<string, string[]> = new Map<string, string[]>();
  private rules: {authority?: string[], group?: string[]}

  // Event emitter when a user logs out
  deconnectSubject: Subject<any> = new Subject<any>();

  impersonateSubject: Subject<Impersonate> = new Subject<Impersonate>();
  impersonate: Impersonate;

  /* Init application use keycloak. */
  init() {
    if (!NkapKeycloakService.accessToken) {
      NkapKeycloakService.accessToken = new AccessToken();
      let userToken = localStorage.getItem(GlobalVariables.APP_NAME + ".token");
      if (userToken) {
        try {
          NkapKeycloakService.accessToken = JSON.parse(userToken);
          this.refrechToken().subscribe((result: AccessToken) => {
            NkapKeycloakService.accessToken = result;
          });
        } catch (error) {
          NkapKeycloakService.accessToken = null;
        } finally {
          this.emitToken();
        }
      }
    }
  }

  public refrechToken() {
    const criteria = new SearchCriteria();
    criteria.value =  NkapKeycloakService.accessToken.refresh_token;
    const headers = {
      headers : new HttpHeaders(
        {
          'Content-Type': 'application/json',
        }
      )
    } ;

    return this.http.post<any>(`${GlobalVariables.CONTEXT_PATH}/security/refreshToken`,
      criteria, headers).pipe(
      tap(_ => {}),
      catchError(ServiceUtils.handleError)
    );
  }

  public impersonation() {
    if (NkapKeycloakService.accessToken && NkapKeycloakService.accessToken.access_token) {
      const headers = {
        headers : new HttpHeaders(
          {
            'Content-Type': 'application/json',
            'dataType': 'json',
            'Authorization': 'Bearer ' + NkapKeycloakService.accessToken.access_token
          }
        )
      } ;
      
      this.http.post<any>(`${GlobalVariables.CONTEXT_PATH}/security/impersonisation`, null, headers,).pipe(
        catchError(ServiceUtils.handleError)
      ).subscribe((result: Impersonate) => {
        this.impersonate = result;
        this.emitImpersonate();
      });
    }
  }

  public impersonation2(token, url) {
    if (NkapKeycloakService.accessToken && NkapKeycloakService.accessToken.access_token) {
      
      $.ajax({
        type: 'post',
        url: url,
        headers: {
          'Authorization': 'Bearer ' + token
        },
        success : (output, status, xhr) => {
          var redirectWindow = window.open(output.redirect, '_blank');
          redirectWindow.location;
        },
        error: (output) => {
          console.log (output);
        }
      });
    }
  }

  public getRules() {
    if (NkapKeycloakService.accessToken && NkapKeycloakService.accessToken.access_token) {
      const headers = {
        headers : new HttpHeaders(
          {
            'Content-Type': 'application/json',
            'dataType': 'json',
            'Authorization': 'Bearer ' + NkapKeycloakService.accessToken.access_token
          }
        ),

        reportProgress: true
      } ;
      this.showSpiner.emit(true);
      this.http.post<any>(`${GlobalVariables.CONTEXT_PATH}/security/rules`, null, headers)
      .pipe(
        catchError(ServiceUtils.handleError)
      ).subscribe(result => {
        this.showSpiner.emit(false);
        this.rules = result;
        this.emitRules();
      });
    }
  }

  emitToken() {
    this.tokenSubject.next(NkapKeycloakService.accessToken);
  }

  emitImpersonate() {
    this.impersonateSubject.next(this.impersonate);
  }

  emitDeconnect() {
    this.deconnectSubject.next();
  }

  emitRules() {
    NkapKeycloakService.goodRules.set(GlobalVariables.KEYCLOAK_RULES_ATTRIBUTE, []);
    NkapKeycloakService.goodRules.set(GlobalVariables.KEYCLOAK_GROUP_ATTRIBUTE, []);
    if (this.rules && this.rules.authority) {
      NkapKeycloakService.goodRules.set(GlobalVariables.KEYCLOAK_RULES_ATTRIBUTE, this.rules.authority);
    }
    if (this.rules && this.rules.group) {
      NkapKeycloakService.goodRules.set(GlobalVariables.KEYCLOAK_GROUP_ATTRIBUTE, this.rules.group);
    }
    this.rulesSubject.next(NkapKeycloakService.goodRules);
  }
}
