import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
import { NotificationType, NkapNotificationService } from 'src/app/services/nkap-notification.service';
import { Doctor } from 'src/app/model/organisation/doctor';
import { IFormActionBarButton, FormAction } from 'src/app/components/buttons-bar/buttons-bar.component';
import { TranslatePipe, TranslateService } from '@ngx-translate/core';
import { MedicalAgent } from 'src/app/model/organisation/medical-agent';
import { NkapHttpService } from 'src/app/services/nkap-http.service';
import { Router, ActivatedRoute } from '@angular/router';
import { Funtions } from 'src/app/model/security/funtionalities';

@Component({
  selector: 'app-doctor-show',
  templateUrl: './doctor-show.component.html',
  styleUrls: ['./doctor-show.component.css']
})
export class DoctorShowComponent implements OnInit {

  protected currentObject: MedicalAgent;

  protected buttons: IFormActionBarButton[];

  translate: TranslatePipe;

  constructor(private _ref: ChangeDetectorRef, protected translateService: TranslateService,
              private httpService: NkapHttpService<MedicalAgent>,
              private router: Router, private route: ActivatedRoute,
              private msgService: NkapNotificationService) {
    this.translate = new TranslatePipe(translateService, _ref);
    this.currentObject = null;
    this.buttons = [
        {id: 'edit', value: FormAction.UPDATE, icon: {type : 'edit', color: '#ff9933'},
          text: 'doctor.btn.edit.label', disabled: false, functionalities: [Funtions.doctor_btn_edit_fct]},
        {id: 'delete', value: FormAction.DELETE, icon: {type : 'delete_forever', color: '#ff9933'},
           text: 'doctor.btn.delete.label', disabled: false, functionalities: [Funtions.doctor_btn_delete_fct]},
        {id: 'list', value: FormAction.VIEW, icon: {type : 'list'}, text: 'btn.list.label', disabled: false, functionalities: [Funtions.doctor_btn_list_fct]}
      ];
  }

  ngOnInit() {
    this.route.paramMap.subscribe(params => {
      const data: {id?: string | number} = (params as any).params;
      if (data.id != null) {
        this.httpService.findById(data.id, 'organisation/medical-agent', true).subscribe( (object) => {
          this.currentObject = object;
        }, (errors) => {
          this.msgService.log('form.edit.get.data.error', NotificationType.ERROR);
        });
      }
    });
  }

  displayTypeFn(doctor: Doctor) {
    if(doctor && doctor.type){
      return `MedicalAgentType.${doctor.type}.label`;
    }
  }

  displayLocalisationFn(doctor: Doctor) {
    if(doctor && doctor.localisation){
      return `${doctor.localisation.name}`;
    }
  }

  btnActionClick(event) {
    if (event && event.id === this.buttons[0].id) {
      this.router.navigate(['doctor/edit', {id: this.currentObject.id}]);
    } else if (event && event.id === this.buttons[1].id) {
        this.delete();
    } else if (event && event.id === this.buttons[2].id) {
      this.router.navigate(['doctor/list', {search : true}]);
    }
  }

delete(){
  this.msgService.confirm(this.translate.transform("doctor.delete.confirm.text")).subscribe( (result) => {
    if(result === true){
      this.httpService.deleteById(this.currentObject.id, 'organisation/medical-agent', true).subscribe( (val) => {
        this.msgService.log('doctor.delete.succed', NotificationType.SUCCESS);
        this.router.navigate(['doctor/list', {search : true}]);
      }, (error) => {
        this.msgService.log('doctor.delete.fail', NotificationType.ERROR);
      });
    }
  });
}
}
