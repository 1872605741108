import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
import { PrestationFamily } from 'src/app/model/prestation/prestation';
import { IFormActionBarButton, FormAction } from 'src/app/components/buttons-bar/buttons-bar.component';
import { TranslatePipe, TranslateService } from '@ngx-translate/core';
import { NkapHttpService } from 'src/app/services/nkap-http.service';
import { Router, ActivatedRoute } from '@angular/router';
import { NkapNotificationService, NotificationType } from 'src/app/services/nkap-notification.service';
import { BusinessUnit } from 'src/app/model/organisation/business-unit';
import { Funtions } from 'src/app/model/security/funtionalities';

@Component({
  selector: 'app-prestation-family-show',
  templateUrl: './prestation-family-show.component.html',
  styleUrls: ['./prestation-family-show.component.css']
})
export class PrestationFamilyShowComponent implements OnInit {

  currentObject: PrestationFamily;
  
  protected buttons: IFormActionBarButton[];

  translate: TranslatePipe;
  
  constructor(private _ref: ChangeDetectorRef, protected translateService: TranslateService,
              private httpService: NkapHttpService<PrestationFamily>, 
              private router: Router, private route: ActivatedRoute,
              private msgService: NkapNotificationService) {
      this.translate = new TranslatePipe(translateService, _ref);
      this.buttons = [
        {id: 'edit', value: FormAction.UPDATE, icon: {type : 'edit', color: '#ff9933'}, 
          text: 'prestation-family.btn.edit.label', disabled: false, functionalities: [Funtions.prestationFamily_btn_edit_fct]},
        {id: 'delete', value: FormAction.DELETE, icon: {type : 'delete_forever', color: '#ff9933'},
           text: 'prestation-family.btn.delete.label', disabled: false, functionalities: [Funtions.prestationFamily_btn_delete_fct]},
        {id: 'list', value: FormAction.VIEW, icon: {type : 'list'}, text: 'btn.list.label', disabled: false, functionalities: [Funtions.prestationFamily_btn_list_fct]}
      ];

  }

  ngOnInit() {
    this.route.paramMap.subscribe(params => {
      let data: {id?: string | number} = (params as any).params;
      if (data.id != null) {
        this.httpService.findById(data.id, 'prestation/prestation-family', true).subscribe( (object) => {
          this.currentObject = object;
        }, (errors) => {
          this.msgService.log('prestation.edit.get.data.error', NotificationType.ERROR);
        });
      }
    });
  }

  displayServiceFn(service?: BusinessUnit): string {
    return service ? `${service.name}` : '';
  }


  displayFamilyFn(family?: PrestationFamily): string  {
    return family ? `${family.code} ${family.name ? family.name : ''}` : '';
  }

  btnActionClick(event) {
    if (event && event.id === this.buttons[0].id) {
      this.router.navigate(['prestation-family/edit', {id: this.currentObject.id}]);
    } else if (event && event.id === this.buttons[1].id) {
        this.delete();
    } else if (event && event.id === this.buttons[2].id) {
      this.router.navigate(['prestation-family/list', {search : true}]);
    } else if (event && event.id === this.buttons[1].id) {
      
    }
  }

  delete(){
    this.msgService.confirm(this.translate.transform("prestation-family.delete.confirm.text")).subscribe( (result) => {
      if(result === true){
          this.httpService.deleteById(this.currentObject.id, 'prestation/prestation-family', true).subscribe( (val) => {
            this.msgService.log('prestation-family.delete.succed', NotificationType.SUCCESS);
            this.router.navigate(['prestation-family/list', {search : true}]);
          }, (error) => {
            this.msgService.log('prestation-family.delete.fail', NotificationType.ERROR);
          });
      }
    });
  }

}
