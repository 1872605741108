import { Component, OnInit, ViewChild, ChangeDetectorRef } from '@angular/core';
import { CarePrescription, PrescriptionItem } from 'src/app/model/prescriptions/prescription';
import { MatTableDataSource, MatTable } from '@angular/material';
import { IFormActionBarButton, FormAction } from 'src/app/components/buttons-bar/buttons-bar.component';
import { TranslatePipe, TranslateService } from '@ngx-translate/core';
import { NkapHttpService } from 'src/app/services/nkap-http.service';
import { ActivatedRoute, Router } from '@angular/router';
import * as moment from 'moment';
import { NkapNotificationService, NotificationType } from 'src/app/services/nkap-notification.service';
import { Funtions } from 'src/app/model/security/funtionalities';
import { MedicalAgent } from 'src/app/model/organisation/medical-agent';
import { MedicalFolder } from 'src/app/model/admission/medical-folder';
import { SearchCriteria } from 'src/app/model/data-page';
import { FileDownloaderComponent } from 'src/app/components/file-downloader/file-downloader.component';

@Component({
  selector: 'app-care-prescription-show',
  templateUrl: './care-prescription-show.component.html',
  styleUrls: ['./care-prescription-show.component.css']
})
export class CarePrescriptionShowComponent implements OnInit {

  prescription: CarePrescription;
  prescriptionItemDataSource: MatTableDataSource<PrescriptionItem> = new MatTableDataSource([]);

  @ViewChild(MatTable)
  tableCmp: MatTable<any>;

  /**
   * A reference on download component
   */
  @ViewChild("downloader")
  downloaderComponent: FileDownloaderComponent;
  openFileInTab: boolean = true;

  criteria: any | SearchCriteria;
  
  protected buttons: IFormActionBarButton[];

  translate: TranslatePipe;
  
  constructor(private _ref: ChangeDetectorRef, protected translateService: TranslateService,
              private httpService: NkapHttpService<CarePrescription>, 
              private router: Router, private route: ActivatedRoute,
              private msgService: NkapNotificationService) {

      this.translate = new TranslatePipe(translateService, _ref);
      this.criteria = new SearchCriteria();
      this.prescription = new CarePrescription();
  }

  ngOnInit() {
    this.route.paramMap.subscribe(params => {
      let data: {id?: string | number} = (params as any).params;
      if (data.id != null) {
        this.httpService.findById(data.id, 'prescription/care-prescription', true).subscribe( (result) => {
          this.prescription = result;
          this.prescriptionItemDataSource.data = this.prescription.prescriptionItems ? this.prescription.prescriptionItems : [];

          this.buttons = [
            {id: 'edit', value: FormAction.UPDATE, icon: {type : 'edit'}, 
              text: 'prescription.btn.edit.label', disabled: !(this.prescription.stillValid), functionalities: [Funtions.carePrescription_btn_edit_fct]},
            {id: 'delete', value: FormAction.DELETE, icon: {type : 'delete_forever'},
               text: 'prescription.btn.delete.label', disabled: false, functionalities: [Funtions.carePrescription_btn_delete_fct]},
            {id: 'list', value: FormAction.VIEW, icon: {type : 'list'}, 
               text: 'btn.list.label', disabled: false, functionalities: [Funtions.carePrescription_btn_list_fct]},
            {id: "print", value: FormAction.PRINT, icon: {type : 'print'}, name: "care-prescription_print-btn",
               text: 'prescription.btn.print.label', disabled: false, functionalities: [Funtions.carePrescription_btn_print_fct]},
            {id: "close", value: FormAction.CLOSE, icon: {type : 'close'},
               text: 'prescription.btn.close.label', disabled: !(this.prescription.stillValid), functionalities: [Funtions.carePrescription_btn_close_fct]}
          ];

        }, (errors) => {
          this.msgService.log('prescription.edit.get.data.error', NotificationType.ERROR);
        });
      }
    });
  }

  btnActionClick(event) {
    if (event && event.id === this.buttons[0].id) {
      this.router.navigate(['care-prescription/edit', {id: this.prescription.id}]);
    } else if (event && event.id === this.buttons[1].id) {
        this.delete();
    } else if (event && event.id === this.buttons[2].id) {
      this.router.navigate(['care-prescription/list', {search : true}]);
    } else if (event && event.id === this.buttons[3].id) {
      this.print(this.prescription.id);
    }else if(event && event.id === this.buttons[4].id){
        this.close(this.prescription.id);
    }
  }

  close(id){
    this.msgService.confirm(this.translate.transform("prescription.close.confirm.text")).subscribe( (result) => {
      if(result === true){
          this.criteria.id=id;
          this.httpService.post(this.criteria, 'prescription/care-prescription/close').subscribe( (val) => {
            this.msgService.log("prescription.close.succeed", NotificationType.SUCCESS);
            this.router.navigate(['./care-prescription/list', {search : true}]);
          }, (error) => {
            this.msgService.log("prescription.close.fail", NotificationType.ERROR);
          });
        }
      });
  }

  delete(){
    this.msgService.confirm(this.translate.transform("prestation.delete.confirm.text")).subscribe( (result) => {
      if(result === true){
        this.httpService.deleteById(this.prescription.id, 'prescription/care-prescription', true).subscribe( (val) => {
          this.msgService.log('prescription.delete.succeed', NotificationType.SUCCESS);
          this.router.navigate(['care-prescription/list', {search : true}]);
        }, (error) => {
          this.msgService.log('prescription.delete.fail', NotificationType.ERROR);
        });
      }
    });
  }

  print(id) {
    this.criteria.id=id;
    this.criteria.lang= this.translateService.currentLang;
    this.downloaderComponent.criteria=this.criteria;
    this.downloaderComponent.link = 'prescription/care-prescription/print';
    this.downloaderComponent.downloadFile({});
    this.downloaderComponent.event.subscribe( (whellDone) => {
      if (whellDone == false) {
        this.msgService.log("form.print.error", NotificationType.ERROR);
      }
    });
  }

  displayPrestationFn(prestation) {
    return prestation ? `${prestation.code} ${prestation.name}` : '';
  }

  displayAgentFn(agent?: MedicalAgent): string  {
    return agent ? `${agent.number} ${agent.fullname ? agent.fullname : ''}` : '';
  }
  
  displayFolderFn(folder?: MedicalFolder): string  {
    return folder ? `${folder.number} ${folder.patient.fullname ? folder.patient.fullname : ''}` : '';
  }
  
  formatDate(date){
    try {
        return "[" + moment(new Date(date)).utc().format("HH:mm") + "] " + moment(new Date(date)).format("LL");
    } catch (e) {
      console.error("Parse date in reception-list fail " , e);
    }
  }

}
