import { Component, OnInit, ViewChild } from '@angular/core';
import { ThirdPartyPayer, ThirdPartyPayerType } from 'src/app/model/admission/care-coverage-doc';
import { NkapHttpService } from 'src/app/services/nkap-http.service';
import { Router, ActivatedRoute } from '@angular/router';
import { NkapNotificationService, NotificationType } from 'src/app/services/nkap-notification.service';
import { FormAction, IFormActionBarButton } from 'src/app/components/buttons-bar/buttons-bar.component';
import { PartyComponent } from 'src/app/components/party/party.component';
import { Party, PartyType } from 'src/app/model/organisation/party';
import { ClassNameMap } from 'src/app/model/persistent';
import { Funtions } from 'src/app/model/security/funtionalities';

@Component({
  selector: 'app-third-party-payer-edit',
  templateUrl: './third-party-payer-edit.component.html',
  styleUrls: ['./third-party-payer-edit.component.css']
})
export class ThirdPartyPayerEditComponent implements OnInit {

  protected currentObject: ThirdPartyPayer;

  @ViewChild('partyC')
  protected partyCmp: PartyComponent;

  protected buttons: IFormActionBarButton[];

  protected party: Party;

  protected type: string;
  
  constructor(private httpService: NkapHttpService<ThirdPartyPayer>, private router: Router,
    private route: ActivatedRoute, private msgService: NkapNotificationService) {
    this.currentObject = new ThirdPartyPayer();
    this.party = this.currentObject.party;
    this.type = PartyType.COMPANY;
    this.buttons = [
      {id: "save", value: FormAction.CREATE, icon: {type : 'save'},
      text: 'btn.save.label', disabled: false, functionalities: [
        Funtions.thirdPartyPayer_insurance_btn_new_fct,
        Funtions.thirdPartyPayer_employer_btn_new_fct,Funtions.thirdPartyPayer_person_btn_new_fct,Funtions.thirdPartyPayer_btn_edit_fct]},
      {id: "list", value: FormAction.VIEW, icon: {type : 'list'},
      text: 'btn.list.label', disabled: false, functionalities: [Funtions.thirdPartyPayer_btn_list_fct]}
    ];
  }

  ngOnInit() {
    this.route.paramMap.subscribe(params => {
      let data: {id?: string, type?: string} = (params as any).params;
      if (data.id != null) {
        this.httpService.findById(data.id, 'third-party-payer', true).subscribe( (object)=>{
          this.setObjectInView(object);
        }, (errors) => {
          this.msgService.log("third-party-payer.edit.get.data.error", NotificationType.ERROR);
        });
      } else if (data.type != null){
        this.currentObject.type = data.type;
        if (data.type == ThirdPartyPayerType[ThirdPartyPayerType.PERSONNE]){
          this.type = PartyType.PERSON;
        }
      }
    });
  }

  btnActionClick(event) {
    if(event && event.id === this.buttons[0].id){
      this.save();
    } else if(event && event.id === this.buttons[1].id){
      this.router.navigate(["third-party-payer/list", {search: true}]);
    }
  }

  getObjectFromView(){
    if(this.partyCmp){
      this.currentObject.party = this.partyCmp.getData();
      this.currentObject.party.toAddOrUpdate = true;
      this.currentObject.party.classe = this.type == PartyType.COMPANY ? ClassNameMap.Company 
      : (this.type == PartyType.PERSON ? ClassNameMap.Person : ClassNameMap.Party);
    }
  }

  setObjectInView(thirdParty: ThirdPartyPayer) {
    if (thirdParty) {
      this.currentObject = thirdParty;
      this.party = this.currentObject.party;
      if (this.currentObject.type === ThirdPartyPayerType[ThirdPartyPayerType.PERSONNE]) {
        this.type = PartyType.PERSON;
      }
    }
  }

  isValid(){
    let isValid = true;
    if(this.partyCmp){
      isValid = this.partyCmp.isValid();
    }
    if(this.partyCmp.addressCmp && this.partyCmp.addressCmp.isValid() == false) {
      isValid = false;
    }
    return isValid;
  }

  save() {
    this.getObjectFromView();
    if (this.isValid() === true) {
      this.httpService.save(this.currentObject, 'third-party-payer', true).subscribe( (result) => {
        this.msgService.log("third-party-payer.edit.save.succed", NotificationType.SUCCESS);
        this.router.navigate(['third-party-payer/list', {search: true}]);
      }, (error) => {
        if (error && Array.isArray(error)) {
          error.forEach( (err) => {
            this.msgService.log(err, NotificationType.ERROR);
          });
        } else {
          this.msgService.log("third-party-payer.edit.save.fail", NotificationType.ERROR);
        }
      });
    } else {
      //this.msgService.log("third-party-payer.data.invalid", NotificationType.ERROR);
    }
  }
}
