import { Persistent, ClassNameMap } from '../persistent';
import { Address } from '../location/address';
import { City } from '../location/city';
import { Country } from '../location/country';
import * as moment from 'moment';

export class Party extends Persistent {
    public name?: string;
    public fullname?: string;
    public fullName?: string;
    public birthdate?: string | Date;
    public emailAddress?: string;
    public mobilePhone?: string;
    public fixedPhone?: string;
    public webSiteAddress?: string;
    public fax?: string;
    public shortName?: string;
    public address?: Address;

    constructor(name?: string){
        super();
        this.address = new Address();
        this.classe = ClassNameMap.Party;
        this.name = name != null? name : "";
        this.fullname = this.name;
    }

    public static getAge(birthdate) {
        let yearOld = null;
        if (birthdate != null && birthdate != "" ){
            yearOld =  new Date(Date.now()).getFullYear() - new Date(birthdate).getFullYear();
        }
       // console.log("yearOld ", yearOld);
        return yearOld;
    }

    public static getYearsOld(birthdate, startDate?: Date): {years:number, months: number, weeks: number, days:number} {
        let yearOld = null;
        if (birthdate != null && birthdate != "" ){
            let nowMoment = moment();
            let difference = startDate != null ? moment(startDate).diff(moment(),"days") : 1;
            nowMoment.add(difference,'days');
            let momentDate = moment(birthdate);
           // console.log({difference,nowMoment:nowMoment.format('L'),momentDate:momentDate.format('L')});
            let years = nowMoment.diff(momentDate,'years');
            nowMoment = nowMoment.add(-years, 'years');
            let months = nowMoment.diff(momentDate,'months');
            nowMoment = nowMoment.add(-months, 'months');
            let weeks = nowMoment.diff(momentDate,'weeks');
            nowMoment = nowMoment.add(-weeks, 'weeks');
            let days = nowMoment.diff(momentDate,'days');
            yearOld = {
                years: Number(years) == Number.NaN ? 0 : Number(years),
                months: Number(months) == Number.NaN ? 0 : Number(months),
                weeks: Number(weeks) == Number.NaN ? 0 : Number(weeks),
                days: Number(days) == Number.NaN ? 0 : Number(days)
            };
           // console.log("years old ",yearOld);
            
        }
        return yearOld;
    }
}

export class Company extends Party {
    public vatNumber?: string;
    constructor(name?: string) {
        super(name);
        this.classe = ClassNameMap.Company;
    }
}

export class PartyType {
    public static COMPANY = "Company";
    public static PERSON = "Person";
}