import { Component, OnInit, ViewChild, ChangeDetectorRef } from '@angular/core';
import { FormControl, AbstractControl } from '@angular/forms';
import { ThirdPartyPayer, ThirdPartyPayerType } from 'src/app/model/admission/care-coverage-doc';
import { IFormActionBarButton, FormAction, ButtonsBarComponent } from 'src/app/components/buttons-bar/buttons-bar.component';
import { TranslatePipe, TranslateService } from '@ngx-translate/core';
import { NkapHttpService } from 'src/app/services/nkap-http.service';
import { Router, ActivatedRoute } from '@angular/router';
import { NkapNotificationService, NotificationType } from 'src/app/services/nkap-notification.service';
import { GlobalVariables } from 'src/app/services/global-variables';
import { NkapListComponent } from 'src/app/components/base/nkap-list-component';
import { UserAppService } from 'src/app/services/user-app.service';
import { Funtions } from 'src/app/model/security/funtionalities';

@Component({
  selector: 'app-third-party-payer-list',
  templateUrl: './third-party-payer-list.component.html',
  styleUrls: ['./third-party-payer-list.component.css']
})
export class ThirdPartyPayerListComponent extends NkapListComponent<ThirdPartyPayer>{

  /** Third party payer type */
  protected typeList: {value?: any, text?: any}[];
  protected typeCtrl: FormControl;

  @ViewChild("btnBar")
  btnBarCmp: ButtonsBarComponent;
  
  constructor(  protected _ref: ChangeDetectorRef, protected httpService: NkapHttpService<ThirdPartyPayer>, 
    protected router: Router, protected route: ActivatedRoute, protected userService: UserAppService,
    protected msgService: NkapNotificationService, protected translateService: TranslateService) {
   super(_ref, httpService, router, route, userService, msgService, translateService);
      this.searchBaseUrl = "third-party-payer";

       this.buttons = [
        {id: "add_insurance",data: {type:ThirdPartyPayerType[ThirdPartyPayerType.INSURANCE]}, value: FormAction.CREATE, icon: {type : 'add_circle_outline', color: '#0099cc'},
          text: 'third-party-payer.insurance.btn.new.label', disabled: false, functionalities: [Funtions.thirdPartyPayer_insurance_btn_new_fct]},
        {id: "add_employer",data: {type:ThirdPartyPayerType[ThirdPartyPayerType.EMPLOYER]}, value: FormAction.CREATE, icon: {type : 'add_circle_outline', color: '#0099cc'},
          text: 'third-party-payer.employer.btn.new.label', disabled: false, functionalities: [Funtions.thirdPartyPayer_employer_btn_new_fct]},
        {id: "add_person",data: {type:ThirdPartyPayerType[ThirdPartyPayerType.PERSONNE]}, value: FormAction.CREATE, icon: {type : 'add_circle_outline', color: '#0099cc'},
          text: 'third-party-payer.person.btn.new.label', disabled: false, functionalities: [Funtions.thirdPartyPayer_person_btn_new_fct]},
        {id: "edit", value: FormAction.UPDATE, icon: {type : 'edit',color:'#ff9933'}, 
          text: 'third-party-payer.btn.edit.label', disabled: false, functionalities: [Funtions.thirdPartyPayer_btn_edit_fct]},
        {id: "delete", value: FormAction.DELETE, icon: {type :'delete_forever',color:'#ff9933'},
          text: 'third-party-payer.btn.delete.label', disabled: false, functionalities: [Funtions.thirdPartyPayer_btn_delete_fct]}
      ];
      this.typeCtrl = new FormControl();
  }

  ngOnInit() {
    super.ngOnInit();
    this.typeList = [
      {value: null, text: ''},
      {value: ThirdPartyPayerType[ThirdPartyPayerType.INSURANCE], text: 'ThirdPartyPayerType.' + ThirdPartyPayerType[ThirdPartyPayerType.INSURANCE] + '.label'},
      {value: ThirdPartyPayerType[ThirdPartyPayerType.EMPLOYER], text: 'ThirdPartyPayerType.' + ThirdPartyPayerType[ThirdPartyPayerType.EMPLOYER] + '.label'},
      {value: ThirdPartyPayerType[ThirdPartyPayerType.PERSONNE], text: 'ThirdPartyPayerType.' + ThirdPartyPayerType[ThirdPartyPayerType.PERSONNE] + '.label'}
    ];
  }

  protected search(criteria: any) {
    criteria.category = this.typeCtrl.value;
    super.search(criteria);
  }

  
  btnActionClick(event) {
    if(event && ( event.id === this.buttons[0].id || event.id === this.buttons[1].id || event.id === this.buttons[2].id)){
      this.router.navigate(["third-party-payer/edit",event.data]);
    } else if(event && event.id === this.buttons[3].id){
      if( !this.selection.selected || this.selection.selected.length != 1){
        this.msgService.log("third-party-payer.list.select.to.modify.error", NotificationType.ERROR);
      }else{
        this.router.navigate(["third-party-payer/edit", {id: this.selection.selected[0].id}]);
      }
    } else if(event && event.id === this.buttons[4].id){
      if( !this.selection.selected || this.selection.selected.length < 1){
        this.msgService.log("third-party-payer.list.select.to.delete.error", NotificationType.ERROR);
      }else{
        this.deleteSelected();
      }
    }
  }

  displayThirdPartyPayerNameFn(elmt:ThirdPartyPayer){
    if( elmt && elmt.party ) {
      return elmt.party.fullname ;
    } 
    return '';
  }

  displayFamilyFn(elmt:ThirdPartyPayer){
    if( elmt && elmt.type != null ) {
      return `ThirdPartyPayerType.${elmt.type}.label`;
    }
  }

  displayAddressFn(elmt:ThirdPartyPayer){
    if( elmt && elmt.party ) {
      return (elmt.party as any).addressString;
    }
  }

  protected viewElement(element) {
    this.router.navigate(['third-party-payer/show', {id: element.id}]);
  }

  deleteSelected(){
    this.msgService.confirm(this.translate.transform("third-party-payer.delete.confirm.text")).subscribe( (result) => {
      if(result === true){
        let prestationSelected = this.selection.selected[0];
        this.httpService.deleteById(prestationSelected.id, 'third-party-payer').subscribe( (val) => {
          this.msgService.log("third-party-payer.delete.succed", NotificationType.SUCCESS);
          this.selection.clear();
          this.searchClicked({});
        }, (error) => {
          this.msgService.log("third-party-payer.delete.fail", NotificationType.ERROR);
        });
      }
    });
  }

  protected setCriteriaDataInView(cachedNavigationData: any) {
    super.setCriteriaDataInView(cachedNavigationData);
    if(cachedNavigationData == null) { return ;}
  
    if( cachedNavigationData.type != null) {
      this.typeCtrl.setValue(cachedNavigationData.type);
    }
  }

  protected getNavigationDataToStore(): any {
    let data = super.getNavigationDataToStore();
    return Object.assign( data != null ? data : {}, {
      type: this.typeCtrl.value,
    });
  }

  listSelectionChanged(){
    super.listSelectionChanged();
    if(this.btnBarCmp){
      const btnStatus = [
        {id: "add_insurance", disabled: false, functionalities: this.buttons[0].functionalities},
        {id: "add_employer", disabled: false, functionalities: this.buttons[1].functionalities},
        {id: "add_person", disabled: false, functionalities: this.buttons[2].functionalities},
        {id: "edit", disabled: !(this.selection && this.selection.selected && this.selection.selected.length > 0), functionalities: this.buttons[3].functionalities},
        {id: "delete", disabled: !(this.selection && this.selection.selected && this.selection.selected.length > 0), functionalities: this.buttons[4].functionalities},
      ];    
      this.btnBarCmp.setButtonsStatus(btnStatus);
    }
  }
}
