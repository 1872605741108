import { Component, OnInit, Input, EventEmitter, ChangeDetectorRef, Output, ViewChild, OnChanges, SimpleChanges } from '@angular/core';
import { CareCoverageDoc } from 'src/app/model/admission/care-coverage-doc';
import { IFormActionBarButton, FormAction } from '../../buttons-bar/buttons-bar.component';
import { NkapHttpService } from 'src/app/services/nkap-http.service';
import { Router, ActivatedRoute } from '@angular/router';
import { NkapNotificationService, NotificationType } from 'src/app/services/nkap-notification.service';
import { TranslateService } from '@ngx-translate/core';
import { CouvertureSanteComponent } from '../../couverture-sante/couverture-sante.component';
import { Funtions } from 'src/app/model/security/funtionalities';

@Component({
  selector: 'app-patient-care-coverage-doc-edit',
  templateUrl: './patient-care-coverage-doc-edit.component.html',
  styleUrls: ['./patient-care-coverage-doc-edit.component.css']
})
export class PatientCareCoverageDocEditComponent implements OnInit , OnChanges {
  
  protected patientFolderId: any;
  protected docNumber: any;
  protected docIssuerId: any;
  protected dataToSave: CareCoverageDoc;

  /**
   * Url parameters
   */
  protected params: any;

  protected buttons: IFormActionBarButton[];
  
  @ViewChild('couverture')
  couvertureCmp: CouvertureSanteComponent;

  constructor(protected _ref: ChangeDetectorRef, protected httpService: NkapHttpService<CareCoverageDoc>, 
    protected router: Router, protected route: ActivatedRoute,
    protected msgService: NkapNotificationService, protected translateService: TranslateService) {
        this.buttons = [
          {id: "add", value: FormAction.CREATE, icon: {type : 'save', color: '#0099cc'},
            text: 'btn.save.label', disabled: false, functionalities: [Funtions.careCoverageDoc_btn_new_fct,Funtions.careCoverageDoc_btn_edit_fct]},
          {id: "list", value: FormAction.UPDATE, icon: {type : 'list',color:'#ff9933'}, 
            text: 'btn.list.label', disabled: false, functionalities: [Funtions.careCoverageDoc_btn_edit_fct]}
        ];
 }

  ngOnInit() {
    // get parameter set in route
    this.route.paramMap.subscribe(params => {
      this.params = (params as any).params;
      if (this.params.folderId) {
        this.patientFolderId = this.params.folderId;
      }
      if (this.params.number) {
        this.docNumber = this.params.number;
      }
      //console.log(" params ", this.params);
      this.couvertureCmp.ngOnInit();
      this.couvertureCmp.searchNumber({});
    }); 
    
  }

  ngOnChanges(changes: SimpleChanges): void {
    
  }

  btnActionClick(event) {
    if(event && event.id === this.buttons[0].id ){
      // save care coverage in medical folder
      this.save();
    } else if(event && event.id === this.buttons[1].id){
      this.router.navigate(['medical-folder/patient-care-coverage/list', {search:true, folderId: this.params? this.params.folderId : null}]);
    }
  }

  isValid(): boolean {
    let isValid = true;
    this.dataToSave = this.couvertureCmp.getData();
    if (this.dataToSave){
      this.dataToSave.patientFolderId = this.params? this.params.folderId : null;
    }else{
      isValid = false;
    } 
    if (this.dataToSave && this.dataToSave.patientFolderId == null){
      isValid = false;
    }
    if (this.couvertureCmp.isValid() === false){
      isValid = false;
    }
    return isValid;
  }

  save(){
    if (this.isValid() === true){
      this.httpService.save(this.dataToSave, 'third-party-payer/care-coverage-doc', true).subscribe( (result) => {
        this.msgService.log("care-coverage-doc.edit.save.succed", NotificationType.SUCCESS);
        this.router.navigate(['medical-folder/patient-care-coverage/list', {search:true, folderId: this.params? this.params.folderId : null}]);    
      }, (error) => {
        if (typeof error == 'string') {
          this.msgService.log(error, NotificationType.ERROR);
        } else {
          this.msgService.log("care-coverage-doc.edit.save.fail", NotificationType.ERROR);
        }
      });
    }else{
      this.msgService.log("care-coverage-doc.edit.valid.fail", NotificationType.ERROR);
    }
  }
}
