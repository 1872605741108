import { Persistent } from './persistent';
import { GlobalVariables } from '../services/global-variables';

export class DataPage<T extends Persistent> {
    public page: number;
    public pageSize: number;
    public datas: T[];
    public totalItemCount: number;
}

export class SearchCriteria {
    public page?: number;
    public pageSize?: number;
    public value: string;
    public medicalAgentId?:number;
    public lang?:string;
    public id?:string | number;

    constructor() {
        this.page = 0;
        this.pageSize = GlobalVariables.MAX_ITEM_PER_PAGE;
    }
}