import { Component, OnInit, ViewChild, OnDestroy, SimpleChanges, OnChanges, ChangeDetectorRef, Input, AfterViewInit } from '@angular/core';
import { FormControl, Validators, AbstractControl, ValidationErrors } from '@angular/forms';
import timeGridPlugin from '@fullcalendar/timegrid';
import dayGridPlugin from '@fullcalendar/daygrid';
import { EventInput } from '@fullcalendar/core';
import interactionPlugin, { Draggable } from '@fullcalendar/interaction';
import {FullCalendarComponent} from '@fullcalendar/angular';
import { Subject, Subscription, from } from 'rxjs';
import { Doctor } from '../../../../model/organisation/doctor';
import { NkapHttpService } from 'src/app/services/nkap-http.service';
import { DayOfWeek, Diary, Periode, TimeSlot, Activity } from 'src/app/model/organisation/diary';
import { MatDialog } from '@angular/material';
import { DoctorPlaningModalComponent } from './doctor-planing-modal/doctor-planing-modal.component';
import { map } from 'rxjs/operators';
import { TranslateService, LangChangeEvent, TranslatePipe } from '@ngx-translate/core';
import { NkapNotificationService, NotificationType } from 'src/app/services/nkap-notification.service';
import { ClassNameMap } from 'src/app/model/persistent';
import {CdkDragDrop} from '@angular/cdk/drag-drop';
import { GlobalVariables } from 'src/app/services/global-variables';
import * as moment from 'moment';
import { IFormActionBarButton, FormAction } from 'src/app/components/buttons-bar/buttons-bar.component';
import { UserAppService } from 'src/app/services/user-app.service';
import { Funtions } from 'src/app/model/security/funtionalities';
import { ModalComponentService } from 'src/app/components/base/modal/modal.component';
import { NkapFullCalendarComponent } from 'src/app/components/nkap-full-calendar/nkap-full-calendar.component';

@Component({
  selector: 'app-doctor-planing',
  templateUrl: './doctor-planing.component.html',
  styleUrls: ['./doctor-planing.component.css']
})
export class DoctorPlaningComponent implements OnInit, OnDestroy, OnChanges, AfterViewInit {

  translate: TranslatePipe;
  currentObject: any = {};
  moreCriteriaShow = false;
  diary: Diary;
  currentDoctor: Doctor = new Doctor();
  currentDoctorSubject: Subject<Doctor> = new Subject<Doctor>();
  currentDoctorSubscription: Subscription = new Subscription();
  newEvent: EventInput;
  // @ViewChild('calendar', null) calendar: FullCalendarComponent;

  @ViewChild('nkapFullCalendar')
  nkapFullCalendarCmp: NkapFullCalendarComponent;
  
  @ViewChild('todoList', null) containerEl;

  calendarPlugins = [dayGridPlugin, timeGridPlugin, interactionPlugin];
  calendarWeekends = true;
  calendarVisible= true;
  selectable = true;
  droppable = true;
  editable = true;
  allDaySlot= false;
  hiddenDays = [];
  businessHours = [];
  eventColor= "#378006"
  nowDate = new Date();
  startControl =  new FormControl(this.nowDate, [Validators.required, DoctorPlaningComponent.validDate]);
  endControl = new FormControl(new Date((new Date()).getFullYear(), (new Date()).getMonth() +3), [Validators.required, DoctorPlaningComponent.validDate]);
  static startControlValue : Date;
  static endControlValue: Date;

  protected buttons: IFormActionBarButton[];


  /**
   * Utiliser pour la configuration du temps minimal
   */
  minTime = '08:00';
  minTimeControl = new FormControl('08:00', [Validators.required, Validators.pattern(/^[0-9]{2}:[0-9]{2}$/)]);
   /**
    * Utiliser pour la configuration du temps maximal
    */
  maxTime = '18:00';
  maxTimeControl = new FormControl('18:00', [Validators.required, Validators.pattern(/^[0-9]{2}:[0-9]{2}$/)]);
  /**
   * Utiliser pour la configuration de l'intervale de temps
   */
  slotDuration = '00:30';
  slotDurationControl = new FormControl('00:30', [Validators.required, Validators.pattern(/^00:[1-9][0-9]$|^0[1-9]:[0-9]{2}$/)]);
   /**
    * Utiliser pour contenir les évènement qui seront affiché sur le calendrier
    */
  // calendarEvents: EventInput[] = [];
  eventRenderSubject: Subject<Diary> = new Subject<Diary>();

  /**
   * Control pour le champs doctor
   */
  doctorControl = new FormControl('', [Validators.required]);
  // doctors: Doctor[] = [];

  /**
   * Parametre pour les différentes Url a insérer dans les requettes vers le serveur
   */
  parameters: any = {};

  /**
   * Utiliser pour spécifier les icones qui seront afficher pour chaque button sur le calendrier
   */
  buttonIcons = {};

  /**
   * Utiliser pour spécifier les texte qui seront afficher pour chaque button sur le calendrier
   */
  buttonText: any = {};

  /**
   * Contient les buttons sur le calendrier
   */
  addEvent = {};

  /**
   * Utiliser pour la configuration du premier jour de la semaine
   */
  firstDay = 1;
  selectedStartDay = this.firstDay;

  /**
   * Utiliser pour la configuration du dernier jour de la semaine
   */
  lastDay = 0;
  selectedEndDay =  this.lastDay;

  /**
   * Utiliser pour la configuration la langue du calendrier
   */
  // locale;
  isSave = false;
  draggable: Draggable;
  DayOfWeek = [
    {value: DayOfWeek.SUNDAY, text: 'doctor.planing.day.enum.sunday'},
    {value: DayOfWeek.MONDAY, text: 'doctor.planing.day.enum.monday'},
    {value: DayOfWeek.TUESDAY, text: 'doctor.planing.day.enum.tuesday'},
    {value: DayOfWeek.WEDNESDAY, text: 'doctor.planing.day.enum.wednesday'},
    {value: DayOfWeek.THURSDAY, text: 'doctor.planing.day.enum.thursday'},
    {value: DayOfWeek.FRIDAY, text: 'doctor.planing.day.enum.friday'},
    {value: DayOfWeek.SATURDAY, text: 'doctor.planing.day.enum.saturday'},
  ];

  constructor(private nkapService: NkapHttpService<any>, public dialog: MatDialog,
              private translateService: TranslateService,
              public modalService: ModalComponentService,
              private _ref: ChangeDetectorRef,
              private notificationService: NkapNotificationService, private userService: UserAppService) {
                this.translate = new TranslatePipe(translateService,_ref);

                this.nkapFullCalendarCmp= new NkapFullCalendarComponent(translateService);

    this.parameters = {
      doctorUrl: 'organisation/medical-agent',
      diaryUrl: 'organisation/diary',
      periodUrl: 'organisation/period'
    };
    

    this.currentObject = {
      view: {type : 'timeGridWeek'},
      action: 'create',
      start: new Date(),
      end: new Date()
    };
    this.diary = new Diary();
    this.diary.doctor = new Doctor();
    this.diary.periods = [];

    this.buttons = [
      {id: 'save', value: FormAction.CREATE , icon: {type : 'save'},
        text: 'btn.save.label', functionalities: [Funtions.doctorPlaning_btn_new_fct]},
      {id: 'list', value: FormAction.CANCEL, icon: {type : 'list'},
        text: 'btn.list.label', functionalities: [Funtions.doctorPlaning_btn_list_fct]},
      {id: 'him', value: FormAction.CANCEL, icon: {type : 'list'},
        text: 'him.diary', functionalities: [Funtions.doctorPlaning_btn_him_fct]},
    ];

   }

  header={
    left: 'prev,next, today',
    center: 'title',
    right: 'dayGridMonth,timeGridWeek,timeGridDay'
  };

  footer={
    left: 'save',
    center: '',
    right: ''
  }

   ngOnChanges(changes: SimpleChanges): void {
    
  }

  drop(event: CdkDragDrop<string[]>) {
  //  this.draggable =  new Draggable(event.item.element.nativeElement, {
  //     eventData: {
  //       title: event.item.element.nativeElement.innerHTML,
  //       duration: '04:00',
  //       color: 'green',
  //       extendedProps: {
  //         symbol: Symbol(),
  //       }
  //     }
  //   });
  }

   ngOnDestroy() {
     this.currentDoctorSubscription.unsubscribe();
   }

  ngOnInit() {
    this.nkapFullCalendarCmp.startControl= this.startControl;
    this.nkapFullCalendarCmp.endControl= this.endControl;
    this.nkapFullCalendarCmp.minTimeControl= this.minTimeControl;
    this.nkapFullCalendarCmp.maxTimeControl =this.maxTimeControl;
    this.nkapFullCalendarCmp.slotDurationControl = this.slotDurationControl;
    this.nkapFullCalendarCmp.doctorControl= this.doctorControl;
    
    if (!this.validAcess()) {
      //this.doctorControl.disable();
      this.nkapFullCalendarCmp.endControl.disable();
      this.nkapFullCalendarCmp.startControl.disable();

      this.editable = false;
      this.selectable = false;
      this.droppable = false;
    }


    if (this.justHimDiary()) {
      this.nkapFullCalendarCmp.endControl.enable();
      this.nkapFullCalendarCmp.startControl.enable();

      this.editable = true;
      this.selectable = true;
      this.droppable = true;
    }
    
    // Nous allons modifier le contenu du Calendrier lorsque on modifie le doctor
    this.currentDoctorSubject.subscribe((doctor: Doctor) => {

      // On verifie si le calendrier est n'est pas vide
      if(this.nkapFullCalendarCmp.calendarEvents.length !== 0) {
        this.translateService.get(['doctor.planing.change-doctor','doctor.planing.change-doctor.label']).subscribe(val => {
          this.notificationService.confirm(val['doctor.planing.change-doctor'],val['doctor.planing.change-doctor.label']).subscribe(res => {
            if (res === true) {
              this.nkapService.search({medicalAgentId: doctor.id}, `${this.parameters.diaryUrl}`, true).subscribe(res => {
                this.diary.doctor = doctor;
                this.nkapFullCalendarCmp.calendarEvents = [];
                this.nkapFullCalendarCmp.calendarComponent.getApi().getEvents().forEach(el => el.remove);
  
                if (res.datas.length != 0) {
                  const  results: Diary = res.datas[0];
                  this.diary = results;
                  this.getEventFromServer(results);
                 }
              }, (err) => {
                this.translateService.get(err).subscribe(val => {
                  this.notificationService.log(val, NotificationType.ERROR);
                });
              });
            } else {
              this.nkapFullCalendarCmp.doctorControl.setValue(this.diary.doctor, {emitEvent: false});
            }
          });
        })
      } else if (this.nkapFullCalendarCmp.calendarEvents.length === 0) {
            this.nkapService.search({medicalAgentId: doctor.id}, `${this.parameters.diaryUrl}`, true).subscribe(res => {
               this.diary.doctor = doctor; 
               this.nkapFullCalendarCmp.calendarEvents = [];
               this.nkapFullCalendarCmp.calendarComponent.getApi().getEvents().forEach(el => el.remove);

               if (res.datas.length != 0) {
                const  results: Diary = res.datas[0];
                this.diary = results;
                 this.getEventFromServer(results);
               }
            }, (err) => {
              this.translateService.get(err).subscribe(val => {
                this.notificationService.log(val, NotificationType.ERROR);
              });
            });
      }
    });

    

    // Use to hide all-day in header of calender
    // this.calendar.allDaySlot = false;

    this.nkapFullCalendarCmp.doctorControl.valueChanges.subscribe(value => {
      if (!value || typeof value === 'string') {
        if (this.justHimDiary() && !this.validAcess()) {
          this.getDoctor(value, this.userService.getUser().number);
          return;
        }
        this.getDoctor(value);
        return;
      }
      if (value.id !== undefined) {
        this.currentDoctor = value;
        this.emitDoctor();
      }
    });

    this.nkapFullCalendarCmp.slotDurationControl.valueChanges.subscribe(value => {
     if (!this.nkapFullCalendarCmp.slotDurationControl.invalid) {
       this.slotDuration = value;
     }
    });

    this.nkapFullCalendarCmp.maxTimeControl.valueChanges.subscribe(value => {
      if (!this.nkapFullCalendarCmp.maxTimeControl.invalid ) {
        this.maxTime = value;
      }
     });

    this.nkapFullCalendarCmp.minTimeControl.valueChanges.subscribe(value => {
      if (!this.nkapFullCalendarCmp.minTimeControl.invalid) {
        this.minTime = value;
      }
     });

    this.eventRenderSubject.subscribe(val => {
      this.nkapService.save(val, `${this.parameters.diaryUrl}`).subscribe(result => {
        this.nkapFullCalendarCmp.doctorControl.reset(result);
      });
    });
    
    this.changeLangCalender();

    this.translateService.onLangChange.subscribe((event: LangChangeEvent) => {
      this.changeLangCalender();
    });

    this.nkapFullCalendarCmp.startControl.valueChanges.subscribe(val => {
      if (val != null) {
        DoctorPlaningComponent.startControlValue = this.nkapFullCalendarCmp.startControl.value._d !== undefined ?this.nkapFullCalendarCmp.startControl.value._d :this.nkapFullCalendarCmp.startControl.value;
        DoctorPlaningComponent.endControlValue = this.nkapFullCalendarCmp.endControl.value._d !== undefined ?this.nkapFullCalendarCmp.endControl.value._d :this.nkapFullCalendarCmp.endControl.value;
        this.nkapFullCalendarCmp.calendarComponent.getApi().gotoDate(DoctorPlaningComponent.startControlValue);
        this.UpdateValidityDate();
      }

    });

    this.nkapFullCalendarCmp.endControl.valueChanges.subscribe(val => {
      if (val != null) {
        DoctorPlaningComponent.startControlValue = this.nkapFullCalendarCmp.startControl.value._d !== undefined ?this.nkapFullCalendarCmp.startControl.value._d :this.nkapFullCalendarCmp.startControl.value;
        DoctorPlaningComponent.endControlValue = this.nkapFullCalendarCmp.endControl.value._d !== undefined ?this.nkapFullCalendarCmp.endControl.value._d :this.nkapFullCalendarCmp.endControl.value;
        this.UpdateValidityDate();
      }
    });
  }

  ngAfterViewInit(){
    if(this.nkapFullCalendarCmp.calendarComponent){
      this.nkapFullCalendarCmp.calendarComponent.select.subscribe(info => {
        const start = (this.nkapFullCalendarCmp.startControl.value && this.nkapFullCalendarCmp.startControl.value._d !== undefined) ? this.nkapFullCalendarCmp.startControl.value._d : this.nkapFullCalendarCmp.startControl.value;
        const end = (this.nkapFullCalendarCmp.endControl.value && this.nkapFullCalendarCmp.endControl.value._d !== undefined) ? this.nkapFullCalendarCmp.endControl.value._d : this.nkapFullCalendarCmp.endControl.value;
        if (this.nkapFullCalendarCmp.startControl.invalid || this.nkapFullCalendarCmp.endControl.invalid) {
          this.translateService.get('doctor.planning.specified-end-and-start-date').subscribe(res => {
            this.notificationService.log(res, NotificationType.INFO);
          });
        } else if (moment((new Date(info.start)).toISOString(), 'YYYY-MM-DD') < moment((new Date(start)).toISOString(), 'YYYY-MM-DD')) {
          this.translateService.get('doctor.planning.selected-pass-date').subscribe(res => {
            this.notificationService.log(res, NotificationType.INFO);
          });
        } else if (this.nkapFullCalendarCmp.doctorControl.invalid || typeof this.nkapFullCalendarCmp.doctorControl.value === 'string') {
          this.translateService.get('doctor.planing.doctor.required').subscribe(res => {
            this.notificationService.log(res, NotificationType.INFO);
          });
        } else {
          this.currentObject = {
            start: start,
            end: end,
            view: info.view,
            specifiqStart: info.start,
            specifiqEnd: info.end,
            startStr: (new Date(info.start)).toTimeString().slice(0, 8),
            endStr: (new Date(info.end)).toTimeString().slice(0, 8),
            action: 'create',
            day:  (new Date(info.start)).getDay(),
          };
            this.AddEvent();
        }
      });
    }
  }
  
  validAcess() {
    let hasAcess = false;
    this.buttons[0].functionalities.forEach(val => {
      if (this.userService.hasAccess(val)) {
        hasAcess = true
        return;
      }
    });
    return hasAcess;
  }

  justHimDiary() {
    let hasAcess = false;
    this.buttons[2].functionalities.forEach(val => {
      if (this.userService.hasAccess(val)) {
        hasAcess = true
        return;
      }
    });
    return hasAcess;
  }

  UpdateValidityDate() {
    this.nkapFullCalendarCmp.calendarEvents.forEach(event => {
      if (event.type === 'recursiveEvent') {
        event.endRecur = DoctorPlaningComponent.endControlValue;
        event.startRecur = DoctorPlaningComponent.startControlValue;
      }
    })
    this.nkapFullCalendarCmp.calendarComponent.getApi().getEvents().forEach(ev => ev.remove());
    this.nkapFullCalendarCmp.calendarEvents = this.nkapFullCalendarCmp.calendarEvents.splice(0);
  }

  getEventFromServer(results: Diary) {
    const recurringEvents = [];
    from(results.periods).pipe(
      map(elt => {
        const eventDisplay: any = {};
        if (elt.day) {
          eventDisplay.daysOfWeek = [parseInt(DayOfWeek[elt.day])];
          eventDisplay.startTime = `${elt.timeSlot.startTime}`;
          eventDisplay.endTime = `${elt.timeSlot.endTime}`;
          eventDisplay.startRecur = elt.validityStartDate;
          eventDisplay.endRecur = elt.validityEndDate;
          eventDisplay.start = this.getGoodDate(`${elt.validityStartDate}`, `${elt.timeSlot.startTime}`);
          eventDisplay.end = this.getGoodDate(`${elt.validityEndDate}`, `${elt.timeSlot.endTime}`);
        } else {
          eventDisplay.start = this.getGoodDate(`${elt.validityStartDate}`, `${elt.timeSlot.startTime}`);
          eventDisplay.end = this.getGoodDate(`${elt.validityEndDate}`, `${elt.timeSlot.endTime}`);
        }
        eventDisplay.hasActivity = elt.activity != null;
        eventDisplay.title = elt.activity != null ? elt.activity.name : this.translateService.instant(elt.isProgramable === true? "planning.period.is.programmable":"planning.period.is.not.programmable");
        eventDisplay.id =  elt.id === undefined ? undefined : elt.id;
        eventDisplay.type = elt.type ;
        eventDisplay.symbol =  Symbol();
        eventDisplay.isProgramable = elt.isProgramable === undefined ? true : elt.isProgramable;

        if (eventDisplay.isProgramable === false ){
          eventDisplay.backgroundColor = '#807F7F';
        }
        return eventDisplay;
      }),
    ).subscribe(
      val => {recurringEvents.push(val); },
      (error => {
        //DOTO
       }),
      () => {this.nkapFullCalendarCmp.calendarEvents = recurringEvents; });
  }

  changeLangCalender() {
    
    // this.locale = this.translateService.currentLang;
    this.translateService.get(
        [
          `doctor.planing.add.activity`,
          `btn.save.label`,
          `doctor.planing.label.today`,
          `doctor.planing.label.month`,
          `doctor.planing.label.week`,
          `doctor.planing.label.day`
        ]
      ).subscribe(res => { 
          this.addEvent = {
            event : {
              code: 'doctor.planing.add.activity',text: res[`doctor.planing.add.activity`],id: 'action',click: () => {
                this.AddEvent();
              },
            },
            save : {code: 'btn.save.label',text: res[`btn.save.label`],id: 'save',
              click: () => {
                this.SaveDiary();
              }
            },
          };

        this.buttonText = {
          today: res[`doctor.planing.label.today`],
          month: res[`doctor.planing.label.month`],
          week: res[`doctor.planing.label.week`],
          day: res[`doctor.planing.label.day`]
        }
    });
  }

  static validDate(control: AbstractControl): ValidationErrors | null {
    const start: Date = new Date(DoctorPlaningComponent.startControlValue);
    const end: Date = new Date(DoctorPlaningComponent.endControlValue);
    if(end < start) {
      return {validDate: false};
    }
    return null;
  }


  displayOptionFn(doctor?: Doctor): string {
    return doctor ? `${doctor.fullname}` : '';
  }

  saveConfig() {
    const config = {
      firsday: this.firstDay,
      lasday: this.lastDay,
      interval: this.slotDuration,
      min: this.minTime,
      max: this.maxTime
    }

    localStorage.setItem(`${GlobalVariables.APP_NAME} config`, JSON.stringify(config));
  }

  loadConfig() {
    const config: any = localStorage.getItem(`${GlobalVariables.APP_NAME} config`);
    if (config) {
      this.firstDay = config.firstDay;
      this.lastDay = config.lasday;
      this.slotDuration = config.interval;
      this.minTime = config.min;
      this.maxTime = config.max;
    }
  }

  openDialog(data?: any) {
    // Configuration de l'ouverture du dialog et les données a transmettre
    const curentData: Periode = {
      classe: ClassNameMap.Periode,
      activity: {classe: ClassNameMap.Activity,name: data === undefined ? '' : data.name === undefined ? '' : data.name},
      validityStartDate: data === undefined ? null : data.start === undefined ? null : data.start,
      validityEndDate: data === undefined ? null : data.end === undefined ? null : data.end,
      timeSlot: {
        endTime: data === undefined ? this.minTime : data.endTime === undefined ? this.minTime : data.endTime,
        startTime: data === undefined ? this.maxTime : data.startTime === undefined ? this.maxTime : data.startTime,
      },
      day: data === undefined ? DayOfWeek[1] : data.day === undefined ? DayOfWeek[1] : data.day,
      action: data.action,
      id: this.currentObject.id,
      symbol: data.symbol,
      specifiqEnd: data.specifiqEnd === undefined ? null : data.specifiqEnd,
      specifiqStart: data.specifiqStart === undefined ? null : data.specifiqStart,
      businessHours: this.businessHoursChange()[0].daysOfWeek,
      isProgramable: data.isProgramable === undefined ? true : data.isProgramable,
    };
    /*const dialogRef = this.dialog.open(DoctorPlaningModalComponent,  {
      width: '700px',
      height: '400px',
      disableClose: true,
      data: curentData
    });*/
    const dialogRef = this.modalService.openModal(DoctorPlaningModalComponent,
      "doctor.planing.add.planning.hours.title",undefined,{
      /*width: '50%',
      height:'340px',*/
      position:{left:"10%", right:"10%"},
      disableClose: true,
      data: curentData
    });
    dialogRef.afterClosed().subscribe((result: Periode) => {
      if (result !== undefined) {
        if (result.days) {
          from(result.days).pipe(
            map((elt: {value: string, text: string}) => elt.value)
          ).subscribe(val => {
            this.newEvent = {};
            if (val) {
              this.newEvent.daysOfWeek = [parseInt(DayOfWeek[val])];
              this.newEvent.startTime = `${result.timeSlot.startTime}`;
              this.newEvent.endTime = `${result.timeSlot.endTime}`;
              this.newEvent.startRecur = result.validityStartDate;
              this.newEvent.endRecur = result.validityEndDate;
            }
            let h = `${result.timeSlot.startTime}`.split(':');
            result.validityStartDate.setHours(parseInt(h[0]), parseInt(h[1]), parseInt(h[2]));
            h = `${result.timeSlot.endTime}`.split(':');
            result.validityEndDate.setHours(parseInt(h[0]), parseInt(h[1]), parseInt(h[2]));
            this.newEvent.title = result.activity? result.activity.name : this.translateService.instant(result.isProgramable === true? "planning.period.is.programmable":"planning.period.is.not.programmable");
            this.newEvent.hasActivity = result.activity != null;
            this.newEvent.start = result.validityStartDate;
            this.newEvent.end = result.validityEndDate;
            this.newEvent.action = result.action;
            this.newEvent.id = result.id;
            this.newEvent.symbol = result.symbol;
            this.newEvent.isProgramable = result.isProgramable;
            this.newEvent.type = result.type;

            switch (result.action) {
              case 'delete' :
                  this.newEvent.toDelete = true;
                  this.nkapFullCalendarCmp.calendarEvents = this.nkapFullCalendarCmp.calendarEvents.filter(v => v.symbol !== result.symbol);
                  break;
              case 'edit' :
                  this.newEvent.backgroundColor = '#4b6584';
                  this.newEvent.toAddOrUpdate = true;
                  this.nkapFullCalendarCmp.calendarEvents = this.nkapFullCalendarCmp.calendarEvents.filter(v => v.symbol !== result.symbol);
                  this.newEvent.symbol = Symbol();
                  this.nkapFullCalendarCmp.calendarEvents = this.nkapFullCalendarCmp.calendarEvents.concat(this.newEvent);
                  break;
              case 'create' :
                this.newEvent.backgroundColor = '#4b6584';
                this.newEvent.symbol = Symbol();
                this.newEvent.toAdd = true;
                this.nkapFullCalendarCmp.calendarEvents = this.nkapFullCalendarCmp.calendarEvents.concat(this.newEvent);
                break;
            }

            if (result.isProgramable === false) {
              this.newEvent.backgroundColor = '#807F7F';
            }

          });
        } 
      }
    });
  }

  getGoodDate(date: string, time: string): Date {
    if (date) {
      const dataArray = date.split('-');
      const timeArray = time.split(':');
      const year = parseInt(dataArray[0]);
      const month = parseInt(dataArray[1]);
      const day = parseInt(dataArray[2]);
      return new Date(year, month, day, parseInt(timeArray[0]), parseInt(timeArray[1]), timeArray[2] ? parseInt(timeArray[2]) : 0);
    }
  }

  selectedStartDayChange(event) {
    if (event) {
      try {
        this.firstDay =  parseInt(event.value);
        this.ChangeHiddenDay();
      } catch (error) {
        //DOTO
      }
    }
  }

  selectedEndDayChange(event) {
    if (event) {
      try {
        this.lastDay = parseInt(event.value);
        this.ChangeHiddenDay();
      } catch (error) {
        //DOTO
      }
    }
  }

  /**
   * Use to show/hide somme days in calender
   */
  businessHoursChange() {

    // when a last day as sunday
    if (this.lastDay === 0 ) {
      const days = [];
      const showDay = new Set();
      for (let index = 1; index < this.firstDay; index++) {
        showDay.add(index);
      }

      for (let index = 0; index < 7; index++) {
        if (!showDay.has(index)) {
          days.push(index);
        }
      }

      return this.businessHours = [
        {
          daysOfWeek: days,
          startTime: this.minTime,
          endTime: this.maxTime
        }
      ];
    }

    // when the user select first day  who are lower thand last day
    if (this.firstDay < this.lastDay) {
      const days = [];
      for (let index = this.firstDay; index <= this.lastDay; index++) {
        days.push(index);
      }
      return this.businessHours = [
        {
          daysOfWeek: days,
          startTime: this.minTime,
          endTime: this.maxTime
        }
      ];
    }

    // when the user select first day who are greater thand last day
    if (this.firstDay > this.lastDay) {
      const days = [];
      for (let index = this.lastDay; index <= this.firstDay; index++) {
        days.push(index);
      }
      return this.businessHours = [
        {
          daysOfWeek: days,
          startTime: this.minTime,
          endTime: this.maxTime
        }
      ];
    }

    // when a first day are equals to last day we shool render all a day
    if (this.firstDay === this.lastDay) {
      return this.businessHours = [
        {
          daysOfWeek: [0, 1, 2, 3, 4, 5, 6],
          startTime: this.minTime,
          endTime: this.maxTime
        }
      ];
    }
  }

  getDoctor(value: any, userId? : string) {
    this.nkapService.search(
      {value: value ? value : '', userId: userId ? userId : undefined},
      `${this.parameters.doctorUrl}`).subscribe(results => {
      this.nkapFullCalendarCmp.doctorList = results.datas;
    }, err => {
      //DOTO
    });
  }
            
  AddEvent() {
    const day = this.currentObject.day 
    const viewType = this.currentObject.view.type !== 'timeGridWeek' && this.currentObject.view.type !== 'timeGridDay';
    const data = {
      isProgramable: this.currentObject.isProgramable,
      action: this.currentObject.action,
      name: this.currentObject.hasActivity === true && this.currentObject.title !== null ? this.currentObject.title : '',
      start: this.currentObject.start,
      end: this.currentObject.end,
      day: DayOfWeek[day],
      endTime: viewType ? this.currentObject.action === 'create'? `${this.maxTime}:00` : this.currentObject.endStr : this.currentObject.endStr,
      startTime: viewType ? this.currentObject.action === 'create'? `${this.minTime}:00` : this.currentObject.startStr : this.currentObject.startStr,
      id: this.currentObject.id === undefined ? null : this.currentObject.id,
      symbol:  this.currentObject.symbol === undefined ? Symbol() : this.currentObject.symbol,
      specifiqStart: this.currentObject.specifiqStart,
      specifiqEnd: this.currentObject.specifiqEnd

    };
    this.openDialog(data);
  }
  emitEvent() {
    this.eventRenderSubject.next(this.diary);
  }

  SaveDiary() {
    if (this.nkapFullCalendarCmp.doctorControl.invalid || typeof this.nkapFullCalendarCmp.doctorControl.value === 'string') {
      this.translateService.get('doctor.planing.doctor.required').subscribe(res => {
        this.notificationService.log(res, NotificationType.INFO);
      });
    } else {
      this.diary.periods = [];
    from(this.nkapFullCalendarCmp.calendarEvents).pipe(
      map((event: any) => {
        const period: any = {};
        if (event.type === 'simpleEvent') {
          period.validityEndDate = event.end;
          period.validityStartDate = event.start;
          period.timeSlot = new TimeSlot();
          period.timeSlot.endTime = (new Date(event.end)).toTimeString().slice(0, 8);
          period.timeSlot.startTime = (new Date(event.start)).toTimeString().slice(0, 8);
        } 
        
        if (event.daysOfWeek){
          period.validityEndDate = event.endRecur;
          period.validityStartDate = event.startRecur;
          period.timeSlot = new TimeSlot();
          period.timeSlot.endTime = event.endTime;
          period.timeSlot.startTime = event.startTime;
          period.day = DayOfWeek[event.daysOfWeek[0]];
        }
        period.activity = event.hasActivity === true ? new Activity(event.title) : null;
        period.id = +event.id;
        period.isProgramable = event.isProgramable;
        period.classe = ClassNameMap.Periode;
        return period;
      }),
    ).subscribe(val => {
      this.diary.periods.push(val);
    }, (err) => {
      //DOTO
    },
    () => {
      this.diary.doctor = this.currentDoctor;
      this.nkapService.save(this.diary, `${this.parameters.diaryUrl}`, true).subscribe((results: Diary) => {
        this.nkapFullCalendarCmp.calendarEvents = [];
        this.nkapFullCalendarCmp.calendarComponent.getApi().getEvents().forEach(el => el.remove);
          this.diary = results;
          this.getEventFromServer(results);
      },
      (err) => {
        this.translateService.get(`${err}`).subscribe(errTranslate => {
          this.notificationService.log(errTranslate, NotificationType.ERROR);
        });
      });
    });
  }
}

  eventClick(info) {
  }

  eventResize(info) {
    const day = (new Date(info.event.start)).getDay();
    const startTime = (new Date(info.event.start)).toTimeString().slice(0, 8);
    const endTime = (new Date(info.event.end)).toTimeString().slice(0, 8);
    let index = this.nkapFullCalendarCmp.calendarEvents.findIndex(val => val.symbol === info.event.extendedProps.symbol);
    this.nkapFullCalendarCmp.calendarEvents[index].startTime = `${startTime}`;
    this.nkapFullCalendarCmp.calendarEvents[index].endTime = `${endTime}`;
    this.nkapFullCalendarCmp.calendarEvents[index].daysOfWeek = [day];
    this.nkapFullCalendarCmp.calendarEvents[index].backgroundColor = `#4b6584`;
    this.nkapFullCalendarCmp.calendarEvents[index].action = `edit`;
    if (this.nkapFullCalendarCmp.calendarEvents[index].isProgramable === false) {
      this.nkapFullCalendarCmp.calendarEvents[index].backgroundColor = '#807F7F';
    }
    this.nkapFullCalendarCmp.calendarComponent.getApi().getEvents().forEach(ev => ev.remove());
    this.nkapFullCalendarCmp.calendarEvents = this.nkapFullCalendarCmp.calendarEvents.splice(0);
  }

  eventRender(info) {
    if (!info.isEnd && !info.isStart) {
      info.event.moveStart()
    }
    if(this.draggable) {
      this.draggable.settings['create'] = false;
      this.draggable.destroy();
      this.draggable = null;
    }

    // ajout de l'évènement de double click
    if (this.validAcess() || this.justHimDiary()) {
      info.el.ondblclick = (event) => {
        this.currentObject = {
          title: info.event.title,
          start: this.nkapFullCalendarCmp.startControl.value._d !== undefined ? this.nkapFullCalendarCmp.startControl.value._d : this.nkapFullCalendarCmp.startControl.value,
          end: this.nkapFullCalendarCmp.endControl.value._d !== undefined ? this.nkapFullCalendarCmp.endControl.value._d : this.nkapFullCalendarCmp.endControl.value,
          view: {type: info.view.type},
          startStr: (new Date(info.event.start)).toTimeString().slice(0, 8),
          endStr: (new Date(info.event.end)).toTimeString().slice(0, 8),
          id: parseInt(info.event.id),
          action: 'edit',
          symbol: info.event.extendedProps.symbol,
          isProgramable: info.event.extendedProps.isProgramable,
          day: (new Date(info.event.start)).getDay()
        };
        this.AddEvent();
      };
    }
   
  }

  emitDoctor() {
    this.currentDoctorSubject.next(this.currentDoctor);
  }

  ChangeHiddenDay() {
    const businessDays = this.businessHoursChange()[0].daysOfWeek;
    const showDays = new Set(businessDays);
    const hiddenDays = new Set();
    for (let index = 0; index < 7; index++) {
      if (!showDays.has(index)) {
        hiddenDays.add(index);
      }
    }
    this.hiddenDays = Array.from(hiddenDays);
  }
}
