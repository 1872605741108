
import { BrowserModule } from '@angular/platform-browser';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import { NgModule, LOCALE_ID, APP_INITIALIZER } from '@angular/core';
import {MatToolbarModule} from '@angular/material/toolbar';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import {MatSidenavModule} from '@angular/material/sidenav';
import { NgxSummernoteModule } from 'ngx-summernote';
import { MatFormFieldModule, MatInputModule, MatCheckboxModule, MatTableModule,
  MatIconModule, MatSelectModule,
  MatButtonToggleModule, MatAutocompleteModule, MatButtonModule, MatStepperModule, 
  MatStepperIntl, MatGridListModule, MatCardModule, MatTooltipModule, MatDividerModule, 
  MatRadioModule, MatListModule, MatPaginatorModule, MatSlideToggleModule, 
  MatSliderModule, MAT_AUTOCOMPLETE_DEFAULT_OPTIONS ,
   MAT_DATE_LOCALE,
   MatPaginatorIntl,
   MatTabsModule} from '@angular/material';
import { MatDialogModule, MatDialogRef, MAT_DIALOG_DATA }from '@angular/material/dialog';
import { HttpClientModule, HttpClient, HTTP_INTERCEPTORS} from '@angular/common/http';
import { TranslateModule, TranslateLoader, TranslateService } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent, NkapMatPaginatorIntl, DynamicLocaleId } from './app.component';
import { PatientPresenceReasonComponent } from './components/reception/patient-presence-reason/patient-presence-reason.component';
import {MatExpansionModule} from '@angular/material/expansion';
import { PatientIdentificationComponent } from './components/patient-identification/patient-identification.component';
import { AdmissionStepperComponent } from './components/admission-stepper/admission-stepper.component';
import {MatMenuModule} from '@angular/material/menu';
import {MatSnackBarModule, MAT_SNACK_BAR_DEFAULT_OPTIONS} from '@angular/material/snack-bar';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';
import 'hammerjs';
import { AdresseComponent } from './components/adresse/adresse.component';
import { HomeComponent } from './components/reception/home/home.component';
import { MainToolbarComponent } from './components/main-toolbar/main-toolbar.component';
import { FooterBarComponent } from './components/footer-bar/footer-bar.component';
import { OverlayContainer, FullscreenOverlayContainer } from '@angular/cdk/overlay';
import { SnackBarMessageComponent, ConfirmDialogComponent,AppProgressBarComponent } from './services/nkap-notification.service';
import { DashboardComponent } from './components/dashboard/dashboard.component';
import { AntecedentsFrcvComponent } from './components/antecedents-frcv/antecedents-frcv.component';
import { LoginComponent } from './components/login/login.component';
import { PrestationFamilyEditComponent } from './components/prestation/prestation-family/prestation-family-edit/prestation-family-edit.component';
import { PrestationFamilyShowComponent } from './components/prestation/prestation-family/prestation-family-show/prestation-family-show.component';
import { PrestationFamilyListComponent } from './components/prestation/prestation-family/prestation-family-list/prestation-family-list.component';
import { PrestationListComponent } from './components/prestation/prestation-list/prestation-list.component';
import { PrestationEditComponent } from './components/prestation/prestation-edit/prestation-edit.component';
import { PrestationShowComponent } from './components/prestation/prestation-show/prestation-show.component';
import { MainMenuComponent } from './components/main-menu/main-menu.component';
import { MenuItemComponent } from './components/main-menu/menu-item/menu-item.component';
import { FrcvEditComponent } from './components/frcv/frcv-edit/frcv-edit.component';
import { FrcvListComponent } from './components/frcv/frcv-list/frcv-list.component';
import { FrcvShowComponent } from './components/frcv/frcv-show/frcv-show.component';
import { SingleContactPersonComponent } from './components/single-contact-person/single-contact-person.component';
import { AntecedentEditComponent } from './components/antecedent/antecedent-edit/antecedent-edit.component';
import { AntecedentListComponent } from './components/antecedent/antecedent-list/antecedent-list.component';
import { AntecedentShowComponent } from './components/antecedent/antecedent-show/antecedent-show.component';
import { UserParametersEditComponent } from './components/user/user-parameters-edit/user-parameters-edit.component';
import { UserParametersShowComponent } from './components/user/user-parameters-show/user-parameters-show.component';
import { ApointmentEditComponent } from './components/apointment/apointment-edit/apointment-edit.component';
import { ApointmentListComponent } from './components/apointment/apointment-list/apointment-list.component';
import { ContactPersonComponent } from './components/contact-person/contact-person.component';
import { CouvertureSanteComponent } from './components/couverture-sante/couverture-sante.component';
import { ButtonsBarComponent } from './components/buttons-bar/buttons-bar.component';
import { CouvertureSanteModalComponent } from './components/couverture-sante/couverture-sante-modal/couverture-sante-modal.component';
import { ApointmentShowComponent } from './components/apointment/apointment-show/apointment-show.component';
import { PartyComponent } from './components/party/party.component';
import { CompanyIdentificationComponent } from './components/party/company-identification/company-identification.component';
import { ThirdPartyPayerEditComponent } from './components/partner/third-party-payer/third-party-payer-edit/third-party-payer-edit.component';
import { ThirdPartyPayerListComponent } from './components/partner/third-party-payer/third-party-payer-list/third-party-payer-list.component';
import { ThirdPartyPayerShowComponent } from './components/partner/third-party-payer/third-party-payer-show/third-party-payer-show.component';
import { DoctorEditComponent } from './components/partner/doctor/doctor-edit/doctor-edit.component';
import { DoctorListComponent } from './components/partner/doctor/doctor-list/doctor-list.component';
import { DoctorShowComponent } from './components/partner/doctor/doctor-show/doctor-show.component';
import { ReceptionListComponent } from './components/reception/reception-list/reception-list.component';
import { NkapAutocompleteComponent } from './components/base/nkap-autocomplete/nkap-autocomplete.component';
import { MainUnitComponent } from './components/initialization/main-unit/main-unit.component';
import { DefaultUserComponent } from './components/initialization/default-user/default-user.component';
import { PersonIdentificationComponent } from './components/party/person-identification/person-identification.component';

import { DoctorPlaningComponent } from './components/partner/doctor/doctor-planing/doctor-planing.component';
import { FullCalendarModule } from '@fullcalendar/angular';
import { AdressShowComponent } from './components/adresse/adress-show/adress-show.component';
import { PartyShowComponent } from './components/party/party-show/party-show.component';
import { BusinessUnitEditComponent } from './components/business-unit/business-unit-edit/business-unit-edit.component';
import { BusinessUnitListComponent } from './components/business-unit/business-unit-list/business-unit-list.component';
import { BusinessUnitShowComponent } from './components/business-unit/business-unit-show/business-unit-show.component';
import { MedicalFolderListComponent } from './components/medical-folder/medical-folder-list/medical-folder-list.component';
import { MedicalFolderShowComponent } from './components/medical-folder/medical-folder-show/medical-folder-show.component';
import { DoctorPlaningModalComponent } from './components/partner/doctor/doctor-planing/doctor-planing-modal/doctor-planing-modal.component';
import {MatDatepickerModule } from '@angular/material/datepicker';
import {MatMomentDateModule, MAT_MOMENT_DATE_ADAPTER_OPTIONS} from '@angular/material-moment-adapter';
import { HashLocationStrategy, LocationStrategy } from '@angular/common';
import { PrestationParamsComponent } from './components/prestation/prestation-params/prestation-params.component';
import {MatProgressBarModule, MatProgressBar} from '@angular/material/progress-bar';
import {OverlayModule} from '@angular/cdk/overlay';
import {MatChipsModule} from '@angular/material/chips';
import { ReceptionShowComponent } from './components/reception/reception-show/reception-show.component';
import { Observable } from 'rxjs';
import { MedicalFolderGridComponent } from './components/medical-folder/medical-folder-grid/medical-folder-grid.component';
import { MedicalFolderSearchDialogComponent } from './components/medical-folder/medical-folder-search-dialog/medical-folder-search-dialog.component';
import { IdentityComponent } from './components/party/identity/identity.component';
import { PatientCareCoverageDocListComponent } from './components/care-coverage-doc/patient-care-coverage-doc-list/patient-care-coverage-doc-list.component';
import { PatientCareCoverageDocEditComponent } from './components/care-coverage-doc/patient-care-coverage-doc-edit/patient-care-coverage-doc-edit.component';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { ChronologicalListComponent } from './components/reception/chronological-list/chronological-list.component';
import { FileDownloaderComponent } from 'src/app/components/file-downloader/file-downloader.component';
import { CareCoverageDocShowComponent } from './components/care-coverage-doc/care-coverage-doc-show/care-coverage-doc-show.component';
import { ReceptionCareCoverageDocEditComponent } from './components/reception/reception-care-coverage-doc-edit/reception-care-coverage-doc-edit.component';
import { NkapKeycloakService } from './services/nkap-keycloak.service';
import { NkapHttpInterceptorService } from './services/nkap-http-interceptor.service';
import { UserAppService } from './services/user-app.service';
import { NkapHttpService } from './services/nkap-http.service';
import { PortalModule } from '@angular/cdk/portal';
import { ApointmentEditModalAddComponent } from './components/apointment/apointment-edit/apointment-edit-modal-add/apointment-edit-modal-add.component';
import { ApointmentEditModalEditComponent } from './components/apointment/apointment-edit/apointment-edit-modal-edit/apointment-edit-modal-edit.component';
import { MandatoryInformationComponent } from './components/admission-stepper/mandatory-information/mandatory-information.component';
import { SecureTemplateComponent } from './components/buttons-bar/secure-template.component';
import { ConsultationSheetComponent } from './components/consultation/consultation-sheet/consultation-sheet.component';
import { PhysicalExamComponent } from './components/consultation/physical-exam/physical-exam.component';
import { PatientHistoryComponent } from './components/medical-folder/medical-folder-show/patient-history/patient-history.component';
import { AntecedentTypeEditComponent } from './components/antecedent/antecedent-type/antecedent-type-edit/antecedent-type-edit.component';
import { SystemInvestigationEditComponent } from './components/consultation/consultation-sheet/system-investigation/system-investigation-edit/system-investigation-edit.component';
import { CostsRegulationsStatementComponent } from './components/reporting/costs-regulations-statement/costs-regulations-statement.component';
import { SystemInvestigationTypeListComponent } from './components/system-investigation-type/system-investigation-type-list/system-investigation-type-list.component';
import { SystemInvestigationTypeEditComponent } from './components/system-investigation-type/system-investigation-type-edit/system-investigation-type-edit.component';
import { AntecedentTypeListComponent } from './components/antecedent/antecedent-type/antecedent-type-list/antecedent-type-list.component';
import { AntecedentTypeShowComponent } from './components/antecedent/antecedent-type/antecedent-type-show/antecedent-type-show.component';
import { CounterActivitiesComponent } from './components/reporting/counter-activities/counter-activities.component';
import { ModalComponent } from './components/base/modal/modal.component';
import { NkapFullCalendarComponent } from './components/nkap-full-calendar/nkap-full-calendar.component';
import { BillingOfServicesComponent } from './components/reporting/billing-of-services/billing-of-services.component';
import { PatientDiagnosisComponent } from './components/consultation/patient-diagnosis/patient-diagnosis.component';
import { MedicalPrescriptionEditComponent } from './components/prescriptions/medical-prescription/medical-prescription-edit/medical-prescription-edit.component';
import { PrescriptionListComponent } from './components/prescriptions/prescription-list/prescription-list.component';
import { ExamPrescriptionEditComponent } from './components/prescriptions/exam-prescription/exam-prescription-edit/exam-prescription-edit.component';
import { ExamPrescriptionShowComponent } from './components/prescriptions/exam-prescription/exam-prescription-show/exam-prescription-show.component';
import { CarePrescriptionEditComponent } from './components/prescriptions/care-prescription/care-prescription-edit/care-prescription-edit.component';
import { CarePrescriptionListComponent } from './components/prescriptions/care-prescription/care-prescription-list/care-prescription-list.component';
import { CarePrescriptionShowComponent } from './components/prescriptions/care-prescription/care-prescription-show/care-prescription-show.component';
import { DurationSelectorComponent } from './components/base/duration-selector/duration-selector.component';
import { MedicalPrescriptionEditFormComponent } from './components/prescriptions/medical-prescription/medical-prescription-edit-form/medical-prescription-edit-form.component';
import { MedicalPrescriptionEditModalComponent } from './components/prescriptions/medical-prescription/medical-prescription-edit-modal/medical-prescription-edit-modal.component';
import { MedicalPrescriptionShowComponent } from './components/prescriptions/medical-prescription/medical-prescription-show/medical-prescription-show.component';
import { MedicalFolderSelectorComponent } from './components/medical-folder/medical-folder-selector/medical-folder-selector.component';
import { PrestationEditModalComponent } from './components/prestation/prestation-edit-modal/prestation-edit-modal.component';
import { MedicalPrescriptionListComponent } from './components/prescriptions/medical-prescription/medical-prescription-list/medical-prescription-list.component';
import { ExamPrescriptionListComponent } from './components/prescriptions/exam-prescription/exam-prescription-list/exam-prescription-list.component';
import { SystemPhysicalExamShowComponent } from './components/consultation/system-physical-exam-show/system-physical-exam-show.component';
import { AntecedentsFrcvShowComponent } from './components/antecedents-frcv/antecedents-frcv-show/antecedents-frcv-show.component';
import { PrescriptionsDetailsShowComponent } from './components/prescriptions/prescriptions-details-show/prescriptions-details-show.component';
import { AgeRendererComponent } from './components/base/age-renderer/age-renderer.component';
import { CareListComponent } from './components/care/care-list/care-list.component';
import { CareShowComponent } from './components/care/care-show/care-show.component';
import { CareExecutionEditComponent } from './components/care/care-execution-edit/care-execution-edit.component';
import { CareShowContentComponent } from './components/care/care-show-content/care-show-content.component';
import { ExamListComponent } from './components/exam/exam-list/exam-list.component';
import { ExamResultEditComponent } from './components/exam/exam-result-edit/exam-result-edit.component';
import { ExamShowComponent } from './components/exam/exam-show/exam-show.component';
import { PrescriptionSelectorComponent } from './components/prescriptions/prescription-selector/prescription-selector.component';
import { ExamShowContentComponent } from './components/exam/exam-show-content/exam-show-content.component';
import { ExamAssessmentEditComponent } from './components/exam/exam-assessment-edit/exam-assessment-edit.component';
import { LinebreaksPipe } from './components/base/nkap-validators';
import { NkapSummernoteEditorComponent } from './components/base/nkap-summernote-editor/nkap-summernote-editor.component';
import { MedicalInstitutionPartnerEditComponent } from './components/partner/medical-institution-partner/medical-institution-partner-edit/medical-institution-partner-edit.component';
import { MedicalInstitutionPartnerListComponent } from './components/partner/medical-institution-partner/medical-institution-partner-list/medical-institution-partner-list.component';
import { MedicalInstitutionPartnerShowComponent } from './components/partner/medical-institution-partner/medical-institution-partner-show/medical-institution-partner-show.component';
import { ExamProcessingEditComponent } from './components/exam/exam-processing-edit/exam-processing-edit.component';

export const MY_FORMATS = {
  parse: {
    dateInput: "yyyy-MM-dd'T'HH:mm:ss.SSSZ",
  },
  display: {
    dateInput: "LL",
    //dateInput: "yyyy-MM-dd",
    monthYearLabel: 'YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'YYYY',
  },
};

export function initializer(keycloak: NkapKeycloakService) {
  return () => keycloak.init();
}

@NgModule({
  declarations: [
    AppComponent,
    PatientPresenceReasonComponent,
    PatientIdentificationComponent,
    AdmissionStepperComponent,
    AdresseComponent,
    HomeComponent,
    MainToolbarComponent,
    SnackBarMessageComponent,
    FooterBarComponent,
    DashboardComponent,
    AntecedentsFrcvComponent,
    LoginComponent,
    PrestationFamilyEditComponent,
    PrestationFamilyShowComponent,
    PrestationFamilyListComponent,
    PrestationListComponent,
    PrestationEditComponent,
    PrestationShowComponent,
    SingleContactPersonComponent,
    MainMenuComponent,
    MenuItemComponent,
    FrcvEditComponent,
    FrcvListComponent,
    FrcvShowComponent,
    AntecedentEditComponent,
    AntecedentListComponent,
    AntecedentShowComponent,
    UserParametersEditComponent,
    UserParametersShowComponent,
    ApointmentEditComponent,
    ApointmentListComponent,
    ContactPersonComponent,
    CouvertureSanteComponent,
    CouvertureSanteModalComponent,
    ButtonsBarComponent,
    ApointmentShowComponent,
    ConfirmDialogComponent,
    PartyComponent,
    CompanyIdentificationComponent,
    AdressShowComponent,
    ThirdPartyPayerEditComponent,
    ThirdPartyPayerListComponent,
    ThirdPartyPayerShowComponent,
    DoctorEditComponent,
    DoctorListComponent,
    DoctorShowComponent,
    ReceptionListComponent,
    NkapAutocompleteComponent,
    MainUnitComponent,
    DefaultUserComponent,
    PersonIdentificationComponent,
    DoctorPlaningComponent,
    DoctorPlaningModalComponent,
    PartyShowComponent,
    BusinessUnitEditComponent,
    BusinessUnitListComponent,
    BusinessUnitShowComponent,
    MedicalFolderListComponent,
    MedicalFolderShowComponent,
    PrestationParamsComponent,
    AppProgressBarComponent,
    PrestationParamsComponent,
    ReceptionShowComponent,
    MedicalFolderGridComponent,
    MedicalFolderSearchDialogComponent,
    IdentityComponent,
    FileDownloaderComponent,
    PatientCareCoverageDocListComponent,
    PatientCareCoverageDocEditComponent,
    ChronologicalListComponent,
    CareCoverageDocShowComponent,
    ReceptionCareCoverageDocEditComponent,
    ApointmentEditModalAddComponent,
    ApointmentEditModalEditComponent,
    MandatoryInformationComponent,
    SecureTemplateComponent,
    ConsultationSheetComponent,
    PhysicalExamComponent,
    PatientHistoryComponent,
    AntecedentTypeEditComponent,
    CostsRegulationsStatementComponent,
    SystemInvestigationEditComponent,
    SystemInvestigationTypeListComponent,
    SystemInvestigationTypeEditComponent,
    AntecedentTypeListComponent,
    AntecedentTypeShowComponent,
    CounterActivitiesComponent,
    ModalComponent,
    NkapFullCalendarComponent,
    BillingOfServicesComponent,
    PatientDiagnosisComponent,
    MedicalPrescriptionEditComponent,
    PrescriptionListComponent,
    ExamPrescriptionEditComponent,
    ExamPrescriptionShowComponent,
    CarePrescriptionEditComponent,
    CarePrescriptionListComponent,
    CarePrescriptionShowComponent,
    DurationSelectorComponent,
    MedicalPrescriptionEditFormComponent,
    MedicalPrescriptionEditModalComponent,
    MedicalPrescriptionShowComponent,
    MedicalFolderSelectorComponent,
    PrestationEditModalComponent,
    MedicalPrescriptionListComponent,
    ExamPrescriptionListComponent,
    SystemPhysicalExamShowComponent,
    AntecedentsFrcvShowComponent,
    PrescriptionsDetailsShowComponent,
    AgeRendererComponent,
    CareListComponent,
    CareShowComponent,
    CareShowContentComponent,
    CareExecutionEditComponent,
    ExamListComponent,
    ExamResultEditComponent,
    ExamShowComponent,
    PrescriptionSelectorComponent,
    ExamShowContentComponent,
    ExamAssessmentEditComponent,
    LinebreaksPipe,
    NkapSummernoteEditorComponent,
    MedicalInstitutionPartnerEditComponent,
    MedicalInstitutionPartnerListComponent,
    MedicalInstitutionPartnerShowComponent,
    ExamProcessingEditComponent],
imports :[
    BrowserModule, BrowserAnimationsModule,
    HttpClientModule, NgxSummernoteModule,
    TranslateModule.forRoot({
        loader: {
            provide: TranslateLoader,
            useFactory: HttpLoaderFactory,
            deps: [HttpClient]
        }
    }),
    AppRoutingModule,
    MatDialogModule,
    MatIconModule,
    MatInputModule,
    MatDatepickerModule,
    MatMomentDateModule,
    MatSelectModule,
    MatButtonToggleModule,
    MatAutocompleteModule,
    MatStepperModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    MatTableModule,
    MatButtonModule,
    MatCardModule,
    MatGridListModule,
    MatCheckboxModule,
    MatFormFieldModule,
    MatInputModule,
    MatSelectModule,
    MatIconModule,
    MatAutocompleteModule,
    MatExpansionModule,
    MatDatepickerModule,
    MatToolbarModule,
    MatMenuModule,
    MatSnackBarModule,
    MatSidenavModule,
    MatSlideToggleModule,
    MatCardModule,
    MatTooltipModule,
    MatDividerModule,
    MatRadioModule,
    MatListModule,
    MatSliderModule,
    MatPaginatorModule,
    ReactiveFormsModule,
    MatProgressSpinnerModule,
    FullCalendarModule,
    MatProgressBarModule,
    DragDropModule,
    OverlayModule,
    MatChipsModule,
    PortalModule,
    MatTabsModule
  ],
  entryComponents: [ModalComponent, MedicalFolderGridComponent,SnackBarMessageComponent, CouvertureSanteModalComponent, DoctorPlaningModalComponent,ConfirmDialogComponent,
    DoctorPlaningModalComponent,ConfirmDialogComponent,MedicalFolderSearchDialogComponent,AppProgressBarComponent,SnackBarMessageComponent, 
    DoctorPlaningComponent,CouvertureSanteModalComponent, DoctorPlaningModalComponent, ConfirmDialogComponent,ApointmentEditModalAddComponent,
    ApointmentEditModalEditComponent, SecureTemplateComponent,AntecedentEditComponent,AntecedentTypeEditComponent, 
    MedicalPrescriptionEditModalComponent, CarePrescriptionEditComponent, ExamPrescriptionEditComponent,
    SystemInvestigationTypeEditComponent, PrestationEditModalComponent], 
  providers: [
    {
      provide: APP_INITIALIZER,
      useFactory: initializer,
      multi: true,
      deps: [ NkapKeycloakService, UserAppService, NkapHttpService]
    },
      {
        provide: MatPaginatorIntl,
        useFactory: (translate) => {
          const service = new NkapMatPaginatorIntl();
          service.injectTranslateService(translate);
          return service;
        },
        deps: [TranslateService]
      },
      {
        provide: HTTP_INTERCEPTORS,
        useClass: NkapHttpInterceptorService,
        multi: true
      },
      {provide: MatDialogRef, useValue: {}},
      {provide: MAT_DIALOG_DATA, useValue: {}},
      //{provide: MatPaginatorIntl, useClass: NkapMatPaginatorIntl},// for paginator translation
      {provide: LocationStrategy, useClass: HashLocationStrategy}, // to have hash url
      {provide: OverlayContainer, useClass: FullscreenOverlayContainer},// for overlay when long process
      {provide: MAT_SNACK_BAR_DEFAULT_OPTIONS, 
        useValue: {duration: 15000, verticalPosition: 'top', horizontalPosition:  'center'}
      },// for the notification popup that render alert/error
      {provide: MAT_AUTOCOMPLETE_DEFAULT_OPTIONS, useValue: 
        {autoActiveFirstOption: true}},// to select by defaut the first element in autocomplete component
      { provide: MAT_MOMENT_DATE_ADAPTER_OPTIONS, useValue: { useUtc: true } },
      { provide: LOCALE_ID, useValue: 'fr-FR'},// to format number according to the language rules
      {provide: MAT_DATE_LOCALE, useValue: 'fr-FR'}// For date picker, default language
    ],
  bootstrap: [AppComponent]
})
export class AppModule { }

// required for AOT compilation
export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http, 'assets/i18n/', '.json');
}

