import { MedicalAgent } from '../organisation/medical-agent';
import { MedicalFolder } from '../admission/medical-folder';
import { Persistent, ClassNameMap } from '../persistent';

export class PatientDiagnosis extends Persistent {

    public doctor?: MedicalAgent;

    public medicalFolder?: MedicalFolder;
    
    public date?: string | Date;
    
    public diagnosisText?: string;
    
    constructor(diagnosisText?: string) {
        super();
        this.classe = ClassNameMap.PatientDiagnosis;
        this.diagnosisText = diagnosisText;
        this.date = new Date();
    }
}