import { BusinessUnit } from './../../../model/organisation/business-unit';
import { Care, CareStatus } from './../../../model/care/care';
import { Prestation } from 'src/app/model/prestation/prestation';
import { ChangeDetectorRef, Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { TranslatePipe, TranslateService } from '@ngx-translate/core';
import * as moment from 'moment';
import { MedicalAgent } from 'src/app/model/organisation/medical-agent';
import { Party } from 'src/app/model/organisation/party';
import { GenderType, Person } from 'src/app/model/organisation/person';
import { ButtonsBarComponent, FormAction } from '../../buttons-bar/buttons-bar.component';
import { FileDownloaderComponent } from '../../file-downloader/file-downloader.component';
import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { NkapHttpService } from 'src/app/services/nkap-http.service';
import { ActivatedRoute, Router } from '@angular/router';
import { UserAppService } from 'src/app/services/user-app.service';
import { NkapNotificationService, NotificationType } from 'src/app/services/nkap-notification.service';
import { SearchCriteria } from 'src/app/model/data-page';
import { Funtions } from 'src/app/model/security/funtionalities';
import { MatAutocompleteSelectedEvent } from '@angular/material';
import { NkapListComponent } from 'src/app/components/base/nkap-list-component';

@Component({
  selector: 'app-care-list',
  templateUrl: './care-list.component.html',
  styleUrls: ['./care-list.component.css']
})
export class CareListComponent extends NkapListComponent<any> {

  /**
   * A reference on download component
   */
  @ViewChild("downloader")
  downloaderComponent: FileDownloaderComponent;
  openFileInTab = true;

  @ViewChild("btnBar")
  btnBarCmp: ButtonsBarComponent;

  translate: TranslatePipe;

  //agentSelected: MedicalAgent;
  medicalAgentControl: FormControl = new FormControl();
  medicalAgentList: MedicalAgent[];

  //unitSelected: MedicalAgent;
  unitControl: FormControl = new FormControl();
  unitList: MedicalAgent[];

  prestationControl: FormControl = new FormControl();
  prestationsSelectedList: Prestation[];
  prestationsList: Prestation[];
  @ViewChild('prestationsInput')
  prestationInput: ElementRef<HTMLInputElement>;

  separatorKeysCodes: number[] = [ENTER, COMMA];

  statusList: {value: any, text: string} [];
  statusSelected: any = null;
  selectUnit: boolean = false;
  selectAgent: boolean = false;

  protected FEMALE = GenderType[GenderType.FEMALE];
  protected MALE = GenderType[GenderType.MALE];
  
  constructor(protected _ref: ChangeDetectorRef, protected httpService: NkapHttpService<any>, 
    protected router: Router, protected route: ActivatedRoute,protected userService: UserAppService,
    protected msgService: NkapNotificationService, protected translateService: TranslateService) {
      super(_ref, httpService,router,route,userService,msgService, translateService);
      this.searchBaseUrl = "care";
      this.criteria = new SearchCriteria();
      this.criteria.pageSize = this.paginatorParams.pageSize;
      this.criteria.period = {};
      this.prestationsSelectedList = [];
      this.buttons = [
        {id: "plan", value: FormAction.CREATE, icon: {type : 'schedule', color: '#0099cc'},
          text: 'btn.care.plan.label', disabled: true, functionalities: [Funtions.care_btn_plan_fct]},
        {id: "add_result", value: FormAction.CREATE, icon: {type : 'book', color: '#0099cc'},
          text: 'btn.care.execution.label', disabled: true, functionalities: [Funtions.care_btn_result_fct]},
        {id: "print", value: FormAction.PRINT, icon: {type : 'print', color: '#ff9933'},
          text: 'btn.care.form.print.label', disabled: true, functionalities: [Funtions.care_btn_print_fct]},
      ];
      this.statusList = [
        {value:null, text: ""},
        {value:CareStatus[CareStatus.REQUESTED], text:"CareStatus." + CareStatus[CareStatus.REQUESTED] + ".label"},
        {value:CareStatus[CareStatus.PLANNED], text:"CareStatus." + CareStatus[CareStatus.PLANNED] + ".label"},
        {value:CareStatus[CareStatus.EXECUTED], text:"CareStatus." + CareStatus[CareStatus.EXECUTED] + ".label"}
      ];
  }

  ngOnInit() {
    super.ngOnInit();
    this.prestationControl.valueChanges.subscribe( (elmt) => {
      if(typeof elmt == "string"){
        let criteria = new SearchCriteria();
        criteria.value = elmt;
        this.httpService.search(criteria, "prestation/care").subscribe( (result)=>{
          this.prestationsList = result ? result.datas : [];
        });
      }
    });
    this.medicalAgentControl.valueChanges.subscribe( (inputValue) => {
      if (typeof inputValue === 'string' ) {
        this.criteria.agentId = null;
        let url = 'organisation/medical-agent';
        if(this.statusSelected == CareStatus[CareStatus.REQUESTED]){

        }
        this.httpService.search({value: inputValue}, url).subscribe ( (result) => {
          this.medicalAgentList = result ? result.datas : [];
        });          
       } else {
        this.criteria.agentId = inputValue.id;
       }
    });
    this.unitControl.valueChanges.subscribe( (inputValue) => {
      if (typeof inputValue === 'string' ) {
        this.criteria.unitId = null;
        this.httpService.search({value: inputValue}, 'organisation/business-unit').subscribe ( (result) => {
           this.unitList = result ? result.datas : [];
        });
       } else {
        this.criteria.unitId = inputValue.id;
       }
    });
  }

  displayPrestation(element: Prestation) {
    //console.log(" element ", element);
    if(element){
      return element.name;
    }
  }

  displayPatient(element: any) {
    if(element && element.patient){
      return element.patient.fullname;
    }
  }

  displayDate( date: Date | string , withHour?: boolean) {
    if(date){
      //console.log("displayDate ", this.lang);
      moment.locale(this.lang);
      let format = this.lang === "en" ? "dddd, MMM Do YYYY" : "dddd, Do MMM YYYY";
      let hourFormat = "HH:mm";
      if(withHour == true) {
        return moment(new Date(date)).format(format) + " [" + moment(new Date(date)).utc().format(hourFormat) + "]";
      }
      return moment(new Date(date)).utc().format(format) ;
    }
  }

  formatDate(date){
    try {
        return moment(new Date(date)).format("LL") + " [" + moment(new Date(date)).utc().format("HH:mm") + "]";
    } catch (e) {
      console.error("Parse date in reception-list fail " , e);
    }
  }
  
  displayMedicalAgent(agent: MedicalAgent) {
    return agent? agent.fullname : '';
  }

  displayUnit(unit: BusinessUnit) {
    return unit? unit.name : '';
  }

  print(id) {
    console.log("print ", id);
    let criteria = new SearchCriteria();
    criteria.lang= this.translateService.currentLang;
    criteria.id = id;
    this.downloaderComponent.criteria=criteria;
    this.downloaderComponent.link = 'care/protocol/print';
    this.downloaderComponent.downloadFile({},true);
    this.downloaderComponent.event.subscribe( (whellDone) => {
      if (whellDone == false) {
        this.msgService.log("form.print.error", NotificationType.ERROR);
      }
    });
  }

  protected search(criteria: any) {
    criteria.prestationIds = [];
    this.prestationsSelectedList.forEach( (elm)=> {
      criteria.prestationIds.push(elm.id);
    });
    super.search(criteria);    
  }

  listSelectionChanged(){
    super.listSelectionChanged();
    if(this.btnBarCmp){
      let lineSelected = (this.selection && this.selection.selected && this.selection.selected.length > 0);
      let selectedLine: Care = lineSelected == true ? this.selection.selected[0] : null;
      const btnStatus = [
        {id: "plan", disabled: (!lineSelected || (selectedLine.status.toString() != CareStatus[CareStatus.REQUESTED] && selectedLine.status.toString() != CareStatus[CareStatus.PLANNED]))},
        {id: "add_result", disabled: (!lineSelected || (selectedLine.status.toString() != CareStatus[CareStatus.PLANNED] && selectedLine.status.toString() != CareStatus[CareStatus.EXECUTED])) },
        {id: "print", disabled: (!lineSelected || selectedLine.status.toString() != CareStatus[CareStatus.PLANNED])},
        {id: "close", disabled: (!lineSelected || selectedLine.status.toString() != CareStatus[CareStatus.EXECUTED])},
      ];    
      this.btnBarCmp.setButtonsStatus(btnStatus);
    }
  }

  btnActionClick(event) {
    let i = 0; 
    if(event && event.id === this.buttons[i++].id){
      if( !this.selection.selected || this.selection.selected.length != 1){
        this.msgService.log("care.list.select.to.modify.error", NotificationType.ERROR);
      }else{
        const care: Care = this.selection.selected[0];
        this.userService.setCare(care);
        if(care.status == CareStatus[CareStatus.REQUESTED]) {
          this.router.navigate(["./apointment/edit", {careId: care.id}]);
        } else if(care.status == CareStatus[CareStatus.PLANNED]) {
          this.router.navigate(["./apointment/edit", {id: (care.currentStep as any).apointmentId,careId: care.id}]);
        }
      }  
    } else if(event && event.id === this.buttons[i++].id){
      if( !this.selection.selected || this.selection.selected.length != 1){
        this.msgService.log("care.list.select.to.modify.error", NotificationType.ERROR);
      }else{
        const care: Care = this.selection.selected[0];
        let params: any = {careId: care.id};
        if(care.status == CareStatus[CareStatus.EXECUTED]) {
          params.id = care.currentStep.id ;
        }
        this.router.navigate(["./care/execution",params ]);
      }
    } else if(event && event.id === this.buttons[i++].id) {
      if( !this.selection.selected || this.selection.selected.length < 1){
        this.msgService.log("care.list.select.to.delete.error", NotificationType.ERROR);
      }else{
        const care: Care = this.selection.selected[0];
        this.print(care.id);
      }
    } else if(event && event.id === this.buttons[i++].id){
      if( !this.selection.selected || this.selection.selected.length < 1){
        this.msgService.log("care.list.select.to.delete.error", NotificationType.ERROR);
      }else{
        const care: Care = this.selection.selected[0];        
        this.closeSelected(care.id);
      }
    } 
  }

  protected startDateSelected(event) {
    if(!this.criteria.period){
      this.criteria.period = {};
    }
    this.criteria.period.start = event.value;
  }

  protected endDateSelected(event) {
    if(!this.criteria.period){
      this.criteria.period = {};
    }
    this.criteria.period.end = event.value;
  }

  protected viewElement(element) {
    this.router.navigate(['./care/show', {id: element.id}]);
  }

  closeSelected(id){
    this.msgService.confirm(this.translate.transform("care.close.confirm.text")).subscribe( (result) => {
      if(result === true){
          this.httpService.post({id:id}, 'care/close').subscribe( (val) => {
            this.msgService.log("care.close.succed", NotificationType.SUCCESS);
            this.searchClicked({});
          }, (error) => {
            this.msgService.log("care.close.fail", NotificationType.ERROR);
          });
        }
      });
  }
  
  protected setCriteriaDataInView(cachedNavigationData: any) {
    super.setCriteriaDataInView(cachedNavigationData);
    if(cachedNavigationData != null && cachedNavigationData.medicalAgent != null) {
      this.medicalAgentControl.setValue(cachedNavigationData.medicalAgent);
    }
    if(cachedNavigationData != null && cachedNavigationData.prestations != null) {
      this.prestationsSelectedList = [].concat(cachedNavigationData.prestations);
    }
    if(cachedNavigationData != null && cachedNavigationData.criteria != null && cachedNavigationData.criteria.period != null) {
      this.criteria = Object.assign(this.criteria, {period: cachedNavigationData.criteria.period});
    }
  }

  protected getNavigationDataToStore(): any {
    let data = super.getNavigationDataToStore();
    return Object.assign( data != null ? data : {}, {
       medicalAgent: this.medicalAgentControl.value,
       prestations: this.prestationsSelectedList
    });
  }

  removeInPrestations(prestation) {
    if (prestation) {
      const index = this.prestationsSelectedList.findIndex( (elmt) => elmt.id == prestation.id);
      if (index != -1) {
        this.prestationsSelectedList.splice(index, 1);
      }
    }
  }

  selectedPrestations(event: MatAutocompleteSelectedEvent) {
    let selected = event.option.value;
    //console.log("selectedPrestations ", selected);
    if(selected != null && this.prestationsSelectedList != null && this.prestationsSelectedList.findIndex( (elm)=> elm.id == selected.id ) == -1) {
      this.prestationsSelectedList = this.prestationsSelectedList.concat([selected]);
    }
    this.prestationInput.nativeElement.value = '';
    this.prestationControl.setValue(null);
  }

  protected getPersonAge(person: Person){
    return person ? Party.getAge(person.birthdate) : null;
  }

  protected getBirthdate(date: Date) {
    moment.locale(this.lang);
    return moment(date).format("L");
  }

  protected getCurrentStepText(care:Care): any{
    if(!care){return;}
    let text: string = "exam-list.ExamStatus." + care.status + ".label";
    let params: any;
    //console.log("getCurrentStepText ", care);
    if(care.currentStep && care.status == CareStatus[CareStatus.REQUESTED] ){
       params = {
         date: this.displayDate(care.currentStep.date, true), 
         requestPrescriber: (care.currentStep as any).prescriberFullname ? (care.currentStep as any).prescriberFullname : null
       };
       if(!params.requestPrescriber) {
          text = "exam-list.ExamStatus." + care.status + ".no.prescriber.label";
       }
    } else if(care.currentStep && care.status == CareStatus[CareStatus.PLANNED] ){
      params = {
        apointmentDate: (care.currentStep as any).apointmentDate ? this.displayDate((care.currentStep as any).apointmentDate) : null,
        apointmentHour: (care.currentStep as any).timeSlot ? (care.currentStep as any).timeSlot.startTime : null,
        apointmentAgent: (care.currentStep as any).personToMeetFullname ? (care.currentStep as any).personToMeetFullname : null,
        apointmentUnit: (care.currentStep as any).unitName ? (care.currentStep as any).unitName : null,
      };
      if(!params.apointmentUnit && params.apointmentAgent) {
         text = "exam-list.ExamStatus." + care.status + ".no.unit.label";
      } else if(!params.apointmentUnit && !params.apointmentAgent) {
        text = "exam-list.ExamStatus." + care.status + ".no.unit.no.agent.label";
      }else if(params.apointmentUnit && !params.apointmentAgent) {
        text = "exam-list.ExamStatus." + care.status + ".no.agent.label";
      }
    }else if(care.currentStep && care.status == CareStatus[CareStatus.EXECUTED] ){
        params = {
          resultAgent: (care.currentStep as any).agentFullname? (care.currentStep as any).agentFullname : null,
          date: this.displayDate(care.currentStep.date)
        };
    }
    return this.translateService.instant(text,params);
    
  }

}
