import { Pipe, PipeTransform } from '@angular/core';
import { AbstractControl } from '@angular/forms';

export class NkapValidators {
    public static notEmpty(control: AbstractControl) {
        if (control && control.value != null && (typeof control.value == 'string')  && control.value.trim() === "") {
            return {'notEmpty': false};
        }
        return null;
    }

    public static notAfterCurrentDay(control: AbstractControl) {
        if (control && control.value && control.value > new Date()) {
            return {'notAfterCurrentDay': false};
        }
        return null;
    }

    public static afterCurrentDay(control: AbstractControl) {
        if (control && control.value && control.value < new Date()) {
            return {'afterCurrentDay': false};
        }
        return null;
    }

    public static dataWithIdSelected(control: AbstractControl) {
        if (control && control.value != null && (typeof control.value == 'string' || (typeof control.value != 'string' && control.value.id == null))) {
            return {'dataWithIdSelected': false};
        }
        return null;
    }

    public static validNumber(control: AbstractControl) {
        if (control && control.value != null && (typeof control.value !== 'number')) {
            return {'validNumber': false};
        }
        return null;
    }

    public static validNumberUpperThanZero(control: AbstractControl) {
        console.log("validNumberUpperThanZero",typeof control.value, Number(control.value));
        if (control && control.value != null && (
             (typeof control.value != 'number') || Number(control.value) <= 0)
            ) {
            return {'validNumberUpperThanZero': false};
        }
        return null;
    }
}

@Pipe({
    name: 'linebreaks'
})
export class LinebreaksPipe implements PipeTransform {

    transform(value: string): string {
      //  console.log("linebreaks", value.match(/\n/g));
      let result = value.replace(/\n/g, '<br/>');
      //result = value.replace(/\\n/g, '<br/>');
      //console.log("linebreaks", value.match(/\n/g), result);
      return result;
    }
}