
export class Persistent {
    public classe: string;
    public id?: number;
    public creationDate?: Date;
    public modificationDate?: Date;
    public toAddOrUpdate?: boolean;
    public toDelete?: boolean;
    public authorization?: string;
    constructor(){}
}

export class TaggedValue extends Persistent {
    public name?: string;
    public stringValue?: string;
    public integerValue?: number;
    public doubleValue?: string;
    public decimalValue?: string;

    constructor(){
        super();
        this.classe = ClassNameMap.TaggedValue;
        this.name = "";
        this.stringValue = "";
    }
}

export class Nameable extends Persistent {
    public name?: string;

    constructor(name?: string) {
        super();
        this.name = name;
        this.classe = ClassNameMap.Nameable;
    }
}

export class PersistentDTO {
    public id?: string;	
	public creationDate?: string| any;
    public modificationDate?: string| any;
    public classe?: string;
}

export class ClassNameMap {
    public static User = "User";
    public static Persistent = "Persistent";
    public static Prestation = "Prestation";
    public static PrestationFamily = "PrestationFamily";
    public static Country = "Country";
    public static City = "City";
    public static Address = "Address";
    public static Company = "Company";
    public static Person = "Person";
    public static Party = "Party";
    public static Employer = "Employer";
    public static Patient = "Patient";
    public static MedicalAgent = "MedicalAgent";
    public static BusinessUnit = "BusinessUnit";
    public static ContactPerson = "ContactPerson";
    public static PrestationsToPerformed = "PrestationsToPerformed";
    public static Reception = "Reception";
    public static CareCoverageDoc = "CareCoverageDoc";
    public static Apointment = "Apointment";
    public static MedicalFolder = "MedicalFolder";
    public static Antecedent = "Antecedent";
    public static FRCVTypes = "FRCVTypes";
    public static Diary = "Diary";
    public static Periode = "Periode";
    public static Activity = "Activity";
    public static Document = "Document";
    public static ThirdPartyPayer = "ThirdPartyPayer";
    public static CareCoverage = "CareCoverage";
    public static PrestationCategoryCovered = "PrestationCategoryCovered";
    public static PrestationTypeCovered = "PrestationFamilyCovered";
    public static PrestationCovered = "PrestationCovered";
    public static CoverageDocument = "CoverageDocument";
    public static CareCoverageDocValidation = "CareCoverageDocValidation";
    public static EndOfLifeReason = "EndOfLifeReason";
    public static PrestationAssortment="PrestationAssortment";
    public static TaggedValue = "TaggedValue";
    public static Nameable = "Nameable";
    public static Profession = "Profession";
    public static Religion = "Religion";  
    public static ConsultationSheet = "ConsultationSheet";  
    public static PhysicalExam = "PhysicalExam";  
    public static AntecedentType = "AntecedentType";  
    public static SystemInvestigationType = "SystemInvestigationType";
    public static SystemInvestigation = "SystemInvestigation";
    public static PatientDiagnosis = "PatientDiagnosis";
    public static Prescription = "Prescription";
    public static PrescriptionItem = "PrescriptionItem";
    public static CarePrescription = "CarePrescription";
    public static ExamPrescription = "ExamPrescription";
    public static MedicalPrescription = "MedicalPrescription";
    public static MedicalPrescriptionItem = "MedicalPrescriptionItem";
    public static BasePrescriptionItem = "BasePrescriptionItem";
    public static Drug = "Drug";
    public static Care = "Care";
    public static CareWorkflowStep = "CareWorkflowStep";
    public static CareApointment = "CareApointment";
    public static CareRequest = "CareRequest";
    public static CareExecution = "CareExecution";
    public static Exam = "Exam";
    public static ExamWorkflowStep = "ExamWorkflowStep";
    public static ExamApointment = "ExamApointment";
    public static ExamAssessment = "ExamAssessment";
    public static ExamProcessing = "ExamProcessing";
    public static ExamRequest = "ExamRequest";
    public static ExamResult = "ExamResult";
    public static MedicalInstitutionPartner = "MedicalInstitutionPartner";
    public static InternalExamResult = "InternalExamResult";
    public static ExternalExamResult = "ExternalExamResult";
}