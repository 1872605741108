import { Component, OnInit, Inject, HostListener, Input, Injectable, ViewContainerRef, TemplateRef, ViewChild, ComponentRef, ComponentFactoryResolver, EventEmitter, Type, AfterViewInit, ElementRef, Renderer2, AfterViewChecked } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog, MatDialogConfig, MatDialogContent } from '@angular/material';
import {ENTER} from '@angular/cdk/keycodes';
import { IFormActionBarButton } from '../../buttons-bar/buttons-bar.component';
import {ComponentType} from '@angular/cdk/typings/portal';
/**
 * Component made to render Modal
 */
@Component({
  selector: 'app-modal',
  templateUrl: './modal.component.html',
  styleUrls: ['./modal.component.css']
})
export class ModalComponent implements OnInit , AfterViewInit, AfterViewChecked{

  //The zone where the modal will be rendered
  @ViewChild('modalContainer', { read: ViewContainerRef })
  protected viewContainerRef: ViewContainerRef;

  // Reference on modal component
  protected componentRef: ComponentRef<any>;

  //parameters of buttons bar to render on the bottom of the modal
  protected footerButtonBar: IButtonBarConfig = null;

  // Title of the modal
  protected headerTitle: string;

  // is header close button must be shown?
  protected headerCloseButton: boolean;

  protected dialogConfig: any;

  constructor( private el: ElementRef , private renderer: Renderer2, public dialogRef: MatDialogRef<any>, @Inject(MAT_DIALOG_DATA) public data: IModalData,
                private resolver: ComponentFactoryResolver) {
      console.log({data:data});
      if(data.title) {
        this.headerTitle = data.title;
        this.headerCloseButton = true;
      }
      if(data.footer) {
        this.footerButtonBar = data.footer.buttonBar;
      }
      this.dialogConfig = (data as any).dialogConfig;
      console.log({headerTitle:this.headerTitle, footerButtonBar: this.footerButtonBar});
  }

  ngOnInit() {
   
  }

  ngAfterViewInit() {
    const factory = this.resolver.resolveComponentFactory(this.data.component as Type<IModalComponent | IShowModalComponent>);
    this.componentRef = this.viewContainerRef.createComponent(factory);// Create Modal component
    console.log({componentRef: this.componentRef});
  }

  ngAfterViewChecked() {
    this.setContentMaxHeight();
  }

  // To set the maxHeight of the modal content and make it scrollable
  protected setContentMaxHeight() {
    // Set maximum modal content size
    const dialogContent = (<HTMLElement>this.el.nativeElement).querySelector('.mat-dialog-content');
    
    //console.log("this.dialogContent", dialogContent);
    if(dialogContent && this.dialogConfig && this.dialogConfig.height != null) {
      const modal = this.el.nativeElement.parentElement;   
     // console.log({modal:modal});
      let modalHeight = (modal as any).offsetHeight;
      let maxHeight = (modalHeight - 135) + "px";
      //console.log(" modalHeight ", modalHeight,"maxHeight", maxHeight);
      this.renderer.setStyle(dialogContent,"max-height",maxHeight);
    }
  }

  ngOnDestroy() {// Destroy the modal component when this component is destroy
    if (this.componentRef) {
      this.componentRef.destroy();
    }
  }

  // Catch bouton click event
  public btnBarActionClick(event): void {
    let data = (this.componentRef.instance as any).getData();
    //console.log("componentRef data ", data);
    this.dialogRef.close({value: data, btn: event});
  }

  // Close the modal when the user click on close button
  public onCloseClicked(event): void {
    this.dialogRef.close(null);
  }
  
}

export abstract class IModalComponent {
  public abstract getData();
}

export abstract class IShowModalComponent {
}

/**
 * Service called to render Modal
 */
@Injectable({
  providedIn: 'root'
})
export class ModalComponentService {

  constructor( public dialog: MatDialog){}

  public openModal(component: ComponentType<IModalComponent | IShowModalComponent>,
    title: string, 
    footer: FooterModalConfig,
    config?:MatDialogConfig<any>): MatDialogRef<any>{
    if(!config){ config = {};}
    config['data'] = { component,title, footer, data: config.data , dialogConfigs: config };
    const dialogRef = this.dialog.open(ModalComponent, config);
    return dialogRef;
  }

}

/**
 * Configuration pattern class
 */
export class NkapMatDialogConfig {
  public dialog: MatDialogRef<any>;
  public events: EventEmitter<any>;
  constructor(dialog:MatDialogRef<any> ){
    this.dialog = dialog;
    this.events = new EventEmitter<any>();
  }
}

/**
 * Pattern of data gave to the Modal
 */
export interface IModalData{
  component: ComponentType<IModalComponent>;
  title?: string;
  footer?: FooterModalConfig;
  data?:MatDialogConfig;
}

/**
 * Header data pattern
 */
export interface HeaderModalConfig {
  title?: string;
  showCloseBtn?: boolean;
}

/**
 * Footer data pattern
 */
export interface FooterModalConfig {
  buttonBar: IButtonBarConfig;
}

/**
 * Footer buton configuration pattern
 */
export interface IButtonBarConfig {
  functionality?:string ;
  functionalities?: string[];
  action?: string ;
  buttons?: IFormActionBarButton[]
}