import { Component, OnInit, Input, Output, EventEmitter, ElementRef, SimpleChange, OnDestroy, AfterViewInit, AfterViewChecked, ChangeDetectorRef } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { UserAppService } from 'src/app/services/user-app.service';
import { Subscription } from 'rxjs';
import { NkapKeycloakService } from 'src/app/services/nkap-keycloak.service';

@Component({
  selector: 'app-buttons-bar',
  templateUrl: './buttons-bar.component.html',
  styleUrls: ['./buttons-bar.component.css']
})
export class ButtonsBarComponent implements OnInit, OnDestroy {
  
  
  rulesSubscription: Subscription;

  /**
   * List of actions/buttons gived by the user
   */
  @Input("buttons") actions: IFormActionBarButton[] = [];

  /**
   * This emitter will inform of all btn click
   */
  @Output() action: EventEmitter<Object> = new EventEmitter();
  /**
   * The fonctionality where the buttons bar is used
   */
  @Input() functionality: string;
  /**
   * The fonctionalities where the buttons bar is used
   */
  @Input() functionalities: string[];

  /**
   * The action representing the form on witch the action bar is
   */
  @Input("action") formAction: string;
  /**
   * List of allowed actions
   */
  protected allow_actions: IFormActionBarButton[];

  /**
   * Default constructor
   */
  constructor(private cd: ChangeDetectorRef, private el: ElementRef, public translateService: TranslateService,
    private nkapKeycloakService: NkapKeycloakService,
              private _dataService: UserAppService) {
  }

  initButtonsList() {
    this.buildAllowedButtons();
    this._dataService.userPrivilegesChanged.subscribe( (changed) => {
        this.buildAllowedButtons();
    });

    this.nkapKeycloakService.rulesSubject.subscribe((rules) => {
        this.buildAllowedButtons();
    });
  }

  ngOnInit() {
    this.initButtonsList();
  }

  ngOnDestroy() {
      
  }
  
  ngOnChanges(changes: {[propName: string]: SimpleChange}) {
      if (changes["actions"] || changes["functionality"]) {
         this.buildAllowedButtons();
      }
  }

  protected btnClick(event, action) {
      if ( this.action != null && !action.disabled ) { // we emit the action only if the button is active
          this.action.emit(action);
      }
  }

  /**
   * Method to change the status of buttons
   */
  public setButtonStatus(btn: {id: any, disabled:boolean}) {
      const newButtons: IFormActionBarButton[] = [].concat(this.allow_actions);
      const buttonFound: any = newButtons.find( (button) => button.id === btn.id);
      if (buttonFound) {
          buttonFound.disabled = btn.disabled;
      }
      this.allow_actions = newButtons;
      this.cd.detectChanges();
  }

  /**
   * Method to change the status of buttons
   */
  public setButtonsStatus(statusList: {id: any, disabled:boolean}[]) {
     // console.log(" statusList ", statusList);
      if ( statusList != null) {
          const newButtons: IFormActionBarButton[] = [].concat(this.allow_actions);
          statusList.forEach( (status) => {
            const buttonFound: any = newButtons.find( (button) => button.id === status.id);
            if (buttonFound) {
                  buttonFound.disabled = status.disabled;
              }
          });
          this.allow_actions = newButtons;
          this.cd.detectChanges();
          //console.log("setButtonsStatus ", this.allow_actions);
      }
  }
  
  protected buildAllowedButtons() {
      this.allow_actions = [];
      if(this.actions){
        this.allow_actions = this.actions.filter(b => {
            b = this.allowAcessToFunctionality(b);
            return b !== null;
        });
      }
      this.cd.detectChanges();
  }

  public allowAcessToFunctionality(button: IFormActionBarButton) {
      if (!button.functionalities || button.functionalities.length === 0) return null;
  
      //inspect map role use fonctionalities
      const functionalities_allowed = button.functionalities.filter(f => {
        return (this._dataService.hasAccess(f) == true)
      });

      if (functionalities_allowed.length === 0) return null;
      return button;
  }

  getToolTip(action: IFormActionBarButton): string {
    if(action.toolTip) return action.toolTip;
    return action.text;
  }

  gematclassCsssButton(action: IFormActionBarButton): string {
    if(action.cssClass) return action.cssClass;
    return 'mat-button';
  }
  
}

export interface IFormActionBarButton {
  id: string;
  name?: string;
  value?: string;
  number?: number;
  text?: string;
  cssClass?: string;
  disabled?: boolean;
  functionalities: string[];
  icon?: {type: string, color?: string};
  data?: any;
  toolTip?: string;
}

export class FormAction {
  public static CREATE = "CREATE";
  public static VIEW = "VIEW";
  public static DELETE = "DELETE";
  public static UPDATE = "UPDATE";
  public static VALIDATE = "UPDATE";
  public static SEND_MAIL = "SEND_MAIL";
  public static CLOSE = "CLOSE";
  public static CANCEL = "CANCEL";
  public static PRINT = "PRINT";
}
