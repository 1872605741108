import { Persistent, ClassNameMap, TaggedValue } from '../persistent';
import { MedicalAgentType } from '../organisation/medical-agent';
import { BusinessUnit } from '../organisation/business-unit';

export class Prestation extends Persistent {
    public code?: string;
    public name?: string;
    public family?: PrestationFamily;
    public price?: {value?: number, currency?: string};
    public priceRetrieved?; boolean;
    public executionDuration?: string;
    public abilitedAgentType?: string | MedicalAgentType;
    public abilitedService?: BusinessUnit;
    public assortments?: PrestationAssortment[];

    constructor(code?: string,name?: string){
        super();
        this.classe = ClassNameMap.Prestation;
        this.code = code? code : "";
        this.name = name? name : "";
        this.family = null;
        this.assortments = [];
    }

    public static displayPrestation(prestation) {
        return prestation ? `${prestation.code} ${prestation.name}` : '';
    }
    
}

export enum PrestationGroup {
    DRUGS,
    EXAMS,
    CARE,
    CONSULTATION
}
export class PrestationAssortment extends Persistent{
    public assortedPrestation?: Prestation;
    public prestation?: Prestation;
    public required?: boolean;
    public quantity?: number | string;

    constructor(prestation?: Prestation , quantity?: number | string){
        super();
        this.classe = ClassNameMap.PrestationAssortment;
        this.prestation = prestation;
        this.quantity = quantity;
    }
}

export class PrestationFamily extends Persistent{
    public family?: PrestationFamily;
    public code?: string;
    public name?: string;
    public abilitedAgentType?: string | MedicalAgentType;
    public abilitedService?: BusinessUnit;

    constructor(){
        super();
        this.code = "";
        this.name = "";
        this.classe = ClassNameMap.PrestationFamily;
    }
}

export class PrestationParameters {
    public mainConsultationFamily: PrestationFamily;
    public mainExamsFamily: PrestationFamily;
    public mainCareFamily: PrestationFamily;
	public mainDrugsFamily: PrestationFamily;
    public mostAskConsultations: Prestation[];
    public mostAskExams: Prestation[];

    constructor() {

    }
}