import { Component, OnInit, Input, ViewChild } from '@angular/core';
import { Patient } from 'src/app/model/organisation/patient';
import { Person } from 'src/app/model/organisation/person';
import { PatientIdentificationComponent } from '../../patient-identification/patient-identification.component';
import { AdresseComponent } from '../../adresse/adresse.component';

@Component({
  selector: 'app-mandatory-information',
  templateUrl: './mandatory-information.component.html',
  styleUrls: ['./mandatory-information.component.css']
})
export class MandatoryInformationComponent implements OnInit {

  @Input() patientInput: Patient;

  @Input() patientAddressInput: Person;

  @ViewChild("patientIdentity")
  patientIdentityCmp: PatientIdentificationComponent;

  @ViewChild("patientAddress")
  patientAddressCmp: AdresseComponent;

  constructor() { }

  getPatientIdentityCmp() {
    return this.patientIdentityCmp;
  }

  getPatientAddressCmp(){
    return this.patientAddressCmp;
  }

  ngOnInit() {
  }

}
