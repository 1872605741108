import { ClassNameMap } from 'src/app/model/persistent';
import { ExamApointment } from './../../../model/exam/exam';
import { Component, OnInit,ViewChild, Inject, ChangeDetectorRef, AfterViewInit, ViewEncapsulation, Output, EventEmitter } from '@angular/core';
import { FullCalendarComponent } from '@fullcalendar/angular';
import { EventInput, OptionsInput } from '@fullcalendar/core';
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGrigPlugin from '@fullcalendar/timegrid';
import interactionPlugin from '@fullcalendar/interaction'; // for dateClick
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { DataService } from './data-service';
import { Patient } from 'src/app/model/organisation/patient';
import { Doctor } from 'src/app/model/organisation/doctor';
import { NkapHttpService } from 'src/app/services/nkap-http.service';
import { Apointment } from 'src/app/model/admission/apointment';
import { MedicalAgent } from 'src/app/model/organisation/medical-agent';
import { Diary, DayOfWeek, TimeSlot } from 'src/app/model/organisation/diary';
import { Subject, Subscription, from } from 'rxjs';
import { map } from 'rxjs/operators';
import { FormGroup, FormBuilder, Validators, FormControl } from '@angular/forms';
import { IFormActionBarButton, FormAction } from '../../buttons-bar/buttons-bar.component';
import { formatDate, Time} from '@angular/common';
import { NkapNotificationService, NotificationType } from 'src/app/services/nkap-notification.service';
import { TranslatePipe, TranslateService } from '@ngx-translate/core';
import { SelectionModel } from '@angular/cdk/collections';
import { ActivatedRoute, Router } from '@angular/router';
import { SearchCriteria } from 'src/app/model/data-page';
import { Reception } from 'src/app/model/admission/reception';
import { UserAppService } from 'src/app/services/user-app.service';
import { analyzeAndValidateNgModules } from '@angular/compiler';
import { DateInput } from '@fullcalendar/core/datelib/env';
import Tooltip from 'tooltip.js';
import { BusinessUnit } from 'src/app/model/organisation/business-unit';
import * as $ from 'jquery';
import * as moment from 'moment';
import { Person } from 'src/app/model/organisation/person';
import { Funtions } from 'src/app/model/security/funtionalities';
import { ApointmentEditModalAddComponent } from './apointment-edit-modal-add/apointment-edit-modal-add.component';
import { ApointmentEditModalEditComponent } from './apointment-edit-modal-edit/apointment-edit-modal-edit.component';
import { ModalComponentService } from '../../base/modal/modal.component';
import { NkapFullCalendarComponent } from '../../nkap-full-calendar/nkap-full-calendar.component';
import { Exam } from 'src/app/model/exam/exam';
import { Care, CareApointment } from 'src/app/model/care/care';

@Component({
  selector: 'app-apointment-edit',
  templateUrl: './apointment-edit.component.html',
  styleUrls: ['./apointment-edit.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class ApointmentEditComponent implements OnInit,AfterViewInit{

  /**
   * The #Calendar component in the template
   */
  // @ViewChild('calendar',null)
  // calendarComponent: FullCalendarComponent;

  @ViewChild('nkapFullCalendar')
  nkapFullCalendarCmp: NkapFullCalendarComponent;
  
  /**
   * Contains the value of the description of current appointment
   */
  eventTitle:string;
  /**
   * Contains the value of the care protocol  of current appointment when loadFromCare is true
   */
  careProtocol:string;
  /**
   * Contains all the events displayed on the calendar
   */
  // calendarEvents: EventInput[] = new Array();
  currentEvents:EventInput[]=new Array();
  /**
   * Contains all the text of the buttons used in the calendar
   */
  buttonText:any;
   /**
   * Utiliser pour la configuration du premier jour de la semaine
   */
  firstDay = 1;
  /**
   * Utiliser pour la configuration du dernier jour de la semaine
   */
  lastDay = 0;
   /**
   * Utiliser pour la configuration du temps minimal
   */
  minTime = '07:00';
  /**
    * Utiliser pour la configuration du temps maximal
  */
   maxTime = '20:00';
  /**
   * Determines whether an event is editable 
   */
  editable=true;
  /**
   * Determines if he is allowed to select periods of time that are occupied by events.
   */
  selectOverlap=true;
  /**
   * Determines if events being dragged and resized are allowed to overlap each other.
   */
  eventOverlap=true;
  /**
   * Determines whether events can overlap visually.
   */
  slotEventOverlap=true;
  /**
   * Determines whether the calendar zones are selectable
   */
  selectable=true;

  allDaySlot= false;
  selectMirror= true;
  /**
   * Parameter for the calendar language
   */
  options: OptionsInput ;
  /**
   * API FullCalendar
   */
  calendarApi:any;

  translate: TranslatePipe;
  // patientList: Patient[];
  // doctorList: MedicalAgent[];
  // serviceList: BusinessUnit[];
  service=false;
  diary:Diary= new Diary();
  diarysEvents: any[] = [];
  diarysControl: any[] = [];
  apointmentEvents: any[] = [];
  apointment:Apointment= new Apointment();
  currentApointment:Apointment= new Apointment();
  apointmentNoDoctor:Apointment= new Apointment();
  apointmentControl=false;
  apointments:Apointment[];
  currentDoctor: MedicalAgent=new MedicalAgent();
  currentPatient:Patient= new Patient();
  currentService:BusinessUnit= new BusinessUnit();
  // doctorControl = new FormControl();
  // patientControl = new FormControl();
  // serviceControl = new FormControl();
  moreCriteriaShow = false;
  moreCriteriaShow2 = false
  criteria: any | SearchCriteria;

  /** 
   * Reception construct in reception/home 
   */
  reception: Reception;
  loadFromReception: boolean = false;

  exam: Exam;
  loadFromExam: boolean = false;

  care: Care;
  loadFromCare: boolean = false;

  afterSaveRoute: any;
  
  constructor(private route: ActivatedRoute,public dialog: MatDialog,public dialogEdit: MatDialog ,
    public dataService: DataService, private userService: UserAppService,
    public modalService: ModalComponentService,protected router: Router,
    private httpService: NkapHttpService<any>,private msgService: NkapNotificationService,private _ref: ChangeDetectorRef,
     protected translateService: TranslateService){

      this.nkapFullCalendarCmp= new NkapFullCalendarComponent(translateService);
      this.criteria= new SearchCriteria();
      this.translate = new TranslatePipe(translateService,_ref);
  }

  /**
   * Determines whether the calendar is visible
   */
  calendarVisible = true;
  /**
   * Definition of plugin used in the calendar
   */
  calendarPlugins = [dayGridPlugin, timeGrigPlugin, interactionPlugin];
  /**
   * Determines whether weekend days are enabled
   */
  calendarWeekends = true;

  header={
    left: 'prev,next, today',
    center: 'title',
    right: 'dayGridMonth,timeGridWeek,timeGridDay,listWeek'
  };

  ngOnInit(){
      this.route.paramMap.subscribe(params => {
        let data: {id?: string, medicalFolderId?: string,
           patientId?: string, examId?: string, careId?: string} = (params as any).params;
        if (data.id != null) {
          this.httpService.findById(data.id, 'admission/apointment').subscribe( (apointment:Apointment)=>{
            this.setObjectInView(apointment);
          }, (errors) => {
            this.msgService.log("apointment.edit.get.data.error", NotificationType.ERROR);
          });
        }
        if (data.patientId != null) {
          this.httpService.findById(data.patientId, 'organisation/patient').subscribe( (patient:Patient)=>{
            this.currentPatient = patient;
            this.selectPatient();
          }, (errors) => {
            this.msgService.log("apointment.edit.get.data.error", NotificationType.ERROR);
          });
        }
        // Build data when an examId is gived in parameter
        if(data.examId != null) {
          if(data.id != null){
            this.afterSaveRoute = ["./exam/list", {search: true}];
          } else {
            this.loadFromExam = true;
            this.exam = this.userService.getExam();
            this.afterSaveRoute = ["./exam/list", {search: true}];
            this.userService.setExam(null);
            if(this.exam != null) {
              let patient = this.exam.patientFolder && this.exam.patientFolder.patient ? this.exam.patientFolder.patient : null;
              if(!patient) {
                patient = new Patient((this.exam.patientFolder as any).patientFullname);
                patient.id = (this.exam.patientFolder as any).patientId;
              }
              //console.log("examId ", patient);
              this.nkapFullCalendarCmp.patientControl.setValue(patient);
              this.currentPatient = patient;
              this.selectPatient();
              if(this.exam.examPrestation){
                this.eventTitle = this.exam.examPrestation.name;
              }
            }
          }
        }
        // Build data when an careId is gived in parameter
        if(data.careId != null) {
          if(data.id != null){
            this.afterSaveRoute = ["./care/list", {search: true}];
            this.httpService.findById(data.careId, "care").subscribe ( (result) => {
              //this.care = result;
              if(result.currentStep && (result.currentStep as any).careProtocol){
                this.careProtocol = (result.currentStep as any).careProtocol;
              }else{
                this.careProtocol = "";
              }
            });
          } else {
            this.loadFromCare = true;
            this.care = this.userService.getCare();
            this.afterSaveRoute = ["./care/list", {search: true}];
            this.userService.setCare(null);
            if(this.care != null) {
              let patient = this.care.patientFolder && this.care.patientFolder.patient ? this.care.patientFolder.patient : null;
              if(!patient) {
                patient = new Patient((this.care.patientFolder as any).patientFullname);
                patient.id = (this.care.patientFolder as any).patientId;
              }
              this.nkapFullCalendarCmp.patientControl.setValue(patient);
              this.currentPatient = patient;
              this.selectPatient();
              if(this.care.carePrestation){
                this.eventTitle = this.care.carePrestation.name;
              }
              if(this.care.currentStep && (this.care.currentStep as any).careProtocol){
                this.careProtocol = (this.care.currentStep as any).careProtocol;
              }
            }
          }
        }
      });

      this.nkapFullCalendarCmp.doctorControl.valueChanges.subscribe(value => {
        if (!value || typeof value === 'string') {
          this.getDoctor(value);
        }
        if (value && value.id !== undefined) {
            this.currentDoctor = value;
            this.selectDoctor();
        }
      });

      this.nkapFullCalendarCmp.patientControl.valueChanges.subscribe(value => {
        if (!value || typeof value === 'string') {
          this.getPatient(value);
        }
        if (value && value.id !== undefined) {
            this.currentPatient = value;
            this.selectPatient();
        }
      });

      this.nkapFullCalendarCmp.serviceControl.valueChanges.subscribe(value => {
        if (!value || typeof value === 'string') {
          this.getService(value);
        }
        if (value && value.id !== undefined) {
            this.currentService = value;
            this.selectService();
        }
      });
      
    /** 
     * Default translation at FullCalendar interface 
     **/
    // this.onTranslateCalendar();
    
    /**
     * FullCalendar translation when changing the language 
     **/
    // this.translateService.onLangChange.subscribe(result=>{
    //     this.onTranslateCalendar();
    // });

    /**
     * Load data from reception
     **/
    console.log(this.reception);
    if(this.loadFromReception == true){
      this.reception = this.userService.getReception();
      this.userService.setReception(null);
      if(this.reception) {
        this.nkapFullCalendarCmp.patientControl.patchValue(this.reception.patient);
        if(this.reception.prestationsToPerformed && this.reception.prestationsToPerformed.length == 1){
          this.nkapFullCalendarCmp.doctorControl.patchValue(this.reception.prestationsToPerformed[0].agent);        
        }
      }
    }

  }

  ngAfterViewInit(){

    /**
     * Trigger when selecting a time zone and control if the selected zone is authorized
     **/
    if(this.nkapFullCalendarCmp.calendarComponent){
      this.nkapFullCalendarCmp.calendarComponent.select.subscribe(event => {
        if(this.currentDoctor.id && this.currentPatient.id)
            this.eventAddOrUpdateControl(event);
        else
          this.msgService.log("apointment.add.select.data.error", NotificationType.ERROR);
        
        });
    }
    
  }

  /**
   * FullCalendar translation 
   **/
  // onTranslateCalendar(){
  //   this.options= {
  //     locale: `${this.translateService.currentLang}`
  //   };

  //   this.buttonText={
  //     today: this.translateService.instant('apointment.edit.buttonText.today'),
  //     dayGridMonth: this.translateService.instant('apointment.edit.buttonText.dayGridMonth'),
  //     timeGridWeek: this.translateService.instant('apointment.edit.buttonText.timeGridWeek'),
  //     timeGridDay: this.translateService.instant('apointment.edit.buttonText.timeGridDay')
  //   }
  // }
  

  // doctorControl(value){
  //   console.log(value);
  //   if (!value || typeof value === 'string') {
  //     this.getDoctor(value);
  //   }
  //   if (value && value.id !== undefined) {
  //       this.currentDoctor = value;
  //       this.selectDoctor();
  //   }
  // };

  // patientControl(value) {
  //   console.log(value);
  //   if (!value || typeof value === 'string') {
  //     this.getPatient(value);
  //   }
  //   if (value && value.id !== undefined) {
  //       this.currentPatient = value;
  //       this.selectPatient();
  //   }
  // };

  // serviceControl(value){
  //   console.log(value);
  //   if (!value || typeof value === 'string') {
  //     this.getService(value);
  //   }
  //   if (value && value.id !== undefined) {
  //       this.currentService = value;
  //       this.selectService();
  //   }
  // };

  getDoctor(value) {
    this.httpService.search({value: value}, 'organisation/medical-agent').subscribe ( (result) => {
      this.nkapFullCalendarCmp.doctorList = result ? result.datas : [];
    });
  }

  getPatient(value) {
    this.httpService.search({value:value}, 'organisation/patient').subscribe ( (result) => {
      this.nkapFullCalendarCmp.patientList = result ? result.datas : [];
    });
  }
  
  getService(value) {
    this.httpService.search({value:value}, 'organisation/business-unit').subscribe ( (result) => {
      this.nkapFullCalendarCmp.serviceList = result ? result.datas : [];
    });
  }

  /**
   * Load appointments and diarys from data base when selecting a doctor 
   **/
  selectDoctor(){
    // this.calendarEvents= [];
    this.nkapFullCalendarCmp.calendarEvents=[];
    this.currentEvents= [];

    if(this.apointmentNoDoctor.id){
      this.httpService.save(this.apointmentNoDoctor, 'admission/apointment',true).subscribe( (result) => {
        this.msgService.log("apointment.edit.save.succed", NotificationType.SUCCESS);
        console.log(result);
        this.currentApointment=this.apointmentNoDoctor;
        this.apointmentNoDoctor=new Apointment();
      }, (errors) => {
        this.msgService.log("apointment.edit.save.error", NotificationType.ERROR);
      });
    }

    if(this.currentDoctor.id){
      this.criteria.medicalAgentId=this.currentDoctor.id;
  
    /** 
     * Get Diary from data base 
     **/
    this.httpService.search({medicalAgentId: this.currentDoctor.id},`${'organisation/diary'}`).subscribe((result) => {
      // console.log(result);
      console.log(result.datas);
      let eventDisplay:any=new Array();
      if (result && result.datas.length > 0) {
      let  results: Diary = result.datas[0];
    const recurringEvents= new Array();
    from(results.periods).pipe(
      map(elt => {
        let dateStart,dateEnd,daysOfWeek,startTime,endTime,startRecur,endRecur;
        let backgroundColor, borderColor;

        if(elt.day){
          daysOfWeek= [parseInt(DayOfWeek[elt.day])],
          startTime=`${elt.timeSlot.startTime}`,
          endTime= `${elt.timeSlot.endTime}`,
          startRecur= elt.validityStartDate,
          endRecur= elt.validityEndDate,
          dateStart= this.getGoodDate(`${elt.validityStartDate}`, `${elt.timeSlot.startTime}`);
          dateEnd= this.getGoodDate(`${elt.validityEndDate}`, `${elt.timeSlot.endTime}`);
        }else{
          dateStart = this.getGoodDate(`${elt.validityStartDate}`, `${elt.timeSlot.startTime}`);
          dateEnd = this.getGoodDate(`${elt.validityEndDate}`, `${elt.timeSlot.endTime}`);
        }

        if(elt.isProgramable){
          backgroundColor= '#ECF40F';
          borderColor= '#ECF40F';
        }else{
          backgroundColor= '#EC5F41';
          borderColor= '#EC5F41';
        }
        
         eventDisplay = {
          id:elt.id,
          hasActivity: elt.activity != null,
          title: elt.activity != null ? elt.activity.name : this.translateService.instant(elt.isProgramable === true? "planning.period.is.programmable":"planning.period.is.not.programmable"),
          // tslint:disable-next-line:radix
          daysOfWeek: daysOfWeek,
          startTime:startTime,
          endTime: endTime,
          startRecur:startRecur,
          endRecur: endRecur,
          start: dateStart,
          end: dateEnd,
          type: !elt.day ? 'simpleEvent' : 'recursiveEvent',
          symbol: Symbol(),
          isProgramable: elt.isProgramable,
          backgroundColor: backgroundColor,
          borderColor:'#EC5F41',
          textColor: borderColor,
          // editable:false,
          rendering: 'background'

        };
        return eventDisplay;
      }),
    ).subscribe(
      val => {recurringEvents.push(val);
       },
      (error => {console.log(error); }),
      () => {this.currentEvents=this.currentEvents.concat(recurringEvents); 
      });
      }

    /** 
    * Get Appointment from data base 
    **/
    this.httpService.search({medicalAgentId: this.currentDoctor.id}, 'admission/apointment').subscribe((results) => {
      //  console.log(results.datas);
      let recurringEvents=new Array();
      this.diarysControl=this.currentEvents;
      if(results && results.datas){
      from(results.datas).pipe(
        map(elt => {
          let eventDisplay:any;
          let dateStart,dateEnd;
         
          if(elt.timeSlot && elt.timeSlot.endTime && elt.timeSlot.startTime){
            let timeStart:string=elt.timeSlot.startTime;
            let timeEnd:string=elt.timeSlot.endTime;
            dateStart=`${(elt.date.toString()).split('T')[0]}T${timeStart}`;
            dateEnd=`${(elt.date.toString()).split('T')[0]}T${timeEnd}`;
          }else if(elt.timeSlot && !elt.timeSlot.endTime){
            let hoursStr,minutesStart,secondsStart,hoursEnd,minutesEnd,secondsEnd,mSrt,hSrt,timeEnd;
            hoursStr=elt.timeSlot.startTime.toString().split(':')[0];
            minutesStart=elt.timeSlot.startTime.toString().split(':')[1];
            secondsStart=elt.timeSlot.startTime.toString().split(':')[2];
            mSrt=+minutesStart;
            hSrt=+hoursStr;
            hoursEnd=hoursStr;
            minutesEnd=minutesStart;
            secondsEnd=secondsStart;
            // if(minutesStart==='00'){
            //   minutesEnd=mSrt+30;
            // }else{
              hoursEnd=`${hSrt+1}`;
            // }
            
            if(hoursEnd.length===1)
            hoursEnd= '0'+hoursEnd;
      
            timeEnd=`${hoursEnd}:${minutesEnd}:${secondsEnd}`;
            elt.timeSlot.endTime=timeEnd;
            console.log(timeEnd);
            console.log(elt);
            this.httpService.save(elt, 'admission/apointment',true).subscribe( (result) => {
              // this.msgService.log("apointment.edit.save.succed", NotificationType.SUCCESS);
              let timeStart:string=elt.timeSlot.startTime;
              let timeEnd:string=elt.timeSlot.endTime;
    
              dateStart=`${(elt.date.toString()).split('T')[0]}T${timeStart}`;
              dateEnd=`${(elt.date.toString()).split('T')[0]}T${timeEnd}`;
             
            }, (errors) => {
              this.msgService.log("apointment.edit.save.error", NotificationType.ERROR);
            });  
           
          }
  
          if(this.currentApointment && this.currentApointment.id===elt.id){
            // console.log(this.currentApointment)
              eventDisplay = {
                id:elt.id,
                title: elt.description,
                start: dateStart,
                end: dateEnd,
                patient:elt.patient,
                doctor:elt.personToMeet,
                unit:elt.unit,
                textColor:'#FFFFFF',
                backgroundColor:'#8E11F5',
                borderColor:'#8E11F5',
                // editable:true,
                // timeSlot:true,
              };
              // this.currentApointment=new Apointment()
          }else{
            eventDisplay = {
              id:elt.id,
              title: elt.description,
              start: dateStart,
              end: dateEnd,
              patient:elt.patient,
              doctor:elt.personToMeet,
              unit:elt.unit,
              textColor:'#FFFFFF',
              // editable:true,
              // timeSlot:true,
            };
  
            // eventDisplay= this.appointmentControl(eventDisplay);
            // console.log(eventDisplay)
          }  
        
        return eventDisplay;
        }),
      ).subscribe(
        val => {
            recurringEvents.push(val);
         },
        (error => {console.log(error); }),
        () => {
          if(this.apointmentNoDoctor.id){
            console.log("No Doctor")
            if(this.apointmentNoDoctor.timeSlot){
              let timeStart=this.apointmentNoDoctor.timeSlot.startTime;
              let timeEnd=this.apointmentNoDoctor.timeSlot.endTime;
              let dateStart=`${(this.apointmentNoDoctor.date.toString()).split('T')[0]}T${timeStart}`;
              let dateEnd=`${(this.apointmentNoDoctor.date.toString()).split('T')[0]}T${timeEnd}`;
  
              let eventDisplay = {
                id:this.apointmentNoDoctor.id,
                title: this.apointmentNoDoctor.description,
                start: dateStart,
                end: dateEnd,
                patient:this.apointmentNoDoctor.patient,
                doctor:this.apointmentNoDoctor.personToMeet,
                unit:this.apointmentNoDoctor.unit,
                textColor:'#FFFFFF',
                backgroundColor:'#8E11F5',
                borderColor:'#8E11F5',
               
              };
              recurringEvents.push(eventDisplay);
            }
          }
          this.currentEvents=this.currentEvents.concat(recurringEvents);
         });
        }

      this.nkapFullCalendarCmp.calendarEvents=this.nkapFullCalendarCmp.calendarEvents.concat(this.currentEvents);
      console.log(this.nkapFullCalendarCmp.calendarEvents);

      // this.apointmentControl=true;
      // console.log(this.apointmentEvents);
      // if(this.apointmentEvents.length>0){
      //   this.apointmentEvents.forEach(val=>{
      //     apointEvents.push( this.appointmentControl(val))
      //   })
      //   console.log(apointEvents);
      // }
     
      // this.nkapFullCalendarCmp.calendarEvents=this.calendarEvents;
    },
    (error) => {console.log(error); });
    },
    (error) => {console.log(error); });
    }
  }

  appointmentControl(apointment: any){
    // console.log(apointment);
    let day=false,edit=true;
    // let isProgramable=false;
    // console.log(this.diarysEvents)
    from(this.diarysEvents).pipe(
      map(currentEvent=>{
        return currentEvent;
      })
    ).subscribe(val=>{

    if(val.extendedProps.isProgramable===false){
      // isProgramable=false;
      // console.log(val);
      let start=val.start;
      let end=val.end;
      let dayStartEv,dayEndEv;
      
      let dayStartBg=start.getDay()
      let dayEndBg=end.getDay()
      
      dayStartEv=apointment.start.getDay();
      dayEndEv=apointment.end.getDay();
      
      // console.log(dayStartBg)
      // console.log(dayEndBg)

      // console.log(dayStartEv)
      // console.log(dayEndEv)

      if(dayStartBg===dayStartEv && dayEndBg===dayEndEv)
      day=true;
    
      
      if((apointment.start>=start && apointment.end<=end) || (apointment.start<start && apointment.end>start) 
      || (apointment.start<end && apointment.end>end))
      edit=false;
      
      // console.log(day)
      // console.log(edit)

      if(day===false || edit===true)
      day=false,edit=true;
    }

    }, (err) => {
      console.log(err);
    },
    () => {

      if(day===true && edit===false){
        // apointment.backgroundColor='#F40F16';
        // apointment.borderColor='#F40F16';
        // console.log(apointment);
        let index;
        for(let i=0;i<this.currentEvents.length;i++){
          if(this.currentEvents[i].id==apointment.id)
          index=i;
        }
        // if(this.currentEvents[index] && this.currentEvents[index].backgroundColor && this.currentEvents[index].borderColor){
          this.currentEvents[index].backgroundColor='#F40F16';
          this.currentEvents[index].borderColor='#F40F16';
          // console.log(this.currentEvents)
          // this.nkapFullCalendarCmp.calendarEvents=[];
        // }
        this.apointmentControl=true;
        this.msgService.log("apointment.position.error", NotificationType.ERROR);
      }
      // return apointment;
    });
    // return apointment;
  }

  selectPatient(){
    console.log( this.currentPatient);
  }

  /**
   * Trigger each time you select a service and update the service of the current appointment
   **/
  selectService(){
    if((this.currentApointment.id || this.apointment.id || this.apointmentNoDoctor.id)&& this.service===false){
      if(this.apointment.id)
      this.currentApointment=this.apointment;

      if(this.apointmentNoDoctor.id)
      this.currentApointment=this.apointmentNoDoctor;
     
      this.currentApointment.unit=this.currentService;
      this.httpService.save(this.currentApointment, 'admission/apointment',true).subscribe( (result) => {
        this.msgService.log("apointment.edit.save.succed", NotificationType.SUCCESS);
      }, (errors) => {
        this.msgService.log("apointment.edit.save.error", NotificationType.ERROR);
      });  
    }
    
    if(this.service===true)
      this.service=false;
  }

  /**
   * Formatting schedule dates from the calendar to be saved in Database
   **/
  getGoodDate(date: string, time: string): Date {
    if (date) {
      const dataArray = date.split('-');
      const timeArray = time.split(':');
      const year = parseInt(dataArray[0]);
      const month = parseInt(dataArray[1]);
      const day = parseInt(dataArray[2]);
      return new Date(year, month, day, parseInt(timeArray[0]), parseInt(timeArray[1]), timeArray[2] ? parseInt(timeArray[2]) : 0);
    }
  }

  // displayPersonFn(agent?: Person): string  {
  //   return agent ? `${agent.fullname}` : '';
  // }

  // displayService(service?: BusinessUnit): string {
  //   return service ? `${service.name}` : '';
  // }
  
/**
 * Add new appointment
 **/
  onEventAdd(event) {
    // console.log(event)
      if(this.currentPatient.id != null && (this.currentDoctor.id != null || this.currentService.id != null) ){
        if(this.apointment.id){
          this.onEventEdit(event);
        }else{
          const dialogRef = this.modalService.openModal(ApointmentEditModalAddComponent,
            'apointment.add.title.label',undefined,{
              width: '65%',
              position:{left:"10%", right:"10%"},
            disableClose: true,
            data: {title : 'apointment.edit.title.label', message:'apointment.edit.description.label',event: this.eventTitle,  
                    careProtocol: this.careProtocol, careMessage:'apointment.care.protocol.label',
                    careId: this.care? this.care.id : undefined}
          });
          dialogRef.afterClosed().subscribe(result => {
            console.log("afterClosed", result);
          if(result != null && result != undefined){
              this.eventTitle = result.event;
              this.careProtocol = result.careProtocol;

              let timeSlot: TimeSlot=new TimeSlot();    
              timeSlot=this.formatTimeSlot(event);
              this.apointment.description=this.eventTitle;
              let dateStart= formatDate(event.start,'yyyy-MM-dd','en');
              console.log(dateStart);
              this.apointment.date=new Date(dateStart);
              console.log(this.apointment.date);

              this.apointment.timeSlot=timeSlot;
              this.apointment.personToMeet=this.currentDoctor;
              this.apointment.patient=this.currentPatient;
              if(this.currentService.id){
                this.apointment.unit=this.currentService;
              }
              if(this.loadFromExam) {
                this.saveExamApointment();
              } else if(this.loadFromCare) {
                this.saveCareApointment();
              } else {
                this.httpService.save(this.apointment, 'admission/apointment',true).subscribe( (result) => {
                    this.msgService.log("apointment.add.save.succed", NotificationType.SUCCESS);
                    console.log({result});
                    this.currentApointment=result;
                    
                    console.log(this.currentPatient)
                    this.selectDoctor();
                }, (errors) => {
                    this.msgService.log("apointment.add.save.error", NotificationType.ERROR);
                });
              }
            this.apointment=new Apointment();
        }
        this.eventTitle='';
        this.dataService.dataAnnuler=false;
      });
    }
    }else{
      this.msgService.log("apointment.add.select.data.error", NotificationType.ERROR);
    }
  }

  saveExamApointment() {
    if(this.exam && this.exam.id){
      let apointmentStep = new ExamApointment(null, this.apointment);
      apointmentStep.user = this.userService.getUser();
      apointmentStep.apointment.patient.employer = null;
      this.httpService.post(apointmentStep, `exam/apointment/save/${this.exam.id}`,true).subscribe( (result) => {
          this.msgService.log("apointment.add.save.succed", NotificationType.SUCCESS);
          console.log({result});
          this.currentApointment=result.apointment;
          
          console.log(this.currentPatient)
          this.selectDoctor();

          // we set curent exam to null, 
          this.exam = null;
          this.loadFromExam = false;
          if(this.afterSaveRoute != null) {
            this.router.navigate(this.afterSaveRoute );
          }
          
      }, (errors) => {
          this.msgService.log("apointment.add.save.error", NotificationType.ERROR);
      });
    }
  }

  saveCareApointment() {
    if(this.care && this.care.id){
      let apointmentStep = new CareApointment(null, this.apointment);
      apointmentStep.user = this.userService.getUser();
      apointmentStep.apointment.patient.employer = null;
      apointmentStep.careProtocol = this.careProtocol;
      this.httpService.post(apointmentStep, `care/apointment/save/${this.care.id}`,true).subscribe( (result) => {
          this.msgService.log("apointment.add.save.succed", NotificationType.SUCCESS);
          console.log({result});
          this.currentApointment=result.apointment;
          
          console.log(this.currentPatient)
          this.selectDoctor();

          // we set curent exam to null, 
          this.care = null;
          this.loadFromCare = false;
          if(this.afterSaveRoute != null) {
            this.router.navigate(this.afterSaveRoute );
          }
          
      }, (errors) => {
          this.msgService.log("apointment.add.save.error", NotificationType.ERROR);
      });
    }
  }

/** 
 * Trigger whenever there is a rendering on the calendar
 **/
  eventRender(info){
    console.log("Render");
    // console.log(info);
    this.diarysEvents=[];
    this.diarysEvents= this.nkapFullCalendarCmp.calendarComponent.getApi().getEvents().filter(ev=> ev.rendering==='background');
    this.apointmentEvents= this.nkapFullCalendarCmp.calendarComponent.getApi().getEvents().filter(ev=> ev.rendering!=='background');
    
    console.log(this.apointmentEvents);
    if(this.apointmentEvents.length>0){
      this.apointmentEvents.forEach(val=>{
      this.appointmentControl(val);
      })
    }

    if(this.apointmentControl){
    this.nkapFullCalendarCmp.calendarComponent.getApi().getEvents().forEach(el => el.remove);
    this.nkapFullCalendarCmp.calendarEvents= [];
    // this.nkapFullCalendarCmp.calendarEvents.push(this.currentEvents);
    // this.nkapFullCalendarCmp.calendarComponent.getApi().refetchEvents();
    //  this.nkapFullCalendarCmp.calendarEvents.push(this.currentEvents);
    this.nkapFullCalendarCmp.calendarEvents=this.nkapFullCalendarCmp.calendarEvents.concat(this.currentEvents);
    // this._ref.detectChanges();
    console.log(this.nkapFullCalendarCmp.calendarEvents);
    this.apointmentControl=false;
  }
    
    /**
     * Put a description on each background planning event
     **/
      if (info.event.rendering == 'background') {
       const descriptionDiv=document.createElement('div')
       descriptionDiv.className="t-overflow";
       descriptionDiv.innerHTML=info.event.title;
        info.el.append(descriptionDiv);
      }
  }

  /**
   * Trigger when the mouse cursor hovers over an event
   **/
  mouseEnter(info){
    /**
      * Tooltip for event of FullCalendar
      **/
    var tooltipDiary=info.event.title;
    let patient="";
    if(info.event.extendedProps.patient && info.event.extendedProps.patient.id)
    patient=info.event.extendedProps.patient.fullname;

     let description=`${info.event.title} - ${patient}`

    if(info.event.rendering==="background"){
      $(info.el).attr({"title": tooltipDiary})
    }
    else{
      $(info.el).attr({
        "title": description,  
      })
    }
    
  }

  mouseLeave(info){
  }

  /**
   * Control the movement of an appointment to see if it is moved to a valid area of ​​the calendar
   **/
  eventAddOrUpdateControl(info){
   
    let day=false,edit=true;
    let day2=false,edit2=false;
    let isProgramable=false;
    
    from(this.apointmentEvents).pipe(
      map(currentEvent=>{
        return currentEvent;
      })
    ).subscribe(val=>{
      
      if(info.event){
        if(val.start && val.end && (val.id !== info.event.id)){
          const start=val.start;
          const end=val.end;
          const dayStartBg=start.getDay()
          const dayEndBg=end.getDay()
          const dayStartEv=info.event.start.getDay()
          const dayEndEv=info.event.end.getDay()
       
         if(dayStartBg===dayStartEv && dayEndBg===dayEndEv)
         day2=true;
        
         if(start<=info.event.start && info.event.start<end || start<info.event.end && info.event.end<=end)
         edit2=true;
     
         if(day2===false || edit2===false)
         day2=false,edit2=false;
         }
      }else{
        const start=val.start;
        const end=val.end;
        const dayStartBg=start.getDay()
        const dayEndBg=end.getDay()
        const dayStartEv=info.start.getDay()
        const dayEndEv=info.end.getDay()
       
        if(dayStartBg===dayStartEv && dayEndBg===dayEndEv)
          day2=true;
        
        if(start<=info.start && info.start<end || start<info.end && info.end<=end)
          edit2=true;
     
        if(day2===false || edit2===false)
          day2=false,edit2=false;
      }
  
    }, (err) => {
      console.log(err);
    },
    () => {
    });
    
    from(this.diarysEvents).pipe(
      map(currentEvent=>{
       
        return currentEvent;
      })
    ).subscribe(val=>{

    if(val.extendedProps.isProgramable===false){
      isProgramable=false;
      let start=val.start;
      let end=val.end;
      let dayStartEv,dayEndEv;
      
       let dayStartBg=start.getDay()
       let dayEndBg=end.getDay()
       if(info.event){
        dayStartEv=info.event.start.getDay();
        dayEndEv=info.event.end.getDay();
       }else{
        dayStartEv=info.start.getDay();
        dayEndEv=info.end.getDay();
       }
    
      if(dayStartBg===dayStartEv && dayEndBg===dayEndEv)
      day=true;
    
      if(info.event){
        if((info.event.start>=start && info.event.end<=end) || (info.event.start<start && info.event.end>start) 
        || (info.event.start<end && info.event.end>end))
        edit=false;
      }else{
        if((info.start>=start && info.end<=end) || (info.start<start && info.end>start) 
        || (info.start<end && info.end>end))
        edit=false;
      }
     
      if(day===false || edit===true)
      day=false,edit=true;

    }

    }, (err) => {
      console.log(err);
    },
    () => {

      if(day===true && edit===false){
        if(info.event){
          info.revert();
          this.msgService.log("apointment.select.data.error", NotificationType.ERROR);
        }else
          this.msgService.log("apointment.select.data.error", NotificationType.ERROR);
      }
      else{
        if(edit2===true && day2===true){
          if(info.event){
            info.revert();
            this.msgService.log("apointment.overloap.data.error", NotificationType.ERROR);
          }else
            this.msgService.log("apointment.select.data.error", NotificationType.ERROR);
          }else{
            if(info.event)
              this.updateEvent(info);
            else
              this.onEventAdd(info);
            }
        }

        
    });
    day=false,edit=true;
    day2=false,edit2=false
  }

  /**
   * Edit and delete a appointment
   **/
  onEventEdit(event){
      console.log("Edit");
    
      console.log(event);
     
        if(event.start || event.event){
          let idSelect:number;
          if(event.start){
            this.eventTitle=this.apointment.description;
            idSelect=this.apointment.id;
          }else{
            this.eventTitle=event.event.title;
            idSelect=+event.event.id;
          }
       
        /*const dialogRef = this.dialogEdit.open(ApointmentEditModalEditComponent, {
          height: '270px',
          width: '600px',
          disableClose: true,
          data: {title : 'apointment.edit.title.label', message:'apointment.edit.description.label',event: this.eventTitle, 
                idSelectedApointment:idSelect}
        });*/

        const dialogRef = this.modalService.openModal(ApointmentEditModalEditComponent,
          'apointment.edit.title.label',undefined,{
          width: '65%',
          /*height:'230px',*/
          position:{left:"10%", right:"10%"},
          disableClose: true,
          data: {title : 'apointment.edit.title.label', message:'apointment.edit.description.label',event: this.eventTitle, 
          idSelectedApointment:idSelect, examId: this.exam? this.exam.id : undefined, 
          careProtocol: this.careProtocol, careMessage:'apointment.care.protocol.label', careId: this.care? this.care.id : undefined}
        });

        dialogRef.afterClosed().subscribe(result => {
          if(this.dataService.dataAnnuler===false && this.dataService.dataDelete===false){
            let index;
           
            if(!result||result===''){
              this.eventTitle=this.currentPatient.fullname;
            } else{
              this.eventTitle = result.event;
              this.careProtocol = result.careProtocol;
            }
        
            let calendarEvents = this.nkapFullCalendarCmp.calendarEvents.slice(); // a clone
      
            if(event.start){
              for(let i=0;i<calendarEvents.length;i++){
                if(calendarEvents[i].id==this.apointment.id)
                index=i;
              }
            }else{
              for(let i=0;i<calendarEvents.length;i++){
                if(calendarEvents[i].id==event.event.id)
                index=i;
              }
            }
            
            let timeSlot: TimeSlot=new TimeSlot();
            let timeStart:any,timeEnd:any,dateStart;
            let singleEvent = Object.assign({}, calendarEvents[index]); // a clone
            singleEvent.title = this.eventTitle;
    
            if(event.start){
              dateStart= formatDate(event.start,'yyyy-MM-dd','en');
              timeSlot=this.formatTimeSlot(event);
            }else{
              dateStart= formatDate(singleEvent.start.toString(),'yyyy-MM-dd','en');
              timeSlot=this.formatTimeSlot(event.event);
              this.apointment.id=+singleEvent.id;
            }
          
            this.apointment.date=new Date(dateStart);
            this.apointment.timeSlot=timeSlot;
            this.apointment.description=singleEvent.title;
            this.apointment.personToMeet=this.currentDoctor;

            if(!event.start){
              this.apointment.patient=singleEvent.patient;
              if(singleEvent.unit && singleEvent.unit.id)
                this.apointment.unit=singleEvent.unit;
            }

            console.log(this.apointment);
            this.httpService.save(this.apointment, 'admission/apointment',true).subscribe( (result) => {
              this.msgService.log("apointment.edit.save.succed", NotificationType.SUCCESS);
              this.currentApointment=this.apointment;
              this.nkapFullCalendarCmp.patientControl.patchValue(this.apointment.patient);
              this.currentPatient=this.apointment.patient;

              if(this.apointment.unit && this.apointment.unit.id){
                this.nkapFullCalendarCmp.serviceControl.patchValue(this.apointment.unit);
                this.currentService=this.apointment.unit;
              }else{
                this.currentService= new BusinessUnit();
                this.nkapFullCalendarCmp.serviceControl.reset();
              }

              if(this.dataService.dataAnnuler===false)
                this.apointment=new Apointment(); 

              this.selectDoctor();
            }, (errors) => {
              this.msgService.log("apointment.edit.save.error", NotificationType.ERROR);
               this.apointment=new Apointment(); 
            });  
          }else{
            // this.selectDoctor();
          }
          if(this.dataService.dataDelete===true){
            this.currentPatient=new Patient();
            this.currentService= new BusinessUnit();
            this.nkapFullCalendarCmp.patientControl.reset()
            this.nkapFullCalendarCmp.serviceControl.reset();
            this.selectDoctor();
          }

          this.eventTitle='';
          this.dataService.dataAnnuler=false;
          this.dataService.dataDelete=false;     
        });
      }
  }

  /**
   * Update the schedules during a resizing and drag-and-drop of a appointment
   **/
  updateEvent(event){
     if(event.start || event.event){
      let index;
    
      let calendarEvents = this.nkapFullCalendarCmp.calendarEvents.slice(); // a clone
        // console.log(this.calendarEvents);
  
      for(let i=0;i<calendarEvents.length;i++){
        if(calendarEvents[i].id==event.event.id)
          index=i;
      }
        let timeSlot: TimeSlot=new TimeSlot();
        let singleEvent = Object.assign({}, calendarEvents[index]);

        this.apointment.id=+singleEvent.id;
        this.apointment.description=singleEvent.title;
        let dateStart= formatDate(event.event.start,'yyyy-MM-dd','en');
        timeSlot=this.formatTimeSlot(event.event);
        this.apointment.date=new Date(dateStart);
        this.apointment.timeSlot=timeSlot;
  
        this.apointment.personToMeet=this.currentDoctor;

        this.apointment.patient=singleEvent.patient;
        if(singleEvent.unit && singleEvent.unit.id)
          this.apointment.unit=singleEvent.unit;

        this.httpService.save(this.apointment, 'admission/apointment',true).subscribe( (result) => {
          this.msgService.log("apointment.edit.update.succed", NotificationType.SUCCESS);
          this.currentApointment=this.apointment;

          this.nkapFullCalendarCmp.patientControl.patchValue(this.apointment.patient);
          this.currentPatient=this.apointment.patient;

          if(this.apointment.unit && this.apointment.unit.id){
             this.nkapFullCalendarCmp.serviceControl.patchValue(this.apointment.unit);
             this.currentService=this.apointment.unit;
          }else{
             this.currentService= new BusinessUnit();
             this.nkapFullCalendarCmp.serviceControl.reset();
          }

          this.apointment=new Apointment();
          this.selectDoctor();
        }, (errors) => {
          event.revert();
          this.msgService.log("apointment.edit.update.error", NotificationType.ERROR);
        });   
      }
  }

/**
 * Format calendar appointments so that they can be saved in Database
 **/
  formatTimeSlot(event:any){
    let timeSlot: TimeSlot=new TimeSlot();
    let timeStart:any;
    let timeEnd:any;
    // console.log(event)
    let dateStar,hoursStr,minutesStart,secondsStart,dateEnd,hoursEd,minutesEnd,secondsEnd,
    hSrt:number,hoursStart,hEnd:number,hoursEnd;

    dateStar=event.start;
    dateEnd= event.end;
     
      hoursStr=(new Date(dateStar)).toTimeString().slice(0, 8).split(':')[0];
      minutesStart=(new Date(dateStar)).toTimeString().slice(0, 8).split(':')[1];
      secondsStart=(new Date(dateStar)).toTimeString().slice(0, 8).split(':')[2];

      hoursEd=(new Date(dateEnd)).toTimeString().slice(0, 8).split(':')[0];
      minutesEnd=(new Date(dateEnd)).toTimeString().slice(0, 8).split(':')[1];
      secondsEnd=(new Date(dateEnd)).toTimeString().slice(0, 8).split(':')[2];

      hSrt=+hoursStr;
      hoursStart=''+hSrt;
      hEnd=+hoursEd;
      hoursEnd=''+hEnd;
    
    if(hoursStart.length===1)
      hoursStart= '0'+hoursStart;

    if(hoursEnd.length===1)
      hoursEnd= '0'+hoursEnd;

      timeStart=`${hoursStart}:${minutesStart}:${secondsStart}`;
      timeEnd=`${hoursEnd}:${minutesEnd}:${secondsEnd}`;

      timeSlot={
        startTime:timeStart,
        endTime:timeEnd
      }

      return timeSlot;
  }
  
  setObjectInView(apointment: Apointment){
    let end=true;
    // console.log(apointment);
    if (apointment.id) {
      this.service=true;
      this.currentDoctor=apointment.personToMeet as MedicalAgent;
      this.currentPatient=apointment.patient;
      this.currentService=apointment.unit;
      
      if(!apointment.timeSlot){
        this.apointment=apointment;
      }else if(apointment.timeSlot && apointment.personToMeet){
        this.currentApointment=apointment;
        // console.log( this.currentApointment);
      }else if(apointment.timeSlot && !apointment.personToMeet){
            this.apointmentNoDoctor=apointment;
            let timeStart=this.apointmentNoDoctor.timeSlot.startTime;
            let timeEnd=this.apointmentNoDoctor.timeSlot.endTime;
  
            let dateStart=`${(this.apointmentNoDoctor.date.toString()).split('T')[0]}T${timeStart}`;
            let dateEnd=`${(this.apointmentNoDoctor.date.toString()).split('T')[0]}T${timeEnd}`;

             let eventDisplay = {
              id:this.apointmentNoDoctor.id,
              title: this.apointmentNoDoctor.description,
              start: dateStart,
              end: dateEnd,
              textColor:'#FFFFFF',
              backgroundColor:'#8E11F5',
              borderColor:'#8E11F5',
            };
        this.nkapFullCalendarCmp.calendarEvents=this.nkapFullCalendarCmp.calendarEvents.concat(eventDisplay);
      }
       
      if(apointment.timeSlot && !apointment.timeSlot.endTime){
          end=false
          console.log(apointment);
          let hoursStr,minutesStart,secondsStart,hoursEnd,minutesEnd,secondsEnd,mSrt,hSrt,timeEnd;
          hoursStr=apointment.timeSlot.startTime.toString().split(':')[0];
          minutesStart=apointment.timeSlot.startTime.toString().split(':')[1];
          secondsStart=apointment.timeSlot.startTime.toString().split(':')[2];
          mSrt=+minutesStart;
          hSrt=+hoursStr;
          hoursEnd=hoursStr;
          minutesEnd=minutesStart;
          secondsEnd=secondsStart;
          // if(minutesStart==='00'){
          //   minutesEnd=mSrt+30;
          // }else{
            hoursEnd=`${hSrt+1}`;
          // }
          
          if(hoursEnd.length===1)
          hoursEnd= '0'+hoursEnd;
          // console.log(hoursEnd)

          timeEnd=`${hoursEnd}:${minutesEnd}:${secondsEnd}`;
          apointment.timeSlot.endTime=timeEnd;
          console.log(timeEnd);
          console.log(apointment);
          this.httpService.save(apointment, 'admission/apointment',true).subscribe( (result) => {
            // this.msgService.log("apointment.edit.save.succed", NotificationType.SUCCESS);
            this.currentApointment=apointment;
            this.nkapFullCalendarCmp.doctorControl.patchValue(this.currentDoctor);
           
          }, (errors) => {
            this.msgService.log("apointment.edit.save.error", NotificationType.ERROR);
          });  
          // this.selectDoctor();
        }
        
        // console.log(this.apointment)
        // console.log(this.apointmentNoDoctor)
        let calendarApi = this.nkapFullCalendarCmp.calendarComponent.getApi();
        calendarApi.gotoDate(apointment.date); // call a method on the Calendar object    

      if(this.currentPatient && this.currentPatient.id)
        this.nkapFullCalendarCmp.patientControl.patchValue(this.currentPatient);
        
      if(this.currentDoctor && this.currentDoctor.id && end===true)
        this.nkapFullCalendarCmp.doctorControl.patchValue(this.currentDoctor);
        
      if(this.currentService && this.currentService.id)
        this.nkapFullCalendarCmp.serviceControl.patchValue(this.currentService);
      else
        this.service=false;
       
    }
  }

}

