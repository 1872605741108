import { Component, OnInit, Inject, ViewChild, AfterViewInit, ChangeDetectorRef } from '@angular/core';
import { NkapHttpService } from 'src/app/services/nkap-http.service';
import { MatTableDataSource, MatPaginator, MatSort, PageEvent, MatInput, MatTable } from '@angular/material';
import { Subscription, Subject } from 'rxjs';
import {MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import { FormControl } from '@angular/forms';
import { SearchCriteria } from 'src/app/model/data-page';
import { GlobalVariables } from 'src/app/services/global-variables';
import { SelectionModel } from '@angular/cdk/collections';
import { Router, ActivatedRoute } from '@angular/router';
import { IFormActionBarButton, FormAction, ButtonsBarComponent } from '../../buttons-bar/buttons-bar.component';
import { NkapNotificationService, NotificationType } from 'src/app/services/nkap-notification.service';
import { TranslateService, TranslatePipe } from '@ngx-translate/core';
import { Antecedent, AntecedentCategory, AntecedentType } from 'src/app/model/admission/medical-folder';
import { NkapListComponent } from '../../base/nkap-list-component';
import { UserAppService } from 'src/app/services/user-app.service';
import { Funtions } from 'src/app/model/security/funtionalities';

/**
 * @title Basic use of `<table mat-table>`
 */
@Component({
  selector: 'app-antecedent-list',
  templateUrl: './antecedent-list.component.html',
  styleUrls: ['./antecedent-list.component.css']
})
export class AntecedentListComponent  extends NkapListComponent<Antecedent>  {
  @ViewChild("btnBar")
  btnBarCmp: ButtonsBarComponent;
  
  userDataAntecedentSubscription: Subscription;
  userDataAntecedentSubject: Subject<Antecedent[]> = new Subject<Antecedent[]>();
  displayedColumns: string[] = ['name', 'description','action'];
  tableDataSource: MatTableDataSource<Antecedent> = new MatTableDataSource<Antecedent>([]);
  private dataAntecedent: Antecedent[];
  categoryList: {value: string , text: string}[];
 
  selectedAntecedent:any;

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;  

  translate: TranslatePipe;

  criteria: any | SearchCriteria;

  constructor(protected _ref: ChangeDetectorRef, protected httpService: NkapHttpService<Antecedent>, 
    protected router: Router, protected route: ActivatedRoute, protected userService: UserAppService,
    protected msgService: NkapNotificationService, protected translateService: TranslateService) {
   super(_ref, httpService, router, route, userService, msgService, translateService);
      this.searchBaseUrl = "admission/antecedent";
    this.buttons = [
      {id: "add", value: FormAction.CREATE, icon: {type : 'add_circle_outline', color: '#0099cc'},
        text: 'antecedent.btn.new.label', disabled: false, functionalities:[Funtions.antecedent_btn_new_fct]},
      {id: "edit", value: FormAction.UPDATE, icon: {type : 'edit',color:'#ff9933'}, 
        text: 'antecedent.btn.edit.label', disabled: false, functionalities: [Funtions.antecedent_btn_edit_fct]},
      {id: "delete", value: FormAction.DELETE, icon: {type :'delete_forever',color:'#ff9933'},
        text: 'antecedent.btn.delete.label', disabled: false, functionalities: [Funtions.antecedent_btn_delete_fct]}
    ];

    this.categoryList = [
      {value: AntecedentCategory[AntecedentCategory.PERSONAL], text:"antecedent.category." + AntecedentCategory[AntecedentCategory.PERSONAL] + ".label"},
      {value: AntecedentCategory[AntecedentCategory.FAMILY], text:"antecedent.category." + AntecedentCategory[AntecedentCategory.FAMILY] + ".label"},
    ];
   }

  ngOnInit() {
    super.ngOnInit();
    this.userDataAntecedentSubscription = this.userDataAntecedentSubject.subscribe((data) => {
      this.tableDataSource = new MatTableDataSource<Antecedent>(data);
      this.tableDataSource.paginator = this.paginator;
      this.tableDataSource.sort = this.sort;
    });
   // this.getAntecedent();
   
  }


  applyFilter(filterValue: string) {
    filterValue = filterValue.trim(); // Remove whitespace
    filterValue = filterValue.toLowerCase(); // Datasource defaults to lowercase matches
    this.tableDataSource.filter = filterValue;
  }

  emitData(){
    this.userDataAntecedentSubject.next(this.dataAntecedent);
  }

  getAntecedent(value = '') {
    this.httpService.search( {value: value}, 'admission/antecedent').subscribe(result => {
      this.dataAntecedent = result? result.datas : [];
      console.log(result);
      this.emitData();
    });
  }

  btnActionClick(event) {
    if(event && event.id === this.buttons[0].id){
      this.router.navigate(["antecedent/edit"]);
    } else if(event && event.id === this.buttons[1].id){
      if( !this.selection.selected || this.selection.selected.length != 1){
        this.msgService.log("antecedent.list.select.to.modify.error", NotificationType.ERROR);
      }else{
        this.router.navigate(["antecedent/edit", {id: this.selection.selected[0].id}]);
      }
    } else if(event && event.id === this.buttons[2].id){
      if( !this.selection.selected || this.selection.selected.length < 1){
        this.msgService.log("antecedent.list.select.to.delete.error", NotificationType.ERROR);
      }else{
        this.deleteSelected();
      }
    }
  }

  deleteSelected(){
    this.msgService.confirm(this.translate.transform("antecedent.delete.confirm.text")).subscribe( (result) => {
      if(result === true){
          let antecedentSelected = this.selection.selected[0];
          this.httpService.deleteById(antecedentSelected.id, 'admission/antecedent').subscribe( (val) => {
            this.msgService.log("antecedent.delete.succed", NotificationType.SUCCESS);
            // this.router.navigate(['antecedent/list', {search: true}]);
            this.searchClicked({});
          }, (error) => {
            this.msgService.log("antecedent.delete.fail", NotificationType.ERROR);
          });
        }
      });
  }

  protected viewElement(element) {
    this.router.navigate(['antecedent/show', {id: element.id}]);
  }

  listSelectionChanged(){
    super.listSelectionChanged();
    if(this.btnBarCmp){
      const btnStatus = [
        {id: "add", disabled: false, functionalities: this.buttons[0].functionalities},
        {id: "edit", disabled: !(this.selection && this.selection.selected && this.selection.selected.length > 0),functionalities: this.buttons[1].functionalities},
        {id: "delete", disabled: !(this.selection && this.selection.selected && this.selection.selected.length > 0),functionalities: this.buttons[2].functionalities},
      ];    
      this.btnBarCmp.setButtonsStatus(btnStatus);
    }
  }

  displayCategoryFn(antecedent:Antecedent){
    if(antecedent.category.toString()==AntecedentCategory[AntecedentCategory.PERSONAL])
    return this.categoryList[0].text;
    if(antecedent.category.toString()==AntecedentCategory[AntecedentCategory.FAMILY])
    return this.categoryList[1].text;
  }

  displayTypeFn(antecedent?: Antecedent): string {
    return antecedent.type ? `${antecedent.type.name}` : '';
  }

  ngOnDestroy(){
    this.userDataAntecedentSubscription.unsubscribe();
  }

}