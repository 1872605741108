import { Persistent, ClassNameMap } from '../persistent';

export class PhysicalExam extends Persistent {
    public eg?:string;
    public ta?:string;
    public t?:string;
    public glycemie?:string;
    public weight?:number;
    public size?:number;
    public urea?:number;
    public creat?:number;
    public imc?:string;
    public report?:number;
    public clearanceCreat?:number;
    public headAndNeck?:string;
    public thorax?:string;
    public abdomen?:string;
    public uroGenital?:string;
    public snc?:string;
    public ganglionicAreas?:string;
    public dermatology?:string;
    public locomotor?:string;
    public edit?:boolean;
  
    constructor() {
      super();
      this.classe = ClassNameMap.PhysicalExam;
    }
  }