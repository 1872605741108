import { Party } from './party';
import { Country } from '../location/country';
import { Persistent, ClassNameMap, Nameable } from '../persistent';
import { Period } from '../admission/Period';

export class Person extends Party {
    public surname?: string;
    public officePhone?: string;
    public religion?: Religion;
    public nationality?: Country;
    public gender?: string | GenderType;
    public identity?: Identity;
    public profession?: Profession;
    public addressString?: string;

    constructor(name?: string, surname?: string ) {
        super(name);
        this.nationality = new Country();
        this.classe = ClassNameMap.Person;
        this.surname = surname? surname : "";
        this.fullname = this.name + " " + this.surname;
        this.fullName = this.fullname;
    }

    public displayNationality(data: Country): string | any {
        return data ? data.name : '';
    }
}

export class Profession extends Nameable{
    constructor(name?: string) {
        super(name);
        this.classe = ClassNameMap.Profession;
    }
}


export class Religion extends Nameable{
    constructor(name?: string) {
        super(name);
        this.classe = ClassNameMap.Religion;
    }
}

export enum GenderType {
    FEMALE,
    MALE
}

export interface Identity {
    number?: string;
    identityType?: string | IdentityType;
    validityPeriod?: Period;
    deliveryPlace?: string;
}

export enum IdentityType {
    NATIONAL_CARD,
    PASSPORT
}

