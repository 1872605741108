import { Component, OnInit, ViewChild, ChangeDetectorRef, AfterContentChecked } from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {STEPPER_GLOBAL_OPTIONS} from '@angular/cdk/stepper';
import { Router, ActivatedRoute } from '@angular/router';

import { PatientIdentificationComponent } from '../patient-identification/patient-identification.component';
import { AdresseComponent } from '../adresse/adresse.component';
import { TranslateService, TranslatePipe } from '@ngx-translate/core';
import { AntecedentsFrcvComponent } from '../antecedents-frcv/antecedents-frcv.component';
import { Patient } from '../../model/organisation/patient';
import { Person } from '../../model/organisation/person';
import { CouvertureSanteComponent } from '../couverture-sante/couverture-sante.component';
import { ContactPersonComponent } from '../contact-person/contact-person.component';
import { ContactPerson } from '../../model/organisation/contact-person';
import { NkapHttpService } from '../../services/nkap-http.service';
import { FRCVTypes, Antecedent, MedicalFolder } from 'src/app/model/admission/medical-folder';
import { UserAppService } from '../../services/user-app.service';
import { NkapNotificationService, NotificationType } from '../../services/nkap-notification.service';
import { Reception } from '../../model/admission/reception';
import { MatDialog } from '@angular/material';
import { MedicalFolderSearchDialogComponent } from '../medical-folder/medical-folder-search-dialog/medical-folder-search-dialog.component';
import { IFormActionBarButton, FormAction, ButtonsBarComponent } from '../buttons-bar/buttons-bar.component';
import { SearchCriteria } from '../../model/data-page';
import { Funtions } from 'src/app/model/security/funtionalities';
import { MandatoryInformationComponent } from './mandatory-information/mandatory-information.component';

let self ;

@Component({
  selector: 'admission-stepper',
  templateUrl: './admission-stepper.component.html',
  styleUrls: ['./admission-stepper.component.css'],
  providers: [{
    provide: STEPPER_GLOBAL_OPTIONS, useValue: {showError: true, displayDefaultIndicatorType: false}
  }]
})

export class AdmissionStepperComponent implements OnInit, AfterContentChecked  {

  isLinear = false;
  isOptional = false;
  isEditable = true;

  firstFormGroup: FormGroup;
  secondFormGroup: FormGroup;

  dataToSave: MedicalFolder;
  patientAlreadySaved = false;

  @ViewChild("mandatoryInformation")
  mandatoryInformationCmp: MandatoryInformationComponent;

  
  patientAddressCmp: AdresseComponent;

 
  patientIdentityCmp: PatientIdentificationComponent;

  @ViewChild("AntecedentFrcv")
  AntecedentFrcvCmp: AntecedentsFrcvComponent;

  @ViewChild("personToContact")
  personToContactCmp: ContactPersonComponent;

  @ViewChild("couvertureSante")
  couvertureSanteCmp: CouvertureSanteComponent;

  @ViewChild("btnBar")
  btnBarCmp: ButtonsBarComponent;
  protected buttons: IFormActionBarButton[];

  /**
   * Translation pipe
   */
  public translatePipe: TranslatePipe;

  userdataFRCV: FRCVTypes[] = [];
  userDataAntecedent: Antecedent[] = [];
  patientAddressData: Person;
  patientData: Patient;
  contactPerson: ContactPerson[];
  reception: Reception;
  coverageDocNumber: any;
  coverageDocPayerId: any;
  coverageDocPatientFolderId: any;

  constructor(private _formBuilder: FormBuilder, public _ref: ChangeDetectorRef,
    private httpService: NkapHttpService<any>,  private router: Router, 
    public dialog: MatDialog,
    private userAppService: UserAppService, private route: ActivatedRoute,
    private translate: TranslateService, private msgService: NkapNotificationService) {

    self = this;
    this.translatePipe = new TranslatePipe(translate, _ref);

    this.buttons = [
      {id: "list", value: FormAction.UPDATE, icon: {type: 'search', color:'#ff9933'}, 
        text: 'patient.same.search.label', disabled: true, functionalities: [Funtions.patient_btn_new_fct]} 
    ];
  }

  ngOnInit() {

    this.reception = this.userAppService.getReception();

    this.patientAddressCmp = this.mandatoryInformationCmp.getPatientAddressCmp();
    this.patientIdentityCmp = this.mandatoryInformationCmp.getPatientIdentityCmp();

    this.firstFormGroup = this._formBuilder.group({
      firstCtrl: ['', Validators.required]
    });

    this.secondFormGroup = this._formBuilder.group({
      secondCtrl: ['', Validators.required]
    });

    /* if(!this.reception){
      this.router.navigate(['reception/list']);
    } */

    if(this.reception && this.reception.prestationsToPerformed.length > 0){   
      for(let data of this.reception.prestationsToPerformed) {
        if(data.careCoverageDoc && data.careCoverageDoc.number){
          this.coverageDocNumber = data.careCoverageDoc.number;
          this.coverageDocPatientFolderId = data.careCoverageDoc.patientFolderId;
          if(data.careCoverageDoc.documentIssuer){
            this.coverageDocPayerId = data.careCoverageDoc.documentIssuer.id;
          }
          break;
        }
      }
    }

    if(this.reception && this.reception.patientFolder && this.reception.patientFolder.id != null) {          
          this.coverageDocPatientFolderId = this.reception.patientFolder.id;
          this.httpService.findById(this.reception.patientFolder.id, 'admission/medical-folder', true).subscribe( (result) => {
              this.setObjectInView(result);
          }, (error) => {
            if (error && Array.isArray(error)) {
              error.forEach( (err) => {
                console.log(err, NotificationType.ERROR);
              });
            } else {
              console.log("object.find.error", NotificationType.ERROR);
            }
        });
    }else{
      this.route.paramMap.subscribe(params => {
        let data: {id?: string} = (params as any).params;
        if (data.id != null) {
          this.coverageDocPatientFolderId = +(data.id);
          this.httpService.findById(data.id, 'admission/medical-folder', true).subscribe( (object)=>{
            this.setObjectInView(object);
          }, (errors) => {
            this.msgService.log("form.edit.get.data.error", NotificationType.ERROR);
          });
        }else{
          this.dataToSave = new MedicalFolder();
          this.setObjectInView(this.dataToSave);
        }
      });
    }

  }

  setObjectInView(object: MedicalFolder) {
    if (object) {
      console.log(object);
      this.patientAlreadySaved = object.id != null ? true : false;
      // init the medical folder stepper values
      this.dataToSave = object;
      this.dataToSave.toAddOrUpdate = true;
      this.patientData = object.patient;
      this.patientAddressData = object.patient;
      this.userDataAntecedent = object.antecedents;
      this.userdataFRCV = object.frcvTypes;

      // get datas from server
      if(object.id != null) {
        // We get complete list of antecedents
        this.httpService.get(`admission/antecedent/find-by-medical-folder-id/${object.id}`).subscribe( (result) => {
          this.userDataAntecedent = result;
        });
        // We get complete list of frcvs
        this.httpService.get(`admission/frcv/find-by-medical-folder-id/${object.id}`).subscribe( (result) => {
          this.userdataFRCV = result;
        });
      }
      
      this.contactPerson = object.contactPersons;
      if(object.attendingDoctor){
        let doctorPerson = new ContactPerson();
        doctorPerson.person = object.attendingDoctor;
        doctorPerson.company = object.attendingDoctor.shortName != null ? object.attendingDoctor.shortName : " ";
        this.contactPerson.push(doctorPerson);
      }
    }
  }

  ngAfterContentChecked() {
    this._ref.detectChanges();  
    this.listSelectionChanged();
  }

  buildData(){
    let identity = this.patientIdentityCmp.getData();
    let address = this.patientAddressCmp.getData();
    let contactPerson = this.personToContactCmp.getData();
    let insurance = this.couvertureSanteCmp? this.couvertureSanteCmp.getData() : null;
    console.log(insurance);

    this.dataToSave.antecedents = this.AntecedentFrcvCmp.getData().dataAntecedent;
    this.dataToSave.frcvTypes = this.AntecedentFrcvCmp.getData().dataFRCV;
    this.dataToSave.contactPersons = contactPerson;

   /* if(insurance != null && insurance != undefined){
        this.dataToSave.careCoverageDocuments = [];
        this.dataToSave.careCoverageDocuments.push(insurance);
    }*/
    
    var mergedObject = Object.assign(identity, address); 
    this.dataToSave.patient = mergedObject;
    if(this.dataToSave.patient.id != null){
      this.dataToSave.patient.toAddOrUpdate = true;
    }

    if(this.reception && this.reception.prestationsToPerformed.length > 0){   
      if(insurance != null && insurance != undefined){// adding carecoverage to prestationsToPerformed
        for(let data of this.reception.prestationsToPerformed) {
          data.careCoverageDoc = insurance;
          if(!data.toDelete){
            data.toAddOrUpdate = true;
          }
        }  
      }
    }

  }

  listSelectionChanged(){
    if(this.btnBarCmp){
      const btnStatus = [
        {id: "list", disabled: !(this.patientAlreadySaved == false && this.isMandatoryInformationIsValid() == true), functionalities: this.buttons[0].functionalities},
      ];    
      this.btnBarCmp.setButtonsStatus(btnStatus);
    }
  }

  btnActionClick(event) {
    if(event && event.id === this.buttons[0].id ){
      if(this.patientIdentityCmp.isValid()){
        this.searchPatient(event);
      }else{
        this.msgService.log("error.label", NotificationType.ERROR);
      }
    }
  }

  /**
   * Search patient base on search text enter
   * If a patient is not found, open modal
   * */
  protected searchPatient(event) {
    let patientIdentity = this.patientIdentityCmp.getData();
    const nameValue = patientIdentity != null ? patientIdentity.name : null;
    const surnameValue = patientIdentity != null ? patientIdentity.surname : "";
    const birthdateValue = patientIdentity != null ? patientIdentity.birthdate : null;
    const genderValue = patientIdentity != null ? patientIdentity.gender : null;
    const nationalityValue = patientIdentity != null ? patientIdentity.nationality.id : null;
    let criteria = {value: nameValue, birthdate: birthdateValue, gender: genderValue, nationalityId: nationalityValue};
    this.httpService.search(criteria, 'admission/medical-folder', true).subscribe ( (result) => {
        if (result && result.datas && result.datas.length > 0) {
          /* if (result.datas.length == 1) {
            this.getFolderById(result.datas[0].id);
          } else { */
            this.searchMedicalFolder(criteria);
          //}
        } else {
          this.searchMedicalFolder(criteria);
        }
    });
  }

  automaticFolderSearchBeforeSave() {
    if(this.dataToSave != null && this.dataToSave.id == null){
      let patientIdentity = this.patientIdentityCmp.getData();
      const nameValue = patientIdentity != null ? patientIdentity.name : null;
      const surnameValue = patientIdentity != null ? patientIdentity.surname : "";
      const birthdateValue = patientIdentity != null ? patientIdentity.birthdate : null;
      const genderValue = patientIdentity != null ? patientIdentity.gender : null;
      const nationalityValue = patientIdentity != null ? patientIdentity.nationality.id : null;
      let criteria = {value: nameValue, surname: surnameValue, birthdate: birthdateValue, gender: genderValue, nationalityId: nationalityValue};
      this.httpService.post(criteria, 'admission/patient-medical-folder/search', true).subscribe ( (result) => {
        if (result && result === true) {
          this.save();
        }else{
          this.msgService.log("patient.data.already.exist", NotificationType.ERROR);
        }
      });
    }else{
      this.save();
    }
  }

    // Open modal to search a patient
    private searchMedicalFolder(inputValue){
      const dialogRef = this.dialog.open(MedicalFolderSearchDialogComponent, {
        width: '900px',
        height:'550px',
        data: { criteria: inputValue}
      });
      dialogRef.afterClosed().subscribe(result => {
          console.log('The dialog was closed', result);
          if(result && result.id != null){
            this.getFolderById(result.id);
          }
      });
    }

  save(){
    this.buildData();
    if (this.reception) {
      if (this.ispatientIdentityValid() == true && this.ispatientAddressValid() == true 
        && this.ispersonToContactValid() == true && this.isInsuranceValid() == true) {
        this.reception.patientFolder = this.dataToSave;
        this.httpService.save(this.reception, 'admission/reception', true).subscribe( (result) => {
            this.msgService.log("object.save.succed.message", NotificationType.SUCCESS);
            this.userAppService.setReception(null);
            this.router.navigate(['reception/list', {search: true}]);
          }, (error) => {
            if (error && Array.isArray(error)) {
              error.forEach( (err) => {
                this.msgService.log(err, NotificationType.ERROR);
              });
            } else {
              this.msgService.log("object.save.error", NotificationType.ERROR);
            }
        });
      }
    }else{
      if (this.ispatientIdentityValid() == true && this.ispatientAddressValid() == true 
      && this.ispersonToContactValid() == true ) {     
          this.httpService.save(this.dataToSave, 'admission/medical-folder', true).subscribe( (result) => {
            this.msgService.log("object.save.succed.message", NotificationType.SUCCESS);
            this.userAppService.setReception(null);
            this.router.navigate(['medical-folder/list', {search: true}]);
          }, (error) => {
            if (error && Array.isArray(error)) {
              error.forEach( (err) => {
                this.msgService.log(err, NotificationType.ERROR);
              });
            } else {
              this.msgService.log("object.save.error", NotificationType.ERROR);
            }
        });
      }
    }
  }

  saveMedicalFolderInBackground() {
    this.buildData();
    if (!this.reception && this.dataToSave.id == null){
        this.httpService.save(this.dataToSave, 'admission/medical-folder', true).subscribe( (result) => {
          this.dataToSave = result;
        }, (error) => {
          if (error && Array.isArray(error)) {
            error.forEach( (err) => {
              this.msgService.log(err, NotificationType.ERROR);
            });
          } else {
            this.msgService.log("object.save.error", NotificationType.ERROR);
          }
      });
    }
  }

  ispatientIdentityValid(){
    return this.patientIdentityCmp.isValid();
  }

  isMandatoryInformationIsValid() {
    return this.ispatientIdentityValid() && this.ispatientAddressValid();
  }

  ispatientAddressValid(){
    return this.patientAddressCmp.isValid(); 
  }

  ispersonToContactValid(){
    return this.personToContactCmp.isValid(); 
  }

  isInsuranceValid(){
   // if(this.coverageDocNumber || this.coverageDocPayerId){
      return this.couvertureSanteCmp != undefined ? this.couvertureSanteCmp.isValid() : true; 
    //}
    // return true;
  }

  isFormGroupValid(){
    return true; 
  }

  stepSelectedChanged(event) {
    //console.log("stepSelectedChanged ", event);
    /*if(event && event.selectedIndex == 4){
      this.saveMedicalFolderInBackground();
    }*/
  }

  // cancel all changed
  cancel(){
    this.msgService.confirm("admission-stepper.cancel.confirm.text").subscribe( (result) => {
      if(result === true){
        if(this.reception){
          this.router.navigate(['reception/list', {search:true}]);
        } else {
          this.router.navigate(['medical-folder/list', {search:true}]);
        }
      }
    });
  }

  // Find Medical Folder By Id
  getFolderById(folderId){
    this.httpService.findById(folderId, 'admission/medical-folder', true).subscribe( (result) => {
    this.setObjectInView(result);
      }, (error) => {
        if (error && Array.isArray(error)) {
          error.forEach( (err) => {
            console.log(err, NotificationType.ERROR);
          });
        } else {
          console.log("object.find.error", NotificationType.ERROR);
        }
    });
  }
}
