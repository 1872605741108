import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent, HttpErrorResponse, HttpResponse } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { NkapKeycloakService } from './nkap-keycloak.service';;
import { catchError, tap } from 'rxjs/operators';
import { Router } from '@angular/router';
import { GlobalVariables } from './global-variables';
import { AccessToken } from '../model/security/access-token';

@Injectable({
  providedIn: 'root'
})
export class NkapHttpInterceptorService implements HttpInterceptor {

  
  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

  let authReq: HttpRequest<any> = req.clone({headers: req.headers.delete("Authorization")});

    
    return next.handle(authReq).pipe(
      
      catchError((err: HttpErrorResponse) => {
        
        if ((err.status === 401 && err.url.includes('/security/disconnect ')) || (err.status === 501 && err.error.message == 'error.security.unknown.user') ) {
          NkapKeycloakService.accessToken = null;
          this.nkapKeycloakService.emitToken();
        } else if (err.status === 401 && err.statusText === 'Unauthorized') {
          if (!err.url.includes('/security/refreshToken') && !err.url.includes('/security/rules') && !err.url.includes('/security/connect')) {
              this.nkapKeycloakService.refrechToken().subscribe((result: AccessToken) => {
                NkapKeycloakService.accessToken = result;
                this.nkapKeycloakService.emitToken();
              });
          }
        } else if (err.status === 403) {
          this.router.navigate(GlobalVariables.AFTER_LOGIN_ROUTE);
        } else if ((err.status === 500 || err.status === 504 || err.status === 400) && err.url.includes('/security/refreshToken')) {
          NkapKeycloakService.accessToken = null;
          this.nkapKeycloakService.emitToken();
          this.nkapKeycloakService.emitDeconnect();
        }
        return throwError(err);
      }),

      tap((response: HttpResponse<any>) => {
        if (response instanceof HttpResponse) {
         // TODO
        }
      }),
    )
  }

  constructor(private nkapKeycloakService: NkapKeycloakService, private router: Router) { }
}
