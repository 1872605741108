import { Persistent, ClassNameMap } from '../persistent';
import { Patient } from '../organisation/patient';
import { Person } from '../organisation/person';
import { ContactPerson } from '../organisation/contact-person';
import { CareCoverageDoc } from './care-coverage-doc';

export class MedicalFolder extends Persistent {

    public patient?: Patient;

    public attendingDoctor?: Person;

    public number?: number | string;

    public firstDateReception?: Date;

    public antecedents?: Antecedent[];

    public frcvTypes?: FRCVTypes[];

    public contactPersons?: ContactPerson[];

    public careCoverageDocuments?: CareCoverageDoc[];
    constructor(number?: number | string,patient?: Patient) {
      super();
      this.classe = ClassNameMap.MedicalFolder;
      this.number = number;
      this.patient = patient;
    }
}

export class Antecedent extends Persistent {
    public description?: string;
    public category?: AntecedentCategory;
    public type?: AntecedentType;
    public folderId?: number;
     index?: number;
    
    constructor(type?: AntecedentType,description?: string) {
        super();
        this.classe = ClassNameMap.Antecedent;
        this.type = type;
        this.description = description ? description : "";
    }
  }


export enum AntecedentCategory {
  PERSONAL,
	FAMILY
}

  export class AntecedentType extends Persistent {
    name?: string;
    
    constructor(name?: string) {
        super();
        this.classe = ClassNameMap.AntecedentType;
        this.name = name? name: "";
    }
  }

export class FRCVTypes extends Persistent {
    name?: string;
    check?: boolean;
    constructor(name?: string) {
        super();
        this.classe = ClassNameMap.FRCVTypes;
        this.name = name? name : "";
    }
  }

  export class SystemInvestigationType extends Persistent {
    public name?: string
    constructor(name?: string) {
        super();
        this.classe = ClassNameMap.SystemInvestigationType;
        this.name = name? name : "";
    }
  }

  export class SystemInvestigation extends Persistent {
    description?: string;
    public systemInvestigationType?: SystemInvestigationType;
    constructor(systemInvestigationType?: SystemInvestigationType,description?: string) {
        super();
        this.systemInvestigationType = systemInvestigationType;
        this.classe = ClassNameMap.SystemInvestigation;
        this.description = description? description : "";
    }
  }
