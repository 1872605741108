import { Component, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
let self;

@Component({
  selector: 'app-identity',
  templateUrl: './identity.component.html',
  styleUrls: ['./identity.component.css']
})
export class IdentityComponent implements OnInit {

  typeControl: FormControl = new FormControl();
  types: any [];

  numberControl: FormControl = new FormControl();

  maxStartDate = new Date();
  maxEndDate = new Date();

  startControl: FormControl = new FormControl();
  endControl: FormControl = new FormControl();

  deliveryPlaceControl: FormControl = new FormControl();

  constructor() {
    self = this;
   }

  ngOnInit() {
  }

  public periodValidator(valueControl: FormControl) {
    if (self && self.startControl && self.endControl) {
      if ( (self.startControl.value == null && self.endControl.value != null)
      || ((self.startControl.value != null && self.endControl.value != null && self.startControl.value > self.endControl.value))) {
        return {
            'periodValidator': false
        };
      }
    }
  }

  public identityValidator(valueControl: FormControl) {
    if (self && self.typeControl && self.numberControl && self.startControl && self.endControl && self.deliveryPlaceControl) {
        if ( (self.valueControl.value != null && typeof self.valueControl.value == "string" && self.valueControl.value.trim() != "" ) && (
          (self.typeControl.value == null)
          || (self.startControl.value == null)
          || (self.numberControl.value == null || self.numberControl.value.trim() == null )
          || (self.deliveryPlaceControl.value == null || self.deliveryPlaceControl.value.trim() == null ))) {
          return {
              'identityValidator': false
          };
        }
    }
    return null;
  }

}
