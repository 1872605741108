import { Component, OnInit, Inject, ViewChild, ElementRef } from '@angular/core';
import { FormGroup, FormBuilder, Validators, FormControl } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef, MatAutocomplete, MatChipInputEvent, MatAutocompleteSelectedEvent } from '@angular/material';
import { IFormActionBarButton, FormAction } from 'src/app/components/buttons-bar/buttons-bar.component';
import { Periode, DayOfWeek, Activity } from 'src/app/model/organisation/diary';
import { Time } from '@angular/common';
import { ENTER, COMMA } from '@angular/cdk/keycodes';
import { Observable, from } from 'rxjs';
import { startWith, map, filter } from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';
import { NkapHttpService } from 'src/app/services/nkap-http.service';
import { NkapNotificationService, NotificationType } from 'src/app/services/nkap-notification.service';
import { ClassNameMap } from 'src/app/model/persistent';
import { Funtions } from 'src/app/model/security/funtionalities';
import { IModalComponent } from 'src/app/components/base/modal/modal.component';

@Component({
  selector: 'app-doctor-planing-modal',
  templateUrl: './doctor-planing-modal.component.html',
  styleUrls: ['./doctor-planing-modal.component.css']
})
export class DoctorPlaningModalComponent extends IModalComponent implements OnInit {
  

  doctorPlaningModalForm: FormGroup;
  protected buttons: IFormActionBarButton[];

  dayControl = new FormControl();
  separatorKeysCodes: number[] = [ENTER, COMMA];
  filteredDays: {value:string, text: string}[];
  days: {value: string, text: string }[] = [];
  activities: Activity[] = [];
  visible = true;
  selectable = true;
  removable = true;
  addOnBlur = true;
  showSpecifiqEvent = false
  allDay = false;
  isProgramable = true;
  datas: any[];
  disabled = true;

  parameters: any = {};

  @ViewChild('dayInput') dayInput: ElementRef<HTMLInputElement>;
  @ViewChild('auto') matAutocomplete: MatAutocomplete;


  DayOfWeek = [
    {value: DayOfWeek[0], text: 'doctor.planing.day.enum.sunday'},
    {value: DayOfWeek[1], text: 'doctor.planing.day.enum.monday'},
    {value: DayOfWeek[2], text: 'doctor.planing.day.enum.tuesday'},
    {value: DayOfWeek[3], text: 'doctor.planing.day.enum.wednesday'},
    {value: DayOfWeek[4], text: 'doctor.planing.day.enum.thursday'},
    {value: DayOfWeek[5], text: 'doctor.planing.day.enum.friday'},
    {value: DayOfWeek[6], text: 'doctor.planing.day.enum.saturday'},
  ];

  protected data: Periode;

  constructor(private formBuilder: FormBuilder,
              private translateService: TranslateService,
              private nkapService: NkapHttpService<any>,
              private notificationService: NkapNotificationService,
              public dialogRef: MatDialogRef<DoctorPlaningModalComponent>,
               @Inject(MAT_DIALOG_DATA) public modalData: any) {
                super();
                this.data = modalData.data;
                console.log(this.data,modalData);
                this.parameters = {
                  activiryUrl: 'organisation/activity',
                };
   }

  ngOnInit() {
    this.initForm();
    if (this.data.businessHours) {
      this.DayOfWeek = this.DayOfWeek.filter(day => this.data.businessHours.includes(DayOfWeek[day.value]));
    }
    
    if (this.data.day) {
      const val = this.DayOfWeek.find(elt => elt.value ===  this.data.day+'');
      this.days.push(val);
      this._filter();
    }

    if (this.data.isProgramable !== undefined) {
      this.isProgramable = this.data.isProgramable;
    }

    this.doctorPlaningModalForm.statusChanges.subscribe(value => {

      if (this.doctorPlaningModalForm.invalid === false) {
        this.buttons[0].disabled = false;
      } else {
        this.buttons[0].disabled = true;
      }
    });

    if (this.data.action !== 'edit') {
      this.disabled = false;
    }

    this.doctorPlaningModalForm.get('name').valueChanges.subscribe(value => {
      if (this.activities && this.activities.find(elt => elt.name === value)) {
        this.doctorPlaningModalForm.get('name').reset(value, {emitEvent: false});
        return;
      }
      if (!value || typeof value === 'string') {
        this.nkapService.search( {value: value ? value : ''}, `${this.parameters.activiryUrl}`).subscribe(results => {
          if (!results) return;
          if (results.datas === null) return;
          this.activities = results.datas;
        }, err => {
          console.log(err);
        });
      }
    });

    this.buttons = [
      {id: 'save', value: FormAction.CREATE, disabled: this.doctorPlaningModalForm.invalid , icon: {type : 'save'},
        text: 'btn.save.label', functionalities: [Funtions.doctorPlaning_btn_new_fct, Funtions.doctorPlaning_btn_him_fct]},
      {id: 'cancel', value: FormAction.CANCEL, icon: {type : 'cancel'},
        text: 'couverture-sante.document.modal.btn.cancel', functionalities: [Funtions.doctorPlaning_btn_new_fct, Funtions.doctorPlaning_btn_him_fct]},
        {id: 'delete', disabled: this.data.action !== 'edit', value: FormAction.DELETE, icon: {type : 'delete'},
        text: 'doctor.planing.modal.action.delete', functionalities: [Funtions.doctorPlaning_btn_new_fct, Funtions.doctorPlaning_btn_him_fct]},
    ];

  }
  clickIsProgrammable(event) {
    this.isProgramable = !this.isProgramable;
  }

  changeShow(event) {
    this.showSpecifiqEvent = !this.showSpecifiqEvent;
    if (this.showSpecifiqEvent) {
      if (this.data.day) {
        this.days = [];
        const val = this.DayOfWeek.find(elt => elt.value ===  this.data.day+'');
        this.days.push(val);
        this._filter();
      }
      if (this.data.specifiqEnd) {
        this.doctorPlaningModalForm.get('end').reset(this.data.specifiqEnd);
      }
      if (this.data.specifiqStart) {
        this.doctorPlaningModalForm.get('start').reset(this.data.specifiqStart);
      }
    } else {
      this.doctorPlaningModalForm.get('start').reset(this.data.validityStartDate);
      this.doctorPlaningModalForm.get('end').reset(this.data.validityEndDate);
    }
  }

  initForm() {
    this.doctorPlaningModalForm = this.formBuilder.group({
      name: [this.data.activity && this.data.activity.name != null ? this.data.activity.name : '' , []],
      start: [this.data.validityStartDate === null ? null : this.data.validityStartDate, [Validators.required]],
      end: [this.data.validityEndDate === null ? null : this.data.validityEndDate, [Validators.required]],
      startTime: [(this.data.timeSlot && this.data.timeSlot.startTime !== null) ? this.data.timeSlot.startTime : null ,
        []],
      endTime: [(this.data.timeSlot && this.data.timeSlot.endTime !== null) ? this.data.timeSlot.endTime : null,
        []],
      day: [undefined, []],
    });
  }

  onNoClick() {
    this.dialogRef.close();
  }

  clickAllDay() {
    if (!this.disabled) {
      this.allDay = !this.allDay;
      if(this.allDay && !this.showSpecifiqEvent) {
        this.days = [];
        this.DayOfWeek.forEach(elt => this.days.push(elt));
        this._filter();
      }
    }
  }

  onSubmitForm() {
    const formValue = this.doctorPlaningModalForm.value;
    const sentValue: Periode = this.getData();
    console.log(sentValue);

    if (!(formValue.name == null || formValue.name.trim() == "" ) && this.activities && !this.activities.find(elt => elt.name === formValue.name)) {
      const activity = new Activity();
      activity.name = formValue.name;
      this.nkapService.save(activity, `${this.parameters.activiryUrl}`).subscribe(res => {
        sentValue.activity = res;
        this.dialogRef.close(sentValue);
      },
      (err) => {
        this.translateService.get(`${err}`).subscribe(errTranslate => {
          this.notificationService.log(errTranslate, NotificationType.ERROR);
        });
      });
    } else {
      this.dialogRef.close(sentValue);
    }
  }

  public getData() {
    const formValue = this.doctorPlaningModalForm.value;
    const sentValue: Periode = {
      classe: ClassNameMap.Periode,
      activity: (formValue.name == null || formValue.name.trim() == "" ) ? null : this.activities.find(elt => elt.name === formValue.name),
      validityEndDate: new Date(formValue.end),
      validityStartDate: new Date(formValue.start),
      timeSlot: {
        startTime: formValue.startTime,
        endTime: formValue.endTime
      },
      day: formValue.day === null ? undefined : formValue.day,
      days: this.days,
      action: this.data.action,
      id: this.data.id,
      symbol: this.data.symbol,
      isProgramable: this.isProgramable,
      type: this.showSpecifiqEvent ? 'simpleEvent' : 'recursiveEvent'
    };
    return sentValue;
  }
  btnActionClick(event) {
    if ( event && event.id === this.buttons[1].id) {
      this.onNoClick();
      return;
    }

    if ( event && event.id === this.buttons[0].id) {
      this.onSubmitForm();
      return;
    }

    if ( event && event.id === this.buttons[2].id) {
      this.data.action = 'delete';
      this.data.days = this.days;
      this.dialogRef.close(this.data);
      return;
    }
  }
  add(event: MatChipInputEvent): void {
    if (!this.matAutocomplete.isOpen) {
      const input = event.input;
      const value = event.value;

      if ((value || '').trim()) {
        console.log(value);
        // this.days.push(value.trim());
      }

      if (input) {
        input.value = '';
      }

      this.dayControl.setValue(null);
    }
  }

  remove(day: {value:string, text: string}): void {
    const index = this.days.indexOf(day);
    if (index >= 0) {
      this.days.splice(index, 1);
      this._filter();
    }
  }

  selected(event: MatAutocompleteSelectedEvent): void {
    const val = this.DayOfWeek.find(elt => elt.value === event.option.value);
    if (val) {
      this.days.push(val);
      this._filter();
    }
    this.dayInput.nativeElement.value = '';
    this.dayControl.setValue(null);
  }

  private _filter(){
    const result: {value:string, text: string}[] = [];
    return from(this.DayOfWeek).pipe(
      filter(elt => !this.days.includes(elt))
    ).subscribe(val => {
      result.push(val);
    }, (err) => {
      console.log(err)
    }, () => {
      this.filteredDays = result;
    });
  }
}
