import { Care, CareExecution } from './../../../model/care/care';
import { Component, OnInit, SimpleChanges, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NkapHttpService } from 'src/app/services/nkap-http.service';
import { UserAppService } from 'src/app/services/user-app.service';
import { ButtonsBarComponent, FormAction, IFormActionBarButton } from '../../buttons-bar/buttons-bar.component';
import { NkapValidators } from '../../base/nkap-validators';
import { MedicalAgent } from 'src/app/model/organisation/medical-agent';
import { ActivatedRoute, Router } from '@angular/router';
import { User } from 'src/app/model/security/user';
import { Funtions } from 'src/app/model/security/funtionalities';
import { NkapNotificationService, NotificationType } from 'src/app/services/nkap-notification.service';

@Component({
  selector: 'app-care-execution-edit',
  templateUrl: './care-execution-edit.component.html',
  styleUrls: ['./care-execution-edit.component.css']
})
export class CareExecutionEditComponent implements OnInit {

  buttons: IFormActionBarButton[] = [];
  form: FormGroup;
  currentObject: CareExecution;
  editable: boolean = true;

  agentConnected: MedicalAgent;
  medicalAgentList: MedicalAgent[];

  currentCare: Care;

  userConnected: User;
  
  @ViewChild("btnBar")
  btnBarCmp: ButtonsBarComponent;

  constructor(private formBuilder: FormBuilder,private userService: UserAppService,
    private msgService: NkapNotificationService,
    protected router: Router, protected route: ActivatedRoute, private httpService: NkapHttpService<any>) {
    this.currentObject = new CareExecution();
    this.buttons = [
      {id: "save", value: FormAction.CREATE, icon: {type : 'save'},
        text: 'btn.save.label', disabled: false, functionalities: [Funtions.care_btn_saveResult_fct, Funtions.care_btn_editResult_fct]},
      {id: "list", value: FormAction.VIEW, icon: {type : 'list'},
        text: 'btn.list.label', disabled: false, functionalities: [Funtions.care_btn_list_fct]}
    ];
  }

  public initCareExecutionForm(result: CareExecution): void {
    this.currentObject = result;
    console.log("initCareExecutionForm ", this.currentObject);
    this.form = this.formBuilder.group({
      date: [this.currentObject && this.currentObject.date? this.currentObject.date : new Date(Date.now()), [Validators.required, NkapValidators.notAfterCurrentDay]],
      agent: [this.currentObject && this.currentObject.agent? this.currentObject.agent : this.agentConnected, [Validators.required, NkapValidators.dataWithIdSelected]],
      comment: [this.currentObject? this.currentObject.comment : '', [Validators.required, NkapValidators.notEmpty]],
    });

    this.form.get('agent').valueChanges.subscribe( (inputValue) => {
      if (typeof inputValue === 'string' ) {
        let url = 'organisation/medical-agent';        
        this.httpService.search({value: inputValue}, url).subscribe ( (result) => {
          this.medicalAgentList = result ? result.datas : [];
        });          
      }
    });
    this.form.get('comment').markAsTouched();
    this.form.statusChanges.subscribe( ()=> {
      this.btnStatusChange();
    });
  }

  btnStatusChange() {
    if(this.btnBarCmp){
      const btnStatus = [
        {id: "save", disabled: !this.isValid()},
        {id: "list", disabled: false },
      ];  
      console.log(" btnStatusChange ", btnStatus);  
      this.btnBarCmp.setButtonsStatus(btnStatus);
    }
  }

  ngOnInit() { 
    
    this.route.paramMap.subscribe(params => {
      let data: {id?: string, careId?: string} = (params as any).params;
      console.log(" ngOnInit ", data);
      if(data && data.careId != null) {
        this.httpService.findById(data.careId, "care").subscribe ( (result) => {
          this.currentCare = result;
        });  
      }
      if(data && data.id != null) {
        this.httpService.findById(data.id, "care/execution").subscribe ( (result) => {
          this.initCareExecutionForm(result);
        });  
      } else {
        this.initCareExecutionForm(this.currentObject);  
      }
    });
     
  }

  ngAfterViewInit(): void {
    // Get the MedicalAgent connected
    let userId = this.userService.getUser().number;
    if(userId != null) {
      this.httpService.search({userId: userId ? userId : undefined},`organisation/medical-agent`).subscribe(results => {
        if(results.datas && results.datas.length > 0){
          this.agentConnected = results.datas[0];
          if(this.form.get('agent').invalid) {
            this.form.get('agent').setValue(this.agentConnected);
          }
        }
      });
    }
  }
  
  ngOnChanges(changes: SimpleChanges): void {
    //console.log("changes ", changes);
    if(changes["currentObject"]){
      console.log("changes on currentObject ", changes["currentObject"], this.currentObject);
      this.initCareExecutionForm(this.currentObject);
      this.form.updateValueAndValidity({onlySelf: true, emitEvent: true});
    }
  }
 
  public isValid(): boolean {
    //this.form.updateValueAndValidity({onlySelf:true});
    let isValid = this.form.valid ? true : false;
    console.log("isValid", isValid, this.form);
    if(!this.currentCare || this.currentCare.id ==null){
      isValid == false;
    }
    return isValid;
  }

  public getData(): CareExecution {
    if(!this.currentObject){
      this.currentObject = new CareExecution();
    }
    this.currentObject = Object.assign( this.currentObject, this.form.value);   
    this.currentObject.user = this.userConnected;
    this.currentObject.care = null;    
    return this.currentObject;
  }

  public btnActionClick(event) {
    let i = 0; 
    if(event && event.id === this.buttons[i++].id){
      this.save();
    } else if(event && event.id === this.buttons[i++].id){
      this.router.navigate(["./care/list", {careId: this.currentCare? this.currentCare.id : null, search: true}]);
    }
  }

  save() {
    if(this.isValid()) {
      let toSave = this.getData();
      this.httpService.post(toSave, `care/execution/save/${this.currentCare.id}`, true).subscribe( (result) => {
        this.msgService.log("care-execution.edit.save.succed", NotificationType.SUCCESS);
        this.router.navigate(["./care/list", {careId: this.currentCare? this.currentCare.id : null, search: true}]);
      }, (error) => {
          this.msgService.log(error, NotificationType.ERROR);
      });
    }
  }

  displayMedicalAgent(agent: MedicalAgent) {
    return agent? agent.fullname : '';
  }

}
