import { Component, OnInit, ViewChild, ChangeDetectorRef } from '@angular/core';
import { FormControl } from '@angular/forms';
import { NkapHttpService } from 'src/app/services/nkap-http.service';
import { MatTable, MatInput, MatTableDataSource } from '@angular/material';
import { SelectionModel } from '@angular/cdk/collections';
import { Patient } from 'src/app/model/organisation/patient';
import { BusinessUnit } from 'src/app/model/organisation/business-unit';
import { MedicalAgent } from 'src/app/model/organisation/medical-agent';
import { Person } from 'src/app/model/organisation/person';
import { SearchCriteria } from 'src/app/model/data-page';
import { GlobalVariables } from 'src/app/services/global-variables';
import { IFormActionBarButton, FormAction, ButtonsBarComponent } from '../../buttons-bar/buttons-bar.component';
import { Router, ActivatedRoute } from '@angular/router';
import { NkapNotificationService, NotificationType } from 'src/app/services/nkap-notification.service';
import { Reception, ReceptionStatus, PrestationsToPerformed, ReceptionType } from 'src/app/model/admission/reception';
import * as moment from 'moment';
import { UserAppService } from 'src/app/services/user-app.service';
import { TranslateService } from '@ngx-translate/core';
import { NkapListComponent } from '../../base/nkap-list-component';
import { FileDownloaderComponent } from '../../file-downloader/file-downloader.component';
import { Funtions } from 'src/app/model/security/funtionalities';

@Component({
  selector: 'app-reception-list',
  templateUrl: './reception-list.component.html',
  styleUrls: ['./reception-list.component.css']
})
export class ReceptionListComponent extends NkapListComponent<any>{

  patientSelected: Person;
  patientControl: FormControl = new FormControl();
  patientList: Patient[];

  medicalAgentSelected: MedicalAgent;
  medicalAgentControl = new FormControl();
  medicalAgentList: MedicalAgent[];
  @ViewChild('medicalAgentInput')
  medicalAgentInputCmp: MatInput;

  serviceSelected: BusinessUnit;
  serviceControl = new FormControl();
  serviceList: BusinessUnit[];
  @ViewChild('serviceInput')
  serviceInputCmp: MatInput;

  @ViewChild("btns")
  protected btnsCmp: ButtonsBarComponent;

  protected startDateCtrl: FormControl = new FormControl();

  /**
   * A reference on download component
   */
  @ViewChild("downloader")
  downloaderComponent: FileDownloaderComponent;
  openFileInTab: boolean = true;

  protected statusList = [
    {value: null, text:""},
    {value:ReceptionStatus[ReceptionStatus.PENDING], text:"ReceptionStatus."+ReceptionStatus[ReceptionStatus.PENDING]+".label"},
    {value:ReceptionStatus[ReceptionStatus.CAN_BE_PROCESS], text:"ReceptionStatus."+ReceptionStatus[ReceptionStatus.CAN_BE_PROCESS]+".label"},
    {value:ReceptionStatus[ReceptionStatus.CLOSED], text:"ReceptionStatus."+ReceptionStatus[ReceptionStatus.CLOSED]+".label"}
  ];

  constructor(protected _ref: ChangeDetectorRef, protected httpService: NkapHttpService<any>, 
    protected router: Router, protected route: ActivatedRoute,protected userService: UserAppService,
    protected msgService: NkapNotificationService, protected translateService: TranslateService) {
      super(_ref, httpService, router, route, userService, msgService, translateService);
      this.searchBaseUrl = "admission/reception";
    this.buttons = [
      {id: 'edit', value: FormAction.UPDATE, icon: {type : 'edit',color:'#ff9933'}, 
        text: 'reception.btn.edit.label', disabled: false, functionalities: [Funtions.reception_btn_edit_fct]},
        {id: "print", value: FormAction.PRINT, icon: {type : 'print',color:'#ff9933'}, 
            text: 'patient.form.btn.print.label', disabled: false, functionalities: [Funtions.patient_form_btn_print_fct]} , 
        {id: 'care_coverage', value: FormAction.UPDATE, icon: {type :'edit',color:'#ff9933'},
           text: 'reception.btn.care-coverage.label', disabled: false, functionalities: [Funtions.reception_btn_edit_fct, Funtions.careCoverageDoc_btn_new_fct]},
      /*{id: 'cancel', value: FormAction.CANCEL, icon: {type :'cancel',color:'#ff9933'},
         text: 'reception.btn.cancel.label', disabled: false},
      {id: "mark_achived", value:"", icon: {type : 'done',color:'#009900'}, 
        text: 'reception.btn.close.label', disabled: false}*/
      {id: "mark_achived", value:FormAction.CLOSE, icon: {type : 'assignment',color:'#009900'}, 
        text: 'reception.btn.consultation-sheet.label', disabled: false,functionalities:[Funtions.reception_btn_close_fct]},
        {id: "allow_exam", value:FormAction.CLOSE, icon: {type : 'assignment',color:'#009900'}, 
        text: 'reception.btn.allow-exam.label', disabled: true,functionalities:[Funtions.reception_btn_allow_exam_fct]}
    ];
      
  }

  ngOnInit() {
    // By default we render receptions of the current day
    if(this.criteria.period == null) {
      this.criteria.period = {};
    }
    const currentDate = moment.utc(new Date()).startOf('day').toDate();// moment(moment.utc(new Date()).startOf('day').format('LL')).startOf('day').toDate();
    this.criteria.period.start = currentDate;
   // console.log(" this.criteria.period.start ", this.criteria.period.start);
    super.ngOnInit();

    this.patientControl.valueChanges.subscribe( (inputValue) => {
      if (typeof inputValue === 'string' ) {
        this.patientSelected = null;
        this.httpService.search({value: inputValue}, 'organisation/patient').subscribe ( (result) => {
           this.patientList = result ? result.datas : [];
        });
       } else {
         this.patientSelected = inputValue;
       }
      this.criteria.patientId = this.patientSelected? this.patientSelected.id : undefined;
    });

    this.medicalAgentControl.valueChanges.subscribe( (inputValue) => {
      if (typeof inputValue === 'string' ) {
        this.medicalAgentSelected = null;
        this.httpService.search({value: inputValue}, 'organisation/medical-agent').subscribe ( (result) => {
           this.medicalAgentList = result ? result.datas : [];
        });
       } else {
         this.medicalAgentSelected = inputValue;
       }
      this.criteria.medicalAgentId = this.medicalAgentSelected? this.medicalAgentSelected.id : undefined;
    });

    this.serviceControl.valueChanges.subscribe( (inputValue) => {
      if (typeof inputValue === 'string' ) {
        this.serviceSelected = null;
        this.httpService.search({value: inputValue}, 'organisation/business-unit').subscribe ( (result) => {
           this.serviceList = result ? result.datas : [];
         });
       } else {
         this.serviceSelected = inputValue;
       }
      this.criteria.unitId = this.serviceSelected? this.serviceSelected.id : undefined;
    });
  }

  formatReceptionStatus(status) {
    return status ? 'ReceptionStatus.' + status + '.label' : 'ReceptionStatus.' + ReceptionStatus[ReceptionStatus.PENDING] + '.label';
  }

  displayPersonFn(agent?: Person): string  {
    return agent ? `${agent.fullname}` : '';
  }

  displayServiceFn(service?: BusinessUnit): string {
    return service ? `${service.name}` : '';
  }

  displayDirectionFn(prestToPerformed: PrestationsToPerformed) {
    let str = "";
    if(prestToPerformed != null){
      str += prestToPerformed.prestation ? `${prestToPerformed.prestation.name} ` : '';
      str += prestToPerformed.agent ? `(${this.displayPersonFn(prestToPerformed.agent)} ` : '';
      str += !prestToPerformed.agent && prestToPerformed.unit ?
       `${prestToPerformed.agent ? '':'('} ${this.displayServiceFn(prestToPerformed.unit)} ` : '';
      str += prestToPerformed.unit || prestToPerformed.agent ? ')' : '';
    }
    return str;
  }
 
  formatDate(date){
    try {
        return "[" + moment(new Date(date)).utc().format("HH:mm") + "] " + moment(new Date(date)).format("LL");
    } catch (e) {
      console.error("Parse date in reception-list fail " , e);
    }
  }

  btnActionClick(event) {
    if(event && event.id === this.buttons[0].id){
      if( !this.selection.selected || this.selection.selected.length != 1){
        this.msgService.log('reception.list.select.to.modify.error', NotificationType.ERROR);
      }else{
        this.router.navigate(['./reception/home', {id: this.selection.selected[0].id}]);
      }
    } else if(event && event.id === this.buttons[2].id){
      if( !this.selection.selected || this.selection.selected.length < 1){
        this.msgService.log('reception.list.select.to.cancel.error', NotificationType.ERROR);
      }else{
        this.router.navigate(['./reception/care-coverage', {receptionId: this.selection.selected[0].id}]);
      }
    }/*else if(event && event.id === this.buttons[2].id){
      if( !this.selection.selected || this.selection.selected.length < 1){
        this.msgService.log('reception.list.select.to.mark.closed.error', NotificationType.ERROR);
      }else{
        // TODO mark reception(s) as achived
      }
    }*/else if(event && event.id === this.buttons[1].id){
      if( !this.selection.selected || this.selection.selected.length < 1){
        this.msgService.log("medical-folder.list.select.to.print.error", NotificationType.ERROR);
      }else{
        const patientFolderId = this.selection.selected[0].patientFolderId;
        this.print(patientFolderId);
      }
    }
    if(event && event.id === this.buttons[3].id){
      if( !this.selection.selected || this.selection.selected.length != 1){
        this.msgService.log('reception.list.select.to.modify.error', NotificationType.ERROR);
      }else{
        this.router.navigate(['./reception/consultation-sheet', {id: this.selection.selected[0].id}]);
      }
    }
    if(event && event.id === this.buttons[4].id){
      if( !this.selection.selected || this.selection.selected.length != 1){
        this.msgService.log('reception.list.select.to.modify.error', NotificationType.ERROR);
      }else{
        this.generateExams();        
      }
    }
  }

  deleteSelected(){
    this.msgService.confirm("reception.delete.confirm.text").subscribe( (result) => {
      if(result === true){
          let prestationSelected = this.selection.selected[0];
          this.httpService.deleteById(prestationSelected.id, 'admission/reception').subscribe( (val) => {
            this.msgService.log("reception.delete.succed", NotificationType.SUCCESS);
            this.searchClicked({});
          }, (error) => {
            this.msgService.log("reception.delete.fail", NotificationType.ERROR);
          });
        }
      });
  }

  generateExams(){
    this.msgService.confirm("reception.generate.exam.confirm.text").subscribe( (result) => {
      if(result === true){
          let selected = this.selection.selected[0];
          this.httpService.get(`exam/generate-by-reception-id/${selected.id}`).subscribe( (val) => {
            this.msgService.log("reception.exam.generated.succed", NotificationType.SUCCESS);
            this.searchClicked({});
          }, (error) => {
            this.msgService.log("reception.exam.generated.fail", NotificationType.ERROR);
          });
        }
      });
  }

  protected startDateSelected(event) {
    if(!this.criteria.period){
      this.criteria.period = {};
    }
    this.criteria.period.start = event.value;
  }

  protected endDateSelected(event) {
    if(!this.criteria.period){
      this.criteria.period = {};
    }
    this.criteria.period.end = event.value;
  }

  protected viewElement(element) {
    this.router.navigate(['./reception/show', {id: element.id}]);
  }
  
  protected setCriteriaDataInView(cachedNavigationData: any) {
    super.setCriteriaDataInView(cachedNavigationData);
    if(cachedNavigationData != null ) {
      if(cachedNavigationData.patient != null) {
        this.patientControl.setValue(cachedNavigationData.patient);
      }
      if(cachedNavigationData.medicalAgent != null) {
        this.medicalAgentControl.setValue(cachedNavigationData.medicalAgent);
      }
      if(cachedNavigationData.service != null) {
        this.serviceControl.setValue(cachedNavigationData.service);
      }
      if(cachedNavigationData.criteria != null && cachedNavigationData.criteria.period != null) {
       this.criteria = Object.assign(this.criteria, {period: cachedNavigationData.criteria.period});
      }
    }
  }

  protected getNavigationDataToStore(): any {
    let data = super.getNavigationDataToStore();
    return Object.assign( data != null ? data : {}, { 
      patient: this.patientControl.value,
      medicalAgent: this.medicalAgentControl.value,
      service: this.serviceControl.value
    });
  }

  listSelectionChanged(){
    super.listSelectionChanged();
    if(this.btnsCmp){
      const newStatus = [
        {id: 'edit', disabled: 
                    (this.selection.selected &&
                     this.selection.selected.length == 1 && 
                     this.selection.selected[0].status == ReceptionStatus[ReceptionStatus.PENDING]
                     ) ? false : true
                     , functionalities: this.buttons[0].functionalities
        },
        {id: 'print', disabled: 
                    (this.selection.selected &&
                     this.selection.selected.length == 1
                     ) ? false : true
                     , functionalities: this.buttons[1].functionalities
        },
        {id: 'care_coverage',  disabled: 
                    (this.selection.selected &&
                    this.selection.selected.length == 1 && 
                    this.selection.selected[0].status == ReceptionStatus[ReceptionStatus.PENDING]
                    ) ? false : true
                    , functionalities: this.buttons[2].functionalities
        },
        {id: 'mark_achived',  disabled: 
                    (this.selection.selected &&
                    this.selection.selected.length == 1 && 
                    (this.selection.selected[0].status == ReceptionStatus[ReceptionStatus.PENDING] 
                      || this.selection.selected[0].status == ReceptionStatus[ReceptionStatus.CAN_BE_PROCESS])&&
                    this.selection.selected[0].type == ReceptionType[ReceptionType.CONSULTATION]
                    ) ? false : true
                    , functionalities:this.buttons[3].functionalities
        },
        {id: 'allow_exam',  disabled: 
                    (this.selection.selected &&
                    this.selection.selected.length == 1 && 
                    this.selection.selected[0].status == ReceptionStatus[ReceptionStatus.PENDING] &&
                    this.selection.selected[0].type == ReceptionType[ReceptionType.EXAMS]
                    ) ? false : true
        }

      ];
      //console.log("listSelectionChanged " ,newStatus);
      this.btnsCmp.setButtonsStatus(newStatus);
    }
  }

  print(id) {
    this.criteria.folderId=id;
    this.criteria.lang= this.translateService.currentLang;
    this.downloaderComponent.criteria=this.criteria;
    this.downloaderComponent.link = 'admission/medical-folder/print';
    this.downloaderComponent.downloadFile({});
    this.downloaderComponent.event.subscribe( (whellDone) => {
      if (whellDone == false) {
        this.msgService.log("form.print.error", NotificationType.ERROR);
      }
    });
  }

}
