import { Component, OnInit, ViewChild, ChangeDetectorRef } from '@angular/core';
import { FormControl } from '@angular/forms';
import { NkapHttpService } from 'src/app/services/nkap-http.service';
import { Apointment, ApointmentStatus } from 'src/app/model/admission/apointment';
import { MatTable, MatInput, MatTableDataSource } from '@angular/material';
import { SelectionModel } from '@angular/cdk/collections';
import { Patient } from 'src/app/model/organisation/patient';
import { BusinessUnit } from 'src/app/model/organisation/business-unit';
import { MedicalAgent } from 'src/app/model/organisation/medical-agent';
import { Person } from 'src/app/model/organisation/person';
import { SearchCriteria } from 'src/app/model/data-page';
import { GlobalVariables } from 'src/app/services/global-variables';
import { IFormActionBarButton, FormAction, ButtonsBarComponent } from '../../buttons-bar/buttons-bar.component';
import { Router, ActivatedRoute } from '@angular/router';
import { NkapNotificationService, NotificationType } from 'src/app/services/nkap-notification.service';
import * as moment from 'moment';
import { UserAppService } from 'src/app/services/user-app.service';
import { Timestamp } from 'rxjs/internal/operators/timestamp';
import { TranslateService } from '@ngx-translate/core';
import { NkapListComponent } from '../../base/nkap-list-component';
import { Funtions } from 'src/app/model/security/funtionalities';
@Component({
  selector: 'app-apointment-list',
  templateUrl: './apointment-list.component.html',
  styleUrls: ['./apointment-list.component.css']
})
export class ApointmentListComponent extends NkapListComponent<any> {

  patientSelected: Person;
  patientControl: FormControl = new FormControl();
  patientList: Patient[];

  medicalAgentSelected: MedicalAgent;
  medicalAgentControl = new FormControl();
  medicalAgentList: MedicalAgent[];
  @ViewChild('medicalAgentInput')
  medicalAgentInputCmp: MatInput;

  serviceSelected: BusinessUnit;
  serviceControl = new FormControl();
  serviceList: BusinessUnit[];
  @ViewChild('serviceInput')
  serviceInputCmp: MatInput;

  statusList: {value: string , text: string}[];

  @ViewChild("btnBar")
  btnBarCmp: ButtonsBarComponent;

  constructor(protected _ref: ChangeDetectorRef, protected httpService: NkapHttpService<any>, 
    protected router: Router, protected route: ActivatedRoute, protected userService: UserAppService,
    protected msgService: NkapNotificationService, protected translateService: TranslateService) {
   super(_ref, httpService, router, route, userService, msgService, translateService);
      this.searchBaseUrl = "admission/apointment";
    this.buttons = [
      {id: "add_new", value: FormAction.CREATE, icon: {type : 'add_box', color: '#0099cc'},
        text: 'apointment.btn.new.label', disabled: false, functionalities: [Funtions.apointment_btn_new_fct]},
      {id: "edit", value: FormAction.UPDATE, icon: {type : 'edit',color:'#ff9933'}, 
        text: 'apointment.btn.edit.label', disabled: false, functionalities: [Funtions.apointment_btn_edit_fct]},
      {id: "cancel", value: FormAction.CANCEL, icon: {type :'cancel',color:'#ff9933'},
         text: 'apointment.btn.cancel.label', disabled: false, functionalities: ['apointment.btn.cancel.label']},
      {id: "mark_achived", value:"", icon: {type : 'done',color:'#009900'}, 
        text: 'apointment.btn.achieved.label', disabled: false, functionalities: [Funtions.apointment_btn_achieved_fct]}
    ];

    this.statusList = [
      {value: "", text: ""},
      {value: ApointmentStatus[ApointmentStatus.PENDING], text:"ApointmentStatus." + ApointmentStatus[ApointmentStatus.PENDING] + ".label"},
      {value: ApointmentStatus[ApointmentStatus.CONFIRM], text:"ApointmentStatus." + ApointmentStatus[ApointmentStatus.CONFIRM] + ".label"},
      {value: ApointmentStatus[ApointmentStatus.CANCEL], text:"ApointmentStatus." + ApointmentStatus[ApointmentStatus.CANCEL] + ".label"},
      {value: ApointmentStatus[ApointmentStatus.POST_PONNED], text:"ApointmentStatus." + ApointmentStatus[ApointmentStatus.POST_PONNED] + ".label"},
      {value: ApointmentStatus[ApointmentStatus.ACHIVED], text:"ApointmentStatus." + ApointmentStatus[ApointmentStatus.ACHIVED] + ".label"}
    ];
  }

  ngOnInit() {
    if(this.criteria.period == null) {
      this.criteria.period = {};
    }
    const currentDate = moment.utc(new Date()).startOf('day').toDate();
    this.criteria.period.start = currentDate;
    super.ngOnInit();
    
    this.patientControl.valueChanges.subscribe( (inputValue) => {
      if (!inputValue || typeof inputValue === 'string' ) {
        this.patientSelected = null;
        this.httpService.search({value: inputValue}, 'organisation/patient').subscribe ( (result) => {
           this.patientList = result ? result.datas : [];
        });
       } else {
         this.patientSelected = inputValue;
       }
      this.criteria.patientId = this.patientSelected? this.patientSelected.id : undefined;
    });

    this.medicalAgentControl.valueChanges.subscribe( (inputValue) => {
      if (!inputValue || typeof inputValue === 'string' ) {
        this.medicalAgentSelected = null;
        this.httpService.search({value: inputValue}, 'organisation/medical-agent').subscribe ( (result) => {
           this.medicalAgentList = result ? result.datas : [];
        });
       } else {
         this.medicalAgentSelected = inputValue;
       }
      this.criteria.medicalAgentId = this.medicalAgentSelected? this.medicalAgentSelected.id : undefined;
    });

    this.serviceControl.valueChanges.subscribe( (inputValue) => {
      if (!inputValue || typeof inputValue === 'string' ) {
        this.serviceSelected = null;
        this.httpService.search({value: inputValue}, 'organisation/business-unit').subscribe ( (result) => {
           this.serviceList = result ? result.datas : [];
         });
       } else {
         this.serviceSelected = inputValue;
       }
      this.criteria.unitId = this.serviceSelected? this.serviceSelected.id : undefined;
    });
  }

  formatApointmentStatus(status) {
    return status ? "ApointmentStatus." + status + ".label" : "ApointmentStatus." + ApointmentStatus[ApointmentStatus.PENDING] + ".label";
  }

  displayPersonFn(agent?: Person): string  {
    return agent ? `${agent.fullname}` : '';
  }

  displayServiceFn(service?: BusinessUnit): string {
    return service ? `${service.name}` : '';
  }
 
  btnActionClick(event) {
    if(event && event.id === this.buttons[0].id){
      this.router.navigate(["apointment/edit"]);
    } else if(event && event.id === this.buttons[1].id){
      if( !this.selection.selected || this.selection.selected.length != 1){
        this.msgService.log("apointment.list.select.to.modify.error", NotificationType.ERROR);
      }else{
        this.router.navigate(["apointment/edit", {id: this.selection.selected[0].id}]);
      }
    } else if(event && event.id === this.buttons[2].id){
      if( !this.selection.selected || this.selection.selected.length < 1){
        this.msgService.log("apointment.list.select.to.cancel.error", NotificationType.ERROR);
      }else{
        // TODO cancel apointment(s)
      }
    }else if(event && event.id === this.buttons[3].id){
      if( !this.selection.selected || this.selection.selected.length < 1){
        this.msgService.log("apointment.list.select.to.mark.achieved.error", NotificationType.ERROR);
      }else{
        // TODO mark apointment(s) as achived
      }
    }
  }

  protected startDateSelected(event) {
    if(!this.criteria.period){
      this.criteria.period = {};
    }
    this.criteria.period.start = event.value;
  }

  protected endDateSelected(event) {
    if(!this.criteria.period){
      this.criteria.period = {};
    }
    this.criteria.period.end = event.value;
  }

  protected viewElement(element) {
    this.router.navigate(['apointment/show', {id: element.id}]);
  }

  formatDate(date){
    try{
      
    return moment(new Date(date)).format("LL");
    }catch(e){
      console.error("Parse date in reception-list fail " , e);
    }
  }

  formatHour(startTime){
   try{
    return moment(new Date((new Date().getFullYear())+"-01-01T" + `${startTime}`)).format("h:mm");
    }catch(e){
      console.error("Parse date in reception-list fail " , e);
    }
  }

  listSelectionChanged(){
    super.listSelectionChanged();
    if(this.btnBarCmp){
      const modifiable = (this.selection.selected &&
        this.selection.selected.length == 1 && 
        (this.selection.selected[0].status == ApointmentStatus[ApointmentStatus.PENDING] ||
          this.selection.selected[0].status == ApointmentStatus[ApointmentStatus.POST_PONNED])
        );
      const btnStatus = [
        {id: "add_new", disabled: false, functionalities: this.buttons[0].functionalities},
        {id: "edit", disabled: !modifiable, functionalities: this.buttons[1].functionalities},
        {id: "cancel", disabled: !modifiable, functionalities: this.buttons[2].functionalities},
        {id: "mark_achived", disabled: !modifiable, functionalities: this.buttons[3].functionalities},
      ];    
      this.btnBarCmp.setButtonsStatus(btnStatus);
    }
  }

  protected setCriteriaDataInView(cachedNavigationData: any) {
    super.setCriteriaDataInView(cachedNavigationData);
    if(cachedNavigationData == null) { return ;}
    if( cachedNavigationData.patient != null) {
      this.patientControl.setValue(cachedNavigationData.patient);
    }
    if( cachedNavigationData.medicalAgent != null) {
      this.medicalAgentControl.setValue(cachedNavigationData.medicalAgent);
    }
    if( cachedNavigationData.service != null) {
      this.serviceControl.setValue(cachedNavigationData.service);
    }
  }

  protected getNavigationDataToStore(): any {
    let data = super.getNavigationDataToStore();
    return Object.assign( data != null ? data : {}, {
      patient: this.patientControl.value,
      medicalAgent: this.medicalAgentControl.value,
      service: this.serviceControl.value
    });
  }
}
