import { Component, OnInit, Input, OnChanges, SimpleChanges } from '@angular/core';
import { Company } from 'src/app/model/organisation/party';
import { FormControl, Validators, FormBuilder, FormGroup, AbstractControl } from '@angular/forms';
import { NkapValidators } from '../../base/nkap-validators';

@Component({
  selector: 'app-company-identification',
  templateUrl: './company-identification.component.html',
  styleUrls: ['./company-identification.component.css']
})
export class CompanyIdentificationComponent implements OnInit, OnChanges {
  
  @Input()
  protected company: Company;

  companyIdentificationForm: FormGroup;

  constructor(private fb: FormBuilder) { 
    this.initForm();
  }

  protected initForm() {
    this.companyIdentificationForm = this.fb.group({
      name: ['',  [Validators.required, NkapValidators.notEmpty, Validators.maxLength(255)]],
      shortName: [''],
      vatNumber: ['']
    });
  }

  ngOnInit() {
    this.company = new Company();
    this.companyIdentificationForm.patchValue(this.company);
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.company) {
      if(this.company) {
        this.companyIdentificationForm.patchValue(this.company);
      } else {
        this.initForm();
      }
    }
  }

  public isValid(): boolean {
      this.companyIdentificationForm.get('name').markAsTouched({onlySelf: false});
      return this.companyIdentificationForm.invalid === true ? false : true;
  }

  public getData(): Company {
    let company: Company = this.companyIdentificationForm.value;
    company.toAddOrUpdate = true;
    company.id = this.company.id;
    return company;
  }
}
