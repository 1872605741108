import { MedicalAgent } from 'src/app/model/organisation/medical-agent';
import { FormAction, ButtonsBarComponent, IFormActionBarButton } from 'src/app/components/buttons-bar/buttons-bar.component';
import { MedicalPrescription, MedicalPrescriptionItem, Drug, PrestationStatus } from 'src/app/model/prescriptions/prescription';
import { Component, OnInit, ChangeDetectorRef, ViewChild, ElementRef } from '@angular/core';
import { NkapListComponent } from 'src/app/components/base/nkap-list-component';
import { NkapHttpService } from 'src/app/services/nkap-http.service';
import { ActivatedRoute, Router } from '@angular/router';
import { UserAppService } from 'src/app/services/user-app.service';
import { TranslateService, TranslatePipe } from '@ngx-translate/core';
import { NkapNotificationService, NotificationType } from 'src/app/services/nkap-notification.service';
import { Funtions } from 'src/app/model/security/funtionalities';
import * as moment from 'moment';
import { FileDownloaderComponent } from 'src/app/components/file-downloader/file-downloader.component';
import { FormControl } from '@angular/forms';
import { MatAutocompleteSelectedEvent } from '@angular/material';
import { Observable } from 'rxjs';
import { ENTER, COMMA } from '@angular/cdk/keycodes';
import { SearchCriteria } from 'src/app/model/data-page';
import { GenderType, Person } from 'src/app/model/organisation/person';
import { Party } from 'src/app/model/organisation/party';

@Component({
  selector: 'app-medical-prescription-list',
  templateUrl: './medical-prescription-list.component.html',
  styleUrls: ['./medical-prescription-list.component.css']
})
export class MedicalPrescriptionListComponent extends NkapListComponent<any> {

  /**
   * A reference on download component
   */
  @ViewChild("downloader")
  downloaderComponent: FileDownloaderComponent;
  openFileInTab = true;

  @ViewChild("btnBar")
  btnBarCmp: ButtonsBarComponent;

  translate: TranslatePipe;

  agentSelected: MedicalAgent;
  medicalAgentControl: FormControl = new FormControl();
  medicalAgentList: MedicalAgent[];

  //drugsList: Drug[] = [];
  drugControl: FormControl = new FormControl();
  drugsSelectedList: Drug[];
  drugsList: Drug[];
  @ViewChild('drugsInput')
  drugInput: ElementRef<HTMLInputElement>;

  separatorKeysCodes: number[] = [ENTER, COMMA];

  statusList: {value: any, text: string} [];

  protected FEMALE = GenderType[GenderType.FEMALE];
  protected MALE = GenderType[GenderType.MALE];
  
  constructor(protected _ref: ChangeDetectorRef, protected httpService: NkapHttpService<any>, 
    protected router: Router, protected route: ActivatedRoute,protected userService: UserAppService,
    protected msgService: NkapNotificationService, protected translateService: TranslateService) {
      super(_ref, httpService,router,route,userService,msgService, translateService);
      this.searchBaseUrl = "prescription/medical-prescription";
      this.criteria = new SearchCriteria();
      this.criteria.pageSize = this.paginatorParams.pageSize;
      this.criteria.period = {};
      this.drugsSelectedList = [];
      this.buttons = [
        {id: "add", value: FormAction.CREATE, icon: {type : 'add_circle_outline', color: '#0099cc'},
          text: 'btn.new.label', disabled: false, functionalities: [Funtions.medicalPrescription_btn_new_fct]},
        {id: "renew", value: FormAction.CREATE, icon: {type : 'add_circle_outline', color: '#0099cc'},
          text: 'medical-prescription.btn.renew.label', disabled: false, functionalities: [Funtions.medicalPrescription_btn_new_fct]},
        /*{id: "edit", value: FormAction.CREATE, icon: {type : 'edit', color: '#ff9933'},
          text: 'medical-prescription.btn.edit.label', disabled: false, functionalities: [Funtions.medicalPrescription_btn_edit_fct]},
        */{id: "print", value: FormAction.CREATE, icon: {type : 'print', color: '#ff9933'},
          text: 'btn.print.label', disabled: false, functionalities: [Funtions.medicalPrescription_btn_print_fct]},
        {id: "close", value: FormAction.CREATE, icon: {type : 'delete', color: '#ff9933'},
          text: 'medical-prescription.btn.close.label', disabled: false, functionalities: [Funtions.medicalPrescription_btn_close_fct]},
        {id: "delete", value: FormAction.CREATE, icon: {type : 'delete', color: '#ff9933'},
          text: 'medical-prescription.btn.delete.label', disabled: false, functionalities: [Funtions.medicalPrescription_btn_delete_fct]},
      ];
      this.statusList = [
        {value:null, text: ""},
        {value:PrestationStatus.VALID, text:"medical-prescription.status.applicable.label"},
        {value:PrestationStatus.INVALID, text:"medical-prescription.status.invalid.label"},
        {value:PrestationStatus.EXPIRED, text:"medical-prescription.status.expired.label"}
      ];
  }

  ngOnInit() {
    super.ngOnInit();
    this.drugControl.valueChanges.subscribe( (elmt) => {
      if(typeof elmt == "string"){
        let criteria = new SearchCriteria();
        criteria.value = elmt;
        this.httpService.search(criteria, "prescription/drug").subscribe( (result)=>{
          this.drugsList = result ? result.datas : [];
        });
      }
    });
    this.medicalAgentControl.valueChanges.subscribe( (inputValue) => {
      if (typeof inputValue === 'string' ) {
        this.criteria.medicalAgentId = null;
        this.httpService.search({value: inputValue}, 'organisation/medical-agent').subscribe ( (result) => {
           this.medicalAgentList = result ? result.datas : [];
        });
       } else {
        this.criteria.medicalAgentId = inputValue.id;
       }
    });
  }

  displayCodeAndPrescriptionDate(element: MedicalPrescription) {
    let result = "" ;
    if(element){
      result = `${element.prescriptionCode != null ? element.prescriptionCode : ''} 
          ${element.expirationDate != null ? '; ' + moment(new Date(element.expirationDate)).format("L") : ''}`;
    }
    return result;
  }

  displayStatus(element: MedicalPrescription) {
    let status = "";
    if(element.stillValid === false) {
      status = "medical-prescription.status.invalid.label";
    } else if(element.expirationDate != null && moment(Date.now()).isAfter(element.expirationDate) == true) {
      status = "medical-prescription.status.expired.label";
    } else {
      status = "medical-prescription.status.applicable.label";
    }
    return status
  }

  displayDrug(prescriptionItem: MedicalPrescriptionItem) {
    if(prescriptionItem && prescriptionItem.drug){
      return prescriptionItem.drug.name;
    }
  }

  displayPatient(element: any) {
    if(element && element.patient){
      return element.patient.fullname;
    }
  }

  displayDate( element: MedicalPrescription) {
    if(element && element.date){
      //console.log("displayDate ", this.lang);
      moment.locale(this.lang);
      return moment(new Date(element.date)).utc().format("llll") ;
    }
  }

  formatDate(date){
    try {
        return moment(new Date(date)).format("LL") + " [" + moment(new Date(date)).utc().format("HH:mm") + "]";
    } catch (e) {
      console.error("Parse date in reception-list fail " , e);
    }
  }

  displayMedicalAgent(agent: MedicalAgent) {
    return agent? agent.fullname : '';
  }

  print(id) {
    // console.log("print ", id);
    let criteria = new SearchCriteria();
    criteria.lang= this.translateService.currentLang;
    criteria.id = id;
    this.downloaderComponent.criteria=criteria;
    this.downloaderComponent.link = 'prescription/medical-prescription/print';
    this.downloaderComponent.downloadFile({},true);
    this.downloaderComponent.event.subscribe( (whellDone) => {
      if (whellDone == false) {
        this.msgService.log("form.print.error", NotificationType.ERROR);
      }
    });
  }

  protected search(criteria: any) {
    criteria.drugIds = [];
    this.drugsSelectedList.forEach( (elm)=> {
      criteria.drugIds.push(elm.id);
    });
    super.search(criteria);    
  }

  listSelectionChanged(){
    super.listSelectionChanged();
    if(this.btnBarCmp){
      let lineSelected = (this.selection && this.selection.selected && this.selection.selected.length > 0);
      let selectedLine: MedicalPrescription = lineSelected == true ? this.selection.selected[0] : null;
      const btnStatus = [
        {id: "add", disabled: false},
        {id: "renew", disabled: !lineSelected},
        {id: "edit", disabled: !lineSelected},
        {id: "delete", disabled: !lineSelected},
        {id: "print", disabled: (!lineSelected || selectedLine.stillValid == false)},
        {id: "close", disabled: (!lineSelected || selectedLine.stillValid == false)},
      ];    
      this.btnBarCmp.setButtonsStatus(btnStatus);
    }
  }

  btnActionClick(event) {
    let i = 0; 
    if(event && event.id === this.buttons[i++].id){
      let lineSelected: MedicalPrescription = this.selection && this.selection.selected && this.selection.selected.length > 0 ? this.selection.selected[0] : null;
      if(lineSelected == null){
        lineSelected = this.tableDataSource && this.tableDataSource.data && this.tableDataSource.data.length == 1 ? this.tableDataSource.data[0] : null;
      }
      //console.log('lineSelected ', lineSelected);
      if(lineSelected != null) {
        this.router.navigate(["./medical-prescription/edit",{medicalFolderId: lineSelected.medicalFolder.id, selectPatient:true}]);
      } else {
        this.router.navigate(["./medical-prescription/edit"]);
      }      
    } else if(event && event.id === this.buttons[i++].id){
      if( !this.selection.selected || this.selection.selected.length != 1){
        this.msgService.log("medical-prescription.list.select.to.modify.error", NotificationType.ERROR);
      }else{
        this.router.navigate(["./medical-prescription/edit", {id: this.selection.selected[0].id, action:MedicalPrescription.RENEW_ACTION}]);
      }
    }/* else if(event && event.id === this.buttons[i++].id){
      if( !this.selection.selected || this.selection.selected.length != 1){
        this.msgService.log("medical-prescription.list.select.to.modify.error", NotificationType.ERROR);
      }else{
        this.router.navigate(["medical-prescription/edit", {id: this.selection.selected[0].id, action:MedicalPrescription.REVISE_ACTION}]);
      }
    }*/ else if(event && event.id === this.buttons[i++].id) {
      if( !this.selection.selected || this.selection.selected.length < 1){
        this.msgService.log("medical-prescription.list.select.to.delete.error", NotificationType.ERROR);
      }else{
        let selected = this.selection.selected[0];
        // console.log( "selected ", selected);
        this.print(selected.id);
      }
    }else if(event && event.id === this.buttons[i++].id){
      if( !this.selection.selected || this.selection.selected.length < 1){
        this.msgService.log("medical-prescription.list.select.to.delete.error", NotificationType.ERROR);
      }else{
        this.closeSelected();
      }
    } else if(event && event.id === this.buttons[i++].id){
      if( !this.selection.selected || this.selection.selected.length < 1){
        this.msgService.log("medical-prescription.list.select.to.delete.error", NotificationType.ERROR);
      }else{
        this.deleteSelected();
      }
    } 
  }

  protected startDateSelected(event) {
    if(!this.criteria.period){
      this.criteria.period = {};
    }
    this.criteria.period.start = event.value;
  }

  protected endDateSelected(event) {
    if(!this.criteria.period){
      this.criteria.period = {};
    }
    this.criteria.period.end = event.value;
  }

  protected viewElement(element) {
    this.router.navigate(['./medical-prescription/show', {id: element.id}]);
  }

  deleteSelected(){
    this.msgService.confirm(this.translate.transform("medical-prescription.delete.confirm.text")).subscribe( (result) => {
      if(result === true){
          let selected = this.selection.selected[0];
          this.httpService.deleteById(selected.id, 'prescription/medical-prescription').subscribe( (val) => {
            this.msgService.log("prescription.delete.succed", NotificationType.SUCCESS);
            this.searchClicked({});
          }, (error) => {
            this.msgService.log("prescription.delete.fail", NotificationType.ERROR);
          });
        }
      });
  }

  closeSelected(){
    this.msgService.confirm(this.translate.transform("medical-prescription.close.confirm.text")).subscribe( (result) => {
      if(result === true){
          let selected = this.selection.selected[0];
          this.httpService.post({id:selected.id}, 'prescription/medical-prescription/close').subscribe( (val) => {
            this.msgService.log("medical-prescription.close.succed", NotificationType.SUCCESS);
            this.searchClicked({});
          }, (error) => {
            this.msgService.log("medical-prescription.close.fail", NotificationType.ERROR);
          });
        }
      });
  }
  
  protected setCriteriaDataInView(cachedNavigationData: any) {
    super.setCriteriaDataInView(cachedNavigationData);
    if(cachedNavigationData != null && cachedNavigationData.medicalAgent != null) {
      this.medicalAgentControl.setValue(cachedNavigationData.medicalAgent);
    }
    if(cachedNavigationData != null && cachedNavigationData.drugs != null) {
      this.drugsSelectedList = [].concat(cachedNavigationData.drugs);
    }
    if(cachedNavigationData != null && cachedNavigationData.criteria != null && cachedNavigationData.criteria.period != null) {
      this.criteria = Object.assign(this.criteria, {period: cachedNavigationData.criteria.period});
    }
  }

  protected getNavigationDataToStore(): any {
    let data = super.getNavigationDataToStore();
    return Object.assign( data != null ? data : {}, {
       medicalAgent: this.medicalAgentControl.value,
       drugs: this.drugsSelectedList
    });
  }

  removeInDrus(drug) {
    if (drug) {
      const index = this.drugsSelectedList.findIndex( (elmt) => elmt.id == drug.id);
      if (index != -1) {
        this.drugsSelectedList.splice(index, 1);
      }
    }
  }

  selectedDrugs(event: MatAutocompleteSelectedEvent) {
    let selected = event.option.value;
    // console.log("selectedDrugs ", selected);
    if(selected != null && this.drugsSelectedList != null && this.drugsSelectedList.findIndex( (elm)=> elm.id == selected.id ) == -1) {
      this.drugsSelectedList = this.drugsSelectedList.concat([selected]);
    }
    this.drugInput.nativeElement.value = '';
    this.drugControl.setValue(null);
  }

  protected getPersonAge(person: Person){
    return person ? Party.getAge(person.birthdate) : null;
  }

  protected getBirthdate(date: Date) {
    moment.locale(this.lang);
    return moment(date).format("L");
  }
}
