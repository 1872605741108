import { SearchCriteria } from 'src/app/model/data-page';
import { Nameable } from './../persistent';
import { Persistent, ClassNameMap } from '../persistent';
import { MedicalAgent } from '../organisation/medical-agent';
import { MedicalFolder } from '../admission/medical-folder';
import { Prestation } from '../prestation/prestation';

import * as moment from 'moment';
import { ConsultationSheet } from '../consultation/consultation.sheet';

export class TimeDuration {
    duration?: number;
    unit?: string;

    constructor(duration?: number, unit?:string) {
        this.duration = duration;
        this.unit = unit;  
    }
    /**
     * Determine the achievment date = startDate + this TimeDuration
     * @param startDate 
     */
    public getAchievementDate(startDate: Date): Date {
        let date: Date = null;
        if(startDate && this.isValid()){
            //console.log("getAchievementDate ", {duration:this.duration, momentUnit: this.unitMomentStringValue()});
            date = moment(startDate).add(this.duration, this.unitMomentStringValue() ).toDate();
        }
        return date;
    }

    public unitMomentStringValue(): moment.unitOfTime.DurationConstructor {
        if(this.unit == TimeUnit[TimeUnit.DAY]){
            return "days";
        } else if(this.unit == TimeUnit[TimeUnit.HOUR]){
            return "hours";
        } else if(this.unit == TimeUnit[TimeUnit.MONTH]){
            return "months";
        } else if(this.unit == TimeUnit[TimeUnit.WEEK]){
            return "weeks";
        } else if(this.unit == TimeUnit[TimeUnit.YEAR]){
            return "years";
        }
        return null;
    }

    public isValid(): boolean {
        let isValid: boolean = true;
        if(this.unit==null || this.duration == null || this.duration < 0 ){
            isValid = false;
        }
        return isValid;
    }

    public static getDurationUnitList():{id: any, text: string}[]{
        return [
            {id:TimeUnit[TimeUnit.HOUR], text:"TimeUnit.HOUR.label"},
            {id:TimeUnit[TimeUnit.DAY], text:"TimeUnit.DAY.label"},
            {id:TimeUnit[TimeUnit.WEEK], text:"TimeUnit.WEEK.label"},
            {id:TimeUnit[TimeUnit.MONTH], text:"TimeUnit.MONTH.label"},
            {id:TimeUnit[TimeUnit.YEAR], text:"TimeUnit.YEAR.label"},
        ];
    }
}

export enum TimeUnit {
    HOUR,
    DAY,
    WEEK,
    MONTH,
    YEAR
}

export class Prescription extends Persistent{

    /**
	 * The code of the Prescription
	 */
    code?: string;

    /**
     * The consultation linked to this prescription
     */
    consultation?: ConsultationSheet;
    
    /**
     * Unique identifier of a prescription
     */
	prescriptionCode?: string;

    /**
	 * When did the doctor made this Prescription
	 */
    date?: Date;
	
	/**
	 * Medical agent who made the Prescription
	 */
	medicalAgent?: MedicalAgent;
	
	/**
	 * The medical folder of the patient concerned
	 */
	medicalFolder?: MedicalFolder;
    
    /**
     * List of prescripted items
     */
    prescriptionItems?: BasePrescriptionItem[];
    
    /**
     * Validity state of the prescription
     */
    stillValid?: boolean;

    constructor() {
        super();
        this.classe = ClassNameMap.Prescription;
        this.prescriptionItems = [];
        this.date = new Date(Date.now());
        this.stillValid = true;
    }

    public validityErrors: string[];// list of errors in the Prestation
    public isValid() : boolean {
        this.validityErrors = [];
        let isValid = true;
        if(!this.date || moment(this.date).isAfter(new Date(Date.now()))) {
            isValid = false;
            this.validityErrors.push(Prescription.invalidDateError);
        }
        if(!this.medicalAgent) {
            isValid = false;
            this.validityErrors.push(Prescription.invalidMedicalAgentError);
        }
        if(!this.medicalFolder) {
            isValid = false;
            this.validityErrors.push(Prescription.invalidMedicalFolderError);
        }
        if(!this.prescriptionItems || this.prescriptionItems.length <1) {
            isValid = false;
            this.validityErrors.push(Prescription.invalidPrescriptionItemsError);
        } else {
            this.prescriptionItems.forEach( (item)=>{
                if((item instanceof PrescriptionItem && !item.isValid())
                || (item instanceof MedicalPrescriptionItem && !item.isValid())) {
                    isValid = false;
                }
            });
        }
        return isValid;
    }

    public static invalidDateError = "prescription.date.invalid.error";
    public static invalidMedicalAgentError = "prescription.medicalAgent.invalid.error";
    public static invalidMedicalFolderError = "prescription.medicalFolder.invalid.error";
    public static invalidPrescriptionItemsError = "prescription.items.invalid.error";
    public static invalidHospitalizationReasonsError = "prescription.hospitalizationReasons.invalid.error";
    
}

export class BasePrescriptionItem extends Persistent {

    prescription?: Prescription ;
	
    quantity?: number;

    protocol?: string;
    
    constructor() {
        super();
        this.classe = ClassNameMap.BasePrescriptionItem;
        this.quantity = 1;
        this.protocol = "";
    }

    public isValid() : boolean {
        let isValid = true;
        return isValid;
    }
}

export class PrescriptionItem extends BasePrescriptionItem {
	
	prestation?: Prestation ;
    
    constructor(prestation?: Prestation,protocol?: string) {
        super();
        this.classe = ClassNameMap.PrescriptionItem;
        this.prestation = prestation;
        this.protocol = protocol? protocol : "";
    }

    public isValid(): boolean {
        let isValid = true;
        if(!this.prestation) {
            isValid = false;
        }
        return isValid;
    }
}

export class ExamPrescription extends Prescription {

    constructor() {
        super();
        this.classe = ClassNameMap.ExamPrescription;
        this.date = new Date(Date.now());
    }

    public isValid() : boolean {
        let isValid = true;
        if(!super.isValid()) {
            isValid = false;
        }
        if(this.prescriptionItems && this.prescriptionItems.length >0) {
            this.prescriptionItems.forEach( (item)=>{
                if(!(item as PrescriptionItem).isValid()) {
                    isValid = false;
                    this.validityErrors.push(Prescription.invalidPrescriptionItemsError);
                }
            });
        }
        return isValid;
    }
}

export class CarePrescription extends Prescription {

    hospitalizationReasons?: string;

    surveillance?: string;

    diet?: string;

    specialRecommendations?: string;

    constructor() {
        super();
        this.classe = ClassNameMap.CarePrescription;
        this.date = new Date(Date.now());
    }

    public isValid() : boolean {
        let isValid = true;
        if(!super.isValid()) {
            isValid = false;
        }
        if(!this.hospitalizationReasons) {
            isValid = false;
            this.validityErrors.push(Prescription.invalidHospitalizationReasonsError);
        }
        if(this.prescriptionItems && this.prescriptionItems.length >0) {
            this.prescriptionItems.forEach( (item)=>{
                if(!(item as PrescriptionItem).isValid()) {
                    isValid = false;
                    this.validityErrors.push(Prescription.invalidPrescriptionItemsError);
                }
            });
        }
        return isValid;
    }
}

export class MedicalPrescription extends Prescription {

    validityDuration?: TimeDuration;

    /**
     * Prescription end of validity date
     */
    expirationDate?: Date;
  
    /**
     * Recommendations on how to take medications
     */
    recommendations?: string;

    constructor() {
        super();
        this.classe = ClassNameMap.MedicalPrescription;
        this.date = new Date(Date.now());
    }

    public isValid() : boolean {
        let isValid = true;
        if(!super.isValid()) {
            isValid = false;
        }
        if(this.prescriptionItems && this.prescriptionItems.length >0) {
            this.prescriptionItems.forEach( (item)=>{
                if(!(item as MedicalPrescriptionItem).isValid()) {
                    isValid = false;
                    this.validityErrors.push(Prescription.invalidPrescriptionItemsError);
                }
            });
        }
        return isValid;
    }

    public static RENEW_ACTION: string = "renew";
    public static REVISE_ACTION: string = "revise";
}

export class PrestationStatus {
   public static VALID = "valid";
   public static INVALID = "invalid";
   public static EXPIRED = "expired";
}

export class Drug extends Nameable{
    constructor(name?: string) {
        super(name);
        this.classe = ClassNameMap.Drug;
    }
}

export class MedicalPrescriptionItem extends BasePrescriptionItem {

    drug?: Drug;
    conditioning?: string;

    constructor(drug?: Drug, conditioning?: string, protocol?: string) {
        super();
        this.classe = ClassNameMap.MedicalPrescriptionItem;
        this.conditioning = conditioning;
        this.protocol = protocol;
        this.drug = drug;
    }

    public isValid() : boolean {
        let isValid = true;
        if(!super.isValid()) {
            isValid = false;
        }
        if(!this.drug || !this.drug.classe || !this.drug.name || (this.drug && this.drug.name!=null && this.drug.name.trim()=="")) {
            isValid = false;
        }
        /*if(!this.conditioning || this.conditioning.trim() == "") {
            isValid = false;
        }*/ //Conditioning is not mandatory
        if(!this.protocol || this.protocol.trim() == "") {
            isValid = false;
        }
        return isValid;
    }
}
