import { Component,ChangeDetectorRef, Input, SimpleChanges } from '@angular/core';
import { FormGroup, AbstractControl, FormBuilder } from '@angular/forms';
import { SearchCriteria } from 'src/app/model/data-page';
import { TranslateService } from '@ngx-translate/core';
import { NkapHttpService } from 'src/app/services/nkap-http.service';
import { Router, ActivatedRoute } from '@angular/router';
import { NkapNotificationService } from 'src/app/services/nkap-notification.service';
import { Person, GenderType, Profession, Religion } from 'src/app/model/organisation/person';
import { Party } from 'src/app/model/organisation/party';
import { Nameable } from 'src/app/model/persistent';
import { Observable } from 'rxjs';
import { UserAppService } from 'src/app/services/user-app.service';
import { NkapListComponent } from '../../base/nkap-list-component';

@Component({
  selector: 'app-medical-folder-grid',
  templateUrl: './medical-folder-grid.component.html',
  styleUrls: ['./medical-folder-grid.component.css']
})
export class MedicalFolderGridComponent extends NkapListComponent<any>{
  
  @Input('component')
  callerComponentName: string;

  @Input('action')
  showActions: boolean = false;

  @Input('criteria')
  externalCriteria: any | SearchCriteria;

  criteria: any | SearchCriteria;


  protected FEMALE = GenderType[GenderType.FEMALE];
  protected MALE = GenderType[GenderType.MALE];

  personIdentificationForm: FormGroup;
  nationalityControl: AbstractControl;

  displayer: Person = new Person();

  nationalities: Observable<any[]>;

  /*select sexe */
  sexes: any[];

  protected professionCtrl: AbstractControl ;
  protected professionList: Profession[];

  protected religionCtrl: AbstractControl;
  protected religionList: Religion[];


  constructor(private fb: FormBuilder, protected _ref: ChangeDetectorRef, protected httpService: NkapHttpService<any>, 
    protected router: Router, protected route: ActivatedRoute, protected userService: UserAppService,
    protected msgService: NkapNotificationService, protected translateService: TranslateService) {
   super(_ref, httpService, router, route, userService, msgService, translateService);
      this.searchBaseUrl = "admission/medical-folder";
      this.personIdentificationForm = this.fb.group({
        birthdate: [''],
        gender: [null],
        nationality: [null],
        profession: [null],
        religion: [null]
      });
      this.nationalityControl = this.personIdentificationForm.get('nationality');
      this.professionCtrl = this.personIdentificationForm.get('profession');
      this.religionCtrl = this.personIdentificationForm.get('religion');      
  }

  ngOnInit() {
   super.ngOnInit();
    this.sexes = [
      {value: null, text:''},
      {value: GenderType[GenderType.FEMALE], text: 'GenderType.' + GenderType[GenderType.FEMALE] + '.label'},
      {value: GenderType[GenderType.MALE], text: 'GenderType.' + GenderType[GenderType.MALE] + '.label'}
    ];

    this.nationalityControl.valueChanges.subscribe( (textInput) => {
      if (!textInput || typeof textInput === 'string') {
        this.criteria.nationalityId = null;
        this.getCountry(textInput);
      } else {
        this.criteria.nationalityId = textInput.id;
      }
    });

    //init autocomplete for religion
    this.religionCtrl.valueChanges.subscribe( (value)=>{
      if (!value || typeof value === 'string') {
         this.criteria.religionId = null;
         this.httpService.search({ criteria: value }, 'organisation/religion').subscribe((result) => {
          this.religionList = result ? result.datas : [];
        }, (error) => {
          this.religionList = [];
        });
      } else {
        this.criteria.religionId = value.id;
      }
    });

    //init autocomplete for profession
    this.professionCtrl.valueChanges.subscribe( (value) => {
      if (!value || typeof value === 'string') {
        this.criteria.professionId = null;
        this.httpService.search({ criteria: value }, 'organisation/profession').subscribe((result) => {
          this.professionList = result ? result.datas : [];
        }, (error) => {
          this.professionList = [];
        });
      } else {
        this.criteria.professionId = value.id;
      }
    });
  }

  protected search(criteria: any) {
    if (this.personIdentificationForm) {
      this.criteria.gender = this.personIdentificationForm.get('gender').value;
      this.criteria.birthdate = this.personIdentificationForm.get('birthdate').value;
    }
    super.search(this.criteria);
  }

  /**
   * Initialisation of cache data management
   */
  initCacheDataManagement() {
    // retrieving cache data
    let cachedNavigationData = this.userService.getNavigationData(this.callerComponentName != null? this.callerComponentName : this.constructor.name);
    if(cachedNavigationData != null && cachedNavigationData.criteria != null) {
      this.criteria = Object.assign(this.criteria != null ? this.criteria : {}, cachedNavigationData.criteria);
      if(this.externalCriteria) {
        this.criteria = Object.assign(this.criteria != null ? this.criteria : {}, this.externalCriteria);
      }
    }
    if(this.externalCriteria) {
      if(!cachedNavigationData){cachedNavigationData = {};}
      cachedNavigationData.criteria = Object.assign({}, this.criteria);
    }
   // console.log('initCacheDataManagement', this.externalCriteria, this.criteria);
    // set cache data in criteria to perform filter with them
    this.setCriteriaDataInView(cachedNavigationData);        
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.externalCriteria){
      this.criteria = Object.assign(this.criteria != null ? this.criteria : {}, this.externalCriteria);
      this.searchFormControl.setValue(this.criteria.value);
     // console.log('ngOnChanges', this.externalCriteria, this.criteria);
      this.searchClicked({});
    }
    /*if (changes.callerComponentName){
      this.initCacheDataManagement();
      this.searchClicked({});
    }*/
  }
  
  protected keydownEnter(event) {
    console.log("keydownEnter ", event);
    event.preventDefault();
    this.searchClicked(event);
  }

  protected viewElement(element) {
    this.router.navigate(['medical-folder/show', {id: element.id}]);
  }

  protected getPersonAge(person: Person){
    return person ? Party.getAge(person.birthdate) : null;
  }

  public getCountry(value) {
    this.httpService.search({ criteria: value }, 'location/country').subscribe((result) => {
      this.nationalities = new Observable((observer) => {
        observer.next(result ? result.datas : []);
        observer.complete();
      });
    });
  }
  
  addNewCountry(event) {// in case of a new value, we set the component value to null
    /*if (this.criteria.nationalityId == null) {
      this.nationalityControl.setValue(null);
    }*/
  }

  addNewProfession(event) {// in case of a new value, we set the component value to null
    /*if (this.criteria.professionId == null) {
      this.professionCtrl.setValue(null);
    }*/
  }

  addNewReligion(event) {// in case of a new value, we set the component value to null
   /* if (this.criteria.religionId == null) {
      this.religionCtrl.setValue(this.criteria.religionId);
    }*/
  }
 
  displayNameable(nameable: Nameable): string {
    return nameable ? nameable.name : "";
  }

  protected setCriteriaDataInView(cachedNavigationData: any) {
    super.setCriteriaDataInView(cachedNavigationData);
    if(cachedNavigationData == null) { return ;}
    if( cachedNavigationData.criteria != null) {
      this.personIdentificationForm.get('birthdate').setValue( cachedNavigationData.criteria.birthdate);
      this.personIdentificationForm.get('gender').setValue( cachedNavigationData.criteria.gender);
    }
    if( cachedNavigationData.nationality != null) {
      this.nationalityControl.setValue(cachedNavigationData.nationality);
    }
    if( cachedNavigationData.profession != null) {
      this.professionCtrl.setValue(cachedNavigationData.profession);
    }
    if( cachedNavigationData.religion != null) {
      this.religionCtrl.setValue(cachedNavigationData.religion);
    }
  }

  protected getNavigationDataToStore(): any {
    let data = super.getNavigationDataToStore();
    return Object.assign( data != null ? data : {}, {
      nationality: this.nationalityControl.value,
      profession: this.professionCtrl.value,
      religion: this.religionCtrl.value
    });
  }

  /**
   * Befor destroying the view, we store data
   */
  ngOnDestroy() {
    this.userService.updateNavigationData( this.callerComponentName != null? this.callerComponentName : this.constructor.name, this.getNavigationDataToStore());
  }

  public getData() {
    if(this.tableDataSource && this.tableDataSource.data && this.tableDataSource.data.length == 1){
      if(this.selection){
        this.selection.select(this.tableDataSource.data[0]);
      }
    }
    if(this.selection){
      return this.selection.selected[0];
    } else {
      return null;
    }
  }

}
