import { Persistent, ClassNameMap } from '../persistent';
import { Company, Party } from './party';

export class MedicalInstitutionPartner extends Persistent{

    public isStillValid?: boolean;
    public notValidReason?: string;
    public party?: Party;
    public type?: MedicalInstitutionType | string;

    constructor(party?: Party,type?: MedicalInstitutionType | string) {
        super();
        this.party = party != null ? party : new Company();
        this.type = type != null ? type : MedicalInstitutionType[MedicalInstitutionType.LABORATORY];
        this.classe = ClassNameMap.MedicalInstitutionPartner;
    }
}

export enum MedicalInstitutionType {
    HOSPITAL,
	LABORATORY
}