import { Component, OnInit } from '@angular/core';
import { UserAppService } from 'src/app/services/user-app.service';
import { User } from 'src/app/model/security/user';
import { IFormActionBarButton, FormAction } from '../../buttons-bar/buttons-bar.component';
import { Router } from '@angular/router';
import { NkapKeycloakService } from 'src/app/services/nkap-keycloak.service';


@Component({
  selector: 'app-user-parameters-show',
  templateUrl: './user-parameters-show.component.html',
  styleUrls: ['./user-parameters-show.component.css']
})
export class UserParametersShowComponent implements OnInit {

  userShow: User;
  protected buttons: IFormActionBarButton[];

  constructor(private userService: UserAppService, private router: Router, private keycloakService: NkapKeycloakService) { 
    this.buttons = [
      {id: 'edit', value: FormAction.UPDATE, icon: {type : 'edit', color: '#ff9933'}, 
        text: 'UserParametersShow.UpdatePwd', disabled: false, functionalities: ['UserParametersEdit.bnt.edit.label']}      
    ];
  }

  ngOnInit() {
    this.userShow = this.userService.getUser();
    this.keycloakService.impersonation();
  }

  btnActionClick(event) {
    if (event && event.id === this.buttons[0].id) {
      this.router.navigate(['user-parameters/edit']);
    } 
  }

}
