import { Party } from 'src/app/model/organisation/party';
import { MedicalInstitutionType, MedicalInstitutionPartner } from 'src/app/model/organisation/medical-institution-partner';
import { Person } from 'src/app/model/organisation/person';
import { Exam, ExamProcessing } from './../../../model/exam/exam';
import { Component, OnInit, SimpleChanges, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ButtonsBarComponent, FormAction, IFormActionBarButton } from '../../buttons-bar/buttons-bar.component';
import { User } from 'src/app/model/security/user';
import { UserAppService } from 'src/app/services/user-app.service';
import { NkapNotificationService, NotificationType } from 'src/app/services/nkap-notification.service';
import { NkapHttpService } from 'src/app/services/nkap-http.service';
import { ActivatedRoute, Router } from '@angular/router';
import { Funtions } from 'src/app/model/security/funtionalities';
import { NkapValidators } from '../../base/nkap-validators';
import { text } from 'body-parser';

@Component({
  selector: 'app-exam-processing-edit',
  templateUrl: './exam-processing-edit.component.html',
  styleUrls: ['./exam-processing-edit.component.css']
})
export class ExamProcessingEditComponent implements OnInit {

  buttons: IFormActionBarButton[] = [];
  form: FormGroup;
  currentObject: ExamProcessing;
  externalProcessing: boolean = true;

  inChargeList: Person[];

  currentExam: Exam;

  userConnected: User;
  
  @ViewChild("btnBar")
  btnBarCmp: ButtonsBarComponent;

  constructor(private formBuilder: FormBuilder,private userService: UserAppService,
    private msgService: NkapNotificationService,
    protected router: Router, protected route: ActivatedRoute, private httpService: NkapHttpService<any>) {
    this.currentObject = new ExamProcessing();
    this.buttons = [
      {id: "save", value: FormAction.CREATE, icon: {type : 'save'},
        text: 'btn.save.label', disabled: true, functionalities: [Funtions.exam_btn_saveProcessing_fct]},
      {id: "list", value: FormAction.VIEW, icon: {type : 'list'},
        text: 'btn.list.label', disabled: false, functionalities: [Funtions.exam_btn_list_fct]}
    ];
  }

  public initForm(step: ExamProcessing): void {
    this.currentObject = step;
    console.log("initForm ", this.currentObject);
    this.form = this.formBuilder.group({
      date: [this.currentObject && this.currentObject.date? this.currentObject.date : new Date(Date.now()), [Validators.required, NkapValidators.notAfterCurrentDay]],
      deadlines: [this.currentObject && this.currentObject.deadlines ? this.currentObject.deadlines : null, [NkapValidators.afterCurrentDay]],
      inCharge: [this.currentObject ? this.currentObject.inCharge : null, [Validators.required, NkapValidators.dataWithIdSelected]],
      comments: [this.currentObject? this.currentObject.comments : '', []],
      externalProcessing: [this.currentObject? this.currentObject.externalProcessing : true, [Validators.required]],
    });
    this.externalProcessing = this.form.get('externalProcessing').value;
    this.form.get('inCharge').valueChanges.subscribe( (inputValue) => {
      if (typeof inputValue === 'string' ) {
        let url = 'organisation/medical-agent'; 
        let param: any = {value: inputValue};
        if(this.externalProcessing == true) {
          url = 'organisation/medical-institution';
          param = {value: inputValue,isValid: true, types: [MedicalInstitutionType[MedicalInstitutionType.LABORATORY]]}
        }       
        this.httpService.search(param, url).subscribe ( (result) => {
          let dataList: any[] = result ? result.datas : [];
          if(this.externalProcessing == true) {
            let institutions = [];
            dataList.forEach( (d) => {
              institutions.push(d.party);
            });
            console.log({institutions});
            this.inChargeList = [].concat(institutions);
          } else {
            this.inChargeList = dataList;
          }
        });          
      } else {
        console.log('inCharge.valueChanges ', inputValue);
      }
    });
    this.form.get('comments').markAsTouched();
    this.form.get('comments').updateValueAndValidity({onlySelf: true});
    this.form.get('inCharge').markAsTouched();
    this.form.get('inCharge').updateValueAndValidity({onlySelf: true});
    this.form.statusChanges.subscribe( ()=> {
      this.btnStatusChange();
    });
  }

  btnStatusChange() {
    if(this.btnBarCmp){
      const btnStatus = [
        {id: "save", disabled: !this.isValid()},
        {id: "list", disabled: false },
      ];  
      console.log(" btnStatusChange ", btnStatus);  
      this.btnBarCmp.setButtonsStatus(btnStatus);
    }
  }

  ngOnInit() { 
    
    this.route.paramMap.subscribe(params => {
      //console.log(" ngOnInit params", params);
      let data: {examId?: string} = (params as any).params;
      //console.log(" ngOnInit ", data);
      if(data && data.examId != null) {
        this.httpService.findById(data.examId, "exam/with-details").subscribe ( (exam) => {
          this.currentExam = exam;
        });  
      }
      this.initForm(this.currentObject);  
    });
     
  }

  ngAfterViewInit(): void {
    // Get the MedicalAgent connected
    this.userConnected = this.userService.getUser();
  }
  
  ngOnChanges(changes: SimpleChanges): void {
    //console.log("changes ", changes);
    if(changes["currentObject"]){
      //console.log("changes on currentObject ", changes["currentObject"], this.currentObject);
      this.initForm(this.currentObject);
      this.form.updateValueAndValidity({onlySelf: true, emitEvent: true});
    }
  }
 
  public isValid(): boolean {
    //this.form.updateValueAndValidity({onlySelf:true});
    let isValid = this.form.valid ? true : false;
    console.log("isValid", isValid, this.form);
    if(!this.currentExam || this.currentExam.id ==null){
      isValid == false;
    }
    if(!this.userConnected || this.userConnected.id ==null){
      isValid == false;
    }
    return isValid;
  }

  public getData(): ExamProcessing {
    if(!this.currentObject){
      this.currentObject = new ExamProcessing();
    }
    this.currentObject = Object.assign( this.currentObject, this.form.value);   
    this.currentObject.user = this.userConnected;
    this.currentObject.exam = null;    
    return this.currentObject;
  }

  public btnActionClick(event) {
    let i = 0; 
    if(event && event.id === this.buttons[i++].id){
      this.save();
    } else if(event && event.id === this.buttons[i++].id){
      this.router.navigate(["./exam/list", {examId: this.currentExam? this.currentExam.id : null, search: true}]);
    }
  }

  save() {
    if(this.isValid()) {
      let toSave = this.getData();
      this.httpService.post(toSave, `exam/processing/save/${this.currentExam.id}`, true).subscribe( (result) => {
        this.msgService.log("exam-processing.edit.save.succed", NotificationType.SUCCESS);
        this.router.navigate(["./exam/list", {examId: this.currentExam? this.currentExam.id : null, search: true}]);
      }, (error) => {
          this.msgService.log(error, NotificationType.ERROR);
      });
    }
  }

  displayParty(party: Party) {
    return party != null? party.fullname : '';    
  }

}
