import { element } from 'protractor';
import { Component, OnInit, ViewChild, Input, Output, EventEmitter, OnChanges, SimpleChanges } from '@angular/core';
import { NkapHttpService } from 'src/app/services/nkap-http.service';
import { Prestation } from 'src/app/model/prestation/prestation';
import { Observable, Subject } from 'rxjs';
import { DataPage } from 'src/app/model/data-page';
import { MatTable, MatDialog, MatTableDataSource } from '@angular/material';
import { SelectionModel } from '@angular/cdk/collections';
import { FormControl } from '@angular/forms';
import { ReceptionType } from 'src/app/model/admission/reception';


@Component({
  selector: 'app-patient-presence-reason',
  templateUrl: './patient-presence-reason.component.html',
  styleUrls: ['./patient-presence-reason.component.css']
})
export class PatientPresenceReasonComponent implements OnInit, OnChanges {
  
  /**
   * Type of prestation handle 
   * E = Exams prestation
   * C = Consultation prestation
   */
  @Input('type')
  receptionType: string;

  columnHeaders: string[];

  prestationsMostAsked: Prestation[];

  @Input('selected')
  prestationsSelected: Prestation[];
  prestations: Prestation[];
  myControl = new FormControl();

  @ViewChild(MatTable)
  tableCmp: MatTable<any>;
  selection: SelectionModel<Prestation> = new SelectionModel<Prestation>(true, []);
  dataSource: MatTableDataSource<Prestation> = new MatTableDataSource([]);

  @Output()
  selectionChanged: EventEmitter<Prestation[]> = new EventEmitter<Prestation[]>();

  constructor(private nkapService: NkapHttpService<Prestation>, public dialog: MatDialog) { }


  ngOnInit() {
    this.selection.changed.subscribe( (val) => {
      this.selectionChanged.emit(this.selection.selected);
    });
    //let url = 'prestation/most-asked';
    let url = null;
    if (this.receptionType === ReceptionType[ReceptionType.EXAMS]){
      url = 'prestation/most-asked/exams';
    }
    if(url != null){
      this.nkapService.list(url).subscribe ( (result) => {
        this.prestationsMostAsked = result || [];
        if(this.selection && (this.selection.selected == null || this.selection.selected.length <=0) ){
          this.initPrestationRendered();
        }
     });
    }
    this.columnHeaders = [
       'checked', 'prestation'
     ];
    this.myControl.valueChanges.subscribe( (inputValue) => {
       if (typeof inputValue === 'string' || !inputValue) {
         this.prestations = [];
         this.nkapService.search({value: inputValue}, `prestation${this.receptionType === ReceptionType[ReceptionType.EXAMS] ? '/exams' : 
            this.receptionType === ReceptionType[ReceptionType.CARE] ? '/care' : '/consultation'}`).subscribe ( (result) => {
            const prestationsWithoutSelectedOne = [];
            if (result && result.datas) {
              result.datas.forEach( (p) => {
                if (this.dataSource.data.findIndex( (p1) => p1.id === p.id) === -1) {
                  prestationsWithoutSelectedOne.push(p);
                }
              });
            }
            this.prestations = prestationsWithoutSelectedOne;
          });
        } else {
          inputValue.canBeDelete = true;
          this.addSelectedPrestation(inputValue);
        }
     });

  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.prestationsSelected) {
      if (this.prestationsSelected != null && this.prestationsSelected.length > 0) {
        this.selection.clear();
        let listNotDuplicatedElements = [];
        this.prestationsSelected.forEach( (prest) => {
          let foundIndex = this.dataSource.data.findIndex((elm) => elm.id == prest.id);
          if(foundIndex == -1) {
            listNotDuplicatedElements.push(prest);
          }
        });
        this.dataSource.data = this.dataSource.data.concat(listNotDuplicatedElements);
        this.dataSource.data.forEach( (prest) => {  
          let foundIndex = this.prestationsSelected.findIndex((elm) => elm.id == prest.id);
          if(foundIndex != -1) {
            this.selection.select(prest);
          }
        });
        this.selectionChanged.emit(this.selection.selected);
      } else {
        this.dataSource.data = [];
        this.selection.clear();
        this.selectionChanged.emit([]);
      }
    }
  }

  public initPrestationRendered() {
    let listNotDuplicatedElements = [];
    if(this.prestationsMostAsked != null) {
      this.prestationsMostAsked.forEach( (prest) => {
        let foundIndex = listNotDuplicatedElements.findIndex((elm) => elm.id == prest.id);
        if(foundIndex == -1) {
          listNotDuplicatedElements.push(prest);
        }
      });
    }
    this.dataSource.data = [].concat(listNotDuplicatedElements);    
   // this.selectionChanged.emit(this.selection.selected);
    this.tableCmp.renderRows();
  }

  public addPrestationToRendered(newPrestations: Prestation[]) {
    this.dataSource.data = this.dataSource.data.concat(newPrestations);
    this.tableCmp.renderRows();
  }

  public addSelectedPrestation(newPrestation: Prestation) {
    if(newPrestation != null) {
      this.dataSource.data = this.dataSource.data.concat([newPrestation]);
      this.selection.select(newPrestation);
      //this.selectionChanged.emit(this.selection.selected);
      this.tableCmp.renderRows();
    }
    console.log("addSelectedPrestation ", newPrestation);
  }

  displayFn(prestation?: Prestation): string | undefined {
    return prestation ? '' : undefined;
  }
 
   /** Whether the number of selected elements matches the total number of rows. */
   isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.dataSource.data.length;
    return numSelected === numRows;
  }

  /** Selects all rows if they are not all selected; otherwise clear selection. */
  masterToggle() {
    this.isAllSelected() ?
        this.selection.clear() :
        this.dataSource.data.forEach(row => this.selection.select(row));
  }

  checkBoxChange(element) {
    this.selection.toggle(element);
    if (!this.selection.isSelected(element) && element.canBeDelete === true) {
        let index = this.dataSource.data.findIndex( (elmt) => elmt.id === element.id);
        if (index != -1){
          this.dataSource.data.splice(index, 1);
          this.tableCmp.renderRows();
        }
    }
    //this.selectionChanged.emit(this.selection.selected);
  }

  public getSelectedPrestations(): Prestation[] {
    return this.selection.selected;
  }

  public isElementSelected(element: Prestation):boolean {
    if(this.selection && this.selection.selected){
      let index = this.selection.selected.findIndex( (prest)=> {return element != null && prest.id == element.id});
      if(index != -1){ return true;}
    }
    return false;
  }
}
