import { Component, OnInit, Input, AfterViewInit, OnChanges, SimpleChanges } from '@angular/core';
import { SystemInvestigation } from 'src/app/model/admission/medical-folder';
import { PhysicalExam } from 'src/app/model/consultation/physical.exam';

@Component({
  selector: 'app-system-physical-exam-show',
  templateUrl: './system-physical-exam-show.component.html',
  styleUrls: ['./system-physical-exam-show.component.css']
})
export class SystemPhysicalExamShowComponent implements OnInit, AfterViewInit, OnChanges {

  @Input('system')
  systemInvestigation: SystemInvestigation[];

  @Input('physical')
  physicalExam: PhysicalExam;
  physicalExamHasData: boolean = false;

  constructor() { 
  }

  ngOnChanges(changes: SimpleChanges): void {
    if(changes['physicalExam'] != null) {
      this.checkIfPhysicalExamHasData();
    }
  }

  ngAfterViewInit(): void {
    this.checkIfPhysicalExamHasData();
  }

  checkIfPhysicalExamHasData() {
    if(this.physicalExam != null) {
      if(this.physicalExam.eg != null || this.physicalExam.ta != null || this.physicalExam.t != null || this.physicalExam.glycemie != null
        || this.physicalExam.weight != null || this.physicalExam.size != null || this.physicalExam.urea != null
        || this.physicalExam.creat != null || this.physicalExam.imc != null || this.physicalExam.report != null 
        || this.physicalExam.clearanceCreat != null || this.physicalExam.headAndNeck != null ||  this.physicalExam.thorax != null 
        || this.physicalExam.abdomen != null || this.physicalExam.uroGenital != null || this.physicalExam.snc != null 
        || this.physicalExam.ganglionicAreas != null || this.physicalExam.dermatology != null || this.physicalExam.locomotor != null) {
        this.physicalExamHasData = true;
      }
    }
  }

  ngOnInit() {

  }

}
