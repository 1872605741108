import { Component, OnInit, ChangeDetectorRef, ViewChild, AfterViewInit } from '@angular/core';
import { NkapHttpService } from 'src/app/services/nkap-http.service';
import { MedicalFolder } from 'src/app/model/admission/medical-folder';
import { Router, ActivatedRoute } from '@angular/router';
import { NkapNotificationService, NotificationType } from 'src/app/services/nkap-notification.service';
import { TranslateService, TranslatePipe } from '@ngx-translate/core';
import { IFormActionBarButton, FormAction, ButtonsBarComponent } from '../../buttons-bar/buttons-bar.component';
import { MedicalFolderGridComponent } from '../medical-folder-grid/medical-folder-grid.component';
import { FileDownloaderComponent } from 'src/app/components/file-downloader/file-downloader.component';
import { Funtions } from 'src/app/model/security/funtionalities';
import { GlobalVariables } from 'src/app/services/global-variables';
import { SearchCriteria } from 'src/app/model/data-page';

@Component({
  selector: 'app-medical-folder-list',
  templateUrl: './medical-folder-list.component.html',
  styleUrls: ['./medical-folder-list.component.css']
})
export class MedicalFolderListComponent implements OnInit, AfterViewInit {
 
  @ViewChild("medicalFolderGrid")
  protected grid: MedicalFolderGridComponent;

  /**
   * A reference on download component
   */
   @ViewChild("downloader")
   downloaderComponent: FileDownloaderComponent;
   openFileInTab = true;

   @ViewChild("btnBar")
   btnBarCmp: ButtonsBarComponent;
   buttons: IFormActionBarButton[];
  translate: TranslatePipe;

  /**
   * Contain the current lang choosed by the user
   */
  protected lang = GlobalVariables.AVAILABLE_LANG ? GlobalVariables.AVAILABLE_LANG[0] : null;

  /**
   * Criteria object send to the server to filter data
   */
  criteria: any | SearchCriteria;
  
  protected componentName = this.constructor.name;

  constructor(private _ref: ChangeDetectorRef, private httpService: NkapHttpService<MedicalFolder>, 
      private router: Router, private route: ActivatedRoute,
      private msgService: NkapNotificationService, protected translateService: TranslateService) {
      this.translate = new TranslatePipe(translateService, _ref);
      this.criteria = new SearchCriteria();
      this.lang = this.translateService.currentLang;
      this.translateService.onLangChange.subscribe( (lang) => {
        this.lang = lang;
      });

      this.buttons = [
        {id: "add", value: FormAction.CREATE, icon: {type : 'add_circle_outline', color: '#0099cc'},
          text: 'patient.btn.new.label', disabled: false, functionalities: [Funtions.patient_btn_new_fct]},
        {id: "edit", value: FormAction.UPDATE, icon: {type : 'edit',color:'#ff9933'}, 
          text: 'patient.btn.edit.label', disabled: false, functionalities: [Funtions.patient_btn_edit_fct]},
        {id: "delete", value: FormAction.DELETE, icon: {type :'delete_forever',color:'#ff9933'},
          text: 'patient.btn.delete.label', disabled: false, functionalities: [Funtions.patient_btn_delete_fct]},
        {id: "list", value: FormAction.UPDATE, icon: {type : 'list',color:'#ff9933'}, 
          text: 'patient.care-coverage-doc.btn.edit.label', disabled: false, functionalities: [Funtions.careCoverageDoc_btn_list_fct,Funtions.careCoverageDoc_btn_new_fct,Funtions.careCoverageDoc_btn_edit_fct]},
        {id: "print", value: FormAction.PRINT, icon: {type : 'print',color:'#ff9933'}, 
          text: 'patient.form.btn.print.label', disabled: false, functionalities: [Funtions.patient_form_btn_print_fct]}
      ];
  }

  ngOnInit() {
    
  }

  ngAfterViewInit(): void {
    if(this.grid){
      this.listSelectionChanged();
      this.grid.elmtSelected.subscribe( (elm)=>{
        this.listSelectionChanged();
      });
    }
  }

  btnActionClick(event) {
    if(event && event.id === this.buttons[0].id ){
      this.router.navigate(["reception/admission"]);
    } else if(event && event.id === this.buttons[1].id){
      if(this.grid && !this.grid.selection.selected || this.grid.selection.selected.length != 1){
        this.msgService.log("medical-folder.list.select.to.modify.error", NotificationType.ERROR);
      }else{
        this.router.navigate(["reception/admission", {id: this.grid.selection.selected[0].id}]);
      }
    } else if(event && event.id === this.buttons[2].id){
      if(this.grid &&  !this.grid.selection.selected || this.grid.selection.selected.length < 1){
        this.msgService.log("medical-folder.list.select.to.delete.error", NotificationType.ERROR);
      }else{
        this.deleteSelected();
      }
    }else if(event && event.id === this.buttons[3].id){
      if(this.grid &&  !this.grid.selection.selected || this.grid.selection.selected.length < 1){
        this.msgService.log("medical-folder.list.select.to.care-coverage.error", NotificationType.ERROR);
      }else{
        this.router.navigate(["medical-folder/patient-care-coverage", {search: true, folderId: this.grid.selection.selected[0].id}]);
      }
    }else if(event && event.id === this.buttons[4].id){
      if(this.grid &&  !this.grid.selection.selected || this.grid.selection.selected.length < 1){
        this.msgService.log("medical-folder.list.select.to.print.error", NotificationType.ERROR);
      }else{
        this.print(this.grid.selection.selected[0].id);
      }
    }
  }

  deleteSelected(){
    this.msgService.confirm(["medical-folder.delete.confirm.text"]).subscribe( (result) => {
      if(result === true){
        let selected = this.grid.selection.selected[0];
        this.httpService.deleteById(selected.id, 'admission/medical-folder').subscribe( (val) => {
          this.msgService.log("medical-folder.delete.succed", NotificationType.SUCCESS);
          this.grid.selection.clear();
          this.grid.searchClicked({});
        }, (error) => {
          this.msgService.log("medical-folder.delete.fail", NotificationType.ERROR);
        });
      }
    });
  }


  print(id) {
    this.criteria.folderId=id;
    this.criteria.lang= this.translateService.currentLang;
    this.downloaderComponent.criteria=this.criteria;
    this.downloaderComponent.link = 'admission/medical-folder/print';
    this.downloaderComponent.downloadFile({});
    this.downloaderComponent.event.subscribe( (whellDone) => {
      if (whellDone == false) {
        this.msgService.log("form.print.error", NotificationType.ERROR);
      }
    });
  }


  listSelectionChanged(){
    if(this.btnBarCmp && this.grid){
      const btnStatus = [
        {id: "add", disabled: false, functionalities: this.buttons[0].functionalities},
        {id: "edit", disabled: !(this.grid.selection && this.grid.selection.selected && this.grid.selection.selected.length > 0), functionalities: this.buttons[1].functionalities},
        {id: "delete", disabled: !(this.grid.selection && this.grid.selection.selected && this.grid.selection.selected.length > 0), functionalities: this.buttons[2].functionalities},
        {id: "list", disabled: !(this.grid.selection && this.grid.selection.selected && this.grid.selection.selected.length > 0), functionalities: this.buttons[3].functionalities},
        {id: "print", disabled: !(this.grid.selection && this.grid.selection.selected && this.grid.selection.selected.length > 0), functionalities: this.buttons[4].functionalities},
      ];    
      this.btnBarCmp.setButtonsStatus(btnStatus);
    }
  }

}
