import { Component, OnInit, ChangeDetectorRef, ViewChild, AfterViewInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NkapHttpService } from 'src/app/services/nkap-http.service';
import { NkapNotificationService, NotificationType } from 'src/app/services/nkap-notification.service';
import { Reception, ReceptionStatus } from 'src/app/model/admission/reception';
import { FormGroup, FormControl, Validators, FormBuilder } from '@angular/forms';
import { MedicalFolder, Antecedent, FRCVTypes, SystemInvestigation, AntecedentCategory } from 'src/app/model/admission/medical-folder';
import { TranslatePipe, TranslateService } from '@ngx-translate/core';
import { PhysicalExamComponent } from '../physical-exam/physical-exam.component';
import { AntecedentsFrcvComponent } from '../../antecedents-frcv/antecedents-frcv.component';
import { ConsultationSheet } from 'src/app/model/consultation/consultation.sheet';
import { PhysicalExam } from 'src/app/model/consultation/physical.exam';
import { SystemInvestigationEditComponent } from './system-investigation/system-investigation-edit/system-investigation-edit.component';
import { SearchCriteria } from 'src/app/model/data-page';
import { MedicalAgent } from 'src/app/model/organisation/medical-agent';
import { UserAppService } from 'src/app/services/user-app.service';
import { PatientDiagnosisComponent } from '../patient-diagnosis/patient-diagnosis.component';
import { PatientDiagnosis } from 'src/app/model/consultation/patient-diagnosis';
import { CarePrescriptionEditComponent } from 'src/app/components/prescriptions/care-prescription/care-prescription-edit/care-prescription-edit.component';
import { PrescriptionListComponent } from 'src/app/components/prescriptions/prescription-list/prescription-list.component';
import { ModalComponentService } from 'src/app/components/base/modal/modal.component';
import { FormAction } from 'src/app/components/buttons-bar/buttons-bar.component';

@Component({
  selector: 'app-consultation-sheet',
  templateUrl: './consultation-sheet.component.html',
  styleUrls: ['./consultation-sheet.component.css']
})
export class ConsultationSheetComponent implements OnInit ,AfterViewInit{

  isLinear = false;
  isOptional = false;
  isEditable = true;
  
  consultationSheet:ConsultationSheet;
  reception:Reception;
  consultationAlreadySaved = true;
  loadConsultation=false;
  data:any;
  isValid=false;
  edit=true;

  userDataSystemInvestigation: SystemInvestigation[] = [];
  userDataAntecedent: Antecedent[] = [];
  userdataFRCV: FRCVTypes[] = [];
  physicalExam:PhysicalExam=new PhysicalExam();

  consutationFormGroup: FormGroup = new FormGroup({});
  reasonControl:FormControl;
  historyControl:FormControl;
  observationControl:FormControl;
  physicalExamFormGroup: FormGroup;
  categoryList: {value: string , text: string}[];
  translate: TranslatePipe;

  @ViewChild('physicalExamIdentity')
  physicalExamCmp:PhysicalExamComponent;

  @ViewChild("AntecedentFrcv")
  AntecedentFrcvCmp: AntecedentsFrcvComponent;

  @ViewChild("systemInvestigation")
  SystemInvestigationCmp: SystemInvestigationEditComponent;

  @ViewChild("diagnosisTag")
  diagnosisCmp: PatientDiagnosisComponent;
  diagnosisData: PatientDiagnosis;

  @ViewChild("prescriptionTag")
  prescriptionCmp: PrescriptionListComponent;
  medicalFolder: MedicalFolder;

  /**
   * The doctor connected
   */
  protected doctorConnected: MedicalAgent;

  private consultationSheetId = null;

  constructor(private _ref:ChangeDetectorRef,private _formBuilder:FormBuilder,
     private translateService:TranslateService, private route:ActivatedRoute,
     private httpService:NkapHttpService<any>,private msgService: NkapNotificationService,private router: Router,
     private userService: UserAppService) {
      this.translate = new TranslatePipe(translateService,_ref);
      this.categoryList = [
        {value: AntecedentCategory[AntecedentCategory.PERSONAL], text:"antecedent.category." + AntecedentCategory[AntecedentCategory.PERSONAL] + ".label"},
        {value: AntecedentCategory[AntecedentCategory.FAMILY], text:"antecedent.category." + AntecedentCategory[AntecedentCategory.FAMILY] + ".label"},
      ];
   }

  ngOnInit() {
    this.route.paramMap.subscribe(params => {
      let data: {id?: string, edit?:any} = (params as any).params;
      if(data.edit=="false")
      this.edit=false;
      console.log(this.edit)

      if (data.id != null) {
        this.data=data.id;
         this.httpService.findById(data.id, 'admission/reception',true).subscribe( (reception:Reception)=>{
          this.reception=reception;
          console.log(this.reception);     
          
          this.httpService.search({receptionId: this.data }, 'consultation-sheet').subscribe((results) => {
            this.consultationSheet= new ConsultationSheet();
            if(results.datas[0])
            this.consultationSheet=results.datas[0];
            this.consultationSheetId = this.consultationSheet != null ? this.consultationSheet.id : null;
            console.log(this.consultationSheet);
            this.setObjectInView(this.consultationSheet);
          
           },(error)=>{
            this.msgService.log("consultation.sheet.get.data.error", NotificationType.ERROR);
           });
         
        }, (errors) => {
          this.msgService.log("consultation-sheet.reception.get.data.error", NotificationType.ERROR);
        });
      }
    });
    
  }

  ngAfterViewInit(){
    // Get the MedicalAgent connected
    let userId = this.userService.getUser().number;
    if(userId != null) {
      this.httpService.search({userId: userId ? userId : undefined},`organisation/medical-agent`).subscribe(results => {
        if(results.datas && results.datas.length > 0){
          this.doctorConnected = results.datas[0];
        }
      });
    }
  }

  cancel(){
    this.msgService.confirm("admission-stepper.cancel.confirm.text").subscribe( (result) => {
      if(result === true){
        this.router.navigate(['reception/list', {search:true}]);
      }
    });
  }

  isConsultFormGrpValid(){
    if(this.consutationFormGroup){
      this.isValid = this.consutationFormGroup.valid ? true : false;
      return this.isValid;
    }
    return false;
  }

  isPhysExamFormGrpValid(){
    return true;
    // return this.physicalExamCmp.isValid();
  }

  save(){
    console.log(this.consultationSheet);
    // this.consultationSheet=new ConsultationSheet();
    this.getObjectFromView();
    if (this.isConsultFormGrpValid() === true){
      // get list of prescriptions
      if(this.prescriptionCmp ) {
        this.consultationSheet.prescriptions = this.prescriptionCmp.prescriptions;
      }
      this.httpService.save(this.consultationSheet, 'consultation-sheet', true).subscribe( (result) => {
        this.msgService.log("consultation.sheet.save.succed", NotificationType.SUCCESS);
        this.consultationSheet = result;
        this.consultationSheetId = result? result.id : null;
        //this.router.navigate(['reception/list', {search: true}]);
      }, (error) => {
        if (error && Array.isArray(error)) {
          error.forEach( (err) => {
            this.msgService.log(err, NotificationType.ERROR);
          });
        } else {
          this.msgService.log("consultation.sheet.save.fail", NotificationType.ERROR);
        }
      });
    } else {
      this.msgService.log("consultation.sheet.data.invalid", NotificationType.ERROR);
    }
  }

  close(){
    console.log(this.consultationSheet);
    this.getObjectFromView();
    if (this.isConsultFormGrpValid() === true){
      this.consultationSheet.reception.status=ReceptionStatus.CLOSED;
      console.log(this.consultationSheet);
      this.httpService.save(this.consultationSheet, 'consultation-sheet', true).subscribe( (result) => {
        this.msgService.log("consultation.sheet.close.succed", NotificationType.SUCCESS);
        this.router.navigate(['reception/list', {search: true}]);
      }, (error) => {
        if (error && Array.isArray(error)) {
          error.forEach( (err) => {
            this.msgService.log(err, NotificationType.ERROR);
          });
        } else {
          this.msgService.log("consultation.sheet.close.fail", NotificationType.ERROR);
        }
      });
    } else {
      this.msgService.log("consultation.sheet.data.invalid", NotificationType.ERROR);
    }

  }

  getObjectFromView(){
    if(this.consultationSheet.id){
      this.consultationSheet.physicalExam.toAddOrUpdate=true;
      this.consultationSheet.reception.toAddOrUpdate=true;
    }
    
    this.consultationSheet.antecedents=this.AntecedentFrcvCmp.getData().dataAntecedent;
    this.consultationSheet.frcvTypes=this.AntecedentFrcvCmp.getData().dataFRCV;
    this.consultationSheet.systemInvestigations = this.SystemInvestigationCmp.getData();
    this.consultationSheet.physicalExam=this.physicalExamCmp.getObjectFromView();
    if(this.consultationSheet.id == null){//we set the agent performing the consultation the first time only
      this.consultationSheet.doctor = this.doctorConnected;
    }
    if(this.diagnosisCmp) {
      this.consultationSheet.diagnosis = this.diagnosisCmp.getData();
    }
  }

  setObjectInView(consultationSheet: ConsultationSheet){
    console.log(consultationSheet);
    console.log(this.reception);
    if (consultationSheet && this.reception) {
      this.consultationSheet.reception=this.reception;
      console.log("Enter")
   
      if(this.AntecedentFrcvCmp){
        this.AntecedentFrcvCmp.sheetDataEmitter.emit(true);
      }
      if(consultationSheet.physicalExam){
        this.physicalExam=this.consultationSheet.physicalExam;
        this.physicalExam.edit=this.edit;
      }

      if (consultationSheet.systemInvestigations) {
        this.userDataSystemInvestigation = consultationSheet.systemInvestigations;
      }
      
      this.diagnosisData = consultationSheet.diagnosis;
      
      if(this.consultationSheet.id){
        this.userDataAntecedent=this.consultationSheet.antecedents;
        this.userdataFRCV=this.consultationSheet.frcvTypes;
      }else{
        let folder= new MedicalFolder();
        let crit: any | SearchCriteria;
        crit=new SearchCriteria();
        crit.id=this.reception.patientFolder.id;
        this.httpService.search(crit, 'admission/medical-folder').subscribe((results) => {
          if(results.datas[0])
          folder=results.datas[0];
          console.log(folder);
          this.userDataAntecedent=folder.antecedents;
          this.userdataFRCV=folder.frcvTypes;
         },(error)=>{
          this.msgService.log("consultation-sheet.folder.get.data.error", NotificationType.ERROR);
         });
         // get datas from server
        if(crit.id != null) {
          // We get complete list of antecedents
          this.httpService.get(`admission/antecedent/find-by-medical-folder-id/${crit.id}`).subscribe( (result) => {
            this.userDataAntecedent = result;
          });
          // We get complete list of frcvs
          this.httpService.get(`admission/frcv/find-by-medical-folder-id/${crit.id}`).subscribe( (result) => {
            this.userdataFRCV = result;
          });
        }
      }

      this.medicalFolder = consultationSheet.reception ? 
            consultationSheet.reception.patientFolder : this.reception.patientFolder;

      console.log(consultationSheet);
      
      this.loadConsultation=true;
    }
    this.initFormBuilders(consultationSheet? consultationSheet : new ConsultationSheet());
      
  }

  protected initFormBuilders(consultationSheet) {
      this.reasonControl = new FormControl(consultationSheet.reason, [Validators.required, Validators.minLength(1)]);
      this.reasonControl.updateValueAndValidity();
      this.historyControl = new FormControl(consultationSheet.diseaseHistory, [Validators.required, Validators.minLength(1)]);
      this.historyControl.updateValueAndValidity();
      this.observationControl = new FormControl(consultationSheet.observation);
      this.observationControl.updateValueAndValidity();

      this.consutationFormGroup = new FormGroup({
        reasonControl: this.reasonControl,
        historyControl: this.historyControl,
        observationControl:this.observationControl
      });

      this.physicalExamFormGroup = this._formBuilder.group({
        firstCtrl: ['', Validators.required]
      });

  }

   displayAntecedentFn(antecedent:Antecedent){
    return `[ ${antecedent.type.name} ]  :   ${antecedent.description}`;
  }

  displayCategoryFn(antecedent:Antecedent){
    if(antecedent.category.toString()==AntecedentCategory[AntecedentCategory.PERSONAL])
    return this.categoryList[0].text;
    if(antecedent.category.toString()==AntecedentCategory[AntecedentCategory.FAMILY])
    return this.categoryList[1].text;
  }

  displaySystemsInvestigationFn(systInvestigation :SystemInvestigation){
    return ` ${systInvestigation.systemInvestigationType.name}   :   ${systInvestigation.description}`;
  }

}

