import { Component, OnInit } from '@angular/core';
import { FRCVTypes } from 'src/app/model/admission/medical-folder';
import { FormControl, Validators } from '@angular/forms';
import { IFormActionBarButton, FormAction } from '../../buttons-bar/buttons-bar.component';
import { NkapHttpService } from 'src/app/services/nkap-http.service';
import { Router, ActivatedRoute } from '@angular/router';
import { NkapNotificationService, NotificationType } from 'src/app/services/nkap-notification.service';
import { Funtions } from 'src/app/model/security/funtionalities';

@Component({
  selector: 'app-frcv-edit',
  templateUrl: './frcv-edit.component.html',
  styleUrls: ['./frcv-edit.component.css']
})
export class FrcvEditComponent implements OnInit {

  protected currentObject: FRCVTypes;

  nameControl: FormControl ;

  protected buttons: IFormActionBarButton[];

  constructor(private httpService: NkapHttpService<FRCVTypes>, private router: Router,
              private route: ActivatedRoute, private msgService: NkapNotificationService) {
    this.currentObject = new FRCVTypes();
    this.buttons = [
      {id: "save", value: FormAction.CREATE, icon: {type : 'save'},
        text: 'btn.save.label', disabled: false, functionalities: [Funtions.frcv_btn_new_fct,Funtions.frcv_btn_edit_fct]},
      {id: "list", value: FormAction.VIEW, icon: {type : 'list'},
        text: 'btn.list.label', disabled: false, functionalities: [Funtions.frcv_btn_list_fct]}
    ];
  }

  ngOnInit() {
   this.nameControl = new FormControl('', [Validators.required, Validators.minLength(1)]);
    this.nameControl.updateValueAndValidity();
    this.route.paramMap.subscribe(params => {
      let data: {id?: string} = (params as any).params;
      if (data.id != null) {
        this.httpService.findById(data.id, 'admission/frcv', true).subscribe( (object)=>{
          this.setObjectInView(object);
        }, (errors) => {
          this.msgService.log("frcv.edit.get.data.error", NotificationType.ERROR);
        });
      }
    });   
  }

  btnActionClick(event) {
    if(event && event.id === this.buttons[0].id){
      this.save();
    } else if(event && event.id === this.buttons[1].id){
      this.router.navigate(["frcv/list", {search: true}]);
    }
  }

  isValid(){
    let isValid = true;
    this.getObjectFromView();
    this.nameControl.markAsTouched();
    if(this.nameControl.invalid){
      isValid = false;
    }
    console.log( "isValid" , isValid);
    return isValid;
  }

  getObjectFromView(){
    this.currentObject.name = this.nameControl.value;
  }

  setObjectInView(object : FRCVTypes){
    if(object){
      this.currentObject = object;
      this.nameControl = new FormControl(object.name, [Validators.required, Validators.minLength(1)]);
      this.nameControl.updateValueAndValidity();
    }
  }

  save() {
    if (this.isValid() === true){
      this.httpService.save(this.currentObject, 'admission/frcv', true).subscribe( (result) => {
        this.msgService.log("frcv.edit.save.succed", NotificationType.SUCCESS);
        this.router.navigate(['frcv/list', {search: true}]);
      }, (error) => {
        if (error && Array.isArray(error)) {
          error.forEach( (err) => {
            this.msgService.log(err, NotificationType.ERROR);
          });
        } else {
          this.msgService.log("frcv.edit.save.fail", NotificationType.ERROR);
        }
      });
    }
  }


}
