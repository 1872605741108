import { Exam } from 'src/app/model/exam/exam';
import { Injectable, EventEmitter } from '@angular/core';
import { NkapNotificationService} from './nkap-notification.service';
import { Observable} from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { User } from "../model/security/user";
import { Reception } from '../model/admission/reception';
import { BusinessUnit } from '../model/organisation/business-unit';
import { GlobalVariables } from './global-variables';
import { Base64, ServiceUtils, mapFunctionnality, mapHomePageByGroup} from './utils';
import { SearchCriteria, DataPage } from '../model/data-page';
import { ClassNameMap } from '../model/persistent';
import { NkapKeycloakService } from './nkap-keycloak.service';
import { AccessToken } from '../model/security/access-token';
import { Router } from '@angular/router';
import { Role } from '../model/security/rule';
import { Care } from 'src/app/model/care/care';


@Injectable({
  providedIn: 'root'
})
export class UserAppService {
  
  userToken: AccessToken;

  /**
   * local storage of user navigation data
   */
  protected userNavigationData: Map<string,any> = new Map<string,any>();

  /**
   * Emitter to detect when user lang is changed
   */
  public userLangChanged: EventEmitter<any> = new EventEmitter<any>();
  /**
   * Event emit when the user log in or out
   */
  public userLoggedChanged: EventEmitter<{logged: boolean, user?: User}> = new EventEmitter<{logged: boolean, user?: User}> ();
  
  /**
   * Event emit when user privileges change
   */
  public userPrivilegesChanged: EventEmitter<any> = new EventEmitter<any>();
  // Map of user privileges
  public userPrivileges: Map<string, string[]> = new Map<string, string[]>();
  public allow_all: boolean;
  protected ALL_ACTION: string = "ALL";

  /**
   * Current user connected
   */
  protected userLoggedIn: User;

  /**
   * Service where the user is logged in
   */
  protected currentService: BusinessUnit;

  /**
   * Reception edited in the system
   */
  protected currentReception: Reception;

  /**
   * Exam to plan
   */
  protected exam: Exam;

  /**
   * Treatment to plan
   */
  protected care: Care;

  constructor(private router: Router,private nkapKeycloakService: NkapKeycloakService,private http: HttpClient, private notificationService: NkapNotificationService) {
    this.allow_all = false;
    this.nkapKeycloakService.tokenSubject.subscribe(token => {
      if (token == null) {
        this.forceDisconnect();
        this.router.navigate(GlobalVariables.AFTER_LOGOUT_ROUTE);
        return;
      }
      this.userToken = token;
      localStorage.setItem(GlobalVariables.APP_NAME + '.token', JSON.stringify(this.userToken));
    });

    this.nkapKeycloakService.rulesSubject.subscribe(rules => {
      this.userPrivileges = rules;
      let action = this.userPrivileges.get(GlobalVariables.KEYCLOAK_RULES_ATTRIBUTE).find(c => c === Role.ADMIN);
      if (action) {
        this.allow_all = true;
      }
    });
  }
  

  protected getHttpOptions() {
      return {
        headers: new HttpHeaders(
          {
              'Content-Type': 'application/json',
          })
    };
  }

  public isUserLoggedIn(): boolean {
    this.getUser();
    this.getService();
    return this.userPrivileges && NkapKeycloakService.accessToken && NkapKeycloakService.accessToken.access_token && this.userLoggedIn != null && this.userLoggedIn.login != null 
    && this.currentService && this.currentService.id != null ? true : false;
  }

  public userConnect(user: User) {
    const failMessage = 'user-connect.fail.error';
    const succedMessage = 'user-connect.succed.message';
    const loggedService = user.loggedInService;
    return this.http.post<any>(`${GlobalVariables.CONTEXT_PATH}/security/connect`,
                              user, this.getHttpOptions()).pipe(
       tap((result: AccessToken) => {
        user.id = result.user.id;
        user.number = result.user.number;
        this.forceConnect(user);
         /* this.notificationService.log(succedMessage, NotificationType.SUCCESS); */
        }),
       catchError(ServiceUtils.handleError)
    );
  }

  public forceConnect(user: User) {
    this.userLoggedIn = {login: user.login, id: user.id, number: user.number ,classe: ClassNameMap.User}; // we remove password for security
    this.currentService = user.loggedInService;
    localStorage.setItem(GlobalVariables.APP_NAME + '.user', JSON.stringify(this.userLoggedIn));
    localStorage.setItem(GlobalVariables.APP_NAME + '.service', 
    JSON.stringify({id: this.currentService.id, name: this.currentService.name, classe: ClassNameMap.BusinessUnit}));
    let token = Base64.encode( user.login + ":" + user.password );
    localStorage.setItem(GlobalVariables.APP_NAME + '.token', JSON.stringify(this.userToken));
    this.userLoggedChanged.emit({logged: true, user: this.userLoggedIn});
  }

  public userDisconnect(){
    const failMessage = 'user-disconnect.fail.error';
    const succedMessage = 'user-disconnect.succed.message';
    this.forceDisconnect();
    const headers = {headers : new HttpHeaders(
      {
          'Content-Type': 'application/json',
          'dataType': 'json',
          'Authorization': 'Bearer ' + this.userToken.access_token
      })
    } ;
    const criteria = new SearchCriteria();
    criteria.value =  this.userToken.refresh_token;
    return this.http.post<any>(`${GlobalVariables.CONTEXT_PATH}/security/disconnect`,
                              criteria, headers).pipe(
       tap(_ => {
        /* this.notificationService.log(succedMessage, NotificationType.SUCCESS);*/
        }),
       catchError(ServiceUtils.handleError)
    );
  }

  public forceDisconnect() {
    localStorage.removeItem(GlobalVariables.APP_NAME + '.user');
    localStorage.removeItem(GlobalVariables.APP_NAME + '.service');
    localStorage.removeItem(GlobalVariables.APP_NAME + '.token');
    this.currentReception = null;
    this.userLoggedIn = null;
    this.userLoggedChanged.emit({logged: false});
  }

  public searchService(criteria: SearchCriteria | any): Observable<DataPage<any>> {
      const baseURI = 'organisation/business-unit';
      const baseMessage = `${baseURI.replace(new RegExp('/', 'g'), '.')}.search`;
      const succedMessage = `${baseMessage}.succed.message`;
      return this.http.post<any>(`${GlobalVariables.CONTEXT_PATH}/${baseURI}/search`,
                               criteria, this.getHttpOptions()).pipe(
            /*tap(_ => this.notificationService.log(succedMessage, NotificationType.SUCCESS)),*/
            catchError(ServiceUtils.handleError)
     );
  }

  public getUserAppLabel(): string {
    const user = this.getUser();
    const service = this.getService();
    const text = (user ? user.login : '' ) + ' [' + (service ? service.name : '') + ']';
    return text;
  }

  public setReception(reception: Reception) {
    this.currentReception = reception;
  }

  public getReception() : Reception{
    return this.currentReception;
  }

  setExam(exam: Exam) {
    this.exam = exam;
  }
  getExam() : Exam{
    return this.exam;
  }

  setCare(care: Care) {
    this.care = care;
  }
  getCare() : Care{
    return this.care;
  }

  public getUser(): User {
    if(!this.userLoggedIn){
      let userStore = localStorage.getItem(GlobalVariables.APP_NAME + '.user');
      this.userLoggedIn = userStore ? JSON.parse(userStore) : null;
    }
    if(!this.userLoggedIn ){ 
      this.userLoggedIn = {id: null, classe: ClassNameMap.User}; 
      this.userLoggedIn.classe = ClassNameMap.User;
    }
    return this.userLoggedIn;
  }

  public getService(): BusinessUnit {
    if(!this.currentService){
      let serviceStore = localStorage.getItem(GlobalVariables.APP_NAME + '.service');
      this.currentService = serviceStore ? JSON.parse(serviceStore) : {};
    }
    this.currentService.classe = ClassNameMap.BusinessUnit;
    return this.currentService;
  }

  private getUSerAccess(menuCode: string) {
    let role = mapFunctionnality.get(menuCode);
    //if role don't exist inside a map return no access
    if (!role) return false;
    return this.evaluateUserRole(role);
  }

  private evaluateUserRole(role: string) {
    if (!NkapKeycloakService.accessToken || 
      !this.userPrivileges ||
      !this.userPrivileges.get(GlobalVariables.KEYCLOAK_RULES_ATTRIBUTE) ||
      this.userPrivileges.get(GlobalVariables.KEYCLOAK_RULES_ATTRIBUTE).length === 0) return false;
    return this.userPrivileges.get(GlobalVariables.KEYCLOAK_RULES_ATTRIBUTE).includes(role);
  }

  /**
   * Check if a user has access to some functionnality
   */
  public hasAccess(menuCode: string): boolean {
    if (this.allow_all === true) return true;
    if(!menuCode) return false;
    if (menuCode.includes("cancel") || (menuCode.includes("select"))) return true;

    return this.getUSerAccess(menuCode);
}

  public updateNavigationData(componentName:string , data: any ){
    if(componentName != null ){
      this.userNavigationData.set(componentName, data);
    }
  }

  public getHomeUrl(): string[] {
    let defaultPage: string[] = GlobalVariables.AFTER_LOGIN_ROUTE;
    if (!NkapKeycloakService.accessToken || 
      !this.userPrivileges ||
      !this.userPrivileges.get(GlobalVariables.KEYCLOAK_RULES_ATTRIBUTE) ||
      this.userPrivileges.get(GlobalVariables.KEYCLOAK_RULES_ATTRIBUTE).length === 0 ) return defaultPage;
    return this.evaluateGroup(this.userPrivileges.get(GlobalVariables.KEYCLOAK_RULES_ATTRIBUTE), defaultPage);
  }

  private evaluateGroup(groups: string[], defaultPage: string[]) {
    if(this.allow_all) return ['reception/admission'];
    if (!mapHomePageByGroup) return defaultPage;
    groups.forEach(group => {
      const homePage = mapHomePageByGroup.get(group);
      if (homePage) {
        defaultPage = homePage;
        return;
      }
    });
    return defaultPage;
  }

  public getNavigationData(componentName:string){
    if(componentName != null ){
      return this.userNavigationData.get(componentName);
    }
    return null;
  }
}


