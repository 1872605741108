import { ExamListComponent } from './components/exam/exam-list/exam-list.component';
import { NgModule, Component } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { HomeComponent } from './components/reception/home/home.component';
import { DashboardComponent } from './components/dashboard/dashboard.component';
import { AdmissionStepperComponent } from './components/admission-stepper/admission-stepper.component';
import { LoginComponent } from './components/login/login.component';
import { PrestationEditComponent } from './components/prestation/prestation-edit/prestation-edit.component';
import { PrestationShowComponent } from './components/prestation/prestation-show/prestation-show.component';
import { PrestationListComponent } from './components/prestation/prestation-list/prestation-list.component';
import { PrestationFamilyEditComponent } from './components/prestation/prestation-family/prestation-family-edit/prestation-family-edit.component';
import { PrestationFamilyShowComponent } from './components/prestation/prestation-family/prestation-family-show/prestation-family-show.component';
import { PrestationFamilyListComponent } from './components/prestation/prestation-family/prestation-family-list/prestation-family-list.component';
import { FrcvEditComponent } from './components/frcv/frcv-edit/frcv-edit.component';
import { FrcvShowComponent } from './components/frcv/frcv-show/frcv-show.component';
import { FrcvListComponent } from './components/frcv/frcv-list/frcv-list.component';
import { AntecedentEditComponent } from './components/antecedent/antecedent-edit/antecedent-edit.component';
import { AntecedentShowComponent } from './components/antecedent/antecedent-show/antecedent-show.component';
import { AntecedentListComponent } from './components/antecedent/antecedent-list/antecedent-list.component';
import { UserParametersEditComponent } from './components/user/user-parameters-edit/user-parameters-edit.component';
import { UserParametersShowComponent } from './components/user/user-parameters-show/user-parameters-show.component';
import { ApointmentEditComponent } from './components/apointment/apointment-edit/apointment-edit.component';
import { ApointmentListComponent } from './components/apointment/apointment-list/apointment-list.component';
import { CouvertureSanteComponent } from './components/couverture-sante/couverture-sante.component';
import { ApointmentShowComponent } from './components/apointment/apointment-show/apointment-show.component';
import { PartyComponent } from './components/party/party.component';
import { ThirdPartyPayerEditComponent } from './components/partner/third-party-payer/third-party-payer-edit/third-party-payer-edit.component';
import { ThirdPartyPayerListComponent } from './components/partner/third-party-payer/third-party-payer-list/third-party-payer-list.component';
import { ThirdPartyPayerShowComponent } from './components/partner/third-party-payer/third-party-payer-show/third-party-payer-show.component';
import { DoctorEditComponent } from './components/partner/doctor/doctor-edit/doctor-edit.component';
import { DoctorListComponent } from './components/partner/doctor/doctor-list/doctor-list.component';
import { DoctorShowComponent } from './components/partner/doctor/doctor-show/doctor-show.component';
import { ReceptionListComponent } from './components/reception/reception-list/reception-list.component';
import { DoctorPlaningComponent } from './components/partner/doctor/doctor-planing/doctor-planing.component';
import { BusinessUnitEditComponent } from './components/business-unit/business-unit-edit/business-unit-edit.component';
import { BusinessUnitListComponent } from './components/business-unit/business-unit-list/business-unit-list.component';
import { RouteGuardGuard } from './services/route-guard.guard';
import { PrestationParamsComponent } from './components/prestation/prestation-params/prestation-params.component';
import { ReceptionShowComponent } from './components/reception/reception-show/reception-show.component';
import { MedicalFolderShowComponent } from './components/medical-folder/medical-folder-show/medical-folder-show.component';
import { MedicalFolderListComponent } from './components/medical-folder/medical-folder-list/medical-folder-list.component';
import { ChronologicalListComponent } from './components/reception/chronological-list/chronological-list.component';
import { PatientCareCoverageDocListComponent } from './components/care-coverage-doc/patient-care-coverage-doc-list/patient-care-coverage-doc-list.component';
import { PatientCareCoverageDocEditComponent } from './components/care-coverage-doc/patient-care-coverage-doc-edit/patient-care-coverage-doc-edit.component';
import { CareCoverageDocShowComponent } from './components/care-coverage-doc/care-coverage-doc-show/care-coverage-doc-show.component';
import { ReceptionCareCoverageDocEditComponent } from './components/reception/reception-care-coverage-doc-edit/reception-care-coverage-doc-edit.component';
import { Role } from './model/security/rule';
import { ConsultationSheetComponent } from './components/consultation/consultation-sheet/consultation-sheet.component';
import { AntecedentTypeEditComponent } from './components/antecedent/antecedent-type/antecedent-type-edit/antecedent-type-edit.component';
import { SystemInvestigationEditComponent } from './components/consultation/consultation-sheet/system-investigation/system-investigation-edit/system-investigation-edit.component';
import { CostsRegulationsStatementComponent } from './components/reporting/costs-regulations-statement/costs-regulations-statement.component';
import { SystemInvestigationTypeListComponent } from './components/system-investigation-type/system-investigation-type-list/system-investigation-type-list.component';
import { SystemInvestigationTypeEditComponent } from './components/system-investigation-type/system-investigation-type-edit/system-investigation-type-edit.component';
import { AntecedentTypeShowComponent } from './components/antecedent/antecedent-type/antecedent-type-show/antecedent-type-show.component';
import { AntecedentTypeListComponent } from './components/antecedent/antecedent-type/antecedent-type-list/antecedent-type-list.component';
import { CounterActivitiesComponent } from './components/reporting/counter-activities/counter-activities.component';
import { BillingOfServicesComponent } from './components/reporting/billing-of-services/billing-of-services.component';
import { ExamPrescriptionShowComponent } from 'src/app/components/prescriptions/exam-prescription/exam-prescription-show/exam-prescription-show.component';
import { ExamPrescriptionEditComponent } from 'src/app/components/prescriptions/exam-prescription/exam-prescription-edit/exam-prescription-edit.component';
import { CarePrescriptionEditComponent } from './components/prescriptions/care-prescription/care-prescription-edit/care-prescription-edit.component';
import { CarePrescriptionListComponent } from './components/prescriptions/care-prescription/care-prescription-list/care-prescription-list.component';
import { CarePrescriptionShowComponent } from 'src/app/components/prescriptions/care-prescription/care-prescription-show/care-prescription-show.component';
import { MedicalPrescriptionEditComponent } from './components/prescriptions/medical-prescription/medical-prescription-edit/medical-prescription-edit.component';
import { MedicalPrescriptionShowComponent } from './components/prescriptions/medical-prescription/medical-prescription-show/medical-prescription-show.component';
import { PrescriptionListComponent } from 'src/app/components/prescriptions/prescription-list/prescription-list.component';
import { MedicalPrescriptionListComponent } from './components/prescriptions/medical-prescription/medical-prescription-list/medical-prescription-list.component';
import { ExamPrescriptionListComponent } from './components/prescriptions/exam-prescription/exam-prescription-list/exam-prescription-list.component';
import { ExamShowComponent } from './components/exam/exam-show/exam-show.component';
import { ExamResultEditComponent } from './components/exam/exam-result-edit/exam-result-edit.component';
import { ExamAssessmentEditComponent } from './components/exam/exam-assessment-edit/exam-assessment-edit.component';
import { CareExecutionEditComponent } from 'src/app/components/care/care-execution-edit/care-execution-edit.component';
import { CareShowComponent } from 'src/app/components/care/care-show/care-show.component';
import { CareListComponent } from 'src/app/components/care/care-list/care-list.component';
import { MedicalInstitutionPartnerEditComponent } from './components/partner/medical-institution-partner/medical-institution-partner-edit/medical-institution-partner-edit.component';
import { MedicalInstitutionPartnerShowComponent } from './components/partner/medical-institution-partner/medical-institution-partner-show/medical-institution-partner-show.component';
import { MedicalInstitutionPartnerListComponent } from './components/partner/medical-institution-partner/medical-institution-partner-list/medical-institution-partner-list.component';
import { ExamProcessingEditComponent } from './components/exam/exam-processing-edit/exam-processing-edit.component';

const routes: Routes = [
  { path: '', redirectTo: '/login', pathMatch: 'full' , canActivate:[RouteGuardGuard]},
  { path: 'logout', redirectTo: '/login', pathMatch: 'full' , canActivate:[RouteGuardGuard]},
  { path: 'login', component: LoginComponent , canActivate:[RouteGuardGuard]},
  {path:"dashboard", component: DashboardComponent, canActivate:[RouteGuardGuard]},
  {path:"party", component: PartyComponent, canActivate:[RouteGuardGuard]},
  {path:"user-parameters", canActivate: [RouteGuardGuard], data : {roles: [Role.SHOW_USER, Role.EDIT_USER]}, children: [
    {path:"edit", component: UserParametersEditComponent , data : {roles: [Role.SHOW_USER, Role.EDIT_USER]}, canActivate:[RouteGuardGuard]},
    {path:"show", component: UserParametersShowComponent, data : {roles: [Role.SHOW_USER, Role.EDIT_USER]}, canActivate:[RouteGuardGuard]}
  ]},
  {path:"third-party-payer", canActivate:[RouteGuardGuard], data: {roles: [Role.EDIT_THIRD_PARTY_PAYER, Role.CREATE_THIRD_PARTY_PAYER_INSURANCE, Role.CREATE_THIRD_PARTY_PAYER_EMPLOYER, Role.CREATE_THIRD_PARTY_PAYER_PERSON, Role.SHOW_THIRD_PARTY_PAYER]}, children: [
    {path:"edit", component: ThirdPartyPayerEditComponent, canActivate:[RouteGuardGuard], data: {roles: [Role.EDIT_THIRD_PARTY_PAYER,Role.CREATE_THIRD_PARTY_PAYER_INSURANCE,Role.CREATE_THIRD_PARTY_PAYER_EMPLOYER,Role.CREATE_THIRD_PARTY_PAYER_PERSON]}},
    {path:"list", component: ThirdPartyPayerListComponent, canActivate:[RouteGuardGuard], data: {roles: [Role.SHOW_THIRD_PARTY_PAYER, Role.EDIT_THIRD_PARTY_PAYER, Role.DELETE_THIRD_PARTY_PAYER,Role.CREATE_THIRD_PARTY_PAYER_INSURANCE,Role.CREATE_THIRD_PARTY_PAYER_EMPLOYER,Role.CREATE_THIRD_PARTY_PAYER_PERSON]}},
    {path:"show", component: ThirdPartyPayerShowComponent, canActivate:[RouteGuardGuard], data: {roles: [Role.SHOW_THIRD_PARTY_PAYER, Role.EDIT_THIRD_PARTY_PAYER, Role.DELETE_THIRD_PARTY_PAYER]}}
  ]},
  {path:"doctor", canActivate:[RouteGuardGuard], data: {roles: [Role.EDIT_DOCTOR, Role.SHOW_DOCTOR, Role.DELETE_DOCTOR, Role.CREATE_DOCTOR, Role.DOCTOR_DIARY, Role.SHOW_DOCTOR_DIARY, Role.SHOW_HIM_DIARY]},
  children: [
    {path:"edit", component: DoctorEditComponent, canActivate:[RouteGuardGuard], data: {roles: [Role.EDIT_DOCTOR, Role.SHOW_DOCTOR, Role.CREATE_DOCTOR]}},
    {path:"list", component: DoctorListComponent, canActivate:[RouteGuardGuard], data: {roles: [Role.EDIT_DOCTOR, Role.SHOW_DOCTOR, Role.DELETE_DOCTOR, Role.CREATE_DOCTOR, Role.DOCTOR_DIARY, Role.SHOW_DOCTOR_DIARY]}},
    {path:"show", component: DoctorShowComponent, canActivate:[RouteGuardGuard], data: {roles: [Role.EDIT_DOCTOR, Role.SHOW_DOCTOR, Role.DELETE_DOCTOR]}},
    {path:"planing", component: DoctorPlaningComponent, canActivate:[RouteGuardGuard], data: {roles: [Role.SHOW_DOCTOR_DIARY, Role.DOCTOR_DIARY, Role.SHOW_HIM_DIARY]}},
    {path:"**", redirectTo:"planing"}
  ]},
  {path:"business-unit", canActivate:[RouteGuardGuard], data: {roles: [Role.EDIT_BUSINESSUNIT, Role.SHOW_BUSINESSUNIT, Role.DELETE_BUSINESSUNIT, Role.CREATE_BUSINESSUNIT]},
    children: [
      {path:"edit", component: BusinessUnitEditComponent, canActivate:[RouteGuardGuard], data: {roles: [Role.EDIT_BUSINESSUNIT, Role.CREATE_BUSINESSUNIT]}},
      {path:"list", component: BusinessUnitListComponent, canActivate:[RouteGuardGuard], data: {roles: [Role.EDIT_BUSINESSUNIT, Role.SHOW_BUSINESSUNIT, Role.DELETE_BUSINESSUNIT, Role.CREATE_BUSINESSUNIT]}}
    ]},
  {path:"reception", canActivate:[RouteGuardGuard], data: {roles: [Role.SHOW_RECEPTION,Role.CREATE_RECEPTION, Role.EDIT_RECEPTION, Role.DELETE_RECEPTION,Role.CONSULTATION_HOME,Role.EXAMS_HOME,Role.TREATMENTS_HOME,Role.CREATE_PATIENT,Role.EDIT_PATIENT,Role.SHOW_CARECOVERAGEDOC, Role.EDIT_CARECOVERAGEDOC, Role.CREATE_CARECOVERAGEDOC, Role.PRINT_PATIENT,Role.SHOW_CONSULTATION_SHEET]}, children: [
    {path:"list", component: ReceptionListComponent, canActivate:[RouteGuardGuard], data: {roles: [Role.SHOW_RECEPTION, Role.EDIT_RECEPTION, Role.DELETE_RECEPTION, Role.SHOW_CARECOVERAGEDOC, Role.EDIT_CARECOVERAGEDOC, Role.CREATE_CARECOVERAGEDOC, Role.PRINT_PATIENT]}},
    {path:"show", component: ReceptionShowComponent, canActivate:[RouteGuardGuard], data: {roles: [Role.SHOW_RECEPTION, Role.EDIT_RECEPTION, Role.DELETE_RECEPTION]}},
    {path:"home", component: HomeComponent, canActivate:[RouteGuardGuard],data: {roles: [Role.CONSULTATION_HOME, Role.EXAMS_HOME, Role.TREATMENTS_HOME, Role.CREATE_RECEPTION,Role.EDIT_RECEPTION]}},
    {path:"admission", component: AdmissionStepperComponent, canActivate:[RouteGuardGuard],data: {roles: [Role.CREATE_PATIENT, Role.EDIT_PATIENT,Role.CREATE_RECEPTION, Role.EDIT_RECEPTION]}},
    {path:"care-coverage", component: ReceptionCareCoverageDocEditComponent, canActivate:[RouteGuardGuard], data: {roles: [Role.SHOW_CARECOVERAGEDOC, Role.EDIT_CARECOVERAGEDOC, Role.CREATE_CARECOVERAGEDOC]}},
    {path:"consultation-sheet", component: ConsultationSheetComponent, canActivate:[RouteGuardGuard], data: {roles: [Role.CLOSE_RECEPTION,Role.SHOW_CONSULTATION_SHEET]}}
  ]},
  {path:"prestation", canActivate:[RouteGuardGuard], data: {roles: [Role.EDIT_PRESTATION, Role.SHOW_PRESTATION, Role.DELETE_PRESTATION, Role.CREATE_PRESTATION, Role.PARAMS_PRESTATION]},
    children: [
      {path:"edit", component: PrestationEditComponent, canActivate:[RouteGuardGuard], data: {roles: [Role.EDIT_PRESTATION, Role.CREATE_PRESTATION]}},
      {path:"show", component: PrestationShowComponent, canActivate:[RouteGuardGuard], data: {roles: [Role.EDIT_PRESTATION, Role.SHOW_PRESTATION, Role.DELETE_PRESTATION]}},
      {path:"list", component: PrestationListComponent, canActivate:[RouteGuardGuard], data: {roles: [Role.EDIT_PRESTATION, Role.SHOW_PRESTATION, Role.DELETE_PRESTATION, Role.CREATE_PRESTATION, Role.PARAMS_PRESTATION]}},
      {path:"parameters", component: PrestationParamsComponent, canActivate:[RouteGuardGuard], data: {roles: [Role.PARAMS_PRESTATION]}}
    ]},
  {path:"prestation-family", canActivate:[RouteGuardGuard], data: {roles: [Role.EDIT_PRESTATION_FAMILY, Role.SHOW_PRESTATION_FAMILY, Role.DELETE_PRESTATION_FAMILY, Role.CREATE_PRESTATION_FAMILY]},
    children: [
      {path:"edit", component: PrestationFamilyEditComponent, canActivate:[RouteGuardGuard], data: {roles: [Role.EDIT_PRESTATION_FAMILY, Role.CREATE_PRESTATION_FAMILY]},},
      {path:"show", component: PrestationFamilyShowComponent, canActivate:[RouteGuardGuard], data: {roles: [Role.EDIT_PRESTATION_FAMILY, Role.SHOW_PRESTATION_FAMILY, Role.DELETE_PRESTATION_FAMILY]},},
      {path:"list", component: PrestationFamilyListComponent, canActivate:[RouteGuardGuard], data: {roles: [Role.EDIT_PRESTATION_FAMILY, Role.SHOW_PRESTATION_FAMILY, Role.DELETE_PRESTATION_FAMILY, Role.CREATE_PRESTATION_FAMILY]}}
    ]},
  {path:"frcv", canActivate:[RouteGuardGuard], data: {roles: [Role.EDIT_FRCV, Role.SHOW_FRCV, Role.DELETE_FRCV, Role.CREATE_FRCV]},
    children: [
      {path:"edit", component: FrcvEditComponent, canActivate:[RouteGuardGuard] , data: {roles: [Role.EDIT_FRCV, Role.CREATE_FRCV]}},
      {path:"show", component: FrcvShowComponent, canActivate:[RouteGuardGuard], data: {roles: [Role.EDIT_FRCV, Role.SHOW_FRCV, Role.DELETE_FRCV]}},
      {path:"list", component: FrcvListComponent, canActivate:[RouteGuardGuard], data: {roles: [Role.EDIT_FRCV, Role.SHOW_FRCV, Role.DELETE_FRCV, Role.CREATE_FRCV]}}
    ]},
  {path:"antecedent", canActivate:[RouteGuardGuard],data: {roles: [Role.EDIT_ANTECEDENT, Role.SHOW_ANTECEDENT, Role.DELETE_ANTECEDENT, Role.CREATE_ANTECEDENT]},
    children: [
      {path:"edit", component: AntecedentEditComponent, canActivate:[RouteGuardGuard], data: {roles: [Role.EDIT_ANTECEDENT, Role.CREATE_ANTECEDENT]}},
      {path:"show", component: AntecedentShowComponent, canActivate:[RouteGuardGuard],data: {roles: [Role.EDIT_ANTECEDENT, Role.SHOW_ANTECEDENT, Role.DELETE_ANTECEDENT]}},
      {path:"list", component: AntecedentListComponent, canActivate:[RouteGuardGuard],data: {roles: [Role.EDIT_ANTECEDENT, Role.SHOW_ANTECEDENT, Role.DELETE_ANTECEDENT, Role.CREATE_ANTECEDENT]}},
    ]},

    {path:"antecedent-type", canActivate:[RouteGuardGuard],data: {roles: [Role.CREATE_ANTECEDENT_TYPE, Role.EDIT_ANTECEDENT_TYPE,Role.DELETE_ANTECEDENT_TYPE,Role.SHOW_ANTECEDENT_TYPE]},
    children: [
      {path:"edit", component: AntecedentTypeEditComponent, canActivate:[RouteGuardGuard], data: {roles: [Role.EDIT_ANTECEDENT_TYPE, Role.CREATE_ANTECEDENT_TYPE]}},
      {path:"show", component: AntecedentTypeShowComponent, canActivate:[RouteGuardGuard],data: {roles: [Role.EDIT_ANTECEDENT_TYPE, Role.SHOW_ANTECEDENT_TYPE, Role.DELETE_ANTECEDENT_TYPE]}},
      {path:"list", component: AntecedentTypeListComponent, canActivate:[RouteGuardGuard],data: {roles: [Role.EDIT_ANTECEDENT_TYPE, Role.SHOW_ANTECEDENT_TYPE, Role.DELETE_ANTECEDENT_TYPE, Role.CREATE_ANTECEDENT_TYPE]}},
    ]},

  {path:"apointment", canActivate:[RouteGuardGuard], data: {roles: [Role.EDIT_APOINTMENT, Role.SHOW_APOINTMENT, Role.DELETE_APOINTMENT, Role.CREATE_APOINTMENT,Role.ACHIEVED_APOINTMENT, Role.CLOSE_CHRONOLOGICALLIST, Role.PRINT_CHRONOLOGICALLIST, Role.SHOW_CHRONOLOGICALLIST,Role.CREATE_RECEPTION, Role.EDIT_RECEPTION, Role.EXAMS_HOME, Role.CONSULTATION_HOME]},
    children: [
      {path:"edit", component: ApointmentEditComponent, canActivate:[RouteGuardGuard], data: {roles: [Role.EDIT_APOINTMENT,  Role.CREATE_APOINTMENT,Role.CREATE_RECEPTION, Role.EDIT_RECEPTION, Role.CONSULTATION_HOME, Role.EXAMS_HOME]}},
      {path:"show", component: ApointmentShowComponent, canActivate:[RouteGuardGuard], data: {roles: [Role.EDIT_APOINTMENT, Role.SHOW_APOINTMENT, Role.DELETE_APOINTMENT, Role.ACHIEVED_APOINTMENT]},},
      {path:"list", component: ApointmentListComponent, canActivate:[RouteGuardGuard], data: {roles: [Role.EDIT_APOINTMENT, Role.SHOW_APOINTMENT, Role.DELETE_APOINTMENT, Role.CREATE_APOINTMENT,Role.ACHIEVED_APOINTMENT]},},
      {path:"chronological-list", component: ChronologicalListComponent, canActivate:[RouteGuardGuard], data: {roles: [Role.CLOSE_CHRONOLOGICALLIST, Role.PRINT_CHRONOLOGICALLIST,Role.SHOW_CHRONOLOGICALLIST]}}
    ]},

  {path:"system-investigation", canActivate:[RouteGuardGuard], data: {roles: [Role.EDIT_SYSTEM_INVESTIGATION, Role.SHOW_SYSTEM_INVESTIGATION, Role.DELETE_SYSTEM_INVESTIGATION, Role.CREATE_SYSTEM_INVESTIGATION]},
    children: [
      {path:"edit", component: SystemInvestigationEditComponent, canActivate:[RouteGuardGuard], data: {roles: [Role.EDIT_SYSTEM_INVESTIGATION, Role.CREATE_SYSTEM_INVESTIGATION]}},
    ]},
    
  {path:"system-investigation-type", canActivate:[RouteGuardGuard], data: {roles: [Role.EDIT_SYSTEM_INVESTIGATION_TYPE, Role.SHOW_SYSTEM_INVESTIGATION_TYPE, Role.DELETE_SYSTEM_INVESTIGATION_TYPE, Role.CREATE_SYSTEM_INVESTIGATION_TYPE]},
    children: [
      {path:"edit", component: SystemInvestigationTypeEditComponent, canActivate:[RouteGuardGuard], data: {roles: [Role.EDIT_SYSTEM_INVESTIGATION_TYPE, Role.CREATE_SYSTEM_INVESTIGATION_TYPE]}},
      {path:"list", component: SystemInvestigationTypeListComponent, canActivate:[RouteGuardGuard], data: {roles: [Role.EDIT_SYSTEM_INVESTIGATION_TYPE, Role.SHOW_SYSTEM_INVESTIGATION_TYPE, Role.DELETE_SYSTEM_INVESTIGATION_TYPE, Role.CREATE_SYSTEM_INVESTIGATION_TYPE]}},
    ]},

  {path:"medical-folder", canActivate: [RouteGuardGuard], data: {roles: [Role.CREATE_PATIENT,Role.SHOW_MEDICAL_FOLDER, Role.EDIT_PATIENT, Role.DELETE_PATIENT,Role.PRINT_PATIENT,Role.CREATE_CARECOVERAGEDOC,Role.EDIT_CARECOVERAGEDOC,Role.DELETE_CARECOVERAGEDOC,Role.SHOW_CARECOVERAGEDOC,Role.SHOW_PATIENT_HISTORY,Role.SHOW_CONSULTATION_SHEET]}, children: [
    {path:"show", component: MedicalFolderShowComponent, canActivate:[RouteGuardGuard], data: {roles: [Role.SHOW_MEDICAL_FOLDER, Role.EDIT_PATIENT, Role.DELETE_PATIENT,Role.SHOW_CARECOVERAGEDOC,Role.EDIT_CARECOVERAGEDOC,Role.DELETE_CARECOVERAGEDOC,Role.SHOW_PATIENT_HISTORY,Role.SHOW_CONSULTATION_SHEET]}},
    {path:"list", component: MedicalFolderListComponent, canActivate:[RouteGuardGuard], data: {roles: [Role.CREATE_PATIENT,Role.EDIT_PATIENT, Role.PRINT_PATIENT,Role.SHOW_MEDICAL_FOLDER, Role.DELETE_PATIENT,Role.CREATE_CARECOVERAGEDOC,Role.EDIT_CARECOVERAGEDOC,Role.DELETE_CARECOVERAGEDOC,Role.SHOW_CARECOVERAGEDOC,Role.SHOW_PATIENT_HISTORY,Role.SHOW_CONSULTATION_SHEET]}},
    {path:"patient-care-coverage", canActivate:[RouteGuardGuard], data: {roles: [Role.CREATE_CARECOVERAGEDOC, Role.EDIT_CARECOVERAGEDOC,Role.DELETE_CARECOVERAGEDOC, Role.SHOW_CARECOVERAGEDOC]},
      children : [
        {path:"", component: PatientCareCoverageDocListComponent, canActivate:[RouteGuardGuard]},
        {path:"edit", component: PatientCareCoverageDocEditComponent, canActivate:[RouteGuardGuard]},
        {path:"show", component: CareCoverageDocShowComponent, canActivate:[RouteGuardGuard]},
        {path:"list", component: PatientCareCoverageDocListComponent, canActivate:[RouteGuardGuard]}
      ]
    }
  ]},

  {path:"prescription", canActivate:[RouteGuardGuard], data: {roles: [Role.EDIT_CARE_PRESCRIPTION, Role.SHOW_CARE_PRESCRIPTION, Role.DELETE_CARE_PRESCRIPTION, Role.CREATE_CARE_PRESCRIPTION, Role.PRINT_CARE_PRESCRIPTION,
      Role.EDIT_EXAM_PRESCRIPTION, Role.SHOW_EXAM_PRESCRIPTION, Role.DELETE_EXAM_PRESCRIPTION, Role.CREATE_EXAM_PRESCRIPTION, Role.PRINT_EXAM_PRESCRIPTION,
      Role.EDIT_MEDICAL_PRESCRIPTION, Role.SHOW_MEDICAL_PRESCRIPTION, Role.DELETE_MEDICAL_PRESCRIPTION, Role.CREATE_MEDICAL_PRESCRIPTION, Role.PRINT_MEDICAL_PRESCRIPTION]},
    children: [
      {path:"list", component: PrescriptionListComponent, canActivate:[RouteGuardGuard], data: {roles: [Role.EDIT_CARE_PRESCRIPTION, Role.SHOW_CARE_PRESCRIPTION, Role.DELETE_CARE_PRESCRIPTION, 
         Role.CREATE_CARE_PRESCRIPTION, Role.PRINT_CARE_PRESCRIPTION, Role.CLOSE_CARE_PRESCRIPTION,
         Role.EDIT_EXAM_PRESCRIPTION, Role.SHOW_EXAM_PRESCRIPTION, Role.DELETE_EXAM_PRESCRIPTION, 
         Role.CREATE_EXAM_PRESCRIPTION, Role.PRINT_EXAM_PRESCRIPTION, Role.CLOSE_EXAM_PRESCRIPTION,
         Role.EDIT_MEDICAL_PRESCRIPTION, Role.SHOW_MEDICAL_PRESCRIPTION, Role.DELETE_MEDICAL_PRESCRIPTION, 
         Role.CREATE_MEDICAL_PRESCRIPTION, Role.PRINT_MEDICAL_PRESCRIPTION, Role.CLOSE_MEDICAL_PRESCRIPTION]}}
  ]},

  {path:"exam-prescription", canActivate:[RouteGuardGuard], data: {roles: [Role.EDIT_EXAM_PRESCRIPTION, Role.SHOW_EXAM_PRESCRIPTION,
       Role.CREATE_EXAM_PRESCRIPTION, Role.PRINT_EXAM_PRESCRIPTION]},
    children: [
      {path:"edit", component: ExamPrescriptionEditComponent, canActivate:[RouteGuardGuard], data: {roles: [Role.EDIT_EXAM_PRESCRIPTION, Role.CREATE_EXAM_PRESCRIPTION]}},
      {path:"show", component: ExamPrescriptionShowComponent, canActivate:[RouteGuardGuard], data: {roles: [Role.EDIT_EXAM_PRESCRIPTION, Role.SHOW_EXAM_PRESCRIPTION, 
         Role.PRINT_EXAM_PRESCRIPTION, Role.CLOSE_EXAM_PRESCRIPTION]},},
      {path:"list", component: ExamPrescriptionListComponent, canActivate:[RouteGuardGuard], data: {roles: [Role.EDIT_EXAM_PRESCRIPTION, Role.SHOW_EXAM_PRESCRIPTION, Role.DELETE_EXAM_PRESCRIPTION, 
        Role.CREATE_EXAM_PRESCRIPTION, Role.PRINT_EXAM_PRESCRIPTION, Role.CLOSE_EXAM_PRESCRIPTION]}}
  ]},

  {path:"care-prescription", canActivate:[RouteGuardGuard], data: {roles: [Role.EDIT_CARE_PRESCRIPTION, Role.SHOW_CARE_PRESCRIPTION, Role.DELETE_CARE_PRESCRIPTION, Role.CREATE_CARE_PRESCRIPTION, Role.PRINT_CARE_PRESCRIPTION]},
    children: [
      {path:"edit", component: CarePrescriptionEditComponent, canActivate:[RouteGuardGuard], data: {roles: [Role.EDIT_CARE_PRESCRIPTION, Role.CREATE_CARE_PRESCRIPTION]}},
      {path:"show", component: CarePrescriptionShowComponent, canActivate:[RouteGuardGuard], data: {roles: [Role.EDIT_CARE_PRESCRIPTION, Role.SHOW_CARE_PRESCRIPTION, Role.DELETE_CARE_PRESCRIPTION,
         Role.PRINT_CARE_PRESCRIPTION, Role.CLOSE_CARE_PRESCRIPTION]},},
      {path:"list", component: CarePrescriptionListComponent, canActivate:[RouteGuardGuard], data: {roles: [Role.EDIT_CARE_PRESCRIPTION, Role.SHOW_CARE_PRESCRIPTION, Role.DELETE_CARE_PRESCRIPTION, 
         Role.CREATE_CARE_PRESCRIPTION, Role.PRINT_CARE_PRESCRIPTION, Role.CLOSE_CARE_PRESCRIPTION]}}
  ]},

  {path:"medical-prescription", canActivate:[RouteGuardGuard], data: {roles: [Role.EDIT_MEDICAL_PRESCRIPTION, Role.SHOW_MEDICAL_PRESCRIPTION, Role.DELETE_MEDICAL_PRESCRIPTION, Role.CREATE_MEDICAL_PRESCRIPTION, Role.PRINT_MEDICAL_PRESCRIPTION]},
    children: [
      {path:"edit", component: MedicalPrescriptionEditComponent, canActivate:[RouteGuardGuard], data: {roles: [Role.EDIT_MEDICAL_PRESCRIPTION, Role.CREATE_MEDICAL_PRESCRIPTION]}},
      {path:"show", component: MedicalPrescriptionShowComponent, canActivate:[RouteGuardGuard], data: {roles: [Role.EDIT_MEDICAL_PRESCRIPTION, Role.SHOW_MEDICAL_PRESCRIPTION, Role.DELETE_MEDICAL_PRESCRIPTION,
         Role.PRINT_MEDICAL_PRESCRIPTION, Role.CLOSE_MEDICAL_PRESCRIPTION]},},
      {path:"list", component: MedicalPrescriptionListComponent, canActivate:[RouteGuardGuard], data: {roles: [Role.EDIT_MEDICAL_PRESCRIPTION, Role.SHOW_MEDICAL_PRESCRIPTION, Role.DELETE_MEDICAL_PRESCRIPTION, 
         Role.CREATE_MEDICAL_PRESCRIPTION, Role.PRINT_MEDICAL_PRESCRIPTION, Role.CLOSE_MEDICAL_PRESCRIPTION]}}
  ]},
  {path:"care", canActivate:[RouteGuardGuard], data: {roles: [Role.LIST_CARE, Role.SHOW_CARE, Role.DELETE_CARE, Role.SAVE_CARE_EXECUTION, Role.CREATE_CARE_APOINTMENT]},
    children: [
      {path:"execution", component: CareExecutionEditComponent, canActivate:[RouteGuardGuard], data: {roles: [Role.SAVE_CARE_EXECUTION, Role.LIST_CARE]}},
      {path:"show", component: CareShowComponent, canActivate:[RouteGuardGuard], data: {roles: [Role.SHOW_CARE, Role.LIST_CARE]}},
      {path:"list", component: CareListComponent, canActivate:[RouteGuardGuard], data: {roles: [Role.LIST_CARE, Role.SHOW_CARE, Role.DELETE_CARE, Role.SAVE_CARE_EXECUTION, Role.CREATE_CARE_APOINTMENT]}},
    ]},
  {path:"reporting", canActivate:[RouteGuardGuard], data: {roles: [Role.SHOW_COSTS_REGULATIONS_STATEMENTS,Role.PRINT_COSTS_REGULATIONS_STATEMENTS,Role.SHOW_COUNTER_ACTIVITIES,Role.PRINT_COUNTER_ACTIVITIES]},
    children: [
      {path:"costs-regulations-statement", component: CostsRegulationsStatementComponent, canActivate:[RouteGuardGuard], data: {roles: [Role.SHOW_COSTS_REGULATIONS_STATEMENTS,Role.PRINT_COSTS_REGULATIONS_STATEMENTS]}},
      {path:"counter-activities", component: CounterActivitiesComponent, canActivate:[RouteGuardGuard], data: {roles: [Role.SHOW_COUNTER_ACTIVITIES,Role.PRINT_COUNTER_ACTIVITIES]}},
      {path:"billing-of-services", component: BillingOfServicesComponent, canActivate:[RouteGuardGuard], data: {roles: [Role.SHOW_BILLING_OF_SERVICES_STATEMENTS,Role.PRINT_BILLING_OF_SERVICES_STATEMENTS]}}
  ]},
  {path:"exam", canActivate:[RouteGuardGuard], data: {roles: [Role.LIST_EXAM, Role.SHOW_EXAM, Role.DELETE_EXAM, Role.SAVE_EXAM_RESULT, Role.CREATE_EXAM_APOINTMENT]},
    children: [
      {path:"assessment", component: ExamAssessmentEditComponent, canActivate:[RouteGuardGuard], data: {roles: [Role.SAVE_EXAM_ASSESSMENT, Role.LIST_EXAM]}},
      {path:"processing", component: ExamProcessingEditComponent, canActivate:[RouteGuardGuard], data: {roles: [Role.SAVE_EXAM_PROCESSING, Role.LIST_EXAM]}},
      {path:"result", component: ExamResultEditComponent, canActivate:[RouteGuardGuard], data: {roles: [Role.SAVE_EXAM_RESULT, Role.LIST_EXAM]}},
      {path:"show", component: ExamShowComponent, canActivate:[RouteGuardGuard], data: {roles: [Role.SHOW_EXAM, Role.LIST_EXAM]}},
      {path:"list", component: ExamListComponent, canActivate:[RouteGuardGuard], data: {roles: [Role.LIST_EXAM, Role.SHOW_EXAM, Role.DELETE_EXAM, Role.SAVE_EXAM_RESULT, Role.CREATE_EXAM_APOINTMENT]}},
    ]},
    {path:"medical-institution-partner", canActivate:[RouteGuardGuard], data: {roles: [Role.EDIT_MEDICAL_INSTITUTION_PARTNER, Role.CREATE_MEDICAL_INSTITUTION_PARTNER, Role.SHOW_MEDICAL_INSTITUTION_PARTNER , Role.SHOW_MEDICAL_INSTITUTION_PARTNER, Role.DELETE_MEDICAL_INSTITUTION_PARTNER, Role.LIST_MEDICAL_INSTITUTION_PARTNER]}, children: [
      {path:"edit", component: MedicalInstitutionPartnerEditComponent, canActivate:[RouteGuardGuard], data: {roles: [Role.CREATE_MEDICAL_INSTITUTION_PARTNER,Role.EDIT_MEDICAL_INSTITUTION_PARTNER]}},
      {path:"list", component: MedicalInstitutionPartnerListComponent, canActivate:[RouteGuardGuard], data: {roles: [Role.LIST_MEDICAL_INSTITUTION_PARTNER,Role.SHOW_MEDICAL_INSTITUTION_PARTNER, Role.EDIT_MEDICAL_INSTITUTION_PARTNER, Role.DELETE_MEDICAL_INSTITUTION_PARTNER,Role.CREATE_MEDICAL_INSTITUTION_PARTNER]}},
      {path:"show", component: MedicalInstitutionPartnerShowComponent, canActivate:[RouteGuardGuard], data: {roles: [Role.SHOW_MEDICAL_INSTITUTION_PARTNER, Role.EDIT_MEDICAL_INSTITUTION_PARTNER, Role.DELETE_MEDICAL_INSTITUTION_PARTNER]}}
    ]},
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
