import { element } from 'protractor';
import { Component, OnInit, ViewChild, AfterViewInit, SimpleChanges, OnChanges, Inject, HostListener } from '@angular/core';
import { ExamPrescription, PrescriptionItem } from 'src/app/model/prescriptions/prescription';
import { IFormActionBarButton, FormAction, ButtonsBarComponent } from 'src/app/components/buttons-bar/buttons-bar.component';
import { Prestation } from 'src/app/model/prestation/prestation';
import { MedicalAgent } from 'src/app/model/organisation/medical-agent';
import { MedicalFolder } from 'src/app/model/admission/medical-folder';
import { Funtions } from 'src/app/model/security/funtionalities';
import * as moment from 'moment';
import { ENTER } from '@angular/cdk/keycodes';
import { NotificationType, NkapNotificationService } from 'src/app/services/nkap-notification.service';
import { MatTable, MatTableDataSource, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { FormGroup, FormBuilder, Validators, FormControl } from '@angular/forms';
import { NkapHttpService } from 'src/app/services/nkap-http.service';
import { ActivatedRoute, Router } from '@angular/router';
import { UserAppService } from 'src/app/services/user-app.service';
import { IModalComponent } from 'src/app/components/base/modal/modal.component';

@Component({
  selector: 'app-exam-prescription-edit',
  templateUrl: './exam-prescription-edit.component.html',
  styleUrls: ['./exam-prescription-edit.component.css']
})
export class ExamPrescriptionEditComponent extends IModalComponent implements OnChanges, OnInit, AfterViewInit {

currentObject: ExamPrescription;
validityErrors: string[]; // list of errors in the Prestation  

@ViewChild(MatTable)
tableCmp: MatTable<any>;
prescriptionItemsDataSource: MatTableDataSource<PrescriptionItem> = new MatTableDataSource([]);
protected prescriptionItemsToDelete: PrescriptionItem[] = [];

@ViewChild("btnBar")
btnBarCmp: ButtonsBarComponent;

protected buttons: IFormActionBarButton[];

displayers: any = {displayPrestation: Prestation.displayPrestation};

form: FormGroup;

folderSelected: MedicalFolder;
folderControl: FormControl = new FormControl();
folderList: MedicalFolder[];

//the agent connected (need ID, number and fullName)
agent: MedicalAgent;

// the the medical folder selected (need ID, Number, and Patient fullname)
medicalFolder: MedicalFolder;

// medical prestations in autocomplete selection 
medicalPrestations: any[];

dialogData: any;

constructor(private formBuilder: FormBuilder,private httpService: NkapHttpService<any>,
  public dialogRef: MatDialogRef<ExamPrescriptionEditComponent>, @Inject(MAT_DIALOG_DATA) public data: any,
  private router: Router, private route: ActivatedRoute, private userService: UserAppService,
  private msgService: NkapNotificationService) {

    super();
    this.currentObject = new ExamPrescription();
    this.currentObject.date = new Date(Date.now());
    this.currentObject.medicalAgent = new MedicalAgent();
    this.currentObject.medicalFolder = new MedicalFolder();
    this.currentObject.prescriptionItems = [new PrescriptionItem()];

    this.dialogData = this.data? this.data.data : {};
    //console.log("DialogComponent ", this.dialogData);
    if(!this.dialogData) { this.dialogData = {}; }
    if(this.dialogData.agent) { this.currentObject.medicalAgent = this.dialogData.agent; }
    if(this.dialogData.medicalFolder) { this.currentObject.medicalFolder = this.dialogData.medicalFolder; }

    if(!(this.dialogData.medicalFolder)){
      this.buttons = [
        {id: "save", value: FormAction.CREATE, icon: {type : 'save'},
          text: 'btn.save.label', disabled: false, functionalities: [Funtions.examPrescription_btn_new_fct,Funtions.examPrescription_btn_edit_fct]},
        {id: "list", value: FormAction.VIEW, icon: {type : 'list', color:'#ff9933'},
          text: 'btn.list.label', disabled: false, functionalities: [Funtions.examPrescription_btn_list_fct]}
      ];
    }
}

displayTitle(){
  if(!(this.dialogData.medicalFolder)){
    return "exam-prescription.edit.form.title";
  }else{
    return "";
  }
}

ngAfterViewInit() {
  this.route.paramMap.subscribe(params => {
    let data: {id?: string} = (params as any).params;
    if (data.id != null) {
      this.httpService.findById(data.id, 'prescription/exam-prescription', true).subscribe( (prescription)=>{
        if(prescription != null && prescription != undefined){
          this.initForm(prescription);
        }
      }, (errors) => {
        this.msgService.log("prescription.edit.get.data.error", NotificationType.ERROR);
      });
    }
  });
  if(!(this.dialogData.medicalFolder)){ this.updateButtonsStatus(); }
}

ngOnChanges(changes: SimpleChanges): void {
  console.log("ngOnChanges ", changes);
  if(changes['currentObject']) {
    this.initForm(changes['currentObject'].currentValue);
    this.form.updateValueAndValidity({onlySelf: true, emitEvent: true});
  } else if(changes['agent']) {
    this.form.updateValueAndValidity({onlySelf: true, emitEvent: true});
  } else if(changes['medicalFolder']) {
    this.form.updateValueAndValidity({onlySelf: true, emitEvent: true});
  }
}

ngOnInit() {
  this.initForm(this.currentObject);

  this.folderControl.valueChanges.subscribe( (inputValue) => {
    if (typeof inputValue === 'string' ) {
      this.folderSelected = null;
      this.httpService.search({value: inputValue}, 'admission/medical-folder').subscribe ( (result) => {
         this.folderList = result ? result.datas : [];
      });
     } else {
       this.folderSelected = inputValue;
       this.currentObject.medicalFolder = this.folderSelected;
       this.medicalFolder = this.currentObject.medicalFolder;
     }
  });
}

updateButtonsStatus(): void {
  if(this.btnBarCmp){
    let newStatusList = [
      {id: 'save', disabled:!(this.currentObject.medicalAgent && this.currentObject.medicalAgent.id)}
    ];
    //console.log("updateButtonsStatus ", newStatusList);
    this.btnBarCmp.setButtonsStatus(newStatusList);
  }
}

public initForm(prescription: ExamPrescription): void {
    //console.log("initForm ", prescription);
  this.currentObject = prescription;
  
  // Get the MedicalAgent connected
  let userId = this.userService.getUser().number;
  if(userId){
    this.httpService.search({userId: userId ? userId : undefined},`organisation/medical-agent`).subscribe(results => {
      if(results.datas && results.datas.length > 0){
        this.currentObject.medicalAgent = results.datas[0];
        this.agent = this.currentObject.medicalAgent;
        if(!(this.dialogData.medicalFolder)){ this.updateButtonsStatus(); }
      }
    });
  }

  if(!this.dialogData) {
    if(!this.formBuilder){
      this.formBuilder = new FormBuilder();
    }
    if(!this.currentObject){
      this.currentObject = new ExamPrescription();
    }
  }

  this.form = this.formBuilder.group({
    date: [this.currentObject? this.currentObject.date : '', [Validators.required]]
  });

  this.prescriptionItemsDataSource = new MatTableDataSource(this.currentObject.prescriptionItems || []);
  this.agent = this.currentObject.medicalAgent;
  this.medicalFolder = this.currentObject.medicalFolder;
  this.checkIfShouldAddNewLine(null,null);
}

isValid(prescriptionData): boolean {
  let currentObject: ExamPrescription = prescriptionData? prescriptionData : this.getData();
  currentObject = Object.assign(new ExamPrescription(), this.currentObject);
  let isValid = currentObject? currentObject.isValid() : false;
  this.validityErrors =  isValid === false ? currentObject.validityErrors : null;  
  return isValid;
}

getCurrentErrors() {
  return this.validityErrors;
}

onNoClick(): void {
  this.dialogRef.close({value:null});
}

@HostListener('window:keyup', ['$event'])
keyEvent(event: KeyboardEvent) {
  if (this.dialogData.medicalFolder && event.keyCode === ENTER) {
    this.save(this.currentObject);
    this.dialogRef.close({value:this.currentObject});
  }
}

getData(): ExamPrescription {
  if(!this.currentObject){
    this.currentObject = new ExamPrescription();
  }
  this.currentObject.date = this.form.get('date').value;
  this.currentObject.medicalAgent = this.agent;
  this.currentObject.medicalFolder = this.medicalFolder;

  // we get valid lines
  this.currentObject.prescriptionItems = [];
  if(this.prescriptionItemsDataSource.data){
    this.prescriptionItemsDataSource.data.forEach( (item: PrescriptionItem)=>{
      item = Object.assign(new PrescriptionItem(), item);
      if(item.isValid() == true) {
        if(item.id) { item.id = null; }
        this.currentObject.prescriptionItems.push(item);
      }
    });
  }
  if (this.prescriptionItemsToDelete.length > 0) {
    this.currentObject.prescriptionItems = this.currentObject.prescriptionItems.concat(this.prescriptionItemsToDelete);
  }
  //console.log("getData", this.currentObject);
  return this.currentObject;
}

setQuantity(element,event) {
  if(event && event.target && Number(event.target.value) <= 0) {
    //console.log("setQuantity " , Number(event.target.value));
    element.quantity = 1;
  }
  if(element) {
    element.toAddOrUpdate = true;
  } 
}

filterPrestationOptions(event) {
  console.log({event});
  if(event && event.target != null){
    const value = event.target.value;
    console.log("filterPrestationOptions" , {value,event});
    this.httpService.search({value: value},"prestation/exams").subscribe( (results)=>{
      //console.log("filterPrestationOptions", results);
      this.medicalPrestations = results? results.datas : [];
    });
   // this.checkIfShouldAddNewLine(null, null);
  }
}

addNewLine(element: PrescriptionItem) {
  if(!element){ return;}
  let datas = [].concat(this.prescriptionItemsDataSource.data)
  datas.push(element);
  this.prescriptionItemsDataSource.data = datas;
  this.tableCmp.renderRows();
}

btnActionClick(event) {
  if(event && event.id === this.buttons[0].id){
    this.save(null);
  } else if(event && event.id === this.buttons[1].id){
    this.router.navigate(["exam-prescription/list", {search: true}]);
  }
}

dateSelected(event) {}

getPatientSelected(event) {
  this.medicalFolder = event;
  this.form? this.form.updateValueAndValidity({onlySelf: true, emitEvent: true}) : null;
}

displayFolderFn(folder?: MedicalFolder): string  {
  return folder ? `${folder.number} ${folder.patient.fullname ? folder.patient.fullname : ''}` : '';
}

formatDate(date){
  try {
      return "[" + moment(new Date(date)).utc().format("HH:mm") + "] " + moment(new Date(date)).format("LL");
  } catch (e) {
    console.error("Parse date in reception-list fail " , e);
  }
}

deleteElement(element){
  let index = this.prescriptionItemsDataSource.data.findIndex( (elm) => 
        (!element.prestation && !elm.prestation)
       || (element.prestation && elm.prestation && element.prestation.id ===  elm.prestation.id)
      );
  console.log("deleteElement ", index);
  if(index != -1){
    const elementToDelete = this.prescriptionItemsDataSource.data[index];
    console.log("deleteElement ", elementToDelete);
    if(elementToDelete.id != null) {
      elementToDelete.toDelete = true;
      this.prescriptionItemsToDelete.push(elementToDelete);
    }
    console.log("prescriptionItemsToDelete ", this.prescriptionItemsToDelete);
    this.prescriptionItemsDataSource.data.splice(index, 1);
    this.tableCmp.renderRows();
    this.checkIfShouldAddNewLine(null,element);
  }
}

save(prescriptionData) {
  if(this.isValid(prescriptionData) === true) {
    let dataToSave = prescriptionData? prescriptionData : this.getData() ;
    this.httpService.save(dataToSave, "prescription/exam-prescription",!(this.dialogData && this.dialogData.medicalFolder)).subscribe( (result)=>{
      this.msgService.log("medical-prescription.form.edit.save.successfully.message", NotificationType.SUCCESS);      
        if(this.dialogData && this.dialogData.medicalFolder){
          this.currentObject = result;
        }else{
          this.router.navigate(['exam-prescription/list', {search: true}]);
        }
    }, (error)=>{
      console.log("error ", error);
      this.msgService.log(error, NotificationType.ERROR);
    });
  } else {
    this.msgService.log(this.getCurrentErrors(), NotificationType.ERROR);
  }
}

checkIfShouldAddNewLine($event,element) {
 
  // console.log({element});
  // To avoid to send to server prestations unsaved!
  if(element && element.prestation != null && typeof element.prestation === 'string') {
    element.prestation = null
  }
  if(this.prescriptionItemsDataSource.data == null || this.prescriptionItemsDataSource.data.length < 1) {
    console.log("checkIfShouldAddNewLine empty table");
    this.addNewLine(new PrescriptionItem());
  } else {
    let lastLine: PrescriptionItem = this.prescriptionItemsDataSource.data[this.prescriptionItemsDataSource.data.length - 1];
    //console.log("checkIfShouldAddNewLine ", lastLine);
    console.log("is lastLine valid ", lastLine.prestation);
    if(lastLine.prestation != null && lastLine.prestation.id != null) {
        this.addNewLine(new PrescriptionItem());
    }
  }
}

}
