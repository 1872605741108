import { Component, OnInit } from '@angular/core';
import { SummernoteOptions } from 'ngx-summernote/lib/summernote-options';

let self;
@Component({
  selector: 'app-nkap-summernote-editor',
  templateUrl: './nkap-summernote-editor.component.html',
  styleUrls: ['./nkap-summernote-editor.component.css']
})
export class NkapSummernoteEditorComponent implements OnInit {

  protected summernoteConfig: SummernoteOptions;
  protected textContent: string;

  maxlength: number;

  constructor() { 
    self = this;
    this.summernoteConfig = {
      placeholder: '',
      tabsize: 2,
      height: 200,
      uploadImagePath: '/api/upload',
      toolbar: [
          ['misc', ['codeview', 'undo', 'redo']],
          ['style', ['bold', 'italic', 'underline', 'clear']],
          ['font', ['bold', 'italic', 'underline', 'strikethrough', 'superscript', 'subscript', 'clear']],
          ['fontsize', ['fontname', 'fontsize', 'color']],
          ['para', ['style', 'ul', 'ol', 'paragraph', 'height']],
          ['insert', ['table', 'picture', 'link', 'video', 'hr']]
      ],
      fontNames: ['Helvetica', 'Arial', 'Arial Black', 'Comic Sans MS', 'Courier New', 'Roboto', 'Times'],
      callbacks: {
        onKeydown: self.summernoteCallbackOnKeydown,
        onKeyup: self.summernoteCallbackOnKeyup,
        onPaste: self.summernoteCallbackOnPaste
      }
    }
  }

  ngOnInit() {
  }

  summernoteCallbackOnKeydown(e: any) {
    var t = e.currentTarget.innerText;
    if (self.maxlength && t.length >= self.maxlength) {
      if (e.keyCode != 8){
        e.preventDefault();
      }
    }
  }

  summernoteCallbackOnKeyup(e: any) {
    var t = e.currentTarget.innerText;
    /*if (self.maxlength && typeof callbackMax == 'function') {
      callbackMax(self.maxlength &&  - t.length);
    }*/
  }

  summernoteCallbackOnPaste(e: any) {
    var t = e.currentTarget.innerText;
    var bufferText = ((e.originalEvent || e).clipboardData /*|| window.clipboardData*/).getData('Text');
    e.preventDefault();
    var all = t + bufferText;
    document.execCommand('insertText', false, all.trim().substring(0, 400));
    /*if (self.maxlength && typeof callbackMax == 'function') {
      callbackMax(self.maxlength &&  - t.length);
    }*/
  }
}
