import { Antecedent } from 'src/app/model/admission/medical-folder';
import { FRCVTypes } from './../../../model/admission/medical-folder';
import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-antecedents-frcv-show',
  templateUrl: './antecedents-frcv-show.component.html',
  styleUrls: ['./antecedents-frcv-show.component.css']
})
export class AntecedentsFrcvShowComponent implements OnInit {

  @Input('frcv')
  frcvs: FRCVTypes[];

  @Input('antecedents')
  antecedents: Antecedent[];

  constructor() { 
  }

  ngOnInit() {
  }

}
