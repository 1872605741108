import { Component, OnInit } from '@angular/core';
import { FormAction, IFormActionBarButton } from '../../buttons-bar/buttons-bar.component';
import { Router } from '@angular/router';
import { ErrorStateMatcher } from '@angular/material';
import { FormControl, FormGroupDirective, NgForm, FormGroup, FormBuilder, Validators } from '@angular/forms';
import { UserAppService } from 'src/app/services/user-app.service';
import { NkapHttpService } from 'src/app/services/nkap-http.service';
import { User } from 'src/app/model/security/user';
import { NkapNotificationService, NotificationType } from 'src/app/services/nkap-notification.service';
import { Condition } from 'selenium-webdriver';
import { formArrayNameProvider } from '@angular/forms/src/directives/reactive_directives/form_group_name';
import { Funtions } from 'src/app/model/security/funtionalities';

/** Error when the parent is invalid */
class CrossFieldErrorMatcher implements ErrorStateMatcher {
  isErrorState(control: FormControl | null, form: FormGroupDirective | NgForm | null): boolean {
    return control.dirty && form.invalid;
  }
} 
@Component({
  selector: 'app-user-parameters-edit',
  templateUrl: './user-parameters-edit.component.html',
  styleUrls: ['./user-parameters-edit.component.css']
})
export class UserParametersEditComponent implements OnInit {  

  hide = true;
  protected buttons: IFormActionBarButton[];
  userForm: FormGroup;
  userPwd = new User();
  errorMatcher = new CrossFieldErrorMatcher();

  constructor(private router: Router, private fb: FormBuilder, 
    private httpService: NkapHttpService<User>, 
    private msgService: NkapNotificationService, private userService: UserAppService) { 
    this.initForm();
    this.buttons = [
      {id: 'edit', value: FormAction.UPDATE, icon: {type : 'edit', color: '#ff9933'}, 
        text: 'UserParametersEdit.bnt.save.label', disabled: false, functionalities: [Funtions.UserParametersEdit_btn_new_fct]},
      {id: 'list', value: FormAction.VIEW, icon: {type : 'delete_forever', color: '#ff9933'}, 
      text: 'UserParametersEdit.btn.cancel.label', disabled: false, functionalities: ['UserParametersEdit.btn.cancel.label']}
    ];    
  }

  ngOnInit() {
  }

  initForm() {
    this.userForm = this.fb.group({
      password: '',
      verifyPassword: ''
    }, {
      validator: this.passwordValidator
    })
  }

  passwordValidator(form: FormGroup) {
    const condition = form.get('password').value !== form.get('verifyPassword').value;
    return condition ? { passwordsDoNotMatch: true} : null;
  }

  btnActionClick(event) {
    if(event && event.id === this.buttons[0].id){
      this.save();
    } else if(event && event.id === this.buttons[1].id){
      this.router.navigate(["user-parameters/show", {search: true}]);
    }
  }

  isValid(){
    let isValid = true;
    if(this.userPwd.password == null || this.userPwd.password === undefined){
      isValid = false;
    }
    if(this.userForm.invalid){
      isValid = false;
    }
    console.log( "isValid" , isValid);
    return isValid;
  }

  save() {
    if (this.isValid() === true){
      this.httpService.save(null, 'prestation/prestation-family').subscribe( (result) => {
        this.msgService.log("user-parameters.edit.save.succed", NotificationType.SUCCESS);
        this.router.navigate(['user-parameters/show', {search: true}]);
      }, (error) => {
        if (error && Array.isArray(error)) {
          error.forEach( (err) => {
            this.msgService.log(err, NotificationType.ERROR);
          });
        } else {
          this.msgService.log("user-parameters.edit.save.fail", NotificationType.ERROR);
        }
      });
    } else {
      this.msgService.log("user-parameters.edit.prestation.data.invalid", NotificationType.ERROR);
    }
  }



}
