import { Component, OnInit, EventEmitter, Output } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { IFormActionBarButton, FormAction } from '../../buttons-bar/buttons-bar.component';
import { Funtions } from 'src/app/model/security/funtionalities';
import { NkapHttpService } from 'src/app/services/nkap-http.service';
import { SystemInvestigationType } from 'src/app/model/admission/medical-folder';
import { ClassNameMap } from 'src/app/model/persistent';
import { MatDialogRef } from '@angular/material';
import { ActivatedRoute, Router } from '@angular/router';
import { NotificationType, NkapNotificationService } from 'src/app/services/nkap-notification.service';

@Component({
  selector: 'app-system-investigation-type-edit',
  templateUrl: './system-investigation-type-edit.component.html',
  styleUrls: ['./system-investigation-type-edit.component.css']
})
export class SystemInvestigationTypeEditComponent implements OnInit {

  SystemInvestigationEditFormType: FormGroup;
  parameters: any;
  typeUse: string;
  protected buttons: IFormActionBarButton[];

  @Output() afterSaveEmitter: EventEmitter<any> = new EventEmitter();

  @Output() cancleEmitter: EventEmitter<boolean> = new EventEmitter();
  currentObject: SystemInvestigationType = new SystemInvestigationType();

  constructor(private msgService: NkapNotificationService,private nkapService: NkapHttpService<any>,private formBuilder: FormBuilder, private route: ActivatedRoute, private router: Router) { 
    this.parameters = {
      SystemInvestigationTypelUrl: 'admission/system-investigation-type',
    };
  }

  ngOnInit() {

    this.initForm();
    this.buttons = [
      {id: 'save', value: FormAction.CREATE, disabled: this.SystemInvestigationEditFormType.invalid , icon: {type : 'save'},
        text: 'btn.save.label', functionalities: [
        Funtions.consultation,Funtions.reception_btn_edit_fct,Funtions.exams,Funtions.systemInvestigation_btn_new_fct,Funtions.systemInvestigation_btn_edit_fct, Funtions.systemInvestigationType_btn_edit_fct, Funtions.systemInvestigationType_btn_new_fct]},
        {id: "list", value: FormAction.VIEW, icon: {type : 'list'},
        text: 'btn.list.label', disabled: false, functionalities: [Funtions.systemInvestigation_btn_list_fct]},
      {id: 'cancel', value: FormAction.CANCEL, icon: {type : 'cancel'},
        text: 'btn.cancel.label', functionalities: ['null']},
    ];

    this.route.paramMap.subscribe(params => {
      let param = (params as any).params;
      this.typeUse = param.type;
      if (this.typeUse == null) {
        this.buttons = this.buttons.filter(button => button.id !== "list")
      } else {
        this.buttons = this.buttons.filter(button => button.id !== "cancel")
      }

      if (param.id != null) {
        this. nkapService.findById(param.id,  this.parameters.SystemInvestigationTypelUrl, true).subscribe( (object)=>{
          this.setView(object);
        }, (errors) => {
          this.msgService.log("system-investigation-type.edit.get.data.error", NotificationType.ERROR);
        });
      }
    });

    this.SystemInvestigationEditFormType.statusChanges.subscribe(value => {
      if (this.SystemInvestigationEditFormType.invalid === false) {
        this.buttons[0].disabled = false;
      } else {
        this.buttons[0].disabled = true;
      }
    });

    this.afterSaveEmitter.subscribe(res => {
      if (this.typeUse) {
        this.router.navigate(['/system-investigation-type/list', {search: true}]);
      }
    })
    
  }

  setView(object : SystemInvestigationType){
    if(object){
      this.currentObject = object;
      this.SystemInvestigationEditFormType.get("name").reset(object.name);
    }
  }

  onSubmitForm() {
    const formValue = this.SystemInvestigationEditFormType.value;
    console.log(formValue);
    this.currentObject.name = formValue.name;
    /*const value: SystemInvestigationType = {
      classe: ClassNameMap.SystemInvestigationType,
      name: formValue.name
    }*/
    this.nkapService.save(this.currentObject, this.parameters.SystemInvestigationTypelUrl).subscribe(result => {
      this.afterSaveEmitter.emit(result);
    });
  }


  initForm() {
    this.SystemInvestigationEditFormType = this.formBuilder.group({
      name: ['', [Validators.required]],
    });
  }

  btnActionClick(event) {
    if ( event && event.id === this.buttons[0].id) {
      if(this.SystemInvestigationEditFormType.valid) {
        this.onSubmitForm();
      }
    } else if ( event && event.id === "cancel") {
      this.cancleEmitter.emit(null);
    } else if ( event && event.id === "list") {
      this.router.navigate(['/system-investigation-type/list']);
    }
  }

}
