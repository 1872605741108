import { Component, OnInit, ChangeDetectorRef, ViewChild } from '@angular/core';
import { FRCVTypes } from 'src/app/model/admission/medical-folder';
import { NkapListComponent } from '../../base/nkap-list-component';
import { NkapHttpService } from 'src/app/services/nkap-http.service';
import { Router, ActivatedRoute } from '@angular/router';
import { UserAppService } from 'src/app/services/user-app.service';
import { TranslateService } from '@ngx-translate/core';
import { NkapNotificationService, NotificationType } from 'src/app/services/nkap-notification.service';
import { FormAction, ButtonsBarComponent } from '../../buttons-bar/buttons-bar.component';
import { Funtions } from 'src/app/model/security/funtionalities';

@Component({
  selector: 'app-frcv-list',
  templateUrl: './frcv-list.component.html',
  styleUrls: ['./frcv-list.component.css']
})
export class FrcvListComponent extends NkapListComponent<FRCVTypes>  {
  
  @ViewChild("btnBar")
  btnBarCmp: ButtonsBarComponent;
  
  constructor(protected _ref: ChangeDetectorRef, protected httpService: NkapHttpService<FRCVTypes>, 
    protected router: Router, protected route: ActivatedRoute, protected userService: UserAppService,
    protected msgService: NkapNotificationService, protected translateService: TranslateService) {
   super(_ref, httpService, router, route, userService, msgService, translateService);
      this.searchBaseUrl = "admission/frcv";
    this.buttons = [
      {id: "add", value: FormAction.CREATE, icon: {type : 'add_circle_outline', color: '#0099cc'},
        text: 'frcv.btn.new.label', disabled: false, functionalities: [Funtions.frcv_btn_new_fct]},
      {id: "edit", value: FormAction.UPDATE, icon: {type : 'edit',color:'#ff9933'}, 
        text: 'frcv.btn.edit.label', disabled: false, functionalities: [Funtions.frcv_btn_edit_fct]},
      {id: "delete", value: FormAction.DELETE, icon: {type :'delete_forever',color:'#ff9933'},
        text: 'frcv.btn.delete.label', disabled: false, functionalities: [Funtions.frcv_btn_delete_fct]}
    ];
   }

  ngOnInit() {
    super.ngOnInit();
  }

  btnActionClick(event) {
    if(event && event.id === this.buttons[0].id){
      this.router.navigate(["frcv/edit"]);
    } else if(event && event.id === this.buttons[1].id){
      if( !this.selection.selected || this.selection.selected.length != 1){
        this.msgService.log("frcv.list.select.to.modify.error", NotificationType.ERROR);
      }else{
        this.router.navigate(["frcv/edit", {id: this.selection.selected[0].id}]);
      }
    } else if(event && event.id === this.buttons[2].id){
      if( !this.selection.selected || this.selection.selected.length < 1){
        this.msgService.log("frcv.list.select.to.delete.error", NotificationType.ERROR);
      }else{
        this.deleteSelected();
      }
    }
  }

  deleteSelected(){
    this.msgService.confirm(this.translate.transform("frcv.delete.confirm.text")).subscribe( (result) => {
      if(result === true){
          let selected = this.selection.selected[0];
          this.httpService.deleteById(selected.id, 'admission/frcv').subscribe( (val) => {
            this.msgService.log("frcv.delete.succed", NotificationType.SUCCESS);
            this.searchClicked({});
          }, (error) => {
            this.msgService.log("frcv.delete.fail", NotificationType.ERROR);
          });
        }
      });
  }

  protected viewElement(element) {
    this.router.navigate(['frcv/show', {id: element.id}]);
  }

  listSelectionChanged(){
    super.listSelectionChanged();
    if(this.btnBarCmp){
      const btnStatus = [
        {id: "add", disabled: false, functionalities: this.buttons[0].functionalities},
        {id: "edit", disabled: !(this.selection && this.selection.selected && this.selection.selected.length > 0), functionalities: this.buttons[1].functionalities},
        {id: "delete", disabled: !(this.selection && this.selection.selected && this.selection.selected.length > 0), functionalities: this.buttons[2].functionalities},
      ];    
      this.btnBarCmp.setButtonsStatus(btnStatus);
    }
  }
}
