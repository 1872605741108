import { Component, OnInit, ViewChild, ChangeDetectorRef } from '@angular/core';
import { CareCoverageDoc, CareCoverage } from 'src/app/model/admission/care-coverage-doc';
import { MatTableDataSource, MatTable } from '@angular/material';
import { IFormActionBarButton, FormAction } from '../../buttons-bar/buttons-bar.component';
import { TranslatePipe, TranslateService } from '@ngx-translate/core';
import { NkapHttpService } from 'src/app/services/nkap-http.service';
import { ActivatedRoute, Router } from '@angular/router';
import { NkapNotificationService, NotificationType } from 'src/app/services/nkap-notification.service';
import * as moment from 'moment';
import { Funtions } from 'src/app/model/security/funtionalities';

@Component({
  selector: 'app-care-coverage-doc-show',
  templateUrl: './care-coverage-doc-show.component.html',
  styleUrls: ['./care-coverage-doc-show.component.css']
})
export class CareCoverageDocShowComponent implements OnInit {

  currentObject: CareCoverageDoc;
  itemsDataSource: MatTableDataSource<CareCoverage> = new MatTableDataSource([]);

  @ViewChild(MatTable)
  tableCmp: MatTable<any>;
  
  protected buttons: IFormActionBarButton[];

  protected params: any;

  protected patient: any;

  translate: TranslatePipe;
  
  constructor(private _ref: ChangeDetectorRef, protected translateService: TranslateService,
              private httpService: NkapHttpService<CareCoverageDoc>, 
              private router: Router, private route: ActivatedRoute,
              private msgService: NkapNotificationService) {
      this.translate = new TranslatePipe(translateService, _ref);
      this.buttons = [
        {id: 'edit', value: FormAction.UPDATE, icon: {type : 'edit', color: '#ff9933'}, 
          text: 'care-coverage-doc.btn.edit.label', disabled: false, functionalities: [Funtions.careCoverageDoc_btn_edit_fct]},
        /*{id: 'delete', value: FormAction.DELETE, icon: {type : 'delete_forever', color: '#ff9933'},
           text: 'care-coverage-doc.btn.delete.label', disabled: false},*/
        {id: 'list', value: FormAction.VIEW, icon: {type : 'list'}, text: 'btn.list.label', disabled: false, functionalities: [Funtions.careCoverageDoc_btn_list_fct, Funtions.careCoverageDoc_btn_edit_fct, Funtions.careCoverageDoc_btn_new_fct]}
      ];

  }

  ngOnInit() {
    this.route.paramMap.subscribe(params => {
      let data: {id?: string | number} = (params as any).params;
      this.params = data;
      if (data.id != null) {
        this.httpService.findById(data.id, 'third-party-payer/care-coverage-doc', true).subscribe( (entity) => {
          this.currentObject = entity;
          this.patient = {patient:
            `${(this.currentObject as any).patientFullname} (${(this.currentObject as any).folderNumber})`};
          let items = [];
          if(this.currentObject.prestations && this.currentObject.prestations.length > 0) {
             items = items.concat(this.currentObject.prestations);
          }
          if(this.currentObject.prestationTypes && this.currentObject.prestationTypes.length > 0) {
              items = items.concat(this.currentObject.prestationTypes);
          }
          if(this.currentObject.prestationCategories && this.currentObject.prestationCategories.length > 0) {
              items = items.concat(this.currentObject.prestationCategories);
          }
          this.itemsDataSource.data = items;
        }, (errors) => {
          this.msgService.log('care-coverage-doc.edit.get.data.error', NotificationType.ERROR);
        });
      }
    });
  }

  btnActionClick(event) {
    if (event && event.id === this.buttons[0].id) {
      this.router.navigate(['medical-folder/patient-care-coverage/edit', {folderId: this.params.folderId, number: this.currentObject.number}]);
    } /*else if (event && event.id === this.buttons[1].id) {
        this.delete();
    }*/ else if (event && event.id === this.buttons[1].id) {
      this.router.navigate(['medical-folder/patient-care-coverage/list', {search : true, folderId: this.params.folderId}]);
    } else if (event && event.id === this.buttons[1].id) {
      
    }
  }

  delete(){
    this.msgService.confirm(this.translate.transform("care-coverage-doc.delete.confirm.text")).subscribe( (result) => {
      if(result === true){
        this.httpService.deleteById(this.currentObject.id, 'third-party-payer/care-coverage-doc', true).subscribe( (val) => {
          this.msgService.log('care-coverage-doc.delete.succed', NotificationType.SUCCESS);
          this.router.navigate(['medical-folder/patient-care-coverage/list', {search : true, folderId: this.params.folderId}]);
        }, (error) => {
          this.msgService.log('care-coverage-doc.delete.fail', NotificationType.ERROR);
        });
      }
    });
  }

  formatDate(date){
    try {
      return date ? moment(new Date(date)).format("LL") : "";
    } catch (e) {
      console.error("Parse date fail " , e);
    }
  }
}
