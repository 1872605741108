export enum Funtions {
  patient_btn_new_fct = 'patient.btn.new.label',
  patient_btn_edit_fct = 'patient.btn.edit.label',
  patient_btn_list_fct = 'patient.btn.list.label',
  patient_btn_delete_fct = 'patient.btn.delete.label',
  patient_form_btn_print_fct = 'patient.form.btn.print.label',
  patient_history_tab_label_fct = 'patient.history.tab.label',
  antecedent_btn_new_fct = 'antecedent.btn.new.label',
  antecedent_btn_list_fct = 'antecedent.btn.list.label',
  antecedent_btn_edit_fct = 'antecedent.btn.edit.label',
  antecedent_btn_delete_fct = 'antecedent.btn.delete.label',
  antecedent_type_btn_new_fct = "antecedent.type.btn.add.label",
  antecedent_type_btn_list_fct = 'antecedent.type.btn.list.label',
  antecedent_type_btn_edit_fct = 'antecedent.type.btn.edit.label',
  antecedent_type_btn_delete_fct = 'antecedent.type.btn.delete.label',
  apointment_btn_new_fct = 'apointment.btn.new.label',
  apointment_btn_list_fct = 'apointment.btn.list.label',
  apointment_btn_edit_fct = 'apointment.btn.edit.label',
  apointment_btn_delete_fct = 'apointment.btn.delete.label',
  apointment_btn_achieved_fct = 'apointment.btn.achieved.label',
  businessUnit_btn_new_fct = 'business-unit.btn.new.label',
  businessUunit_btn_list_fct = 'business-unit.btn.list.label',
  businessUnit_btn_edit_fct = 'business-unit.btn.edit.label',
  businessUnit_btn_delete_fct = 'business-unit.btn.delete.label',
  careCoverageDoc_btn_new_fct = 'care-coverage-doc.btn.new.label',
  careCoverageDoc_btn_list_fct = 'care-coverage-doc.btn.list.label',
  careCoverageDoc_btn_edit_fct = 'care-coverage-doc.btn.edit.label',
  frcv_btn_new_fct = 'frcv.btn.new.label',
  frcv_btn_list_fct = 'frcv.btn.list.label',
  frcv_btn_edit_fct = 'frcv.btn.edit.label',
  frcv_btn_delete_fct = 'frcv.btn.delete.label',
  doctor_btn_new_fct = 'doctor.btn.new.label',
  doctor_btn_list_fct = 'doctor.btn.list.label',
  doctor_btn_edit_fct = 'doctor.btn.edit.label',
  doctor_btn_delete_fct = 'doctor.btn.delete.label',
  doctorPlaning_btn_new_fct = 'doctor-planing.btn.new.label',
  doctorPlaning_btn_list_fct = 'doctor-planing.btn.list.label',
  doctorPlaning_btn_him_fct = 'doctor-planing.btn.him.label',
  UserParametersEdit_btn_new_fct = 'UserParametersEdit.btn.new.label',
  UserParametersEdit_btn_list_fct = 'UserParametersEdit.btn.list.label',
  UserParametersEdit_btn_edit_fct = 'UserParametersEdit.btn.edit.label',
  UserParametersEdit_btn_delete_fct = 'UserParametersEdit.btn.delete.label',
  reception_btn_new_fct = 'reception.btn.new.label',
  reception_btn_list_fct = 'reception.btn.list.label',
  reception_btn_edit_fct = 'reception.btn.edit.label',
  reception_btn_delete_fct = 'reception.btn.delete.label',
  reception_btn_close_fct = 'reception.btn.close.label',
  prestation_btn_new_fct = 'prestation.btn.new.label',
  prestation_btn_list_fct = 'prestation.btn.list.label',
  prestation_btn_edit_fct = 'prestation.btn.edit.label',
  prestation_btn_delete_fct = 'prestation.btn.delete.label',
  prestation_btn_params_fct = 'prestation.btn.params.label',
  prestationFamily_btn_new_fct = 'prestation-family.btn.new.label',
  prestationFamily_btn_list_fct = 'prestation-family.btn.list.label',
  prestationFamily_btn_edit_fct = 'prestation-family.btn.edit.label',
  prestationFamily_btn_delete_fct = 'prestation-family.btn.delete.label',
  chronologicalList_btn_list_fct = 'chronological-list.btn.list.label',
  chronologicalList_btn_print_fct = 'chronological-list.btn.print.label',
  chronologicalList_btn_close_fct = 'chronological-list.btn.close.label',
  activity_btn_new_fct = 'activity.btn.new.label',
  activity_btn_list_fct = 'activity.btn.list.label',
  activity_btn_edit_fct = 'activity.btn.edit.label',
  activity_btn_delete_fct = 'activity.btn.delete.label',
  thirdPartyPayer_insurance_btn_new_fct = 'third-party-payer.insurance.btn.new.label',
  thirdPartyPayer_employer_btn_new_fct = 'third-party-payer.employer.btn.new.label',
  thirdPartyPayer_person_btn_new_fct = 'third-party-payer.person.btn.new.label',
  thirdPartyPayer_btn_list_fct = 'third-party-payer.btn.list.label',
  thirdPartyPayer_btn_edit_fct = 'third-party-payer.btn.edit.label',
  thirdPartyPayer_btn_delete_fct = 'third-party-payer.btn.delete.label',
  systemInvestigation_btn_new_fct = 'system-investigation.btn.new.label',
  systemInvestigation_btn_list_fct = 'system-investigation.btn.list.label',
  systemInvestigation_btn_edit_fct = 'system-investigation.btn.edit.label',
  systemInvestigation_btn_delete_fct = 'system-investigation.btn.delete.label',
  systemInvestigationType_btn_new_fct = 'system-investigation-type.btn.new.label',
  systemInvestigationType_btn_list_fct = 'system-investigation-type.btn.list.label',
  systemInvestigationType_btn_edit_fct = 'system-investigation-type.btn.edit.label',
  systemInvestigationType_btn_delete_fct = 'system-investigation-type.btn.delete.label',
  consultationSheet_btn_view_fct = 'consultation-sheet.btn.view.label',
  costRegulationStatement_btn_view_fct = 'cost-regulation-statement.btn.view.label',
  costRegulationStatement_btn_print_fct = 'cost-regulation-statement.btn.print.label',
  counterActivities_btn_view_fct = 'counter-activities.btn.view.label',
  counterActivities_btn_print_fct = 'counter-activities.btn.print.label',
  billingOfServices_btn_view_fct = 'billing-of-services.btn.view.label',
  billingOfServices_btn_print_fct = 'billing-of-services.btn.print.label',
  consultation = 'consultation.home',
  exams = 'exams.home',
  examPrescription_btn_new_fct = "exam-prescription.btn.new.label",
  examPrescription_btn_edit_fct = "exam-prescription.btn.new.label",
  examPrescription_btn_list_fct = "exam-prescription.btn.list.label",
  examPrescription_btn_delete_fct = "exam-prescription.btn.delete.label",
  examPrescription_btn_print_fct = "exam-prescription.btn.print.label",
  examPrescription_btn_close_fct = "exam-prescription.btn.close.label",
  carePrescription_btn_new_fct = "care-prescription.btn.new.label",
  carePrescription_btn_edit_fct = "care-prescription.btn.new.label",
  carePrescription_btn_list_fct = "care-prescription.btn.list.label",
  carePrescription_btn_delete_fct = "care-prescription.btn.delete.label",
  carePrescription_btn_print_fct = "care-prescription.btn.print.label",
  carePrescription_btn_close_fct = "care-prescription.btn.close.label",
  medical = "medical",
  medicalPrescription_btn_new_fct = "medical-prescription.btn.new.label",
  medicalPrescription_btn_edit_fct = "medical-prescription.btn.new.label",
  medicalPrescription_btn_list_fct = "medical-prescription.btn.list.label",
  medicalPrescription_btn_delete_fct = "medical-prescription.btn.delete.label",
  medicalPrescription_btn_print_fct = "medical-prescription.btn.print.label",
  medicalPrescription_prestation_btn_edit_fct = "medicalPrescription_prestation_btn_edit_fct",
  medicalPrescription_btn_close_fct = "medicalPrescription_btn_close_fct",
  care_btn_plan_fct = "care_btn_plan_fct",
  care_btn_result_fct = "care_btn_result_fct",
  care_btn_print_fct = "care_btn_print_fct",
  care_btn_saveResult_fct = "care_btn_saveResult_fct",
  care_btn_editResult_fct = "care_btn_editResult_fct",
  care_btn_list_fct = "care_btn_list_fct",
  care_btn_view_fct = "care_btn_view_fct",
  care_zone_view_details_zone = "care_zone_view_details_zone",
  reception_btn_allow_exam_fct = "reception_btn_allow_exam_fct",
  exam_btn_plan_fct = "exam_btn_plan_fct",
  exam_btn_result_fct = "exam_btn_result_fct",
  exam_btn_print_fct = "exam_btn_print_fct",
  treatments = 'treatments.home',
  exam_btn_saveResult_fct = "exam_btn_saveResult_fct",
  exam_btn_editResult_fct = "exam_btn_editResult_fct",
  exam_btn_list_fct = "exam_btn_list_fct",
  exam_btn_view_fct = "exam_btn_view_fct",
  exam_btn_saveAssessment_fct = "exam_btn_saveAssessment_fct",
  exam_zone_view_details_zone = "exam_zone_view_details_zone",
  exam_btn_result_print_fct = "exam_btn_result_print_fct",
  medicalInstitutionPartner_btn_new_fct = "medicalInstitutionPartner_btn_new_fct",
  medicalInstitutionPartner_btn_list_fct = "medicalInstitutionPartner_btn_list_fct",
  medicalInstitutionPartner_btn_edit_fct = "medicalInstitutionPartner_btn_edit_fct",
  medicalInstitutionPartner_btn_delete_fct = "medicalInstitutionPartner_btn_delete_fct",
  medicalInstitutionPartner_btn_show_fct = "medicalInstitutionPartner_btn_show_fct",
  exam_btn_saveProcessing_fct = "exam_btn_saveProcessing_fct"
}