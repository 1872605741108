import { ButtonsBarComponent ,IFormActionBarButton, FormAction } from './../../../buttons-bar/buttons-bar.component';
import { Component, OnInit, ViewChild, Inject, HostListener, AfterViewInit } from '@angular/core';
import { MedicalPrescription, Prescription, MedicalPrescriptionItem } from 'src/app/model/prescriptions/prescription';
import { MedicalPrescriptionEditFormComponent } from '../medical-prescription-edit-form/medical-prescription-edit-form.component';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Funtions } from 'src/app/model/security/funtionalities';
import { NotificationType } from 'src/app/services/nkap-notification.service';
import { IModalComponent } from '../../../base/modal/modal.component';
import { NkapHttpService } from 'src/app/services/nkap-http.service';
import { UserAppService } from 'src/app/services/user-app.service';

@Component({
  selector: 'app-medical-prescription-edit-modal',
  template: `
    <div mat-dialog-content> 
      <app-medical-prescription-edit-form 
      [agent]="currentObject?.medicalAgent"
      [medicalFolder]="currentObject?.medicalFolder"
      [prescription]="currentObject"></app-medical-prescription-edit-form>
    </div>

    <div *ngIf="buttons" mat-dialog-actions class="d-flex justify-content-center">
    <app-buttons-bar #buttonsBar *ngIf="buttons" 
    [buttons]="buttons"
    [functionality]="'medical-prescription.edit'"
    (action)="btnActionClick($event)">
    </app-buttons-bar>
    </div>
  `
})
export class MedicalPrescriptionEditModalComponent implements OnInit, AfterViewInit, IModalComponent {

  currentObject: MedicalPrescription;
    
  @ViewChild(MedicalPrescriptionEditFormComponent)
  editForm: MedicalPrescriptionEditFormComponent;

  @ViewChild('buttonsBar')
  protected buttonsBarCmp: ButtonsBarComponent;
  protected buttons: IFormActionBarButton[];

  dialogData: any;
  
  constructor(private httpService: NkapHttpService<any>,private userService: UserAppService,
    public dialogRef: MatDialogRef<MedicalPrescriptionEditModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any) {
      this.currentObject = new MedicalPrescription();
      this.currentObject.prescriptionItems = [new MedicalPrescriptionItem()];
      //console.log("MedicalPrescriptionEditModalComponent ", this.currentObject);
      this.dialogData = this.data? this.data.data : {};
      //console.log("DialogComponent ", this.dialogData);
      if(this.dialogData && this.dialogData.medicalFolder) { 
        this.currentObject.medicalFolder = this.dialogData.medicalFolder; 
      } 
      if(this.dialogData && this.dialogData.consultation && this.dialogData.consultation.id != null) { 
        this.currentObject.consultation = this.dialogData.consultation; 
      }      
  }

  ngAfterViewInit(): void {
    this.updateButtonsStatus(this.editForm.getFormStatus());
    this.editForm.statusChanged.subscribe(status => {
        this.updateButtonsStatus(status);
    });
  }

  ngOnInit(): void {
    this.buttons = [
      {id: 'save', value: FormAction.CREATE, disabled: !this.editForm.isValid() , icon: {type : 'save'},
        text: 'btn.save.label', functionalities: [Funtions.medicalPrescription_btn_edit_fct,Funtions.exams,Funtions.medicalPrescription_btn_new_fct]},
      {id: 'cancel', value: FormAction.CANCEL, icon: {type : 'cancel'},
        text: 'btn.cancel.label', functionalities: ['btn.cancel']},
    ];
    
    // Get the MedicalAgent connected
    let userId = this.userService.getUser().number;
    if(userId){
      this.httpService.search({userId: userId ? userId : undefined},`organisation/medical-agent`).subscribe(results => {
        if(results.datas && results.datas.length > 0){
          this.currentObject.medicalAgent = results.datas[0];
        }
      });
    }
  }

  onNoClick(): void {
    this.dialogRef.close({value:null});
  }

  updateButtonsStatus(status: boolean): void {
      let newStatusList = [
        {id: 'save', disabled:!status}
      ];
      //console.log("updateButtonsStatus ",newStatusList);
      this.buttonsBarCmp.setButtonsStatus(newStatusList);
  }

  btnActionClick(event) {
    if ( event && event.id === this.buttons[0].id) {
      this.editForm.afterSaveEvent.subscribe( (result: {result:NotificationType, value: Prescription}) => {
        if(result.result == NotificationType.SUCCESS){// if save goes well, we close the dialog
          this.dialogRef.close({value:result.value, btn: event});
        }
      });
      this.editForm.save();
    } else if ( event && event.id === this.buttons[1].id) {
      this.dialogRef.close({value:this.currentObject, btn: event});
    }
  }

  public getData() {
    return this.editForm ? this.editForm.getData() : null;
  }
}
