import { MedicalInstitutionPartner } from 'src/app/model/organisation/medical-institution-partner';
import { TimeSlot } from 'src/app/model/organisation/diary';
import { PersistentDTO } from './../persistent';
import { User } from 'src/app/model/security/user';
import { Prestation } from 'src/app/model/prestation/prestation';
import { MedicalFolder } from '../admission/medical-folder';
import { ClassNameMap, Persistent } from '../persistent';
import { Apointment } from '../admission/apointment';
import { MedicalAgent } from '../organisation/medical-agent';
import { Party } from '../organisation/party';
import { Person } from '../organisation/person';


export class Exam extends Persistent {
    public examPrestation?: Prestation;
    public patientFolder?: MedicalFolder;
    public status?: ExamStatus | string;
    public currentStep?: ExamWorkflowStep;
	public receptionId?: string; 
	
    constructor(examPrestation?: Prestation,patientFolder?: MedicalFolder,status?: ExamStatus,currentStep?: ExamWorkflowStep) {
        super();
        this.classe = ClassNameMap.Exam;
        this.examPrestation = examPrestation;
        this.patientFolder = patientFolder;
        this.status = status;
        this.currentStep = currentStep;
    }
}

export class ExamWorkflowStep extends Persistent {
	public user?: User;	
	public date?: Date | string;
    public exam?: Exam;
    constructor(exam?: Exam) {
        super();
        this.classe = ClassNameMap.ExamWorkflowStep;
        this.exam = exam;
    }
}

export enum ExamStatus {
	REQUESTED,
	PLANNED,
	IN_PROCESS,
	COMPLETED,
    ASSED,
    WAITING_VALIDATION,
    RESULT_REJECTED
}

export class ExamApointment extends ExamWorkflowStep {
    public apointment?: Apointment;
    constructor(exam?: Exam,apointment?: Apointment) {
        super(exam);
        this.classe = ClassNameMap.ExamApointment;
        this.apointment = apointment;
    }
}

export class ExamAssessment extends ExamWorkflowStep {
	public doctor?: MedicalAgent;	
    public comments?: string;
    public result?: ExamResult;
    public resultRejected?: boolean;
    constructor(exam?: Exam,doctor?: MedicalAgent,result?: ExamResult) {
        super(exam);
        this.comments = "";
        this.classe = ClassNameMap.ExamAssessment;
        this.doctor = doctor;
        this.result = result;
    }
}

export class ExamProcessing extends ExamWorkflowStep {
	
	public inCharge?: Party;
	
	public deadlines?: Date;
	
    public comments?: string;

    public externalProcessing?: boolean;
    
    constructor(exam?: Exam,inCharge?: Party,deadlines?: Date) {
        super(exam);
        this.comments = "";
        this.classe = ClassNameMap.ExamProcessing;
        this.inCharge = inCharge;
        this.deadlines = deadlines;
        this.externalProcessing = true;
    }
}

export class ExamRequest extends ExamWorkflowStep {

	public prescriber?: Person;
	
    public prescriptionCode?: string;
    
    constructor(exam?: Exam,prescriber?: Person) {
        super(exam);
        this.classe = ClassNameMap.ExamRequest;
        this.prescriber = prescriber;
    }
}

export class ExamResult extends ExamWorkflowStep {

    public agent?: MedicalAgent;
    
    public medicalInstitution?: MedicalInstitutionPartner;
    
    public resultText?: string;

    public editionEnded?: boolean;
    
    constructor(exam?: Exam,agent?: MedicalAgent,medicalInstitution?: MedicalInstitutionPartner) {
        super(exam);
        this.classe = ClassNameMap.ExamResult;
        this.agent = agent;
        this.medicalInstitution = medicalInstitution;
        this.date = new Date(Date.now());
    }
}

export class ExamDTO extends PersistentDTO{    
    
    public examPrestation?: {code?: string, name?: string};
	
    public patientFolder?: FlatMedicalFolderDTO;

    public status?: ExamStatus | string;
	
	public currentStep?: ExamWorkflowStepDTO;
	
	public request?: ExamRequestDTO;
	
	public result?: ExamResultDTO;
	
	public assessment?: ExamAssessmentDTO;
}

export class ExamWorkflowStepDTO extends PersistentDTO{
    public examId?: string;
	public userLogin?: string;
	public date?: string | any;
}

export class ExamRequestDTO extends ExamWorkflowStepDTO{
    public prescriberFullname?: string;
	public prescriptionCode?: string;
}

export class ExamResultDTO extends ExamWorkflowStepDTO{
    public agentFullname?: string;
    public medicalInstitutionFullname?: string;
	public resultText?: string;
}

export class ExamAssessmentDTO extends ExamWorkflowStepDTO{
    public doctorFullname?: string;
	public comments?: string;
}

export class ExamApointmentDTO extends ExamWorkflowStepDTO {

	public apointmentId?: string;
	public patientId?: string;
	public apointmentDate?: string| any;
	public timeSlot?: TimeSlot;
	public personToMeetFullname?: string;
	public personEncounteredFullname?: string;
	public unitName?: string;
	public place?: string;
	public apointmentStatus?: string;
	public description?: string;
}

export class FlatMedicalFolderDTO extends PersistentDTO{
    public id?: string;	
	public patientId?: string;
    public number?: string;
    public patientFullname?: string;
    public patientBirthdate?: string| any;
    public patientNationalityName?: string;
    public patientGender?: string;
}