import { NkapValidators } from './../../base/nkap-validators';
import { Component, OnInit, ViewChild, ChangeDetectorRef, Input, Output, EventEmitter, OnChanges, SimpleChanges } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatInput, MatDialog } from '@angular/material';
import { TranslatePipe, TranslateService } from '@ngx-translate/core';
import { ModalComponentService } from '../../base/modal/modal.component';
import { NkapHttpService } from 'src/app/services/nkap-http.service';
import { Prescription } from 'src/app/model/prescriptions/prescription';
import { NkapNotificationService } from 'src/app/services/nkap-notification.service';
import { SearchCriteria } from 'src/app/model/data-page';
import { MedicalFolder } from 'src/app/model/admission/medical-folder';
import { ENTER } from '@angular/cdk/keycodes';

@Component({
  selector: 'app-prescription-selector',
  templateUrl: './prescription-selector.component.html',
  styleUrls: ['./prescription-selector.component.css']
})
export class PrescriptionSelectorComponent implements OnInit , OnChanges{

  @ViewChild('prescriptionInput')
  prescriptionInputCmp: MatInput;
  prescriptionControl = new FormControl();

  /**
   * To set the current prescription selected
   */
  @Input('selected-prescription')
  ordonnanceSelected: Prescription;

  @Output('prescriptionSelected')
  event: EventEmitter<Prescription> = new EventEmitter<Prescription>();
  
  @Input('required')
  isRequired: boolean = false;

  @Input('exam-prescription')
  isExamPrescription: boolean = false;

  @Input('care-prescription')
  isCarePrescription: boolean = false;

  /**
   * To set the current folder selected
   */
  @Input('selected-folder')
  medicalFolderSelected: MedicalFolder;

  /**
   * If details of patient selected must be show to the user
   */
  @Input('show-prescriptor-details')
  showPrescriptorDetails: boolean = true;

  /**
   * True to retrieve only valid prescription on the server
   */
  @Input('valid-prescription')
  validPrescription: boolean = true;

  /**
   * Criteria object send to the server to filter data
   */
  criteria: any | SearchCriteria;

  private translator: TranslatePipe;
  
  constructor(public dialog: MatDialog,private _ref: ChangeDetectorRef,
              public modalService: ModalComponentService,
              private httpService: NkapHttpService<any>, 
              private msgService: NkapNotificationService,
              private translateService: TranslateService ) {
        this.translator = new TranslatePipe(translateService, _ref);
        this.criteria = new SearchCriteria();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if(changes['ordonnanceSelected']) {
      this.prescriptionCodeSelected(changes['ordonnanceSelected'].currentValue);
      if (!this.ordonnanceSelected) {
        this.prescriptionControl.patchValue(null);
      }
    }
  }

  ngOnInit() {
    this.prescriptionControl = new FormControl(this.ordonnanceSelected ? this.ordonnanceSelected.code : null,
      this.isRequired == true? [NkapValidators.notEmpty] : []);
    this.prescriptionControl.markAsTouched();
  }

  /**
   * Search prescription base on search text enter
   * If a prescription is not found, display error message
   * */
  protected searchPrescription(event) {
    if(this.prescriptionControl.value != null) {
      this.criteria.prescriptionCode = this.prescriptionControl.value;
      this.criteria.validPrescription = this.validPrescription;
      this.criteria.examPrescription = this.isExamPrescription;
      this.criteria.carePrescription = this.isCarePrescription;
      this.criteria.folderId = this.medicalFolderSelected ? this.medicalFolderSelected.id : null;

      this.httpService.search(this.criteria, 'prescription', true).subscribe ( (result) => {
          if (result && result.datas && result.datas.length > 0) {
            if (result.datas.length == 1) {
              this.prescriptionCodeSelected(result.datas[0]);
            } else {
              this.prescriptionCodeSelected(null);
              this.translateService.get('prescription-selector.code.null.error').subscribe(val => {
                this.msgService.log(val);
              })
            }
          } else {
            this.prescriptionCodeSelected(null);
            this.translateService.get('prescription-selector.code.null.error').subscribe(val => {
              this.msgService.log(val);
            })
          }
      });
    }
  }

  protected clearPrescriptionCode(event) {
    this.prescriptionControl.patchValue(null);
    this.prescriptionCodeSelected(null);
  }

  // set the prescription selected
  protected prescriptionCodeSelected(presc: Prescription) {
    if(presc) {
      this.ordonnanceSelected = presc;
    } else {
      this.ordonnanceSelected = null;   
    }
    this.prescriptionControl.markAsTouched();
    this.event.emit(this.ordonnanceSelected);
  }

  catchEnter(event) {
    if(event && event.keyCode == ENTER) {
      this.searchPrescription(null);
    }
  }
}
