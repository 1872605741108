export enum Role {
  CREATE_PATIENT = 'CREATE PATIENT',
  EDIT_PATIENT = 'EDIT PATIENT',
  SHOW_MEDICAL_FOLDER = 'SHOW MEDICAL FOLDER',
  DELETE_PATIENT = 'DELETE PATIENT',
  PRINT_PATIENT = 'PRINT PATIENT',
  SHOW_PATIENT_HISTORY = 'SHOW PATIENT HISTORY',

  CREATE_ANTECEDENT = 'CREATE ANTECEDENT',
  SHOW_ANTECEDENT = 'SHOW ANTECEDENT',
  EDIT_ANTECEDENT = 'EDIT ANTECEDENT',
  DELETE_ANTECEDENT = 'DELETE ANTECEDENT',

  CREATE_ANTECEDENT_TYPE = 'CREATE ANTECEDENT TYPE',
  SHOW_ANTECEDENT_TYPE = 'SHOW ANTECEDENT TYPE',
  EDIT_ANTECEDENT_TYPE = 'EDIT ANTECEDENT TYPE',
  DELETE_ANTECEDENT_TYPE = 'DELETE ANTECEDENT TYPE',

  CREATE_APOINTMENT = 'CREATE APOINTMENT',
  SHOW_APOINTMENT = 'SHOW APOINTMENT',
  EDIT_APOINTMENT = 'EDIT APOINTMENT',
  DELETE_APOINTMENT = 'DELETE APOINTMENT',
  ACHIEVED_APOINTMENT = 'ACHIEVED APOINTMENT',

  CREATE_BUSINESSUNIT = 'CREATE BUSINESSUNIT',
  SHOW_BUSINESSUNIT = 'SHOW BUSINESSUNIT',
  EDIT_BUSINESSUNIT = 'EDIT BUSINESSUNIT',
  DELETE_BUSINESSUNIT = 'DELETE BUSINESSUNIT',

  CREATE_CARECOVERAGEDOC = 'CREATE CARECOVERAGEDOC',
  SHOW_CARECOVERAGEDOC = 'SHOW CARECOVERAGEDOC',
  EDIT_CARECOVERAGEDOC = 'EDIT CARECOVERAGEDOC',
  DELETE_CARECOVERAGEDOC = 'DELETE CARECOVERAGEDOC',

  CREATE_FRCV = 'CREATE FRCV',
  SHOW_FRCV = 'SHOW FRCV',
  EDIT_FRCV = 'EDIT FRCV',
  DELETE_FRCV = 'DELETE FRCV',

  CREATE_DOCTOR = 'CREATE DOCTOR',
  SHOW_DOCTOR = 'SHOW DOCTOR',
  EDIT_DOCTOR = 'EDIT DOCTOR',
  DELETE_DOCTOR = 'DELETE DOCTOR',

  DOCTOR_DIARY = 'DOCTOR DIARY',
  SHOW_DOCTOR_DIARY = 'SHOW DOCTOR DIARY',
  SHOW_HIM_DIARY = 'SHOW HIM DIARY',

  CREATE_USER = 'CREATE USER',
  SHOW_USER = 'SHOW USER',
  EDIT_USER = 'EDIT USER',
  DELETE_USER = 'DELETE USER',

  CREATE_RECEPTION = 'CREATE RECEPTION',
  SHOW_RECEPTION = 'SHOW RECEPTION',
  EDIT_RECEPTION = 'EDIT RECEPTION',
  DELETE_RECEPTION = 'DELETE RECEPTION',
  CLOSE_RECEPTION = 'ClOSE RECEPTION',

  CREATE_PRESTATION = 'CREATE PRESTATION',
  SHOW_PRESTATION = 'SHOW PRESTATION',
  EDIT_PRESTATION = 'EDIT PRESTATION',
  DELETE_PRESTATION = 'DELETE PRESTATION',
  PARAMS_PRESTATION = 'PARAMS PRESTATION',

  CREATE_PRESTATION_FAMILY = 'CREATE PRESTATION FAMILY',
  SHOW_PRESTATION_FAMILY = 'SHOW PRESTATION FAMILY',
  EDIT_PRESTATION_FAMILY = 'EDIT PRESTATION FAMILY',
  DELETE_PRESTATION_FAMILY = 'DELETE PRESTATION FAMILY',

  SHOW_CHRONOLOGICALLIST = 'SHOW CHRONOLOGICALLIST',
  PRINT_CHRONOLOGICALLIST = 'PRINT CHRONOLOGICALLIST',
  CLOSE_CHRONOLOGICALLIST = 'CLOSE CHRONOLOGICALLIST',

  CREATE_ACTIVITY = 'CREATE ACTIVITY',
  SHOW_ACTIVITY = 'SHOW ACTIVITY',
  EDIT_ACTIVITY = 'EDIT ACTIVITY',
  DELETE_ACTIVITY = 'DELETE ACTIVITY',

  CREATE_THIRD_PARTY_PAYER_INSURANCE = 'CREATE THIRD PARTY PAYER INSURANCE',
  CREATE_THIRD_PARTY_PAYER_EMPLOYER = 'CREATE THIRD PARTY PAYER EMPLOYER',
  CREATE_THIRD_PARTY_PAYER_PERSON = 'CREATE THIRD PARTY PAYER PERSON',
  SHOW_THIRD_PARTY_PAYER = 'SHOW THIRD PARTY PAYER',
  EDIT_THIRD_PARTY_PAYER = 'EDIT THIRD PARTY PAYER',
  DELETE_THIRD_PARTY_PAYER = 'DELETE THIRD PARTY PAYER',


  CREATE_SYSTEM_INVESTIGATION = 'CREATE SYSTEM INVESTIGATION',
  SHOW_SYSTEM_INVESTIGATION = 'SHOW SYSTEM INVESTIGATION',
  EDIT_SYSTEM_INVESTIGATION = 'EDIT SYSTEM INVESTIGATION',
  DELETE_SYSTEM_INVESTIGATION = 'DELETE SYSTEM INVESTIGATION',

  CREATE_SYSTEM_INVESTIGATION_TYPE = 'CREATE SYSTEM INVESTIGATION TYPE',
  SHOW_SYSTEM_INVESTIGATION_TYPE = 'SHOW SYSTEM INVESTIGATION TYPE',
  EDIT_SYSTEM_INVESTIGATION_TYPE = 'EDIT SYSTEM INVESTIGATION TYPE',
  DELETE_SYSTEM_INVESTIGATION_TYPE = 'DELETE SYSTEM INVESTIGATION TYPE',


  CREATE_CONSULTATION_SHEET = 'CREATE CONSULTATION SHEET',
  EDIT_CONSULTATION_SHEET = 'EDIT CONSULTATION SHEET',
  SHOW_CONSULTATION_SHEET = 'SHOW CONSULTATION SHEET',
  DELETE_CONSULTATION_SHEET = 'DELETE CONSULTATION SHEET',

  CREATE_EXAM_PRESCRIPTION = 'CREATE EXAM PRESCRIPTION',
  EDIT_EXAM_PRESCRIPTION = 'EDIT EXAM PRESCRIPTION',
  SHOW_EXAM_PRESCRIPTION = 'SHOW EXAM PRESCRIPTION',
  DELETE_EXAM_PRESCRIPTION = 'DELETE EXAM PRESCRIPTION',
  PRINT_EXAM_PRESCRIPTION = 'PRINT EXAM PRESCRIPTION',
  CLOSE_EXAM_PRESCRIPTION = 'CLOSE EXAM PRESCRIPTION',

  CREATE_CARE_PRESCRIPTION = 'CREATE CARE PRESCRIPTION',
  EDIT_CARE_PRESCRIPTION = 'EDIT CARE PRESCRIPTION',
  SHOW_CARE_PRESCRIPTION = 'SHOW CARE PRESCRIPTION',
  DELETE_CARE_PRESCRIPTION = 'DELETE CARE PRESCRIPTION',
  PRINT_CARE_PRESCRIPTION = 'PRINT CARE PRESCRIPTION',
  CLOSE_CARE_PRESCRIPTION = 'CLOSE CARE PRESCRIPTION',

  SHOW_COSTS_REGULATIONS_STATEMENTS = 'SHOW_COSTS_REGULATIONS_STATEMENTS',
  PRINT_COSTS_REGULATIONS_STATEMENTS = 'PRINT_COSTS_REGULATIONS_STATEMENTS',

  SHOW_COUNTER_ACTIVITIES = 'SHOW COUNTER ACTIVITIES',
  PRINT_COUNTER_ACTIVITIES = 'PRINT COUNTER ACTIVITIES',

  CONSULTATION_HOME = 'CONSULTATION HOME',
  EXAMS_HOME = 'EXAMS HOME',
  TREATMENTS_HOME = 'TREATMENTS HOME',

  SHOW_BILLING_OF_SERVICES_STATEMENTS = 'SHOW_BILLING_OF_SERVICES_STATEMENTS',
  PRINT_BILLING_OF_SERVICES_STATEMENTS = 'SHOW_BILLING_OF_SERVICES_STATEMENTS',

  ADMIN = 'ADMIN-CMH',
  PRINT_MEDICAL_PRESCRIPTION = "PRINT MEDICAL PRESCRIPTION",
  EDIT_MEDICAL_PRESCRIPTION = "EDIT MEDICAL PRESCRIPTION",
  SHOW_MEDICAL_PRESCRIPTION = "SHOW MEDICAL PRESCRIPTION",
  DELETE_MEDICAL_PRESCRIPTION = "DELETE MEDICAL PRESCRIPTION",
  CREATE_MEDICAL_PRESCRIPTION = "CREATE MEDICAL PRESCRIPTION",
  CLOSE_MEDICAL_PRESCRIPTION = "CLOSE MEDICAL PRESCRIPTION",
  GENERATE_EXAM_BASED_ON_RECEPTION = "GENERATE_EXAM_BASED_ON_RECEPTION",

  LIST_CARE = "CARE_SEARCH",
  SHOW_CARE = "CARE_VIEW_AN_CARE",
  DELETE_CARE = "DELETE_CARE",
  SAVE_CARE_EXECUTION = "CARE_SAVE_EXECUTION",
  CREATE_CARE_APOINTMENT = "CREATE_CARE_APOINTMENT",
  CARE_PLAN_NEW_APOINTMENT = "CARE_PLAN_NEW_APOINTMENT",
  CARE_VIEW_AN_CARE = "CARE_VIEW_AN_CARE",

  LIST_EXAM = "EXAM_SEARCH",
  SHOW_EXAM = "EXAM_VIEW_AN_EXAM",
  DELETE_EXAM = "DELETE_EXAM",
  SAVE_EXAM_RESULT = "EXAM_SAVE_RESULT",
  CREATE_EXAM_APOINTMENT = "CREATE_EXAM_APOINTMENT",
  EXAM_PLAN_NEW_APOINTMENT = "EXAM_PLAN_NEW_APOINTMENT",
  EXAM_VIEW_AN_EXAM = "EXAM_VIEW_AN_EXAM",
  SAVE_EXAM_ASSESSMENT = "SAVE_EXAM_ASSESSMENT",
  EXAM_RESULT_PRINT = "EXAM_RESULT PRINT",
  EXAM_PRINT = "EXAM_PRINT_LIST",
  EDIT_MEDICAL_INSTITUTION_PARTNER = "EDIT_MEDICAL_INSTITUTION_PARTNER",
  CREATE_MEDICAL_INSTITUTION_PARTNER = "CREATE_MEDICAL_INSTITUTION_PARTNER",
  SHOW_MEDICAL_INSTITUTION_PARTNER = "SHOW_MEDICAL_INSTITUTION_PARTNER",
  LIST_MEDICAL_INSTITUTION_PARTNER = "LIST_MEDICAL_INSTITUTION_PARTNER",
  DELETE_MEDICAL_INSTITUTION_PARTNER = "DELETE_MEDICAL_INSTITUTION_PARTNER",
  SAVE_EXAM_PROCESSING = "SAVE_EXAM_PROCESSING"
}