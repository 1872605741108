import { Persistent, ClassNameMap } from '../persistent';

export class Document extends Persistent {
    public data?: string;
    public externalId?: number;
    public name?: string;
    constructor() {
        super();
        this.classe = ClassNameMap.Document;
    }
}
