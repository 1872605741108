import { Component, OnInit, ViewChild } from '@angular/core';
import { MatTable, MatTableDataSource, MatInput } from '@angular/material';
import { PrestationFamily, Prestation } from 'src/app/model/prestation/prestation';
import { FormControl, Validators } from '@angular/forms';
import { IFormActionBarButton, FormAction } from 'src/app/components/buttons-bar/buttons-bar.component';
import { Router, ActivatedRoute } from '@angular/router';
import { NkapNotificationService, NotificationType } from 'src/app/services/nkap-notification.service';
import { NkapHttpService } from 'src/app/services/nkap-http.service';
import { BusinessUnit } from 'src/app/model/organisation/business-unit';
import { NkapValidators } from 'src/app/components/base/nkap-validators';
import { Funtions } from 'src/app/model/security/funtionalities';

@Component({
  selector: 'app-prestation-family-edit',
  templateUrl: './prestation-family-edit.component.html',
  styleUrls: ['./prestation-family-edit.component.css']
})
export class PrestationFamilyEditComponent implements OnInit {

  protected currentObject: PrestationFamily;

  familyControl: FormControl = new FormControl('', [ NkapValidators.dataWithIdSelected]);
  familyList: PrestationFamily[];

  codeControl: FormControl;
  nameControl: FormControl;

  serviceControl = new FormControl('', [ NkapValidators.dataWithIdSelected]);
  serviceList: BusinessUnit[];

  protected buttons: IFormActionBarButton[];

  constructor(private httpService: NkapHttpService<PrestationFamily>, private router: Router,
              private route: ActivatedRoute, private msgService: NkapNotificationService) {
    this.currentObject = new PrestationFamily();
    this.buttons = [
      {id: "save", value: FormAction.CREATE, icon: {type : 'save'},
        text: 'btn.save.label', disabled: false, functionalities: [Funtions.prestationFamily_btn_new_fct, Funtions.prestationFamily_btn_edit_fct]},
      {id: "list", value: FormAction.VIEW, icon: {type : 'list'},
        text: 'btn.list.label', disabled: false, functionalities: [Funtions.prestationFamily_btn_list_fct]}
    ];
  }

  ngOnInit() {
    this.codeControl = new FormControl('', [Validators.required, Validators.minLength(1)]);
    this.codeControl.updateValueAndValidity();
    this.nameControl = new FormControl('', [Validators.required, Validators.minLength(1)]);
    this.nameControl.updateValueAndValidity();
    this.familyControl = new FormControl('', [ NkapValidators.dataWithIdSelected]);
    this.route.paramMap.subscribe(params => {
      let data: {id?: string} = (params as any).params;
      if (data.id != null) {
        this.httpService.findById(data.id, 'prestation/prestation-family', true).subscribe( (object)=>{
          this.setObjectInView(object);
        }, (errors) => {
          this.msgService.log("prestation-family.edit.get.data.error", NotificationType.ERROR);
        });
      }
    });
    this.familyControl.valueChanges.subscribe( (inputValue) => {
      if (typeof inputValue === 'string' ) {
        this.currentObject.family = null;
        this.httpService.search({value: inputValue}, 'prestation/prestation-family').subscribe ( (result) => {
           this.familyList = result ? result.datas : [];
        });
      } else {
        this.currentObject.family = inputValue;
        if(this.currentObject.abilitedService == null && inputValue && inputValue.abilitedServiceId != null){
          const unit = new BusinessUnit();
          unit.id = inputValue.abilitedServiceId;
          unit.name = inputValue.abilitedServiceName;
          this.serviceControl.setValue(unit)
        }
      }
    });
    this.serviceControl = new FormControl('', [ NkapValidators.dataWithIdSelected]);
    this.serviceControl.valueChanges.subscribe( (inputValue) => {
      if (typeof inputValue === 'string' ) {
        this.currentObject.abilitedService = null;
        this.httpService.search({value: inputValue}, 'organisation/business-unit').subscribe ( (result) => {
           this.serviceList = result ? result.datas : [];
         });
       } else {
         this.currentObject.abilitedService = inputValue;
       }
    });
   
  }

  displayFamilyFn(family?: PrestationFamily): string  {
    return family ? `${family.code} ${family.name ? family.name : ''}` : '';
  }

  displayServiceFn(service?: BusinessUnit): string {
    return service ? `${service.name}` : '';
  }

  btnActionClick(event) {
    if(event && event.id === this.buttons[0].id){
      this.save();
    } else if(event && event.id === this.buttons[1].id){
      this.router.navigate(["prestation-family/list", {search: true}]);
    }
  }

  displayPrestationFn(prestation) {
    return prestation ? `${prestation.code} ${prestation.name}` : '';
  }

  displayOtherPrestationFn(prestation){
    return '';
  }

  isValid(){
    let isValid = true;
    this.getObjectFromView();
    this.codeControl.markAsTouched();
    this.nameControl.markAsTouched();
    this.serviceControl.markAsTouched();
    this.familyControl.markAsTouched();
    if(this.codeControl.invalid){
      isValid = false;
    }
    if(this.nameControl.invalid){
      isValid = false;
    }
    if(this.serviceControl.invalid){
      isValid = false;
    }
    if(this.familyControl.invalid){
      isValid = false;
    }
    console.log( "isValid" , isValid);
    return isValid;
  }

  getObjectFromView(){
    this.currentObject.code = this.codeControl.value;
    this.currentObject.name = this.nameControl.value;
  }

  setObjectInView(object : PrestationFamily){
    if(object){
      this.currentObject = object;
      this.codeControl = new FormControl(object.code, [Validators.required, Validators.minLength(1)]);
      this.codeControl.updateValueAndValidity();
      this.nameControl = new FormControl(object.name, [Validators.required, Validators.minLength(1)]);
      this.nameControl.updateValueAndValidity();
      this.familyControl.setValue(object.family ? object.family : null);
      this.serviceControl.setValue(object.abilitedService ? object.abilitedService : null);
    }
  }

  save() {
    if (this.isValid() === true){
      this.httpService.save(this.currentObject, 'prestation/prestation-family', true).subscribe( (result) => {
        this.msgService.log("prestation-family.edit.save.succed", NotificationType.SUCCESS);
        this.router.navigate(['prestation-family/list', {search: true}]);
      }, (error) => {
        if (error && Array.isArray(error)) {
          error.forEach( (err) => {
            this.msgService.log(err, NotificationType.ERROR);
          });
        } else if(typeof error === 'string') {
          this.msgService.log(error, NotificationType.ERROR);
        }  else {
          this.msgService.log("prestation-family.edit.save.fail", NotificationType.ERROR);
        }
      });
    }/* else {
      this.msgService.log("prestation-family.edit.prestation.data.invalid", NotificationType.ERROR);
    }*/
  }


}
