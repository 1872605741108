import { CareDTO, CareWorkflowStepDTO } from './../../../model/care/care';
import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NkapHttpService } from 'src/app/services/nkap-http.service';
import { ButtonsBarComponent, FormAction, IFormActionBarButton } from '../../buttons-bar/buttons-bar.component';
import { Funtions } from 'src/app/model/security/funtionalities';
import { NkapNotificationService, NotificationType } from 'src/app/services/nkap-notification.service';
import { MatTable, MatTableDataSource } from '@angular/material';
import * as moment from 'moment';

@Component({
  selector: 'app-care-show',
  templateUrl: './care-show.component.html',
  styleUrls: ['./care-show.component.css']
})
export class CareShowComponent implements OnInit {

  currentCare?: CareDTO;

  careSteps: CareWorkflowStepDTO[];
  tableDataSource: MatTableDataSource<CareWorkflowStepDTO> = new MatTableDataSource([]);
  
  @ViewChild(MatTable)
  tableCmp: MatTable<any>;
  panelOpenState = false;

  @ViewChild("btnBar")
  btnBarCmp: ButtonsBarComponent;
  buttons: IFormActionBarButton[] = [];
  careDetailsFunctionality = [Funtions.care_zone_view_details_zone];

  constructor(protected router: Router, protected route: ActivatedRoute, 
    protected msgService: NkapNotificationService,private httpService: NkapHttpService<any>) {
      this.buttons = [
        {id: "list", value: FormAction.VIEW, icon: {type : 'list'},
          text: 'btn.list.label', disabled: false, functionalities: [Funtions.care_btn_list_fct]}
      ];
  }

  ngOnInit() {
    this.route.paramMap.subscribe(params => {
      let data: {id?: string} = (params as any).params;
      console.log(" ngOnInit ", data);
      if(data && data.id != null) {
        this.httpService.findById(data.id, "care/with-details").subscribe ( (result) => {
          this.currentCare = result;
        }); 
      }
    });     
  }

  btnActionClick(event) {
    let i = 0; 
    if(event && event.id === this.buttons[i++].id){
      this.router.navigate(["./care/list", {search: true}]);
    }
  }

  loadSteps() {
    if(this.careSteps == null && this.currentCare) {
      this.httpService.findById(this.currentCare.id, "care/steps").subscribe ( (result) => {
        this.careSteps= result;
        this.tableDataSource.data = this.careSteps ;
        this.tableCmp.renderRows();
      }); 
    }
  }

  formatDate(date){
    try {
        return moment(new Date(date)).format("LL") ;
    } catch (e) {
      console.error("Parse date in reception-list fail " , e);
    }
  }

}
