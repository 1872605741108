import { MedicalInstitutionPartner, MedicalInstitutionType } from 'src/app/model/organisation/medical-institution-partner';
import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { FormAction, IFormActionBarButton } from 'src/app/components/buttons-bar/buttons-bar.component';
import { TranslatePipe, TranslateService } from '@ngx-translate/core';
import { NkapHttpService } from 'src/app/services/nkap-http.service';
import { ActivatedRoute, Router } from '@angular/router';
import { NkapNotificationService, NotificationType } from 'src/app/services/nkap-notification.service';
import { Funtions } from 'src/app/model/security/funtionalities';

@Component({
  selector: 'app-medical-institution-partner-show',
  templateUrl: './medical-institution-partner-show.component.html',
  styleUrls: ['./medical-institution-partner-show.component.css']
})
export class MedicalInstitutionPartnerShowComponent implements OnInit {

  currentObject: MedicalInstitutionPartner;
  type = null;

  protected buttons: IFormActionBarButton[];

  translate: TranslatePipe;

  constructor(private _ref: ChangeDetectorRef, protected translateService: TranslateService,
              private httpService: NkapHttpService<MedicalInstitutionPartner>,
              private router: Router, private route: ActivatedRoute,
              private msgService: NkapNotificationService) {
    this.translate = new TranslatePipe(translateService, _ref);
    this.currentObject = null;
    this.buttons = [
        {id: 'edit', value: FormAction.UPDATE, icon: {type : 'edit', color: '#ff9933'},
          text: 'medical-institution-partner.btn.edit.label', disabled: false, functionalities: [Funtions.medicalInstitutionPartner_btn_edit_fct]},
        {id: 'delete', value: FormAction.DELETE, icon: {type : 'delete_forever', color: '#ff9933'},
           text: 'medical-institution-partner.btn.delete.label', disabled: false, functionalities: [Funtions.medicalInstitutionPartner_btn_delete_fct]},
        {id: 'list', value: FormAction.VIEW, icon: {type : 'list'}, text: 'btn.list.label', disabled: false, functionalities: [Funtions.medicalInstitutionPartner_btn_list_fct]}
      ];
  }

  ngOnInit() {
    this.route.paramMap.subscribe(params => {
      const data: {id?: string | number} = (params as any).params;
      if (data.id != null) {
        this.httpService.findById(data.id, 'organisation/medical-institution', true).subscribe( (object) => {
          this.currentObject = object;
          this.type = object ? object.type : null;
        }, (errors) => {
          this.msgService.log('form.edit.get.data.error', NotificationType.ERROR);
        });
      }
    });
  }

  btnActionClick(event) {
    if (event && event.id === this.buttons[0].id) {
      this.router.navigate(['medical-institution-partner/edit', {id: this.currentObject.id}]);
    } else if (event && event.id === this.buttons[1].id) {
        this.delete();
    } else if (event && event.id === this.buttons[2].id) {
      this.router.navigate(['medical-institution-partner/list', {search : true}]);
    }
  }

  delete() {
    this.msgService.confirm(this.translate.transform("medical-institution-partner.delete.confirm.text")).subscribe( (result) => {
      if(result === true){
         this.httpService.deleteById(this.currentObject.id, 'organisation/medical-institution').subscribe( (val) => {
            this.msgService.log('medical-institution-partner.delete.succed', NotificationType.SUCCESS);
            this.router.navigate(['medical-institution-partner/list', {search : true}]);
          }, (error) => {
            this.msgService.log('medical-institution-partner.delete.fail', NotificationType.ERROR);
          });
      }});
  }

  displayFamilyFn(elmt:MedicalInstitutionPartner){
    if( elmt && elmt.type != null ) {
      return `MedicalInstitutionType.${elmt.type}.label`;
    }
  }
}