import { Persistent, ClassNameMap } from '../persistent';
import { Person } from './person';

export class ContactPerson extends Persistent {
    public person?:Person
    public acquaintanceType?: string; //type relationship with the patient
    constructor() {
        super();
        this.classe = ClassNameMap.ContactPerson;
    }
    public company?: string; //use in the case of attending doctor to specify his hospital
}