import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
import { ThirdPartyPayer } from 'src/app/model/admission/care-coverage-doc';
import { IFormActionBarButton, FormAction } from 'src/app/components/buttons-bar/buttons-bar.component';
import { TranslatePipe, TranslateService } from '@ngx-translate/core';
import { NkapHttpService } from 'src/app/services/nkap-http.service';
import { PrestationFamily } from 'src/app/model/prestation/prestation';
import { NkapNotificationService, NotificationType } from 'src/app/services/nkap-notification.service';
import { ActivatedRoute, Router } from '@angular/router';
import { Funtions } from 'src/app/model/security/funtionalities';

@Component({
  selector: 'app-third-party-payer-show',
  templateUrl: './third-party-payer-show.component.html',
  styleUrls: ['./third-party-payer-show.component.css']
})
export class ThirdPartyPayerShowComponent implements OnInit {

  protected currentObject: ThirdPartyPayer;
  protected type = null;

  protected buttons: IFormActionBarButton[];

  translate: TranslatePipe;

  constructor(private _ref: ChangeDetectorRef, protected translateService: TranslateService,
              private httpService: NkapHttpService<ThirdPartyPayer>,
              private router: Router, private route: ActivatedRoute,
              private msgService: NkapNotificationService) {
    this.translate = new TranslatePipe(translateService, _ref);
    this.currentObject = null;
    this.buttons = [
        {id: 'edit', value: FormAction.UPDATE, icon: {type : 'edit', color: '#ff9933'},
          text: 'third-party-payer.btn.edit.label', disabled: false, functionalities: [Funtions.thirdPartyPayer_btn_edit_fct]},
        {id: 'delete', value: FormAction.DELETE, icon: {type : 'delete_forever', color: '#ff9933'},
           text: 'third-party-payer.btn.delete.label', disabled: false, functionalities: [Funtions.thirdPartyPayer_btn_delete_fct]},
        {id: 'list', value: FormAction.VIEW, icon: {type : 'list'}, text: 'btn.list.label', disabled: false, functionalities: [Funtions.thirdPartyPayer_btn_list_fct]}
      ];
  }

  ngOnInit() {
    this.route.paramMap.subscribe(params => {
      const data: {id?: string | number} = (params as any).params;
      if (data.id != null) {
        this.httpService.findById(data.id, 'third-party-payer', true).subscribe( (object) => {
          this.currentObject = object;
          this.type = object ? object.type : null;
        }, (errors) => {
          this.msgService.log('form.edit.get.data.error', NotificationType.ERROR);
        });
      }
    });
  }

  btnActionClick(event) {
    if (event && event.id === this.buttons[0].id) {
      this.router.navigate(['third-party-payer/edit', {id: this.currentObject.id}]);
    } else if (event && event.id === this.buttons[1].id) {
        this.delete();
    } else if (event && event.id === this.buttons[2].id) {
      this.router.navigate(['third-party-payer/list', {search : true}]);
    }
  }

  delete() {
    this.msgService.confirm(this.translate.transform("third-party-payer.delete.confirm.text")).subscribe( (result) => {
      if(result === true){
         this.httpService.deleteById(this.currentObject.id, 'third-party-payer').subscribe( (val) => {
            this.msgService.log('third-party-payer.delete.succed', NotificationType.SUCCESS);
            this.router.navigate(['third-party-payer/list', {search : true}]);
          }, (error) => {
            this.msgService.log('third-party-payer.delete.fail', NotificationType.ERROR);
          });
      }});
  }

  displayFamilyFn(elmt:ThirdPartyPayer){
    if( elmt && elmt.type != null ) {
      return `ThirdPartyPayerType.${elmt.type}.label`;
    }
  }
}
