import { Persistent, ClassNameMap } from '../persistent';
import { MedicalAgentType } from './medical-agent';

export class BusinessUnit extends Persistent {
    public name?: string;
    public parent?: BusinessUnit;
    public root?: boolean;
    public hasChildren?: boolean;
    constructor(name?: string) {
        super();
        this.classe = ClassNameMap.BusinessUnit;
        this.name = name? name : "";
    }
}