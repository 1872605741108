import { Component, OnInit, Input, ChangeDetectorRef } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NkapHttpService } from 'src/app/services/nkap-http.service';
import { NkapNotificationService, NotificationType } from 'src/app/services/nkap-notification.service';
import { IFormActionBarButton, FormAction } from '../../buttons-bar/buttons-bar.component';
import { Antecedent, AntecedentCategory, AntecedentType } from 'src/app/model/admission/medical-folder';
import { TranslatePipe, TranslateService } from '@ngx-translate/core';
import { Funtions } from 'src/app/model/security/funtionalities';

@Component({
  selector: 'app-antecedent-show',
  templateUrl: './antecedent-show.component.html',
  styleUrls: ['./antecedent-show.component.css']
})
export class AntecedentShowComponent implements OnInit {

  @Input() antecedent: Antecedent;

  protected buttons: IFormActionBarButton[];

  categoryList: {value: string , text: string}[];

  translate: TranslatePipe;

  constructor(private _ref: ChangeDetectorRef,private route: ActivatedRoute,
    private msgService: NkapNotificationService,private httpService: NkapHttpService<Antecedent>,
    private router: Router, protected translateService: TranslateService) {
      this.translate = new TranslatePipe(translateService, _ref);


    this.buttons = [
      {id: 'edit', value: FormAction.UPDATE, icon: {type : 'edit', color: '#ff9933'}, 
        text: 'antecedent.btn.edit.label', disabled: false, functionalities: [Funtions.antecedent_btn_edit_fct]},
      {id: 'delete', value: FormAction.DELETE, icon: {type : 'delete_forever', color: '#ff9933'},
         text: 'antecedent.btn.delete.label', disabled: false, functionalities: [Funtions.antecedent_btn_delete_fct]},
      {id: 'list', value: FormAction.VIEW, icon: {type : 'list'}, text: 'btn.list.label', disabled: false, functionalities: [Funtions.antecedent_btn_list_fct]}
    ];

    this.categoryList = [
      {value: AntecedentCategory[AntecedentCategory.PERSONAL], text:"antecedent.category." + AntecedentCategory[AntecedentCategory.PERSONAL] + ".label"},
      {value: AntecedentCategory[AntecedentCategory.FAMILY], text:"antecedent.category." + AntecedentCategory[AntecedentCategory.FAMILY] + ".label"},
    ];
    
   }

  ngOnInit() {
    this.route.paramMap.subscribe(params => {
      let data: {id?: string | number} = (params as any).params;
      if (data.id != null) {
        this.httpService.findById(data.id, 'admission/antecedent', true).subscribe( (antecedent) => {
          this.antecedent = antecedent;
        }, (errors) => {
          this.msgService.log('antecedent.edit.get.data.error', NotificationType.ERROR);
        });
      }
    });

  }


  getAntecedentById(id: number): Antecedent {
    let ant: Antecedent;
    this.httpService.findById( id, `admission/antecedent`).subscribe(result => {
      this.antecedent = result;
      ant=result;
    });
    return ant;
  }

  displayAntecedentFn(antecedent) {
    return antecedent ? `${antecedent.name} ${antecedent.description}` : '';
  }

  displayCategoryFn(antecedent:Antecedent){
    if(antecedent.category.toString()==AntecedentCategory[AntecedentCategory.PERSONAL])
    return this.categoryList[0].text;
    if(antecedent.category.toString()==AntecedentCategory[AntecedentCategory.FAMILY])
    return this.categoryList[1].text;
  }

  displayTypeFn(antecedent?: Antecedent): string {
    return antecedent.type ? `${antecedent.type.name}` : '';
  }

  btnActionClick(event) {
    if (event && event.id === this.buttons[0].id) {
      this.router.navigate(['antecedent/edit', {id: this.antecedent.id}]);
    } else if (event && event.id === this.buttons[1].id) {
        this.delete();
    } else if (event && event.id === this.buttons[2].id) {
      this.router.navigate(['antecedent/list', {search : true}]);
    } else if (event && event.id === this.buttons[1].id) {
      
    }
  }


  delete(){
    this.msgService.confirm(this.translate.transform("antecedent.delete.confirm.text")).subscribe( (result) => {
      if(result === true){
        this.httpService.deleteById(this.antecedent.id, 'admission/antecedent', true).subscribe( (val) => {
          this.msgService.log('antecedent.delete.succed', NotificationType.SUCCESS);
          this.router.navigate(['antecedent/list', {search : true}]);
        }, (error) => {
          this.msgService.log('antecedent.delete.fail', NotificationType.ERROR);
        });
      }
    });
  }

}
