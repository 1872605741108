import { ClassNameMap } from './../../../model/persistent';
import { MedicalInstitutionPartner } from 'src/app/model/organisation/medical-institution-partner';
import { Exam, ExamResult, ExamStatus } from './../../../model/exam/exam';
import { AfterViewInit, Component, OnChanges, OnInit, SimpleChanges, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NkapHttpService } from 'src/app/services/nkap-http.service';
import { UserAppService } from 'src/app/services/user-app.service';
import { ButtonsBarComponent, FormAction, IFormActionBarButton } from '../../buttons-bar/buttons-bar.component';
import { NkapValidators } from '../../base/nkap-validators';
import { MedicalAgent } from 'src/app/model/organisation/medical-agent';
import { ActivatedRoute, Router } from '@angular/router';
import { User } from 'src/app/model/security/user';
import { Funtions } from 'src/app/model/security/funtionalities';
import { NkapNotificationService, NotificationType } from 'src/app/services/nkap-notification.service';

@Component({
  selector: 'app-exam-result-edit',
  templateUrl: './exam-result-edit.component.html',
  styleUrls: ['./exam-result-edit.component.css']
})
export class ExamResultEditComponent implements OnInit, AfterViewInit, OnChanges {

  buttons: IFormActionBarButton[] = [];
  form: FormGroup;
  currentObject: ExamResult;

  agentConnected: MedicalAgent;
  medicalAgentList: MedicalAgent[];

  medicalInstitution: MedicalInstitutionPartner;
  isExternalResult: boolean = false;

  currentExam: Exam;

  userConnected: User;
  
  @ViewChild("btnBar")
  btnBarCmp: ButtonsBarComponent;
  protected editionEnded: boolean ;

  constructor(private formBuilder: FormBuilder,private userService: UserAppService,
    private msgService: NkapNotificationService,
    protected router: Router, protected route: ActivatedRoute, private httpService: NkapHttpService<any>) {
    this.currentObject = new ExamResult();
    this.buttons = [
      {id: "save", value: FormAction.CREATE, icon: {type : 'save'},
        text: 'btn.save.draft.label', disabled: false, functionalities: [Funtions.exam_btn_saveResult_fct, Funtions.exam_btn_editResult_fct]},
      {id: "to_validate", value: FormAction.CREATE, icon: {type : 'thumb_up'},
        text: 'btn.save.ok.label', disabled: false, functionalities: [Funtions.exam_btn_saveResult_fct, Funtions.exam_btn_editResult_fct]},
      {id: "list", value: FormAction.VIEW, icon: {type : 'list'},
        text: 'btn.list.label', disabled: false, functionalities: [Funtions.exam_btn_list_fct]}
    ];
  }

  public initExamResultForm(result: ExamResult): void {
    this.editionEnded = null;
    this.currentObject = result;
    console.log("initExamResultForm ", this.currentObject);
    this.form = this.formBuilder.group({
      date: [this.currentObject && this.currentObject.date? this.currentObject.date : new Date(Date.now()), [Validators.required, NkapValidators.notAfterCurrentDay]],
      agent: [this.currentObject && this.currentObject.agent? this.currentObject.agent : this.agentConnected, [Validators.required, NkapValidators.dataWithIdSelected]],
      medicalInstitution: [this.currentObject && this.currentObject.medicalInstitution? this.currentObject.medicalInstitution : this.medicalInstitution, [Validators.required, NkapValidators.dataWithIdSelected]],
      resultText: [this.currentObject? this.currentObject.resultText : '', [Validators.required, NkapValidators.notEmpty]],
    });

    this.form.get('agent').valueChanges.subscribe( (inputValue) => {
      if (typeof inputValue === 'string' ) {
        let url = 'organisation/medical-agent';        
        this.httpService.search({value: inputValue}, url).subscribe ( (result) => {
          this.medicalAgentList = result ? result.datas : [];
        });          
      }
    });
    this.form.get('resultText').markAsTouched();
    this.form.get('resultText').updateValueAndValidity({onlySelf: true});
    this.form.get('agent').markAsTouched();
    this.form.get('agent').updateValueAndValidity({onlySelf: true});
    this.switchExternalInternalResul();
    this.form.statusChanges.subscribe( ()=> {
      this.btnStatusChange();
    });
  }

  switchExternalInternalResul() {
    if(this.form) {
      if(this.isExternalResult == true){ // we set medicalInstitution
        this.form.get('medicalInstitution').setValue(this.medicalInstitution);
        this.form.get('agent').clearValidators();
        this.form.get('agent').setValue(undefined);
      } else { 
        this.form.get('medicalInstitution').clearValidators();
        this.form.get('medicalInstitution').setValue(undefined);
      }
    }
  }

  btnStatusChange() {
    if(this.btnBarCmp){
      const btnStatus = [
        {id: "save", disabled: !this.isValid()},
        {id: "to_validate", disabled: !this.isValid()},
        {id: "list", disabled: false },
      ];  
      console.log(" btnStatusChange ", btnStatus);  
      this.btnBarCmp.setButtonsStatus(btnStatus);
    }
  }

  ngOnInit() { 
    
    this.route.paramMap.subscribe(params => {
      //console.log(" ngOnInit params", params);
      let data: {id?: string, examId?: string} = (params as any).params;
      console.log(" ngOnInit ", data);
      if(data && data.examId != null) {
        this.httpService.findById(data.examId, "exam").subscribe ( (result) => {
          this.currentExam = result;
          if(result != null && result.status == ExamStatus[ExamStatus.IN_PROCESS] && result.currentStep.externalProcessing ==true ) {
            console.log("result ", result);
            this.isExternalResult = true;
            this.httpService.get(`organisation/medical-institution/find-by-party-id/${result.currentStep.inChargeId}`).subscribe ( (medicalInst) => {
              this.medicalInstitution = medicalInst;
              this.currentObject.medicalInstitution = this.medicalInstitution;
            });
          } else {
            this.medicalInstitution = null;
            this.isExternalResult = false;
          }
          if(this.form && this.form.get('medicalInstitution').value == null){
            this.form.get('medicalInstitution').setValue(this.medicalInstitution);
          }
          this.switchExternalInternalResul();
        });  
      }
      if(data && data.id != null) {
        this.httpService.findById(data.id, "exam/result").subscribe ( (result) => {
          this.medicalInstitution = result? result.medicalInstitution : null;
          if(result.classe == ClassNameMap.ExternalExamResult) {
            this.isExternalResult = true;
          }
          this.initExamResultForm(result);
        });  
      } else {
        this.initExamResultForm(this.currentObject);  
      }
    });
     
  }

  ngAfterViewInit(): void {
    // Get the MedicalAgent connected
    this.userConnected = this.userService.getUser();
    let userId = this.userConnected.number;
    if(userId != null) {
      this.httpService.search({userId: userId ? userId : undefined},`organisation/medical-agent`).subscribe(results => {
        if(results.datas && results.datas.length > 0){
          this.agentConnected = results.datas[0];
          if(this.form.get('agent').invalid) {
            this.form.get('agent').setValue(this.agentConnected);
          }
        }
      });
    }
  }
  
  ngOnChanges(changes: SimpleChanges): void {
    //console.log("changes ", changes);
    if(changes["currentObject"]){
      console.log("changes on currentObject ", changes["currentObject"], this.currentObject);
      this.initExamResultForm(this.currentObject);
      this.form.updateValueAndValidity({onlySelf: true, emitEvent: true});
    }
  }
 
  public isValid(): boolean {
    //this.form.updateValueAndValidity({onlySelf:true});
    
    let isformValid = this.form.valid ? true : false;
    if(this.currentExam == null || this.currentExam.id ==null){
      isformValid == false;
    }
    if(this.userConnected == null || this.userConnected.id ==null){
      isformValid == false;
    }
    console.log("isValid", isformValid, this.form.valid, this.currentExam, this.userConnected);
    return isformValid;
  }

  public getData(): ExamResult {
    if(!this.currentObject){
      this.currentObject = new ExamResult();
    }
    this.currentObject = Object.assign( this.currentObject, this.form.value);   
    this.currentObject.user = this.userConnected;
    this.currentObject.exam = null;  
    if(this.currentObject.id == null) {
      this.currentObject.classe = this.isExternalResult == true ? ClassNameMap.ExternalExamResult : ClassNameMap.InternalExamResult;
      this.currentObject.medicalInstitution = this.isExternalResult == true ? this.medicalInstitution : undefined;
    }  
    return this.currentObject;
  }

  public btnActionClick(event) {
    let i = 0; 
    if(event && event.id === this.buttons[i++].id){
      this.editionEnded = null;
      this.save();
    } else if(event && event.id === this.buttons[i++].id){
      this.editionEnded = true;
      this.save();
    } else if(event && event.id === this.buttons[i++].id){
      this.router.navigate(["./exam/list", {examId: this.currentExam? this.currentExam.id : null, search: true}]);
    }
  }

  save() {
    if(this.isValid()) {
      let toSave = this.getData();
      toSave.editionEnded = this.editionEnded ;
      this.httpService.post(toSave, `exam/result/save/${this.currentExam.id}`, true).subscribe( (result) => {
        this.msgService.log("exam-result.edit.save.succed", NotificationType.SUCCESS);
        this.router.navigate(["./exam/list", {examId: this.currentExam? this.currentExam.id : null, search: true}]);
      }, (error) => {
          this.msgService.log(error, NotificationType.ERROR);
      });
    }
  }

  displayMedicalAgent(agent: MedicalAgent) {
    return agent? agent.fullname : '';
  }


}
