import { Component, OnInit, Input, ChangeDetectorRef, SimpleChanges, EventEmitter, Output, ViewChild } from '@angular/core';
import { MedicalFolder } from 'src/app/model/admission/medical-folder';
import { NkapListComponent } from '../../base/nkap-list-component';
import { CareCoverageDoc, CareCoverage } from 'src/app/model/admission/care-coverage-doc';
import { NkapHttpService } from 'src/app/services/nkap-http.service';
import { TranslateService } from '@ngx-translate/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NkapNotificationService, NotificationType } from 'src/app/services/nkap-notification.service';
import { IFormActionBarButton, FormAction, ButtonsBarComponent } from '../../buttons-bar/buttons-bar.component';
import * as moment from 'moment';
import { ClassNameMap } from 'src/app/model/persistent';
import { UserAppService } from 'src/app/services/user-app.service';
import { Funtions } from 'src/app/model/security/funtionalities';

@Component({
  selector: 'app-patient-care-coverage-doc-list',
  templateUrl: './patient-care-coverage-doc-list.component.html',
  styleUrls: ['./patient-care-coverage-doc-list.component.css']
})
export class PatientCareCoverageDocListComponent extends NkapListComponent<CareCoverageDoc> {


  @ViewChild("btnBar")
  btnBarCmp: ButtonsBarComponent;
  
  constructor(protected _ref: ChangeDetectorRef, protected httpService: NkapHttpService<CareCoverageDoc>, 
    protected router: Router, protected route: ActivatedRoute, protected userService: UserAppService,
    protected msgService: NkapNotificationService, protected translateService: TranslateService) {
  
        super(_ref, httpService, router, route,userService, msgService, translateService);
        this.searchBaseUrl = "third-party-payer/care-coverage-doc";
        this.buttons = [
          {id: "add", value: FormAction.CREATE, icon: {type : 'add_circle_outline', color: '#0099cc'},
            text: 'care-coverage-doc.btn.new.label', disabled: false, functionalities: [Funtions.careCoverageDoc_btn_new_fct]},
          {id: "edit", value: FormAction.UPDATE, icon: {type : 'edit',color:'#ff9933'}, 
            text: 'care-coverage-doc.btn.edit.label', disabled: false, functionalities: [Funtions.careCoverageDoc_btn_edit_fct]},
         /* {id: "cancel", value: FormAction.CANCEL, icon: {type :'cancel',color:'#ff9933'},
            text: 'care-coverage-doc.btn.delete.label', disabled: false},*/

            // le role nécessaire pour ce bouton es déjà lier au role du menu pour visualiser le liste des patients
          {id: "list", value: FormAction.VIEW, icon: {type :'list',color:'#ff9933'},
            text: 'medical-folder.btn.list.label', disabled: false, functionalities: [Funtions.careCoverageDoc_btn_list_fct, Funtions.careCoverageDoc_btn_edit_fct, Funtions.careCoverageDoc_btn_new_fct]}
        ];
  }

  ngOnInit() {
    super.ngOnInit();
  }

  ngOnChanges(changes: SimpleChanges): void {
      super.ngOnChanges(changes);
  }
  
  btnActionClick(event) {
    if(event && event.id === this.buttons[0].id ){
      let doc = new CareCoverageDoc();
      this.router.navigate(['medical-folder/patient-care-coverage/edit', {folderId: this.params? this.params.folderId : null}]);
    } else if(event && event.id === this.buttons[1].id){
      if( !this.selection.selected || this.selection.selected.length != 1) {
        this.msgService.log("list.select.to.modify.error", NotificationType.ERROR);
      }else{
        this.router.navigate(['medical-folder/patient-care-coverage/edit', {number: this.selection.selected[0].number , folderId: this.params? this.params.folderId : null}]);
      }
    }
    /*else if(event && event.id === this.buttons[2].id){
      if( this.selection.selected && this.selection.selected.length == 1) {
        this.deleteSelected();
      }
    }*/
    else if(event && event.id === this.buttons[2].id){
      this.router.navigate(['medical-folder/list', {search: true}]);
    }
  }

  formatDate(date){
    try {
      return date ? moment(new Date(date)).format("LL") : "";
    } catch (e) {
      console.error("Parse date fail " , e);
    }
  }

  public launchSearch(folderId?: string | number) {
    this.criteria.folderId = folderId;
    this.selection.clear();
    this.searchClicked({});
  }


  /**
   * Call to filter the grid when the search btn is cliecked
   * @param event 
   */
  searchClicked(event) {
    this.criteria.folderId = this.params? this.params.folderId : null;
    super.searchClicked(event);
  }

  listSelectionChanged(){
    super.listSelectionChanged();
    if(this.btnBarCmp){
      const btnStatus = [
        {id: "add", disabled: false, functionalities: this.buttons[0].functionalities},
        {id: "edit", disabled: !(this.selection && this.selection.selected && this.selection.selected.length > 0), functionalities: this.buttons[1].functionalities},
        {id: "cancel", disabled: !(this.selection && this.selection.selected && this.selection.selected.length > 0), functionalities: this.buttons[2].functionalities},
      ];    
      this.btnBarCmp.setButtonsStatus(btnStatus);
    }
  }

  protected viewElement(element) {
    this.router.navigate(['medical-folder/patient-care-coverage/show', {id: element.id, folderId: this.params? this.params.folderId : null}]);
  }

  deleteSelected(){
    this.msgService.confirm("reception.delete.confirm.text").subscribe( (result) => {
      if(result === true){
          let lineSelected = this.selection.selected[0];
          this.httpService.deleteById(lineSelected.id,this.searchBaseUrl).subscribe( (val) => {
            this.msgService.log("care-coverage-doc.delete.succed", NotificationType.SUCCESS);
            this.searchClicked({});
          }, (error) => {
            if(typeof error == "string") {
              this.msgService.log(error, NotificationType.ERROR);
            } else  {
              this.msgService.log("care-coverage-doc.delete.fail", NotificationType.ERROR);
            }
          });
        }
      });
  }

}
