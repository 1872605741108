import { Component, OnInit, Input, ChangeDetectorRef, OnChanges, SimpleChanges } from '@angular/core';
import { ContactPerson } from 'src/app/model/organisation/contact-person';
import { Person } from 'src/app/model/organisation/person';
import { FormControl, Validators } from '@angular/forms';
import { TranslatePipe, TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'single-contact-person',
  templateUrl: './single-contact-person.component.html',
  styleUrls: ['./single-contact-person.component.css']
})
export class SingleContactPersonComponent implements OnInit, OnChanges {

  public translator: TranslatePipe;

  @Input() title: string;

  @Input() displayContact: ContactPerson;

  @Input() doctorContact: boolean;

  contactPerson: ContactPerson;

  nameControl = new FormControl();

  clinicControl = new FormControl();

  phoneControl = new FormControl('', [
    Validators.pattern(/[+0-9]{9}/)
  ]);

  constructor(public _ref: ChangeDetectorRef, private translate: TranslateService) {
    this.translator = new TranslatePipe(translate, _ref);
    this.contactPerson = new ContactPerson();
    this.contactPerson.person = new Person();
    this.contactPerson.person.address = undefined;
    this.doctorContact = false;
  }

  ngOnInit() {

    if (this.displayContact) {
      if(this.displayContact.person != null && this.displayContact.person != undefined){
        this.contactPerson = this.displayContact;
        this.contactPerson.toAddOrUpdate = true;
      }
    }

    this.nameControl.valueChanges.subscribe((value) => {
      //console.log("valeur entrée", value);
      if (value && value.trim() != "") {
        this.phoneControl.setValidators([Validators.required, Validators.pattern(/[+0-9]{9}/)]);
        this.phoneControl.updateValueAndValidity({ emitEvent: false }); // Vérifie la validité du composant
        this.phoneControl.markAsTouched(); // changer la valeur du touch

        if(this.doctorContact == true){
          this.clinicControl.setValidators([Validators.required]);
          this.clinicControl.updateValueAndValidity({ emitEvent: false });
          this.clinicControl.markAsTouched();
        }

      } else {
        this.phoneControl.setValidators([Validators.pattern(/[+0-9]{9}/)]);
        this.phoneControl.updateValueAndValidity();

        if(this.doctorContact == true){
          this.clinicControl.setValidators([]);
          this.clinicControl.updateValueAndValidity({ emitEvent: false });
        }
      }
    });

    this.phoneControl.valueChanges.subscribe((value) => {
      //console.log("vaaleur entrée", value);
      if (value && value.trim() != "") {
        this.nameControl.setValidators([Validators.required]);
        this.nameControl.updateValueAndValidity({ emitEvent: false });
        this.nameControl.markAsTouched();

        if(this.doctorContact == true){
          this.clinicControl.setValidators([Validators.required]);
          this.clinicControl.updateValueAndValidity({ emitEvent: false });
          this.clinicControl.markAsTouched();
        }

      } else {
        this.nameControl.setValidators([]);
        this.nameControl.updateValueAndValidity({ emitEvent: false });

        if(this.doctorContact == true){
          this.clinicControl.setValidators([]);
          this.clinicControl.updateValueAndValidity({ emitEvent: false });
        }
      }
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['displayContact'] || changes['doctorContact'] || changes['title']) {
      this.ngOnInit();
    }
  }

  phoneErrorMessage() {

    var textError1 = "";
    var textError2 = "";
    if (this.phoneControl.hasError('required')) {
      textError1 = this.translator.transform("phone.enter.error");
    }

    if (this.phoneControl.hasError('pattern')) {
      textError2 = this.translator.transform("format.phone.error");
    }

    return textError1 + " " + textError2;
  }


  PhoneErrorContact() {
    return this.nameControl.hasError('required') ? this.translator.transform("name.person.contact.error") : '';
  }

  ClinicErrorContact() {
    return this.clinicControl.hasError('required') ? this.translator.transform("clinic.doctor.contact.error") : '';
  }

  isValid() {

    if (this.contactPerson.person.name && !this.contactPerson.person.mobilePhone) {
      return false;
    }

    if (!this.contactPerson.person.name && this.contactPerson.person.mobilePhone) {
      return false;
    }

    if (this.doctorContact == true && this.contactPerson.person.name && !this.contactPerson.company) {
      return false;
    }

    if (this.phoneControl.invalid) {
      return false;
    }
    
    return true
  }

  getData() { 
    return this.contactPerson.person.name != null ? this.contactPerson : null;
  }
}
