import { Persistent, ClassNameMap } from '../persistent';
import { User } from '../security/user';
import { Prestation } from '../prestation/prestation';
import { BusinessUnit } from '../organisation/business-unit';
import { MedicalAgent } from '../organisation/medical-agent';
import { Apointment } from './apointment';
import { CareCoverageDoc, CareCoverage } from './care-coverage-doc';
import { MedicalFolder } from './medical-folder';
import { Patient } from '../organisation/patient';

export class Reception extends Persistent {

    public patientFolder?: MedicalFolder;

    public patient?: Patient;

    public date?: Date;

    public receptionAgent?: User;

    public receptionService?: BusinessUnit;

    public prescriptionCode?: string;

    public prestationsToPerformed?: PrestationsToPerformed[];

    public receptionType?: string | ReceptionType;

    public type?: string | ReceptionType;

    public apointment?: Apointment ;

    public status?: string | ReceptionStatus;

    constructor() {
        super();
        this.date = new Date();
        this.prestationsToPerformed = [];
        this.classe = ClassNameMap.Reception;
    }
}

export class PrestationsToPerformed extends Persistent {
    public prestation?: Prestation;
    public unit?: BusinessUnit;
    public agent?: MedicalAgent;
    public reception?: Reception;
    public careCoverageDoc?: CareCoverageDoc;
    public careCoverageToApply?: CareCoverage;
    constructor(prestation?: Prestation, agent?: MedicalAgent, unit?: BusinessUnit ) {
        super();
        this.classe = ClassNameMap.PrestationsToPerformed;
        this.prestation = prestation;
        this.agent = agent;
        this.unit = unit;
    }
}

export enum ReceptionStatus {
    PENDING,
    CLOSED,
    CAN_BE_PROCESS
}

export enum ReceptionType {
    CONSULTATION,
	EXAMS,
	CARE
}