import { Component, OnInit, Input, SimpleChanges, OnChanges } from '@angular/core';
import {from, Subscription, Subject} from 'rxjs';
import { FormControl, FormGroup, FormBuilder, Validators, AbstractControl, ValidationErrors } from '@angular/forms';
import { SystemInvestigation, SystemInvestigationType } from 'src/app/model/admission/medical-folder';
import { NkapHttpService } from 'src/app/services/nkap-http.service';
import { IFormActionBarButton, FormAction } from '../../../../buttons-bar/buttons-bar.component';
import { Funtions } from 'src/app/model/security/funtionalities';
import { ClassNameMap } from 'src/app/model/persistent';
import { MatDialog, MatSelectionList, MatTableDataSource } from '@angular/material';
import { SystemInvestigationTypeEditComponent } from '../../../../system-investigation-type/system-investigation-type-edit/system-investigation-type-edit.component';

@Component({
  selector: 'app-system-investigation-edit',
  templateUrl: './system-investigation-edit.component.html',
  styleUrls: ['./system-investigation-edit.component.css']
})
export class SystemInvestigationEditComponent implements OnInit, OnChanges {

  DataSubscription: Subscription;
  autocompleteValue: any;
  userDataSubject: Subject<SystemInvestigation[]> = new Subject<SystemInvestigation[]>();
  SystemInvestigationEditForm: FormGroup;
  datasToDelete: SystemInvestigation[] = [];
  datas: any[] = [];
  parameters: any;
  buttons: IFormActionBarButton[];

  displayedColumns: string[] = ['position', 'type', 'description','edit','delete'];
  dataSource: MatTableDataSource<SystemInvestigation>;

  @Input() userData: SystemInvestigation[] = [];

  constructor(private formBuilder: FormBuilder, private nkapService: NkapHttpService<any>,  public dialog: MatDialog, ) {
    this.parameters = {
      systemInvestigationUrl: 'admission/system-investigation',
      SystemInvestigationTypelUrl: 'admission/system-investigation-type',
    };

    this.buttons = [
        {id: 'add-system-investigation-type', value: FormAction.CREATE, icon: {type : 'add_circle_outline'},
    text: 'system-investigation-type.add.label', functionalities: [Funtions.consultation,Funtions.reception_btn_edit_fct, Funtions.exams,Funtions.systemInvestigation_btn_new_fct,Funtions.systemInvestigation_btn_edit_fct, Funtions.systemInvestigationType_btn_new_fct, Funtions.systemInvestigationType_btn_edit_fct]}
    ];
   }

   ngOnChanges(changes: SimpleChanges): void {
    if (changes['userData']) {
      this.emitUserData();
    }
  }


  ngOnInit() {
    this.DataSubscription = this.userDataSubject.subscribe((data) => {
      this.dataSource = new MatTableDataSource<SystemInvestigation>(data);
    });
    
    this.initForm();

    this.SystemInvestigationEditForm.get('type').valueChanges.subscribe(value => {
      console.log(value);
      if (!value || typeof value === 'string') {
        this.autocompleteValue = {};
        this.loadData(value);
      } else {
        this.autocompleteValue = value;
      }
    });
  }

  validTypeSystemInvestigation(control: AbstractControl): ValidationErrors | null {
    const value: any = control.value;
    if (!value || typeof value === 'string') {
      return {
        valid: 'false',
      };
    } else {
      return null;
    }
  }

  loadData(criteria: string) {
    this.nkapService.search({value: criteria}, this.parameters.SystemInvestigationTypelUrl).subscribe(result => {
      this.datas = result? (result.datas ? result.datas : []) : [] ;
    });
  }

  getDataForm() {
    const formValue = this.SystemInvestigationEditForm.value;
    const currentValue: SystemInvestigation = {
      classe: ClassNameMap.SystemInvestigation,
      description: formValue.description,
      systemInvestigationType: this.autocompleteValue
    };
    return currentValue;
  }

  getData() {
    return this.userData.concat(this.datasToDelete);
  }

  onSubmitForm(){
    const option: SystemInvestigation = this.getDataForm();
    console.log(option);

    if (!this.userData) {
      this.userData = [];
    }
    this.userData = this.userData.filter(val => (val.systemInvestigationType.id != option.systemInvestigationType.id));

    option.toAddOrUpdate = true;
    option.toDelete = false;
    this.userData.push(option);
    
    // remove item if is in delete datas
    if (option.id) {
      this.datasToDelete = this.datasToDelete.filter(val => val.id != option.id);
    }
    this.resetForm();
    this.emitUserData();
  }

  resetForm(item?: SystemInvestigation) {
    this.SystemInvestigationEditForm.get('type').reset(item? item.systemInvestigationType : null);
    this.SystemInvestigationEditForm.get('description').reset(item? item.description : '');
  }

  remouve(item: SystemInvestigation) {
    if (item.id) {
      item.toDelete = true;
      item.toAddOrUpdate = false;
      this.datasToDelete.push(item);
    }
    this.userData = this.userData.filter(val => val.systemInvestigationType.id !== item.systemInvestigationType.id);
    this.emitUserData();
  }

  edit(item: SystemInvestigation) {
    this.resetForm(item);
  }

  initForm(item?: SystemInvestigation) {
    this.SystemInvestigationEditForm = this.formBuilder.group({
      description: [item ? (item.description ? item.description : '') :'', [Validators.required]],
      type: [item ? (item.systemInvestigationType ? (item.systemInvestigationType.id ? item.systemInvestigationType : null) : null) : null, [Validators.required, this.validTypeSystemInvestigation]],
    });
  }

  displayOption(val: SystemInvestigationType){
    let response = "";
    if(val){
      response = val.name;
    }
    return response;
  }

  btnActionClick(event) {
    if ( event && event.id === this.buttons[0].id) {
      this.openDialog();
    }
  }

  emitUserData() {
    this.userDataSubject.next(this.userData);
  }

  openDialog() {
    const dialogRef = this.dialog.open(SystemInvestigationTypeEditComponent,  {
      width: '500px',
      height: '200px',
      disableClose: true,
      data: null
    });

    dialogRef.componentInstance.afterSaveEmitter.subscribe(res => {
      console.log(res);
      dialogRef.close();
    });

    dialogRef.componentInstance.cancleEmitter.subscribe(val => {
      dialogRef.close();
    });
  }
  

}
