import { Component, OnInit, ViewChild } from '@angular/core';
import { NotificationType, NkapNotificationService } from 'src/app/services/nkap-notification.service';
import { PartyType, Party } from 'src/app/model/organisation/party';
import { ThirdPartyPayer, ThirdPartyPayerType } from 'src/app/model/admission/care-coverage-doc';
import { PartyComponent } from 'src/app/components/party/party.component';
import { IFormActionBarButton, FormAction } from 'src/app/components/buttons-bar/buttons-bar.component';
import { NkapHttpService } from 'src/app/services/nkap-http.service';
import { Router, ActivatedRoute } from '@angular/router';
import { ClassNameMap } from 'src/app/model/persistent';
import { MedicalAgent, MedicalAgentType } from 'src/app/model/organisation/medical-agent';
import { FormGroup, FormBuilder, Validators, FormControl, AbstractControl } from '@angular/forms';
import { BusinessUnit } from 'src/app/model/organisation/business-unit';
import { NkapValidators } from 'src/app/components/base/nkap-validators';
import { Funtions } from 'src/app/model/security/funtionalities';

@Component({
  selector: 'app-doctor-edit',
  templateUrl: './doctor-edit.component.html',
  styleUrls: ['./doctor-edit.component.css']
})
export class DoctorEditComponent implements OnInit {

  protected currentObject: MedicalAgent;

  @ViewChild('partyC')
  protected partyCmp: PartyComponent;

  protected buttons: IFormActionBarButton[];

  protected party: Party;

  protected type: string;

  doctorForm: FormGroup;
  unitControl: AbstractControl;
  unitList: BusinessUnit[];

  types: any[];

  constructor(private fb: FormBuilder, private httpService: NkapHttpService<MedicalAgent>, private router: Router,
    private route: ActivatedRoute, private msgService: NkapNotificationService) {
    this.currentObject = new MedicalAgent();
    this.party = this.currentObject;
    this.type = PartyType.PERSON;
    this.buttons = [
      {id: "save", value: FormAction.CREATE, icon: {type : 'save'},
      text: 'btn.save.label', disabled: false, functionalities: [Funtions.doctor_btn_new_fct, Funtions.doctor_btn_edit_fct]},
      {id: "list", value: FormAction.VIEW, icon: {type : 'list'},
      text: 'btn.list.label', disabled: false, functionalities: [Funtions.doctor_btn_list_fct]}
    ];
    this.doctorForm = this.fb.group({
      type: [null,  [Validators.required]],
      professionTitle: [null, Validators.maxLength(255)],
      number: [null, Validators.maxLength(50)],
      localisation: [null, [NkapValidators.dataWithIdSelected]]
    });
    this.unitControl = this.doctorForm.get('localisation');
    this.unitControl.valueChanges.subscribe( (inputValue) => {
      if (typeof inputValue === 'string' ) {
        this.currentObject.localisation = null;
        this.httpService.search({value: inputValue}, 'organisation/business-unit').subscribe ( (result) => {
           this.unitList = result ? result.datas : [];
         });
       } else {
        this.currentObject.localisation = inputValue;
       }
    });
  
    this.doctorForm.patchValue(this.currentObject);
  }
 

  ngOnInit() {
    this.types = [
      {value: MedicalAgentType[MedicalAgentType.GENERALIST_DOCTOR], text: 'MedicalAgentType.' + MedicalAgentType[MedicalAgentType.GENERALIST_DOCTOR] + '.label'},
      {value: MedicalAgentType[MedicalAgentType.SPECIALIST_DOCTOR], text: 'MedicalAgentType.' + MedicalAgentType[MedicalAgentType.SPECIALIST_DOCTOR] + '.label'},
      {value: MedicalAgentType[MedicalAgentType.NURSE], text: 'MedicalAgentType.' + MedicalAgentType[MedicalAgentType.NURSE] + '.label'},
      {value: MedicalAgentType[MedicalAgentType.LABORATORY_TECHNICIAN], text: 'MedicalAgentType.' + MedicalAgentType[MedicalAgentType.LABORATORY_TECHNICIAN] + '.label'},
      {value: MedicalAgentType[MedicalAgentType.OTHERS], text: 'MedicalAgentType.' + MedicalAgentType[MedicalAgentType.OTHERS] + '.label'}
    ];

    this.route.paramMap.subscribe(params => {
      let data: {id?: string, type?: string} = (params as any).params;
      if (data.id != null) {
        this.httpService.findById(data.id, 'organisation/medical-agent',true).subscribe( (object)=>{
          this.setObjectInView(object);
        }, (errors) => {
          this.msgService.log("medical-agent.edit.get.data.error", NotificationType.ERROR);
        });
      } else if (data.type != null) {
        this.currentObject.type = data.type;
      }
    });
  }

  displayServiceFn(service?: BusinessUnit): string {
    return service ? `${service.name}` : '';
  }

  btnActionClick(event) {
    if(event && event.id === this.buttons[0].id){
      this.save();
    } else if(event && event.id === this.buttons[1].id){
      this.router.navigate(["doctor/list", {search: true}]);
    }
  }

  getObjectFromView(){
    if(this.partyCmp){
      this.currentObject = ( this.partyCmp.getData() as MedicalAgent);
      this.currentObject.toAddOrUpdate = true;
      this.currentObject.classe = ClassNameMap.MedicalAgent;
    }
    this.currentObject = Object.assign(this.currentObject,this.doctorForm.value);
    if(this.currentObject.localisation != null && typeof this.currentObject.localisation === 'string') {
      this.currentObject.localisation = undefined;
    }
  }

  setObjectInView(object: MedicalAgent) {
    if (object) {
      this.currentObject = object;
      this.party = this.currentObject;
      this.type = PartyType.PERSON;
      this.doctorForm.patchValue(this.currentObject);
    }
  }

  isValid(){
    let isValid = true;
    if(this.partyCmp){
      isValid = this.partyCmp.isValid();
    }
    if(this.partyCmp.addressCmp && this.partyCmp.addressCmp.isValid() == false) {
      isValid = false;
    }
    this.doctorForm.get('type').markAsTouched();
    if(this.doctorForm.invalid == true){
      isValid = false;
    }
    return isValid;
  }

  save() {
    this.getObjectFromView();
    if (this.isValid() === true) {
      this.httpService.save(this.currentObject, 'organisation/medical-agent', true).subscribe( (result) => {
        this.msgService.log("medical-agent.edit.save.succed", NotificationType.SUCCESS);
        this.router.navigate(['doctor/list', {search: true}]);
      }, (error) => {
        if (typeof error == 'string') {
          this.msgService.log(error, NotificationType.ERROR);
        } else {
          this.msgService.log("medical-agent.edit.save.fail", NotificationType.ERROR);
        }
      });
    } else {
      //this.msgService.log("third-party-payer.data.invalid", NotificationType.ERROR);
    }
  }
}
