import { Component, OnInit, ViewChild, ChangeDetectorRef, Input, SimpleChanges } from '@angular/core';
import { NkapListComponent } from 'src/app/components/base/nkap-list-component';
import { ButtonsBarComponent, IFormActionBarButton, FormAction } from 'src/app/components/buttons-bar/buttons-bar.component';
import { NkapHttpService } from 'src/app/services/nkap-http.service';
import { Router, ActivatedRoute } from '@angular/router';
import { UserAppService } from 'src/app/services/user-app.service';
import { TranslateService } from '@ngx-translate/core';
import * as moment from 'moment';
import { NkapNotificationService, NotificationType } from 'src/app/services/nkap-notification.service';
import { Funtions } from 'src/app/model/security/funtionalities';
import { FormControl } from '@angular/forms';
import { MedicalAgent } from 'src/app/model/organisation/medical-agent';
import { FileDownloaderComponent } from 'src/app/components/file-downloader/file-downloader.component';
import { MedicalFolder } from 'src/app/model/admission/medical-folder';
import { ClassNameMap } from 'src/app/model/persistent';
import { ModalComponentService } from 'src/app/components/base/modal/modal.component';
import { CarePrescriptionEditComponent } from 'src/app/components/prescriptions/care-prescription/care-prescription-edit/care-prescription-edit.component';
import { MedicalPrescriptionEditModalComponent } from 'src/app/components/prescriptions/medical-prescription/medical-prescription-edit-modal/medical-prescription-edit-modal.component';
import { ExamPrescriptionEditComponent } from 'src/app/components/prescriptions/exam-prescription/exam-prescription-edit/exam-prescription-edit.component';

@Component({
  selector: 'app-prescription-list',
  templateUrl: './prescription-list.component.html',
  styleUrls: ['./prescription-list.component.css']
})
export class PrescriptionListComponent extends NkapListComponent<any> {

  @ViewChild("btnBar")
  btnBarCmp: ButtonsBarComponent;

  agentSelected: MedicalAgent;
  agentControl: FormControl = new FormControl();
  agentList: MedicalAgent[];

  @Input('medicalFolder')
  patientFolder: MedicalFolder = null;

  folderSelected: MedicalFolder;
  folderControl: FormControl = new FormControl();
  folderList: MedicalFolder[];

  protected startDateCtrl: FormControl = new FormControl();

  @Input('editable')
  editable: boolean = true;

  @Input('consultationId')
  consultationSheetId: string;

  carePrescriptionCmp: CarePrescriptionEditComponent;

  examPrescriptionCmp: ExamPrescriptionEditComponent;

  /**
   * List of prescription without consultation id
   * This list must be send to the server with consultation to update it
   */
  prescriptions: any[] = [];

  /**
   * A reference on download component
   */
  @ViewChild("downloader")
  downloaderComponent: FileDownloaderComponent;
  openFileInTab: boolean = true;
  
  constructor(protected _ref: ChangeDetectorRef, protected httpService: NkapHttpService<any>, 
    public modalService: ModalComponentService,
    protected router: Router, protected route: ActivatedRoute, protected userService: UserAppService,
    protected msgService: NkapNotificationService, protected translateService: TranslateService) {
   super(_ref, httpService, router, route, userService, msgService, translateService);

   this.searchBaseUrl = "prescription";

   this.criteria.validPrescription = false;
   this.criteria.examPrescription = true;
   this.criteria.medicalPrescription = true;
   this.criteria.carePrescription = true;

   this.buttons = [
    {id: "add_care", value: FormAction.CREATE, icon: {type : 'add_circle_outline', color: '#0099cc'},
      text: 'care-prescription.btn.new.label', disabled: false, functionalities: [Funtions.carePrescription_btn_new_fct]},
    {id: "add_medical", value: FormAction.CREATE, icon: {type : 'add_circle_outline', color: '#0099cc'},
      text: 'medical-prescription.btn.new.label', disabled: false, functionalities: [Funtions.medicalPrescription_btn_new_fct]},
    {id: "add_exam", value: FormAction.CREATE, icon: {type : 'add_circle_outline', color: '#0099cc'},
      text: 'exam-prescription.btn.new.label', disabled: false, functionalities: [Funtions.examPrescription_btn_new_fct]},
    /* {id: "edit", value: FormAction.UPDATE, icon: {type : 'edit',color:'#ff9933'}, 
      text: 'prescription.btn.edit.label', disabled: false, functionalities: [Funtions.carePrescription_btn_edit_fct]},
    {id: "delete", value: FormAction.DELETE, icon: {type :'delete_forever',color:'#ff9933'},
      text: 'prescription.btn.delete.label', disabled: false, functionalities: [Funtions.carePrescription_btn_delete_fct]}, */
    {id: "print", value: FormAction.PRINT, icon: {type : 'print',color:'#ff9933'}, name: "care-prescription_print-btn",
      text: 'prescription.btn.print.label', disabled: false, functionalities: [Funtions.carePrescription_btn_print_fct]}
    /* {id: "close", value: FormAction.CLOSE, icon: {type : 'close',color:'#ff9933'},
      text: 'prescription.btn.close.label', disabled: false, functionalities: [Funtions.carePrescription_btn_close_fct]} */
  ];     
}


ngOnInit() {
  // By default we render receptions of the current day
  if(this.criteria.period == null) {
    this.criteria.period = {};
  }
  const currentDate = moment.utc(new Date()).startOf('day').toDate();// moment(moment.utc(new Date()).startOf('day').format('LL')).startOf('day').toDate();
  if(this.patientFolder == null) {
    this.criteria.period.start = currentDate;
  }
  super.ngOnInit();   

  this.agentControl.valueChanges.subscribe( (inputValue) => {
    if (typeof inputValue === 'string' ) {
      this.agentSelected = null;
      this.httpService.search({value: inputValue}, 'organisation/medical-agent').subscribe ( (result) => {
         this.agentList = result ? result.datas : [];
      });
     } else {
       this.agentSelected = inputValue;
     }
    this.criteria.medicalAgentId = this.agentSelected? this.agentSelected.id : undefined;
  });

  this.folderControl.valueChanges.subscribe( (inputValue) => {
    if (typeof inputValue === 'string' ) {
      this.folderSelected = null;
      this.httpService.search({value: inputValue}, 'admission/medical-folder').subscribe ( (result) => {
         this.folderList = result ? result.datas : [];
      });
     } else {
       this.folderSelected = inputValue;
     }
    this.criteria.folderId = this.folderSelected? this.folderSelected.id : undefined;
  });
}

ngOnChanges(changes: SimpleChanges): void {
  if(changes["patientFolder"]){
    //console.log("changes on medical folder Selected ", changes["patientFolder"], this.patientFolder);
    this.folderControl.setValue(this.patientFolder);
    this.criteria.folderId = this.patientFolder? this.patientFolder.id : undefined;
    this.searchClicked(null);
  }
}

displayAgentFn(agent?: MedicalAgent): string  {
  return agent ? `${agent.name ? agent.name : ''}` : '';
}

displayFolderFn(folder?: MedicalFolder): string  {
  return folder ? `${folder.patient.fullname ? folder.patient.fullname : ''}` : '';
}

formatStateFn(state?: Boolean): string  {
  return state == true ? this.translate.transform("prescription.list.stillValid.true.label") : 
            this.translate.transform("prescription.list.stillValid.false.label");
}

displayDetailsFn(details?: string): string  {
  return details ? `${details.replace(/\t/g, '<br/>')}` : '';
}

formatTypeFn(type?: String): string  {
  if(type == ClassNameMap.CarePrescription){
    return this.translate.transform("prescription.CarePrescription.type.label");
  }else if(type == ClassNameMap.ExamPrescription){
    return this.translate.transform("prescription.ExamPrescription.type.label");
  }else if(type == ClassNameMap.MedicalPrescription){
    return this.translate.transform("prescription.MedicalPrescription.type.label");
  }  
}

formatDate(date){
  try {
      return moment(new Date(date)).format("LL") + " [" + moment(new Date(date)).utc().format("HH:mm") + "]";
  } catch (e) {
    console.error("Parse date in reception-list fail " , e);
  }
}

btnActionClick(event) {
  let btnNumber = 0;
  if(event && event.id === this.buttons[btnNumber++].id){
    //this.router.navigate(["care-prescription/edit"]);
    this.addCarePrescription();
  } else if(event && event.id === this.buttons[btnNumber++].id){
    //this.router.navigate(["medical-prescription/edit"]);
    this.addMedicalPrescription();
  } else if(event && event.id === this.buttons[btnNumber++].id){
    //this.router.navigate(["exam-prescription/edit"]);
    this.addExamPrescription();
  } else if(event && event.id === this.buttons[btnNumber++].id){
    if( !this.selection.selected || this.selection.selected.length < 1){
      this.msgService.log("prescription.list.select.to.print.error", NotificationType.ERROR);
    }else{
      const prescriptionId = this.selection.selected[0].id;
      const objectType = this.selection.selected[0].classe;
      this.print(prescriptionId, objectType);
    }
  } else if(event && event.id === this.buttons[btnNumber++].id){
    if( !this.selection.selected || this.selection.selected.length != 1){
      this.msgService.log("prescription.list.select.to.modify.error", NotificationType.ERROR);
    }else{
      const objectType = this.selection.selected[0].classe;
      if(objectType == ClassNameMap.CarePrescription){
        this.router.navigate(["care-prescription/edit", {id: this.selection.selected[0].id}]);
      }else if(objectType == ClassNameMap.ExamPrescription){
        this.router.navigate(["exam-prescription/edit", {id: this.selection.selected[0].id}]);
      }else if(objectType == ClassNameMap.MedicalPrescription){
        this.router.navigate(["medical-prescription/edit", {id: this.selection.selected[0].id}]);
      }    
    }
  } else if(event && event.id === this.buttons[btnNumber++].id){
    if( !this.selection.selected || this.selection.selected.length < 1){
      this.msgService.log("prescription.list.select.to.delete.error", NotificationType.ERROR);
    }else{
      const objectType = this.selection.selected[0].classe;
      this.deleteSelected(objectType);
    }
  } else if(event && event.id === this.buttons[btnNumber++].id){
    if( !this.selection.selected || this.selection.selected.length < 1){
      this.msgService.log("prescription.list.select.to.close.error", NotificationType.ERROR);
    }else{
      const prescriptionId = this.selection.selected[0].id;
      const objectType = this.selection.selected[0].classe;
      this.close(prescriptionId, objectType);
    }
  }
}

close(id, objectType){
  this.msgService.confirm(this.translate.transform("prescription.close.confirm.text")).subscribe( (result) => {
    if(result === true){
        this.criteria.id=id;
        let closeUrl = 'prescription/close';

        if(objectType == ClassNameMap.CarePrescription){
          closeUrl = 'prescription/care-prescription/close';
        }else if(objectType == ClassNameMap.ExamPrescription){
          closeUrl = 'prescription/exam-prescription/close';
        }else if(objectType == ClassNameMap.MedicalPrescription){
          closeUrl = 'prescription/medical-prescription/close';
        }

        this.httpService.post(this.criteria, closeUrl).subscribe( (val) => {
          this.msgService.log("prescription.close.succeed", NotificationType.SUCCESS);
          this.searchClicked({});
        }, (error) => {
          this.msgService.log("prescription.close.fail", NotificationType.ERROR);
        });
      }
    });
}

deleteSelected(objectType){
  this.msgService.confirm(this.translate.transform("prescription.delete.confirm.text")).subscribe( (result) => {
    if(result === true){
        let prescriptionSelected = this.selection.selected[0];
        let deleteUrl = 'prescription';

        if(objectType == ClassNameMap.CarePrescription){
          deleteUrl = 'prescription/care-prescription';
        }else if(objectType == ClassNameMap.ExamPrescription){
          deleteUrl = 'prescription/exam-prescription';
        }else if(objectType == ClassNameMap.MedicalPrescription){
          deleteUrl = 'prescription/medical-prescription';
        }

        this.httpService.deleteById(prescriptionSelected.id, deleteUrl).subscribe( (val) => {
          this.msgService.log("prescription.delete.succeed", NotificationType.SUCCESS);
          this.searchClicked({});
        }, (error) => {
          this.msgService.log("prescription.delete.fail", NotificationType.ERROR);
        });
      }
    });
}

protected startDateSelected(event) {
  if(!this.criteria.period){
    this.criteria.period = {};
  }
  this.criteria.period.start = event.value;
}

protected endDateSelected(event) {
  if(!this.criteria.period){
    this.criteria.period = {};
  }
  this.criteria.period.end = event.value;
}

protected viewElement(element) {
  const objectType = element.classe;
  if(objectType == ClassNameMap.CarePrescription){
    this.router.navigate(["care-prescription/show", {id: element.id}]);
  }else if(objectType == ClassNameMap.ExamPrescription){
    this.router.navigate(["exam-prescription/show", {id: element.id}]);
  }else if(objectType == ClassNameMap.MedicalPrescription){
    this.router.navigate(["medical-prescription/show", {id: element.id}]);
  }
}

print(id, objectType) {
  this.criteria.id=id;
  this.criteria.lang= this.translateService.currentLang;
  this.downloaderComponent.criteria=this.criteria;

  let printUrl = 'prescription/print';

  if(objectType == ClassNameMap.CarePrescription){
    printUrl = 'prescription/care-prescription/print';
  }else if(objectType == ClassNameMap.ExamPrescription){
    printUrl = 'prescription/exam-prescription/print';
  }else if(objectType == ClassNameMap.MedicalPrescription){
    printUrl = 'prescription/medical-prescription/print';
  }

  this.downloaderComponent.link = printUrl;
  this.downloaderComponent.downloadFile({});
  this.downloaderComponent.event.subscribe( (whellDone) => {
    if (whellDone == false) {
      this.msgService.log("form.print.error", NotificationType.ERROR);
    }
  });
}

protected setCriteriaDataInView(cachedNavigationData: any) {
  super.setCriteriaDataInView(cachedNavigationData);
  if(cachedNavigationData != null && cachedNavigationData.agent != null) {
    this.agentControl.setValue(cachedNavigationData.agent);
  }
  if(cachedNavigationData != null && cachedNavigationData.folder != null) {
    this.folderControl.setValue(cachedNavigationData.folder);
  }
}

protected getNavigationDataToStore(): any {
  let data = super.getNavigationDataToStore();
  return Object.assign( data != null ? data : {}, {agent: this.agentControl.value, folder: this.folderControl.value});
}

listSelectionChanged(){
  super.listSelectionChanged();
  if(this.btnBarCmp){
    const btnStatus = [
      {id: "add_care", disabled: false, functionalities: this.buttons[0].functionalities},
      {id: "add_medical", disabled: false, functionalities: this.buttons[1].functionalities},
      {id: "add_exam", disabled: false, functionalities: this.buttons[2].functionalities},
     /*  {id: "edit", disabled: !(this.selection && this.selection.selected && this.selection.selected.length > 0 && this.selection.selected[0].stillValid), 
        functionalities: this.buttons[3].functionalities},
      {id: "delete", disabled: !(this.selection && this.selection.selected && this.selection.selected.length > 0), functionalities: this.buttons[4].functionalities}, */
      {id: "print", disabled: !(this.selection && this.selection.selected && this.selection.selected.length > 0), functionalities: this.buttons[3].functionalities},
      /* {id: "close", disabled: !(this.selection && this.selection.selected && this.selection.selected.length > 0 && this.selection.selected[0].stillValid), 
        functionalities: this.buttons[6].functionalities}, */
    ];    
    this.btnBarCmp.setButtonsStatus(btnStatus);
  }
}

  // Open modal to add a care prescription
  addCarePrescription(){
    const dialogRef = this.modalService.openModal(CarePrescriptionEditComponent,
      "care-prescription.edit.form.title", 
      {buttonBar:{functionality: "partner.third-party-payer", buttons: [
        {id: "ok", name: "app-modal_save_btn", value: FormAction.CREATE, icon: {type : 'check_circle_outline'}, 
        text: 'btn.save.label', disabled: false, functionalities: ['btn.save.label']},
        {id: "cancel", name: "app-modal_cancel_btn", value: FormAction.CANCEL, icon: {type : 'cancel'},
          text: 'btn.cancel.label', disabled: false, functionalities: ['btn.cancel.label']},
       ]}},
      {// Modal configuration: size of the modal, and data passed to the Modal
        width: '70%',
        position:{left:"10%", right:"10%"},
        data: { medicalFolder: this.patientFolder}
    });

    dialogRef.afterOpened().subscribe(result => {
      console.log('The dialog was opened', result);
      this.carePrescriptionCmp = dialogRef.componentInstance.componentRef.instance;
    });

    dialogRef.beforeClosed().subscribe(result => {
      console.log('The dialog will be close', result);
      let validForm = this.carePrescriptionCmp.isFormValid(result.value);
      if(validForm == true){
        if(result && ((result.btn != null && result.btn.id == "ok") || !result.btn) && result.value){
          if(this.consultationSheetId != null) {
            result.value.consultation = {id: this.consultationSheetId, classe: ClassNameMap.ConsultationSheet};
          }
          this.carePrescriptionCmp.save(result.value); 
        }
      }else{
        dialogRef.disableClose = true;
      }
    });
    
    dialogRef.afterClosed().subscribe(result => {
        console.log('The care prescription dialog was closed', result);
        if(result != null && result.value != null && this.consultationSheetId == null) {
          const prescription = result.value;
          this.prescriptions.push({classe: prescription.classe, id: prescription.id});
        }
        this.searchClicked(null);
    });
    
  }

  // Open modal to add a exam prescription
  addExamPrescription(){
    const dialogRef = this.modalService.openModal(ExamPrescriptionEditComponent,
      "exam-prescription.edit.form.title", 
      {buttonBar:{functionality: "partner.third-party-payer", buttons: [
        {id: "cancel", name: "app-modal_cancel_btn", value: FormAction.CANCEL, icon: {type : 'cancel'},
          text: 'btn.cancel.label', disabled: false, functionalities: ['btn.cancel.label']},
        {id: "ok", name: "app-modal_save_btn", value: FormAction.CREATE, icon: {type : 'check_circle_outline'}, 
          text: 'btn.save.label', disabled: false, functionalities: ['btn.save.label']}
      ]}},
      {// Modal configuration: size of the modal, and data passed to the Modal
        width: '70%',
        position:{left:"10%", right:"10%"},
        data: { medicalFolder: this.patientFolder}
    });

    dialogRef.afterOpened().subscribe(result => {
      console.log('The dialog was opened', result);
      this.examPrescriptionCmp = dialogRef.componentInstance.componentRef.instance;
    });
    
    dialogRef.beforeClosed().subscribe(result => {
        console.log('The dialog will be close', result);
        let validForm = this.examPrescriptionCmp.isValid(result.value);
      if(validForm == true){
        if(result && ((result.btn != null && result.btn.id == "ok") || !result.btn) && result.value){
          if(this.consultationSheetId != null) {
            result.value.consultation = {id: this.consultationSheetId, classe: ClassNameMap.ConsultationSheet};
          }
          this.examPrescriptionCmp.save(result.value);
        }
      }else{
        dialogRef.disableClose = true;
      }
    });

    dialogRef.afterClosed().subscribe(result => {
        console.log('The exam prescription dialog was closed', result);
        if(result != null && result.value != null && this.consultationSheetId == null) {
          const prescription = result.value;
          this.prescriptions.push({classe: prescription.classe, id: prescription.id});
        }
        this.searchClicked(null);
    });
  }

  // Open modal to add a medical prescription
  addMedicalPrescription(){
    const dialogRef = this.modalService.openModal(MedicalPrescriptionEditModalComponent,
      "medical-prescription.edit.form.title", 
      {buttonBar: undefined},
      {// Modal configuration: size of the modal, and data passed to the Modal
        width: '70%',
        position:{left:"10%", right:"10%"},
        data: { medicalFolder: this.patientFolder ,
           consultation: {id: this.consultationSheetId, classe: ClassNameMap.ConsultationSheet}}
    });
          
    dialogRef.afterClosed().subscribe(result => {
        console.log('The medical prescription dialog was closed', result);
        if(result != null && result.value != null && this.consultationSheetId == null) {
          const prescription = result.value;
          this.prescriptions.push({classe: prescription.classe, id: prescription.id});
        }
        this.searchClicked(null);
    });
  }

}
