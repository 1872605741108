import { Injectable, EventEmitter } from '@angular/core';
import { MatSnackBar, MatDialog, MAT_DIALOG_DATA, MatDialogRef, MatSnackBarConfig, MatSnackBarRef } from '@angular/material';

import {Component, Inject} from '@angular/core';
import {MAT_SNACK_BAR_DATA} from '@angular/material';
import { TranslateService } from '@ngx-translate/core';
import { Observable } from 'rxjs';
import { ModalComponentService, IModalComponent, IShowModalComponent } from '../components/base/modal/modal.component';

@Injectable({
  providedIn: 'root'
})
export class NkapNotificationService {

  public newMessage: EventEmitter<any> = new EventEmitter();

  public showSpiner: EventEmitter<boolean> = new EventEmitter<boolean>();


  constructor(private _snackBar: MatSnackBar , public dialog: MatDialog, 
    public modalService: ModalComponentService,
    private translate: TranslateService) { }

  log(message: string | string[], messageType?: NotificationType) {
    console.log(`${ NotificationType[messageType]} : ${message}`);
    MatSnackBarConfig
    this._snackBar.openFromComponent( 
     SnackBarMessageComponent,{
      panelClass: NotificationTypeBgClassMapping[NotificationType[messageType]],
      data: {message}
    }
    );
    /* this._snackBar.open( 
      this.translate.instant(message), 'x',
      {
        panelClass: NotificationTypeBgClassMapping[NotificationType[messageType]],
        data: {message}
      }
    ); */
  }  

  /*confirm(message: string, title?: string, config?: any): Observable<boolean> {
    console.log(`${ title } : ${message}`);
    return new Observable( (observer) => {
      let dialogConfig = config ? config : {
          width: '45%',
          position:{left:"10%", right:"10%"},
        disableClose: true
      }
      dialogConfig.data = {title: title ? title : "notification.service.confirm.title", message: message};
      const dialogRef = this.modalService.openModal(ConfirmDialogComponent,
          undefined,undefined,dialogConfig);
      dialogRef.afterClosed().subscribe(result => {
          console.log('The dialog was closed', result);
          observer.next(result === 'ok' ? true : false);
          observer.complete();
      });
    });
  }*/  

  confirm(messages: any, title?: string, config?: any): Observable<boolean> {
    console.log(`${ title } : ${messages}`);
    return new Observable( (observer) => {
      let dialogConfig = config ? config : {
          width: '45%',
          position:{left:"10%", right:"10%"},
        disableClose: true
      }
      dialogConfig.data = {title: title ? title : "notification.service.confirm.title", messages: messages};
      const dialogRef = this.modalService.openModal(ConfirmDialogComponent,
          undefined,undefined,dialogConfig);
      dialogRef.afterClosed().subscribe(result => {
          console.log('The dialog was closed', result);
          observer.next(result === 'ok' ? true : false);
          observer.complete();
      });
    });
  }  
}

@Component({
  selector: 'nkap-snack-bar',
  template: `<div class="row">
              <div class="col-xs-9 col-sm-9 col-md-9 col-lg-9">
                <p *ngFor="let msg of messages" class="message_locator"
                 innerHTML="{{msg | translate}}"></p> 
              </div> 
              <div class="col-xs-1 col-sm-1 col-md-1 col-lg-1">
                <button  class="snack-close-btn" (click)="closeSnackBar($event)" mat-button><mat-icon>close</mat-icon></button>
              </div>
             </div>`,
   styles: [` .snack-close-btn {
      width:15px!important;
   }`]
})
export class SnackBarMessageComponent {
  protected messages : string[];
  constructor(public snackRef: MatSnackBarRef<SnackBarMessageComponent>,
    @Inject(MAT_SNACK_BAR_DATA) public data: {message: any}) { 
    if(data.message) {
      let type = typeof data.message;
      this.messages = (type === 'string')? [data.message] :  data.message;
      // console.log(" messages ", type, this.messages);
    }
  }

  closeSnackBar(event) {
    // console.log("closeSnackBar");
    this.snackRef.dismiss();
  }
}

@Component({
  selector: 'confirm-dialog',
  template: `
  <div class="warning-header">
    <div mat-dialog-title >
        <mat-icon class="text-warning icon" >report_problem</mat-icon> 
        {{ title | translate}}
    </div >
  </div >
  <div mat-dialog-content>
      <div class="confirm-text">
          <p *ngFor="let msg of messages" innerHTML="{{msg | translate}}"></p>          
      </div>  
  </div>
  <div  mat-dialog-actions class="d-flex justify-content-center">
          <div class="float-right">            
            <button mat-stroked-button [mat-dialog-close]="'ok'" class="text-light bg-secondary mat-button confirm-dialog-ok-btn_locator">
                <mat-icon >check</mat-icon> 
                {{"confirm-dialog.ok.btn.label" | translate}}
            </button>
            <button mat-stroked-button (click)="onNoClick()" cdkFocusInitial class="text-light bg-secondary mat-button confirm-dialog-cancel-btn_locator">
                <mat-icon >cancel</mat-icon> 
                {{"confirm-dialog.cancel.btn.label" | translate}}
            </button>
          </div>
  </div>
    `,
    styles : [`
    .confirm-text{
     /* min-height: 160px;*/
      font-size: 18px;
      margin-top: 6px;
      margin-bottom: 6px;
    }
    `]
})
export class ConfirmDialogComponent extends IShowModalComponent{

  protected title: string;

  protected messages : string[];

  constructor(
    public dialogRef: MatDialogRef<ConfirmDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public modalData: any) {
      super();
      if(modalData.data) {
        this.title = modalData.data.title;
        let type = typeof modalData.data.messages;
        this.messages = (type == 'string')? [ modalData.data.messages] :  modalData.data.messages;
        //console.log(" messages ", type, this.messages);
      }
    }

  onNoClick(): void {
    this.dialogRef.close();
  }
}

@Component({
  selector: 'app-progress-bar',
  template: ``,
})
export class AppProgressBarComponent {
  constructor() { }
}
const NotificationTypeBgClassMapping = {
  'ERROR' : 'bg-danger',
  'INFO' : 'bg-info',
  'WARNING' : 'bg-warning',
  'SUCCESS' : 'bg-info'
}
export enum NotificationType {
  ERROR,
  INFO,
  WARNING,
  SUCCESS
}
