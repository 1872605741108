import { Component, OnInit, Input, Output, EventEmitter, OnChanges, SimpleChanges } from '@angular/core';
import { FormControl, Validators, Validator, ValidatorFn } from '@angular/forms';
import { SearchCriteria } from 'src/app/model/data-page';
import { Observable } from 'rxjs';
import { NkapHttpService } from 'src/app/services/nkap-http.service';
import { map } from 'rxjs/operators';

@Component({
  selector: 'app-nkap-autocomplete',
  templateUrl: './nkap-autocomplete.component.html',
  styleUrls: ['./nkap-autocomplete.component.css']
})
export class NkapAutocompleteComponent implements OnInit, OnChanges {
  
  @Output('change')
  protected change: EventEmitter<any> = new EventEmitter();

  @Output('new')
  protected new: EventEmitter<any> = new EventEmitter();
  
  @Input('options')
  protected options: any[] = [];

  @Input('value')
  protected value: any = null;

  @Input('displayValue')
  protected displayValue: Function = null;

  @Input('maxlength')
  protected maxlength: number = 255;

  @Input('placeholder')
  protected placeholder: string = "";

  @Input('dataSourceFn')
  protected dataSourceFn: Function = null;

  @Input('addNewFn')
  protected addNewFn: Function = null;

  @Input('label')
  protected labelField = "";

  @Input('search')
  protected searchParams: {criteria?: SearchCriteria | any, url: string, resultField?: string} = {url: null};
  @Input('search-criteria')
  protected criteria: SearchCriteria | any = {};

  protected optionControl: FormControl = new FormControl();

  protected elements: Observable<any[]>;

  @Input('required')
  protected required = false;

  constructor(private httpService: NkapHttpService<any>) { }

  ngOnInit() {
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['options'] || changes['searchParams']){
      if(this.searchParams != null){
        this.criteria = this.searchParams.criteria;
      }
      this.setElements();
    }
    if (changes['value']) {
      this.optionControl.setValue(this.value);
    }
  }


  protected setElements() {
    this.optionControl.valueChanges.subscribe( (value) => {
      if (!value || typeof value === 'string') {
        this.elements = this.filterElements(value);
      } else {
        this.value = value;
        this.change.emit(value);
      }
    });
  }

  protected filterElements(value?: string): Observable<any[]> {
    return new Observable( (observer) => {
      if (this.options && this.options.length > 0) {
        const result = this.options.filter( (opt) => {
          let str = "" + this.displayValueCall(opt);
          return str.toLowerCase().indexOf(( "" + value).toLowerCase()) != -1;
        });
        observer.next(result);
        observer.complete();
      } else if (this.searchParams && this.searchParams.url) {
        this.httpService.post(Object.assign(this.criteria || {}, { value: value }), this.searchParams.url ).subscribe( (results) => {
          const result = this.searchParams.resultField ? results[this.searchParams.resultField] : results;
          observer.next(result);
          observer.complete();
        });
      } else if( this.dataSourceFn) {
        this.dataSourceFn(observer);
      }
    });
  }

  protected buildValidators(): ValidatorFn[] {
    let validators = [];
    if(this.required){
      validators.push(Validators.required);
    }
    if(this.maxlength != null){
      validators.push(Validators.maxLength(this.maxlength));
    }
    return validators.length > 0 ? validators : undefined;
  }

  protected addNew(event) {
    if (this.addNewFn) {
      let newValue = this.addNewFn(this.optionControl.value);
      this.elements = new Observable( (observer) => {
        this.elements.pipe(map(list => {
          list.push(newValue);
          observer.next(list);
          observer.complete();
        }));
      });
    }
  }

  protected displayValueCall(value) {
    return value ? (this.labelField ? value[this.labelField] : (this.displayValue ? this.displayValue(value) : value)) : '';
  }
}
