import { Component, OnInit, ViewChild, ChangeDetectorRef, AfterViewInit, OnChanges, SimpleChanges } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { Observable } from 'rxjs';
import { Prestation } from 'src/app/model/prestation/prestation';
import { NkapHttpService } from 'src/app/services/nkap-http.service';
import { FormControl, Validators } from '@angular/forms';
import { Reception, PrestationsToPerformed, ReceptionType } from 'src/app/model/admission/reception';
import { MedicalAgent, MedicalAgentType } from 'src/app/model/organisation/medical-agent';
import { TranslateService, TranslatePipe } from '@ngx-translate/core';
import { UserAppService } from 'src/app/services/user-app.service';
import { MatCardActions, MatInput, MatDialog, MatRadioGroup } from '@angular/material';
import { BusinessUnit } from 'src/app/model/organisation/business-unit';
import { Patient } from 'src/app/model/organisation/patient';
import { MedicalFolder } from 'src/app/model/admission/medical-folder';
import { Party } from 'src/app/model/organisation/party';
import { ClassNameMap } from 'src/app/model/persistent';
import { Apointment } from 'src/app/model/admission/apointment';
import { FormAction } from '../../buttons-bar/buttons-bar.component';
import { NkapNotificationService, NotificationType } from 'src/app/services/nkap-notification.service';
import { MedicalFolderSearchDialogComponent } from '../../medical-folder/medical-folder-search-dialog/medical-folder-search-dialog.component';
import * as moment from 'moment';
import { GenderType, Person } from 'src/app/model/organisation/person';
import { CareCoverageDoc } from 'src/app/model/admission/care-coverage-doc';
import { ModalComponentService } from '../../base/modal/modal.component';
import { MedicalFolderGridComponent } from '../../medical-folder/medical-folder-grid/medical-folder-grid.component';
import { BlockScrollStrategy } from '@angular/cdk/overlay/typings/scroll';
import { Prescription, PrescriptionItem } from 'src/app/model/prescriptions/prescription';


let self;
@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent implements OnInit , AfterViewInit, OnChanges{

  protected cards: CardData[] = [];

  protected card: CardData;
  cardActionBtnStatus: boolean;
  cardSaveBtnStatus: boolean = false;

  protected mostAskedConsultation: Prestation[];

  myControl = new FormControl();
  currentConsultationSelected: Prestation;
  radioSelected: Prestation;
  options: any[];
  radioClicked: boolean = false;

  currrentObject: Reception;
  receptionType: string;

  medicalAgentSelected: MedicalAgent;
  medicalAgentControl = new FormControl();
  medicalAgentList: MedicalAgent[];
  @ViewChild('medicalAgentInput')
  medicalAgentInputCmp: MatInput;

  currentPatientSelected: Patient;
  patientList: MedicalAgent[];
  @ViewChild('patientInput')
  patientInputCmp: MatInput;
  medicalFolderSelected: MedicalFolder;

  prescriptionSelected: Prescription;

  serviceSelected: BusinessUnit;
  serviceControl = new FormControl();
  serviceList: BusinessUnit[];
  @ViewChild('serviceInput')
  serviceInputCmp: MatInput;

  currentExamsSelected: Prestation[];
  lastPrescriptionExams: Prestation[] = [];

  prestationsWithPrices?: Prestation[];
  prestationsSelected?: Prestation[];

  loadPriceProcessLaunch: boolean = false;

  protected lastReception: Reception; // the last time the patient was received

  private translator: TranslatePipe;

  lastParams: any;

  protected FEMALE = GenderType[GenderType.FEMALE];
  
  protected toDeletePrestationsToPerformed: PrestationsToPerformed[];

  protected insuranceToApply: CareCoverageDoc;

  /**
   * To manage how price appear
   */
  priceChecked: boolean = true;
  priceDisabled: boolean = false;

  constructor(private router: Router, private route: ActivatedRoute,
              public dialog: MatDialog,
              public modalService: ModalComponentService,
              private msgService: NkapNotificationService,
              private appService: UserAppService, private _ref: ChangeDetectorRef,
              private httpService: NkapHttpService<any>, private translateService: TranslateService ) {
      self = this;
      this.translator = new TranslatePipe(translateService, _ref);
      this.currrentObject = new Reception();
  }

  ngOnInit() {
    this.initCardsData();
   
    this.route.paramMap.subscribe(params => {
      let param = (params as any).params;
      console.log(" route ", param, this.lastParams);
      if (param.id != null) {// if an id is set we retrieve the reception by his id
       this.httpService.findById(param.id, 'admission/reception', true).subscribe( (reception)=>{
          this.setCurrentReception(reception, param);
       });
      } else {// if not we look at the reception inside the appService
       /* console.log("reset ?", ((this.lastParams && this.lastParams.id != null && param && !param.id) 
        || (this.lastParams && param && this.lastParams.type != param.type)));*/
        if((this.lastParams && this.lastParams.id != null && param && !param.id) 
        || (this.lastParams && param && this.lastParams.type != param.type)){
          this.appService.setReception(null);
          this.setCurrentReception(new Reception(), param);
        } else {
          this.setCurrentReception(this.appService.getReception(), param);
        }
      }
      this.lastParams = Object.assign({}, param);
      
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.medicalFolderSelected) {
      if (this.medicalFolderSelected != null) {
        // Do your Stuff
      } else {
        this.ordonanceSelected(null); 
      }
    }
  }

  /**
   * Init the view with a reception
   */
  setCurrentReception(reception: Reception, param: {type?: any, id?: string}) {
      let params = Object.assign({}, param);
      this.currrentObject = reception? reception : new Reception();
      this.insuranceToApply = null;
      if (reception && reception.prestationsToPerformed && reception.prestationsToPerformed.length > 0) {
        if (reception.type == ReceptionType[ReceptionType.CONSULTATION]/*reception.prestationsToPerformed.length == 1 && reception.prestationsToPerformed[0].agent != null
          && reception.prestationsToPerformed[0].agent.id != null*/) {
          this.medicalAgentSelected = this.currrentObject.prestationsToPerformed[0].agent ;
          this.medicalAgentControl.patchValue(this.medicalAgentSelected);
          this.serviceSelected = this.currrentObject.prestationsToPerformed[0].unit ;
          this.serviceControl.patchValue(this.serviceSelected);
          this.currentConsultationSelected = this.currrentObject.prestationsToPerformed[0].prestation;
          this.myControl.patchValue(this.currentConsultationSelected);
          this.insuranceToApply = this.currrentObject.prestationsToPerformed[0].careCoverageDoc;
          params.type = this.medicalAgentSelected && this.medicalAgentSelected.id != null ?
          ReceptionType[ReceptionType.CONSULTATION] : ReceptionType[ReceptionType.EXAMS];
        } else if (reception.type == ReceptionType[ReceptionType.EXAMS]){
          let exams = [];
          reception.prestationsToPerformed.forEach( (ex) => {
            exams.push(ex.prestation);
          });
          this.insuranceToApply = this.currrentObject.prestationsToPerformed[0].careCoverageDoc;
          this.currentExamsSelected = exams;
          params.type = ReceptionType[ReceptionType.EXAMS];
        } else if (reception.type == ReceptionType[ReceptionType.CARE]){
          let treatments = [];
          reception.prestationsToPerformed.forEach( (ex) => {
            treatments.push(ex.prestation);
          });
          this.insuranceToApply = this.currrentObject.prestationsToPerformed[0].careCoverageDoc;
          this.currentExamsSelected = treatments;
          params.type = ReceptionType[ReceptionType.CARE];
        }
      } else {
        this.currrentObject = new Reception();
        this.currrentObject.date = new Date();
        this.currrentObject.receptionAgent = this.appService.getUser();
        this.currrentObject.receptionService = this.appService.getService();
        this.medicalAgentSelected = null ;
        this.serviceSelected = null ;
        this.currentConsultationSelected = null;
        this.currentExamsSelected = [];
        this.medicalFolderSelected = null;
        this.currentPatientSelected = null;
        this.prestationsWithPrices = null;
        this.lastReception = null;
        this.myControl.patchValue(this.currentConsultationSelected);
        this.serviceControl.patchValue(this.serviceSelected);
        this.medicalAgentControl.patchValue(this.medicalAgentSelected);
      }
      if (this.currrentObject.patientFolder == null) {
         this.currrentObject.patientFolder = this.medicalFolderSelected;
      } else {
        this.medicalFolderSelected = this.currrentObject.patientFolder;
      }
      this.patientSelected(this.medicalFolderSelected);
      this.cardActionBtnStatus = false;
      this.cardSaveBtnStatus = false;
      if (params && params.type === ReceptionType[ReceptionType.CONSULTATION] ){
        this.loadConsultationData();
      } else if (params && params.type === ReceptionType[ReceptionType.EXAMS] ){
        this.loadExamData();
      } else if (params && params.type === ReceptionType[ReceptionType.CARE] ){
        this.loadCareData();
      }
      this.getPrestationsPrices();
  }

  ngAfterViewInit() {
    
  }

    // set the curent prescription selected
    protected ordonanceSelected(presc: Prescription) {
      if(presc) {
        this.prescriptionSelected = presc;
        this.currrentObject.prescriptionCode = presc.prescriptionCode;

        let exams = [];
        this.prescriptionSelected.prescriptionItems.forEach( (ex) => {
          let prestationItem = new Prestation();
          prestationItem.id = (ex as PrescriptionItem).prestation.id;
          prestationItem.code = (ex as PrescriptionItem).prestation.code;
          prestationItem.name = (ex as PrescriptionItem).prestation.name;
          exams.push(prestationItem);
        });
        this.lastPrescriptionExams = exams;
        this.currentExamsSelected = exams;
        let medicalFolder = (presc as any).patient? 
        {classe: ClassNameMap.MedicalFolder, 
          id:(presc as any).patient.medicalFolderId, 
          number: (presc as any).patient.medicalFolderNumber, 
          patient:(presc as any).patient} : null;
        this.patientSelected(medicalFolder);

      } else {
        let prestationsToNotRemove = [];
        this.currrentObject.prestationsToPerformed.forEach( (prest) => {
        let foundIndex = this.lastPrescriptionExams.findIndex((elm) => elm.id == prest.prestation.id);
          if(foundIndex == -1) {
            prestationsToNotRemove.push(prest.prestation);
          }
        });
        this.currentExamsSelected = [].concat(prestationsToNotRemove);
        this.prescriptionSelected = null;
        this.currrentObject.prescriptionCode = null;
        this.patientSelected(null); 
      }
    }

  // set the curent patient/medical folder selected
  protected patientSelected(folder: MedicalFolder) {
    if(folder) {
      this.medicalFolderSelected = folder;
      this.currrentObject.patientFolder = folder;
      this.currentPatientSelected = folder.patient;
      this.currentPatientSelected.folderNumber = folder.number;
      this.getLastDoctor();
      this.getLastReception();
    } else {
      this.medicalFolderSelected = null;
      this.currentPatientSelected = null;
      this.currrentObject.patientFolder = null;
      this.lastReception = null;
    }
    this.updateActionBtnStatus();
  }

  /**
   * Get the last doctor who made a consultation of the patient
   */
  protected getLastDoctor(){
    if(this.currrentObject.patientFolder && this.currentConsultationSelected){
        this.httpService.post({folderId: this.currrentObject.patientFolder.id, prestationId: this.currentConsultationSelected.id},
          "admission/prestation/last-agent").subscribe( (res) => {
            if(res && !this.medicalAgentSelected) {
              this.medicalAgentControl.setValue(res);
            }
          });
    }
  }

  /**
   * Get the last reception of the patient selected
   */
  protected getLastReception(){
    this.lastReception = null;
    // We render last reception only in creation mode
    if(this.currentPatientSelected && ( !this.currrentObject || this.currrentObject.id == null) ){      
        this.httpService.post({patientId: this.currentPatientSelected.id},
          "admission/reception/last").subscribe( (res) => {
            if(res && res.patientFolder && this.currentPatientSelected && res.patientFolder.number == this.currentPatientSelected.folderNumber) {
              this.lastReception = res;
            }
          });
    }
  }

  /**
   * Initialize the view for consultation
   */
  private loadConsultationData() {
    this.card = this.cards[0];    
    this.receptionType = ReceptionType[ReceptionType.CONSULTATION];
    this.httpService.get('prestation/most-asked/consultation/list').subscribe(
      (result) => {
        this.mostAskedConsultation = result;
      }
    );

    this.myControl = new FormControl(this.currentConsultationSelected);
    this.myControl.valueChanges.subscribe( (inputValue) => {
      if (!inputValue || typeof inputValue === 'string' ) {
        this.options = [];
        this.httpService.search({value: inputValue}, 'prestation/consultation').subscribe ( (result) => {
           let optionsList = result ? result.datas : [];
           let newList = [];
           if(this.mostAskedConsultation != null && this.mostAskedConsultation.length > 0){
            optionsList.forEach( (elm)=>{
              if(this.mostAskedConsultation.findIndex( (e)=> e.id === elm.id) === -1){
                newList.push(elm);
              }
            });
           }
           this.options = newList.length > 0 ? newList : optionsList;
         });
       } else {
         this.consultationSelected(inputValue, true);
       }
    });

    this.medicalAgentControl = new FormControl(this.medicalAgentSelected, [Validators.required]);
    this.medicalAgentControl.valueChanges.subscribe( (inputValue) => {
      if (inputValue == null || typeof inputValue === 'string' ) {
        this.medicalAgentSelected = null;
        let criteria: any = {value: inputValue,types:[MedicalAgentType[MedicalAgentType.GENERALIST_DOCTOR] ,MedicalAgentType[MedicalAgentType.SPECIALIST_DOCTOR]]};
        if (this.card.value) {// consultation selected
          criteria.type = this.card.value.abilitedAgentType;
          criteria.prestation = this.card.value;
        }
        this.httpService.search(criteria, 'organisation/medical-agent').subscribe ( (result) => {
           this.medicalAgentList = result ? result.datas : [];
        });
       } else {
         this.medicalAgentSelected = inputValue;
         this.updateConsultationPrestationsToPerformed();
         this.httpService.get(`admission/count/this-day/apointment/by-medical-agent-id/${this.medicalAgentSelected.id}`)
         .subscribe( (numberOfApointment) => {
          this.medicalAgentSelected = Object.assign(this.medicalAgentSelected, {numberOfApointment: numberOfApointment.value});
        });
       }
    });

    this.serviceControl = new FormControl(this.serviceSelected);
    this.serviceControl.valueChanges.subscribe( (inputValue) => {
      if (typeof inputValue === 'string' ) {
        this.serviceSelected = null;
        this.httpService.search({value: inputValue}, 'organisation/business-unit').subscribe ( (result) => {
           this.serviceList = result ? result.datas : [];
         });
       } else {
         this.serviceSelected = inputValue;
         this.updateConsultationPrestationsToPerformed();
       }
    });
    let prestationsToPerformed = null;
    if (this.currrentObject.prestationsToPerformed) {
      prestationsToPerformed = [].concat(this.currrentObject.prestationsToPerformed);
    }
    //this.prestationsWithPrices = prestationsToPerformed;
    this.consultationSelected(this.currentConsultationSelected, true);
  }

  /**
   * Set the selected consultation
   */
  protected consultationSelected(value, resetRadioGroup?: boolean){
    this.card.value = value;
    this.currentConsultationSelected = value;
    this.currrentObject.prestationsToPerformed = this.currrentObject.prestationsToPerformed ?
                                                 this.currrentObject.prestationsToPerformed : [];
    if (value && value.id ) {
      this.getLastDoctor();
     // console.log('consultationSelected', value);
      // set service
      if (value.abililitedServiceId != null) {
        this.serviceSelected = {id: value.abililitedServiceId , name: value.abililitedServiceName, classe: ClassNameMap.BusinessUnit};
        this.serviceControl.setValue(this.serviceSelected);
      }
        let list = [value];
        this.httpService.get(`prestation/assortment/by-id/${value.id}`).subscribe( (result)=>{
          
          if (result && result.datas && result.datas.length > 0) {
            result.datas.forEach( (elmt) => {
              list.push(elmt.prestation);
            });
          }
         // console.log("consultationSelected ", list);
          this.prestationsWithPrices = [].concat(list);
          this.updateConsultationPrestationsToPerformed();
          this.getPrestationsPrices();
        });
        this.prestationsWithPrices = [].concat(list);
        this.updateConsultationPrestationsToPerformed();
        this.getPrestationsPrices();
    } else {
      //this.prestationsWithPrices = [total as any];
    }
    if (resetRadioGroup && resetRadioGroup === true) {
      this.radioSelected = null;
    } else { // we check if radiobox are selected and autocomplete have value
      //console.log(" this.radioSelected ", this.radioSelected);
      this.myControl.setValue(null);
    }
    
    this.updateActionBtnStatus();
  }

  updateConsultationPrestationsToPerformed() {
    if (this.currrentObject.prestationsToPerformed && this.currrentObject.prestationsToPerformed.length == 1){
      console.log(" step 1 ", this.currentConsultationSelected);
      this.currrentObject.prestationsToPerformed[0].agent = this.medicalAgentSelected;
      this.currrentObject.prestationsToPerformed[0].prestation = this.currentConsultationSelected;
      this.currrentObject.prestationsToPerformed[0].unit = this.serviceSelected;
      this.currrentObject.prestationsToPerformed[0].toAddOrUpdate = true;
    } else {
      console.log(" step 2 ", this.currentConsultationSelected);
      this.currrentObject.prestationsToPerformed = [
        new PrestationsToPerformed(this.currentConsultationSelected, this.medicalAgentSelected, this.serviceSelected)
      ];
    }
    this.updateActionBtnStatus();
  }
  /**
   * Get prestation prices on server
   */
  getPrestationsPrices() {
    if(this.priceChecked == true && this.prestationsWithPrices){
      let ids = [];
      this.prestationsWithPrices.forEach( (prest) => {
        if(prest.id != null){ids.push(prest.id)};
      });
      this.getPrestationsWithPrices(ids);
    }
  }

  // Build array that will show prestations on details zone
  protected getPrestationsWithPrices(ids: string[]){
    this.loadPriceProcessLaunch = true;
    this.httpService.post(ids, `prestation/with-prices`).subscribe( (prestations)=>{
      this.loadPriceProcessLaunch = false;
      this.buildPrestationsWithPrices(prestations);
    },(error)=>{
      this.loadPriceProcessLaunch = false;
    });
  }


  protected buildPrestationsWithPrices(prestations: any[]) {
    if(prestations != null){
      const total = {
        name: this.translator.transform('reception.home.consultation.details.total.label'),
        price: {value: 0, currency: ""},
        priceStr: '',
        total: true
      };
      prestations.forEach((val:any) => {
        total.price.value += val.price != null ? val.price.value : 0;
        total.price.currency = val.price != null ? val.price.currency : '';
        val.priceStr = val.price != null ? val.price.value + ' ' : (val.priceRetrieved == false ? '?' : 0) + ' ';
        val.priceStr += val.price != null ? val.price.currency : '';
      });
      total.priceStr = total.price.value + ' ' + total.price.currency ;
      this.prestationsWithPrices = prestations.concat([total]);
    }
  }
  /**
   * Initialize the view for exams
   */
  private loadExamData() {
    this.card = this.cards[1];
    this.receptionType = ReceptionType[ReceptionType.EXAMS];
   
    this.prestationsWithPrices = null;
  }

  /**
   * Initialize the view for treatments
   */
  private loadCareData() {
    this.card = this.cards[2];
    this.receptionType = ReceptionType[ReceptionType.CARE];
   
    this.prestationsWithPrices = null;
  }

  // set the current selected exam(s). Called by the grid of exam selection
  examsSelected(exams: Prestation[]) {
    //console.log("examsSelected", this.currrentObject);
    this.card.value = exams;
    if(this.currrentObject.prestationsToPerformed == null){
     this.currrentObject.prestationsToPerformed = [];
    }    
    //console.log("examsSelected", exams);
    if (exams && exams.length > 0 ) {
      this.currrentObject.prestationsToPerformed.forEach( (el) => { // un mark all items
          el.toAddOrUpdate = false;
          el.toDelete = false;
      });
      // Mark items to delete
      //console.log(" this.currrentObject.prestationsToPerformed ", this.currrentObject.prestationsToPerformed);
      let prestationsToPerformed = [];
      this.currrentObject.prestationsToPerformed.forEach( (el) => {
        const index = exams.findIndex( (ex) => el.id != null && el.prestation && el.prestation.id == ex.id);
        if (index == -1 ) {// do not exist
          if(el.id != null) {
            el.toAddOrUpdate =false;
            el.toDelete = true;
            prestationsToPerformed.push(el);
          } 
        } else {
          prestationsToPerformed.push(el);
        }
      });
      this.currrentObject.prestationsToPerformed = prestationsToPerformed;
      // add inexistant item
      exams.forEach( (ex: any) => {
        const index = this.currrentObject.prestationsToPerformed.findIndex( (el) => el.prestation && el.prestation.id == ex.id);
        if (index == -1) {// do not exist
          let service = null;
          if(ex.abilitedServiceId ){
            service = new BusinessUnit();
            service.id = ex.abilitedServiceId;
            service.name = ex.abilitedServiceName;
          }          
          const prestToPerform = new PrestationsToPerformed(ex, null, service); 
          prestToPerform.careCoverageDoc = this.insuranceToApply;          
          this.currrentObject.prestationsToPerformed.push(prestToPerform);
        }
      });
      // add prestation not already in list
      exams.forEach( (prest)=> {
          let index = this.prestationsWithPrices != null? this.prestationsWithPrices.findIndex( (el)=>{return el.id == prest.id}) : -1;
          if(index != -1){
            prest.price = this.prestationsWithPrices[index].price;
          }
      });
      this.buildPrestationsWithPrices(exams);
      
      //this.prestationsWithPrices = (this.prestationsWithPrices || []).concat(listOfNewPrestationSelected);

    } else {
      this.prestationsWithPrices = null;
      // Mark items to delete
      this.currrentObject.prestationsToPerformed.forEach( (el) => {
          el.toAddOrUpdate =false;
          el.toDelete = true;
      });
    }
    this.getPrestationsPrices();
    this.updateActionBtnStatus();
  }

  displayOptionFn(prestation?: Prestation): string {
    return prestation ? `${prestation.name}` : '';
  }

  displayAgentFn(agent?: MedicalAgent): string  {
    return agent ? `${agent.fullname}` : '';
  }

  displayServiceFn(service?: BusinessUnit): string {
    return service ? `${service.name}` : '';
  }
 
  displayPatientFn(patient: Patient) {
    return patient ? `${patient.fullname}` : '';
  }

  displayMedicalFolderFn(folder?: MedicalFolder) {
    let patient = folder && folder.patient ? self.displayPatientFn(folder.patient) : '';
    return folder ? `${folder.number} - ${patient}` : '';
  }

  getPatientContact(patient?: Patient) {
    let contact = patient ? patient.mobilePhone : null;
    contact = patient && !contact ? patient.fixedPhone : contact;
    contact = patient && !contact ? patient.emailAddress : contact;
    return contact;
  }

  // init card datas
  protected initCardsData() {
    this.cards = [
      { cardType: ReceptionType[ReceptionType.CONSULTATION],
        avatarUrl: 'assets/images/consultation.png',
        avatarCss: 'consultation-avatar',
        title: 'reception.home.consultation.title.label',
        subtitle: 'reception.home.consultation.subtitle.label',
        detailTitle: 'reception.home.consultation.detail.label',
        textContent: 'reception.home.consultation.textContent.label',
        datailSubTitle: 'reception.home.consultation.detail.doctor.label',
          actions: [
            {label: 'reception.home.action.file.label', routeData: ['/reception/admission']},
            {label: 'reception.home.action.save.label', action: FormAction.CREATE, name: 'home-consultation_save_btn'},
            {label: 'reception.home.action.apointment.label', routeData: ['/apointment/edit', {fromReception: true}]}
           /* {label: 'reception.home.consultation.action.new.label'},
          {label: 'reception.home.consultation.action.old.label'}*/]
      },
      { cardType: ReceptionType[ReceptionType.EXAMS],
        avatarUrl: 'assets/images/exams.png',
        avatarCss: 'exams-avatar',
        title: 'reception.home.exams.title.label',
        subtitle: 'reception.home.exams.subtitle.label',
        detailTitle : 'reception.home.exams.detail.label',
        textContent: 'reception.home.exams.textContent.label',
          actions: [
            {label: 'reception.home.action.file.label', routeData: ['/reception/admission']},
            {label: 'reception.home.action.save.label', action: FormAction.CREATE, name: 'home-exams_save_btn'},
            {label: 'reception.home.action.apointment.label', routeData: ['/apointment/edit', {fromReception: true}]}
          /*  {label: 'reception.home.exams.action.new.label'},
          {label: 'reception.home.exams.action.old.label'}*/]
      },
      { cardType: ReceptionType[ReceptionType.CARE],
        avatarUrl: 'assets/images/soins.jpg',
        avatarCss: 'treatments-avatar',
        title: 'reception.home.treatments.title.label',
        subtitle: 'reception.home.treatments.subtitle.label',
        detailTitle : 'reception.home.treatments.detail.label',
        textContent: 'reception.home.treatments.textContent.label',
          actions: [
            {label: 'reception.home.action.file.label', routeData: ['/reception/admission']},
            {label: 'reception.home.action.save.label', action: FormAction.CREATE, name: 'home-treatments_save_btn'},
            {label: 'reception.home.action.apointment.label', routeData: ['/apointment/edit', {fromReception: true}]}
          ]
      },
      { cardType: 'RDV',
        avatarUrl: 'assets/images/apointment.png',
        avatarCss: 'apointment-avatar',
        title: 'reception.home.apointment.title.label',
        subtitle: 'reception.home.apointment.subtitle.label',
        detailTitle: 'reception.home.apointment.detail.label',
        textContent: 'reception.home.apointment.textContent.label',
          actions: [{label: 'reception.home.apointment.action.new.label'},
          {label: 'reception.home.apointment.action.old.label'}]
      }
    ];
  }

  protected cardActionClicked(card, action) {
    this.currrentObject.type = this.receptionType;
    if (action && action.routeData) {      
      this.appService.setReception(this.currrentObject);
      this.router.navigate(action.routeData);
    } else if (action && action.action && action.action === FormAction.CREATE) {
      if (action.action === FormAction.CREATE && this.isValidToSave() === true) {
        this.save();
      }
    }
  }

  protected isValid(): boolean {
    let isValid = true;
    if (this.currrentObject && this.currrentObject.prestationsToPerformed.length < 1){
      isValid = false;
    }
    if (this.currrentObject && this.currrentObject.prestationsToPerformed.length > 0){
      const itemNotCorrect = this.currrentObject.prestationsToPerformed.find( (el)=> el.prestation == null || el.prestation.id == null);
       //console.log("itemNotCorrect ", itemNotCorrect, this.currrentObject.prestationsToPerformed);
      isValid = itemNotCorrect != null ? false: true;
      // at least one item to save must exist
      const itemToSave = this.currrentObject.prestationsToPerformed.find( (el)=> !el.toDelete);
      // console.log("itemToSave ", itemToSave, this.currrentObject.prestationsToPerformed);
      isValid = itemToSave == null ? false: isValid;
    }
    if (this.receptionType === ReceptionType[ReceptionType.CONSULTATION] && !this.medicalAgentSelected){
      isValid = false;
    }
    return isValid;
  }

  protected isValidToSave(){
    if(this.isValid() == true && this.currentPatientSelected != null && this.currentPatientSelected.id != null ){
      return true;
    }
    return false;
  }

  protected updateActionBtnStatus(){
    this.cardActionBtnStatus = this.isValid();
      
    if(this.medicalFolderSelected != null){
      this.cardSaveBtnStatus = this.isValidToSave();
    }
  }

  save() {
   // console.log("this.currrentObject",this.currrentObject);
   // set insurance on prestation to performed
   if(this.currrentObject && this.currrentObject)
    this.httpService.save(this.currrentObject, 'admission/reception', true).subscribe( (result) => {
        this.msgService.log("object.save.succed.message", NotificationType.SUCCESS);
        this.appService.setReception(null);
        this.router.navigate(['reception/list', {search: true}]);
      }, (error) => {
        if (error && Array.isArray(error)) {
          error.forEach( (err) => {
            this.msgService.log(err, NotificationType.ERROR);
          });
        } else {
          this.msgService.log("object.save.error", NotificationType.ERROR);
        }
    });
  }

  formatDate(date){
    try {
        return moment(new Date(date)).format("LL");
    } catch (e) {
      console.error("Parse date in reception-list fail " , e);
    }
  }

  displayDirectionFn(prestToPerformed: PrestationsToPerformed) {
    let str = "";
    if(prestToPerformed != null){
      str += prestToPerformed.prestation ? `${prestToPerformed.prestation.name} ` : '';
      str += prestToPerformed.agent ? `(${this.displayPersonFn(prestToPerformed.agent)} ` : '';
      str += !prestToPerformed.agent && prestToPerformed.unit ?
       `${prestToPerformed.agent ? '':'('} ${this.displayServiceFn(prestToPerformed.unit)} ` : '';
      str += prestToPerformed.unit || prestToPerformed.agent ? ')' : '';
    }
    return str;
  }
  displayPersonFn(agent?: Person): string  {
    return agent ? `${agent.fullname}` : '';
  }

  priceToggleChange(event) {
    this.priceChecked = !this.priceChecked;
    if(this.priceChecked == true){
      this.getPrestationsPrices();
    }
  }
}

export interface CardData {
  avatarCss?: string;
  avatarUrl?: string;
  title?: string;
  subtitle?: string;
  textContent?: string;
  actions?: {label?: string,planApointment?: boolean, routeData?: any, action?: FormAction, name?: string}[];
  cardType?: string;
  selected?: any;
  detailTitle?: string;
  value?: any;
  datailSubTitle?: string;
}

/*export class ReceptionType {
  public static CONSULTATION = 'C';
  public static EXAMS = 'E';
  public static RDV = 'RDV';
}*/
