import { NkapValidators } from './../../base/nkap-validators';
import { MedicalFolder } from 'src/app/model/admission/medical-folder';
import { Component, OnInit, ViewChild, ChangeDetectorRef, Input, Output, EventEmitter, OnChanges, SimpleChanges, HostListener } from '@angular/core';
import { FormControl, AbstractControl } from '@angular/forms';
import { Patient } from 'src/app/model/organisation/patient';
import { MedicalAgent } from 'src/app/model/organisation/medical-agent';
import { MatInput, MatDialog } from '@angular/material';
import { MedicalFolderSearchDialogComponent } from '../medical-folder-search-dialog/medical-folder-search-dialog.component';
import { TranslatePipe, TranslateService } from '@ngx-translate/core';
import { Router, ActivatedRoute } from '@angular/router';
import { ModalComponentService } from '../../base/modal/modal.component';
import { NkapNotificationService } from 'src/app/services/nkap-notification.service';
import { UserAppService } from 'src/app/services/user-app.service';
import { NkapHttpService } from 'src/app/services/nkap-http.service';
import { FormAction } from '../../buttons-bar/buttons-bar.component';
import * as moment from 'moment';
import { Party } from 'src/app/model/organisation/party';
import { ENTER } from '@angular/cdk/keycodes';

@Component({
  selector: 'app-medical-folder-selector',
  templateUrl: './medical-folder-selector.component.html',
  styleUrls: ['./medical-folder-selector.component.css']
})
export class MedicalFolderSelectorComponent implements OnInit , OnChanges{

  currentPatientSelected: Patient;

  patientList: MedicalAgent[];

  @ViewChild('patientInput')
  patientInputCmp: MatInput;
  patientControl = new FormControl();
  /**
   * To set the current folder selected
   */
  @Input('selected-folder')
  medicalFolderSelected: MedicalFolder;

  @Output('folderSelected')
  event: EventEmitter<MedicalFolder> = new EventEmitter<MedicalFolder>();

  /**
   * If details of patient selected must be show to the user
   */
  @Input('show-patient-details')
  showDetails: boolean = true;
  
  @Input('required')
  isRequired: boolean = false;

  private translator: TranslatePipe;
  /**
   * An indicator to know if http search has been performed and has not yet send result
   */
  private searchInProgress: boolean = false;
  
  constructor(public dialog: MatDialog,private _ref: ChangeDetectorRef,
              public modalService: ModalComponentService,
              private httpService: NkapHttpService<any>, 
              private translateService: TranslateService ) {
        this.translator = new TranslatePipe(translateService, _ref);
  }

  ngOnChanges(changes: SimpleChanges): void {
    if(changes['medicalFolderSelected']) {
      this.patientSelected(changes['medicalFolderSelected'].currentValue);
      if(this.showDetails == false && this.currentPatientSelected) {
        this.patientControl.patchValue(this.currentPatientSelected.fullname);
      }
      if (!this.currentPatientSelected) {
        this.patientControl.patchValue('');
      }
    }
  }

  ngOnInit() {
    this.patientControl = new FormControl(this.currentPatientSelected ? this.currentPatientSelected.fullname : '',
    this.isRequired == true?[ NkapValidators.notEmpty] : []);
     this.patientControl.markAsTouched();
  }

  /**
   * Search patient base on search text enter
   * If a patient is not found, open modal
   * */
  protected searchPatientFolder(event) {
    if(this.patientControl.valid == true && this.searchInProgress == false) {
      const inputValue = this.patientControl.value;
      this.searchInProgress = true;
      this.httpService.search({value: inputValue}, 'admission/medical-folder', true).subscribe ( (result) => {
          this.searchInProgress = false;
          if (result && result.datas && result.datas.length > 0) {
            if (result.datas.length == 1) {
              this.patientSelected(result.datas[0]);
            } else {
              this.patientSelected(null);
              this.searchMedicalFolder(inputValue);
            }
          } else {
            this.patientSelected(null);
            this.searchMedicalFolder(inputValue);
          }
      });
    }
  }

  protected clearPatientFolder(event) {
    this.patientControl.patchValue(null);
    this.patientSelected(null);
  }

  // Open modal to search a patient
  private searchMedicalFolder(inputValue){
    const dialogRef = this.modalService.openModal(MedicalFolderSearchDialogComponent,
      "reception.home.medical-folder.search.modal.title",
      {buttonBar:{functionality: "partner.third-party-payer",buttons: [
        {id: "cancel", name: "app-modal_cancel_btn", value: FormAction.CANCEL, icon: {type : 'cancel'},
          text: 'btn.cancel.label', disabled: false, functionalities: ['btn.cancel.label']},
        {id: "ok", name: "app-modal_save_btn", value: FormAction.CREATE, icon: {type : 'check_circle_outline'}, 
          text: 'btn.select.label', disabled: false, functionalities: ['btn.select.label']}
      ]}},
      {// Modal configuration: size of the modal, and data passed to the Modal
       width: '60%',
       /*height:'600px',*/
      position:{left:"10%", right:"10%"},
      data: { criteria: {value: inputValue}}
    });

    dialogRef.afterClosed().subscribe(result => {
        //console.log('The dialog was closed', result);
        if(result && ((result.btn != null && result.btn.id == "ok") || !result.btn) && result.value && result.value.id){
          this.patientSelected(result.value);
        }
    });
  }

  // set the curent patient/medical folder selected
  protected patientSelected(folder: MedicalFolder) {
    console.log({patientSelected:folder});
    if(folder && folder.patient) {
      this.medicalFolderSelected = folder;
      this.currentPatientSelected = folder.patient;
      this.currentPatientSelected.folderNumber = folder.number;
    } else {
      this.medicalFolderSelected = null;
      this.currentPatientSelected = null;      
    }
    this.patientControl.markAsTouched();
    this.event.emit(this.medicalFolderSelected);
    if(this.showDetails == false && this.currentPatientSelected) {
      this.patientControl.patchValue( this.currentPatientSelected.fullname);
    }
    console.log({patientSelected:this.currentPatientSelected});
  }

  getPatientContact(patient?: Patient) {
    let contact = patient ? patient.mobilePhone : null;
    contact = patient && !contact ? patient.fixedPhone : contact;
    contact = patient && !contact ? patient.emailAddress : contact;
    return contact;
  }

  formatDate(date){
    try {
        return moment(new Date(date)).format("LL");
    } catch (e) {
      console.error("Parse date in reception-list fail " , e);
    }
  }

  currentPatientSelectedAge() {
    const age = Party.getAge(this.currentPatientSelected.birthdate);
    return age != null ? age : '';
  }

  catchEnter(event) {
    if(event && event.keyCode == ENTER) {
      this.searchPatientFolder(null);
    }
  }

  getText(): string {
    return this.patientControl != null ? this.patientControl.value : '';
  }
}
