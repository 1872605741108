import { Component, OnInit, ViewChild, Input } from '@angular/core';
import { PrestationFamily, Prestation, PrestationAssortment } from 'src/app/model/prestation/prestation';
import { FormControl, Validators } from '@angular/forms';
import { NkapHttpService } from 'src/app/services/nkap-http.service';
import { Router, ActivatedRoute } from '@angular/router';
import { NkapNotificationService, NotificationType } from 'src/app/services/nkap-notification.service';
import { IFormActionBarButton, FormAction } from '../../buttons-bar/buttons-bar.component';
import { MatTableDataSource, MatTable } from '@angular/material';
import { BusinessUnit } from 'src/app/model/organisation/business-unit';
import { ClassNameMap } from 'src/app/model/persistent';
import { NkapValidators } from '../../base/nkap-validators';
import { Funtions } from 'src/app/model/security/funtionalities';

@Component({
  selector: 'app-prestation-edit',
  templateUrl: './prestation-edit.component.html',
  styleUrls: ['./prestation-edit.component.css']
})
export class PrestationEditComponent implements OnInit {
  
  @ViewChild(MatTable)
  tableCmp: MatTable<any>;
  prestationDataSource: MatTableDataSource<PrestationAssortment> = new MatTableDataSource([]);

  protected assortmentsToDelete: PrestationAssortment[] = [];
  protected prestation: Prestation;

  familyControl: FormControl = new FormControl();
  familyList: PrestationFamily[];

  prestations: Prestation[];
  myControl = new FormControl();

  codeControl: FormControl;
  nameControl: FormControl;

  serviceControl = new FormControl('', [ NkapValidators.dataWithIdSelected]);
  serviceList: BusinessUnit[];

  /**
   * Disables the family and code fields when editing a prestation
   */
  disabled:boolean=false;

  protected buttons: IFormActionBarButton[];

  constructor(private httpService: NkapHttpService<Prestation>, private router: Router,
              private route: ActivatedRoute, private msgService: NkapNotificationService) {
    this.prestation = new Prestation();
    this.buttons = [
      {id: "save", value: FormAction.CREATE, icon: {type : 'save'},
        text: 'btn.save.label', disabled: false, functionalities: [Funtions.prestation_btn_new_fct,Funtions.prestation_btn_edit_fct]},
      {id: "list", value: FormAction.VIEW, icon: {type : 'list'},
        text: 'btn.list.label', disabled: false, functionalities: [Funtions.prestation_btn_list_fct]}
    ];
  }

  ngOnInit() {
    this.codeControl = new FormControl('', [Validators.required, Validators.minLength(1)]);
    this.codeControl.updateValueAndValidity();
    this.nameControl = new FormControl('', [Validators.required, Validators.minLength(1)]);
    this.nameControl.updateValueAndValidity();
    this.familyControl = new FormControl('', [Validators.required, NkapValidators.dataWithIdSelected]);
    this.route.paramMap.subscribe(params => {
      let data: {id?: string} = (params as any).params;
      if (data.id != null) {
        this.httpService.findById(data.id, 'prestation', true).subscribe( (prestation)=>{
          this.disabled=true;
          this.setObjectInView(prestation);
        }, (errors) => {
          this.msgService.log("prestation.edit.get.data.error", NotificationType.ERROR);
        });
      }
    });
    this.familyControl.valueChanges.subscribe( (inputValue) => {
      if (typeof inputValue === 'string' ) {
        this.prestation.family = null;
        this.httpService.search({value: inputValue}, 'prestation/prestation-family').subscribe ( (result) => {
           this.familyList = result ? result.datas : [];
        });
      } else {
        this.prestation.family = inputValue;
        if(this.prestation.abilitedService == null && inputValue.abilitedServiceId != null){
          const unit = new BusinessUnit();
          unit.id = inputValue.abilitedServiceId;
          unit.name = inputValue.abilitedServiceName;
          this.serviceControl.setValue(unit)
        }
      }
    });
    this.serviceControl = new FormControl('', [ NkapValidators.dataWithIdSelected]);
    this.serviceControl.valueChanges.subscribe( (inputValue) => {
      if (typeof inputValue === 'string' ) {
        this.prestation.abilitedService = null;
        this.httpService.search({value: inputValue}, 'organisation/business-unit').subscribe ( (result) => {
           this.serviceList = result ? result.datas : [];
         });
       } else {
         this.prestation.abilitedService = inputValue;
       }
    });
    this.myControl.valueChanges.subscribe( (inputValue) => {
      if (typeof inputValue === "string" ) {
        this.httpService.search({value: inputValue}, 'prestation').subscribe ( (result) => {
           this.prestations = result.datas;
         });
       } else {
         this.addSelectedPrestation([new PrestationAssortment(inputValue,1)]);
       }
    });
  }

  public addSelectedPrestation(newPrestations: PrestationAssortment[]) {
    //console.log("newPrestations ", newPrestations);
    if(newPrestations.length > 0 && newPrestations[0].prestation != null){
      if(newPrestations[0].prestation.id != null && newPrestations[0].prestation.id != this.prestation.id){
        this.prestationDataSource.data = this.prestationDataSource.data.concat(newPrestations);
        this.tableCmp.renderRows();
      }else if(newPrestations[0].prestation.id != null && newPrestations[0].prestation.id == this.prestation.id){
        this.msgService.log("prestation.assortement.select.error", NotificationType.ERROR);
      }
    }
    
  }

  displayFamilyFn(family?: PrestationFamily): string  {
    return family ? `${family.code} ${family.name ? family.name : ''}` : '';
  }

  deleteElement(element){
    let index = this.prestationDataSource.data.findIndex( (elm) => element.prestation.id ==  elm.prestation.id);
    console.log("deleteElement ", index);
    if(index != -1){
      const elementToDelete = this.prestationDataSource.data[index];
      console.log("deleteElement ", elementToDelete);
      if(elementToDelete.id != null) {
        elementToDelete.toDelete = true;
        this.assortmentsToDelete.push(elementToDelete);
      }
      console.log("assortmentsToDelete ", this.assortmentsToDelete);
      this.prestationDataSource.data.splice(index, 1);
      this.tableCmp.renderRows();
    }
  }

  btnActionClick(event) {
    if(event && event.id === this.buttons[0].id){
      this.save();
    } else if(event && event.id === this.buttons[1].id){
      this.router.navigate(["prestation/list", {search: true}]);
    }
  }

  displayPrestationFn(prestation) {
    return prestation ? `${prestation.code} ${prestation.name}` : '';
  }

  displayOtherPrestationFn(prestation){
    return '';
  }

  displayServiceFn(service?: BusinessUnit): string {
    return service ? `${service.name}` : '';
  }

  isValid(){
    let isValid = true;
    this.getObjectFromView();
    this.codeControl.markAsTouched();
    this.nameControl.markAsTouched();
    this.familyControl.markAsTouched();
    this.serviceControl.markAsTouched();
    if(this.codeControl.invalid){
      isValid = false;
    }
    if(this.nameControl.invalid){
      isValid = false;
    }
    if(this.familyControl.invalid){
      isValid = false;
    }
    if(this.serviceControl.invalid){
      isValid = false;
    }
    if(this.prestation.assortments && this.prestation.assortments.length > 0){
      this.prestation.assortments.forEach( (elmt) => {
        if(!elmt.quantity || elmt.quantity <  0){
          isValid = false;
        }
      });
    }
    console.log( "isValid" , isValid);
    return isValid;
  }

  getObjectFromView(){
    this.prestation.code = this.codeControl.value;
    this.prestation.name = this.nameControl.value;
    this.prestation.assortments = this.prestationDataSource.data || [];
    if (this.assortmentsToDelete.length > 0) {
      this.prestation.assortments = this.prestation.assortments.concat(this.assortmentsToDelete);
    }
    this.prestation.classe = ClassNameMap.Prestation;
  }

  setObjectInView(prestation: Prestation){
    if (prestation) {
      this.prestation = prestation;
      this.codeControl = new FormControl(prestation.code, [Validators.required, Validators.minLength(1)]);
      this.codeControl.updateValueAndValidity();
      this.nameControl = new FormControl(prestation.name, [Validators.required, Validators.minLength(1)]);
      this.nameControl.updateValueAndValidity();
      this.familyControl.setValue(prestation.family ? prestation.family : null);
      this.serviceControl.setValue(prestation.abilitedService ? prestation.abilitedService : null);
      this.prestationDataSource.data = prestation.assortments ? prestation.assortments : [];
    }
  }

  save() {
    if (this.isValid() === true){
      this.httpService.save(this.prestation, 'prestation', true).subscribe( (result) => {
        this.msgService.log("prestation.edit.save.succed", NotificationType.SUCCESS);
        this.router.navigate(['prestation/list', {search: true}]);
      }, (error) => {
        if (error && Array.isArray(error)) {
          error.forEach( (err) => {
            this.msgService.log(err, NotificationType.ERROR);
          });
        } else if(typeof error === 'string') {
          this.msgService.log(error, NotificationType.ERROR);
        } else {
          this.msgService.log(error ? error : "prestation.edit.save.fail", NotificationType.ERROR);
        }
      });
    }/* else {
      this.msgService.log("prestation.edit.prestation.data.invalid", NotificationType.ERROR);
    }*/
  }

  setQuantity(element,event) {
    if(event && event.target && Number(event.target.value) <= 0) {
      //console.log("setQuantity " , Number(event.target.value));
      element.quantity = 1;
    }
    if(element) {
      element.toAddOrUpdate = true;
    } 
  }
}
