import { User } from './user';

export class AccessToken {
    public upgraded?: boolean;
    public access_token?: string;
    public expires_in?: number;
    public refresh_expires_in?: number;
    public refresh_token?: string;
    public token_type?: string;
    public id_token?: any;
    public not_before_policy?: number;
    public session_state?: any;
    public scope?: any;
    public user?: User;
}