import { Person } from './person';
import { Persistent, ClassNameMap } from '../persistent';
import { Party } from './party';

export class Patient extends Person {
    public employer?: Employer;
    public folderNumber?: number | string;

    constructor(name?: string, surname?: string ) {
        super(name, surname);
        this.employer = new Employer();
        this.classe = ClassNameMap.Patient;
    }
}

export class Employer extends Persistent {
    party?: Party;
    employementStartDate?: Date;
    constructor() {
        super();
        this.party = new Party();
        this.classe = ClassNameMap.Employer;
    }
}

