import { HttpErrorResponse } from '@angular/common/http';
import { throwError } from 'rxjs';
import { Role } from '../model/security/rule';
import { Funtions } from '../model/security/funtionalities';

export class Base64 {
    public static encode(str: string) {
        try{
            return btoa(str);
        }catch(e){
            console.error("Base64.encode ", e);
        }
    }
    public static decode(str: string) {
        try{
            return atob(str);
        }catch(e){
            console.error("Base64.decode ", e);
        }
    }
    
    public static convertBase64ToBlob( base64: string, type?: string) {
        // convert base64 to raw binary data held in a string
       // if(base64 == null || base64 == "") return "";
        try {
            let byteString = atob(base64);
            // write the bytes of the string to an ArrayBuffer
            let ab = new ArrayBuffer(byteString.length);
            let ia = new Uint8Array(ab);
            for (let i = 0; i < byteString.length; i++) {
                ia[i] = byteString.charCodeAt(i);
            }
            // write the ArrayBuffer to a blob, and you're done
            let bb = new Blob([ab],{type: type});
            return bb;
        }catch(e){
            console.error(" Utils.convertBase64ToBlob error ", e);
        }
        return null;
    }
}

export class DateUtils {
    public static getMonday(d) {
        return DateUtils.getDayOfInDateWeek(1, d);
    }
    public static getDayOfInDateWeek(number, d) {
        d = new Date(d);
        var day = d.getDay(),
            diff = d.getDate() - day + (day == 0 ? -6:number); // adjust when day is sunday
        return new Date(d.setDate(diff));
    }
}

// Table de correspondance entre les rôles correspondant aux différentes fonctionnalités
// il sera intéressant d'utiliser les noms des étiquettes sur les boutons pour désigner la fonctionnalité
// cela évitera de recréer un rôle qui existe déjà sous un nom différent
export const mapFunctionnality: Map<string, string> = new Map<string, string>(
    [
        /** Gestions des patients */
        [ Funtions.patient_btn_new_fct,   Role.CREATE_PATIENT ],
        [ Funtions.patient_btn_edit_fct,   Role.EDIT_PATIENT ],
        [ Funtions.patient_btn_list_fct,   Role.SHOW_MEDICAL_FOLDER ],
        [ Funtions.patient_btn_delete_fct,   Role.DELETE_PATIENT ],
        [ Funtions.patient_form_btn_print_fct,   Role.PRINT_PATIENT ],
        [ Funtions.patient_history_tab_label_fct,   Role.SHOW_PATIENT_HISTORY ],

        /** Gestions des antecedents */
        [ Funtions.antecedent_btn_new_fct,   Role.CREATE_ANTECEDENT ],
        [ Funtions.antecedent_btn_list_fct,   Role.SHOW_ANTECEDENT ],
        [ Funtions.antecedent_btn_edit_fct,   Role.EDIT_ANTECEDENT ],
        [ Funtions.antecedent_btn_delete_fct,   Role.DELETE_ANTECEDENT ],

         /** Gestions des types d'antecedents */
         [ Funtions.antecedent_type_btn_new_fct,   Role.CREATE_ANTECEDENT_TYPE ],
         [ Funtions.antecedent_type_btn_edit_fct,   Role.EDIT_ANTECEDENT_TYPE ],
         [ Funtions.antecedent_type_btn_list_fct,   Role.SHOW_ANTECEDENT_TYPE ],
         [ Funtions.antecedent_type_btn_delete_fct,   Role.DELETE_ANTECEDENT_TYPE ],

        /** Gestions des apointment */
        [ Funtions.apointment_btn_new_fct,   Role.CREATE_APOINTMENT ],
        [ Funtions.apointment_btn_list_fct,   Role.SHOW_APOINTMENT ],
        [ Funtions.apointment_btn_edit_fct,   Role.EDIT_APOINTMENT ],
        [ Funtions.apointment_btn_delete_fct,   Role.DELETE_APOINTMENT ],
        [ Funtions.apointment_btn_achieved_fct,   Role.ACHIEVED_APOINTMENT ],
        
        /** Gestions des businessUnit */
        [ Funtions.businessUnit_btn_new_fct,   Role.CREATE_BUSINESSUNIT ],
        [ Funtions.businessUunit_btn_list_fct,   Role.SHOW_BUSINESSUNIT ],
        [ Funtions.businessUnit_btn_edit_fct,   Role.EDIT_BUSINESSUNIT ],
        [ Funtions.businessUnit_btn_delete_fct,   Role.DELETE_BUSINESSUNIT ],
        
        /** Gestions des care coverage doc */
        [ Funtions.careCoverageDoc_btn_new_fct,   Role.CREATE_CARECOVERAGEDOC ],
        [ Funtions.careCoverageDoc_btn_list_fct,   Role.SHOW_CARECOVERAGEDOC ],
        [ Funtions.careCoverageDoc_btn_edit_fct,   Role.EDIT_CARECOVERAGEDOC ],

        /** Gestions des frcv */
        [ Funtions.frcv_btn_new_fct,   Role.CREATE_FRCV ],
        [ Funtions.frcv_btn_list_fct,   Role.SHOW_FRCV ],
        [ Funtions.frcv_btn_edit_fct,   Role.EDIT_FRCV ],
        [ Funtions.frcv_btn_delete_fct,   Role.DELETE_FRCV ],
        
        /** Gestions des docteurs(medical-agent) */
        [ Funtions.doctor_btn_new_fct,   Role.CREATE_DOCTOR ],
        [ Funtions.doctor_btn_list_fct,   Role.SHOW_DOCTOR ],
        [ Funtions.doctor_btn_edit_fct,   Role.EDIT_DOCTOR ],
        [ Funtions.doctor_btn_delete_fct,   Role.DELETE_DOCTOR ],

        /** Gestions des planing(diary) */
        [ Funtions.doctorPlaning_btn_new_fct,   Role.DOCTOR_DIARY ],
        [ Funtions.doctorPlaning_btn_list_fct,   Role.SHOW_DOCTOR_DIARY ],
        [ Funtions.doctorPlaning_btn_him_fct,   Role.SHOW_HIM_DIARY ],
        

        /** Gestions des User */
        [ Funtions.UserParametersEdit_btn_new_fct,   Role.CREATE_USER ],
        [ Funtions. UserParametersEdit_btn_list_fct,   Role.SHOW_USER ],
        [ Funtions.UserParametersEdit_btn_edit_fct,   Role.EDIT_USER ],
        [ Funtions.UserParametersEdit_btn_delete_fct,   Role.DELETE_USER ],

        /** Gestions des receptions */
        [ Funtions.reception_btn_new_fct,   Role.CREATE_RECEPTION ],
        [ Funtions.reception_btn_list_fct,   Role.SHOW_RECEPTION ],
        [ Funtions.reception_btn_edit_fct,   Role.EDIT_RECEPTION ],
        [ Funtions.reception_btn_delete_fct,   Role.DELETE_RECEPTION ],
        [ Funtions.reception_btn_close_fct,Role.CLOSE_RECEPTION],

        /** Gestions des prestations */
        [ Funtions.prestation_btn_new_fct,   Role.CREATE_PRESTATION ],
        [ Funtions.prestation_btn_list_fct,   Role.SHOW_PRESTATION ],
        [ Funtions.prestation_btn_edit_fct,   Role.EDIT_PRESTATION ],
        [ Funtions.prestation_btn_delete_fct,   Role.DELETE_PRESTATION ],
        [ Funtions.prestation_btn_params_fct,   Role.PARAMS_PRESTATION ],

        /** Gestions des prestations de famille */
        [ Funtions.prestationFamily_btn_new_fct,   Role.CREATE_PRESTATION_FAMILY ],
        [ Funtions.prestationFamily_btn_list_fct,   Role.SHOW_PRESTATION_FAMILY ],
        [ Funtions.prestationFamily_btn_edit_fct,   Role.EDIT_PRESTATION_FAMILY  ],
        [ Funtions.prestationFamily_btn_delete_fct,   Role.DELETE_PRESTATION_FAMILY ],


        /** Gestions des listes chronologique */
        [ Funtions.chronologicalList_btn_list_fct,   Role.SHOW_CHRONOLOGICALLIST ],
        [ Funtions.chronologicalList_btn_print_fct,   Role.PRINT_CHRONOLOGICALLIST ],
        [ Funtions.chronologicalList_btn_close_fct,   Role.CLOSE_CHRONOLOGICALLIST ],

        /** Gestions des activitées */
        [ Funtions.activity_btn_new_fct,   Role.CREATE_ACTIVITY ],
        [ Funtions.activity_btn_list_fct,   Role.SHOW_ACTIVITY ],
        [ Funtions.activity_btn_edit_fct,   Role.EDIT_ACTIVITY ],
        [ Funtions. activity_btn_delete_fct,   Role.DELETE_ACTIVITY ],

        /** Gestions des third party payer */
        [ Funtions.thirdPartyPayer_insurance_btn_new_fct,   Role.CREATE_THIRD_PARTY_PAYER_INSURANCE ],
        [ Funtions.thirdPartyPayer_employer_btn_new_fct,   Role.CREATE_THIRD_PARTY_PAYER_EMPLOYER ],
        [ Funtions.thirdPartyPayer_person_btn_new_fct,   Role.CREATE_THIRD_PARTY_PAYER_PERSON ],
        [ Funtions.thirdPartyPayer_btn_list_fct,   Role.SHOW_THIRD_PARTY_PAYER ],
        [ Funtions.thirdPartyPayer_btn_edit_fct,   Role.EDIT_THIRD_PARTY_PAYER ],
        [ Funtions.thirdPartyPayer_btn_delete_fct,   Role.DELETE_THIRD_PARTY_PAYER ],

        /** Gestions des prescriptions de soins */
        [ Funtions.carePrescription_btn_new_fct,   Role.CREATE_CARE_PRESCRIPTION ],
        [ Funtions.carePrescription_btn_edit_fct,   Role.EDIT_CARE_PRESCRIPTION ],
        [ Funtions.carePrescription_btn_list_fct,   Role.SHOW_CARE_PRESCRIPTION ],
        [ Funtions.carePrescription_btn_delete_fct,   Role.DELETE_CARE_PRESCRIPTION ],
        [ Funtions.carePrescription_btn_close_fct,   Role.CLOSE_CARE_PRESCRIPTION ],
        [ Funtions.carePrescription_btn_print_fct,   Role.PRINT_CARE_PRESCRIPTION ],

        /** Gestions des prescriptions d'examens */
        [ Funtions.examPrescription_btn_new_fct,   Role.CREATE_EXAM_PRESCRIPTION ],
        [ Funtions.examPrescription_btn_edit_fct,   Role.EDIT_EXAM_PRESCRIPTION ],
        [ Funtions.examPrescription_btn_list_fct,   Role.SHOW_EXAM_PRESCRIPTION ],
        [ Funtions.examPrescription_btn_delete_fct, Role.DELETE_EXAM_PRESCRIPTION ],
        [ Funtions.examPrescription_btn_close_fct,   Role.CLOSE_EXAM_PRESCRIPTION ],
        [ Funtions.examPrescription_btn_print_fct,   Role.PRINT_EXAM_PRESCRIPTION ],

        /** Manage consultation sheet */
        [ Funtions.consultationSheet_btn_view_fct,   Role.SHOW_CONSULTATION_SHEET ],

        /** Manage cost regulation statement */
        [ Funtions.costRegulationStatement_btn_print_fct,   Role.PRINT_COSTS_REGULATIONS_STATEMENTS ],
        [ Funtions.costRegulationStatement_btn_view_fct,   Role.SHOW_COSTS_REGULATIONS_STATEMENTS ],

        /** Manage counter activities report */
        [ Funtions.counterActivities_btn_view_fct,   Role.SHOW_COUNTER_ACTIVITIES ],
        [ Funtions.counterActivities_btn_print_fct,   Role.PRINT_COUNTER_ACTIVITIES ],

        [ Funtions.consultation,   Role.CONSULTATION_HOME ],
        [ Funtions.exams,   Role.EXAMS_HOME ],
        [ Funtions.treatments,  Role.TREATMENTS_HOME ],

        /** Gestions des prescriptions médicales */
        [ Funtions.medicalPrescription_btn_delete_fct,   Role.DELETE_MEDICAL_PRESCRIPTION ],
        [ Funtions.medicalPrescription_btn_close_fct,   Role.CLOSE_MEDICAL_PRESCRIPTION ],
        [ Funtions.medicalPrescription_btn_edit_fct,   Role.EDIT_MEDICAL_PRESCRIPTION ],
        [ Funtions.medicalPrescription_btn_print_fct,   Role.PRINT_MEDICAL_PRESCRIPTION ],
        [ Funtions.medicalPrescription_btn_list_fct,   Role.SHOW_MEDICAL_PRESCRIPTION ],
        [ Funtions.medicalPrescription_btn_new_fct,   Role.CREATE_MEDICAL_PRESCRIPTION ],

         /** Gestions des examens */
        [ Funtions.reception_btn_allow_exam_fct,   Role.GENERATE_EXAM_BASED_ON_RECEPTION ],
        [ Funtions.exam_btn_list_fct,   Role.SHOW_EXAM ],
        [ Funtions.exam_btn_plan_fct,   Role.EXAM_PLAN_NEW_APOINTMENT ],
        [ Funtions.exam_btn_result_fct,   Role.SAVE_EXAM_RESULT ],
        [ Funtions.exam_btn_view_fct,   Role.EXAM_VIEW_AN_EXAM ],
        [ Funtions.exam_btn_saveResult_fct,   Role.SAVE_EXAM_RESULT ],
        [ Funtions.exam_btn_saveAssessment_fct,   Role.SAVE_EXAM_ASSESSMENT ],
        [ Funtions.exam_btn_saveProcessing_fct,   Role.SAVE_EXAM_PROCESSING ],
        [ Funtions.exam_btn_result_print_fct,   Role.EXAM_RESULT_PRINT ],
        [ Funtions.exam_btn_print_fct,   Role.EXAM_PRINT ],

         /** Gestions des medical institution partner */
         [ Funtions.medicalInstitutionPartner_btn_list_fct,   Role.LIST_MEDICAL_INSTITUTION_PARTNER ],
         [ Funtions.medicalInstitutionPartner_btn_list_fct,   Role.SHOW_MEDICAL_INSTITUTION_PARTNER ],
         [ Funtions.medicalInstitutionPartner_btn_edit_fct,   Role.CREATE_MEDICAL_INSTITUTION_PARTNER ],
         [ Funtions.medicalInstitutionPartner_btn_edit_fct,   Role.EDIT_MEDICAL_INSTITUTION_PARTNER ],
         [ Funtions.medicalInstitutionPartner_btn_show_fct,   Role.SHOW_MEDICAL_INSTITUTION_PARTNER ],
         [ Funtions.medicalInstitutionPartner_btn_delete_fct,   Role.DELETE_MEDICAL_INSTITUTION_PARTNER ]
    ]
);

export const mapHomePageByGroup: Map<string, string[]> = new Map<string, string[]>(
    [
        [Role.CREATE_PATIENT,["reception/admission"]],
        [Role.EDIT_PATIENT,["reception/admission"]],
        [Role.SHOW_MEDICAL_FOLDER,["medical-folder/list"]],
        [Role.DELETE_PATIENT,["medical-folder/list"]],
        [Role.PRINT_PATIENT,["medical-folder/list"]],
        [Role.CREATE_ANTECEDENT,["antecedent/list"]],
        [Role.SHOW_ANTECEDENT,["antecedent/list"]],
        [Role.EDIT_ANTECEDENT,["antecedent/list"]],
        [Role.DELETE_ANTECEDENT,["antecedent/list"]],
        [Role.CREATE_APOINTMENT,["apointment/edit"]],
        [Role.SHOW_APOINTMENT,["apointment/edit"]],
        [Role.EDIT_APOINTMENT,["apointment/list"]],
        [Role.DELETE_APOINTMENT,["apointment/list"]],
        [Role.ACHIEVED_APOINTMENT,["apointment/list"]],
        [Role.CREATE_BUSINESSUNIT,["business-unit/list"]],
        [Role.SHOW_BUSINESSUNIT,["business-unit/list"]],
        [Role.EDIT_BUSINESSUNIT,["business-unit/list"]],
        [Role.DELETE_BUSINESSUNIT,["business-unit/list"]],
        [Role.CREATE_CARECOVERAGEDOC,["medical-folder/list"]],
        [Role.SHOW_CARECOVERAGEDOC,["medical-folder/list"]],
        [Role.EDIT_CARECOVERAGEDOC,["medical-folder/list"]],
        [Role.CREATE_FRCV,["frcv/list"]],
        [Role.SHOW_FRCV,["frcv/list"]],
        [Role.EDIT_FRCV,["frcv/list"]],
        [Role.DELETE_FRCV,["frcv/list"]],
        [Role.CREATE_DOCTOR,["doctor/list"]],
        [Role.SHOW_DOCTOR,["doctor/list"]],
        [Role.EDIT_DOCTOR,["doctor/list"]],
        [Role.DELETE_DOCTOR,["doctor/list"]],
        [Role.DOCTOR_DIARY,["doctor/planing"]],
        [Role.SHOW_DOCTOR_DIARY,["doctor/planing"]],
        [Role.SHOW_HIM_DIARY,["doctor/planing"]],
        [Role.CREATE_USER,["user-parameters/show"]],
        [Role.SHOW_USER,["user-parameters/show"]],
        [Role.EDIT_USER,["user-parameters/show"]],
        [Role.DELETE_USER,["user-parameters/show"]],
        [Role.CREATE_RECEPTION,["reception/list"]],
        [Role.SHOW_RECEPTION,["reception/list"]],
        [Role.EDIT_RECEPTION,["reception/list"]],
        [Role.DELETE_RECEPTION,["reception/list"]],
        [Role.CREATE_PRESTATION,["prestation/list"]],
        [Role.SHOW_PRESTATION,["prestation/list"]],
        [Role.EDIT_PRESTATION,["prestation/list"]],
        [Role.DELETE_PRESTATION,["prestation/list"]],
        [Role.PARAMS_PRESTATION,["prestation/list"]],
        [Role.CREATE_PRESTATION_FAMILY,["prestation-family/list"]],
        [Role.SHOW_PRESTATION_FAMILY,["prestation-family/list"]],
        [Role.EDIT_PRESTATION_FAMILY,["prestation-family/list"]],
        [Role.DELETE_PRESTATION_FAMILY,["prestation-family/list"]],
        [Role.SHOW_CHRONOLOGICALLIST,["apointment/chronological-list"]],
        [Role.PRINT_CHRONOLOGICALLIST,["apointment/chronological-list"]],
        [Role.CLOSE_CHRONOLOGICALLIST,["apointment/chronological-list"]],
        [Role.CREATE_ACTIVITY,["medical-folder/list"]],
        [Role.SHOW_ACTIVITY,["medical-folder/list"]],
        [Role.EDIT_ACTIVITY,["medical-folder/list"]],
        [Role.DELETE_ACTIVITY,["medical-folder/list"]],
        [Role.CREATE_EXAM_PRESCRIPTION,["prescription/list"]],
        [Role.SHOW_EXAM_PRESCRIPTION,["prescription/list"]],
        [Role.EDIT_EXAM_PRESCRIPTION,["prescription/list"]],
        [Role.DELETE_EXAM_PRESCRIPTION,["prescription/list"]],
        [Role.CREATE_CARE_PRESCRIPTION,["prescription/list"]],
        [Role.SHOW_CARE_PRESCRIPTION,["prescription/list"]],
        [Role.EDIT_CARE_PRESCRIPTION,["prescription/list"]],
        [Role.DELETE_CARE_PRESCRIPTION,["prescription/list"]],
        [Role.CREATE_THIRD_PARTY_PAYER_INSURANCE,["third-party-payer/list"]],
        [Role.CREATE_THIRD_PARTY_PAYER_EMPLOYER,["third-party-payer/list"]],
        [Role.CREATE_THIRD_PARTY_PAYER_PERSON,["third-party-payer/list"]],
        [Role.SHOW_THIRD_PARTY_PAYER,["third-party-payer/list"]],
        [Role.EDIT_THIRD_PARTY_PAYER,["third-party-payer/list"]],
        [Role.DELETE_THIRD_PARTY_PAYER,["third-party-payer/list"]],
        [ Role.CONSULTATION_HOME,["reception/home"]],
        [Role.EXAMS_HOME,["reception/home"]],
        [Role.TREATMENTS_HOME,["reception/home"]],
    ]
);

export class ServiceUtils {
    public static handleError(error: HttpErrorResponse, doSomething?: any) {
        let errorData = error.error;
        if (error.error instanceof ErrorEvent) {
          // A client-side or network error occurred. Handle it accordingly.
          
        } else {
          if(error.status == 401){
            errorData = error.error;
          } else if(error.status >= 500 && error.status < 600){
            errorData = error.error;
            const indexOfServerInaccessibleError = error.error.indexOf("Error occured while trying to proxy to");
            if(indexOfServerInaccessibleError != -1){
                errorData = "http.error.server.unreachable.error";
            }
          }
        }
        return throwError(errorData);
    }
}