import { SearchCriteria } from '../data-page';

export class SaleReportCriteriaSearch extends SearchCriteria{

    public period?: {start?:Date | any, end?:Date | any, comparator?: string};

	public productCodes?: string[];
	
    public cashierNumbers?: string[];
    
    public allPages?: boolean;

    constructor(){
        super();
        this.allPages = false;
        this.period = {};
        this.productCodes = [];
        this.cashierNumbers = [];
    }
}
