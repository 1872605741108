import { MedicalPrescription, ExamPrescription, CarePrescription, MedicalPrescriptionItem, PrescriptionItem } from './../../../model/prescriptions/prescription';
import { Component, OnInit, Input, OnChanges, SimpleChanges } from '@angular/core';

@Component({
  selector: 'app-prescriptions-details-show',
  templateUrl: './prescriptions-details-show.component.html',
  styleUrls: ['./prescriptions-details-show.component.css']
})
export class PrescriptionsDetailsShowComponent implements OnInit, OnChanges {

  @Input('medicals')
  medicalPrescriptions: MedicalPrescription[];
  medicalItems: MedicalPrescriptionItem[];

  @Input('exams')
  examPrescriptions: ExamPrescription[];
  examItems: PrescriptionItem[];

  @Input('care')
  carePrescriptions: CarePrescription[];
  careItems: PrescriptionItem[];

  constructor() { 
  }

  ngOnChanges(changes: SimpleChanges): void {
    if(changes["medicalPrescriptions"]){
      let items = [];
      if(this.medicalPrescriptions) {
        this.medicalPrescriptions.forEach( (elmt:MedicalPrescription)=> {
          if(elmt.prescriptionItems != null){
            items = items.concat(elmt.prescriptionItems);
          }
        });
      }
      this.medicalItems = items;
    }
    if(changes["examPrescriptions"]){
      let items = [];
      if(this.examPrescriptions != null) {
        this.examPrescriptions.forEach( (elmt:ExamPrescription)=> {
          if(elmt.prescriptionItems){
            items = items.concat(elmt.prescriptionItems);
          }
        });
      }
      this.examItems = items;
    }
    if(changes["carePrescriptions"]){
      let items = [];
      if(this.carePrescriptions != null) {
        this.carePrescriptions.forEach( (elmt:CarePrescription)=> {
          if(elmt.prescriptionItems){
            items = items.concat(elmt.prescriptionItems);
          }
        });
      }
      this.careItems = items;
    }
  }

  ngOnInit() {
  }

  formatMedicalItem(item: MedicalPrescriptionItem) {
    let textFormated = "";
    if(item.drug) {
      textFormated = `${item.quantity && item.quantity > 0? item.quantity : ""} `;
      if(item.conditioning && item.conditioning.trim() != "") {
        textFormated = `${item.drug.name} ${textFormated && textFormated.trim() != "" ?
         "(" + textFormated + " " + item.conditioning + ")" : item.conditioning}. ${item.protocol? item.protocol : ""}`;
      } else {
        textFormated = `${textFormated && textFormated.trim() != "" ? textFormated : ""} 
        ${item.drug.name}. ${item.protocol? item.protocol : ""}`;
      }
    }
    return textFormated;
  }

  formatExamOrCareItem(item: PrescriptionItem) {
    let textFormated = "";
    if(item.prestation) {
      if(item.quantity && item.quantity >= 1) {
        textFormated = `${item.quantity}`;
      }
      textFormated += ` ${item.prestation.name}. ${item.protocol? item.protocol : ""}`;
    }
    return textFormated;
  }
}