import { NkapValidators } from './../../base/nkap-validators';
import { IModalComponent } from 'src/app/components/base/modal/modal.component';
import { Component, OnInit, Input, ViewChild, ChangeDetectorRef, Inject } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Prestation, PrestationFamily } from 'src/app/model/prestation/prestation';
import { NkapHttpService } from 'src/app/services/nkap-http.service';
import { NkapNotificationService, NotificationType } from 'src/app/services/nkap-notification.service';
import { TranslateService } from '@ngx-translate/core';
import { MatTableDataSource, MatTable, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { GlobalVariables } from 'src/app/services/global-variables';
import { NkapListComponent } from '../../base/nkap-list-component';
import { Router, ActivatedRoute } from '@angular/router';
import { UserAppService } from 'src/app/services/user-app.service';
import { FormAction, ButtonsBarComponent, IFormActionBarButton } from '../../buttons-bar/buttons-bar.component';
import { Funtions } from 'src/app/model/security/funtionalities';
import { SelectionModel } from '@angular/cdk/collections';

@Component({
  selector: 'app-prestation-edit-modal',
  templateUrl: './prestation-edit-modal.component.html',
  styleUrls: ['./prestation-edit-modal.component.css']
})
export class PrestationEditModalComponent extends NkapListComponent<Prestation> implements OnInit , IModalComponent{

  currentObject: Prestation;

  protected form: FormGroup;
  familyList: PrestationFamily[];

  @ViewChild('buttonsBar')
  protected buttonsBarCmp: ButtonsBarComponent;
  
  constructor(private formBuilder: FormBuilder,
    protected _ref: ChangeDetectorRef, protected httpService: NkapHttpService<any>, 
    protected router: Router, protected route: ActivatedRoute,protected userService: UserAppService,
    protected msgService: NkapNotificationService, protected translateService: TranslateService,
    public dialogRef: MatDialogRef<PrestationEditModalComponent>,
    @Inject(MAT_DIALOG_DATA) public modalData: any) {

     super(_ref,httpService,router, route,userService,msgService,translateService);
     this.currentObject = modalData? modalData.data : new Prestation();
     //console.log(" this.currentObject ", this.currentObject);
     this.searchBaseUrl = "prestation/drugs";
     this.selection = new SelectionModel<Prestation>(false, []);// single selection possible
     this.selection.changed.subscribe( ()=> {
        this.updateButtonsStatus();
     });
     this.buttons = [
      {id: 'select', value: FormAction.CREATE, disabled: this.selection.isEmpty() , icon: {type : 'check_circle_outline'},
        text: 'medical-prestation.btn.select.label', functionalities: [Funtions.medicalPrescription_btn_edit_fct]},
      {id: 'save', value: FormAction.CREATE, disabled: !this.isCurrentObjectValid() , icon: {type : 'save'},
        text: 'medical-prestation.btn.save.label', functionalities: [Funtions.medicalPrescription_btn_edit_fct]},
      {id: 'cancel', value: FormAction.CANCEL, icon: {type : 'cancel'},
        text: 'btn.cancel.label', functionalities: ['btn.cancel']},
     ];
     
  }
  public getData() {
    this.currentObject = Object.assign(new Prestation(), this.form ? this.form.value: {});
    return this.currentObject;
  }

  ngOnInit() {
    this.initForm();
  }

  initForm() {
    this.form = this.formBuilder.group({
      family: [this.currentObject? this.currentObject.family : null,[Validators.required, NkapValidators.dataWithIdSelected]],
      code: [this.currentObject? this.currentObject.code : null,[Validators.required, NkapValidators.notEmpty]],
      name: [this.currentObject? this.currentObject.name : null,[Validators.required, NkapValidators.notEmpty]]
    });
    this.form.get("family").valueChanges.subscribe( (inputValue) => {
      if (typeof inputValue === 'string' ) {
        this.currentObject.family = null;
        // We only look at drugs familly
        this.httpService.search({value: inputValue, onlyDrugs: true}, 'prestation/prestation-family').subscribe ( (result) => {
           this.familyList = result ? result.datas : [];
        });
      } else {
        this.currentObject.family = inputValue;
        if(this.form.get("code").invalid){
          this.form.get("code").patchValue(inputValue.code + "_");
        }
      }
    });
    this.form.statusChanges.subscribe( ()=> {
      this.updateButtonsStatus();
    });
    this.updateButtonsStatus();
  }

  displayFamilyFn(family?: PrestationFamily): string  {
    return family ? `${family.name ? family.name : ''}` : '';
  }

  isCurrentObjectValid(): boolean{
    let isValid = true;
    if(!this.form || this.form.invalid){
      isValid = false;
    }
    console.log( "isValid" , isValid, this.form? this.form.value : null);
    return isValid;
  }

  onNoClick(): void {
    this.dialogRef.close({value:null});
  }

  updateButtonsStatus(): void {
    if(this.buttonsBarCmp){
      let newStatusList = [
        {id: 'select', disabled: this.selection.isEmpty() },
        {id: 'save', disabled:!this.isCurrentObjectValid()}
      ];
      //console.log("updateButtonsStatus ",newStatusList);
      this.buttonsBarCmp.setButtonsStatus(newStatusList);
    }
  }

  btnActionClick(event) {
    let btnNumber = 0;
    if ( event && event.id === this.buttons[btnNumber++].id) {
      if(!this.selection.isEmpty()) {
        this.dialogRef.close({value:this.selection.selected[0]});
      }
    } else if ( event && event.id === this.buttons[btnNumber++].id) {
      this.save();
    } else if ( event && event.id === this.buttons[btnNumber++].id) {
      this.dialogRef.close({value:null});
    }
  }

  save() {
    if(this.isCurrentObjectValid() == true) {
      this.httpService.save(this.getData(), 'prestation', true).subscribe( (result) => {
        this.msgService.log("medical-prestation.edit.save.succed", NotificationType.SUCCESS);
        this.dialogRef.close({value:result});
      }, (error) => {
        if (error && Array.isArray(error)) {
          error.forEach( (err) => {
            this.msgService.log(err, NotificationType.ERROR);
          });
        } else if(typeof error === 'string') {
          this.msgService.log(error, NotificationType.ERROR);
        } else {
          this.msgService.log(error ? error : "medical-prestation.edit.save.fail", NotificationType.ERROR);
        }
      });
    }
  }

  searchPrestation(event) {
    this.criteria.value = null;
    this.criteria.code = this.form && this.form.get('code').valid == true? this.form.get('code').value : undefined;
    this.criteria.name = this.form && this.form.get('name').valid == true ? this.form.get('name').value : undefined;
    if(!this.criteria.code){
      this.criteria.value = this.criteria.name;
      this.criteria.name = undefined;
    }
    this.criteria.categoryId = this.currentObject && this.currentObject.family ? this.currentObject.family.id : undefined
    this.search(this.criteria)
  }
}
