import { Party } from 'src/app/model/organisation/party';
import { Component, OnInit, ChangeDetectorRef, ViewChild, Input, AfterViewInit, AfterViewChecked } from '@angular/core';
import { NkapListComponent } from 'src/app/components/base/nkap-list-component';
import { Reception, ReceptionStatus, PrestationsToPerformed, ReceptionType } from 'src/app/model/admission/reception';
import { NkapHttpService } from 'src/app/services/nkap-http.service';
import { ActivatedRoute, Router } from '@angular/router';
import { UserAppService } from 'src/app/services/user-app.service';
import { NkapNotificationService, NotificationType } from 'src/app/services/nkap-notification.service';
import { TranslateService } from '@ngx-translate/core';
import { FormControl } from '@angular/forms';
import { MedicalAgent } from 'src/app/model/organisation/medical-agent';
import { BusinessUnit } from 'src/app/model/organisation/business-unit';
import { Person } from 'src/app/model/organisation/person';
import { Prestation } from 'src/app/model/prestation/prestation';
import * as moment from 'moment';
import { MatInput, MatTableDataSource } from '@angular/material';
import { ButtonsBarComponent, IFormActionBarButton, FormAction } from 'src/app/components/buttons-bar/buttons-bar.component';
import { Funtions } from 'src/app/model/security/funtionalities';
import { Antecedent, FRCVTypes, SystemInvestigation } from 'src/app/model/admission/medical-folder';
import { PhysicalExam } from 'src/app/model/consultation/physical.exam';
import { ExamPrescription, MedicalPrescription, CarePrescription } from 'src/app/model/prescriptions/prescription';
import { trigger, state, style, animate, transition } from '@angular/animations';
import { VisitHistory, VisitHistoryDetails } from 'src/app/model/admission/visit-history';


@Component({
  selector: 'app-patient-history',
  templateUrl: './patient-history.component.html',
  styleUrls: ['./patient-history.component.css'],
  animations: [
    trigger('detailExpand', [
      state('collapsed', style({height: '0px', minHeight: '0'})),
      state('expanded', style({height: '*'})),
      transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
    ]),
  ],
})
export class PatientHistoryComponent extends NkapListComponent <any> {

  medicalAgentControl = new FormControl();
  medicalAgentSelected: MedicalAgent;
  medicalAgentList: MedicalAgent[];
  @ViewChild('medicalAgentInput')
  medicalAgentInputCmp: MatInput;

  serviceControl = new FormControl();
  serviceSelected: BusinessUnit;
  serviceList: BusinessUnit[];
  @ViewChild('serviceInput')
  serviceInputCmp: MatInput;

  @ViewChild("btnBar")
  btnBarCmp: ButtonsBarComponent;

  @Input()patientIdInput:string | number;

  protected statusList = [
    {value: null, text:""},
    {value:ReceptionStatus[ReceptionStatus.PENDING], text:"ReceptionStatus."+ReceptionStatus[ReceptionStatus.PENDING]+".label"},
    {value:ReceptionStatus[ReceptionStatus.CLOSED], text:"ReceptionStatus."+ReceptionStatus[ReceptionStatus.CLOSED]+".label"}
  ];

  expandedElement?: VisitHistoryDetails;

  constructor(protected _ref: ChangeDetectorRef, protected httpService: NkapHttpService<any>, 
    protected router: Router, protected route: ActivatedRoute,protected userService: UserAppService,
    protected msgService: NkapNotificationService, protected translateService: TranslateService) {

    super(_ref, httpService, router, route, userService, msgService, translateService);

    this.searchBaseUrl = "admission/visits";

    this.buttons = [
        {id: 'view', value: FormAction.VIEW, icon: {type : 'assignment', color: '#ff9933'}, 
          text: 'consultation-sheet.btn.view.label', disabled: false, functionalities: [Funtions.consultationSheet_btn_view_fct]},
      ];
   }
  
  ngOnInit() {
    super.ngOnInit();
    console.log(this.patientIdInput)
    this.criteria.patientId=this.patientIdInput;

     // By default we render receptions of the current day
    if(this.criteria.period == null) {
      this.criteria.period = {};
    }
    this.medicalAgentControl.valueChanges.subscribe( (inputValue) => {
      if (typeof inputValue === 'string' ) {
        this.medicalAgentSelected = null;
        this.httpService.search({value: inputValue}, 'organisation/medical-agent').subscribe ( (result) => {
           this.medicalAgentList = result ? result.datas : [];
        });
       } else {
         this.medicalAgentSelected = inputValue;
       }
      this.criteria.medicalAgentId = this.medicalAgentSelected? this.medicalAgentSelected.id : undefined;
    });

    this.serviceControl.valueChanges.subscribe( (inputValue) => {
      if (typeof inputValue === 'string' ) {
        this.serviceSelected = null;
        this.httpService.search({value: inputValue}, 'organisation/business-unit').subscribe ( (result) => {
           this.serviceList = result ? result.datas : [];
         });
       } else {
         this.serviceSelected = inputValue;
       }
      this.criteria.unitId = this.serviceSelected? this.serviceSelected.id : undefined;
    });

  }

  ngAfterViewInit(): void {
    super.ngAfterViewInit();
    this.search(this.criteria);
  }

  protected search(criteria: any) {
    if(criteria != null && criteria.patientId != null){
      super.search(criteria);
      this.expandedElement = null;
    }
  }

  btnActionClick(event) {
    console.log(event)
    if(event && event.id === this.buttons[0].id){
      if( !this.selection.selected || this.selection.selected.length < 1){
        // this.msgService.log("", NotificationType.ERROR);
      }else{
        this.router.navigate(['reception/consultation-sheet', {id: this.selection.selected[0].id, edit:false}]);
      }
    }
  }

  rowClicked(element: VisitHistory) {
    console.log("rowClicked", element);
    if(this.expandedElement != null && this.expandedElement.receptionId == element.receptionId) {
      this.expandedElement = null;
      console.log("expandedElement", this.expandedElement);
    } else if(element && element.receptionId != null) {
        console.log("element.receptionId", element.receptionId);
        if(element.details == null) {
          this.httpService.post({receptionId: element.receptionId}, 'admission/visits-details/search', true).subscribe ( (result) => {
            this.expandedElement = Object.assign({},result);
            element.details = Object.assign({},result);
          });
        } else {
          this.expandedElement = Object.assign({},element.details);;
        }
    }
  }

  displayMedicalAgentFn(agent?: Person): string  {
    return agent ? `${agent.fullname}` : '';
  }

  displayPersonFn(agent?: Person): string  {
    return agent ? `${agent.fullname}` : '';
  }

  displayServiceFn(service?: BusinessUnit): string {
    return service ? `${service.name}` : '';
  }

  displayPrestationFn(prestation?: Prestation): string {
    return prestation ? `${prestation.name}` : '';
  }

  formatDate(date){
    try {
        return moment(new Date(date)).format("LL") + "[" + moment(new Date(date)).utc().format("HH:mm") + "] " ;
    } catch (e) {
      console.error("Parse date in reception-list fail " , e);
    }
  }

  protected startDateSelected(event) {
    if(!this.criteria.period){
      this.criteria.period = {};
    }
    this.criteria.period.start = event.value;
  }

  protected endDateSelected(event) {
    if(!this.criteria.period){
      this.criteria.period = {};
    }
    this.criteria.period.end = event.value;
  }

  protected viewElement(element) {
    this.router.navigate(['reception/show', {id: element.id}]);
  }

  listSelectionChanged(){
    super.listSelectionChanged();
    if(this.btnBarCmp){
      const newStatus = [
        {id: 'view',  disabled: 
                    (this.selection.selected &&
                    this.selection.selected.length == 1 &&
                    this.selection.selected[0].type == ReceptionType[ReceptionType.CONSULTATION]
                    ) ? false : true
                    , functionalities:this.buttons[0].functionalities
        }

      ];
      console.log("listSelectionChanged " ,newStatus);
      this.btnBarCmp.setButtonsStatus(newStatus);
    }
  }

}
