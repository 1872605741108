import { Patient } from './../../../../model/organisation/patient';
import { Component, OnInit, ViewChild, ChangeDetectorRef } from '@angular/core';
import { NkapListComponent } from 'src/app/components/base/nkap-list-component';
import { ButtonsBarComponent, IFormActionBarButton, FormAction } from 'src/app/components/buttons-bar/buttons-bar.component';
import { NkapHttpService } from 'src/app/services/nkap-http.service';
import { Router, ActivatedRoute } from '@angular/router';
import { UserAppService } from 'src/app/services/user-app.service';
import { TranslateService } from '@ngx-translate/core';
import * as moment from 'moment';
import { NkapNotificationService, NotificationType } from 'src/app/services/nkap-notification.service';
import { Funtions } from 'src/app/model/security/funtionalities';
import { FormControl } from '../../../../../../node_modules/@angular/forms';
import { MedicalAgent } from 'src/app/model/organisation/medical-agent';
import { MedicalFolder } from '../../../../model/admission/medical-folder';
import { FileDownloaderComponent } from 'src/app/components/file-downloader/file-downloader.component';

@Component({
  selector: 'app-care-prescription-list',
  templateUrl: './care-prescription-list.component.html',
  styleUrls: ['./care-prescription-list.component.css']
})
export class CarePrescriptionListComponent extends NkapListComponent<any> {

  @ViewChild("btnBar")
  btnBarCmp: ButtonsBarComponent;

  agentSelected: MedicalAgent;
  agentControl: FormControl = new FormControl();
  agentList: MedicalAgent[];

  folderSelected: MedicalFolder;
  folderControl: FormControl = new FormControl();
  folderList: MedicalFolder[];

  protected startDateCtrl: FormControl = new FormControl();

  /**
   * A reference on download component
   */
  @ViewChild("downloader")
  downloaderComponent: FileDownloaderComponent;
  openFileInTab: boolean = true;
  
  constructor(protected _ref: ChangeDetectorRef, protected httpService: NkapHttpService<any>, 
    protected router: Router, protected route: ActivatedRoute, protected userService: UserAppService,
    protected msgService: NkapNotificationService, protected translateService: TranslateService) {
   super(_ref, httpService, router, route, userService, msgService, translateService);

   this.searchBaseUrl = "prescription/care-prescription";

   this.buttons = [
    {id: "add", value: FormAction.CREATE, icon: {type : 'add_circle_outline', color: '#0099cc'},
      text: 'prescription.btn.new.label', disabled: false, functionalities: [Funtions.carePrescription_btn_new_fct]},
    {id: "edit", value: FormAction.UPDATE, icon: {type : 'edit',color:'#ff9933'}, 
      text: 'prescription.btn.edit.label', disabled: false, functionalities: [Funtions.carePrescription_btn_edit_fct]},
    {id: "delete", value: FormAction.DELETE, icon: {type :'delete_forever',color:'#ff9933'},
      text: 'prescription.btn.delete.label', disabled: false, functionalities: [Funtions.carePrescription_btn_delete_fct]},
    {id: "print", value: FormAction.PRINT, icon: {type : 'print',color:'#ff9933'}, name: "care-prescription_print-btn",
      text: 'prescription.btn.print.label', disabled: false, functionalities: [Funtions.carePrescription_btn_print_fct]},
    {id: "close", value: FormAction.CLOSE, icon: {type : 'close',color:'#ff9933'},
      text: 'prescription.btn.close.label', disabled: false, functionalities: [Funtions.carePrescription_btn_close_fct]}
  ];     
}


ngOnInit() {
  // By default we render receptions of the current day
  if(this.criteria.period == null) {
    this.criteria.period = {};
  }
  const currentDate = moment.utc(new Date()).startOf('day').toDate();// moment(moment.utc(new Date()).startOf('day').format('LL')).startOf('day').toDate();
  //this.criteria.period.start = currentDate;
  super.ngOnInit();   

  this.agentControl.valueChanges.subscribe( (inputValue) => {
    if (typeof inputValue === 'string' ) {
      this.agentSelected = null;
      this.httpService.search({value: inputValue}, 'organisation/medical-agent').subscribe ( (result) => {
         this.agentList = result ? result.datas : [];
      });
     } else {
       this.agentSelected = inputValue;
     }
    this.criteria.medicalAgentId = this.agentSelected? this.agentSelected.id : undefined;
  });

  this.folderControl.valueChanges.subscribe( (inputValue) => {
    if (typeof inputValue === 'string' ) {
      this.folderSelected = null;
      this.httpService.search({value: inputValue}, 'admission/medical-folder').subscribe ( (result) => {
         this.folderList = result ? result.datas : [];
      });
     } else {
       this.folderSelected = inputValue;
     }
    this.criteria.folderId = this.folderSelected? this.folderSelected.id : undefined;
  });

}

displayAgentFn(agent?: MedicalAgent): string  {
  return agent ? `${agent.fullname ? agent.fullname : ''}` : '';
}

displayFolderFn(folder?: MedicalFolder): string  {
  return folder ? `${folder.patient.fullname ? folder.patient.fullname : ''}` : '';
}

displayPatientFn(patient?: Patient): string  {
  return patient ? `${patient.fullname ? patient.fullname : ''}` : '';
}

formatDate(date){
  try {
      return  moment(new Date(date)).format("LL") + " [" + moment(new Date(date)).utc().format("HH:mm") + "]";
  } catch (e) {
    console.error("Parse date in reception-list fail " , e);
  }
}

displayDate(date) {
  if(date){
    //console.log("displayDate ", this.lang);
    moment.locale(this.lang);
    return moment(new Date(date)).utc().format("ddd") + " " + moment(new Date(date)).format("L") + 
    " [" + moment(new Date(date)).utc().format("HH:mm") + "]";;
  }
}

displayDetailsFn(details?: string): string  {
  return details ? `${details.replace(/\t/g, '<br/>')}` : '';
}

btnActionClick(event) {
  let btnNumber = 0;
  if(event && event.id === this.buttons[btnNumber++].id){
    this.router.navigate(["care-prescription/edit"]);
  } else if(event && event.id === this.buttons[btnNumber++].id){
    if( !this.selection.selected || this.selection.selected.length != 1){
      this.msgService.log("prescription.list.select.to.modify.error", NotificationType.ERROR);
    }else{
      this.router.navigate(["care-prescription/edit", {id: this.selection.selected[0].id}]);
    }
  } else if(event && event.id === this.buttons[btnNumber++].id){
    if( !this.selection.selected || this.selection.selected.length < 1){
      this.msgService.log("prescription.list.select.to.delete.error", NotificationType.ERROR);
    }else{
      this.deleteSelected();
    }
  } else if(event && event.id === this.buttons[btnNumber++].id){
    if( !this.selection.selected || this.selection.selected.length < 1){
      this.msgService.log("prescription.list.select.to.print.error", NotificationType.ERROR);
    }else{
      const prescriptionId = this.selection.selected[0].id;
      this.print(prescriptionId);
    }
  } else if(event && event.id === this.buttons[btnNumber++].id){
    if( !this.selection.selected || this.selection.selected.length < 1){
      this.msgService.log("prescription.list.select.to.close.error", NotificationType.ERROR);
    }else{
      const prescriptionId = this.selection.selected[0].id;
      this.close(prescriptionId);
    }
  }
}

close(id){
  this.msgService.confirm(this.translate.transform("prescription.close.confirm.text")).subscribe( (result) => {
    if(result === true){
        this.criteria.id=id;
        this.httpService.post(this.criteria, 'prescription/care-prescription/close').subscribe( (val) => {
          this.msgService.log("prescription.close.succeed", NotificationType.SUCCESS);
          this.searchClicked({});
        }, (error) => {
          this.msgService.log("prescription.close.fail", NotificationType.ERROR);
        });
      }
    });
}

deleteSelected(){
  this.msgService.confirm(this.translate.transform("prescription.delete.confirm.text")).subscribe( (result) => {
    if(result === true){
        let prescriptionSelected = this.selection.selected[0];
        this.httpService.deleteById(prescriptionSelected.id, 'prescription/care-prescription').subscribe( (val) => {
          this.msgService.log("prescription.delete.succeed", NotificationType.SUCCESS);
          this.searchClicked({});
        }, (error) => {
          this.msgService.log("prescription.delete.fail", NotificationType.ERROR);
        });
      }
    });
}

protected startDateSelected(event) {
  if(!this.criteria.period){
    this.criteria.period = {};
  }
  this.criteria.period.start = event.value;
}

protected endDateSelected(event) {
  if(!this.criteria.period){
    this.criteria.period = {};
  }
  this.criteria.period.end = event.value;
}

protected viewElement(element) {
  this.router.navigate(['care-prescription/show', {id: element.id}]);
}

print(id) {
  this.criteria.id=id;
  this.criteria.lang= this.translateService.currentLang;
  this.downloaderComponent.criteria=this.criteria;
  this.downloaderComponent.link = 'prescription/care-prescription/print';
  this.downloaderComponent.downloadFile({});
  this.downloaderComponent.event.subscribe( (whellDone) => {
    if (whellDone == false) {
      this.msgService.log("form.print.error", NotificationType.ERROR);
    }
  });
}

protected setCriteriaDataInView(cachedNavigationData: any) {
  super.setCriteriaDataInView(cachedNavigationData);
  if(cachedNavigationData != null && cachedNavigationData.agent != null) {
    this.agentControl.setValue(cachedNavigationData.agent);
  }
  if(cachedNavigationData != null && cachedNavigationData.folder != null) {
    this.folderControl.setValue(cachedNavigationData.folder);
  }
}

protected getNavigationDataToStore(): any {
  let data = super.getNavigationDataToStore();
  return Object.assign( data != null ? data : {}, {agent: this.agentControl.value, folder: this.folderControl.value});
}

listSelectionChanged(){
  super.listSelectionChanged();
  if(this.btnBarCmp){
    const btnStatus = [
      {id: "add", disabled: false, functionalities: this.buttons[0].functionalities},
      {id: "edit", disabled: !(this.selection && this.selection.selected && this.selection.selected.length > 0 && this.selection.selected[0].stillValid), 
        functionalities: this.buttons[1].functionalities},
      {id: "delete", disabled: !(this.selection && this.selection.selected && this.selection.selected.length > 0), functionalities: this.buttons[2].functionalities},
      {id: "print", disabled: !(this.selection && this.selection.selected && this.selection.selected.length > 0), functionalities: this.buttons[3].functionalities},
      {id: "close", disabled: !(this.selection && this.selection.selected && this.selection.selected.length > 0 && this.selection.selected[0].stillValid), 
        functionalities: this.buttons[4].functionalities},
    ];    
    this.btnBarCmp.setButtonsStatus(btnStatus);
  }
}

}
