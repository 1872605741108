import { Patient } from 'src/app/model/organisation/patient';
import { Component, Input, OnInit, OnChanges, SimpleChanges } from '@angular/core';
import { ExamDTO } from 'src/app/model/exam/exam';
import * as moment from 'moment';
import { TranslateService } from '@ngx-translate/core';
import { Country } from 'src/app/model/location/country';

@Component({
  selector: 'app-exam-show-content',
  templateUrl: './exam-show-content.component.html',
  styleUrls: ['./exam-show-content.component.css']
})
export class ExamShowContentComponent implements OnInit , OnChanges{

  @Input()
  exam: ExamDTO;

  @Input()
  renderPatient: boolean = false;
  patient: Patient;
  
  lang?: string;

  constructor(protected translateService: TranslateService) { 
    this.lang = this.translateService.currentLang;
    this.translateService.onLangChange.subscribe( (lang) => {
      this.lang = lang.lang;
    });
  }
 
  ngOnInit() {
    
  }

  ngOnChanges(changes: SimpleChanges): void {
    if(changes != null && changes['renderPatient'] != null) {
      this.buildData();
    }
    if(changes != null && changes['exam'] != null) {
      this.buildData();
    }
  }

  buildData() {
    if(this.renderPatient == true && this.exam != null && this.exam.patientFolder != null) {
      let patient = new Patient(this.exam.patientFolder.patientFullname);
      (patient as any).number = this.exam.patientFolder.number;
      patient.gender = this.exam.patientFolder.patientGender;
      patient.birthdate = this.exam.patientFolder.patientBirthdate;
      //patient.nationality = {classe: null,name: this.exam.patientFolder.patientNationalityName};
      this.patient = patient;
    } else {
      this.patient = null;
    }
  }

  displayDate( date: Date , withHour?: boolean) {
    if(date){
      console.log("displayDate ", this.lang);
      moment.locale(this.lang);
      let format = this.lang === "en" ? "dddd, MMM Do YYYY" : "dddd, Do MMM YYYY";
      let hourFormat = "HH:mm";
      if(withHour == true) {
        return moment(new Date(date)).format(format) + " [" + moment(new Date(date)).utc().format(hourFormat) + "]";
      }
      return moment(new Date(date)).utc().format(format) ;
    }
  }

  renderDate(date: string) {
    //console.log(" renderDate ", this.locale);
    moment.locale(this.lang);
    return moment(new Date(date)).format("LL");
  }
}
