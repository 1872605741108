import { Component, OnInit, Inject, ViewChild, HostListener } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { IFormActionBarButton, FormAction } from '../../buttons-bar/buttons-bar.component';
import { MedicalFolderGridComponent } from '../medical-folder-grid/medical-folder-grid.component';
import { ENTER } from '@angular/cdk/keycodes';
import { IModalComponent } from '../../base/modal/modal.component';

@Component({
  selector: 'app-medical-folder-search-dialog',
  styles: [],
  template: ` <div mat-dialog-content> 
                <app-medical-folder-grid *ngIf="data" [criteria]='data.criteria'  [component]="componentName" #medicalFolderGrid></app-medical-folder-grid>
              </div>
            `
})
export class MedicalFolderSearchDialogComponent extends IModalComponent {

  protected componentName = this.constructor.name;
  protected data: any;
  @ViewChild("medicalFolderGrid")
  protected grid: MedicalFolderGridComponent;

  constructor(
    public dialogRef: MatDialogRef<MedicalFolderSearchDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public modalData: any) {
      super();      
      this.data = modalData? modalData.data : {};
      console.log("MedicalFolderSearchDialogComponent ", this.data);
  }

  onNoClick(): void {
    this.dialogRef.close({value:null});
  }

  @HostListener('window:keyup', ['$event'])
  keyEvent(event: KeyboardEvent) {
    if (event.keyCode === ENTER) {
      this.dialogRef.close({value:this.grid.selection.selected[0]});
    }
  }

  public getData() {
    return this.grid ? this.grid.getData() : null;
  }
}
