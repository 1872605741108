import { Component, OnInit, ViewChild, ElementRef, Input, SimpleChanges, OnChanges, AfterViewInit } from '@angular/core';
import { MatSelect, DateAdapter, MatTableDataSource, MatDialog } from '@angular/material';
import { from, Subject } from 'rxjs';
import { FormControl, FormGroup, Validators, AbstractControl, ValidationErrors } from '@angular/forms';
import { Party } from 'src/app/model/organisation/party';
import { Persistent, ClassNameMap } from 'src/app/model/persistent';
import { TranslateService, LangChangeEvent } from '@ngx-translate/core';
// tslint:disable-next-line:max-line-length
import { CareCoverageDocType, PrestationCovered, ThirdPartyPayerType, ThirdPartyPayer, CareCoverage, PrestationCategoryCovered, PrestationFamilyCovered, CareCoverageDoc, PrestationCategory } from 'src/app/model/admission/care-coverage-doc';
import { CouvertureSanteModalComponent } from './couverture-sante-modal/couverture-sante-modal.component';
import { NkapHttpService } from 'src/app/services/nkap-http.service';
import { IFormActionBarButton, FormAction } from '../buttons-bar/buttons-bar.component';
import { Period } from 'src/app/model/admission/Period';
import { NkapNotificationService } from 'src/app/services/nkap-notification.service';
import { Funtions } from 'src/app/model/security/funtionalities';
import { ModalComponentService } from '../base/modal/modal.component';

@Component({
  selector: 'couverture-sante',
  templateUrl: './couverture-sante.component.html',
  styleUrls: ['./couverture-sante.component.css'],
})
export class CouvertureSanteComponent implements OnInit, OnChanges, AfterViewInit {

  protected thirdPartyPayerSelected: ThirdPartyPayer;

  @Input("spinner") showSpinner: boolean = false;
  
    /**
     * Garde les informations du fichier uploader
     */
    static file: any;
    static extentions = ['video/mp4', 'text/javascript', 'application/x-msdownload', 'audio/mp3','application/zip']

    @Input() coverageDocNumber: string = null;

    @Input() coverageDocPayerId: Number = null;

    @Input() folderIdInput: number = null;
    
    folderId: number;
    

    /**
     * use to manage a view
     * @type {{any}}
     */
    currentObject: any = {};

    /**
     * Contient la liste des tiers payant provenant du server
     */
    tiers: ThirdPartyPayer[] = [];
    /**
     * Contient le document sélectionné ou creer
     */
    careCoverageDoc: CareCoverageDoc[] = [];

    /**
     * Selecteur Pour les Types de Tiers Payant
     */
    @ViewChild('selectedTiersPayant', null) selectedTiersPayant: MatSelect;


    /**
     * Selecteur pour les documents
     */
    @ViewChild('selectedDocType', null) selectedDocType: MatSelect;

    /**
     * Selecteur pour le fichier a joindre
     */
    @ViewChild('fileInput', null) fileInput: ElementRef;

    /**
     * Control du Input des tiers
     */
    myControl = new FormControl('', [Validators.required, CouvertureSanteComponent.validControl]);

    /**
     * Control pour le document
     */
    numeroDocumentControle = new FormControl('', [CouvertureSanteComponent.validNumeroControl]);
    /**
     * Control de la date de bebut pour valider le document
     */
    dateDebutValidite = new FormControl(null, [CouvertureSanteComponent.validend]);
    /**
     * Utiliser pour l'interpolation au niveau de la vue du champ date de début
     */
    dateStart;
    /**
     * Utiliser pour l'interpolation au niveau de la vue du champ date de fin
     */
    dateEnd;
    /**
     * Control de la date de fin pour valider le document
     */
    datefinvalidite = new FormControl(null, [CouvertureSanteComponent.validDebut]);
    /**
     * Pour le formulaire upload du fichier
     */
    uploadForm: FormGroup;
    /**
     * Contient les informations du fichier renvoyer par le serveur
     */
    fileInformation: any;
    /**
     * Les paramettres pour les Url a utiliser sur la vue
     */
    parameters: any = {};
    /**
     * Utiliser pour les différents buttons de la vue
     */
    protected buttons: IFormActionBarButton[];
    /**
     * Les colonnes du tableaux pour les différente careCovrage
     */
    displayedColumns: string[] = ['name', 'pourcentage', 'delete', 'edit'];
    /**
     * Les données du tableau
     */
    dataSource: MatTableDataSource<PrestationCategoryCovered & PrestationFamilyCovered & PrestationCovered>;
    /**
     * Le subject utiliser pour émettre les données du tableau a chaque modification
     */
    dataSubject: Subject<any> = new Subject<any>();
    /**
     * Contient les données a traité avant l'affichage sur le tableau
     */
    data: (PrestationCategoryCovered & PrestationFamilyCovered & PrestationCovered)[] = [];
    protected gridLineIndex: number = 0;// the identifier of an element in the grid
    /**
     * List of all items to send to the serve to be deleted
     */
    protected toDeleteData: (PrestationCategoryCovered & PrestationFamilyCovered & PrestationCovered)[] = [];

    /**
     * Tableau pour l'affichage des types de document
     */
    careCoverageDocType = [
      {value: CareCoverageDocType[0], text: 'couverture-sante.document-type.insurance-card'},
      {value: CareCoverageDocType[1], text: 'couverture-sante.document-type.care-voucher'},
    ];

    selectedDocTypeControl = new FormControl(CareCoverageDocType[0], [Validators.required]);
    selectedTiersPayantControl = new FormControl(ThirdPartyPayerType[0], [Validators.required]);

    /**
     * Tableau pour l'affichage des types de tiers payant
     */
    options = [
      {value: ThirdPartyPayerType[0], text: 'couverture-sante.tiers-payant-type.assurance'},
      {value: ThirdPartyPayerType[1], text: 'couverture-sante.tiers-payant-type.employer'},
      {value: ThirdPartyPayerType[2], text: 'couverture-sante.tiers-payant-type.personne'}
    ];
    /**
     * Utiliser pour l'affichage du méssage lorsque le tier payant est invalide
     */
    showMessage = true;

    careCovrageValidity = true;

    phoneDisplay: string;
    emailDisplay: string;

    static numberDocumentInvalid = false;
    dataTableInvalid = false;

  constructor(private translateService: TranslateService,
    private nkapService: NkapHttpService<any>,
    private msgService: NkapNotificationService,
    public modalService: ModalComponentService,
    private adapter: DateAdapter<any>, public dialog: MatDialog) {
    this.currentObject.tiers = [];
    this.currentObject.tiersSelected = new ThirdPartyPayer();
    this.currentObject.tiersSelected.party = new Party();
    this.currentObject.careCoverageDoc = new CareCoverageDoc();
    this.currentObject.careCoverageDoc.validityPeriod = new Period();
    this.currentObject.docType = null;
    this.currentObject.categoryThirdParty = null;
    this.folderId = this.folderIdInput;

    this.parameters = {
    thirdPartyListUrl: 'third-party-payer',
    thirdPartyDoctUrl: 'third-party-payer/care-coverage-doc',
    };

    this.buttons = [
    {id: 'save-global', value: FormAction.CREATE, icon: {type : 'add_circle_outline'},
    text: 'couverture-sante.action-create.couverture-global', functionalities: [Funtions.consultation,Funtions.reception_btn_edit_fct,Funtions.exams,Funtions.careCoverageDoc_btn_new_fct,Funtions.careCoverageDoc_btn_edit_fct]},
    {id: 'save-family', value: FormAction.CREATE, icon: {type : 'add_circle_outline'},
    text: 'couverture-sante.action-create.couverture-sur-famille', functionalities: [ Funtions.consultation,Funtions.reception_btn_edit_fct, Funtions.exams,Funtions.careCoverageDoc_btn_new_fct,Funtions.careCoverageDoc_btn_edit_fct]},
    {id: 'save-prestation', value: FormAction.CREATE, icon: {type : 'add_circle_outline'},
    text: 'couverture-sante.action-create.couverture-sur-prestation', functionalities: [Funtions.consultation,Funtions.reception_btn_edit_fct, Funtions.exams,Funtions.careCoverageDoc_btn_new_fct,Funtions.careCoverageDoc_btn_edit_fct]}
    ];
}
    /**
     * Use to validate a size file and type
     * @param control 
     */
  static validatelengthfile(control: AbstractControl): ValidationErrors | null {
    if (CouvertureSanteComponent.file) {
      console.log(CouvertureSanteComponent.file);
      if (CouvertureSanteComponent.file.size && CouvertureSanteComponent.file.size > 5242880) {
        return { validatelengthfile : 'couverture-sante.document.upload.file.size.error'};
      } else if (CouvertureSanteComponent.extentions.includes(CouvertureSanteComponent.file.type || CouvertureSanteComponent.file.type === "")) {
        return { validatelengthfile : 'couverture-sante.document.upload.type.error'};
      } else {
        return null;
      }
    } else {
      return null;
    }
  }

  static validControl(control: AbstractControl): ValidationErrors | null {
    if (typeof control.value !== 'object') {
      return {
        validControl: 'couverture-sante.tiers-payant.valid'
      };
    }
    return null;
  }

  static validNumeroControl(control: AbstractControl): ValidationErrors | null {

    if (control.value) return null;
    if (CouvertureSanteComponent.numberDocumentInvalid === true) {
      return {numberDocumentInvalid : true};
    }
    return null;
  }

  static validDebut(control: AbstractControl): ValidationErrors | null {
    const date: Date = control.value;
    if (!date ||  !CouvertureSanteComponent.staticStartDate) return null;
    if (date < CouvertureSanteComponent.staticStartDate) {
      return {
        validDebut: 'false',
      };
    }
    return null;
  }

  static validend(control: AbstractControl): ValidationErrors | null {
    const date: Date = control.value;
    if (!date || !CouvertureSanteComponent.staticEndDate) return null;
    if (date > CouvertureSanteComponent.staticEndDate) {
      return {
        validEnd: 'false',
      };
    }
    return null;
  }
  static staticStartDate;
  static staticEndDate;
  /**
   * Utiliser pour reinitialiser le champ myControl
   */
  clearMyControl() {
    this.myControl.reset(null, {emitEvent: false});
    this.currentObject.tiersSelected.party = new Party();
    this.currentObject.tiersSelected.party.name = '';
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['coverageDocNumber'] || changes['coverageDocPayerId'] || changes['folderIdInput']) {
      // console.log("changes ", changes);
     // if (this.coverageDocNumber || this.coverageDocPayerId) {
       this.folderId = this.folderIdInput;
        if (this.coverageDocNumber) {
          this.numeroDocumentControle.reset(this.coverageDocNumber);
        }
        if (this.folderId) {
          this.getCareCovrageDoc(this.coverageDocNumber,false, this.coverageDocPayerId);
        }
     // }
    }
  }

  closeMessage() {
    this.showMessage = false;
  }

  selectFile() {
    this.fileInput.nativeElement.click();
  }

  onSelectFile(event) {
    if (event.target.files && event.target.files.length > 0) {
       CouvertureSanteComponent.file = event.target.files[0];
       this.uploadForm.get('filename').setValue(CouvertureSanteComponent.file.name);
       this.fileInformation = null;
    }
  }

    /**
     * Methode a appéle pour uploader le fichier pour un document
     */
  sentFile() {
    const data: FormData = new FormData();
    data.append(`data`, CouvertureSanteComponent.file);
    console.log(CouvertureSanteComponent.file);
  }

  displayOptionFn(tier?: ThirdPartyPayer) {
    return tier && tier.party && tier.party.name ? `${tier.party.name}` : '';
  }

  displayNumberFn(doc?: CareCoverageDoc) {
    return doc ? `${doc.number}` : '';
  }

  /**
   * To diplay a phone number of a tier party
   * @param tier 
   */
  displayPhone(tier?: ThirdPartyPayer) {
    let value: string;
    if (tier.party.mobilePhone) {
      this.translateService.get('person.mobilePhone.label').subscribe(val => {
        value = `${val}
                 ${tier.party.mobilePhone}`;
      });
    } else if (tier.party.fixedPhone) {
      this.translateService.get('person.fixedPhone.label').subscribe(val => {
        value = `${val}
                 ${tier.party.fixedPhone}`;
      });
    } else if (tier.party.fax) {
      this.translateService.get('person.officePhone.label').subscribe(val => {
        value = `${val}
                 ${tier.party.fax}`;
      });
    }
    this.phoneDisplay = value;
  }
/**
 * To display a email of third party
 * @param tier 
 */
  displayEmail(tier?: ThirdPartyPayer) {
    let value: string;
    if (tier.party.emailAddress) {
      value = tier.party.emailAddress;
    } else if (tier.party.address) {
      value = tier.party.address.addressString;
    }
    this.emailDisplay = value;
  }
  /**
     * Lorsque le numéro de document change envoyer une requette au serveur pour filter les données
     * recherche dans la liste des documents le documment qui corespond a celui recherche
     * Une fois le documment trouver modidier les dates, parcourir les différents tableau de careCovrage
     * pour récuperer les données en fonction des types
     *  @param event keybord.enter
     */
  searchNumber(event) {
    const value = this.numeroDocumentControle.value;

    // On verifie si le numéro de dossier du patient est valide
    if (this.folderId === undefined){
      return;
    }

    
    // On verifie si le numéro de document est valide
    if (this.numeroDocumentControle.invalid) {
      this.translateService.get('couverture-sante.document.numero.required.notification').subscribe(val => {
        this.msgService.log(val);
      })
      return;
    } 

    this.getCareCovrageDoc(value, true);
  }

  
/**
 * Validate fonction we call this before save a document
 */
  isValid(): boolean {
    let valid = true;
    const numero = this.numeroDocumentControle.value;
    if (this.myControl.invalid && (!numero && this.data && this.data.length === 0)) return valid;
    if (!numero && this.data.length !== 0) {
      CouvertureSanteComponent.numberDocumentInvalid = true;
      valid = false;
    } else if (numero && this.data && this.data.length === 0) {
      this.dataTableInvalid =  true;
      valid = false;
    } else if (this.uploadForm.invalid) {
      valid = false;
    } else if (this.myControl.invalid) {
      valid = false;
    } else if (this.datefinvalidite.invalid) {
      valid = false;
    } else if(this.dateDebutValidite.invalid) {
      valid = false;
    }else if (this.selectedTiersPayantControl.invalid) {
      valid = false;
    } else if (this.selectedDocTypeControl.invalid) {
      valid = false;
    } else if (!numero) {
      CouvertureSanteComponent.numberDocumentInvalid = true;
      this.numeroDocumentControle.setValue(null);
      valid = false;
    }
    return valid;
  }

  ngAfterViewInit() {
    
  }

  ngOnInit() {

    /**
     * Utiliser pour mettre a jour le tableau des prestations apres une mise a jour, un ajout ou une supression
     */
    this.dataSubject.subscribe(results => {
      if (this.numeroDocumentControle.value  && this.data && this.data.length === 0) {
        this.dataTableInvalid = true;
      } else if (!this.numeroDocumentControle.value  && this.data && this.data.length === 0) {
        this.dataTableInvalid = false;
      } else if (this.numeroDocumentControle.value &&  this.data && this.data.length !== 0) {
        this.dataTableInvalid = false;
      } else if (!this.numeroDocumentControle.value &&  this.data && this.data.length !== 0) {
        CouvertureSanteComponent.numberDocumentInvalid = true;
        this.numeroDocumentControle.reset(null);
      }
      this.dataSource = new MatTableDataSource<PrestationCategoryCovered & PrestationFamilyCovered & PrestationCovered>(this.data);
    });
    this.emitData();

    /**
     * Use to manage a formFile
     */
    this.uploadForm = new FormGroup({
      filename: new FormControl('', [CouvertureSanteComponent.validatelengthfile])
    });

    this.numeroDocumentControle.valueChanges.subscribe(val => {
      if (val && this.data && this.data.length === 0) {
        this.dataTableInvalid = true;
      } else {
        this.dataTableInvalid = false;
      }
      if (!val && this.data && this.data.length !== 0) {
        CouvertureSanteComponent.numberDocumentInvalid = true;
      }
      if (!val && this.data && this.data.length === 0) {
        CouvertureSanteComponent.numberDocumentInvalid = false;
      }
    });

    /**
     * Magane a thirdParty
     */
    this.myControl.valueChanges.subscribe(value => {
      if (!value || typeof value === 'string') {
        this.thirdPartyPayerSelected = null;
        this.getTiers(value);
        return;
      } else if (value.id !== undefined) {
        this.thirdPartyPayerSelected = value;
        this.currentObject.tiersSelected = value;
       /* this.nkapService.findById(value.id, `${this.parameters.thirdPartyListUrl}`).subscribe(res => {
        
          this.currentObject.tiersSelected = res;
          this.displayPhone(res);
          this.displayEmail(res);
          if( res.type ) {
            this.selectedTiersPayant.value = res.type;
          }
          this.myControl.reset(res, {emitEvent: false});
        });*/
      }
      this.showMessage = true;
    });

    this.selectedTiersPayantControl.valueChanges.subscribe(val => {
      this.getTiers();
    })

    this.dateDebutValidite.valueChanges.subscribe(value => {
      if ( this.currentObject.careCoverageDoc.validityPeriod === null) {
        this.currentObject.careCoverageDoc.validityPeriod = new Period();
      }
      this.currentObject.careCoverageDoc.validityPeriod.start = (value);
      CouvertureSanteComponent.staticStartDate = value;
      this.datefinvalidite.updateValueAndValidity({emitEvent: false});
    });


    this.datefinvalidite.valueChanges.subscribe(value => {
      if ( this.currentObject.careCoverageDoc.validityPeriod === null) {
        this.currentObject.careCoverageDoc.validityPeriod = new Period();
      }
      try {
      this.currentObject.careCoverageDoc.validityPeriod.end = (value);
      CouvertureSanteComponent.staticEndDate = value;
      this.dateDebutValidite.updateValueAndValidity({emitEvent: false});
    } catch (e) { this.currentObject.careCoverageDoc.validityPeriod.end = null; }
    });

    this.translateService.onLangChange.subscribe((event: LangChangeEvent) => {
      this.adapter.setLocale(`${event.lang}`);
      this.displayPhone(this.currentObject.tiersSelected);
      this.displayEmail(this.currentObject.tiersSelected);
    });
    
    this.selectedDocType.selectionChange.subscribe(val => {
      this.currentObject.docType = val.value;
    });
    this.selectedTiersPayant.selectionChange.subscribe(val => {
      this.currentObject.categoryThirdParty = val.value;
    });
  }

  /**
   * Utiliser pour l'ouverture du dialog
   * @param event : Lorque cela est appele depuis le template
   * @param typeCoverage : Precise le type de carecovrage (prestation, famille de prestation, categorie)
   * @param value : S'il s'agit d'une modification il contient les données a modifier
   */
  openDialog(event?, value?: PrestationCategoryCovered & PrestationFamilyCovered & PrestationCovered) {
   // Initialisation en fonction d'une categorie de prestion
   let modalData = {modalTitle: "", careCoverage: value};
    if(value ){
      switch (value.classe) {
        case 'PrestationCovered' :
          modalData.modalTitle = this.buttons[2].text;
          break;
        case 'PrestationFamilyCovered' :
          modalData.modalTitle = this.buttons[1].text;
          break;
        case 'PrestationCategoryCovered' :
          modalData.modalTitle = this.buttons[0].text;
          break;
      }
    } else {
      if (event && event.id === this.buttons[0].id ) {
        modalData.modalTitle = this.buttons[0].text;
        modalData.careCoverage = new PrestationCategoryCovered();
      }
      // Initialisation des familles de prestation
      if (event && event.id === this.buttons[1].id ) {
        modalData.modalTitle = this.buttons[1].text;
        modalData.careCoverage = new PrestationFamilyCovered();
      }
      // Initialisation en fonction des prestations
      if (event && event.id === this.buttons[2].id ) {
        modalData.modalTitle = this.buttons[2].text;
        modalData.careCoverage = new PrestationCovered();
      } 
      (modalData.careCoverage as any).lineNumber =  this.gridLineIndex++;
    }  
    
    //console.log("modalData ", modalData);
    const dialogRef = this.modalService.openModal(CouvertureSanteModalComponent,
      modalData.modalTitle,undefined,{
     /* width: '50%',
      height:'260px',*/
      position:{left:"10%", right:"10%"},
      disableClose: true,
      data: modalData
    });
    
    // Configuration de l'ouverture du dialog et les données a transmettre
    /*const dialogRef = this.dialog.open(CouvertureSanteModalComponent,  {
      width: '700px',
      height: '400px',
      disableClose: true,
      data: modalData
    });*/

    // Lorque le dialog se ferme il faut récuperr les données et ajouter dans le tableau
    dialogRef.afterClosed().subscribe(result => {
     // console.log("result", result, this.data);
     if(result){
        const indexInDataTable = this.data.findIndex((elmt: any) => (elmt.lineNumber == result.lineNumber) || 
          (result.classe == 'PrestationCovered' && elmt.classe == result.classe && elmt.prestation.id == result.prestation.id) ||
          (result.classe == 'PrestationFamilyCovered' && elmt.classe == result.classe && elmt.family.id == result.family.id) ||
          (result.classe == 'PrestationCategoryCovered' && elmt.classe == result.classe && elmt.category == result.category)
          );
        
      // console.log("indexInDataTable ", indexInDataTable, result);
        result.toAddOrUpdate = true;
        result.toDelete = false;
        if (indexInDataTable === -1) {
          this.data.push(result);
        } else {
          this.data[indexInDataTable] = Object.assign(this.data[indexInDataTable], result);;
        }
        this.emitData();
      }
    });
  }

  remouve(elt: any) {
      this.data = this.data.filter(elt1 => !( (elt1 as any).lineNumber == elt.lineNumber));
      if(elt.id != null) {
        elt.toAddOrUpdate = false;
        elt.toDelete = true;
        this.toDeleteData.push(elt);
      }
      this.emitData();
  }

  emitData() {
    this.dataSubject.next(this.data);
  }

  /**
   * Methode pour contacter le serveur pour avoir la liste des tiers payants en fonction d'un critere
   * @param value critere de recherche
   */
  getTiers(value?) {
    this.nkapService.search(
      {value: value ? value : '', category: this.selectedTiersPayantControl.value},
      `${this.parameters.thirdPartyListUrl}`).subscribe(results => {
      this.tiers = results.datas;
    });
  }

  clearTiersPayant(event) {
    if(event){
      console.log('clearTiersPayant', event);
      this.tiers = [];
      this.myControl.setValue(null);
    }
  }

  clearCareCoverageDoc(event) {
     // we reinit the component
     if(event) {
      this.currentObject.careCoverageDoc = new CareCoverageDoc();
      this.data = [];
      this.emitData();
      this.myControl.setValue(null);
      this.numeroDocumentControle.setValue(null);
     }
  }

  getCareCovrageDoc(value?,showNotification?: boolean, id?) {
    this.nkapService.search(
      {value: value ? (value === ' ' ? '' : value) : '', category: this.currentObject.docType,
          folderId: this.folderId != null ? this.folderId : null,
          parentId: id != null ? id : this.currentObject.tiersSelected ? this.currentObject.tiersSelected.id : null,
          parentCategory: this.currentObject.tiersSelected ? this.currentObject.tiersSelected.type : null},
      `${this.parameters.thirdPartyDoctUrl}`,this.showSpinner).subscribe(results => {
      this.careCoverageDoc = results.datas;

      // Si le serveur n'as rien renvoyer
      if (this.careCoverageDoc === null && showNotification === true) {
        this.translateService.get('couverture-sante.document.numero.required.notification').subscribe(val => {
          this.msgService.log(val);
        });
        return;
      }
      const inde = this.careCoverageDoc.findIndex(val => val.number === value);
      
      // si les données venus du serveur ne contiennent pas le document récherché
      if (inde === -1 && showNotification === true) {
        this.translateService.get('couverture-sante.document.numero.required.notification').subscribe(val => {
          this.msgService.log(val);
        });
        return;
      }

      // lorsqu'on a le bon numéro de document on peut continuer
      if (this.currentObject.careCoverageDoc === undefined) {
        this.currentObject.careCoverageDoc = new CareCoverageDoc();
      }
      this.getCareCovrageDocById(this.careCoverageDoc[inde] ? this.careCoverageDoc[inde].id : null);
    });
  }

  async getCareCovrageDocById(id: number) {
   if (!id) return;
    this.nkapService.findById(id, `${this.parameters.thirdPartyDoctUrl}`, this.showSpinner).subscribe((result: CareCoverageDoc) => {
        this.currentObject.careCoverageDoc = result;

        if (!this.currentObject.careCoverageDoc) return;

        if ( this.currentObject.careCoverageDoc.type ) {
          this.selectedDocTypeControl.setValue(this.currentObject.careCoverageDoc.type);
          this.selectedDocType.value = this.currentObject.careCoverageDoc.type ;
          this.currentObject.docType = this.selectedDocType.value;
        }

        if ( this.currentObject.careCoverageDoc.documentIssuer ) {
          this.currentObject.tiersSelected = this.currentObject.careCoverageDoc.documentIssuer;
          this.currentObject.tiersSelected.toAddOrUpdate = true;
          this.myControl.setValue(this.currentObject.tiersSelected);
          if( this.currentObject.careCoverageDoc.documentIssuer.type ) {
            this.selectedTiersPayant.value = this.currentObject.careCoverageDoc.documentIssuer.type;
          }
        }

        if ( this.currentObject.careCoverageDoc.validityPeriod !== null ) {
          this.dateStart =
          this.getLocaleDateFormat(this.currentObject.careCoverageDoc.validityPeriod.start);
          this.dateEnd =
          this.getLocaleDateFormat(this.currentObject.careCoverageDoc.validityPeriod.end);
          this.dateDebutValidite.setValue(new Date(this.dateStart));
          this.datefinvalidite.setValue(new Date(this.dateEnd));
        } else {
          this.dateDebutValidite.reset(null, {emitEvent: false});
          this.datefinvalidite.reset(null, {emitEvent: false});
          this.dateStart = null;
          this.dateEnd = null;
        }
        this.data = [];

        /**
         * Information dans le tableau de prestation
         */
        if (this.currentObject.careCoverageDoc.prestations !== undefined) {
          for (const elt of this.currentObject.careCoverageDoc.prestations) {
            this.data.push(Object.assign({lineNumber: this.gridLineIndex ++},elt));
          }
        }

        /**
         * Information de le tableau de famille de prestation
         */
        if (this.currentObject.careCoverageDoc.prestationTypes !== undefined) {
          for (const elt of this.currentObject.careCoverageDoc.prestationTypes) {
            this.data.push(Object.assign({lineNumber: this.gridLineIndex ++},elt));
          }
        }

        /**
         * Information dans le tableau de categorie de prestation
         */
        if (this.currentObject.careCoverageDoc.prestationCategories !== undefined) {
          for (const elt of this.currentObject.careCoverageDoc.prestationCategories) {
            this.data.push(Object.assign({lineNumber: this.gridLineIndex ++},elt));
          }
        }

        this.emitData();
    });
  }

  getLocaleDateFormat(date: string): Date {

    let localDate = null;
    if (date === undefined) {
      return null;
    }

    try {
      const dates = date.split('/');
      localDate = new Date(dates.join('-'));
    } catch (error) {}
    return localDate;
  }

  getData(): CareCoverageDoc {
    const thirdParty = this.currentObject.tiersSelected;

    if (this.currentObject.careCoverageDoc.number === undefined || this.currentObject.careCoverageDoc.id != null) {
      this.currentObject.careCoverageDoc.toAddOrUpdate = true;
    }
    let result = null;
    if(this.numeroDocumentControle.value && typeof this.numeroDocumentControle.value == "string" && this.numeroDocumentControle.value.trim() != ""
      && thirdParty && thirdParty.id != null){
      result = {
        id: this.currentObject.careCoverageDoc.id,
        classe: ClassNameMap.CareCoverageDoc,
        documentIssuer: thirdParty,
        type: this.selectedDocTypeControl.value,
        prestations: (this.data.filter((elt)=> elt.classe === 'PrestationCovered') || []).concat( ( this.toDeleteData.filter((elt)=> elt.classe === 'PrestationCovered') || [])),
        prestationTypes: (this.data.filter((elt)=> elt.classe === 'PrestationFamilyCovered') || []).concat( ( this.toDeleteData.filter((elt)=> elt.classe === 'PrestationFamilyCovered') || [])),
        prestationCategories: (this.data.filter((elt)=> elt.classe === 'PrestationCategoryCovered') || []).concat( ( this.toDeleteData.filter((elt)=> elt.classe === 'PrestationCategoryCovered') || [])),
        number: this.numeroDocumentControle.value,
        validityPeriod: this.currentObject.careCoverageDoc.validityPeriod === null ? null : {
          end:  CouvertureSanteComponent.staticEndDate,
          start:  CouvertureSanteComponent.staticStartDate,
        },
        isValid: this.currentObject.careCoverageDoc.isValid,
        toAddOrUpdate: true,
        endOfLifeReason: this.currentObject.careCoverageDoc.endOfLifeReason,
        file: CouvertureSanteComponent.file === undefined ? null : CouvertureSanteComponent.file .name
      };
    }
    console.log(result);
    console.log(this.isValid());
    return result;
  }

}

