import { Persistent, ClassNameMap } from '../persistent';
import { Country } from './country';

export class City extends Persistent {
    postCode?: string;
    name?: string;
    country?: Country;

    constructor() {
      super();
      this.country = new Country();
      this.classe = ClassNameMap.City;
    }
}