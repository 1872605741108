import { Component, OnInit, ViewChild, ChangeDetectorRef } from '@angular/core';
import { NotificationType, NkapNotificationService } from 'src/app/services/nkap-notification.service';
import { FormControl } from '@angular/forms';
import { MatTableDataSource, MatTable, MatInput } from '@angular/material';
import { SelectionModel } from '@angular/cdk/collections';
import { SearchCriteria } from 'src/app/model/data-page';
import { IFormActionBarButton, FormAction, ButtonsBarComponent } from 'src/app/components/buttons-bar/buttons-bar.component';
import { TranslatePipe, TranslateService } from '@ngx-translate/core';
import { NkapHttpService } from 'src/app/services/nkap-http.service';
import { Router, ActivatedRoute } from '@angular/router';
import { GlobalVariables } from 'src/app/services/global-variables';
import { BusinessUnit } from 'src/app/model/organisation/business-unit';
import { NkapListComponent } from '../../base/nkap-list-component';
import { UserAppService } from 'src/app/services/user-app.service';
import { Funtions } from 'src/app/model/security/funtionalities';

@Component({
  selector: 'app-business-unit-list',
  templateUrl: './business-unit-list.component.html',
  styleUrls: ['./business-unit-list.component.css']
})
export class BusinessUnitListComponent extends NkapListComponent<BusinessUnit> {

  @ViewChild("btnBar")
  btnBarCmp: ButtonsBarComponent;
  
  parentSelected: BusinessUnit;
  parentControl: FormControl = new FormControl();
  unitList: BusinessUnit[];

  constructor(protected _ref: ChangeDetectorRef, protected httpService: NkapHttpService<BusinessUnit>, 
    protected router: Router, protected route: ActivatedRoute, protected userService: UserAppService,
    protected msgService: NkapNotificationService, protected translateService: TranslateService) {
    
      super(_ref, httpService, router, route, userService, msgService, translateService);
      this.searchBaseUrl = "organisation/business-unit";
       this.buttons = [
        {id: "add", value: FormAction.CREATE, icon: {type : 'add_circle_outline', color: '#0099cc'},
          text: 'business-unit.btn.new.label', disabled: false, functionalities: [Funtions.businessUnit_btn_new_fct]},
        {id: "edit", value: FormAction.UPDATE, icon: {type : 'edit',color:'#ff9933'}, 
          text: 'business-unit.btn.edit.label', disabled: false, functionalities: [Funtions.businessUnit_btn_edit_fct]},
        {id: "delete", value: FormAction.DELETE, icon: {type :'delete_forever',color:'#ff9933'},
          text: 'business-unit.btn.delete.label', disabled: false, functionalities: [Funtions.businessUnit_btn_delete_fct]}
      ];
  }

  ngOnInit() {    
    super.ngOnInit();
    this.parentControl.valueChanges.subscribe( (inputValue) => {
      if (typeof inputValue === 'string' ) {
        this.parentSelected = null;
        this.httpService.search({value: inputValue}, 'organisation/business-unit').subscribe ( (result) => {
           this.unitList = result ? result.datas : [];
        });
       } else {
         this.parentSelected = inputValue;
       }
      this.criteria.parentId = this.parentSelected? this.parentSelected.id : undefined;
    });
  }

  
  displayParentFn(unit?: BusinessUnit): string  {
    return unit ? `${unit.name}` : '';
  }
 
  btnActionClick(event) {
    if(event && event.id === this.buttons[0].id){
      this.router.navigate(["business-unit/edit"]);
    } else if(event && event.id === this.buttons[1].id){
      if( !this.selection.selected || this.selection.selected.length != 1){
        this.msgService.log("business-unit.list.select.to.modify.error", NotificationType.ERROR);
      }else{
        this.router.navigate(["business-unit/edit", {id: this.selection.selected[0].id}]);
      }
    } else if(event && event.id === this.buttons[2].id){
      if( !this.selection.selected || this.selection.selected.length < 1){
        this.msgService.log("business-unit.list.select.to.modify.error", NotificationType.ERROR);
      }else{
        this.deleteSelected();
      }
    }
  }

  displayUnitFn(unit?: BusinessUnit): string  {
    return unit ? `${unit.name}` : '';
  }

  protected viewElement(element) {
    this.router.navigate(['business-unit/show', {id: element.id}]);
  }

  deleteSelected(){
    this.msgService.confirm(this.translate.transform("business-unit.delete.confirm.text")).subscribe( (result) => {
      if(result === true){
        let objectSelected = this.selection.selected[0];
        this.httpService.deleteById(objectSelected.id, 'organisation/business-unit').subscribe( (val) => {
          this.msgService.log("business-unit.delete.succed", NotificationType.SUCCESS);
          this.selection.clear();
          this.searchClicked({});
        }, (error) => {
          this.msgService.log(error, NotificationType.ERROR);
        });
      }
    });
  }

  protected setCriteriaDataInView(cachedNavigationData: any) {
    super.setCriteriaDataInView(cachedNavigationData);
    if(cachedNavigationData != null && cachedNavigationData.family != null) {
      this.parentControl.setValue(cachedNavigationData.family);
    }
  }

  protected getNavigationDataToStore(): any {
    let data = super.getNavigationDataToStore();
    return Object.assign( data != null ? data : {}, { family: this.parentControl.value});
  }

  listSelectionChanged(){
    super.listSelectionChanged();
    if(this.btnBarCmp){
      const btnStatus = [
        {id: "add", disabled: false, functionalities: this.buttons[0].functionalities},
        {id: "edit", disabled: !(this.selection && this.selection.selected && this.selection.selected.length > 0), functionalities: this.buttons[1].functionalities},
        {id: "delete", disabled: !(this.selection && this.selection.selected && this.selection.selected.length > 0), functionalities: this.buttons[2].functionalities},
      ];    
      this.btnBarCmp.setButtonsStatus(btnStatus);
    }
  }
}
