import { Component, OnInit, ChangeDetectorRef, Inject, AfterContentChecked } from '@angular/core';
import { IFormActionBarButton, FormAction } from 'src/app/components/buttons-bar/buttons-bar.component';
import { FormControl } from '@angular/forms';
import { TranslatePipe, TranslateService } from '@ngx-translate/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { IModalComponent } from 'src/app/components/base/modal/modal.component';
import { DataService } from '../data-service';
import { Funtions } from 'src/app/model/security/funtionalities';

@Component({
  selector: 'app-apointment-edit-modal-add',
  templateUrl: './apointment-edit-modal-add.component.html',
  styleUrls: ['./apointment-edit-modal-add.component.css']
})
export class ApointmentEditModalAddComponent extends IModalComponent implements OnInit, AfterContentChecked {

  protected buttons: IFormActionBarButton[];
  translate: TranslatePipe;
  descriptionControl = new FormControl();
  careProtocolControl = new FormControl();

  IsCareApointment: boolean = false;

  protected data:  {title?: string, message: string, event:string, 
    careProtocol:string, careMessage: string, careId: string};

  constructor(public dataService:DataService,public dialogRef: MatDialogRef<ApointmentEditModalAddComponent>,
    protected translateService: TranslateService,private _ref: ChangeDetectorRef,
    @Inject(MAT_DIALOG_DATA) public modalData: any) {

      super();
      this.data = modalData.data ;

      this.translate = new TranslatePipe(translateService, _ref);

      this.buttons = [
        {id: "save", value: FormAction.CREATE, icon: {type : 'save'},
          text: 'btn.save.label', disabled: false, functionalities: [Funtions.apointment_btn_new_fct,Funtions.consultation,Funtions.reception_btn_edit_fct, Funtions.apointment_btn_edit_fct, Funtions.apointment_btn_new_fct,Funtions.exams ]},
        {id: "cancel", value: FormAction.CANCEL, icon: {type :'cancel',color:'#ff9933'},
          text: 'apointment.btn.cancel', disabled: false, functionalities: ['apointment.btn.cancel.label']},
      ];

    }

    ngOnInit() {
      
    }

    ngAfterContentChecked() {
      this.IsCareApointment = this.data.careId ? true : false;
      this._ref.detectChanges(); 
    }

    cancel(): void {
      this.dataService.dataAnnuler=true;
      this.dialogRef.close();
    }

  btnActionClick(event) {
    if(event && event.id === this.buttons[0].id){
     this.dialogRef.close(this.data ? this.data : null);
    } else if(event && event.id === this.buttons[1].id){
      this.cancel();
    }
  }

  public getData() {
    return this.data;
  }

}
