import { Component, OnInit, Input, OnChanges, SimpleChanges, ViewChild } from '@angular/core';
import { MenuItem } from '../main-menu.component';
import { Router } from '@angular/router';
import { UserAppService } from 'src/app/services/user-app.service';
import { MatExpansionPanel } from '@angular/material';

@Component({
  selector: 'app-menu-item',
  templateUrl: './menu-item.component.html',
  styleUrls: ['./menu-item.component.css']
})
export class MenuItemComponent implements OnInit, OnChanges {
  
  @Input("item")
  menuItem: MenuItem;

  @Input()
  level: number;

  @ViewChild("expansionPannel")
  panel: MatExpansionPanel;
  
  constructor(private router: Router, private userService: UserAppService) {
  }

  ngOnInit() {
  }

  ngOnChanges(changes: SimpleChanges): void {
  }

  protected menuItemClicked(menuItem: MenuItem) {
    this.userService.setReception(null);
    if (menuItem && menuItem.routeData) {
      this.router.navigate(menuItem.routeData);
    }
  }

  expandMenu(event, menuItem) {
   // console.log("expandMenu ",menuItem);
    if(menuItem){

    }
  }
}
