import { Party } from './../../../model/organisation/party';
import { Component, Input, OnInit, OnChanges, SimpleChanges } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { GlobalVariables } from 'src/app/services/global-variables';

/**
 * To render age of a person
 */
@Component({
  selector: 'app-age-renderer',
  templateUrl: './age-renderer.component.html',
  styleUrls: ['./age-renderer.component.css']
})
export class AgeRendererComponent implements OnInit , OnChanges{
  
  /**
   * The age to render
   */
  @Input()
  age: { years: number; months: number; weeks: number; days: number; };
  
  /**
   * The birthdate to use to calculate age and render it
   * Is only used when age is not set
   */
  @Input()
  birthdate: any;

  /**
   * The reference date to calculate age based on birthdate
   * For exemple, if you received a patient the 12/01/2016 and you look at his folder, 
   * you will need to know what age he has at that moment (the referenceDate)
   * By default, reference date is now
   */
  @Input('reference')
  referenceDate: any;

  /**
   * If the age must be show in detail from years to days
   * NB, if a part is null, it wan't be shown
   */
  @Input('all')
  renderAll: boolean = false;

  /**
   * The language in wich the age must be rendered
   */
  lang: string = GlobalVariables.AVAILABLE_LANG ? GlobalVariables.AVAILABLE_LANG[0] : null;

  yearsLimitToIgnoreMonthsAndOthers: number = 4;
  monthsLimitToIgnoreDays: number = 1;

  numberOfPart: number = 2;
  years: number;
  months: number;
  weeks: number;
  days: number;

  colClass = "";

  constructor( protected translateService: TranslateService) { 
    this.lang = this.translateService.currentLang;
    this.translateService.onLangChange.subscribe( (lang) => {
      this.lang = lang.lang;
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    let age = null;
    if(changes["renderAll"] || changes["birthdate"] || changes["referenceDate"]){
      if(this.age == null) {
        age = Party.getYearsOld(this.birthdate,this.referenceDate);
      } else {
        age = this.age;
      }
    }
    if(changes["age"]){
      age = this.age;
    }
    this.setYearsData(age);
    this.updateRendererData();
  }

  setYearsData(age: { years: number; months: number; weeks: number; days: number; }) {
    if(age != null) {
      this.years = age.years;
      this.months = age.months;
      this.weeks = age.weeks;
      this.days = age.days;
      if(this.renderAll == false) {
        if(age.years >= this.yearsLimitToIgnoreMonthsAndOthers) {
          this.months = 0;
          this.weeks = 0;
          this.days = 0;
        }
        if(age.years <= 0 && age.months >= this.monthsLimitToIgnoreDays) {
          this.days = 0;
        }
        if(this.numberOfPart == 2){
          if(this.years > 0){
            this.weeks = this.months > 0 ? 0 : this.weeks;
            this.days = this.months > 0 || this.weeks > 0 ? 0 : this.days;
          } else if(this.months > 0){
            this.days = this.weeks > 0 ? 0 : this.days;
          }
        }
      }
    }
  }

  updateRendererData() {
    this.colClass = `col-xs-${12 / (this.renderAll == false? this.numberOfPart : 4)} col-sm-${12 / (this.renderAll == false? this.numberOfPart : 4)}`;
    this.colClass += ` col-md-${12 / (this.renderAll == false? this.numberOfPart : 4)} col-lg-${12 / (this.renderAll == false? this.numberOfPart : 4)}`;
  }

  ngOnInit() {
    this.updateRendererData();
  }

}
