import { Patient } from '../organisation/patient';

export class CounterActivitiesReport{
		
    public patientCreates?: Patient;
    public patientUpdated?: Patient;
    public invoicedCession?: string;
    public cessionBPC?: string;
    public invoicedPrestationNb?: number;
    
  }