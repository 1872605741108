import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root'
  })
  export class DataService {
    confirmAnnuler:boolean=false;
    confirmDelete:boolean=false;

    get dataAnnuler():boolean { 
        return this.confirmAnnuler; 
      } 
      get dataDelete():boolean { 
        return this.confirmDelete; 
      } 
      set dataAnnuler(value: boolean) { 
        this.confirmAnnuler = value; 
      } 
      set dataDelete(value: boolean) { 
        this.confirmDelete = value; 
      } 
      

    constructor() { }
  
  }