import { Component, OnInit, ViewChild, ChangeDetectorRef, ElementRef } from '@angular/core';
import { NkapListComponent } from 'src/app/components/base/nkap-list-component';
import { FileDownloaderComponent } from 'src/app/components/file-downloader/file-downloader.component';
import { NkapHttpService } from 'src/app/services/nkap-http.service';
import { Router, ActivatedRoute } from '@angular/router';
import { UserAppService } from 'src/app/services/user-app.service';
import { NkapNotificationService, NotificationType } from 'src/app/services/nkap-notification.service';
import { TranslateService } from '@ngx-translate/core';
import { FormAction } from 'src/app/components/buttons-bar/buttons-bar.component';
import { Funtions } from 'src/app/model/security/funtionalities';
import { FormControl } from '@angular/forms';
import { MatAutocompleteSelectedEvent, MatInput } from '@angular/material';
import { Patient } from 'src/app/model/organisation/patient';
import { MedicalAgent } from 'src/app/model/organisation/medical-agent';
import * as moment from 'moment';
import { Person } from 'src/app/model/organisation/person';
import { ENTER, COMMA } from '@angular/cdk/keycodes';
import { Prestation } from 'src/app/model/prestation/prestation';
import { CounterActivitiesReport } from 'src/app/model/reporting/counter-activities-report';
import { GlobalVariables } from 'src/app/services/global-variables';
import { DateUtils } from 'src/app/services/utils';

@Component({
  selector: 'app-counter-activities',
  templateUrl: './counter-activities.component.html',
  styleUrls: ['./counter-activities.component.css']
})
export class CounterActivitiesComponent extends NkapListComponent<any> {

  agentSelected: MedicalAgent[] = [];
  agentControl = new FormControl();
  agentList: MedicalAgent[];
  @ViewChild('agentInput')
  agentInputCmp: ElementRef<HTMLInputElement>;

  prestationSelected: Prestation[] = [];
  prestationControl = new FormControl();
  prestationList: Prestation[];
  @ViewChild('prestationInput')
  prestationInputCmp: ElementRef<HTMLInputElement>;

  /**
   * BPC Checkbox control variables
   */
  checkWithBPC=false;
  checkWithoutBPC=false;

  separatorKeysCodes: number[] = [ENTER, COMMA];

  tableColumn = [];
  counterActivitiesReport:CounterActivitiesReport=new CounterActivitiesReport();

  comparotorControl = new FormControl();
  comparators: string[] = [];

   /**
   * A reference on download component
   */
  @ViewChild("downloader")
  downloaderComponent: FileDownloaderComponent;
  openFileInTab = true;
  
  constructor(protected _ref: ChangeDetectorRef, protected httpService: NkapHttpService<any>, 
    protected router: Router, protected route: ActivatedRoute, protected userService: UserAppService,
    protected msgService: NkapNotificationService, protected translateService: TranslateService) {
   super(_ref, httpService, router, route, userService, msgService, translateService);
      this.searchBaseUrl = "reporting/counter-activity";
     
      this.criteria = {period: {}};
      this.criteria.period = {start: DateUtils.getMonday(Date.now()), end:Date.now()};
      
   this.buttons = [
    {id: "search", value: FormAction.PRINT, icon: {type : 'search'}, toolTip: "app.search.label", cssClass:"mat-raised-button",
      disabled: false, functionalities: [Funtions.counterActivities_btn_view_fct]},
    {id: "print", value: FormAction.PRINT, icon: {type : 'print'}, toolTip: "app.print.label",cssClass:"mat-raised-button",
      disabled: false, functionalities: [Funtions.counterActivities_btn_print_fct]},

      /*{id: "close", value: FormAction.UPDATE, icon: {type : 'close'}, 
         disabled: false, functionalities: [Funtions.chronologicalList_btn_close_fct]},*/
      ];   
      this.tableColumn = ['agent','patCreates','patUpdated','invCessions','cessionsBPC','invPrestNb'/*,'action'*/];  
      this.comparators = ["","=","<",">","<=",">=","<>"];
  }

  ngOnInit() {
    super.ngOnInit();
    this.route.paramMap.subscribe(params => {
      let data: any = (params as any).params;
      if (data.search) {
        this.searchClicked({});
      }
    });    

    this.agentControl = new FormControl();
    this.agentControl.valueChanges.subscribe( (inputValue) => {
      if (typeof inputValue === 'string' ) {
        this.httpService.search({value: inputValue}, 'organisation/medical-agent').subscribe ( (result) => {
           this.agentList = result ? result.datas : [];
        });
       }
    });

    this.prestationControl = new FormControl();
    this.prestationControl.valueChanges.subscribe( (inputValue) => {
      if (typeof inputValue === 'string' ) {
        this.httpService.search({value: inputValue}, 'prestation').subscribe ( (result) => {
           this.prestationList = result ? result.datas : [];
         });
       }
    });
  }

  displayPersonFn(agent?: Person): string  {
    return agent ? `${agent.fullname}` : '';
  }

  displayPrestationFn(prestation?: any): string {
    if(prestation.productCode){
      return `${prestation.productCode} - ${prestation.productName}`
    } else{
      return `${prestation.code} - ${prestation.name}`
    }
  }

  displayBooleanFn(boolean: boolean){
    if(boolean)
    return "counter-cessions-BPC.true.label";
    else
    return "counter-cessions-BPC.false.label"
  }

  checkerWithBPC(){
    if(this.checkerWithoutBPC)
    this.checkWithoutBPC=false;
  }

  checkerWithoutBPC(event:any){
    if(this.checkerWithBPC)
    this.checkWithBPC=false;
  }

  protected startDateSelected(event) {
    if(event && event.value) {
      this.criteria.period.start = null;
      this.criteria.period.start = event.value? event.value.utc().startOf('day').toDate() : this.criteria.period.start;
    } else {
      this.msgService.log("reporting.period.start.is.required.text",NotificationType.INFO);
      this.criteria.period.start = DateUtils.getMonday(Date.now());
    }  
  }

  protected endDateSelected(event) {
    this.criteria.period.end = null;
    this.criteria.period.end = event.value? event.value.utc().startOf('day').toDate() : this.criteria.period.end;
  }

  btnActionClick(event) {
    if(event && event.id === this.buttons[1].id){
      // print report
     this.printClicked(event);
    } else if(event && event.id === this.buttons[0].id){
      this.searchClicked(event)
    } else if(event && event.id === this.buttons[2].id){
      if( !this.selection.selected || this.selection.selected.length < 1){
        this.msgService.log('counter-activities.select.to.cancel.error', NotificationType.ERROR);
      }else{
      }
    }
  }


  protected printAllClicked(event) {
    this.criteria.allPages = true;
    this.printClicked(null);
  }

  protected printSingleClicked(event) {
    this.criteria.allPages = false;
    this.printClicked(null);
  }

  public searchClicked(event) {   
    this.criteria.pageSize = GlobalVariables.PAGINATOR_DEFAULT_SIZE;
    this.elmtSelected.emit(null);
    this.criteria.prestationIds = Array.from(this.prestationSelected,(prestation)=> prestation.id);
    this.criteria.agentIds = Array.from(this.agentSelected,(agent)=> agent.id);
    this.criteria.withCareCoverage=null;
    if(this.checkWithBPC)
    this.criteria.withCareCoverage=true;
    else if(this.checkWithoutBPC)
    
    this.criteria.withCareCoverage=false;
    this.search(this.criteria);
  }

  /**
   * Function triggering the printing of the Counter Activities List
   */
  protected printClicked(event) {
    this.criteria.pageSize = GlobalVariables.PAGINATOR_DEFAULT_SIZE;
    this.criteria.lang= this.translateService.currentLang;
    this.downloaderComponent.link = 'reporting/counter-activity/print';
    this.criteria.prestationIds = Array.from(this.prestationSelected,(prestation)=> prestation.id);
    this.criteria.agentIds = Array.from(this.agentSelected,(agent)=> agent.id);
    this.criteria.withCareCoverage=null;
    if(this.checkWithBPC)
    this.criteria.withCareCoverage=true;
    else if(this.checkWithoutBPC)
    this.criteria.withCareCoverage=false;
    this.downloaderComponent.criteria=this.criteria;
    this.downloaderComponent.downloadFile({});
    this.downloaderComponent.event.subscribe( (whellDone) => {
        if (whellDone == false) {
          this.msgService.log("form.print.error", NotificationType.ERROR);
        }
    });
  }

  // formatHour(startTime){
  //   try{
  //    return moment(new Date((new Date().getFullYear())+"-01-01T" + `${startTime}`)).format("h:mm");
  //    }catch(e){
  //      console.error("Parse date in counter-activities fail " , e);
  //    }
  // }

  // formatDate(date){
  //   try {
  //       return "[" + moment(new Date(date)).utc().format("HH:mm") + "] " + moment(new Date(date)).format("LL");
  //   } catch (e) {
  //     console.error("Parse date in counter-activities fail " , e);
  //   }
  // }

  selectedPrestation(event: MatAutocompleteSelectedEvent) {
    let selected = event.option.value;
    if(selected != null && this.prestationSelected != null && this.prestationSelected.findIndex( (elm)=> elm.code == selected.code ) == -1) {
      this.prestationSelected = this.prestationSelected.concat([selected]);
    }
    this.prestationInputCmp.nativeElement.value = '';
    this.prestationControl.setValue(null);
    //console.log( "selectedPrestation", event);
  }

  removeInPrestationSelected(prestation) {
    if (prestation) {
      const index = this.prestationSelected.findIndex( (elmt) => elmt.code == prestation.code);
      if (index != -1) {
        this.prestationSelected.splice(index, 1);
      }
    }
  }

  selectedAgent(event: MatAutocompleteSelectedEvent) {
    let selected = event.option.value;
    if(selected != null && this.agentSelected != null && this.agentSelected.findIndex( (elm)=> elm.fullname == selected.fullname ) == -1) {
      this.agentSelected = this.agentSelected.concat([selected]);
    }
    this.agentInputCmp.nativeElement.value = '';
    this.agentControl.setValue(null);
    //console.log( "selectedAgent", event);
  }

  removeInAgentSelected(agent) {
    if (agent) {
      const index = this.agentSelected.findIndex( (elmt) => elmt.fullname == agent.fullname);
      if (index != -1) {
        this.agentSelected.splice(index, 1);
      }
    }
  }

  comparatorChanged(event) {
    if(!this.criteria.period){
      this.criteria.period = {};
    }
    this.criteria.period.comparator = event? event.value: this.criteria.period.comparator; 
  }

}
