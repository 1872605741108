import { Component, OnInit, ChangeDetectorRef, Inject } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NkapNotificationService, NotificationType } from 'src/app/services/nkap-notification.service';
import { NkapHttpService } from 'src/app/services/nkap-http.service';
import { AntecedentType, Antecedent } from 'src/app/model/admission/medical-folder';
import { MatDialog, MatTableDataSource, MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import { FormControl, Validators } from '@angular/forms';
import { IFormActionBarButton, FormAction } from 'src/app/components/buttons-bar/buttons-bar.component';
import { Funtions } from 'src/app/model/security/funtionalities';
import { NkapListComponent } from 'src/app/components/base/nkap-list-component';
import { UserAppService } from 'src/app/services/user-app.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-antecedent-type-edit',
  templateUrl: './antecedent-type-edit.component.html',
  styleUrls: ['./antecedent-type-edit.component.css']
})
export class AntecedentTypeEditComponent extends NkapListComponent<AntecedentType> {
  nameControl: FormControl;
  antecedentType:AntecedentType;

  constructor(protected _ref: ChangeDetectorRef,protected route: ActivatedRoute, protected httpService: NkapHttpService<AntecedentType>,
    protected msgService: NkapNotificationService ,protected router: Router,protected dialog: MatDialog,
     protected translateService: TranslateService, protected userService: UserAppService) { 
      super(_ref, httpService, router, route, userService, msgService, translateService);
      this.searchBaseUrl = "admission/antecedent-type";
      this.antecedentType=new AntecedentType();

        this.buttons = [
          {id: "save", value: FormAction.CREATE, icon: {type : 'save'},
            text: 'btn.save.label', disabled: false, functionalities: [Funtions.antecedent_type_btn_new_fct, Funtions.antecedent_type_btn_edit_fct]},
          {id: "list", value: FormAction.VIEW, icon: {type : 'list'},
            text: 'btn.list.label', disabled: false, functionalities: [Funtions.antecedent_type_btn_list_fct]},
            // {id: "exit", value: FormAction.CLOSE, icon: {type : 'cancel'},
            // text: 'stepper.exit.label', disabled: data ? false:true, functionalities: [Funtions.antecedent_btn_new_fct, Funtions.antecedent_type_btn_edit_fct]},
        ];
     
    }

  ngOnInit() {
    super.ngOnInit();
    this.nameControl = new FormControl('', [Validators.required, Validators.minLength(1)]);
    this.nameControl.updateValueAndValidity();

    this.route.paramMap.subscribe(params => {
      let data: {id?: string} = (params as any).params;
      if (data.id != null) {
        this.httpService.findById(data.id, this.searchBaseUrl, true).subscribe( (type)=>{
          console.log(type)
          this.setObjectInView(type);
        }, (errors) => {
          this.msgService.log("antecedent.type.edit.get.data.error", NotificationType.ERROR);
        });
      }
    });
  }

  save() {
    console.log(this.antecedentType)
    if (this.isValid() === true){
      this.httpService.save(this.antecedentType, this.searchBaseUrl, true).subscribe( (result) => {
        this.msgService.log("antecedent.type.save.succed", NotificationType.SUCCESS);
        this.router.navigate(['antecedent-type/list', {search: true}]);
        // this.search(this.criteria);
        // this.nameControl = new FormControl('', [Validators.required, Validators.minLength(1)]);
        // this.nameControl.updateValueAndValidity();
      },(error) => {
        if (error && Array.isArray(error)) {
          error.forEach( (err) => {
            this.msgService.log(err, NotificationType.ERROR);
          });
        } else if(typeof error === 'string') {
          this.msgService.log(error, NotificationType.ERROR);
        } else {
          this.msgService.log(error ? error : "antecedent-type.edit.save.fail", NotificationType.ERROR);
        }
      });
    } else {
      this.msgService.log("antecedent.type.data.invalid", NotificationType.ERROR);
    }
  }
  

  edit(antecedentType:AntecedentType){
    this.setObjectInView(antecedentType);
  }

  // delete(antecedentType:AntecedentType){
  //   console.log(antecedentType);
  //   this.msgService.confirm(this.translate.transform("antecedent.type.delete.confirm.text")).subscribe( (result) => {
  //     if(result === true){
  //       this.httpService.deleteById(antecedentType.id,this.searchBaseUrl, true).subscribe( (val) => {
  //         this.msgService.log('antecedent.type.delete.succed', NotificationType.SUCCESS);
  //         this.search(this.criteria);
  //         // this.router.navigate(['antecedent/list', {search : true}]);
  //       }, (error) => {
  //         this.msgService.log('antecedent.type.delete.fail', NotificationType.ERROR);
  //       });
  //     }
  //   });
  // }

  isValid(){
    let isValid = true;
    this.getObjectFromView();
    this.nameControl.markAsTouched();
    if(this.nameControl.invalid){
      isValid = false;
    }
    /* if(this.descriptionControl.invalid){
      isValid = false;
    } */
    console.log( "isValid" , isValid);
    return isValid;
  }

  getObjectFromView(){
    this.antecedentType.name = this.nameControl.value;
  }

  setObjectInView(antecedentType: AntecedentType){
    if (antecedentType) {
      this.antecedentType = antecedentType;
      this.nameControl = new FormControl(antecedentType.name, [Validators.required, Validators.minLength(1)]);
      this.nameControl.updateValueAndValidity();
    }
  }

  
  btnActionClick(event) {
    if(event && event.id === this.buttons[0].id){
      this.save();
    } else if(event && event.id === this.buttons[1].id){
      this.router.navigate(["antecedent-type/list", {search: true}]);
    }
}

  // cancel(): void {
  //   this.dialogRef.close();
  // }

}
