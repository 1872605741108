import { Component, OnInit, ViewChild, ChangeDetectorRef } from '@angular/core';
import { NkapHttpService } from 'src/app/services/nkap-http.service';
import { Prestation, PrestationFamily, PrestationAssortment } from 'src/app/model/prestation/prestation';
import { ActivatedRoute, Router } from '@angular/router';
import { NkapNotificationService, NotificationType } from 'src/app/services/nkap-notification.service';
import { MatTableDataSource, MatTable } from '@angular/material';
import { IFormActionBarButton, FormAction } from '../../buttons-bar/buttons-bar.component';
import { TranslateService, TranslatePipe } from '@ngx-translate/core';
import { BusinessUnit } from 'src/app/model/organisation/business-unit';
import { Funtions } from 'src/app/model/security/funtionalities';

@Component({
  selector: 'app-prestation-show',
  templateUrl: './prestation-show.component.html',
  styleUrls: ['./prestation-show.component.css']
})
export class PrestationShowComponent implements OnInit {

  prestation: Prestation;
  assortmentDataSource: MatTableDataSource<PrestationAssortment> = new MatTableDataSource([]);

  @ViewChild(MatTable)
  tableCmp: MatTable<any>;
  
  protected buttons: IFormActionBarButton[];

  translate: TranslatePipe;
  
  constructor(private _ref: ChangeDetectorRef, protected translateService: TranslateService,
              private httpService: NkapHttpService<Prestation>, 
              private router: Router, private route: ActivatedRoute,
              private msgService: NkapNotificationService) {
      this.translate = new TranslatePipe(translateService, _ref);
      this.buttons = [
        {id: 'edit', value: FormAction.UPDATE, icon: {type : 'edit', color: '#ff9933'}, 
          text: 'prestation.btn.edit.label', disabled: false, functionalities: [Funtions.prestation_btn_edit_fct]},
        {id: 'delete', value: FormAction.DELETE, icon: {type : 'delete_forever', color: '#ff9933'},
           text: 'prestation.btn.delete.label', disabled: false, functionalities: [Funtions.prestation_btn_delete_fct]},
        {id: 'list', value: FormAction.VIEW, icon: {type : 'list'}, text: 'btn.list.label', disabled: false, functionalities: [Funtions.prestation_btn_list_fct]}
      ];

  }

  ngOnInit() {
    this.route.paramMap.subscribe(params => {
      let data: {id?: string | number} = (params as any).params;
      if (data.id != null) {
        this.httpService.findById(data.id, 'prestation', true).subscribe( (prestation) => {
          this.prestation = prestation;
          this.assortmentDataSource.data = this.prestation.assortments ? this.prestation.assortments : [];
        }, (errors) => {
          this.msgService.log('prestation.edit.get.data.error', NotificationType.ERROR);
        });
      }
    });
  }

  displayFamilyFn(family?: PrestationFamily): string  {
    return family ? `${family.code} ${family.name ? family.name : ''}` : '';
  }

  displayPrestationFn(prestation) {
    return prestation ? `${prestation.code} ${prestation.name}` : '';
  }

  btnActionClick(event) {
    if (event && event.id === this.buttons[0].id) {
      this.router.navigate(['prestation/edit', {id: this.prestation.id}]);
    } else if (event && event.id === this.buttons[1].id) {
        this.delete();
    } else if (event && event.id === this.buttons[2].id) {
      this.router.navigate(['prestation/list', {search : true}]);
    } else if (event && event.id === this.buttons[1].id) {
      
    }
  }

  delete(){
    this.msgService.confirm(this.translate.transform("prestation.delete.confirm.text")).subscribe( (result) => {
      if(result === true){
        this.httpService.deleteById(this.prestation.id, 'prestation', true).subscribe( (val) => {
          this.msgService.log('prestation.delete.succed', NotificationType.SUCCESS);
          this.router.navigate(['prestation/list', {search : true}]);
        }, (error) => {
          this.msgService.log('prestation.delete.fail', NotificationType.ERROR);
        });
      }
    });
  }

  displayServiceFn(service?: BusinessUnit): string {
    return service ? `${service.name}` : '';
  }
}
