import { NkapValidators } from 'src/app/components/base/nkap-validators';
import { Component, OnInit, Input, OnChanges, SimpleChanges, Output, EventEmitter } from '@angular/core';
import { TimeDuration, TimeUnit } from 'src/app/model/prescriptions/prescription';
import { FormBuilder, FormGroup } from '@angular/forms';

@Component({
  selector: 'app-duration-selector',
  templateUrl: './duration-selector.component.html',
  styleUrls: ['./duration-selector.component.css']
})
export class DurationSelectorComponent implements OnInit , OnChanges{

  /**
   * Label of the component
   */
  @Input()
  label: string = "duration-selector.label";

  /**
   * Units to show in select
   */
  @Input('units')
  durationUnits: {id: any, text: string}[] = [];

  @Input()
  duration: TimeDuration;

  /**
   * When the curent value is changed, this event emit it
   */
  @Output('durationChanged')
  event: EventEmitter<TimeDuration> = new EventEmitter<TimeDuration>();

  durationSelectorForm: FormGroup;
  
  constructor(private formBuilder: FormBuilder) {

  }
  
  ngOnInit() {
    this.durationUnits = TimeDuration.getDurationUnitList();
    this.initComponent(this.duration);
  }

  ngOnChanges(changes: SimpleChanges): void {
    if(changes['duration']){
      this.initComponent(changes['duration'].currentValue);
    }
  }

  protected initComponent(duration: TimeDuration): void {
    this.duration = duration;
    //console.log("initComponent ", this.duration);
    this.durationSelectorForm = this.formBuilder.group({
        duration:[this.duration && this.duration.duration ? this.duration.duration : '', [NkapValidators.validNumber]],
        unit:[this.duration && this.duration.unit? this.duration.unit : TimeUnit[TimeUnit.MONTH], []],
    });
    this.durationSelectorForm.valueChanges.subscribe( (res)=> {
      this.duration = this.durationSelectorForm.valid == true ? 
      Object.assign(new TimeDuration(),this.durationSelectorForm.value) : null;        
      this.event.emit(this.duration ? new TimeDuration(this.duration.duration, this.duration.unit) : null);
    });
    this.durationSelectorForm.get('unit').valueChanges.subscribe( (res)=> {
        if(this.durationSelectorForm.get('duration').invalid){
          this.durationSelectorForm.get('duration').setValue(1);
        }
    });
  }

  public setValue(duration: TimeDuration) {
    this.initComponent(duration);
  }

  public getValue(): TimeDuration {
    this.duration = this.durationSelectorForm.value
    return this.duration;
  }

  public isValid(): boolean {
    let isValid = this.durationSelectorForm.valid;
    return isValid;
  }

  checkDuration(event) {
    let durationNumber = this.durationSelectorForm.get("duration").value;
    console.log(durationNumber);
    if(durationNumber == null || durationNumber <= 0) {
      this.durationSelectorForm.get("duration").patchValue(null);
    } 
  }
}
