import { Injectable, Inject, ElementRef } from '@angular/core';
import { EventManager, DOCUMENT } from '@angular/platform-browser';
import { Observable } from 'rxjs';
import { MatInput } from '@angular/material';

  export interface HotKeysOptions {
    element?: any;
    keys: string;
    action?: KeyDownAction;
  }
  
  export interface KeyDownAction{
      doAction(evt: any);
  }
  
  @Injectable({ providedIn: 'root' })
  export class HotKeys {
    defaults: Partial<HotKeysOptions> = {
      element: this.document
    }
      
    constructor(private eventManager: EventManager,
        @Inject(DOCUMENT) private document: Document) {
    }
  
    addShortcut(options: Partial<HotKeysOptions>): Observable<any> {
      const merged = { ...this.defaults, ...options };
      const event = `keydown.${merged.keys}`;
      merged.element = this.document;
      console.log("addShortcut ",merged,event);
      return new Observable(observer => {
        const handler = (e) => {
          e.preventDefault();
          observer.next(e);
        };
        const dispose = this.eventManager.addEventListener(
          merged.element, event, handler
        );
  
        return () => {
          dispose();
        };
      })
    }

    init(keyActions: HotKeysOptions[], hostElement?: ElementRef){   
        if(keyActions){     
            keyActions.forEach( (keyAction)=> {
                const options:HotKeysOptions = {keys: keyAction.keys};
                if(hostElement){
                  options.element = hostElement.nativeElement;
                }
                
                this.addShortcut(options).pipe().subscribe( (ev)=>{
                    console.log(" keyAction ", ev);
                    keyAction.action.doAction(ev);
                });
            });
        }
    }
  }

  export class FocusMatInputKeyDownAction implements KeyDownAction{

    constructor(protected input: MatInput){}

    doAction(event){
        console.log("FocusInputKeyDownAction ", event);
        this.input.focus();
    }     
}