import { Component, OnInit, Input } from '@angular/core';
import { Address } from 'src/app/model/location/address';
import { Person } from 'src/app/model/organisation/person';

@Component({
  selector: 'app-adress-show',
  templateUrl: './adress-show.component.html',
  styleUrls: ['./adress-show.component.css']
})
export class AdressShowComponent implements OnInit {

  @Input()
  protected colClass;

  @Input('person')
  protected currentObject: Person;

  constructor() { }

  ngOnInit() {
  }

}
