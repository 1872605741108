import { Injectable, EventEmitter } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, CanActivate, Router, NavigationEnd, NavigationStart } from '@angular/router';
import { Observable } from 'rxjs';
import { UserAppService } from './user-app.service';
import { GlobalVariables } from './global-variables';
import { Role } from '../model/security/rule';
import { NkapKeycloakService } from './nkap-keycloak.service';

@Injectable({
  providedIn: 'root'
})
export class RouteGuardGuard implements CanActivate {

  public routeChanged: EventEmitter<string> = new EventEmitter();
  protected previousUrl: string;

  constructor(private nkapKeycloakService: NkapKeycloakService , private router: Router, private userService: UserAppService){
    // retrieve previous url
    router.events.subscribe( (event) => {
      if (event instanceof NavigationEnd) {
        this.previousUrl = event.url;
      }
    });
  }

  /**
   * Used to control user navigation base on navigation logic.
   * @param route 
   * @param state 
   */
  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean | UrlTree | Observable<boolean | UrlTree> | Promise<boolean | UrlTree> {
    let currentUrl: string = route.url != null && route.url.length > 0 ? route.url[0].path : '';
    console.log( " RouteGuardGuard:canActivate ", this.previousUrl, currentUrl);

    // When user tries to access the login page but already logs in.
    if(currentUrl == "login" && this.userService.isUserLoggedIn() == true){
      this.router.navigate(["dashboard"]);
      return false;
    }

    // when user tries to access to some page but don't logs in.
    if(currentUrl != "login" && this.userService.isUserLoggedIn() == false){
      this.router.navigate(["login"]);
      return false;
    }

    // When user tries to access the login page and don't logs in.
    if (currentUrl === "login" && this.userService.isUserLoggedIn() == false) {
      return true;
    }

    // When user tries to access the dashbord page but already logs in.
    if (currentUrl === "dashboard" && this.userService.isUserLoggedIn() == true) {

      // Get a home page base rules of user.
      const nav: string[] = this.userService.getHomeUrl();

      // If dashbord page as a home page.
      if (nav.indexOf(currentUrl) !== -1) return true;

      this.router.navigate(nav);
      return false;
    }

    // When a user has `${Role.ADMIN}` Rule.
    if (this.userService.allow_all) {
      return true;
    }

    /* Evalute grant access. */
    let haveAccess = false;
    const userPrivileges = this.userService.userPrivileges.get(GlobalVariables.KEYCLOAK_RULES_ATTRIBUTE);
    
    // Return false if user don't have rule
    if (!userPrivileges) return haveAccess;

    userPrivileges.forEach(r => {
      if (route.data && route.data.roles && route.data.roles.indexOf(r) !== -1) {
        haveAccess = true;
        this.routeChanged.emit(this.previousUrl);
      }
    });
    return haveAccess;
  }

}
