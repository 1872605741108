
import { FormControl } from '@angular/forms';
import { Prestation, PrestationFamily } from 'src/app/model/prestation/prestation';
import { IFormActionBarButton, FormAction, ButtonsBarComponent } from '../../buttons-bar/buttons-bar.component';
import { NkapHttpService } from 'src/app/services/nkap-http.service';
import { NkapNotificationService, NotificationType } from 'src/app/services/nkap-notification.service';
import { Router, ActivatedRoute } from '@angular/router';
import { Component,ChangeDetectorRef, ViewChild } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { UserAppService } from 'src/app/services/user-app.service';
import { NkapListComponent } from '../../base/nkap-list-component';
import { Funtions } from 'src/app/model/security/funtionalities';

@Component({
  selector: 'app-prestation-list',
  templateUrl: './prestation-list.component.html',
  styleUrls: ['./prestation-list.component.css']
})
export class PrestationListComponent extends NkapListComponent<Prestation> {

  @ViewChild("btnBar")
  btnBarCmp: ButtonsBarComponent;

  familySelected: PrestationFamily;
  familyControl: FormControl = new FormControl();
  familyList: PrestationFamily[];

  constructor(protected _ref: ChangeDetectorRef, protected httpService: NkapHttpService<Prestation>, 
        protected router: Router, protected route: ActivatedRoute, protected userService: UserAppService,
        protected msgService: NkapNotificationService, protected translateService: TranslateService) {
       super(_ref, httpService, router, route, userService, msgService, translateService);
          this.searchBaseUrl = "prestation";
       this.buttons = [
        {id: "add", value: FormAction.CREATE, icon: {type : 'add_circle_outline', color: '#0099cc'},
          text: 'prestation.btn.new.label', disabled: false, functionalities: [Funtions.prestation_btn_new_fct]},
        {id: "edit", value: FormAction.UPDATE, icon: {type : 'edit',color:'#ff9933'}, 
          text: 'prestation.btn.edit.label', disabled: false, functionalities: [Funtions.prestation_btn_edit_fct]},
        {id: "delete", value: FormAction.DELETE, icon: {type :'delete_forever',color:'#ff9933'},
          text: 'prestation.btn.delete.label', disabled: false, functionalities: [Funtions.prestation_btn_delete_fct]},
        {id: "params", value: null, functionalities: [Funtions.prestation_btn_params_fct], icon: {type :'build',color:'#ff9933'},
          text: 'prestation.btn.params.label', disabled: false}
      ];     
  }

  ngOnInit() {
    super.ngOnInit();   
    this.familyControl.valueChanges.subscribe( (inputValue) => {
      if (typeof inputValue === 'string' ) {
        this.familySelected = null;
        this.httpService.search({value: inputValue}, 'prestation/prestation-family').subscribe ( (result) => {
           this.familyList = result ? result.datas : [];
        });
       } else {
         this.familySelected = inputValue;
       }
      this.criteria.categoryId = this.familySelected? this.familySelected.id : undefined;
    });
  }

  displayFamilyFn(family?: PrestationFamily): string  {
    return family ? `${family.code} ${family.name ? family.name : ''}` : '';
  }
 
  btnActionClick(event) {
    if(event && event.id === this.buttons[0].id){
      this.router.navigate(["prestation/edit"]);
    } else if(event && event.id === this.buttons[1].id){
      if( !this.selection.selected || this.selection.selected.length != 1){
        this.msgService.log("prestation.list.select.to.modify.error", NotificationType.ERROR);
      }else{
        this.router.navigate(["prestation/edit", {id: this.selection.selected[0].id}]);
      }
    } else if(event && event.id === this.buttons[2].id){
      if( !this.selection.selected || this.selection.selected.length < 1){
        this.msgService.log("prestation.list.select.to.delete.error", NotificationType.ERROR);
      }else{
        this.deleteSelected();
      }
    } else if(event && event.id === this.buttons[3].id) {
      this.router.navigate(["prestation/parameters"]);
    }
  }

  protected startDateSelected(event) {
    if(!this.criteria.period){
      this.criteria.period = {};
    }
    this.criteria.period.start = event.value;
  }

  protected endDateSelected(event) {
    if(!this.criteria.period){
      this.criteria.period = {};
    }
    this.criteria.period.end = event.value;
  }

  protected viewElement(element) {
    this.router.navigate(['prestation/show', {id: element.id}]);
  }

  deleteSelected(){
    this.msgService.confirm(this.translate.transform("prestation.delete.confirm.text")).subscribe( (result) => {
      if(result === true){
          let prestationSelected = this.selection.selected[0];
          this.httpService.deleteById(prestationSelected.id, 'prestation').subscribe( (val) => {
            this.msgService.log("prestation.delete.succed", NotificationType.SUCCESS);
            this.searchClicked({});
          }, (error) => {
            this.msgService.log("prestation.delete.fail", NotificationType.ERROR);
          });
        }
      });
  }
  
  protected setCriteriaDataInView(cachedNavigationData: any) {
    super.setCriteriaDataInView(cachedNavigationData);
    if(cachedNavigationData != null && cachedNavigationData.family != null) {
      this.familyControl.setValue(cachedNavigationData.family);
    }
  }

  protected getNavigationDataToStore(): any {
    let data = super.getNavigationDataToStore();
    return Object.assign( data != null ? data : {}, { family: this.familyControl.value});
  }

  listSelectionChanged(){
    super.listSelectionChanged();
    if(this.btnBarCmp){
      const btnStatus = [
        {id: "add", disabled: false, functionalities: this.buttons[0].functionalities},
        {id: "edit", disabled: !(this.selection && this.selection.selected && this.selection.selected.length > 0), functionalities: this.buttons[1].functionalities},
        {id: "delete", disabled: !(this.selection && this.selection.selected && this.selection.selected.length > 0), functionalities: this.buttons[2].functionalities},
        {id: "params", disabled: false, functionalities: this.buttons[3].functionalities}
      ];    
      this.btnBarCmp.setButtonsStatus(btnStatus);
    }
  }
}
