import { Person } from './person';
import { BusinessUnit } from './business-unit';
import { ClassNameMap } from '../persistent';

export class MedicalAgent extends Person {
    public number?: string;
    public localisation?: BusinessUnit;
    public professionTitle?: string;
    public type?: string | MedicalAgentType;
    public numberOfApointment?: number | string;
    constructor(number?: string, name?: string, surname?: string ) {
        super(name, surname);
        this.classe = ClassNameMap.MedicalAgent;
        this.professionTitle = "";
        this.number = number? number : "";
    }

    public nameAndTitle(): string {
        let result = this.fullname;
        result = result + (this.professionTitle && this.professionTitle.trim() != "" ? ", " + this.professionTitle : "");
        return result;
    }
}

export enum MedicalAgentType {
    SPECIALIST_DOCTOR,
    GENERALIST_DOCTOR,
    NURSE,
    LABORATORY_TECHNICIAN,
    OTHERS
}